import { ComponentModal, SuccessIcon } from "@components";
import { enumNotificationAction, enumProcessStatus } from "@configs";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
interface Iprops {
    isVisible: boolean;
    handleCancel: () => void;
    confirmType: enumProcessStatus;
}
export const ModuleAlertSuccess = (props: Iprops) => {
    const { isVisible, handleCancel, confirmType } = props;
    const { t } = useTranslation();
    return (
        <ComponentModal
            title={"-"}
            isVisible={isVisible}
            onCancel={handleCancel}
            className="success-modal"
            width={205}
        >
            <StyledSuccess>
                <SuccessIcon />
                <p className="success-message">
                    {confirmType === enumProcessStatus.COMPLETE
                        ? t("page.redeem.redeem_successful")
                        : t("page.redeem.reject_successful")}
                </p>
            </StyledSuccess>
        </ComponentModal>
    );
};
const StyledSuccess = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 32px !important;
    text-align: center;
    .success-message {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #000000;
    }
`;
