import { useState, useEffect } from "react";
import {
    ComponentContactSortIcon,
    StyledTable,
    ComponentEmptyData,
    StyledTableButtonTop,
    ComponentRowCategory,
} from "@components";
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    TouchSensor,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    rectSortingStrategy,
    //@ts-ignore
} from "@dnd-kit/sortable";
import { Row, Col, Pagination } from "antd";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import { enumSortBy, enumContactSortFields, PAGINATION, INITIAL_LIST_PARAMS } from "@configs";
import { IConplaintCategory, IContactListParams, INews } from "@interfaces";
import { useDispatch, useSelector } from "react-redux";
import { getConplaintCategories, selectAuth, selectContact, selectOrg } from "@redux";
import { ModuleCategoryDrawerForm } from "src/Modules/category";
import { usePermission } from "@utils";

export const PageCategoryList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const search_params = useSelector(selectContact).paramsContact;
    const [params, setParams] = useState<IContactListParams>(search_params);
    const org_id = useSelector(selectAuth).auth?.organization_data?._id;
    const adminId = useSelector(selectAuth).auth?.admin_data?._id;
    const conplaintCategory = useSelector(selectOrg).conplaintCategory;
    const permisssion = useSelector(selectAuth).permission;
    const [showForm, setShowForm] = useState(false);
    const [editItem, setEditItem] = useState<IConplaintCategory>();
    const [total, setTotal] = useState<number>(0);

    usePermission({ permission: permisssion?.permission_list.complain.is_manage });
    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        await dispatch(
            getConplaintCategories({ organization_id: org_id || "", admin_id: adminId || "" })
        );
    };

    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            const swapItem = (items: INews[]) => {
                const oldIndex = items.findIndex((item) => JSON.stringify(item) === active.id);
                const newIndex = items.findIndex((item) => JSON.stringify(item) === over.id);
                return arrayMove(items, oldIndex, newIndex);
            };
            // setNews(swapItem);
        }
    };
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
        useSensor(TouchSensor)
    );
    const handleSort = (sortField: enumContactSortFields, sortBy: enumSortBy) => {
        const newSortParams = { ...params, sortField, sortBy };
        setParams(newSortParams);
        // fetchContacts(newSortParams);
    };
    const handleCancel = () => {
        setShowForm(!showForm);
    };
    const editCategory = (editItem) => {
        setShowForm(!showForm);
        setEditItem(editItem);
    };
    const handleAddNew = () => {
        // setEditItem({
        //     category_name: "",
        //     divisions: [],
        //     is_enable: false,
        //     division_data_list: [],
        //     _id: "",
        //     count: 0,
        // });
        setEditItem(undefined);
        setShowForm(true);
    };
    return (
        <StyledTable>
            <div className="page-layout">
                <div className="page-header-layout">
                    <div className="page-header">
                        <div>
                            <h3>{t("page.complaint_list")}</h3>
                        </div>
                        <div className="flex-header">
                            <StyledTableButtonTop
                                type="danger"
                                size="default"
                                text={t("page.add_division")}
                                onClick={handleAddNew}
                                className="add-btn"
                                icon="plus"
                            />
                        </div>
                    </div>
                </div>
                <div className="page-table-layout">
                    <div className="table">
                        <ScrollContainer vertical={false}>
                            <div className="table_ground">
                                <div className="table-body">
                                    <div className="table-header">
                                        <Row
                                            // gutter={24}
                                            type="flex"
                                            justify="space-between"
                                            align="middle"
                                            className="header-row"
                                        >
                                            <Col className="col-item" span={8}>
                                                <p>{t("page.complaint_subject")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.FULL_NAME}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.FULL_NAME}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={8}>
                                                <p>{t("page.relevant_division")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.TEL}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.TEL}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={4}>
                                                <p>{t("page.active")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.TEL}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.TEL}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={4}>
                                                <p>{t("page.manage")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <DndContext
                                        sensors={sensors}
                                        collisionDetection={closestCenter}
                                        onDragEnd={handleDragEnd}
                                    >
                                        <SortableContext
                                            items={conplaintCategory.map((item) =>
                                                JSON.stringify(item)
                                            )}
                                            strategy={rectSortingStrategy}
                                        >
                                            {conplaintCategory && conplaintCategory.length > 0 ? (
                                                <div className="data-table">
                                                    {conplaintCategory.map((item, index) => (
                                                        <ComponentRowCategory
                                                            key={index}
                                                            item={item}
                                                            itemJson={JSON.stringify(item)}
                                                            index={index}
                                                            editCategory={editCategory}
                                                        />
                                                    ))}
                                                    <div
                                                        style={{ width: "100%", height: 10 }}
                                                    ></div>
                                                </div>
                                            ) : (
                                                <ComponentEmptyData />
                                            )}
                                        </SortableContext>
                                    </DndContext>
                                </div>
                            </div>
                        </ScrollContainer>
                    </div>
                </div>
                <div className="page-bottom">
                    {total > 0 && (
                        <div className="pagination">
                            <Pagination
                                defaultCurrent={INITIAL_LIST_PARAMS.page || 1}
                                pageSize={PAGINATION}
                                total={total}
                                // onChange={handlePagination}
                                current={params.page}
                            />
                        </div>
                    )}
                </div>
            </div>
            <ModuleCategoryDrawerForm
                editItem={editItem}
                isVisible={showForm}
                onClose={handleCancel}
            />
        </StyledTable>
    );
};
