import { useState, useRef, useEffect } from "react";
import {
  ComponentContactSortIcon,
  ComponentRowNews,
  StyledTable,
  ComponentEmptyData,
  StyledTableButtonTop,
  ComponentStatusFilter,
  ComponentBillFilter,
  ComponentTableSearchBill,
  ComponentRowBill,
  SharedButtonSub,
  StyledCancelButton,
} from "@components";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  TouchSensor,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
  //@ts-ignore
} from "@dnd-kit/sortable";
import { Row, Col, Pagination, Modal, Button } from "antd";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import {
  enumSortBy,
  enumContactSortFields,
  PAGINATION,
  PAGE_START,
  enumNewsStatus,
  INITIAL_LIST_PARAMS,
  PATH_CREATE_NEWS,
  enumBillStatus,
  PATH_TRASH_FEE_BILL_CREATE,
  PATH_TRASH_FEE_BILL_MANUAL_CREATE,
  PATH_TRASH_FEE_BILL_AUTO_CREATE,
} from "@configs";
import { IPaginationNext, INews, IStatusCount, ITrashfeeBillList, ITrashfeeArea } from "@interfaces";
import { useDispatch, useSelector } from "react-redux";
import { getCategories, selectAuth, selectContact, selectOrg, setLoading } from "@redux";
import { newsAPI } from "src/API/news";
import { useNotify, usePermission } from "@utils";
import { useHistory } from "react-router";
import { homeMenuAPI, trashfeeAPI } from "@api";
import styled from "styled-components";
import { ModuleBillListModal } from "@modules";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

const INITAL_PAGE = {
  xpage: PAGE_START,
  xlimit: PAGINATION,
};

export const PageBillList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const search_params = useSelector(selectContact).paramsContact;
  const pemisssion = useSelector(selectAuth).permission;
  const [params, setParams] = useState(search_params);
  const typingTimeoutRef = useRef(0);
  const org_id = useSelector(selectAuth).auth?.organization_data?._id;
  const categories = useSelector(selectOrg).category;
  const [textSearch, setTextSearch] = useState<string>("");

  const [catSearch, setCatSearch] = useState<string>("");
  const [status, setStatus] = useState<enumBillStatus>(enumBillStatus.OVERDUE);
  const [filterStatus, setFilterStatus] = useState<boolean>(true);
  const [pagination, setPagination] = useState<IPaginationNext>(INITAL_PAGE);
  const [news, setNews] = useState<INews[]>([]);
  const [billList, setBillList] = useState<ITrashfeeBillList[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [areaList, setAreaList] = useState<ITrashfeeArea[]>([]);
  const [newsCount, setNewsCount] = useState<IStatusCount>();
  const { success, error } = useNotify();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [cashModal, setCashModal] = useState<boolean>(false);
  const [cancleModal, setCancleModal] = useState<boolean>(false);
  const [openDetailModal, setOpenDetailModal] = useState<boolean>(false);
  const [selectNo, setSelectNo] = useState<string>("");
  const [selectHouse, setSelectHouse] = useState<ITrashfeeBillList>();
  const history = useHistory();
  const [searchHouseType, setSearchHouseType] = useState<string>("all");
  const [searchArea, setSearchArea] = useState<string>("all");
  const [searchDate, setSearchDate] = useState<[number, number]>([0, 0]);
  const [focusBillId, setFocusBillId] = useState<string>("");
  const [toggleBill, setToggleBill] = useState<boolean>(false);
  const [createStatus, setCreateStatus] = useState<number>(0);
  usePermission({ permission: pemisssion?.permission_list.news.is_manage });
  useEffect(() => {
    fetchBill(pagination, textSearch, status);
  }, [pagination, textSearch, status, searchHouseType, searchArea, searchDate]);

  useEffect(() => {
    fetchAreaList();
  }, []);

  const fetchNewsCategory = async () => {
    await dispatch(getCategories({ organization_id: org_id || "" }));
  };
  const fetchNewsCountStatus = async () => {
    try {
      const res = await newsAPI.getNewsEnableCount({ organization_id: org_id || "" });
      if (res.data.error === "0") setNewsCount(res.data.result);
    } catch (error) { }
  };

  const fetchBill = async (
    pagination: IPaginationNext,
    textSearch: string,
    status: enumBillStatus
  ) => {
    dispatch(setLoading(true));
    try {
      const response = await trashfeeAPI.getBillList({
        organization_id: org_id || "",
        ...pagination,
        ...(textSearch && { text_search: textSearch }),
        ...(status === enumBillStatus.OVERDUE && {
          is_enable: true,
          is_paid: false,
        }),
        ...(status === enumBillStatus.PAID && {
          is_enable: true,
          is_paid: true,
        }),
        ...(status === enumBillStatus.CANCEL && {
          is_enable: false,
        }),
        ...(searchHouseType === "home" && {
          house_type: "home",
        }),
        ...(searchHouseType === "business" && {
          house_type: "business",
        }),
        ...(searchArea !== "all" && {
          area_id: searchArea,
        }),
        ...(searchDate[0] !== 0 && searchDate[1] !== 0 && {
          month_cycle: searchDate[0],
          year_cycle: searchDate[1],
        }),

      });
      setBillList(response.data.result);
      // setNews(response.data.result);
      // setTotal(response.data.total);
      // success(response.data.msg);
    } catch (err: any) {
      error(err.msg);
      dispatch(setLoading(false));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const fetchAreaList = async () => {
    try {
      dispatch(setLoading(true));
      const payload = {
        organization_id: org_id || "",
        xpage: "1",
      };
      const response = await trashfeeAPI.getAreaList({ ...payload });
      setAreaList(response.data.result);
      dispatch(setLoading(false));
    } catch {
      dispatch(setLoading(false));
    }
  };

  const handleSearchDate = (month: number, year: number) => {
    console.log(month, year);
    setSearchDate([month, year]);
  };

  const handleSort = (sortField: enumContactSortFields, sortBy: enumSortBy) => {
    const newSortParams = { ...params, sortField, sortBy };
    setParams(newSortParams);
    // fetchContacts(newSortParams);
  };
  const updateNews = async (isEnable: boolean, data: INews) => {
    dispatch(setLoading(true));
    try {
      const payload = {
        organization_id: data.organization_id,
        title: data.title,
        detail: data.detail,
        image_url_list: data.image_url_list,
        is_hot_news: data.is_hot_news,
        news_category_id: data.news_category_id,
        date_from: data.date_from,
        date_to: data.date_to,
        is_enable: isEnable,
        news_id: data._id,
      };
      const res = await newsAPI.update(payload);
      callbackGetList();
      if (res.data.error === "1") {
        error(t(res.data.msg));
      } else {
        success(t(res.data.msg));
      }
    } catch (error) {
      dispatch(setLoading(false));
    } finally {
      dispatch(setLoading(false));
    }
  };
  const callbackGetList = () => {

    fetchBill(pagination, textSearch, status);
    fetchNewsCountStatus();
  };
  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const swapItem = (items: ITrashfeeBillList[]) => {
        const oldIndex = items.findIndex((item) => JSON.stringify(item) === active.id);
        const newIndex = items.findIndex((item) => JSON.stringify(item) === over.id);
        return arrayMove(items, oldIndex, newIndex);
      };
      setBillList(swapItem);
    }
  };
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(TouchSensor)
  );
  const handleSearchCategory = (value) => {
    setCatSearch(value);
  };

  const handlePagination = (page) => {
    setPagination({ xpage: page, xlimit: pagination.xlimit });
  };

  const clickDetailModal = (data: ITrashfeeBillList) => {
    // console.log(data?._id?.houseDetail?.register_no);
    setSelectHouse(data);
    setOpenDetailModal(true);
  };

  const closeDetailModal = () => {
    setOpenDetailModal(false);
    setSelectNo("");
  };
  const payBillByCash = (billId: string) => {
    setOpenDetailModal(false);
    setFocusBillId(billId);
    setCashModal(true);
  };
  const toggleCancleStatus = (billId: string, status: boolean) => {
    setOpenDetailModal(false);
    setFocusBillId(billId);
    setCancleModal(true);
    setToggleBill(status);
  };

  const closeStatusModal = () => {
    //
    setCashModal(false);
    setCancleModal(false);
    setFocusBillId("");
  };
  const confirmPayCash = async () => {
    try {
      dispatch(setLoading(true));
      let response = await trashfeeAPI.updatePayCash({
        organization_id: org_id || "",
        trash_fee_id: focusBillId,
      });
      console.log('confirmPayCash', response.data.result);
      if (response.data.error === "0") {
        success(response.data.msg);
      } else {
        error(response.data.msg);
      }
    } catch (error: any) {
      if (error) {
        error(error.msg);
      }
    } finally {
      dispatch(setLoading(false));
      closeStatusModal();
    }
  };
  const confirmCancleStatus = async () => {
    try {
      dispatch(setLoading(true));
      let response = await trashfeeAPI.updateCancleBill({
        organization_id: org_id || "",
        trash_fee_id: focusBillId,
        is_enable: toggleBill,
      });
      console.log('confirmCancle', response.data.result);
      if (response.data.error === "0") {
        success(response.data.msg);
      } else {
        error(response.data.msg);
      }
    } catch (error: any) {
      if (error) {
        error(error.msg);
      }
    } finally {
      dispatch(setLoading(false));
      closeStatusModal();
    }
  };

  const confirmCreate = () => {
    if (createStatus === 1) {
      history.push(PATH_TRASH_FEE_BILL_AUTO_CREATE);
    } else if (createStatus === 2) {
      //not for now
    } else if (createStatus === 3) {
      history.push(PATH_TRASH_FEE_BILL_MANUAL_CREATE);
    }
  };

  return (
    <StyledTable>
      <div className="page-layout">
        <div className="page-header-layout">
          <div className="page-header">
            <div>
              <h3>รายการชำระค่าขยะ</h3>
            </div>
            <div className="flex-header">
              <StyledTableButtonTop
                type="danger"
                size="default"
                text="สร้างรายการชำระค่าขยะ"
                // onClick={() => history.push(PATH_TRASH_FEE_BILL_CREATE)}
                onClick={() => setOpenModal(true)}
                className="add-btn"
                icon="plus"
              />
            </div>
          </div>
          <div className="search-layout">
            <ComponentBillFilter
              setStatus={setStatus}
              setFilterStatus={setFilterStatus}
              activeCount={newsCount?.enable_true || 0}
              inactiveCount={newsCount?.enable_false || 0}
              status={status}
            />
            <ComponentTableSearchBill
              setTextSearch={setTextSearch}
              total={billList?.length}
              setSearchHouseType={setSearchHouseType}
              areaList={areaList}
              setSearchArea={setSearchArea}
              handleSearchDate={handleSearchDate}
            />
          </div>
        </div>
        <div className="page-table-layout">
          <div className="table">
            <ScrollContainer vertical={false}>
              <div className="table_ground">
                <div className="table-body">
                  <div className="table-header">
                    <Row
                      gutter={24}
                      type="flex"
                      justify="space-between"
                      align="middle"
                      className="header-row"
                    >
                      <Col className="col-item" span={2}>
                        <p className="small-title">เลขที่ทะเบียนบ้าน</p>
                        <div className="filter-button">
                          <ComponentContactSortIcon
                            handleSort={handleSort}
                            sortBy={enumSortBy.ASC}
                            sortField={enumContactSortFields.FULL_NAME}
                            searchParams={params}
                          />
                          <ComponentContactSortIcon
                            handleSort={handleSort}
                            sortBy={enumSortBy.DESC}
                            sortField={enumContactSortFields.FULL_NAME}
                            searchParams={params}
                          />
                        </div>
                      </Col>
                      <Col className="col-item" span={3}>
                        <p className="small-title">หมวดพื้นที่</p>
                        <div className="filter-button">
                          <ComponentContactSortIcon
                            handleSort={handleSort}
                            sortBy={enumSortBy.ASC}
                            sortField={enumContactSortFields.TEL}
                            searchParams={params}
                          />
                          <ComponentContactSortIcon
                            handleSort={handleSort}
                            sortBy={enumSortBy.DESC}
                            sortField={enumContactSortFields.TEL}
                            searchParams={params}
                          />
                        </div>
                      </Col>
                      <Col className="col-item" span={2}>
                        <p className="small-title">ประเภทที่อยู่</p>
                        <div className="filter-button">
                          <ComponentContactSortIcon
                            handleSort={handleSort}
                            sortBy={enumSortBy.ASC}
                            sortField={enumContactSortFields.POINTS}
                            searchParams={params}
                          />
                          <ComponentContactSortIcon
                            handleSort={handleSort}
                            sortBy={enumSortBy.DESC}
                            sortField={enumContactSortFields.POINTS}
                            searchParams={params}
                          />
                        </div>
                      </Col>
                      <Col className="col-item" span={5}>
                        <p className="small-title">ที่อยู่</p>
                        <div className="filter-button">
                          <ComponentContactSortIcon
                            handleSort={handleSort}
                            sortBy={enumSortBy.ASC}
                            sortField={enumContactSortFields.CREATED_AT}
                            searchParams={params}
                          />
                          <ComponentContactSortIcon
                            handleSort={handleSort}
                            sortBy={enumSortBy.DESC}
                            sortField={enumContactSortFields.CREATED_AT}
                            searchParams={params}
                          />
                        </div>
                      </Col>
                      <Col className="col-item" span={3}>
                        <p className="small-title">จำนวนบิลคงค้างค่าขยะ</p>
                        <div className="filter-button">
                          <ComponentContactSortIcon
                            handleSort={handleSort}
                            sortBy={enumSortBy.ASC}
                            sortField={enumContactSortFields.CREATED_AT}
                            searchParams={params}
                          />
                          <ComponentContactSortIcon
                            handleSort={handleSort}
                            sortBy={enumSortBy.DESC}
                            sortField={enumContactSortFields.CREATED_AT}
                            searchParams={params}
                          />
                        </div>
                      </Col>
                      <Col className="col-item" span={3}>
                        <p className="small-title">จำนวนรวมค่าขยะ (บาท)</p>
                        <div className="filter-button">
                          <ComponentContactSortIcon
                            handleSort={handleSort}
                            sortBy={enumSortBy.ASC}
                            sortField={enumContactSortFields.CREATED_AT}
                            searchParams={params}
                          />
                          <ComponentContactSortIcon
                            handleSort={handleSort}
                            sortBy={enumSortBy.DESC}
                            sortField={enumContactSortFields.CREATED_AT}
                            searchParams={params}
                          />
                        </div>
                      </Col>
                      <Col className="col-item" span={3}>
                        <p className="small-title">สถานะการจ่ายเงิน</p>
                        <div className="filter-button">
                          <ComponentContactSortIcon
                            handleSort={handleSort}
                            sortBy={enumSortBy.ASC}
                            sortField={enumContactSortFields.CREATED_AT}
                            searchParams={params}
                          />
                          <ComponentContactSortIcon
                            handleSort={handleSort}
                            sortBy={enumSortBy.DESC}
                            sortField={enumContactSortFields.CREATED_AT}
                            searchParams={params}
                          />
                        </div>
                      </Col>
                      <Col className="col-item" span={3}>
                        {/* <p>{t("page.news_manage")}</p> */}
                      </Col>
                    </Row>
                  </div>
                  <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                  >
                    <SortableContext
                      items={news.map((item) => JSON.stringify(item))}
                      strategy={rectSortingStrategy}
                    >
                      {billList && billList.length > 0 ? (
                        <div className="data-table">
                          {billList.map((item, index) => (
                            <ComponentRowBill
                              key={index}
                              data={item}
                              itemJson={JSON.stringify(item)}
                              handleEdit={updateNews}
                              callbackGetList={callbackGetList}
                              index={index}
                              areaList={areaList}
                              openDetailModal={clickDetailModal}
                              billStatus={status}
                            />
                          ))}
                          <div
                            style={{ width: "100%", height: 10 }}
                          ></div>
                        </div>
                      ) : (
                        <ComponentEmptyData />
                      )}
                    </SortableContext>
                  </DndContext>
                </div>
              </div>
            </ScrollContainer>
          </div>
        </div>
        <div className="page-bottom">
          {total > 0 && (
            <div className="pagination">
              <Pagination
                defaultCurrent={INITIAL_LIST_PARAMS.page || 1}
                pageSize={PAGINATION}
                total={total}
                onChange={handlePagination}
                current={pagination.xpage}
              />
            </div>
          )}
        </div>
      </div>
      <Modal
        visible={openModal}
        closable={false}
        footer={null}
        width={550}
        onCancel={() => {
          setOpenModal(false);
          setCreateStatus(0);
        }}
      >

        <StyledModal>
          <div className="close-icon"
            onClick={() => {
              setOpenModal(false);
              setCreateStatus(0);
            }}
          >
            <CloseOutlined />
          </div>
          <StyledButton>
            <p>เลือกประเภทการสร้างรายการชำระค่าขยะ</p>
            <Button className={`auto-btn ${createStatus === 1 ? "select" : ""}`}
              onClick={() => setCreateStatus(1)}
            // onClick={() => history.push(PATH_TRASH_FEE_BILL_AUTO_CREATE)}
            >
              {createStatus === 1 && <CheckOutlined />} ระบบคำนวนค่าขยะอัตโนมัติ
            </Button>
            <Button className={`auto-btn ${createStatus === 2 ? "select" : ""}`}
              onClick={() => setCreateStatus(2)}
            // onClick={() => history.push(PATH_TRASH_FEE_BILL_AUTO_CREATE)}
            >
              {createStatus === 2 && <CheckOutlined />} นำเข้าเอกสารชำระค่าขยะ
            </Button>
            <Button className={`auto-btn ${createStatus === 3 ? "select" : ""}`}
              onClick={() => setCreateStatus(3)}
            // onClick={() => history.push(PATH_TRASH_FEE_BILL_MANUAL_CREATE)}
            >
              {createStatus === 3 && <CheckOutlined />} ระบุข้อมูลเองรายลูกหนี้
            </Button>

            <Button className={`confirm-create ${createStatus === 0 ? "disable" : ""}`}
              // onClick={() => setCreateStatus(3)}
              onClick={confirmCreate}
            >
              ตกลง
            </Button>
          </StyledButton>
        </StyledModal>

      </Modal>
      <Modal
        visible={cashModal}
        closable={false}
        footer={null}
        width={400}
        onCancel={() => setOpenModal(false)}
      >
        <StyledModal>
          <div className="header-section">
            <div className="header">
              ยืนยันการชำระเงิน (เงินสด)
            </div>
            <div className="sub-header">
              โปรดยืนยันการชำระ
            </div>
          </div>
          <br />
          <Row gutter={16}>
            <Col md={12}>
              <StyledCancelButton
                type="sub"
                text="ยกเลิก"
                style={{
                  lineHeight: "0px",
                  width: "160px",
                  height: "49px",
                  color: "#29304D",
                  borderRadius: "4px",
                  float: 'right',
                  fontWeight: "500",
                  fontSize: "16px",
                }}
                onClick={closeStatusModal}
                htmlType="button"
              />
            </Col>
            <Col md={12}>
              <StyledCancelButton
                type="sub"
                text="ยืนยัน"
                style={{
                  lineHeight: "0px",
                  width: "160px",
                  height: "49px",
                  borderRadius: "4px",
                  fontWeight: "500",
                  fontSize: "16px",
                  color: "#FFFFFF",
                  background: "#0D6EFD",
                }}
                htmlType="button"
                onClick={confirmPayCash}
              />
            </Col>
          </Row>
        </StyledModal>
      </Modal>
      <Modal
        visible={cancleModal}
        closable={false}
        footer={null}
        width={400}
        onCancel={() => setOpenModal(false)}
      >
        <StyledModal>
          <div className="header-section">
            <div className="header">
              ยืนยันยกเลิกการชำระ
            </div>
            <div className="sub-header">
              โปรดยืนยันการชำระ
            </div>
          </div>
          <br />
          <Row gutter={16}>
            <Col md={12}>
              <StyledCancelButton
                type="sub"
                text="ยกเลิก"
                style={{
                  lineHeight: "0px",
                  width: "160px",
                  height: "49px",
                  color: "#29304D",
                  borderRadius: "4px",
                  float: 'right',
                  fontWeight: "500",
                  fontSize: "16px",
                }}
                onClick={closeStatusModal}
                htmlType="button"
              />
            </Col>
            <Col md={12}>
              <StyledCancelButton
                type="sub"
                text="ยืนยัน"
                style={{
                  lineHeight: "0px",
                  width: "160px",
                  height: "49px",
                  borderRadius: "4px",
                  fontWeight: "500",
                  fontSize: "16px",
                  color: "#FFFFFF",
                  background: "#0D6EFD",
                }}
                onClick={confirmCancleStatus}
                // onClick={() => history.push(PATH_TRASH_FEE_BILL_AUTO_CREATE)}
                htmlType="button"
              />
            </Col>
          </Row>
        </StyledModal>
      </Modal>
      <ModuleBillListModal
        openModal={openDetailModal}
        selectHouse={selectHouse}
        closeModal={closeDetailModal}
        payBillByCash={payBillByCash}
        toggleCancleStatus={toggleCancleStatus}
      />
    </StyledTable>
  );
};



const StyledModal = styled.div`
    .close-icon {
      font-size: 20px;
      position: absolute;
      right: 15px;
      top: 15px;
      cursor: pointer;
    }
    .title {
        font-weight: 700;
        font-size: 25px;
        line-height: 38px;
        text-align: center;
        color: #000000;
        margin-bottom: 0px;
    }
    .sub-title {
        font-size: 14px;
        line-height: 21px;
        color: #8A959E;
        text-align: center;
    }
    .grid-three{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 20px;
        .item {
            .item-img {
                margin-top: 10px;
                width: 230px;
            }
        }
    }
    .header-section {
      .header {
        font-weight: 600;
        font-size: 25px;
        line-height: 38px;
        text-align: center;
        color: #29304D;
      }
      .sub-header {
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #8A959E;
        margin-bottom: 10px;
      }
    }
`;

const StyledButton = styled.div`
  font-size: 25px;
  text-align: center;
  p{
    font-weight: 500;
    font-size: 25px;
    line-height: 38px;
    color: #29304D;
    text-align: center;
  }
  .auto-btn{
    font-size: 20px;
    line-height: 0px;
    height: 72px;
    width: 100%;
    color: #0D6EFD;
    border-color: #0D6EFD;
    border-radius: 16px;
    margin-bottom: 10px;
  }
  .select {
    color: white;
    border-color: #0D6EFD;
    background-color: #0D6EFD;
  }
  .confirm-create {
    width: 170px;
    height: 49px;
    background: #0D6EFD;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-top: 10px;
  }
  .disable {
    /* background: #f2f2f2;
    color: black; */
  }
`;