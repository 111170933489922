export const UploadFileIcon = () => {
    return (
        <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask
                id="mask0_1534_1766"
                maskUnits="userSpaceOnUse"
                x="0"
                y="5"
                width="16"
                height="12"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 5.9353H16V16.9307H0V5.9353Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_1534_1766)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.4522 16.9307H3.54807C1.59203 16.9307 0 15.3395 0 13.3827V9.48257C0 7.52653 1.59203 5.9353 3.54807 5.9353H4.29449C4.62569 5.9353 4.8945 6.20411 4.8945 6.53531C4.8945 6.86652 4.62569 7.13533 4.29449 7.13533H3.54807C2.25285 7.13533 1.20002 8.18815 1.20002 9.48257V13.3827C1.20002 14.6779 2.25285 15.7307 3.54807 15.7307H12.4522C13.7467 15.7307 14.8003 14.6779 14.8003 13.3827V9.47537C14.8003 8.18495 13.7507 7.13533 12.4611 7.13533H11.7066C11.3754 7.13533 11.1066 6.86652 11.1066 6.53531C11.1066 6.20411 11.3754 5.9353 11.7066 5.9353H12.4611C14.4123 5.9353 16.0003 7.52333 16.0003 9.47537V13.3827C16.0003 15.3395 14.4083 16.9307 12.4522 16.9307"
                    fill="white"
                />
            </g>
            <mask id="mask1_1534_1766" maskUnits="userSpaceOnUse" x="7" y="0" width="2" height="12">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.3999 0.5H8.59993V11.3329H7.3999V0.5Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask1_1534_1766)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.99991 11.333C7.66871 11.333 7.3999 11.0642 7.3999 10.733V1.10001C7.3999 0.768805 7.66871 0.5 7.99991 0.5C8.33112 0.5 8.59993 0.768805 8.59993 1.10001V10.733C8.59993 11.0642 8.33112 11.333 7.99991 11.333"
                    fill="white"
                />
            </g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.66769 4.04304C5.51488 4.04304 5.36128 3.98464 5.24448 3.86784C5.01007 3.63423 5.00847 3.25502 5.24288 3.01982L7.57492 0.677372C7.79973 0.450967 8.19974 0.450967 8.42454 0.677372L10.7574 3.01982C10.991 3.25502 10.9902 3.63423 10.7558 3.86784C10.5206 4.10144 10.1414 4.10144 9.90777 3.86624L7.99973 1.951L6.09249 3.86624C5.97569 3.98464 5.82129 4.04304 5.66769 4.04304"
                fill="white"
            />
        </svg>
    );
};
