import { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
    IconExport,
    SharedButtonDefault,
    StyledCard,
    Users,
    ComponentContactSortIcon,
    IconArrowUp,
    StyledInfoTable,
    IconCopy,
} from "@components";
import { IProfileReward } from "@interfaces";
import { Row, Col, Tooltip } from "antd";
import styled from "styled-components";
import { enumSortBy, enumContactSortFields, dateFormatHM } from "@configs";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectContact } from "@redux";
import moment from "moment";
import { copyText } from "@utils";
interface IProps {
    profileRewrads: IProfileReward[] | [];
}

export const ComponentRewardInfo = memo((props: IProps) => {
    const { t } = useTranslation();
    const search_params = useSelector(selectContact).paramsContact;
    const [params, setParams] = useState(search_params);
    const [selectedProfile, setSelectedProfile] = useState<IProfileReward>();
    const { profileRewrads } = props;
    const handleSort = (sortField: enumContactSortFields, sortBy: enumSortBy) => {
        const newSortParams = { ...params, sortField, sortBy };
        setParams(newSortParams);
    };
    const [isCopied, setIsCopied] = useState(false);

    const handleCopyText = useCallback((params) => {
        copyText(params, () => showTooltipCopy());
    }, []);
    const showTooltipCopy = () => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 5000);
    };

    return (
        <StyledInfoTable>
            <div className="page-body">
                <div className="left-side">
                    <div className="card-wrap">
                        <StyledInfo>
                            <div>
                                <h5>
                                    <Users color="#130F26" /> {t("page.total_number_participants")}
                                </h5>
                            </div>
                            <div>
                                <p className="count">
                                    <span className="number eng">{profileRewrads.length}</span>
                                    <span className="base">{`/ ${"-"} คน`}</span>
                                </p>
                            </div>
                        </StyledInfo>
                    </div>
                    <div className="card-wrap">
                        <StyledCard>
                            <header>
                                <h5>{t("page.total_number_claim")}</h5>
                                <SharedButtonDefault
                                    style={{
                                        color: "white",
                                        background: "#0D6EFD",
                                        fontWeight: 600,
                                        border: 0,
                                        padding: "20px 32px",
                                        height: "49px",
                                    }}
                                    customIcon={<IconExport />}
                                    text={t("page.export")}
                                    type="default"
                                    size="default"
                                    className="default-btn"
                                />
                            </header>
                            <div className="table-header">
                                <Row
                                    gutter={10}
                                    type="flex"
                                    justify="space-between"
                                    align="middle"
                                    className="header-row"
                                >
                                    <Col className="col-item" span={6}>
                                        <p>{t("page.number")}</p>
                                        <div className="filter-button">
                                            <ComponentContactSortIcon
                                                handleSort={handleSort}
                                                sortBy={enumSortBy.ASC}
                                                sortField={enumContactSortFields.FULL_NAME}
                                                searchParams={params}
                                            />
                                            <ComponentContactSortIcon
                                                handleSort={handleSort}
                                                sortBy={enumSortBy.DESC}
                                                sortField={enumContactSortFields.FULL_NAME}
                                                searchParams={params}
                                            />
                                        </div>
                                    </Col>
                                    <Col className="col-item" span={6}>
                                        <p>{t("page.list_of_participants")}</p>
                                        <div className="filter-button">
                                            <ComponentContactSortIcon
                                                handleSort={handleSort}
                                                sortBy={enumSortBy.ASC}
                                                sortField={enumContactSortFields.FULL_NAME}
                                                searchParams={params}
                                            />
                                            <ComponentContactSortIcon
                                                handleSort={handleSort}
                                                sortBy={enumSortBy.DESC}
                                                sortField={enumContactSortFields.FULL_NAME}
                                                searchParams={params}
                                            />
                                        </div>
                                    </Col>
                                    <Col className="col-item" span={6}>
                                        <p>{t("page.requested_date_time")}</p>
                                        <div className="filter-button">
                                            <ComponentContactSortIcon
                                                handleSort={handleSort}
                                                sortBy={enumSortBy.ASC}
                                                sortField={enumContactSortFields.FULL_NAME}
                                                searchParams={params}
                                            />
                                            <ComponentContactSortIcon
                                                handleSort={handleSort}
                                                sortBy={enumSortBy.DESC}
                                                sortField={enumContactSortFields.FULL_NAME}
                                                searchParams={params}
                                            />
                                        </div>
                                    </Col>
                                    <Col className="col-item" span={6}>
                                        <p>{t("page.view_applicant_details")}</p>
                                        <div className="filter-button">
                                            <ComponentContactSortIcon
                                                handleSort={handleSort}
                                                sortBy={enumSortBy.ASC}
                                                sortField={enumContactSortFields.FULL_NAME}
                                                searchParams={params}
                                            />
                                            <ComponentContactSortIcon
                                                handleSort={handleSort}
                                                sortBy={enumSortBy.DESC}
                                                sortField={enumContactSortFields.FULL_NAME}
                                                searchParams={params}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="table-body">
                                {profileRewrads.map((item, index) => (
                                    <Row key={index} onClick={() => setSelectedProfile(item)}>
                                        <Col className="col-item" span={6}>
                                            <p className="eng">{index + 1}</p>
                                        </Col>
                                        <Col className="col-item row-title" span={6}>
                                            <p>{item?.profileData?.name}</p>
                                        </Col>
                                        <Col className="col-item" span={6}>
                                            <p className="eng">
                                                {moment(item.created_date).format(dateFormatHM)}
                                            </p>
                                        </Col>
                                        <Col className="col-item icon" span={6}>
                                            <IconArrowUp />
                                        </Col>
                                    </Row>
                                ))}
                            </div>
                        </StyledCard>
                    </div>
                </div>
                <div className="right-side">
                    {selectedProfile && (
                        <div className="detail-info">
                            <div className="detail-title">{t("page.social_appointment_info")}</div>
                            <div className="info-layout">
                                <div className="left">
                                    <div className="item">
                                        <div className="label">{t("page.surname")}</div>
                                        <div className="value">
                                            {selectedProfile?.profileData?.name || "-"}
                                            <Tooltip
                                                title={
                                                    isCopied
                                                        ? t("message.copied")
                                                        : t("message.copy")
                                                }
                                            >
                                                <div
                                                    className="copy"
                                                    onClick={() =>
                                                        handleCopyText(
                                                            selectedProfile?.profileData?.name ||
                                                                "-"
                                                        )
                                                    }
                                                >
                                                    <IconCopy />
                                                </div>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="label">{t("page.gender")}</div>
                                        <div className="value">{"-"}</div>
                                    </div>
                                    <div className="item">
                                        <div className="label">{t("page.telephone")}</div>
                                        <div className="value">
                                            {selectedProfile?.profileData?.username}
                                        </div>
                                    </div>
                                </div>
                                <div className="right">
                                    <div className="item">
                                        <div className="label">{t("page.id_card_number")}</div>
                                        <div className="value">{"-"}</div>
                                    </div>
                                    <div className="item">
                                        <div className="label">{t("page.age")}</div>
                                        <div className="value">{"-"}</div>
                                    </div>
                                    <div className="item">
                                        <div className="label">{t("page.email")}</div>
                                        <div className="value">{"-"}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="label">{t("page.address")}</div>
                                <div className="value">{"-"}</div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </StyledInfoTable>
    );
});
const StyledInfo = styled.header`
    display: flex;
    justify-content: space-between;
    padding: 20px 32px;
    border-radius: 4px;
    background-color: white;
    max-width: 760px;
    h5 {
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 33px;
        color: #29304d;
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 33px;
        color: #29304d;
    }
    .count {
        display: flex;
        justify-content: end;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        column-gap: 10px;
        .number {
            font-style: normal;
            font-weight: 600;
            font-size: 35px;
            line-height: 53px;
            color: #29304d;
        }
        .base {
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            color: #29304d;
        }
    }
    .label {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #29304d;
    }
    .small {
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: #8a959e;
        padding: 0 6px;
    }
`;
