import { Drawer, Avatar, Menu } from "antd";

import { useDispatch, useSelector } from "react-redux";

import { LogoutOutline, StyledProfileSidebar } from "@components";

import {
    selectApp,
    setProfileDrawer,
    useAppSelector,
    selectTheme,
    selectAuth,
    logout,
} from "@redux";
import { useHistory } from "react-router";
import { PATH_LOGIN } from "@configs";
import { useTranslation } from "react-i18next";

export const ProfileSidebar = () => {
    const dispatch = useDispatch();

    const app = useAppSelector(selectApp);
    const Theme = useSelector(selectTheme);
    const { auth } = useSelector(selectAuth);
    const history = useHistory();
    const urlImage = Theme.logo;
    const { t } = useTranslation();
    const handleSignOut = () => {
        dispatch(logout());
        setTimeout(() => {
            history.push(PATH_LOGIN);
        }, 1000);
    };

    return (
        <>
            <Drawer
                closable={false}
                width={275}
                placement={"right"}
                onClose={() => dispatch(setProfileDrawer(!app.profileDrawer))}
                visible={app.profileDrawer}
                // visible={true}
                className="sidebar-drawer"
            >
                <StyledProfileSidebar>
                    <div
                        style={{
                            overflow: "hidden",
                            flex: "1 1 auto",
                            flexDirection: "column",
                            display: "flex",
                            height: "100vh",
                        }}
                    >
                        {/* <div className="profile-header">
                            <div className="profile-image">
                                <Avatar
                                    size={84}
                                    className="avatar"
                                    src={userInfo?.avatar || "/user-avatar.png"}
                                />
                            </div>
                            <div className="profile-info">
                                <p className="profile-name">{userInfo?.fullName}</p>
                                <p className="profile-role">{userInfo?.role?.name}</p>
                                <p className="profile-email">{userInfo?.email}</p>
                            </div>
                        </div> */}

                        {/* <Divider className="profile-divider" type="horizontal" /> */}
                        <div className="sidebar-layout">
                            <Avatar className="future-avatar" src={urlImage || ""} />
                            <div className="email-layout">
                                <h4>{auth?.admin_data?.name}</h4>
                            </div>
                        </div>
                        {/* <Divider className="profile-divider" type="horizontal" /> */}
                        <Menu className="profile-layout">
                            <Menu.Item
                                style={{
                                    fontWeight: 400,
                                    fontSize: "16px",
                                    cursor: "pointer",
                                    color: "#F22F46",
                                }}
                                onClick={handleSignOut}
                            >
                                <div className="item-text">
                                    <LogoutOutline />
                                    <span>{t("page.manage_account.logout")}</span>
                                </div>
                            </Menu.Item>
                        </Menu>
                    </div>
                </StyledProfileSidebar>
            </Drawer>
        </>
    );
};
