import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StyledNewDetail } from "@components";
import { IPermission } from "@interfaces";

import { useNotify, usePermission } from "@utils";
import { permissionAPI } from "@api";
import { setLoading, selectAuth, selectOrg } from "@redux";
import { ModulePermissionForm } from "src/Modules/permission";
import { useParams, useHistory } from "react-router";
import { PATH_PERMISSIONS_CREATE } from "@configs";

export const PagePermissionCreate = () => {
    const org_id = useSelector(selectAuth).auth?.organization_data?._id;
    const divisions = useSelector(selectOrg).division;
    const isPermission = useSelector(selectAuth).permission;
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch();
    const { warning } = useNotify();
    const history = useHistory();
    const [permission, setPermission] = useState<IPermission>();
    const pathName = history.location.pathname;
    usePermission({ permission: isPermission?.permission_list.organization.is_manage_permission });
    useEffect(() => {
        fetchPermissionTemplate();
    }, []);

    const fetchPermissionTemplate = async () => {
        try {
            dispatch(setLoading(true));
            if (pathName === PATH_PERMISSIONS_CREATE) {
                const response = await permissionAPI.getPermissionTemplate({
                    organization_id: org_id || "",
                });
                const data: IPermission = response.data.result;
                setPermission(data);
            } else {
                const response = await permissionAPI.getPermission({
                    organization_id: org_id || "",
                    permission_id: id,
                });
                const data: IPermission = response.data.result;
                setPermission(data);
            }
            dispatch(setLoading(false));
        } catch (err: any) {
            if (err) {
                warning(err.msg);
                dispatch(setLoading(false));
            }
        } finally {
            dispatch(setLoading(false));
        }
    };

    return (
        <StyledNewDetail>
            {<ModulePermissionForm permission={permission} divisions={divisions} />}
        </StyledNewDetail>
    );
};
