import { Modal } from "antd";
import { ReactNode } from "react";
interface IProps {
    title?: string | JSX.Element;
    isVisible: boolean;
    onCancel: () => void;
    children: ReactNode;
    width: string | number;
    className?: string;
    onOk?: () => void;
    style?: React.CSSProperties;
}
export const ComponentModal = (props: IProps) => {
    const { title, isVisible, width, onOk, onCancel, children, className, style } = props;
    return (
        <Modal
            title={title || ""}
            visible={isVisible}
            onCancel={onCancel}
            width={width}
            footer={null}
            onOk={onOk}
            style={style}
            className={className || ""}
        >
            {children}
        </Modal>
    );
};
