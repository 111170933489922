import {
    ComponentContactSortIcon,
    ComponentEmptyData,
    ComponentRowBidding,
    ComponentRowNews,
    StyledTable,
    StyledTableButtonTop,
} from "@components";
import {
    enumContactSortFields,
    enumSortBy,
    PAGE_START,
    PAGINATION,
    PATH_CREATE_PURCHASE,
} from "@configs";
import {
    closestCenter,
    DndContext,
    KeyboardSensor,
    PointerSensor,
    TouchSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import {
    arrayMove,
    rectSortingStrategy,
    SortableContext,
    sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import { IBidding, IContactListParams, INews, IPaginationNext } from "@interfaces";
import { getCategories, selectAuth, selectContact, selectOrg, setLoading } from "@redux";
import { useNotify, usePermission } from "@utils";
import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { newsAPI } from "src/API/news";
import { biddingAPI } from "@api";

const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: PAGINATION,
};

export const PurchaseListPage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const categories = useSelector(selectOrg).category;
    const org_id = useSelector(selectAuth).auth?.organization_data?._id;
    const permission = useSelector(selectAuth).permission;
    const search_params = useSelector(selectContact).paramsContact;
    const [params, setParams] = useState<IContactListParams>(search_params);

    const [pagination, setPagination] = useState<IPaginationNext>(INITAL_PAGE);
    const [textSearch, setTextSearch] = useState<string>("");
    const [dateFrom, setDateFrom] = useState<string>("");
    const [dateTo, setDateTo] = useState<string>("");

    const [news, setNews] = useState<IBidding[]>([]);
    const [total, setTotal] = useState<number>(0);
    const { success, error } = useNotify();
    usePermission({ permission: permission?.permission_list.bidding.is_manage_bidding });
    useEffect(() => {
        fetchNewsCategory();
        fetchNews(pagination, textSearch, dateFrom, dateTo);
    }, [pagination, textSearch, dateFrom, dateTo]);

    const handleSort = (sortField: enumContactSortFields, sortBy: enumSortBy) => {
        const newSortParams = { ...params, sortField, sortBy };
        setParams(newSortParams);
        // fetchContacts(newSortParams);
    };
    const fetchNewsCategory = async () => {
        await dispatch(getCategories({ organization_id: org_id || "" }));
    };

    const fetchNews = async (
        pagination: IPaginationNext,
        textSearch: string,
        dateFrom: string,
        dateTo: string
    ) => {
        dispatch(setLoading(true));
        try {
            const payload = {
                bidding_type: "PURCHASE",
                organization_id: org_id || "",
                text_search: textSearch,
            };
            // const response = await newsAPI.getList({ ...payload, ...pagination });
            const response = await biddingAPI.getList({ ...payload, ...pagination });
            setNews(response.data.result);
            setTotal(response.data.total);
            // success(response.data.msg);
        } catch (error: any) {
            // error(error.data.msg);
            // console.log(error);
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const callbackGetList = () => {
        // fetchNews(pagination, textSearch, dateFrom, dateTo);
    };

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
        useSensor(TouchSensor)
    );
    const editNews = (userId: string, points: number) => {
        console.log("update fun");
    };

    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            const swapItem = (items: IBidding[]) => {
                const oldIndex = items.findIndex((item) => JSON.stringify(item) === active.id);
                const newIndex = items.findIndex((item) => JSON.stringify(item) === over.id);
                return arrayMove(items, oldIndex, newIndex);
            };
            setNews(swapItem);
        }
    };

    return (
        <StyledTable>
            <div className="page-layout">
                <div className="page-header-layout">
                    <div className="page-header">
                        <div>
                            <h3>{t("page.bidding.view_purchase_headline")} </h3>
                        </div>
                        <div className="flex-header">
                            <StyledTableButtonTop
                                type="danger"
                                size="default"
                                text={t("page.bidding.add_purchase")}
                                onClick={() => history.push(PATH_CREATE_PURCHASE)}
                                className="add-btn"
                                icon="plus"
                            />
                        </div>
                    </div>
                    <div className="search-layout">
                        <div className="status-filter"></div>
                        <div className="filter"></div>
                        {/* <Input
						className="search-input visible-ms"
						// style={{ height: 42, maxWidth: 210 }}
						suffix={<Search style={{ paddingLeft: 4 }} size={20} />}
						name="search"
						defaultValue={params.search}
						placeholder={t("page.search_phone_number")}
						onChange={handleSearch}
					/> */}
                    </div>
                </div>
                <div className="page-table-layout">
                    <div className="table">
                        <ScrollContainer vertical={false}>
                            <div className="table_ground">
                                <div className="table-body">
                                    <div className="table-header">
                                        <Row
                                            gutter={24}
                                            type="flex"
                                            justify="space-between"
                                            align="middle"
                                            className="header-row"
                                        >
                                            <Col className="col-item" span={5}>
                                                <p>{t("page.bidding.bidding_headline")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.FULL_NAME}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.FULL_NAME}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={3}>
                                                <p>{t("page.bidding.bidding_join_number")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.TEL}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.TEL}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={3}>
                                                <p>{t("page.bidding.bidding_join_date")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={3}>
                                                <p>{t("page.bidding.bidding_create_date")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={3}>
                                                <p>{t("page.active")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.CREATED_AT}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.CREATED_AT}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={3}>
                                                <p>{t("page.news_manage")}</p>
                                            </Col>
                                        </Row>
                                    </div>
                                    <DndContext
                                        sensors={sensors}
                                        collisionDetection={closestCenter}
                                        onDragEnd={handleDragEnd}
                                    >
                                        <SortableContext
                                            items={news.map((item) => JSON.stringify(item))}
                                            strategy={rectSortingStrategy}
                                        >
                                            {news && news.length > 0 ? (
                                                <div className="data-table">
                                                    {news.map((item, index) => (
                                                        <ComponentRowBidding
                                                            key={index}
                                                            data={item}
                                                            itemJson={JSON.stringify(item)}
                                                            categories={categories}
                                                            handleEdit={editNews}
                                                            callbackGetList={callbackGetList}
                                                            index={index}
                                                        />
                                                    ))}
                                                    <div
                                                        style={{ width: "100%", height: 10 }}
                                                    ></div>
                                                </div>
                                            ) : (
                                                <ComponentEmptyData />
                                            )}
                                        </SortableContext>
                                    </DndContext>
                                </div>
                            </div>
                        </ScrollContainer>
                    </div>
                </div>
            </div>
        </StyledTable>
    );
};
