import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNotify, usePermission } from "@utils";
import { homeMenuAPI } from "@api";
import { setLoading, selectAuth } from "@redux";
import { StyledBiddingDetail } from "@components";
import { ModuleHomeMenuForm } from "src/Modules/homeMenu/Form";
import { IHomeOrganization } from "src/Interfaces/homeMenu";

export const HomeMenuPage = () => {
    const org_id = useSelector(selectAuth).auth?.organization_data?._id;
    const permission = useSelector(selectAuth).permission;
    const dispatch = useDispatch();
    const { warning } = useNotify();
    const [organization, setOrganization] = useState<IHomeOrganization>();
    usePermission({ permission: permission?.permission_list.home_information.is_manage });
    useEffect(() => {
        fetchHomeMenu();
    }, []);
    const fetchHomeMenu = async () => {
        try {
            dispatch(setLoading(true));
            const res = await homeMenuAPI.getorganization({
                organization_id: org_id || "",
            });

            const data: IHomeOrganization = res.data.result;
            setOrganization(data);
            dispatch(setLoading(false));
        } catch (err: any) {
            if (err) {
                warning(err.msg);
                dispatch(setLoading(false));
            }
        } finally {
            dispatch(setLoading(false));
        }
    };

    return (
        <StyledBiddingDetail>
            <ModuleHomeMenuForm organization={organization} />
        </StyledBiddingDetail>
    );
};
