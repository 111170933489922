import { IConplaintCategory, INewsCategory } from "@interfaces";
import { useTranslation } from "react-i18next";

interface Iprops {
    categories: INewsCategory[] | IConplaintCategory[];
    filterId: string;
    setFilterId: (params: string) => void;
}
export const ComponentCategoriesFilter = (props: Iprops) => {
    const { categories, filterId, setFilterId } = props;
    const { t } = useTranslation();
    return (
        <div className="status-filter">
            <div
                onClick={() => setFilterId("")}
                className={filterId === "" ? "active filter-elem" : "filter-elem"}
            >
                {t("page.all")}
            </div>
            {categories.map((item, index) => (
                <div
                    key={index}
                    onClick={() => setFilterId(item._id)}
                    className={filterId === item._id ? "active filter-elem" : "filter-elem"}
                >
                    {item.category_name}
                    <span className="count eng">{item.count}</span>
                </div>
            ))}
        </div>
    );
};
