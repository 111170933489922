import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { StyledNewDetail } from "@components";
import { IClaim } from "@interfaces";
import { PATH_CLAIM_CREATE, PATH_CLAIM } from "@configs";
import { useNotify, usePermission } from "@utils";
import { claimAPI } from "@api";
import { setLoading, selectAuth } from "@redux";
import { ModuleClaimForm } from "src/Modules/claim";

export const PageClaimCreate = () => {
    const { id } = useParams<{ id: string }>();
    const org_id = useSelector(selectAuth).auth?.organization_data?._id;
    const permission = useSelector(selectAuth).permission;
    const dispatch = useDispatch();
    const history = useHistory();
    const { warning } = useNotify();
    const { t } = useTranslation();
    // page state
    const [claim, setClaim] = useState<IClaim>();
    // variable.
    const pathName = history.location.pathname;
    usePermission({ permission: permission?.permission_list.claim_reward.is_manage_claim });
    useEffect(() => {
        fetchReward();
    }, []);
    const fetchReward = async () => {
        if (pathName !== PATH_CLAIM_CREATE) {
            dispatch(setLoading(true));
            try {
                const response = await claimAPI.detail({
                    organization_id: org_id || "",
                    claim_id: id,
                });
                const data: IClaim = response.data.result;
                setClaim(data);
                dispatch(setLoading(false));
            } catch (err: any) {
                if (err) {
                    warning(err.msg);
                    dispatch(setLoading(false));
                } else warning(t("message.not_found", { name: t("object._reward") }));
                history.push(PATH_CLAIM);
            } finally {
                dispatch(setLoading(false));
            }
        }
    };
    return <StyledNewDetail>{<ModuleClaimForm claim={claim} />}</StyledNewDetail>;
};
