import { INewsParams, INewsDetailParams, INewsData, IOrganizationId } from "@interfaces";

import axiosClient from "./axiosClient";

export const newsAPI = {
    getList: (params: INewsParams) => {
        const url = "news/getNewss";
        return axiosClient.get(url, { params });
    },
    delete: (params: INewsDetailParams) => {
        const url = `news/deleteNews`;
        return axiosClient.post(url, params);
    },
    uploadImage: (params: FormData) => {
        const url = `uploads/upload_file`;
        return axiosClient.post(url, params);
    },
    duplicate: (id: string) => {
        const url = `contacts/${id}/duplicate`;
        return axiosClient.post(url, { id: id });
    },
    create: (params: INewsData) => {
        const url = "news/createNews";
        return axiosClient.post(url, params);
    },
    update: (params: INewsData) => {
        const url = "news/updateNews";
        return axiosClient.patch(url, params);
    },
    updateName: (id: string, params: any) => {
        const url = `contacts/${id}/update`;
        return axiosClient.put(url, params);
    },
    detail: (params: INewsDetailParams) => {
        const url = `news/getNews`;
        return axiosClient.get(url, { params });
    },
    getNewsEnableCount: (params: IOrganizationId) => {
        const url = `news/getNewsEnableCount`;
        return axiosClient.get(url, { params });
    },
};
