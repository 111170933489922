import { useEffect, memo, useState, useRef } from "react";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Row, Col, Select } from "antd";
import styled from "styled-components";
import { INewsCategoryParams, IService, IDepartment, IUploadFiles, IFileUrl } from "@interfaces";
import {
    SharedCKEditor,
    StyledSubmitButton,
    StyledCancelButton,
    StyledCard,
    SharedInput,
    ComponentServicePreview,
    ShareButton,
    IconEdit,
    ComponentDrawer,
} from "@components";
import { useYup } from "@validations";
import { getCategories, setLoading, selectAuth } from "@redux";
import { ComponentFileUpload, MutlipleModuleUploadImage } from "@modules";
import { showErrorMessage, useNotify } from "@utils";
import {
    enumDrawerPlacement,
    MAX_CHAR,
    PATH_NEWS,
    PATH_SERVICES,
    PATH_SERVICES_CREATE,
    serviceHours,
} from "@configs";
import { orgApi, serviceAPI } from "@api";

interface IProps {
    service: IService | undefined;
    department: IDepartment[] | undefined;
}
const fileInit: IFileUrl = {
    name: "",
    file_url: "",
};
const initData: IService = {
    organization_id: "",
    name: "",
    code: "",
    file_url_list: [{ name: "", file_url: "" }],
    image_url_list: [],
    description: "",
    is_enable: true,
    cut_off_time: {
        value: 1,
        unit: "hours",
    },
    future_days: {
        value: 1,
        unit: "days",
    },
    service_time: {
        value: 45,
        unit: "minutes",
    },
};
const { Option } = Select;
export const ModuleServiceForm = memo((props: IProps) => {
    //hook
    const { YupService } = useYup();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const { error, success } = useNotify();
    const [showModal, setShowModal] = useState<boolean>(false);
    const organization_id = useSelector(selectAuth).auth?.admin_data?.organization_id;
    const searchInputRef = useRef<any | null>(null);
    const [cat, setCat] = useState<string>("");
    const pathName = window.location.pathname;
    const createService = Yup.object().shape(YupService);
    const { service, department } = props;
    const [formData, setFormData] = useState<IService>(initData);
    useEffect(() => {
        if (service) {
            setFormData(service);
        }
    }, [service]);

    const createCategory = async () => {
        dispatch(setLoading(true));
        // if (news?.organization_id === "") return;
        try {
            const payload: INewsCategoryParams = {
                category_name: cat,
                organization_id: organization_id || "",
                is_enable: true,
            };
            const response = await orgApi.createCat(payload);
            success(response.data.msg);
            dispatch(getCategories({ organization_id: organization_id || "" }));
        } catch (err: any) {
            if (err) {
                error(err.msg);
            } else error(t("message.create.fail"));
        } finally {
            dispatch(setLoading(false));
        }
    };
    const handleServiceSubmit = async (values: IService) => {
        dispatch(setLoading(true));
        try {
            if (pathName === PATH_SERVICES_CREATE) {
                const res = await serviceAPI.createService({
                    ...values,
                    ...{ organization_id: organization_id || "" },
                });
                if (res.data.error === "0") {
                    success(res.data.msg);
                    history.push(PATH_SERVICES);
                } else {
                    error(res.data.msg);
                }
            } else {
                const res = await serviceAPI.updateService({
                    ...values,
                    ...{ organization_id: organization_id || "" },
                    ...{ service_id: service?._id },
                });
                if (res.data.error === "1") {
                    error(res.data.msg);
                } else {
                    success(res.data.msg);
                    history.push(PATH_SERVICES);
                }
            }
        } catch (err: any) {
            if (err.response) {
                error(showErrorMessage(err.response));
            } else error(t("message.error"));
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleCancel = () => {
        history.push(PATH_SERVICES);
    };

    return (
        <div className="form-wrap">
            <Formik
                initialValues={formData}
                onSubmit={handleServiceSubmit}
                enableReinitialize={true}
                validationSchema={createService}
            >
                {({ values, touched, handleChange, setFieldValue, handleBlur, errors }) => (
                    <Form>
                        {/* <form onSubmit={handleSubmit}> */}
                        <div className="page-header">
                            <h3>
                                {pathName.includes(PATH_SERVICES_CREATE)
                                    ? `สร้างธุรกรรม`
                                    : `สร้างธุรกรรม`}
                            </h3>
                            <p className="sub-title">{`โปรดระบุรายละเอียดให้ครบถ้วน`}</p>
                        </div>
                        <div className="page-body">
                            <div className="card-wrap">
                                <StyledCard>
                                    <div className="title">{`ตั้งค่าธุรกรรม`}</div>
                                    <SharedInput
                                        label={`ชื่อธุรกรรม*`}
                                        // descLabel={t("page._2_100_characters")}
                                        name="name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.name}
                                        errors={errors.name}
                                        touched={touched.name}
                                        parentClassName="mb-6"
                                        suffixIcon={
                                            <span>{`${values.name.length} / ${MAX_CHAR}`}</span>
                                        }
                                    />
                                    <SharedInput
                                        label={`รหัสธุรกรรม*`}
                                        // descLabel={t("page._2_100_characters")}
                                        name="code"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.code}
                                        errors={errors.code}
                                        touched={touched.code}
                                        parentClassName="mb-6"
                                    />
                                </StyledCard>
                            </div>
                            <div className="card-wrap">
                                <StyledCard>
                                    <div className="title">{`ระยะเวลาการจอง`}</div>
                                    <Row className="select-layout">
                                        <Col md={24}>
                                            <div className="label">{`เปิดจองเวลาก่อนถึงคิวล่วงหน้า`}</div>
                                            <div className="input-field">
                                                <div className="service-time">
                                                    {`${values.service_time.value} ${values.service_time.unit}`}
                                                </div>
                                                <ShareButton
                                                    type="default"
                                                    size="large"
                                                    htmlType="button"
                                                    style={{ width: "131px" }}
                                                    customIcon={<IconEdit />}
                                                    className="ant-btn-primary carryout-btn"
                                                    text={`เพิ่มแบบฟอร์ม`}
                                                    onClick={() => setShowModal(true)}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </StyledCard>
                            </div>
                            <div className="card-wrap">
                                <StyledCard>
                                    <Row gutter={16}>
                                        <Col span={8}>
                                            <SharedInput
                                                label={t("page.headlines")}
                                                // descLabel={t("page._2_100_characters")}
                                                name="title"
                                                onBlur={handleBlur}
                                                onChange={(e) =>
                                                    setFieldValue(
                                                        "cut_off_time.value",
                                                        e.target.value
                                                    )
                                                }
                                                value={values.cut_off_time.value}
                                                parentClassName="mb-6"
                                                type="number"
                                            />
                                        </Col>
                                        <Col span={4}>
                                            {/* <div className="label">{`ตั้งเวลาล่วงหน้า`}</div> */}
                                            <div className="input-field no-label">
                                                <Select
                                                    style={{ width: "100%", height: 48 }}
                                                    placeholder={`เปิดจองเวลาก่อนถึงคิวล่วงหน้า`}
                                                    onChange={(value) =>
                                                        setFieldValue("cut_off_time.unit", value)
                                                    }
                                                    defaultActiveFirstOption={true}
                                                    value={values.cut_off_time.unit}
                                                >
                                                    {["hours", "minutes"].map((item, index) => {
                                                        return (
                                                            <Option key={index} value={item}>
                                                                {item}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                                <div className="error">
                                                    {/* {errors.businessTypeName} */}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col span={8}>
                                            <SharedInput
                                                label={t("page.headlines")}
                                                // descLabel={t("page._2_100_characters")}
                                                name="title"
                                                onBlur={handleBlur}
                                                onChange={(e) =>
                                                    setFieldValue(
                                                        "future_days.value",
                                                        e.target.value
                                                    )
                                                }
                                                value={values.cut_off_time.value}
                                                parentClassName="mb-6"
                                                type="number"
                                            />
                                        </Col>
                                        <Col span={4}>
                                            {/* <div className="label">{t("page.news_type")}</div> */}
                                            <div className="input-field no-label">
                                                <Select
                                                    style={{ width: "100%", height: 48 }}
                                                    placeholder={t("page.news_type")}
                                                    onChange={(value) =>
                                                        setFieldValue("future_days.unit", value)
                                                    }
                                                    defaultActiveFirstOption={true}
                                                    value={values.cut_off_time.unit}
                                                >
                                                    {["hours", "minutes"].map((item, index) => {
                                                        return (
                                                            <Option key={index} value={item}>
                                                                {item}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                                <div className="error">
                                                    {/* {errors.businessTypeName} */}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </StyledCard>
                            </div>
                            <div className="card-wrap">
                                <StyledCard>
                                    <MutlipleModuleUploadImage
                                        images={values.image_url_list}
                                        handleGetImage={(images: string[]) => {
                                            setFieldValue("image_url_list", [...images]);
                                        }}
                                    />
                                </StyledCard>
                            </div>
                            <div className="card-wrap">
                                <StyledCard>
                                    <div className="title">{t("page.descriptions")}</div>
                                    <SharedCKEditor
                                        editor={values.description || ""}
                                        handleChangeEditor={(event: any, editors: any) => {
                                            const data = editors.getData();
                                            setFieldValue("description", data);
                                        }}
                                        name="description"
                                    />
                                </StyledCard>
                            </div>
                            <div className="card-wrap">
                                <StyledCard>
                                    <div className="title">{`แบบฟอร์มการทำธุรกรรม`}</div>
                                    <FieldArray name="file_url_list">
                                        {({ remove, push }) => (
                                            <div>
                                                {values.file_url_list.length > 0 &&
                                                    values.file_url_list.map((item, index) => (
                                                        <div key={index}>
                                                            <ComponentFileUpload
                                                                handleGetFile={(file) => {
                                                                    setFieldValue(
                                                                        `file_url_list[${index}]`,
                                                                        {
                                                                            name: file.key,
                                                                            file_url:
                                                                                file.publicUrl,
                                                                        }
                                                                    );
                                                                }}
                                                                changeName={(value) =>
                                                                    setFieldValue(
                                                                        `file_url_list[${index}].name`,
                                                                        value
                                                                    )
                                                                }
                                                                file={item}
                                                                index={index}
                                                                placeholder={`โปรดระบุชื่อแบบฟอร์ม`}
                                                                onDelete={() => remove(index)}
                                                            />
                                                        </div>
                                                    ))}
                                                <div>
                                                    <ShareButton
                                                        type="default"
                                                        size="large"
                                                        htmlType="button"
                                                        style={{ width: "190px" }}
                                                        icon={"plus"}
                                                        className="ant-btn-primary carryout-btn"
                                                        text={`เพิ่มแบบฟอร์ม`}
                                                        onClick={() => push(fileInit)}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </FieldArray>
                                </StyledCard>
                            </div>
                            <div className="btn-layout">
                                <StyledSubmitButton
                                    type="default"
                                    text={t("page.save")}
                                    htmlType="submit"
                                    // disable={isSubmitting}
                                />
                                <StyledCancelButton
                                    type="sub"
                                    text={t("page.cancel")}
                                    htmlType="button"
                                    onClick={handleCancel}
                                />
                            </div>
                        </div>
                        <ComponentServicePreview service={values} />
                        <ComponentDrawer
                            visible={showModal}
                            placement={enumDrawerPlacement.RIGHT}
                            title={`เพิ่มระยะเวลาการจองคนถัดไป`}
                            handleClose={() => setShowModal(false)}
                        >
                            <StyledElem>
                                {serviceHours.map((item, index) => (
                                    <div
                                        className="elem"
                                        key={index}
                                        onClick={() => {
                                            setFieldValue("service_time", item);
                                            setShowModal(false);
                                        }}
                                    >
                                        <span>{`${item.value} ${item.unit}`}</span>
                                    </div>
                                ))}
                            </StyledElem>
                        </ComponentDrawer>
                    </Form>
                )}
            </Formik>
        </div>
    );
});
const StyledElem = styled.div`
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 8px;
    .elem {
        cursor: pointer;
        height: 49px;
        background: #ffffff;
        border: 0.5px solid #8a959e;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #29304d;
        }
    }
`;
