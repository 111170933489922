import { IPermissionsParams, IPermissionParams, IPermission, IOrganizationId } from "@interfaces";

import axiosClient from "./axiosClient";

export const permissionAPI = {
    getPermissions: (params: IPermissionsParams) => {
        const url = "permission/getPermissions";
        return axiosClient.get(url, { params });
    },
    getPermission: (params: IPermissionParams) => {
        const url = `permission/getPermission`;
        return axiosClient.get(url, { params });
    },
    createPermission: (params: IPermission) => {
        const url = "permission/createPermission";
        return axiosClient.post(url, params);
    },
    updatePermission: (params: IPermission) => {
        const url = "permission/updatePermission";
        return axiosClient.patch(url, params);
    },
    deletePermission: (params: IPermissionParams) => {
        const url = `permission/deletePermission`;
        return axiosClient.post(url, params);
    },
    getPermissionTemplate: (params: IOrganizationId) => {
        const url = `permission/getPermissionTemplate`;
        return axiosClient.get(url, { params });
    },
};
