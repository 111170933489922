import { Layout, Menu } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { StyledMenuHeader, User } from "@components";
import { IconSetting } from "@components";
import {
    PATH_COMPLAINT,
    PATH_COMPLAINT_CREATE,
    PATH_HOME_NEW,
    PATH_HOME,
    PATH_NEWS,
    PATH_NEWS_ALL,
    PATH_NEWS_HIGHLIGHT,
    PATH_CREATE_NEWS,
    PATH_ACTIVITY,
    PATH_ACTIVITY_CREATE,
    PATH_BOOKING,
    PATH_SERVICES,
    PATH_DEPARTMENT,
    PATH_SERVICES_CREATE,
    PATH_DEPARTMENT_CREATE,
    PATH_PRIVILEGES,
    PATH_CLAIM,
    PATH_REDEEM_PRIVILEGES,
    PATH_PRIVILEGES_CREATE,
    PATH_INTEGRATION_SETTINGS,
    PATH_SETTINGS,
    PATH_THEMES,
    PATH_ADMIN,
    PATH_PERMISSIONS,
    PATH_COMPLAINT_CATEGORY,
    PATH_COMPLAINT_DIVISIONS,
    PATH_PERMISSIONS_CREATE,
    PATH_ADMIN_CREATE,
    PATH_BIDDING,
    PATH_BIDDING_RESULT_LIST,
    PATH_BIDDING_PURCHASE_LIST,
    PATH_BIDDING_EMPLOY_LIST,
    PATH_CREATE_BIDDING_RESULT,
    PATH_CREATE_PURCHASE,
    PATH_CREATE_EMPLOY,
    PATH_HOME_MENU,
    PATH_HOME_ABOUT,
    PATH_HOME_ORG,
    PATH_HOME_DECS_REPORT,
    PATH_HOME_SER_INFO,
    PATH_TRASH_FEE,
} from "@configs";
const { Header } = Layout;

export const ComponentMenuHeader = () => {
    //page hook
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    //redux state
    const pathname = location.pathname;
    const handleClickMenu = (e) => {
        history.push(e.key);
    };

    const isNewsRoutes = (route: string) =>
        route === PATH_NEWS ||
        route === PATH_NEWS_ALL ||
        route === PATH_NEWS_HIGHLIGHT ||
        route === PATH_CREATE_NEWS ||
        route.includes(PATH_NEWS);
    const isComplaintRoutes = (route: string) =>
        route === PATH_COMPLAINT ||
        route === PATH_COMPLAINT_CREATE ||
        route === PATH_COMPLAINT_CATEGORY ||
        route === PATH_COMPLAINT_DIVISIONS ||
        route.includes(PATH_COMPLAINT);
    const isActivityRoutes = (route: string) =>
        route === PATH_ACTIVITY || route === PATH_ACTIVITY_CREATE || route.includes(PATH_ACTIVITY);
    const isBooking = (route: string) =>
        route === PATH_BOOKING ||
        route === PATH_SERVICES ||
        route === PATH_DEPARTMENT ||
        route === PATH_SERVICES_CREATE ||
        route === PATH_DEPARTMENT_CREATE ||
        route.includes(PATH_DEPARTMENT) ||
        route.includes(PATH_SERVICES);
    const isRewardClaim = (route: string) =>
        route === PATH_PRIVILEGES ||
        route === PATH_CLAIM ||
        route === PATH_REDEEM_PRIVILEGES ||
        route === PATH_PRIVILEGES_CREATE ||
        route.includes(PATH_PRIVILEGES) ||
        route.includes(PATH_CLAIM);
    const isSetting = (route: string) =>
        route === PATH_INTEGRATION_SETTINGS ||
        route === PATH_SETTINGS ||
        route === PATH_THEMES ||
        route === PATH_ADMIN ||
        route === PATH_ADMIN_CREATE ||
        route === PATH_PERMISSIONS ||
        route === PATH_PERMISSIONS_CREATE;
    const isBidding = (route: string) =>
        route === PATH_BIDDING ||
        route === PATH_BIDDING_RESULT_LIST ||
        route === PATH_BIDDING_PURCHASE_LIST ||
        route === PATH_BIDDING_EMPLOY_LIST ||
        route === PATH_CREATE_BIDDING_RESULT ||
        route === PATH_CREATE_PURCHASE ||
        route === PATH_CREATE_EMPLOY ||
        route.includes(PATH_BIDDING) ||
        route.includes(PATH_BIDDING_RESULT_LIST);
    const isTrashfee = (route: string) =>
        route.includes(PATH_TRASH_FEE);
    const isHome = (route: string) =>
        route === PATH_HOME_MENU ||
        route === PATH_HOME_ABOUT ||
        route === PATH_HOME_ORG ||
        route === PATH_HOME_DECS_REPORT ||
        route === PATH_HOME_SER_INFO;
    return (
        <StyledMenuHeader>
            <Header>
                <Menu onClick={handleClickMenu} mode="horizontal">
                    <Menu.Item
                        className={
                            pathname === PATH_HOME || pathname === PATH_HOME_NEW
                                ? `ant-menu-item-selected menu-after`
                                : `menu-after`
                        }
                        key="/"
                    >
                        {t("page.home")}
                        <div className="menu-heightlight">
                            <div className="line"></div>
                        </div>
                    </Menu.Item>
                    <Menu.Item
                        className={
                            isComplaintRoutes(pathname)
                                ? `ant-menu-item-selected menu-after conplaint`
                                : `menu-after conplaint`
                        }
                        key="/complaints"
                    >
                        {t("page.conplaint")}
                        <div className="menu-heightlight">
                            <div className="line"></div>
                        </div>
                    </Menu.Item>
                    <Menu.Item
                        className={
                            isNewsRoutes(pathname)
                                ? `ant-menu-item-selected menu-after`
                                : `menu-after`
                        }
                        key="/news"
                    >
                        {t("page.news")}
                        <div className="menu-heightlight">
                            <div className="line"></div>
                        </div>
                    </Menu.Item>
                    <Menu.Item
                        className={
                            isActivityRoutes(pathname)
                                ? `ant-menu-item-selected menu-after rewards`
                                : `menu-after rewards`
                        }
                        key="/activity"
                    >
                        {t("page.activity")}
                        <div className="menu-heightlight">
                            <div className="line"></div>
                        </div>
                    </Menu.Item>
                    <Menu.Item
                        className={
                            isRewardClaim(pathname)
                                ? `ant-menu-item-selected menu-after line`
                                : `menu-after line`
                        }
                        key="/privileges"
                    >
                        {t("page.privilege")}
                        <div className="menu-heightlight">
                            <div className="line"></div>
                        </div>
                    </Menu.Item>
                    <Menu.Item
                        className={
                            isBooking(pathname)
                                ? `ant-menu-item-selected menu-after transaction`
                                : `menu-after transaction`
                        }
                        key="/booking"
                    >
                        {t("page.transaction")}
                        <div className="menu-heightlight">
                            <div className="line"></div>
                        </div>
                    </Menu.Item>
                    <Menu.Item
                        className={
                            isBidding(pathname)
                                ? `ant-menu-item-selected menu-after bidding`
                                : `menu-after bidding`
                        }
                        key="/bidding-result"
                    >
                        {t("page.bidding.menu_header")}
                        <div className="menu-heightlight">
                            <div className="line"></div>
                        </div>
                    </Menu.Item>
                    <Menu.Item
                        className={
                            isTrashfee(pathname)
                                ? `ant-menu-item-selected menu-after trashfee`
                                : `menu-after trashfee`
                        }
                        key="/trashfee/bill"
                    >
                        ชำระค่าขยะ
                        <div className="menu-heightlight">
                            <div className="line"></div>
                        </div>
                    </Menu.Item>
                    <Menu.Item
                        className={
                            isHome(pathname)
                                ? `ant-menu-item-selected menu-after home-menu`
                                : `menu-after home-menu`
                        }
                        key="/home-menu"
                    >
                        {t("page.homeMenu.menu_header")}
                        <div className="menu-heightlight">
                            <div className="line"></div>
                        </div>
                    </Menu.Item>
                    {/* <Menu.Item
                        className={
                            pathname === "/transaction"
                                ? `ant-menu-item-selected menu-after transaction`
                                : `menu-after transaction`
                        }
                        key="transaction"
                    >
                        {t("page.transaction")}
                    </Menu.Item> */}
                </Menu>
                <Menu onClick={handleClickMenu} mode="horizontal">
                    <Menu.Item
                        className={
                            pathname === "/transaction-contact"
                                ? `ant-menu-item-selected menu-after transaction-contact`
                                : `menu-after transaction-contact`
                        }
                        key="transaction-contact"
                    >
                        <span className="title">
                            <User />
                            {t("page.transaction_contact")}
                        </span>
                        <div className="menu-heightlight">
                            <div className="line"></div>
                        </div>
                    </Menu.Item>
                    <Menu.Item
                        className={
                            isSetting(pathname)
                                ? `ant-menu-item-selected menu-after settings`
                                : `menu-after settings`
                        }
                        key="/settings"
                    >
                        <span className="title">
                            <IconSetting size={23} />
                            {t("page.settings")}
                        </span>
                        <div className="menu-heightlight">
                            <div className="line"></div>
                        </div>
                    </Menu.Item>
                </Menu>
            </Header>
        </StyledMenuHeader>
    );
};
