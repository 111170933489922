import styled from "styled-components";
import { memo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Upload, Button, Row, Col, Modal, Input } from "antd";
import { Skeleton } from "@mui/material";
import { UploadChangeParam } from "antd/lib/upload/interface";

import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    rectSortingStrategy,
    //@ts-ignore
} from "@dnd-kit/sortable";

import { biddingAPI, newsAPI } from "@api";

import { showErrorMessage, useNotify } from "@utils";
import { SortableItem } from "@modules";
import { IconDelete, SharedInput, UploadImageIcon } from "@components";
import { UploadFileIcon } from "src/Components/icon/UploadFile";
import { IFileUrl } from "@interfaces";
import { CheckOutlined, LoadingOutlined } from "@ant-design/icons";

interface IProps {
    index: number;
    handleGetFile: (file: any, parentIndex: number, childIndex: number) => void;
    changeText: (e: any, parentIndex: number, childIndex: number) => void;
    deleteField: (parentIndex: number, childIndex: number) => void;
    title?: string | JSX.Element;
    defaultFileName?: string | IFileUrl[];
    lable?: string;
    placeholder?: string;
    btnText?: string;
    parentIndex: number;
}

export const ModuleUploadFileWithText = memo((props: IProps) => {
    const { Dragger } = Upload;
    //page hook
    const { t } = useTranslation();
    const { error } = useNotify();
    // props
    const {
        // handleGetImage,
        // images,
        btnText,
        placeholder,
        handleGetFile,
        index,
        title,
        parentIndex,
        defaultFileName,
        changeText,
        deleteField,
    } = props;

    // page state
    const [isVisible, setIsVisible] = useState(false);
    const [urlImg, setUrlImg] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const fileInput = useRef<any | null>();
    const [file, setFile] = useState<any | null>();
    const [loadStatus, setLoadStatus] = useState<string>('wait');

    const onUploadFile = async (file: any) => {
        setIsLoading(true);
        const formData = new FormData();
        //@ts-ignore
        formData.append("file", file);
        if (file.size > 300000000) {
            error("ไฟล์มีขนาดใหญ่กว่า 300MB");
        } else {
            try {
                setLoadStatus('loading');
                const res = await biddingAPI.uploadFilePdf(formData);
                console.log('res', res.data);
                if (res.data.error === "0") {
                    handleGetFile(res.data.result.publicUrl, parentIndex, index);
                    setLoadStatus('loaded');
                } else {
                    error(res.data.msg);
                    setLoadStatus('wait');
                }
            } catch (err: any) {
                setLoadStatus('wait');
                if (err.response) {
                    error(showErrorMessage(err.response));
                } else error(t("message.error"));
            } finally {
                setIsLoading(false);
            }
        }
    };

    const deleteDocs = () => {
        deleteField(parentIndex, index);
    };

    const uploadProps = {
        name: "file",
        accept: ".pdf",
        maxCount: 1,
        beforeUpload() {
            return false;
        },
        onChange({ file }) {
            if (file.status !== "uploading") {
                setFile(file);
                onUploadFile(file);

                // handleGetFile(file);
            }
        },
        showUploadList: false,
        disabled: (loadStatus === "loading"),
    };

    const handleTextChange = (e: any) => {
        // console.log(e.target.value);
        changeText(e, parentIndex, index);
    };

    return (
        <>
            <div className="title">{title}</div>
            <StyledContainer >
                <div className="file-bg">
                    <span className="file-name">
                        {typeof defaultFileName === "string"
                            &&
                            <SharedInput
                                name={`${index}`}
                                onChange={handleTextChange}
                                value={defaultFileName}
                                parentClassName="mb-6"
                            />
                        }
                    </span>
                    <Upload multiple={false} {...uploadProps}>
                        <button
                            className={`file-btn ${loadStatus}-btn`}
                            // onClick={clickUpload}
                            type="button"
                        >
                            {loadStatus === "wait" &&
                                <>
                                    <UploadFileIcon /> &#160;
                                    <span>{btnText}</span>
                                </>
                            }
                            {loadStatus === "loading" &&
                                <>
                                    <LoadingOutlined /> &#160;
                                    <span>กำลังอัปโหลด</span>

                                </>
                            }
                            {loadStatus === "loaded" &&
                                <>
                                    <CheckOutlined /> &#160;
                                    <span>อัปโหลดแล้ว</span>
                                </>
                            }
                        </button>
                    </Upload>
                    <p
                        className="delete-icon"
                        onClick={deleteDocs}
                    >
                        <IconDelete color="#6C7084" size={20} />
                    </p>
                </div>
            </StyledContainer>
        </>
    );
});

const StyledContainer = styled.div`
    background-color: #f7f7f7;
    margin-bottom: 16px;
    border: 1px solid #F3F3F3;
    border-radius: 12px;
    .file-bg {
        padding: 20px;
        padding-bottom: 0px;
        display: flex;
        justify-content: space-between;
        .file-name {
            margin: auto 0;
            flex: 2;
            width: 90%;
            margin-right: 10px;
        }
    }
    .file-btn {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        background-color: #0d6efd;
        color: white;
        font-weight: 500;
        font-size: 16px;
        padding: 10px 20px;
        border: none;
        border-radius: 4px;
    }
    .ant-upload .ant-upload-btn {
        padding: 40px;
        background-color: #f7f7f8;
        p {
            font-size: 12px;
            text-align: center;
            color: #6c7084;
        }
    }
    .btn-drag {
        &:hover {
            color: white !important;
            background: #6c7084;
        }
    }
    .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
        border-color: #6c7084;
    }
    .ant-radio-wrapper {
        display: flex;
        align-items: center;
        height: 48px;
    }
    .preview-field {
        padding: 40px 20px;
        position: relative;
        border: 1px dashed #6c7084;
        border-radius: 12px;
        .img-wrap {
            overflow: hidden;
            position: relative;
            border-radius: 10px;
            cursor: pointer;
            height: 100%;
            img {
                object-fit: cover;
                width: 100%;
                height: 136px;
                object-position: center;
                @media (max-width: ${(p) => p.theme.breakPoints.breakOnlyMobile}) {
                    height: 250px;
                }
            }
            .img-layer {
                position: absolute;
                z-index: 1;
                background-color: transparent;
                width: 100%;
                height: 100%;
                :hover {
                    background-color: rgba(68, 61, 65, 0.45);
                }
                :hover .icon {
                    display: block;
                    z-index: 100;
                }
                .icon {
                    display: none;
                }
                .icon-full-screen {
                    position: absolute;
                    left: 10px;
                    top: 10px;
                }
                .icon-delete {
                    position: absolute;
                    right: 10px;
                    top: 10px;
                }
            }
        }
        .button-upload-preview {
            overflow: hidden;
            position: absolute;
            right: 20px;
            bottom: 14px;
            border: none;
            cursor: pointer;
            button {
                border: none;
                font-size: 14px;
                font-weight: 600;
                background-color: transparent !important;
                :hover,
                :active,
                :focus {
                    color: rgba(0, 0, 0, 0.65);
                }
            }
            input {
                position: absolute;
                z-index: 1;
                opacity: 0;
                cursor: pointer;
            }
        }
    }
    .ant-upload .ant-upload-btn {
        padding: 40px;
        background-color: #f7f7f8;
        p {
            font-size: 12px;
            text-align: center;
            color: #6c7084;
        }
    }
    .btn-drag {
        &:hover {
            color: white !important;
            background: #6c7084;
        }
    }
    .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
        border-color: #6c7084;
    }
    .drag-field {
        height: 100%;
    }
    .ant-upload-drag-container {
        .label {
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            color: #a5a5a5;
            margin-top: 16px;
        }
    }
    @media (max-width: ${(p) => p.theme.breakPoints.breakOnlyMobile}) {
        .preview-field {
            padding: 20px;
            .img-wrap {
                img {
                    height: 100px;
                    width: 100px;
                }
            }
        }
        .ant-upload-btn {
            padding: 20px;
        }
    }
    .delete-icon{
        padding-top: 10px;
        padding-left: 5px;
        border-left:1px solid #BEBEBE;
        height: 42px;
        margin-left: 5px;
    }
    .wait-btn {
        background-color: #0d6efd;
    }
    .loading-btn {
        background-color: #C5C5C5;
    }
    .loaded-btn {
        background-color:#34C759;
    }
`;

// const StyledUploadButton = styled(Button)`
//     background: #6c7084;
//     font-size: 16px;
//     color: white;
//     padding: 6px 16px;
//     height: auto;
//     border-radius: 2px;
//     margin-bottom: 18px;
// `;

const StyledUploadButtonInline = styled(Button)`
    display: flex;
    align-items: center;
`;

const StyledCol = styled(Col)`
    margin-bottom: 12px;
`;
