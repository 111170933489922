import { useEffect, memo, useState, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Row, Col, Select } from "antd";
import styled from "styled-components";
import moment from "moment";
import { IContactListParams, IBidding, IbiddingUpdate, IBidder } from "@interfaces";
import {
  SharedCKEditor,
  StyledSubmitButton,
  StyledCancelButton,
  StyledCard,
  SharedInput,
  ComponentPeriod,
  FriendIcon,
  ComponentContactSortIcon,
  ComponentRowBidder,
  ComponentEmptyData,
} from "@components";
import { useYup } from "@validations";
import { setLoading, selectAuth, selectContact } from "@redux";
import { showErrorMessage, useNotify, usePermission } from "@utils";
import {
  dateFormatHM,
  enumContactSortFields,
  enumSortBy,
  PATH_BIDDING,
  PATH_BIDDING_EMPLOY_LIST,
  PATH_BIDDING_PURCHASE_LIST,
  PATH_CREATE_EMPLOY,
  PATH_CREATE_PURCHASE,
} from "@configs";
import { biddingAPI } from "@api";
import ScrollContainer from "react-indiana-drag-scroll";
import { ModuleUploadFile } from "../form/UploadFile";

interface IProps {
  bidding: IBidding | undefined;
  // categories: INewsCategory[] | undefined;
}
const newDate = new Date();
const initData: IbiddingUpdate = {
  organization_id: "",
  bidding_id: "",
  title: "",
  detail: "",
  template_url: "",
  bid_date_from: new Date().toString(),
  bid_date_to: new Date(newDate.setFullYear(newDate.getFullYear() + 1)).toString(),
  is_enable: true,
  bidding_type: "",
};
const { Option } = Select;
const MONTHlIST = [
  'มกราคม',
  'กุมภาพันธ์',
  'มีนาคม',
  'เมษายน',
  'พฤษภาคม',
  'มิถุนายน',
  'กรกฎาคม',
  'สิงหาคม',
  'กันยายน',
  'ตุลาคม',
  'พฤศจิกายน',
  'ธันวาคม'
];
const dateFormat = 'DD/MM/YYYY';
export const ModuleBidderDetail = memo((props: IProps) => {
  //hook
  const { YupNews, YupBidding } = useYup();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id, bidder } = useParams<{ id: string, bidder: string }>();
  const { error, success } = useNotify();
  const search_params = useSelector(selectContact).paramsContact;
  const permisssion = useSelector(selectAuth).permission;
  const [params, setParams] = useState<IContactListParams>(search_params);
  const [showModal, setShowModal] = useState<boolean>(false);
  const organization_id = useSelector(selectAuth).auth?.admin_data?.organization_id;
  const searchInputRef = useRef<any | null>(null);
  const pathName = window.location.pathname;
  const createNews = Yup.object().shape(YupNews);
  const createBidding = Yup.object().shape(YupBidding);
  const { bidding } = props;
  const [menuTab, setMenuTab] = useState(0);
  const [fileName, setFileName] = useState<string>("");
  const [detail, setDetail] = useState<IBidder>();
  usePermission({ permission: permisssion?.permission_list.bidding.is_manage_bidding });
  useEffect(() => {
    if (bidding) {
      let findBidder = bidding?.bidder_list?.filter(
        (item: any) => item._id === bidder
      );
      if (findBidder?.length === 1) {
        setDetail(findBidder[0]);
        console.log(findBidder[0]);
      }
    }
  }, [bidding, id, bidder]);



  const handleBiddingSubmit = (values: IbiddingUpdate) => {
    // dispatch(setLoading(true))
    console.log("hello submit", values);
  };

  const handleClose = () => {
    if (bidding?.bidding_type === "PURCHASE" || pathName === PATH_CREATE_PURCHASE) {
      history.push(PATH_BIDDING_PURCHASE_LIST);
    } else if (bidding?.bidding_type === "EMPLOY" || PATH_CREATE_EMPLOY) {
      history.push(PATH_BIDDING_EMPLOY_LIST);
    } else {
      history.push(PATH_BIDDING_PURCHASE_LIST);
    }
  };

  const { values, handleChange, handleBlur, handleSubmit, errors, touched, setFieldValue } =
    useFormik({
      initialValues: initData,
      validationSchema: createBidding,
      enableReinitialize: true,
      onSubmit: handleBiddingSubmit,
    });

  const handleChangeDateFrom = (date: Date | string) => {
    setFieldValue("bid_date_from", moment(date).toISOString());
  };

  const handlesort = () => {
    console.log("sort");
  };

  const handleChangeDateTo = (date: Date | string) => {
    setFieldValue("bid_date_to", moment(date).toISOString());
  };

  const handleChangeGuideLine = (event: any, editors: any) => {
    const data = editors.getData();
    setFieldValue("detail", data);
  };

  const onGetFile = (file: string, index: number) => {
    let fileUrlSplit = file.split("/");
    let pdfName = fileUrlSplit[fileUrlSplit.length - 1];
    let nameOnly = pdfName.split("-").slice(1).join();
    // console.log("name", nameOnly);
    setFileName(nameOnly);
    setFieldValue("template_url", file);
  };

  const saveBidding = async () => {
    dispatch(setLoading(true));
    try {
      if (pathName === PATH_CREATE_PURCHASE) {
        console.log("create_purchase");
        const payload = {
          organization_id: organization_id || "",
          title: values.title,
          detail: values.detail,
          bid_date_from: values.bid_date_from,
          bid_date_to: values.bid_date_to,
          is_enable: true,
          bidding_type: "PURCHASE",
          template_url: values.template_url,
        };
        await biddingAPI.create(payload);
        success(t("message.create.success"));
        handleClose();
      } else if (pathName === PATH_CREATE_EMPLOY) {
        console.log("create_employ");
        const payload = {
          organization_id: organization_id || "",
          title: values.title,
          detail: values.detail,
          bid_date_from: values.bid_date_from,
          bid_date_to: values.bid_date_to,
          is_enable: true,
          bidding_type: "EMPLOY",
          template_url: values.template_url,
        };
        await biddingAPI.create(payload);
        success(t("message.create.success"));
        handleClose();
      } else {
        // update bidding
        await biddingAPI.update(values);
        success(t("message.create.success"));
        handleClose();
      }
    } catch (err: any) {
      if (err.response) {
        error(showErrorMessage(err.response));
      } else error(t("message.error"));
    } finally {
      dispatch(setLoading(false));
    }
  };
  const renderDate = () => {
    if (detail?.created_date === "") {
      return undefined;
    } else {

      let date = moment(detail?.created_date).add(543, 'years');
      date.format("DD/MM/YY");
      // console.log('renderDate', date.format("DD/MM/YYYY"));
      return date.format("DD/MM/YYYY");
    }
  };
  return (
    <div className="form-wrap">
      <div className="info-card" >
        <div className="page-header">
          <h3>
            ผู้เข้าร่วมการประมูล
          </h3>
        </div>
        <StyledPageBody>
          <StyledCard>
            <StyledBidderDetail>
              <div className="header">
                <div className="title">รายละเอียดผู้เข้าร่วมประมูล</div>
              </div>
              <Row gutter={16}>
                <Col md={12}>
                  <div className="field-title">ชื่อ - นามสกุล</div>
                  <div className="field-desc">{detail?.name}</div>
                </Col>
                <Col md={12}>
                  <div className="field-title">เลขบัตรประชาชน</div>
                  <div className="field-desc">{detail?.card_no || "-"}</div>
                </Col>
                <Col md={12}>
                  <div className="field-title">หมายเลขโทรศัพท์</div>
                  <div className="field-desc">{detail?.phone_no || "-"}</div>
                </Col>
                <Col md={12}>
                  <div className="field-title">อีเมล</div>
                  <div className="field-desc">{detail?.email || "-"}</div>
                </Col>
                <Col md={12}>
                  <div className="field-title">ผู้เข้าประมูลลำดับที่</div>
                  <div className="field-desc">{detail?.bidder_no}</div>
                </Col>
                <Col md={12}>
                  <div className="field-title">เวลาที่เข้าร่วมประมูล</div>
                  <div className="field-desc">{renderDate()}</div>
                </Col>
                <Col md={24}>
                  <div className="field-title">รายละเอียดการขอเข้าร่วมการประมูล</div>
                  <div className="field-desc"></div>
                </Col>
              </Row>
            </StyledBidderDetail>
          </StyledCard>
          <br />
          <StyledCard>
            <StyledBidderDetail>
              <div className="header">
                <div className="title">{t("page.bidding.name_list")}</div>
                <StyledSubmitButton
                  type="default"
                  text={t("page.export")}
                  onClick={() => window.open(detail?.file_url)}
                // disable={isSubmitting}
                />
              </div>
              {detail?.file_url &&
                <iframe src={detail?.file_url} className="iframe-pdf" />
              }

            </StyledBidderDetail>
          </StyledCard>
        </StyledPageBody>
      </div>
    </div>
  );
});

const StyleMenuTab = styled.div`
    span {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin-right: 10px;
        padding: 10px;
        cursor: pointer;
    }
    .selected {
        background-color: #fff;
        border-top: 5px solid #0d6efd;
        color: #0d6efd;
        border-radius: 5px 5px 0 0;
    }
`;

const StyledBidderNum = styled.div`
    height: 30px;
    display: flex;
    .title {
        margin-left: 5px;
    }

    .number {
        font-weight: 600;
        font-size: 35px;
        line-height: 53px;
        margin-left: auto;
    }
`;

const StyledBidderDetail = styled.div`
    .header {
        display: flex;
        justify-content: space-between;
    }
    .field-title {
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      color: #8A959E;
    }
    .field-desc {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #29304D;
      margin-bottom: 35px;
    }
    .iframe-pdf {
      width: 100%;
      height: 80vh;
    }
`;

const StyledPageBody = styled.div`
    display: grid;
    grid-template-columns: 760px 2fr;
    grid-gap: 15px;
`;

const StyledDetailBody = styled.div`
    background: #ffffff;
    border-radius: 4px;
    padding: 30px;
    max-width: 700px;
    .title {
        font-weight: 500;
        font-size: 25px;
        line-height: 38px;
    }
    .period {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }

    hr {
        border-top: 1px solid #f3f3f3;
    }

    .detail-headline {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }
    .detail-desc {
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
    }
`;
