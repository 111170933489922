import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Switch, Col, Row, Menu, Dropdown, Button } from "antd";
import { MoreHorizontal } from "react-feather";
import moment from "moment";

import { IBidding, INewsCategory } from "@interfaces";

import { enumTypeFetchList, dateFormat, dateFormatHM, PATH_BIDDING } from "@configs";
import { IconDrag } from "@components";
import { useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
interface IProps {
    data: IBidding;
    itemJson: string;
    handleEdit: (userId: string, points: number) => void;
    callbackGetList: (typeGetList: enumTypeFetchList) => void;
    index: number;
    categories: INewsCategory[];
}

export const ComponentRowBidding = (props: IProps) => {
    //hooks
    const { t } = useTranslation();

    //props
    const { data, itemJson } = props;
    const [show, setShowModal] = useState(false);

    const { attributes, listeners, setNodeRef, transform } = useSortable({
        id: itemJson,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
    };

    const menu = (
        <Menu>
            <Menu.Item key={2}>
                <Link to={`${PATH_BIDDING}/${data._id}`}>
                    <div className="delete-btn">
                        {t("page.bidding.bidding_see_more")}
                    </div>
                </Link>

            </Menu.Item>
        </Menu>
    );
    const handleUpdateStatus = () => {
        console.log("update fun");
    };
    return (
        <div className="table-element" ref={setNodeRef} style={style}>
            <div className="drag-icon" {...attributes} {...listeners}>
                <IconDrag />
            </div>
            <div className="table-row">
                <Row
                    style={{ height: "100%" }}
                    gutter={24}
                    type="flex"
                    align="middle"
                    justify="space-between"
                >
                    <Col className="col-item" lg={5} md={5} xs={14} sm={14}>
                        <Link to={`${PATH_BIDDING}/${data._id}`}>
                            <p className="news-title">{data.title || t("page.empty_text")}</p>
                        </Link>
                    </Col>
                    <Col className="col-item" span={3}>
                        {data.bidder_list?.length || t("page.empty_text")}
                    </Col>

                    <Col className="col-item visible-md" span={3}>
                        <p className="eng">
                            {moment(data.bid_date_from).format(dateFormatHM)}
                            <br />
                            {moment(data.bid_date_to).format(dateFormatHM)}
                        </p>
                    </Col>
                    <Col className="col-item m-member-code" xs={10} sm={10} lg={3} md={3}>
                        <p className="visible-md eng">
                            {moment(data.created_date).format(dateFormat) || t("page.empty_text")}
                        </p>
                    </Col>
                    <Col className="col-item visible-md" span={3}>
                        <Switch onChange={handleUpdateStatus} checked={data.is_enable} />
                    </Col>
                    <Col className="col-item" span={3}>
                        <Dropdown
                            //@ts-ignore
                            getPopupContainer={(trigger) => trigger.parentNode}
                            overlay={menu}
                            trigger={["click"]}
                            placement="bottomRight"
                        >
                            <Button style={{ border: "unset" }}>
                                <MoreHorizontal />
                            </Button>
                        </Dropdown>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
