import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";
import moment from "moment";
import { IBidder } from "@interfaces";
import { dateFormat, PATH_BIDDING } from "@configs";
import styled from "styled-components";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useHistory, useParams } from "react-router";
interface IProps {
    data: IBidder;
    itemJson: string;
    index: number;
}

export const ComponentRowBidder = (props: IProps) => {
    //hooks
    const { t } = useTranslation();
    //props
    const { data, itemJson } = props;
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const { setNodeRef, transform } = useSortable({
        id: itemJson,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
    };

    return (
        <div className="table-row" ref={setNodeRef} style={style}>
            <Row
                style={{ height: "100%" }}
                gutter={24}
                type="flex"
                align="middle"
                justify="space-between"
            >
                <Col className="col-item" lg={1} md={1} xs={2} sm={2}>
                    {data.bidder_no || t("page.empty_text")}
                </Col>
                <Col className="col-item" span={4}>
                    {data.name || t("page.empty_text")}
                </Col>

                <Col className="col-item m-member-code" xs={5} sm={5} lg={2} md={2}>
                    {moment(data.created_date).format(dateFormat) || t("page.empty_text")}
                </Col>
                <Col className="col-item" span={4}>
                    <StyledButton onClick={() => history.push(PATH_BIDDING + "/" + id + "/" + data?._id)}>ดูรายละเอียด</StyledButton>
                </Col>
            </Row>
        </div>
    );
};

const StyledButton = styled.button`
    background-color: white;
    width: 100%;
    padding: 5px 0px;
    border: 1px solid #8a959e;
    border-radius: 5px;
    cursor: pointer;
`;
