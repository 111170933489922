import { memo, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Row, Col, Select, Spin } from "antd";
import { IConplaintParam } from "@interfaces";
import {
    SharedCKEditor,
    StyledSubmitButton,
    StyledCancelButton,
    StyledCard,
    SharedInput,
    SharedButtonDefault,
    LocationIcon,
    ComponentModal,
    ComponentSearchMap,
} from "@components";
import { useYup } from "@validations";
import { setLoading, selectAuth, selectOrg } from "@redux";
import { MutlipleModuleUploadImage } from "@modules";
import { useNotify } from "@utils";
import { PATH_COMPLAINT, prefix, defautLocation } from "@configs";
import { conplaintAPI } from "@api";
import Geocode from "react-geocode";
Geocode.setApiKey("AIzaSyAW-yZSy9ibuh4jq9QmiV5_cLqC9VKxdKQ");
Geocode.setLanguage("en");
Geocode.setRegion("th");
Geocode.setLocationType("ROOFTOP");

const initData: IConplaintParam = {
    organization_id: "",
    complain_category_id: "",
    complainer: {
        prefix: "",
        name: "",
        surname: "",
        email: "",
        phone_no: "",
        card_no: "",
    },
    header: "",
    detail: "",
    image_url_list: [],
    place_name: "",
    location_lat: 13.7253229,
    location_lon: 100.5336137,
};
const { Option } = Select;
export const ModuleComplainForm = memo(() => {
    //hook
    const { YupComplaint } = useYup();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { error, success, warning } = useNotify();
    const organization_id = useSelector(selectAuth).auth?.admin_data?.organization_id;
    const conplaintCategory = useSelector(selectOrg).conplaintCategory;
    const createNews = Yup.object().shape(YupComplaint);
    const [address, setAddress] = useState<string>("");
    const [showModal, setShowModal] = useState<boolean>(false);
    const [isLoadingInput, setLoadingInput] = useState<boolean>(false);
    const location = window.navigator && window.navigator.geolocation;

    const handleComplainSubmit = async (values: IConplaintParam) => {
        dispatch(setLoading(true));
        try {
            const { data } = await conplaintAPI.create({
                ...values,
                organization_id: organization_id || "",
            });
            if (data.error === "0") {
                success(data.msg);
                history.push(PATH_COMPLAINT);
            } else {
                error(data.msg);
            }
        } catch (err: any) {
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleCancel = () => {
        history.push(PATH_COMPLAINT);
    };

    const { values, handleChange, handleBlur, handleSubmit, errors, touched, setFieldValue } =
        useFormik({
            initialValues: initData,
            validationSchema: createNews,
            enableReinitialize: true,
            onSubmit: handleComplainSubmit,
        });

    const handleUploadImage = (images: string[]) => {
        setFieldValue("image_url_list", [...images]);
    };

    const handleChangeGuideLine = (event: any, editors: any) => {
        const data = editors.getData();
        setFieldValue("detail", data);
    };
    const handleSelectType = (value: string) => {
        setFieldValue("complain_category_id", value);
    };
    const handleSelectPrefix = (value: string) => {
        setFieldValue("complainer.prefix", value);
    };

    const handleGetLocation = () => {
        setShowModal(true);
    };
    const formatAddress = (lat, long) => {
        Geocode.fromLatLng(lat, long).then(
            (response) => {
                const res = response.results[0].formatted_address;
                setAddress(res);
                setLoadingInput(false);
            },
            (error) => {
                console.error(error);
            }
        );
    };
    const onChangeLocation = (lat: number, lon: number, address: string) => {
        setFieldValue("location_lat", lat);
        setFieldValue("location_lon", lon);
        // setLat(lat);
        // setLon(lon);
        if (address === "") {
            formatAddress(lat, lon);
        } else {
            setAddress(address);
            setFieldValue("address", address);
        }
    };

    return (
        <div className="form-wrap">
            <ComponentModal
                title={t("page.conplaint_position")}
                isVisible={showModal}
                width={660}
                onCancel={() => setShowModal(false)}
            >
                <ComponentSearchMap
                    location_lat={values.location_lat || defautLocation.lat}
                    location_lon={values.location_lon || defautLocation.long}
                    onChangeLocation={onChangeLocation}
                />
                <SharedButtonDefault
                    style={{
                        // width: 110,
                        background: "white",
                        // borderColor: "black",
                        fontSize: 16,
                        padding: 14,
                        fontWeight: 600,
                        height: 49,
                        backgroundColor: "#0263E0",
                        color: "#fff",
                    }}
                    text={t("page.confirm_location")}
                    type="default"
                    size="default"
                    className="default-btn"
                    onClick={() => setShowModal(false)}
                />
            </ComponentModal>
            <form onSubmit={handleSubmit}>
                <div className="page-header">
                    <h3>{t("page.add_a_complaint")}</h3>
                    <p className="sub-title">{t("page.please_provide_complete")}</p>
                </div>
                {/* form basic setting */}
                <div className="page-body">
                    <div className="card-wrap">
                        <StyledCard>
                            <div className="title">{t("page.complaint_topic_title")}</div>
                            <div>
                                <Row>
                                    <Col xs={24} sm={24} md={24}>
                                        <SharedInput
                                            label={t("page.complaint_topic")}
                                            // descLabel={t("page._2_100_characters")}
                                            name="header"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.header}
                                            errors={errors.header}
                                            touched={touched.header}
                                            parentClassName="mb-6"
                                        />
                                    </Col>
                                </Row>
                                <Row className="select-layout">
                                    <Col md={24}>
                                        <div className="label">{t("page.grievance_topic")}</div>
                                        <div className="input-field">
                                            <Select
                                                style={{ width: "100%", height: 48 }}
                                                placeholder={t("page.news_type")}
                                                onChange={handleSelectType}
                                                defaultActiveFirstOption={true}
                                                value={values.complain_category_id}
                                            >
                                                {conplaintCategory &&
                                                    conplaintCategory.map((item, index) => {
                                                        return (
                                                            <Option key={index} value={item._id}>
                                                                {item.category_name}
                                                            </Option>
                                                        );
                                                    })}
                                            </Select>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="card-wrap">
                                <div className="title">{t("page.complaint_details")}</div>
                                <SharedCKEditor
                                    editor={values.detail || ""}
                                    handleChangeEditor={handleChangeGuideLine}
                                    name="detail"
                                />
                            </div>
                            <div className="card-wrap">
                                <MutlipleModuleUploadImage
                                    images={values.image_url_list}
                                    handleGetImage={handleUploadImage}
                                />
                            </div>
                            <div className="card-wrap">
                                <SharedInput
                                    label={t("page.place_name")}
                                    name="place_name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.place_name}
                                    errors={errors.place_name}
                                    touched={touched.place_name}
                                    parentClassName="mb-6"
                                />
                            </div>
                            <div className="card-wrap">
                                <div className="title">{t("page.pin_location_compliant")}</div>
                                <div className="location-input">
                                    <div className="input-layout">
                                        {isLoadingInput && (
                                            <div className="loading-input">
                                                <Spin />
                                            </div>
                                        )}
                                        <SharedInput
                                            // label={}
                                            // name="location"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={address}
                                            // errors={errors.title}
                                            // touched={touched.title}
                                            placeholder={t("page.select_pin_button")}
                                            disable={true}
                                            parentClassName="mb-6"
                                        />
                                    </div>
                                    <SharedButtonDefault
                                        type="default"
                                        size="large"
                                        className="ant-btn-primary"
                                        text={t("page.select_pin")}
                                        disable={isLoadingInput}
                                        onClick={handleGetLocation}
                                        style={{ height: 48 }}
                                        customIcon={<LocationIcon />}
                                    />
                                </div>
                            </div>
                        </StyledCard>
                    </div>
                    <div className="card-wrap">
                        <StyledCard>
                            <div className="title">{t("page.complainant_information")}</div>
                            <Row gutter={32}>
                                <Col xs={24} sm={24} md={8}>
                                    <div className="label">{t("page.prefix")}</div>
                                    <div className="input-field">
                                        <Select
                                            style={{ width: "100%", height: 48 }}
                                            placeholder={t("page.news_type")}
                                            onChange={handleSelectPrefix}
                                            defaultActiveFirstOption={true}
                                            value={values.complainer.prefix}
                                        >
                                            {prefix.map((item, index) => {
                                                return (
                                                    <Option key={index} value={item}>
                                                        {item}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={8}>
                                    <SharedInput
                                        label={t("page.name_thai")}
                                        // descLabel={t("page._2_100_characters")}
                                        name="complainer.name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.complainer.name}
                                        // errors={errors.complainer.firstName}
                                        // touched={touched.complainer.firstName}
                                        parentClassName="mb-6"
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={8}>
                                    <SharedInput
                                        label={t("page.last_name_thai")}
                                        // descLabel={t("page._2_100_characters")}
                                        name="complainer.surname"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.complainer.surname}
                                        // errors={errors.title}
                                        // touched={touched.title}
                                        parentClassName="mb-6"
                                    />
                                </Col>
                            </Row>
                            <Row gutter={32}>
                                <Col xs={24} sm={24} md={12}>
                                    <SharedInput
                                        label={t("page.id_card_number")}
                                        // descLabel={t("page._2_100_characters")}
                                        name="complainer.card_no"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.complainer.card_no}
                                        // errors={errors.title}
                                        // touched={touched.title}
                                        parentClassName="mb-6"
                                        className="eng"
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={12}>
                                    <SharedInput
                                        label={t("page.telephone_number")}
                                        // descLabel={t("page._2_100_characters")}
                                        name="complainer.phone_no"
                                        type="number"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.complainer.phone_no}
                                        // errors={errors.title}
                                        // touched={touched.title}
                                        parentClassName="mb-6"
                                        className="eng"
                                    />
                                </Col>
                            </Row>
                        </StyledCard>
                    </div>
                    <div className="btn-layout">
                        <StyledCancelButton
                            type="sub"
                            text={t("page.cancel")}
                            htmlType="button"
                            onClick={handleCancel}
                        />
                        <StyledSubmitButton
                            type="default"
                            text={t("page.save")}
                            htmlType="submit"
                            // disable={isSubmitting}
                        />
                    </div>
                </div>
            </form>
        </div>
    );
});
