import { useEffect, memo, useState, useRef } from "react";
import { setIn, useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Row, Col, Select, Modal, Radio, Switch } from "antd";
import styled from "styled-components";
import moment from "moment";
import {
  IContactListParams,
  IDocumentUpdate,
  IInfo,
  IResultCreate,
  IResultUpdate,
} from "@interfaces";
import { KeyboardSensor, PointerSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import {
  SharedCKEditor,
  StyledCancelButton,
  StyledCard,
  SharedInput,
  SharedButtonSub,
  IconDelete,
  HomeCloseIcon,
} from "@components";
import { useYup } from "@validations";
import { setLoading, selectAuth, selectContact } from "@redux";
import { ModuleUploadImage } from "@modules";
import { showErrorMessage, useNotify } from "@utils";
import { PATH_BIDDING_RESULT_LIST } from "@configs";
import { biddingAPI, homeMenuAPI } from "@api";
import {
  IBannerData,
  ICoverDataUpdate,
  IHomeNewsItem,
  IHomeOrganization,
  IInfoData,
  IInfoDelete,
  IInfoItemUpdate,
  IShortCutMenuItem,
} from "src/Interfaces/homeMenu";
import { ModuleUploadFileWithText } from "../form/UploadFileWithText";
import { ModuleUploadFile } from "../form/UploadFile";

interface IProps {
  organization: IHomeOrganization | undefined;
  bannerData: IBannerData;
  infoData: IInfoData[];
  setBanner: (data: IBannerData) => void;
  setInfo: (data: IInfoData[]) => void;
  fetchInfo: () => void;
}

const initData: IResultUpdate = {
  organization_id: "",
  title: "",
  image_url: "",
  is_enable: true,
  information_id: "",
  created_date: new Date().toString(),
  documents: [],
};

const initCoverData: ICoverDataUpdate = {
  image_url: "",
  detail_in: "",
  text_in: "",
  organization_id: "",
};

const initShortcutItem: IShortCutMenuItem = {
  name: "",
  link_url: "",
  image_url: "",
  _id: "",
};

export const ModuleHomeORGForm = memo((props: IProps) => {
  const { YupBidding } = useYup();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { error, success } = useNotify();
  const search_params = useSelector(selectContact).paramsContact;

  const organization_id = useSelector(selectAuth).auth?.admin_data?.organization_id;

  const createBidding = Yup.object().shape(YupBidding);
  const { organization, bannerData, setBanner, infoData, setInfo, fetchInfo } = props;
  const [menuTab, setMenuTab] = useState<number>(0);

  const [fileList, setFileList] = useState<IDocumentUpdate[]>([]);
  const [infoItem, setInfoItem] = useState<IInfoData>();
  const [selectIndex, setSelectIndex] = useState<number>(0);

  const [openChangeTab, setOpenChangeTab] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectRadio, setSelectRadio] = useState<number>(3);
  const [notSelectType, setNotSelectType] = useState<number[]>([]);
  const [isChangeData, setIsChangeData] = useState<boolean>(false);
  const [newTab, setNewTab] = useState<number>(0);
  const { Option } = Select;

  useEffect(() => {
    if (bannerData && infoData) {
      if (infoData.length > 0) {
        setInfoItem({ ...infoData[menuTab] });
      }
    }
  }, [bannerData, infoData]);

  const setSwitch = () => {
    let arr = infoItem;
    if (arr !== undefined) {
      setIsChangeData(true);
      arr.is_enable = !arr?.is_enable;
      setInfoItem({ ...arr });
    }
  };

  const addInfoItem = () => {
    let arr = infoData;

    arr.push({
      organization_id: organization_id || "",
      header: "header",
      info_type: "ORG_CHART",
      is_delete: false,
      is_enable: true,
      created_date: "",
      created_by: "",
      updated_date: "",
      updated_by: "",
      deleted_date: "",
      deleted_by: "",
      _id: "",
      detail_list: [],
    });
    setIsChangeData(true);
    setInfo([...arr]);
    setInfoItem({ ...arr[arr.length - 1] });
    setMenuTab(arr.length - 1);
  };

  const saveCover = async () => {
    if (
      bannerData?.detail_in !== "" &&
      bannerData?.image_url !== "" &&
      bannerData?.text_in !== ""
    ) {
      try {
        if (organization_id) {
          let raw = {
            ...bannerData,
            organization_id: organization_id,
          };
          await homeMenuAPI.updateBanner(raw);
          success(t("message.create.success"));
        }
      } catch (err: any) {
        if (err.response) {
          error(showErrorMessage(err.response));
        } else error(t("message.error"));
      } finally {
        dispatch(setLoading(false));
      }
    }
  };

  const handleBiddingSubmit = (values: IResultUpdate) => {
    // console.log("hello submit", values);
  };

  const addMoreFile = () => {
    const arrFileList = fileList;
    arrFileList.push({
      document_name: "",
      document_url: "",
    });
    setFileList([...arrFileList]);
  };

  const handleClose = () => {
    history.push(PATH_BIDDING_RESULT_LIST);
  };
  const clickTab = (index: number) => {
    setNewTab(index);
    if (index !== menuTab) {
      if (isChangeData) {
        setOpenChangeTab(true);
        setIsChangeData(false);
      } else {
        setMenuTab(index);
        setInfoItem(infoData[index]);
        setIsChangeData(false);
      }
    }
    setIsChangeData(false);
  };
  const notSaveChangeTab = () => {
    setMenuTab(newTab);
    setInfoItem(infoData[newTab]);
    setOpenChangeTab(false);
    setIsChangeData(false);
  };
  const onSaveChangeTab = () => {
    setMenuTab(newTab);
    saveInfo()
      .then(() => {
        console.log("savechange then");
        let arr = infoData;
        if (infoItem !== undefined) {
          arr[menuTab] = infoItem;
          setInfo([...arr]);
        }
      })
      .catch(() => {
        error(t("message.error"));
      });
    setInfoItem(infoData[newTab]);
    setOpenChangeTab(false);
  };

  const deleteTab = async (index: number) => {
    if (infoData[index]._id === "") {
      let arr = infoData;
      arr = arr.filter((data, id: number) => id !== index);
      setInfo([...arr]);
    } else {
      try {
        if (organization_id) {
          let raw: IInfoDelete = {
            organization_id: organization_id,
            home_information_id: infoData[index]._id,
          };
          await homeMenuAPI.deleteInfoItem(raw);
          success(t("message.delete.success"));
          fetchInfo();
        }
      } catch (err: any) {
        if (err.response) {
          error(showErrorMessage(err.response));
        } else error(t("message.error"));
      } finally {
        dispatch(setLoading(false));
      }
    }
  };

  const { values, handleChange, handleBlur, handleSubmit, errors, touched, setFieldValue } =
    useFormik({
      initialValues: initData,
      validationSchema: createBidding,
      enableReinitialize: true,
      onSubmit: handleBiddingSubmit,
    });

  const handleChangeDateFrom = (date: Date | string) => {
    setFieldValue("bid_date_from", moment(date).toISOString());
  };

  const saveInfo = async () => {
    if (infoItem?._id !== "") {
      setIsChangeData(false);
      try {
        if (
          organization_id &&
          infoItem?.header !== "" &&
          infoItem?.header !== undefined &&
          infoItem?._id !== undefined
        ) {
          let raw: IInfoItemUpdate = {
            organization_id: organization_id,
            header: infoItem?.header,
            info_type: infoItem?.info_type,
            is_enable: infoItem?.is_enable,
            detail_list: infoItem?.detail_list,
            home_information_id: infoItem?._id,
          };
          await homeMenuAPI.updateInfoItem(raw);
          success(t("message.create.success"));
          fetchInfo();
        }
      } catch (err: any) {
        if (err.response) {
          error(showErrorMessage(err.response));
        } else error(t("message.error"));
      } finally {
        dispatch(setLoading(false));
      }
    } else {
      try {
        if (organization_id && infoItem?.header !== "" && infoItem?.header !== undefined) {
          let arr = infoItem.detail_list.map(function (item) {
            if (item._id !== undefined) {
              delete item._id;
            }
            return item;
          });
          let raw = {
            organization_id: organization_id,
            header: infoItem?.header,
            info_type: infoItem?.info_type,
            is_enable: infoItem?.is_enable,
            detail_list: arr,
          };

          await homeMenuAPI.createInfoItem(raw);
          success(t("message.create.success"));
          fetchInfo();
        }
      } catch (err: any) {
        if (err.response) {
          error(showErrorMessage(err.response));
        } else error(t("message.error"));
      } finally {
        dispatch(setLoading(false));
      }
    }
  };

  const handleChangeGuideLine = (event: any, editors: any) => {
    let arr = infoItem;
    const data = editors.getData();
    if (arr?.detail_list[selectIndex]) {
      setIsChangeData(true);
      arr.detail_list[selectIndex].html_text = data;
      setInfoItem({ ...arr });
    }
  };
  const uploadSectionImg = (img: string, index?: number) => {
    let arr = infoItem;
    if (index !== undefined) {
      if (arr?.detail_list[index]) {
        setIsChangeData(true);
        arr.detail_list[index].image_url = img;
        setInfoItem({ ...arr });
      }
    }
  };

  const changeCoverData = (e: any) => {
    let arr = bannerData;
    arr[e.target.id] = e.target.value;
    setBanner({ ...arr });
  };

  const changeInfoValues = (e: any) => {
    if (infoItem) {
      let arr = infoItem;
      arr[e.target.id] = e.target.value;
      setInfoItem({ ...arr });
      setIsChangeData(true);
    }
  };

  const uploadCoverImg = (images: string) => {
    // console.log(images);
    setBanner({
      ...bannerData,
      image_url: images,
    });
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(TouchSensor)
  );

  const onGetFile = (file: string, index: number) => {
    let fileUrlSplit = file.split("/");
    let pdfName = fileUrlSplit[fileUrlSplit.length - 1];
    let nameOnly = pdfName.split("-").slice(1).join();
    // console.log(index, pdfName, file, fileList[index]);
    const arrFileList = fileList;
    arrFileList[index] = {
      document_name: nameOnly,
      document_url: file,
    };
  };

  const clickAddSection = () => {
    setOpenModal(true);
  };

  const addNewDetail = () => {
    if (infoItem?.detail_list) {
      let arr = infoItem;
      arr.detail_list.push({
        detail_type: selectRadio.toString(),
        image_url: "",
        fouth_type_data: {
          title: "",
          is_single: true,
          docs: [],
        },
        html_text: "",
        fifth_type_data: [],
        _id: "",
      });
      if (selectRadio === 4) {
        setNotSelectType([...notSelectType, arr.detail_list.length - 1]);
      }
      setIsChangeData(true);
      setInfoItem({ ...arr });
      setOpenModal(false);
      setSelectRadio(3);
    }
  };

  const clickIsSingle = (check: boolean, index: number) => {
    let arr = infoItem;
    if (arr?.detail_list[index]) {
      setIsChangeData(true);
      arr.detail_list[index].fouth_type_data.is_single = check;
      // setInfoItem({ ...arr });
      // console.log(index);
      setNotSelectType([...notSelectType.filter((num) => num !== index)]);
      setInfoItem({ ...arr });
    }
  };

  const onChangeFouthTitle = (e: any) => {
    let num = parseInt(e.target.id);
    let data = e.target.value;
    let arr = infoItem;
    if (arr?.detail_list[num]) {
      setIsChangeData(true);
      arr.detail_list[num].fouth_type_data.title = data;
      setInfoItem({ ...arr });
    }
  };

  const addFouthDocs = (index: number) => {
    let arr = infoItem;
    if (arr?.detail_list[index]) {
      setIsChangeData(true);
      arr.detail_list[index].fouth_type_data.docs.push({
        file_url: "",
        text: "",
      });
      setInfoItem({ ...arr });
    }
  };

  const changeTextFouthMulti = (e: any, parentIndex: number, childIndex: number) => {
    let data = e.target.value;
    let arr = infoItem;
    if (arr?.detail_list[parentIndex]) {
      setIsChangeData(true);
      arr.detail_list[parentIndex].fouth_type_data.docs[childIndex].text = data;
      setInfoItem({ ...arr });
    }
    // console.log(e.target.value, parentIndex, childIndex);
  };

  const onGetFileFouthMulti = (file: string, parentIndex: number, childIndex: number) => {
    let arr = infoItem;
    if (arr?.detail_list[parentIndex]) {
      setIsChangeData(true);
      arr.detail_list[parentIndex].fouth_type_data.docs[childIndex].file_url = file;
      setInfoItem({ ...arr });
    }
  };

  const onGetFileFouth = (file: string, index: number) => {
    let fileUrlSplit = file.split("/");
    let pdfName = fileUrlSplit[fileUrlSplit.length - 1];
    let nameOnly = pdfName.split("-").slice(1).join();

    let arr = infoItem;
    if (arr?.detail_list[index]) {
      arr.detail_list[index].fouth_type_data.docs = [
        {
          file_url: file,
          text: nameOnly,
        },
      ];
      setInfoItem({ ...arr });
      setIsChangeData(true);
    }
  };

  const deleteDetail = (index: number) => {
    let arr = infoItem;
    if (arr?.detail_list[index]) {
      arr.detail_list = arr.detail_list.filter((data, id: number) => id !== index);
      setInfoItem({ ...arr });
      setIsChangeData(true);

      // console.log(arr.detail_list[index]);
    }
  };
  const deleteFouthDocs = (parentIndex: number, childIndex: number) => {
    let arr = infoItem;
    if (arr?.detail_list[parentIndex]?.fouth_type_data.docs) {
      arr.detail_list[parentIndex].fouth_type_data.docs = arr.detail_list[
        parentIndex
      ].fouth_type_data.docs.filter((data, id: number) => id !== childIndex);
      setInfoItem({ ...arr });
      setIsChangeData(true);
    }
  };
  const changeIndex = (oldIndex: number, newIndex: number) => {
    if (infoItem && infoItem?.detail_list?.length > 0) {
      const length = infoItem?.detail_list?.length;
      // console.log('length', length);
      // console.log('old ', oldIndex, 'new', newIndex - 1);
      let arr = infoItem.detail_list;
      let item = infoItem.detail_list[oldIndex];
      arr = arr.filter((data, id: number) => id !== oldIndex);
      arr.splice(newIndex - 1, 0, item);
      setInfoItem({
        ...infoItem,
        detail_list: [...arr],
      });
      success(t("ย้ายที่สำเร็จ"));
    }
  };

  return (
    <div className="form-wrap">
      <form onSubmit={handleSubmit}>
        <div className="page-header">
          <h3>เมนูแนะนำเทศบาล</h3>
        </div>

        <StyledPageBody>
          <StyledCard maxWidth="100%">
            <StyledBidderList>
              <div className="header">
                <p className="title">{t("page.homeMenu.cover_headline")}</p>
                <SharedButtonSub
                  type="default"
                  style={{
                    fontSize: "16px",
                    lineHeight: "0px",
                    height: "40px",
                  }}
                  text={t("page.save")}
                  htmlType="submit"
                  onClick={saveCover}
                />
              </div>
            </StyledBidderList>
            <div>
              <Row gutter={16}>
                <Col xs={12} sm={12} md={24}>
                  <ModuleUploadImage
                    title={t("page.homeMenu.cover_img")}
                    images={bannerData?.image_url}
                    IsCanResetImage={true}
                    onResetImage={() => {
                      setBanner({ ...bannerData, image_url: "" });
                    }}
                    handleGetImage={uploadCoverImg}
                  />
                  <br />
                  <br />
                  <SharedInput
                    // label={t("page.bidding.title_headline")}
                    label={t("page.homeMenu.cover_text")}
                    // descLabel={t("page._2_100_characters")}
                    name="text_in"
                    onBlur={handleBlur}
                    onChange={changeCoverData}
                    value={bannerData?.text_in}
                    errors={bannerData?.text_in}
                    // touched={touched.title}
                    parentClassName="mb-6"
                  />
                  <SharedInput
                    // label={t("page.bidding.title_headline")}
                    label={t("page.homeMenu.cover_detail")}
                    // descLabel={t("page._2_100_characters")}
                    // name="desc"
                    name="detail_in"
                    onBlur={handleBlur}
                    onChange={changeCoverData}
                    value={bannerData?.detail_in}
                    errors={bannerData?.detail_in}
                    // touched={touched.title}
                    parentClassName="mb-6"
                  />
                </Col>
              </Row>
            </div>
            <br />
          </StyledCard>
        </StyledPageBody>

        <br />
        <br />
        {/* ข้อมูลแนะนำเทศบาล */}
        <StyledPageBody>
          <div>
            <StyledBidderList>
              <div className="header">
                <p className="title">{t("page.homeMenu.introduce_headline")}</p>
                <SharedButtonSub
                  type="default"
                  style={{
                    fontSize: "16px",
                    lineHeight: "0px",
                    height: "40px",
                  }}
                  text="เพิ่มเมนูอื่นๆ"
                  htmlType="submit"
                  onClick={addInfoItem}
                />
              </div>
            </StyledBidderList>
            <p>
              สามารถเพิ่มเมนูรายละเอียดได้มากกว่า 1 เมนู โดยกดเลือกปุ่ม
              “เพิ่มเมนูอื่นๆ”
            </p>
          </div>
        </StyledPageBody>
        <br />
        <br />
        <StyleMenuTab>
          <div className="parent-tab">
            {infoData.length > 0 &&
              infoData?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`menu-tab ${menuTab === index && "selected"}`}
                  >
                    <span onClick={() => clickTab(index)}>{item.header}</span>
                    <span
                      className="delete-icon"
                      onClick={() => deleteTab(index)}
                    >
                      <HomeCloseIcon />
                    </span>
                  </div>
                );
              })}
          </div>
        </StyleMenuTab>

        {infoData?.length > 0 && (
          <StyledPageBody>
            <StyledCard maxWidth="100%">
              <StyledBidderList>
                <div className="header">
                  <p className="title">รายละเอียด</p>
                  <div>
                    ปิดกั้นการมองทั้งหมด
                    <Switch
                      className="switch"
                      onClick={setSwitch}
                      checked={!infoItem?.is_enable}
                    />
                    <SharedButtonSub
                      type="default"
                      style={{
                        fontSize: "16px",
                        lineHeight: "0px",
                        height: "40px",
                      }}
                      text={t("page.save")}
                      htmlType="submit"
                      onClick={saveInfo}
                    />
                  </div>
                </div>
              </StyledBidderList>
              <SharedInput
                // label={t("page.bidding.title_headline")}
                label={t("page.homeMenu.header_field")}
                // descLabel={t("page._2_100_characters")}
                // name="desc"
                name="header"
                onBlur={handleBlur}
                onChange={changeInfoValues}
                value={infoItem?.header}
                // errors={infoItem?.header}
                // touched={touched.title}
                parentClassName="mb-6"
              />
              <br />
            </StyledCard>
            {infoItem?.detail_list &&
              infoItem.detail_list.length > 0 &&
              infoItem?.detail_list?.map((item, index) => {
                return (
                  <StyledCard maxWidth="100%" key={index}>
                    <StyledCardWrap className="card-wrap" key={index}>
                      <div className="title-flex">
                        <div className="title">
                          {t("page.descriptions")} {index + 1}
                        </div>
                        <p onClick={() => deleteDetail(index)}>
                          <IconDelete color="#6C7084" size={20} />
                        </p>
                      </div>
                      <div className="select-index">
                        <p className="title"> ลำดับที่</p>
                        <Select
                          value={index + 1}
                          onChange={(e) => changeIndex(index, e)}
                        // onChange={ }
                        >
                          {infoItem?.detail_list?.map(
                            (selectItem, selectIndex: number) => {
                              return (
                                <Option
                                  value={selectIndex + 1}
                                  key={index}
                                >
                                  {selectIndex + 1}
                                </Option>
                              );
                            }
                          )}
                        </Select>
                      </div>
                      {item.detail_type === "3" && (
                        <>
                          <ModuleUploadImage
                            title=""
                            images={item.image_url}
                            IsCanResetImage={true}
                            index={index}
                            onResetImage={() => {
                              uploadSectionImg("", index);
                            }}
                            handleGetImage={uploadSectionImg}
                          />
                          <SharedCKEditor
                            clickEditor={() => setSelectIndex(index)}
                            index={index}
                            editor={item.html_text}
                            handleChangeEditor={handleChangeGuideLine}
                            name="desc"
                          />
                        </>
                      )}
                      {item.detail_type === "4" && (
                        <StyledSection4>
                          {notSelectType.find((num) => {
                            return num === index;
                          }) !== undefined ? (
                            <div className="button-section">
                              <StyledCancelButton
                                type="sub"
                                text="+ เพิ่มเอกสารประเภทชุดเดียว"
                                htmlType="button"
                                onClick={() =>
                                  clickIsSingle(true, index)
                                }
                              />
                              <p className="button-or">หรือ</p>
                              <StyledCancelButton
                                type="sub"
                                text="+ เพิ่มเอกสารประเภทกลุ่ม"
                                htmlType="button"
                                onClick={() =>
                                  clickIsSingle(false, index)
                                }
                              />
                            </div>
                          ) : item.fouth_type_data.is_single ? (
                            <div className="show-section4">
                              <SharedInput
                                // label={t("page.bidding.title_headline")}
                                label="ชื่อเอกสาร (หัวข้อหลัก)*"
                                // descLabel={t("page._2_100_characters")}
                                name={`${index}`}
                                onBlur={handleBlur}
                                onChange={onChangeFouthTitle}
                                value={item.fouth_type_data.title}
                                errors={item.fouth_type_data.title}
                                // touched={touched.title}
                                parentClassName="mb-6"
                              />
                              <ModuleUploadFile
                                title=""
                                handleGetFile={onGetFileFouth}
                                index={index}
                                key={index}
                                defaultFileName={
                                  item.fouth_type_data.docs[0]
                                    ? item.fouth_type_data
                                      .docs[0].text
                                    : ""
                                }
                                // defaultFileName={item.fouth_type_data.docs[0]?.file_url || ""}
                                placeholder={t(
                                  "page.bidding.please_select_file"
                                )}
                                btnText={t(
                                  "page.bidding.upload_file"
                                )}
                              />
                            </div>
                          ) : (
                            <div className="show-section4">
                              <SharedInput
                                // label={t("page.bidding.title_headline")}
                                label="ชื่อเอกสาร (หัวข้อหลัก)*"
                                // descLabel={t("page._2_100_characters")}
                                name={`${index}`}
                                onBlur={handleBlur}
                                onChange={onChangeFouthTitle}
                                value={item.fouth_type_data.title}
                                errors={item.fouth_type_data.title}
                                // touched={touched.title}
                                parentClassName="mb-6"
                              />
                              {item.fouth_type_data.docs.length > 0 &&
                                item.fouth_type_data.docs.map(
                                  (docsItem, docsIndex) => {
                                    return (
                                      <ModuleUploadFileWithText
                                        title=""
                                        handleGetFile={
                                          onGetFileFouthMulti
                                        }
                                        parentIndex={index}
                                        changeText={
                                          changeTextFouthMulti
                                        }
                                        index={docsIndex}
                                        key={docsIndex}
                                        defaultFileName={
                                          docsItem.text
                                        }
                                        deleteField={
                                          deleteFouthDocs
                                        }
                                        // defaultFileName={item.fouth_type_data.docs[0]?.file_url || ""}
                                        placeholder={t(
                                          "page.bidding.please_select_file"
                                        )}
                                        btnText={t(
                                          "page.bidding.upload_file"
                                        )}
                                      />
                                    );
                                  }
                                )}
                              <StyledCancelButton
                                type="sub"
                                text="+ เพิ่มเอกสาร"
                                htmlType="button"
                                onClick={() => addFouthDocs(index)}
                              />
                            </div>
                          )}
                        </StyledSection4>
                      )}
                      <br />
                    </StyledCardWrap>
                  </StyledCard>
                );
              })}
            <br />
            <StyledCancelButton
              type="sub"
              text="+ เพิ่มรายละเอียด"
              htmlType="button"
              onClick={clickAddSection}
              style={{ backgroundColor: "white", width: "200px" }}
            />
          </StyledPageBody>
        )}
        <Modal
          visible={openChangeTab}
          closable={false}
          footer={[
            <StyledCancelButton
              type="sub"
              text="ยกเลิก"
              style={{
                fontSize: "16px",
                lineHeight: "0px",
                height: "52px",
              }}
              htmlType="button"
              onClick={notSaveChangeTab}
            />,
            <SharedButtonSub
              type="default"
              style={{
                fontSize: "16px",
                lineHeight: "0px",
                height: "52px",
              }}
              text="ตกลง"
              htmlType="submit"
              onClick={onSaveChangeTab}
            />,
          ]}
        >
          <StyledModal>
            <br />
            <p className="title">คุณต้องการ "บันทึก" ข้อมูลก่อนเปลี่ยนเมนูหรือไม่</p>
            <p className="sub-title">โปรดยืนยันการบันทึก</p>
          </StyledModal>
        </Modal>
        <Modal
          visible={openModal}
          closable={false}
          footer={[
            <StyledCancelButton
              type="sub"
              text="ยกเลิก"
              style={{
                fontSize: "16px",
                lineHeight: "0px",
                height: "52px",
              }}
              htmlType="button"
              onClick={() => {
                setOpenModal(false);
                setSelectRadio(3);
              }}
            />,
            <SharedButtonSub
              type="default"
              style={{
                fontSize: "16px",
                lineHeight: "0px",
                height: "52px",
              }}
              text="ตกลง"
              htmlType="submit"
              onClick={addNewDetail}
            />,
          ]}
          width={650}
        >
          <StyledModal>
            <p className="title">เพิ่มรายละเอียดส่วนอื่น</p>
            <p className="sub-title">
              โปรดเลือกแบบที่ต้องการเพิ่มเพื่อแสดงผลในหน้าเว็บไซต์
            </p>
            <div className="grid-three">
              <div className="item" onClick={() => setSelectRadio(3)}>
                <Radio checked={selectRadio === 3} /> รายละเอียด แบบกำหนดเอง
                <img src="/images/detail_type_3.png" className="item-img" />
              </div>
              <div className="item" onClick={() => setSelectRadio(4)}>
                <Radio checked={selectRadio === 4} /> แนบเอกสาร .pdf
                <img src="/images/detail_type_4.png" className="item-img" />
              </div>
            </div>
          </StyledModal>
        </Modal>

        <br></br>
      </form>
    </div>
  );
});
const StyledAddCategoryModal = styled(Modal)`
    .ant-modal-header {
        border-bottom: 0.5px solid #8a959e !important;
        margin-bottom: 20px;
    }
    .form-input {
        .input-with-search {
            width: 100%;
        }
        .ant-select-selection__rendered {
            height: 100%;
        }
    }
    .footer {
        display: flex;
        justify-content: center;
        .add-cat-btn {
            width: 170px;
        }
    }
`;
const StyledSwitch = styled.div`
    display: flex;
    align-items: center;
    p {
        margin-bottom: 0;
    }
`;

const StyleMenuTab = styled.div`
  .parent-tab::-webkit-scrollbar {
    width: 0px;
    // background: transparent; /* make scrollbar transparent */
    display: none;
  }
    .parent-tab {
      position: relative;
      width: 100%;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      display: flex;
    }
    .menu-tab {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin-right: 10px;
        padding: 10px;
        cursor: pointer;
        border-top: 5px solid #ffffff00;
    }
    .delete-icon {
        margin-left: 10px;
    }
    .selected {
        background-color: #fff;
        border-top: 5px solid #0d6efd;
        color: #0d6efd;
        border-radius: 5px 5px 0 0;
    }
`;

const StyledBidderNum = styled.div`
    height: 30px;
    display: flex;
    .title {
        margin-left: 5px;
    }

    .number {
        font-weight: 600;
        font-size: 35px;
        line-height: 53px;
        margin-left: auto;
    }
`;

const StyledBidderList = styled.div`
    .title {
        font-weight: 700;
        font-size: 35px;
        line-height: 38px;
        color: black;
    }
    .header {
        display: flex;
        justify-content: space-between;
        .switch {
            margin: 0px 10px;
        }
    }
    .disabled {
        background-color: #e4e4e4;
    }
`;

const StyledPageBody = styled.div`
    display: grid;
    /* grid-template-columns: 760px 2fr; */
    grid-gap: 15px;
`;

const StyledDetailBody = styled.div`
    background: #ffffff;
    border-radius: 4px;
    padding: 30px;
    max-width: 700px;
    .title {
        font-weight: 500;
        font-size: 25px;
        line-height: 38px;
    }
    .period {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }

    hr {
        border-top: 1px solid #f3f3f3;
    }

    .detail-headline {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }
    .detail-desc {
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
    }
`;

const StyledDocument = styled.div`
    background-color: #f4f4f4;
    border-radius: 5px;
    margin: 10px 0px;
    display: flex;
    justify-content: space-between;
    .name {
        margin: auto 30px;
        font-weight: 600;
    }
    button {
        background-color: #0d6efd;
        border: none;
        padding: 10px;
        width: 100px;
        border-radius: 5px;
        color: white;
        margin: 15px;
    }
`;

const StyledFileName = styled.p`
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
`;

const StyledNews = styled.div`
    /* display: flex; */
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 20px;
    .img {
        /* max-height: 100px; */
    }
    .url {
        margin: auto 0px;
    }
`;

const StyledCardWrap = styled.div`
    .title-flex {
        display: flex;
        justify-content: space-between;
        .title {
            font-weight: 500;
            font-size: 25px;
            line-height: 38px;
        }
    }
    .select-index {
        width: 100px;
        padding-bottom: 30px;
        .title {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #29304d;
            padding: 0px;
            margin-bottom: 10px;
        }
    }
`;

const StyledModal = styled.div`
    .title {
        font-weight: 700;
        font-size: 25px;
        line-height: 38px;
        text-align: center;
        color: #000000;
        margin-bottom: 0px;
    }
    .sub-title {
        font-size: 14px;
        line-height: 21px;
        color: #8a959e;
        text-align: center;
    }
    .grid-three {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        .item {
            .item-img {
                margin-top: 10px;
                width: 230px;
            }
        }
    }
`;

const StyledSection4 = styled.div`
    max-width: 65vw;
    .button-section {
        display: grid;
        grid-template-columns: 2fr 1fr 2fr;
        .button-or {
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            margin: auto;
        }
    }
    .show-section4 {
        border: 1px solid #8a959e9f;
        border-radius: 8px;
        padding: 20px;
    }
`;
