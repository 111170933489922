export const IconEdit = () => (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.7508 22.4397H13.4978C13.0838 22.4397 12.7478 22.1037 12.7478 21.6897C12.7478 21.2757 13.0838 20.9397 13.4978 20.9397H20.7508C21.1648 20.9397 21.5008 21.2757 21.5008 21.6897C21.5008 22.1037 21.1648 22.4397 20.7508 22.4397Z"
            fill="#29304D"
        />
        <mask id="mask0_3601_48918" maskUnits="userSpaceOnUse" x="2" y="3" width="18" height="20">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.00037 3.50024H19.181V22.4398H2.00037V3.50024Z"
                fill="white"
            />
        </mask>
        <g mask="url(#mask0_3601_48918)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.1106 5.51689L3.69564 17.2919C3.52464 17.5059 3.46164 17.7819 3.52464 18.0469L4.20564 20.9319L7.24464 20.8939C7.53364 20.8909 7.80064 20.7619 7.97764 20.5419C11.1946 16.5169 17.3276 8.84289 17.5016 8.61789C17.6656 8.35189 17.7296 7.97589 17.6436 7.61389C17.5556 7.24289 17.3246 6.92789 16.9916 6.72689C16.9206 6.67789 15.2356 5.36989 15.1836 5.32889C14.5496 4.82089 13.6246 4.90889 13.1106 5.51689ZM3.61364 22.4399C3.26664 22.4399 2.96464 22.2019 2.88364 21.8629L2.06464 18.3919C1.89564 17.6729 2.06364 16.9309 2.52464 16.3549L11.9446 4.57289C11.9486 4.56889 11.9516 4.56389 11.9556 4.55989C12.9886 3.32489 14.8566 3.14289 16.1166 4.15389C16.1666 4.19289 17.8396 5.49289 17.8396 5.49289C18.4476 5.85489 18.9226 6.50189 19.1026 7.26789C19.2816 8.02589 19.1516 8.80789 18.7346 9.46889C18.7036 9.51789 18.6766 9.55989 9.14864 21.4799C8.68964 22.0519 8.00164 22.3849 7.26264 22.3939L3.62364 22.4399H3.61364Z"
                fill="#29304D"
            />
        </g>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.2234 12.1849C16.0634 12.1849 15.9034 12.1339 15.7664 12.0299L10.3144 7.84187C9.98642 7.58987 9.92442 7.11987 10.1764 6.78987C10.4294 6.46187 10.8994 6.40087 11.2284 6.65287L16.6814 10.8399C17.0094 11.0919 17.0714 11.5629 16.8184 11.8919C16.6714 12.0839 16.4484 12.1849 16.2234 12.1849Z"
            fill="#29304D"
        />
    </svg>
);
