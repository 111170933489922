export const PointIcon = () => (
    <svg
        width="101"
        height="100"
        viewBox="0 0 101 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M97.4886 80.6896C97.4886 88.4482 91.617 94.7413 84.3693 94.7413H16.1124C8.86472 94.7413 2.99316 88.4482 2.99316 80.6896V34.9999C2.99316 28.4482 36.2042 11.0344 46.7546 5.6034C48.773 4.56891 50.9748 4.56891 52.9014 5.51719C63.3601 10.7758 97.5803 28.4482 97.5803 34.9999V80.6896H97.4886Z"
            fill="#283244"
        />
        <path
            d="M25.195 87.0684C25.8372 90.6891 29.4152 93.1891 33.2684 92.5857L81.4336 85.4305C85.2868 84.827 87.9473 81.465 87.3051 77.8443L76.8464 15.0857C76.2042 11.465 72.6262 8.96498 68.773 9.56843L20.6079 16.7236C16.7547 17.3271 14.0941 20.6891 14.7363 24.3098L25.195 87.0684Z"
            fill="#D0D1D2"
        />
        <path
            opacity="0.15"
            d="M83.635 55.6034L83.3598 84.9999C82.8093 85.3448 81.4332 85.431 80.6992 85.6034L57.9469 88.793C57.9469 88.793 25.5616 88.2758 25.3781 88.2758L21.7084 62.7586L20.6992 19.6551L21.9836 19.3965L82.4423 49.1379L83.635 55.6034Z"
            fill="#231F20"
        />
        <path
            d="M18.4981 79.3105C18.4064 82.9312 21.4339 86.0346 25.3789 86.2071L74.0945 87.6726C77.9477 87.7588 81.2504 84.9139 81.4339 81.2071L83.6357 17.6726C83.7275 14.0519 80.7 10.9484 76.755 10.776L28.0394 9.3105C24.1862 9.2243 20.8834 12.0691 20.7 15.776L18.4981 79.3105Z"
            fill="#F6F4F4"
        />
        <path
            opacity="0.2"
            d="M67.5801 11.3789L68.1305 25.9479C68.1305 25.9479 67.8553 27.9306 70.0571 28.103C72.259 28.2755 83.3599 26.2927 83.3599 26.2927L67.5801 11.3789Z"
            fill="#231F20"
        />
        <path
            d="M67.5802 10.5176L67.305 23.9659C67.305 23.9659 67.0297 25.9486 69.2316 26.121C71.4334 26.2934 83.36 26.3796 83.36 26.3796L67.5802 10.5176Z"
            fill="#DDE4E8"
        />
        <path
            opacity="0.15"
            d="M70.7914 46.2929C70.7914 46.8101 70.3327 47.2412 69.7822 47.155L32.3511 46.0343C31.8006 46.0343 31.3419 45.6032 31.4336 45.086C31.4336 44.5688 31.8923 44.1377 32.4428 44.2239L69.874 45.3446C70.3327 45.4308 70.7914 45.7757 70.7914 46.2929Z"
            fill="#231F20"
        />
        <path
            opacity="0.15"
            d="M59.6904 49.7413C59.6904 50.2585 59.2317 50.6896 58.6813 50.6034L32.2593 49.7413C31.7088 49.7413 31.2501 49.3103 31.3418 48.793C31.3418 48.2758 31.8005 47.8448 32.351 47.931L58.773 48.793C59.3235 48.793 59.6904 49.2241 59.6904 49.7413Z"
            fill="#231F20"
        />
        <path
            opacity="0.15"
            d="M70.6078 54.0517C70.6078 54.5689 70.1491 55 69.5987 54.9138L32.1675 53.7931C31.617 53.7931 31.1583 53.362 31.25 52.8448C31.25 52.3275 31.7087 51.8965 32.2592 51.9827L69.6904 53.1034C70.2409 53.1034 70.6078 53.5344 70.6078 54.0517Z"
            fill="#231F20"
        />
        <path
            opacity="0.15"
            d="M55.9289 58.1033C55.9289 58.6206 55.4702 59.0516 54.9197 58.9654L32.0757 58.1895C31.5252 58.1895 31.0665 57.7585 31.1582 57.2413C31.1582 56.724 31.617 56.293 32.1674 56.3792L55.0114 57.1551C55.5619 57.1551 55.9289 57.5861 55.9289 58.1033Z"
            fill="#231F20"
        />
        <path
            opacity="0.15"
            d="M60.2408 62.2413C60.2408 62.7585 59.7821 63.1896 59.2316 63.1034L32.0757 62.2413C31.5252 62.2413 31.0665 61.8103 31.1582 61.293C31.1582 60.7758 31.617 60.3448 32.1674 60.431L59.3234 61.293C59.7821 61.3792 60.2408 61.8103 60.2408 62.2413Z"
            fill="#231F20"
        />
        <path
            opacity="0.15"
            d="M70.3324 66.8964C70.3324 67.4137 69.8737 67.8447 69.3233 67.7585L31.8921 66.6378C31.3416 66.6378 30.8829 66.2068 30.9746 65.6895C30.9746 65.1723 31.4334 64.7412 31.9838 64.8274L69.415 65.9481C69.9655 65.9481 70.4242 66.3792 70.3324 66.8964Z"
            fill="#231F20"
        />
        <path
            opacity="0.15"
            d="M70.3324 70.862C70.3324 71.3792 69.8737 71.8102 69.3232 71.724L31.8003 70.6895C31.2498 70.6895 30.7911 70.2585 30.8828 69.7413C30.8828 69.224 31.3416 68.793 31.892 68.8792L69.3232 69.9999C69.8737 69.9999 70.3324 70.3447 70.3324 70.862Z"
            fill="#231F20"
        />
        <path
            d="M97.4889 36.1211V81.3797C97.2137 85.0866 96.021 87.8452 94.4614 89.7418C90.7917 94.3107 85.1036 94.7418 85.1036 94.7418L50.2412 62.328L97.4889 36.1211Z"
            fill="#0D6EFD"
        />
        <path
            d="M50.2409 62.4138L48.773 63.7931L15.3785 94.8276C15.3785 94.8276 9.87395 94.3965 6.20423 89.9138C6.20423 89.9138 6.20423 89.9138 6.20423 89.8276L6.11248 89.7413C6.11248 89.6551 6.02074 89.6551 6.02074 89.5689C5.929 89.4827 5.83725 89.3965 5.74551 89.2241C5.74551 89.1379 5.65377 89.1379 5.65377 89.0517C4.27762 87.1551 3.26845 84.6551 3.08496 81.2931V35.9482L42.0758 57.6724L42.8097 58.1034L49.9657 62.0689L50.2409 62.4138Z"
            fill="#0D6EFD"
        />
        <path
            d="M94.4609 89.8279C90.7912 94.3968 85.1031 94.8279 85.1031 94.8279H15.3783C15.3783 94.8279 9.69023 94.3968 6.02051 89.8279L50.2407 62.4141L94.4609 89.8279Z"
            fill="#0D6EFD"
        />
        <path
            d="M42.7175 58.1898L41.0661 59.6553L6.11198 89.914L6.02024 89.8278C6.02024 89.7415 5.9285 89.7415 5.9285 89.6553C5.83675 89.5691 5.74501 89.4829 5.65327 89.3105C5.65327 89.2243 5.56152 89.2243 5.56152 89.1381L41.9835 57.7588L42.7175 58.1898Z"
            fill="white"
        />
        <path
            d="M57.6719 58.3617L59.3233 59.8272L94.2774 90.0858L94.3691 89.9996C94.3691 89.9134 94.4609 89.9134 94.4609 89.8272C94.5526 89.741 94.6444 89.6548 94.7361 89.4824C94.7361 89.3962 94.8278 89.3962 94.8278 89.31L58.4058 57.9307L57.6719 58.3617Z"
            fill="#F6F4F4"
        />
        <path
            d="M44.0938 20.5171C43.4516 17.3274 40.791 14.7412 37.3965 14.0515C37.0296 13.9653 37.0296 13.9653 36.4791 13.8791C35.9286 13.7929 35.5617 13.8791 35.1947 13.8791C34.8277 13.8791 34.736 13.9653 34.4608 13.9653C31.6167 14.4826 29.4149 15.9481 28.1305 18.3619C27.213 20.1722 26.9378 22.0688 27.7635 23.9653C28.314 25.3447 29.1396 26.724 29.9653 28.0171C31.4332 30.1722 33.0846 32.155 34.8277 34.1378C35.103 34.4826 35.4699 34.8274 35.7452 35.1722C35.8369 35.086 35.9286 34.9998 35.9286 34.9136C37.3965 33.2757 38.8644 31.6378 40.1488 29.9136C41.3415 28.2757 42.5341 26.6378 43.3598 24.8274C44.0938 23.4481 44.369 22.0688 44.0938 20.5171ZM35.6534 25.6033C33.0846 25.6033 31.0663 23.7067 30.8828 21.3791C30.8828 21.2929 30.8828 21.2067 30.8828 21.1205C30.8828 21.0343 30.8828 20.9481 30.8828 20.8619C30.9745 18.5343 33.0846 16.6378 35.6534 16.6378C38.2222 16.6378 40.2406 18.5343 40.4241 20.8619C40.4241 20.9481 40.4241 21.0343 40.4241 21.1205C40.4241 21.2067 40.4241 21.2929 40.4241 21.3791C40.3323 23.7929 38.2222 25.6033 35.6534 25.6033Z"
            fill="#EE5A58"
        />
        <path
            d="M35.6535 25.6891C38.2882 25.6891 40.4241 23.6822 40.4241 21.2064C40.4241 18.7306 38.2882 16.7236 35.6535 16.7236C33.0187 16.7236 30.8828 18.7306 30.8828 21.2064C30.8828 23.6822 33.0187 25.6891 35.6535 25.6891Z"
            fill="white"
        />
    </svg>
);
