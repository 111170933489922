import { CloseOutlined } from "@ant-design/icons";
import { trashfeeAPI } from "@api";
import { ComponentContactSortIcon, ComponentEmptyData, ComponentRowBillDetail, SharedButtonSub, StyledCancelButton } from "@components";
import { enumContactSortFields, enumSortBy, PATH_HOME_DECS_REPORT, PATH_HOME_ORG, PATH_HOME_SER_INFO } from "@configs";
import { ITrashfeeBillList, ItrashfeeBillModal } from "@interfaces";
import { selectAuth, selectContact, setLoading } from "@redux";
import { useNotify } from "@utils";
import { Button, Col, Modal, Radio, Row, Tree } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { LoadIcon } from "src/Components/icon/LoadIcon";
import styled from "styled-components";

interface IProps {
  openModal: boolean;
  selectHouse: ITrashfeeBillList | undefined;
  closeModal: () => void;
  payBillByCash: (id: string) => void;
  toggleCancleStatus: (id: string, status: boolean) => void;
  // selectRadio: number;
  // setOpenModal: (data: boolean) => void;
  // setSelectRadio: (data: number) => void;
  // addNewDetail: () => void;
}



export const ModuleBillListModal = (props: IProps) => {
  const {
    openModal,
    selectHouse,
    closeModal,
    payBillByCash,
    toggleCancleStatus,
    // selectRadio,
    // setOpenModal,
    // setSelectRadio,
    // addNewDetail,
  } = props;
  const history = useHistory();
  const pathName = history.location.pathname;
  const org_id = useSelector(selectAuth).auth?.organization_data?._id;
  const dispatch = useDispatch();
  const [billList, setBillList] = useState<ItrashfeeBillModal[]>([]);
  const [menuTab, setMenuTab] = useState<number>(0);
  const { success, error } = useNotify();

  const search_params = useSelector(selectContact).paramsContact;
  const [params, setParams] = useState(search_params);

  useEffect(() => {
    if (selectHouse !== undefined && openModal) {
      fetchDetailList(false);
    } if (openModal === false) {
      setMenuTab(0);
    }
  }, [selectHouse, openModal]);

  const callbackGetList = () => {
    fetchDetailList(false);
  };

  const fetchDetailList = async (paidStatus: boolean) => {
    try {
      dispatch(setLoading(true));
      let response = await trashfeeAPI.getBillDetailList({
        organization_id: org_id || "",
        register_no: selectHouse?._id?.houseDetail?.register_no || "",
        xpage: 1,
        is_paid: paidStatus,
        is_enable: true,
      });
      // console.log('detail', response.data.result);
      if (response.data.error === "0") {
        setBillList(response.data.result);
      } else {
        error(response.data.msg);
      }
    } catch (error: any) {
      if (error) {
        error(error.msg);
      }
    } finally {
      dispatch(setLoading(false));
    }
  };
  const fetchCancleDetail = async () => {
    try {
      dispatch(setLoading(true));
      let response = await trashfeeAPI.getBillDetailCancle({
        organization_id: org_id || "",
        register_no: selectHouse?._id?.houseDetail?.register_no || "",
        xpage: 1,
        is_enable: false,
      });
      // console.log('detail', response.data.result);
      if (response.data.error === "0") {
        setBillList(response.data.result);
      } else {
        error(response.data.msg);
      }
    } catch (error: any) {
      if (error) {
        error(error.msg);
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

  const ClickTab = (index: number) => {
    setMenuTab(index);
    if (index === 0) {
      fetchDetailList(false);
    } else if (index === 1) {
      fetchDetailList(true);
    } else if (index === 2) {
      fetchCancleDetail();
    }
  };

  const handleSort = (sortField: enumContactSortFields, sortBy: enumSortBy) => {
    // const newSortParams = { ...params, sortField, sortBy };
    // setParams(newSortParams);
    // fetchContacts(newSortParams);
  };

  const clickSubmit = (status, data) => {
    // if (selectRadio !== 0) {
    //   addNewDetail();
    // }
  };
  const changeBillStatus = () => {
    //
  };

  return (
    <Modal
      visible={openModal}
      closable={false}
      footer={null}
      width={"75%"}
      style={{ top: 0 }}
    >
      <StyledModal>
        <div className="header-section">
          <p className="title">รายละเอียดค่าขยะ</p>
          <span onClick={closeModal}><CloseOutlined /></span>
        </div>
        <StyledFilter>
          <div className="filter-section">
            <p className={"filter-item " + (menuTab === 0 && "focus-item")} onClick={() => ClickTab(0)}>ค้างชำระ</p>
            <p className={"filter-item " + (menuTab === 1 && "focus-item")} onClick={() => ClickTab(1)}>ชำระแล้ว</p>
            <p className={"filter-item " + (menuTab === 2 && "focus-item")} onClick={() => ClickTab(2)}>ยกเลิกการชำระ</p>
          </div>
        </StyledFilter>
        <StyledHouse>
          <div className="house-section">
            <div className="house-item">
              <p className="house-sub">เลขที่ทะเบียนบ้าน</p>
              <p className="house-title">{selectHouse?._id?.houseDetail?.register_no}</p>
            </div>
            <div className="house-item">
              <p className="house-sub">ชื่อ</p>
              <p className="house-title">{selectHouse?._id?.houseDetail?.owner?.name} {selectHouse?._id?.houseDetail?.owner?.surname}</p>
            </div>
            <div className="house-item">
              <p className="house-sub">หมวดพื้นที่</p>
              <p className="house-title">{selectHouse?._id?.areaDetail?.area_name}</p>
            </div>
            <div className="house-item">
              <p className="house-sub">ประเภทที่อยู่</p>
              <p className="house-title">
                {selectHouse?._id?.houseDetail?.house_type === "home" ? "ครัวเรือน" : "กิจการ"}
              </p>
            </div>
            <div className="house-item">
              <p className="house-sub">ที่อยู่</p>
              <p className="house-title">{selectHouse?._id?.houseDetail?.full_address}</p>
            </div>
            <Button className="load-btn">
              <LoadIcon color="white" /> &#160;
              <span>ดาวน์โหลดใบแจ้งหนี้รอบบิลนี้</span>
            </Button>
          </div>
        </StyledHouse>
        <StyledDetail>
          <Row
            gutter={24}
            type="flex"
            justify="space-between"
            align="middle"
            className="header-row"
          >
            <Col className="col-item" span={4}>
              <p>ID Invoice</p>
            </Col>
            <Col className="col-item" span={4}>
              <p>รอบบิลค่าขยะประจำเดือน</p>
            </Col>
            <Col className="col-item" span={4}>
              <p>วันที่หมดเขต</p>
            </Col>
            <Col className="col-item" span={3}>
              <p>จำนวนค่าขยะ (บาท)</p>
            </Col>
            <Col className="col-item" span={4}>
              <p>สถานะการจ่ายเงิน</p>
            </Col>
            <Col className="col-item" span={5}>
              <p style={{ textAlign: "right" }}>ใบเสร็จชำระเงิน</p>
            </Col>
          </Row>
          {billList && billList.length > 0 ? (
            <div className="data-table">
              {billList.map((item, index) => {
                console.log('br', item);
                return (
                  <ComponentRowBillDetail
                    key={index}
                    data={item}
                    itemJson={JSON.stringify(item)}
                    handleEdit={changeBillStatus}
                    callbackGetList={callbackGetList}
                    index={index}
                    payBillByCash={payBillByCash}
                    toggleCancleStatus={toggleCancleStatus}
                  />
                );
              })}
              <div
                style={{ width: "100%", height: 10 }}
              ></div>
            </div>
          ) : (
            <ComponentEmptyData />
          )}
        </StyledDetail>
      </StyledModal>
    </Modal >
  );
};


const StyledModal = styled.div`
    .title {
        font-weight: 700;
        font-size: 25px;
        line-height: 38px;
        color: #000000;
        margin-bottom: 0px;
    }
    .sub-title {
        font-size: 14px;
        line-height: 21px;
        color: #8A959E;
        text-align: center;
    }
    .grid-three{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 20px;
        .item {
            .item-img {
                margin-top: 10px;
                width: 230px;
            }
        }
    }
    .header-section{
      display: flex;
      justify-content: space-between;
      font-size: 25px;
      span {
        cursor: pointer;
      }
    }
`;

const StyledFilter = styled.div`
  .filter-section{
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 15px 0;
    color: black;
    display: flex;
    /* padding-bottom: 10px; */
    border-bottom: 1px solid #F7F7F7;
    .filter-item {
      cursor: pointer;
      margin-right: 10px;
      margin-bottom: 0px;
      padding-bottom: 10px;
    }
    .focus-item {
      color: #0D6EFD;
      border-bottom: 2px solid #0D6EFD;
    }
  }
`;

const StyledHouse = styled.div`
  .house-section {
    margin-top: 30px;
    display: flex;
    .house-item {
      margin-right: 20px;
      .house-sub {
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: #8A959E;
        margin-bottom: 0px;
      }
      .house-title {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #29304D;
      }
    }
    .load-btn {
      margin-top: 15px;
      margin-left: auto;
      color: white;
      background: #0D6EFD;
      width: 343px;
      height: 49px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      span {
        margin-left: 5px;
      }
      
    }
    margin-bottom: 20px;
  }
`;

const StyledDetail = styled.div`
  background-color: #F7F7F7;
  border-radius: 16px;
  padding: 32px;
`;