import { useEffect, memo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Switch, Row, Col, Select, Input } from "antd";
import styled from "styled-components";
import { IClaimCreate, IClaim, IUploadFiles } from "@interfaces";
import {
    SharedCKEditor,
    StyledSubmitButton,
    StyledCancelButton,
    StyledCard,
    SharedInput,
    ComponentClaimPreview,
} from "@components";
import { useYup } from "@validations";
import { setLoading, selectAuth } from "@redux";
import { ModuleUploadFiles, ModuleUploadImage } from "@modules";
import { ModuleUploadFile } from "../form/UploadFile";
import { useNotify } from "@utils";
import { PATH_CLAIM, PATH_CLAIM_CREATE, PATH_NEWS } from "@configs";
import { claimAPI } from "@api";

const { TextArea } = Input;
interface IProps {
    claim: IClaim | undefined;
}

const initData: IClaimCreate = {
    organization_id: "",
    name: "",
    description: "",
    phone_no: "",
    address: "",
    file_url_list: [],
    image_url: "",
    is_enable: true,
};
const { Option } = Select;
export const ModuleClaimForm = memo((props: IProps) => {
    //hook
    const { YupClaim } = useYup();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { error, success } = useNotify();
    const organization_id = useSelector(selectAuth).auth?.admin_data?.organization_id;
    const pathName = window.location.pathname;
    const createClaim = Yup.object().shape(YupClaim);
    const { claim } = props;
    useEffect(() => {
        console.log(claim);
        if (claim) {
            setFieldValue("name", claim.name);
            setFieldValue("phone_no", claim.phone_no);
            setFieldValue("description", claim.description);
            setFieldValue("address", claim.address);
            setFieldValue("is_enable", claim.is_enable);
            setFieldValue("image_url", claim.image_url);
            setFieldValue("file_url_list", claim.file_url_list);
        }
    }, [claim]);

    const handleNewsSubmit = async (values: IClaimCreate) => {
        try {
            dispatch(setLoading(true));
            if (pathName === PATH_CLAIM_CREATE) {
                await claimAPI.create({ ...values, organization_id: organization_id || "" });
                success(t("message.create.success"));
                history.push(PATH_CLAIM);
            } else {
                await claimAPI.update({
                    ...values,
                    organization_id: organization_id || "",
                    claim_id: claim?._id || "",
                });
                success(t("message.create.success"));
                history.push(PATH_CLAIM);
            }
        } catch (err: any) {
            if (err) {
                error(err.msg);
            } else error(t("message.error"));
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleCancel = () => {
        history.push(PATH_CLAIM);
    };

    const { values, handleChange, handleBlur, handleSubmit, errors, touched, setFieldValue } =
        useFormik({
            initialValues: initData,
            validationSchema: createClaim,
            enableReinitialize: true,
            onSubmit: handleNewsSubmit,
        });

    const handleChangeStatus = (e: boolean) => {
        setFieldValue("is_hot_news", e);
    };

    const handleUploadImage = (images: string) => {
        setFieldValue("image_url", images);
    };

    const handleChangeGuideLine = (event: any, editors: any) => {
        const data = editors.getData();
        setFieldValue("description", data);
    };

    const onGetFile = (file: IUploadFiles) => {
        setFieldValue("file_url_list", [
            ...values.file_url_list,
            { name: file.key, file_url: file.publicUrl },
        ]);
    };

    const onChangePdfTitle = (value) => {
        setFieldValue("file_url_list", [
            { name: value, file_url: values.file_url_list[0].file_url },
        ]);
    };
    return (
        <div className="form-wrap">
            <form onSubmit={handleSubmit}>
                <div className="page-header">
                    <h3>
                        {pathName.includes(PATH_NEWS)
                            ? t("page.allowance_details")
                            : t("page.allowance_details")}
                    </h3>
                    <p className="sub-title">{t("page.please_provide_complete_details")}</p>
                </div>
                {/* form basic setting */}
                <div className="page-body">
                    <div className="card-wrap">
                        <StyledCard>
                            <div className="title">{t("page.claim_details")}</div>
                            <div>
                                <Row gutter={16}>
                                    <Col xs={12} sm={12} md={24}>
                                        <SharedInput
                                            label={t("page.allowance_subject")}
                                            // descLabel={t("page._2_100_characters")}
                                            name="name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.name}
                                            errors={errors.name}
                                            touched={touched.name}
                                            parentClassName="mb-6"
                                        />
                                    </Col>
                                </Row>
                                <div className="card-wrap">
                                    <div className="title">
                                        {t("page.how_to_claim_allowance")}
                                        {/* <span>{t("page._2_2000_characters")}</span> */}
                                    </div>
                                    <SharedCKEditor
                                        editor={values.description || ""}
                                        handleChangeEditor={handleChangeGuideLine}
                                        name="description"
                                    />
                                </div>
                                <Row gutter={16}>
                                    <Col xs={12} sm={12} md={24}>
                                        <SharedInput
                                            label={t("page.telephone_number")}
                                            // descLabel={t("page._2_100_characters")}
                                            name="phone_no"
                                            type="number"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.phone_no}
                                            errors={errors.phone_no}
                                            touched={touched.phone_no}
                                            parentClassName="mb-6"
                                            className="eng"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <div className="label">
                                            {t("page.place_name_location_address")}
                                        </div>
                                        <TextArea
                                            name="address"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.address}
                                            rows={4}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </StyledCard>
                    </div>
                    <div className="card-wrap">
                        <StyledCard>
                            <div className="title">{t("page.images_and_relevant_docs")}</div>
                            <ModuleUploadImage
                                images={values.image_url}
                                handleGetImage={handleUploadImage}
                            />
                            <div>
                                <ModuleUploadFiles
                                    title={t("page.relevant_form_to_claim_allowance")}
                                    handleGetFile={onGetFile}
                                    index={0}
                                    defaultFileName={values.file_url_list}
                                    placeholder={t("page.placeholder_upload_file")}
                                    btnText={t("page.upload_files")}
                                    onChangePdfTitle={onChangePdfTitle}
                                />
                            </div>
                        </StyledCard>
                    </div>
                    <StyledSwitch className="switch-field">
                        <p>{t("page.activate")}</p>
                        <Switch checked={values.is_enable} onChange={handleChangeStatus} />
                    </StyledSwitch>
                    <div className="btn-layout">
                        <StyledSubmitButton
                            type="default"
                            text={t("page.save")}
                            htmlType="submit"
                            // disable={isSubmitting}
                        />
                        <StyledCancelButton
                            type="sub"
                            text={t("page.cancel")}
                            htmlType="button"
                            onClick={handleCancel}
                        />
                    </div>
                </div>
            </form>
            <ComponentClaimPreview claim={values} />
        </div>
    );
});

const StyledSwitch = styled.div`
    display: flex;
    align-items: center;
    p {
        margin-bottom: 0;
    }
`;
