import styled from "styled-components";
import { Checkbox } from "antd";
import {
    IPermissionAction,
    IPermissionReward,
    IPermissionComplaint,
    IPermissionService,
    IPermissionBidding,
    IPermissionTrash,
    IPermissionTrashHomeInfo,
    IPermissionOrg,
    IPermissionDivision,
} from "@interfaces";
import { useTranslation } from "react-i18next";

interface IProps {
    label: string;
    options:
        | IPermissionAction
        | IPermissionReward
        | IPermissionComplaint
        | IPermissionService
        | IPermissionBidding
        | IPermissionTrash
        | IPermissionTrashHomeInfo
        | IPermissionOrg;
    callbackOnChange: (value) => void;
    callbackSelectDivision?: (value, index) => void;
    divisions?: IPermissionDivision[] | [];
}

export const ComponentCheckbox = (props: IProps) => {
    const { label, options, callbackOnChange, divisions, callbackSelectDivision } = props;
    const { t } = useTranslation();
    const handleCheck = (e) => {
        callbackOnChange(e);
    };
    const handleSelectDivision = (e, index) => {
        if (callbackSelectDivision) {
            callbackSelectDivision(e, index);
        }
    };

    return (
        <StyledLayout>
            <h4>{label}</h4>
            {Object.entries(options).map((item, index) => {
                if (item[0] === "divisions" || item[0] === "service_departments") {
                    return null;
                } else {
                    return (
                        <Checkbox
                            key={index}
                            onChange={handleCheck}
                            name={item[0]}
                            checked={item[1]}
                        >
                            {t(`page.${item[0]}`)}
                        </Checkbox>
                    );
                }
            })}
            {divisions && divisions.length > 0 && (
                <div className="department-list">
                    <h5>
                        {t("page.select_the_organization_to_be_displayed_in_the_complaint_list")}
                    </h5>
                    <div className="ant-checkbox-group">
                        {divisions.map((item, index) => (
                            <div key={index}>
                                <Checkbox
                                    key={index}
                                    checked={item.is_enable}
                                    onChange={(e) => handleSelectDivision(e, index)}
                                >
                                    {item.division_name}
                                </Checkbox>
                                <br />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </StyledLayout>
    );
};
const StyledLayout = styled.div`
    margin-bottom: 34px;
    h4 {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 12px 20px;
        gap: 16px;
        height: 48px;
        background: #f7f9fc;
        border-radius: 8px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #29304d;
        margin-bottom: 16px;
    }
    h5 {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #29304d;
        margin-bottom: 16px;
        padding-left: 20px;
    }
    .ant-checkbox-group {
        margin-bottom: 20px;
        padding: 0 20px;
    }
    label {
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: #29304d;
    }
    .department-list {
        margin-top: 16px;
        .ant-checkbox-group {
            height: 130px;
            overflow-y: scroll;
            border: 1px solid;
            width: 97%;
            padding: 14px;
            margin-left: 20px;
            background: #ffffff;
            border: 0.5px solid #8a959e;
            border-radius: 4px;
        }
    }
`;
