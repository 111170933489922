import { Badge, Menu } from "antd";

import { useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useState, useRef, useEffect } from "react";
import { capitalize } from "@utils";

import { StyledSidebar, TrashfeeIcon } from "@components";
import { selectApp, setMobileDrawer, useAppSelector } from "@redux";
import {
    enumPermission,
    PATH_TRASH_FEE_HOUSE,
    PATH_TRASH_FEE_BUSINESS,
    PATH_TRASH_FEE_BILL,
    PATH_TRASH_FEE_AREA,
    PATH_TRASH_FEE_VERRIFY,
} from "@configs";

interface ISideBarRoute {
    path?: string;
    name: string;
    icon: JSX.Element;
    permission?: string;
    children?: Array<{
        path: string;
        name: string;
        permission?: string;
        badge?: {
            value: string;
        };
    }>;
    badge?: {
        value: string;
    };
}
const getKey = (name: string, index: number) => {
    const string = `${name}-${index}`;
    const key = string.replace(" ", "-");
    return key.charAt(0).toLowerCase() + key.slice(1);
};
const trashfeeRoutes: ISideBarRoute[] = [
    {
        name: "trashfee",
        icon: <TrashfeeIcon />,
        children: [
            {
                path: PATH_TRASH_FEE_BILL,
                name: "รายการลูกหนี้",
                permission: enumPermission.MANAGE_NEWS,
            },
            {
                path: PATH_TRASH_FEE_VERRIFY,
                name: "คำขอตรวจสอบค่าขยะ",
                permission: enumPermission.MANAGE_NEWS,
            },
            {
                path: PATH_TRASH_FEE_HOUSE,
                name: "รายการทะเบียนครัวเรือน",
                permission: enumPermission.MANAGE_NEWS,
            },
            {
                path: PATH_TRASH_FEE_BUSINESS,
                name: "รายการทะเบียนกิจการ",
                permission: enumPermission.MANAGE_NEWS,
            },

            {
                path: PATH_TRASH_FEE_AREA,
                name: "หมวดพื้นที่",
                permission: enumPermission.MANAGE_NEWS,
            },
        ],
    },
];
const { SubMenu } = Menu;
export const TrashFeeSidebar = () => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const sidebarRef = useRef();
    const [openKeys, setOpenKeys] = useState<string[]>([]);
    const app = useAppSelector(selectApp);
    const pathname = location.pathname;

    useEffect(() => {
        if (sidebarRef.current) {
            //@ts-ignore
            sidebarRef.current.scrollIntoView({
                behavior: "auto",
                block: "center",
                inline: "nearest",
            });
        }
    }, [pathname]);

    const badgeTemplate = (badge: { value: string }) => <Badge count={badge.value} />;

    const switchRoute = (path: string, permission?: string, index?: string) => {
        if (app.mobile) dispatch(setMobileDrawer(!app.mobileDrawer));
        if (permission) {
            history.push(path);
        } else {
            history.push(path);
        }
    };

    const handleOpenKey = (e) => {
        setOpenKeys([...openKeys, e.key]);
    };

    return (
        <StyledSidebar>
            <Menu
                theme={"light"}
                className="border-0 scroll-y "
                style={{ flex: 1 }}
                mode={"inline"}
                defaultOpenKeys={["0", "1"]}
                onClick={handleOpenKey}
            >
                {trashfeeRoutes.map((route, index) => {
                    if (!route.children) {
                        return (
                            <Menu.Item
                                key={index}
                                className={
                                    pathname === route.path
                                        ? `ant-menu-item-selected menu-after ${route.path}`
                                        : `menu-after ${route.path}`
                                }
                                onClick={() => switchRoute(route.path || "/", "", index.toString())}
                            >
                                <div>
                                    <span style={{ marginBottom: 5 }} className="anticon">
                                        {route.icon}
                                    </span>
                                    <span
                                        className="mr-2 menu-item-text"
                                        style={{
                                            color: "black",
                                            fontWeight: 700,
                                            fontSize: 16,
                                        }}
                                    >
                                        {capitalize(t("page.sidebar." + route.name))}
                                    </span>
                                    {route.badge && badgeTemplate(route.badge)}
                                </div>
                            </Menu.Item>
                        );
                    } else {
                        return (
                            <SubMenu
                                className={`menu-after ${route.name}`}
                                key={index}
                                onTitleClick={() => {
                                    if (route.path && route.permission) {
                                        switchRoute(
                                            route.path,
                                            route.permission || "",
                                            index.toString()
                                        );
                                    }
                                }}
                                title={
                                    <span>
                                        <span className="anticon" style={{ marginBottom: 5 }}>
                                            {route.icon}
                                        </span>
                                        <span
                                            className="menu-item-text"
                                            style={{
                                                color: "black",
                                                fontWeight: 700,
                                                fontSize: 16,
                                            }}
                                        >
                                            {/* ชำระค่าขยะ */}
                                            {capitalize(t("page.sidebar." + route.name))}
                                        </span>
                                        {route.badge && badgeTemplate(route.badge)}
                                    </span>
                                }
                            >
                                {route.children.map((subitem, secondIndex) => (
                                    <Menu.Item
                                        key={getKey(subitem.name, secondIndex)}
                                        style={{ paddingLeft: 60 }}
                                        className={
                                            pathname === subitem.path
                                                ? `ant-menu-item-selected sub-item-text`
                                                : ` sub-item-text`
                                        }
                                        onClick={() =>
                                            switchRoute(
                                                subitem.path,
                                                subitem.permission || "",
                                                index.toString()
                                            )
                                        }
                                    >
                                        <div
                                            className={subitem.path}
                                            //@ts-ignore
                                            ref={
                                                pathname.includes(subitem.path) ? sidebarRef : null
                                            }
                                        >
                                            <span
                                                className="mr-auto"
                                                style={
                                                    subitem.path && pathname.includes(subitem.path)
                                                        ? {
                                                            color: "black",
                                                            fontSize: 16,
                                                            fontWeight: 700,
                                                        }
                                                        : { color: "#646464", fontSize: 16 }
                                                }
                                            >
                                                {subitem.name}
                                                {/* {capitalize(t("page.sidebar." + subitem.name))} */}
                                            </span>
                                            {subitem.badge && badgeTemplate(subitem.badge)}
                                        </div>
                                    </Menu.Item>
                                ))}
                            </SubMenu>
                        );
                    }
                })}
            </Menu>
        </StyledSidebar>
    );
};
