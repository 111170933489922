import { Badge, Menu } from "antd";

import { useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useState, useRef, useEffect } from "react";
import { capitalize } from "@utils";

import { IconTheme, IconList, IconPermission, IconOffice } from "@components";
import { selectApp, setMobileDrawer, useAppSelector } from "@redux";
import {
    enumPermission,
    PATH_SETTINGS,
    PATH_THEMES,
    PATH_PROFILE,
    PATH_INTEGRATION_SETTINGS,
    PATH_ADMIN,
    PATH_PERMISSIONS,
} from "@configs";

interface ISideBarRoute {
    path?: string;
    name: string;
    icon: JSX.Element;
    permission?: string;
    children?: Array<{
        path: string;
        name: string;
        permission?: string;
        badge?: {
            value: string;
        };
    }>;
    badge?: {
        value: string;
    };
}
const getKey = (name: string, index: number) => {
    const string = `${name}-${index}`;
    const key = string.replace(" ", "-");
    return key.charAt(0).toLowerCase() + key.slice(1);
};
const settingRoutes: ISideBarRoute[] = [
    {
        path: PATH_SETTINGS,
        name: "tessabarn_info",
        icon: <IconOffice size={24} color={"#0D6EFD"} />,
        permission: enumPermission.MANAGE_BIDDING,
    },
    {
        path: PATH_THEMES,
        name: "theme_setting",
        icon: <IconTheme size={24} color={"#0D6EFD"} />,
        permission: enumPermission.MANAGE_BIDDING,
    },
    {
        path: PATH_PROFILE,
        name: "profile",
        icon: <IconList size={24} color={"#0D6EFD"} />,
        permission: enumPermission.MANAGE_BIDDING,
    },
    {
        path: "/",
        name: "permission_setting",
        icon: <IconPermission size={24} color={"#0D6EFD"} />,
        children: [
            {
                path: PATH_ADMIN,
                name: "admin_user_list",
                permission: enumPermission.MANAGE_NEWS,
            },
            {
                path: PATH_PERMISSIONS,
                name: "permission_list",
                permission: enumPermission.MANAGE_NEWS,
            },
        ],
    },
    {
        path: "/",
        name: "integration_main",
        icon: <IconList size={24} color={"#0D6EFD"} />,
        children: [
            {
                path: PATH_INTEGRATION_SETTINGS,
                name: "line_integration",
                permission: enumPermission.MANAGE_NEWS,
            },
        ],
    },
];
const { SubMenu } = Menu;

export const SettingsSidebar = () => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const sidebarRef = useRef();
    const [openKeys, setOpenKeys] = useState<string[]>([]);
    const app = useAppSelector(selectApp);
    const pathname = location.pathname;

    useEffect(() => {
        if (sidebarRef.current) {
            //@ts-ignore
            sidebarRef.current.scrollIntoView({
                behavior: "auto",
                block: "center",
                inline: "nearest",
            });
        }
    }, [pathname]);

    const badgeTemplate = (badge: { value: string }) => <Badge count={badge.value} />;

    const switchRoute = (path: string, permission?: string, index?: string) => {
        if (app.mobile) dispatch(setMobileDrawer(!app.mobileDrawer));
        if (permission) {
            history.push(path);
        } else {
            history.push(path);
        }
    };

    const handleOpenKey = (e) => {
        setOpenKeys([...openKeys, e.key]);
    };

    return (
        <Menu
            theme={"light"}
            className="border-0 scroll-y "
            style={{ flex: 1 }}
            mode={"inline"}
            defaultOpenKeys={["3", "4"]}
            onClick={handleOpenKey}
        >
            {settingRoutes.map((route, index) => {
                if (!route.children) {
                    return (
                        <SubMenu
                            className={`menu-after ${route.name}`}
                            key={index}
                            onTitleClick={() => {
                                if (route.path && route.permission) {
                                    switchRoute(
                                        route.path,
                                        route.permission || "",
                                        index.toString()
                                    );
                                }
                            }}
                            title={
                                <span>
                                    <span className="anticon" style={{ marginBottom: 5 }}>
                                        {route.icon}
                                    </span>
                                    <span
                                        className={
                                            pathname === route.path
                                                ? "selected-news menu-item-text"
                                                : "menu-item-text"
                                        }
                                        style={{
                                            color: "black",
                                            fontWeight: 700,
                                            fontSize: 16,
                                        }}
                                    >
                                        {capitalize(t("page.sidebar." + route.name))}
                                    </span>
                                    {route.badge && badgeTemplate(route.badge)}
                                </span>
                            }
                        ></SubMenu>
                    );
                } else {
                    return (
                        <SubMenu
                            className={`menu-after ${route.name}`}
                            key={index}
                            onTitleClick={() => {
                                if (route.path && route.permission) {
                                    switchRoute(
                                        route.path,
                                        route.permission || "",
                                        index.toString()
                                    );
                                }
                            }}
                            title={
                                <span>
                                    <span className="anticon" style={{ marginBottom: 5 }}>
                                        {route.icon}
                                    </span>
                                    <span
                                        className="menu-item-text"
                                        style={{
                                            color: "black",
                                            fontWeight: 700,
                                            fontSize: 16,
                                        }}
                                    >
                                        {capitalize(t("page.sidebar." + route.name))}
                                    </span>
                                    {route.badge && badgeTemplate(route.badge)}
                                </span>
                            }
                        >
                            {route.children.map((subitem, secondIndex) => (
                                <Menu.Item
                                    key={getKey(subitem.name, secondIndex)}
                                    style={{ paddingLeft: 60 }}
                                    className={
                                        pathname === subitem.path
                                            ? `ant-menu-item-selected sub-item-text`
                                            : ` sub-item-text`
                                    }
                                    onClick={() =>
                                        switchRoute(
                                            subitem.path,
                                            subitem.permission || "",
                                            index.toString()
                                        )
                                    }
                                >
                                    <div
                                        className={subitem.path}
                                        //@ts-ignore
                                        ref={pathname.includes(subitem.path) ? sidebarRef : null}
                                    >
                                        <span
                                            className="mr-auto"
                                            style={
                                                subitem.path && pathname.includes(subitem.path)
                                                    ? {
                                                          color: "black",
                                                          fontSize: 16,
                                                          fontWeight: 700,
                                                      }
                                                    : { color: "#646464", fontSize: 16 }
                                            }
                                        >
                                            {capitalize(t("page.sidebar." + subitem.name))}
                                        </span>
                                        {subitem.badge && badgeTemplate(subitem.badge)}
                                    </div>
                                </Menu.Item>
                            ))}
                        </SubMenu>
                    );
                }
            })}
        </Menu>
    );
};
