import {
    IOrganizationId,
    INewsCategory,
    IConplaintCategory,
    IDivision,
    IComplaint,
    IActivityCategory,
    IAdminParam,
    ITransaction,
} from "@interfaces";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from ".";
import { orgApi } from "@api";
interface IInitialState {
    category: INewsCategory[];
    isLoading: boolean;
    conplaintCategory: IConplaintCategory[];
    division: IDivision[];
    complainTypes: IComplaint[];
    activityCategory: IActivityCategory[];
    transaction: ITransaction | null;
}

const initialState: IInitialState = {
    category: [],
    conplaintCategory: [],
    isLoading: false,
    division: [],
    complainTypes: [],
    activityCategory: [],
    transaction: null,
};

export const getCategories = createAsyncThunk<INewsCategory[], IOrganizationId>(
    "org/category",
    async (values: IOrganizationId, { rejectWithValue }) => {
        try {
            const res = await orgApi.getCat(values);
            return res.data.result as INewsCategory[];
        } catch (err: any) {
            return rejectWithValue(err);
        }
    }
);
export const getConplaintCategories = createAsyncThunk<IConplaintCategory[], IAdminParam>(
    "org/conplaintCategory",
    async (values: IAdminParam, { rejectWithValue }) => {
        try {
            const res = await orgApi.getConplainCat(values);
            return res.data.result as IConplaintCategory[];
        } catch (err: any) {
            return rejectWithValue(err);
        }
    }
);
export const getDivisions = createAsyncThunk<IDivision[], IAdminParam>(
    "org/division",
    async (values: IAdminParam, { rejectWithValue }) => {
        try {
            const res = await orgApi.getDivision(values);
            return res.data.result as IDivision[];
        } catch (err: any) {
            return rejectWithValue(err);
        }
    }
);
export const getComplaintType = createAsyncThunk<IComplaint[], IOrganizationId>(
    "org/complaintType",
    async (values: IOrganizationId, { rejectWithValue }) => {
        try {
            const res = await orgApi.getComplaintStatus(values);
            return res.data.result as IComplaint[];
        } catch (err: any) {
            return rejectWithValue(err);
        }
    }
);
export const getActivityCategory = createAsyncThunk<IActivityCategory[], IOrganizationId>(
    "org/activityCategory",
    async (values: IOrganizationId, { rejectWithValue }) => {
        try {
            const res = await orgApi.getActivityCategorys(values);
            return res.data.result as IActivityCategory[];
        } catch (err: any) {
            return rejectWithValue(err);
        }
    }
);
export const getHeaderBar = createAsyncThunk<ITransaction, IOrganizationId>(
    "org/getHeaderBar",
    async (values: IOrganizationId, { rejectWithValue }) => {
        try {
            const res = await orgApi.getHeaderBar(values);
            return res.data.result as ITransaction;
        } catch (err: any) {
            return rejectWithValue(err);
        }
    }
);
const organization = createSlice({
    name: "organization",
    initialState,
    reducers: {
        addCat: (state, action: PayloadAction<number>) => {
            state.isLoading = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCategories.fulfilled, (state, action: { payload: INewsCategory[] }) => {
            state.category = action.payload;
            state.isLoading = false;
        });

        builder.addCase(getCategories.pending, (state) => {
            state.isLoading = true;
        });

        builder.addCase(getCategories.rejected, (state) => {
            state.category = [];
            state.isLoading = false;
        });
        builder.addCase(
            getConplaintCategories.fulfilled,
            (state, action: { payload: IConplaintCategory[] }) => {
                state.conplaintCategory = action.payload;
                state.isLoading = false;
            }
        );

        builder.addCase(getConplaintCategories.pending, (state) => {
            state.isLoading = true;
        });

        builder.addCase(getConplaintCategories.rejected, (state) => {
            state.conplaintCategory = [];
            state.isLoading = false;
        });
        builder.addCase(getDivisions.fulfilled, (state, action: { payload: IDivision[] }) => {
            state.division = action.payload;
            state.isLoading = false;
        });

        builder.addCase(getDivisions.pending, (state) => {
            state.isLoading = true;
        });

        builder.addCase(getDivisions.rejected, (state) => {
            state.division = [];
            state.isLoading = false;
        });
        builder.addCase(getComplaintType.fulfilled, (state, action: { payload: IComplaint[] }) => {
            state.complainTypes = action.payload;
            state.isLoading = false;
        });

        builder.addCase(getComplaintType.pending, (state) => {
            state.isLoading = true;
        });

        builder.addCase(getComplaintType.rejected, (state) => {
            state.complainTypes = [];
            state.isLoading = false;
        });
        builder.addCase(
            getActivityCategory.fulfilled,
            (state, action: { payload: IActivityCategory[] }) => {
                state.activityCategory = action.payload;
                state.isLoading = false;
            }
        );

        builder.addCase(getActivityCategory.pending, (state) => {
            state.isLoading = true;
        });

        builder.addCase(getActivityCategory.rejected, (state) => {
            state.activityCategory = [];
            state.isLoading = false;
        });
        builder.addCase(getHeaderBar.fulfilled, (state, action: { payload: ITransaction }) => {
            state.transaction = action.payload;
        });
        builder.addCase(getHeaderBar.rejected, (state) => {
            state.transaction = null;
        });
    },
});

export const selectOrg = (state: RootState) => state.org;

export const { addCat } = organization.actions;

export default organization.reducer;
