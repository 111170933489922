import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { Switch, Col, Row, Avatar, Menu, Dropdown, Button } from "antd";
import { MoreHorizontal } from "react-feather";
import moment from "moment";

import { INews, INewsCategory, ITrashfeeArea, ITrashfeeHouse } from "@interfaces";
import { newsAPI, trashfeeAPI } from "@api";
import { dateFormat, dateFormatHM, PATH_CREATE_NEWS, enumNewsType, defaultImage, PATH_TRASH_FEE_HOUSE, PATH_TRASH_FEE_VERRIFY } from "@configs";
import { selectAuth } from "@redux";
import { useSelector } from "react-redux";
import { IconDrag } from "@components";
import { showErrorMessage, useNotify } from "@utils";
import { useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
interface IProps {
  data: ITrashfeeHouse;
  itemJson: string;
  handleEdit: (status: boolean, data: ITrashfeeHouse) => void;
  callbackGetList: () => void;
  index: number;
  newType?: enumNewsType;
  categories: INewsCategory[];
  areaList: ITrashfeeArea[];
}

export const ComponentRowVerrify = (props: IProps) => {
  //hooks
  const { t } = useTranslation();
  const { success, error } = useNotify();
  const organization_id = useSelector(selectAuth).auth?.organization_data?._id;
  //props
  const history = useHistory();
  const { data, handleEdit, callbackGetList, itemJson, newType, areaList } = props;
  const [show, setShowModal] = useState(false);

  const { attributes, listeners, setNodeRef, transform } = useSortable({
    id: itemJson,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
  };

  const handleDelete = async () => {
    try {
      await trashfeeAPI.deleteHouse({
        organization_id: organization_id || "",
        house_id: data?._id
      });
      callbackGetList();
      setShowModal(false);
      success(t("message.delete.success"));
    } catch (err: any) {
      if (err) {
        error(err.msg);
      } else error(t("message.delete.fail"));
    }
  };

  const menu = (
    <Menu>
      {/* <Menu.Divider /> */}
      <Menu.Item key={1} onClick={handleDelete}>
        <div className="duplicate-btn">{t("page.delete")}</div>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="table-element" ref={setNodeRef} style={style}>
      <div className="drag-icon" {...attributes} {...listeners}>
        <IconDrag />
      </div>
      <div className="table-row">
        <Row
          style={{ height: "100%" }}
          gutter={24}
          type="flex"
          align="middle"
          justify="space-between"
        >
          <Col className="col-item" span={4}
            onClick={() => history.push(`${PATH_TRASH_FEE_VERRIFY}/${data._id}`)}
          >
            <b style={{ cursor: "pointer" }}>
              {data.owner.prefix} {data.owner.name} {data.owner.surname}
            </b>
          </Col>
          <Col className="col-item" span={3}>
            {data.owner.card_no}
          </Col>
          <Col className="col-item" span={3}>
            {data.register_no}

          </Col>
          <Col className="col-item" span={11}>
          </Col>
          <Col className="col-item" span={3}>
            <Dropdown
              //@ts-ignore
              getPopupContainer={(trigger) => trigger.parentNode}
              overlay={menu}
              trigger={["click"]}
              placement="bottomRight"
            >
              <Button style={{ border: "unset" }}>
                <MoreHorizontal />
              </Button>
            </Dropdown>
          </Col>
        </Row>
      </div>
    </div >
  );
};
