import { useTranslation } from "react-i18next";

import { Col, Row, Avatar, Checkbox, Select } from "antd";

import { IBooking, INewsCategory } from "@interfaces";
import { enumNewsType, defaultImage } from "@configs";
import styled from "styled-components";

const status = ["COMPLETE", "PENDING", "CANCELED"];
interface IProps {
    data: IBooking;
    itemJson: string;
    handleView: (data: IBooking) => void;
    callbackGetList: () => void;
    index: number;
    newType?: enumNewsType;
    categories: INewsCategory[];
    handleStatus: (value: string, id: string) => void;
}
const { Option } = Select;

export const ComponentRowBooking = (props: IProps) => {
    const { t } = useTranslation();
    const { data, handleView, handleStatus } = props;
    const isDisable = data.status === "COMPLETE" || data.status === "CANCELED";
    return (
        <div className="table-element">
            <div className="table-row small">
                <Row
                    style={{ height: "100%" }}
                    gutter={24}
                    type="flex"
                    align="middle"
                    justify="space-between"
                >
                    <Col className="col-item" span={8} onClick={() => handleView(data)}>
                        <Checkbox />
                        <Avatar
                            shape="circle"
                            src={defaultImage}
                            size={40}
                            style={{ minWidth: 40, padding: 0 }}
                        />
                        <p className="news-title">{data.profile_name || t("page.empty_text")}</p>
                    </Col>
                    <Col className="col-item" span={4}>
                        <p className="eng">
                            {data?.profileDetail?.card_no || t("page.empty_text")}
                        </p>
                    </Col>
                    <Col className="col-item code" span={4}>
                        <p className="eng">{data?.profileDetail?.tel || t("page.empty_text")}</p>
                    </Col>
                    <Col className="col-item visible-md" span={3}>
                        <p className="eng">{`${data?.service_from} - ${data?.service_to}`}</p>
                    </Col>
                    <Col className="col-item" span={3}>
                        <StyledSelect>
                            <Select
                                style={{ width: "100%", height: 48 }}
                                placeholder={`เปิดจองเวลาก่อนถึงคิวล่วงหน้า`}
                                onChange={(value) => handleStatus(value, data?._id)}
                                defaultActiveFirstOption={true}
                                value={data?.status}
                                disabled={isDisable}
                            >
                                {status.map((item, index) => {
                                    return (
                                        <Option key={index} value={item}>
                                            {t(`page.booking_status.${item}`)}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </StyledSelect>
                    </Col>
                    <Col className="col-item" span={1}>
                        <RightArrow />
                    </Col>
                </Row>
            </div>
        </div>
    );
};

const RightArrow = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.50012 19.789L16.3941 11.895L8.50012 4"
            stroke="#8A959E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const StyledSelect = styled.div`
    .ant-select-selection--single {
        background-color: #0d6efd;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #ffffff;
        .ant-select-arrow {
            color: #ffffff;
        }
    }
`;
