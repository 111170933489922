export const GiftIcon = () => (
    <svg
        width="131"
        height="128"
        viewBox="0 0 131 128"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M69.1404 6.70995L2.30273 25.4297L27.8937 116.656L94.7313 97.9367L69.1404 6.70995Z"
            fill="#52A1FF"
        />
        <path
            d="M66.8516 10.4264L6.10059 27.4414L29.7924 111.898L90.5434 94.8829L66.8516 10.4264Z"
            fill="white"
        />
        <path
            d="M36.5531 18.9491L6.12012 27.4727L29.8119 111.929L60.2448 103.406L36.5531 18.9491Z"
            fill="#E7E6E8"
        />
        <path
            d="M48.5161 12.5177L38.2592 15.3779C38.4978 14.782 38.617 14.1862 38.4978 13.4711C38.2592 11.4452 36.4702 10.0151 34.4427 10.2535C32.4152 10.4918 30.984 12.2794 31.2225 14.3053C31.3418 15.3779 31.9381 16.2121 32.6537 16.8079L22.9932 19.6681L25.0207 26.9376L50.5436 19.7872L48.5161 12.5177ZM34.562 11.6835C35.7546 11.5644 36.9473 12.3986 37.0666 13.5903C37.1858 14.782 36.351 15.9737 35.1583 16.0929C33.9656 16.2121 32.773 15.3779 32.6537 14.1862C32.5344 12.9944 33.3693 11.9219 34.562 11.6835Z"
            fill="#464E57"
        />
        <path
            d="M64.1405 33.134C64.2598 33.7299 64.2598 34.3258 64.0213 34.3258L41.1222 40.9994C40.8837 41.1186 40.5259 40.6419 40.2873 40.046C40.1681 39.4502 40.1681 38.8543 40.4066 38.8543L63.3057 32.1807C63.5442 32.1807 63.902 32.5382 64.1405 33.134Z"
            fill="#B7B8BA"
        />
        <path
            d="M65.0948 36.4708C65.2141 37.0666 65.2141 37.6625 64.8563 37.6625L38.0214 45.4087C37.6636 45.5279 37.3058 45.0512 37.1866 44.4553C37.0673 43.8594 37.0673 43.2636 37.4251 43.2636L64.26 35.5174C64.4985 35.3982 64.8563 35.8749 65.0948 36.4708Z"
            fill="#B7B8BA"
        />
        <path
            d="M77.498 79.3727C77.6173 79.9685 77.498 80.4452 77.2595 80.5644L50.4246 88.3106C50.0668 88.4297 49.709 87.953 49.5898 87.4764C49.4705 86.8805 49.5898 86.4038 49.8283 86.2846L76.6632 78.5385C76.9017 78.4193 77.3787 78.7768 77.498 79.3727Z"
            fill="#B7B8BA"
        />
        <path
            d="M78.5713 82.9479C78.6905 83.5437 78.5713 84.0204 78.3327 84.1396L51.4979 91.8858C51.1401 92.0049 50.7823 91.5282 50.663 91.0515C50.5437 90.4557 50.663 89.979 50.9015 89.8598L77.7364 82.1137C77.9749 81.9945 78.3327 82.352 78.5713 82.9479Z"
            fill="#B7B8BA"
        />
        <path
            d="M70.9384 56.8495C71.0577 57.3261 70.9384 57.8028 70.6999 57.922L43.8651 65.6682C43.5073 65.7874 43.2687 65.4298 43.0302 64.9531C42.9109 64.4765 43.0302 63.9998 43.2687 63.8806L70.1036 56.1344C70.4614 56.0153 70.8192 56.3728 70.9384 56.8495Z"
            fill="#B7B8BA"
        />
        <path
            d="M71.8926 60.0672C72.0118 60.5439 71.8926 61.0206 71.654 61.0206L48.3971 67.6942C48.1586 67.8134 47.8008 67.4559 47.6816 66.9792C47.5623 66.5025 47.6816 66.0258 47.9201 66.0258L71.177 59.3522C71.4155 59.233 71.7733 59.5905 71.8926 60.0672Z"
            fill="#B7B8BA"
        />
        <path
            d="M69.9841 53.3934C70.1034 53.8701 69.9841 54.3468 69.7456 54.3468L46.7272 61.0204C46.4887 61.1396 46.1309 60.7821 46.0116 60.3054C45.8924 59.8287 46.0116 59.352 46.2502 59.352L69.2685 52.6784C69.507 52.5592 69.8648 52.9167 69.9841 53.3934Z"
            fill="#B7B8BA"
        />
        <path
            d="M32.892 38.4969L30.8645 37.3052C30.626 37.186 30.3875 37.3052 30.2682 37.4244L25.8554 45.1706L21.8003 43.0255C21.5618 42.9063 21.3232 43.0255 21.204 43.1446L20.0113 45.1706C19.892 45.4089 20.0113 45.6473 20.1306 45.7664L26.6902 49.3416C26.9287 49.4608 27.1673 49.3416 27.2865 49.2224L28.1214 47.7924L28.2407 47.554L28.4792 47.1965L33.1306 39.0928C33.1306 38.8545 33.1306 38.6161 32.892 38.4969Z"
            fill="#2E3640"
        />
        <path
            d="M39.81 62.4501L37.7825 61.2583C37.544 61.1392 37.3054 61.2583 37.1862 61.3775L32.7733 69.1237L28.7183 66.8594C28.4797 66.7403 28.2412 66.8594 28.1219 66.9786L26.9293 69.0045C26.81 69.2429 26.9293 69.4812 27.0486 69.6004L33.6082 73.1755C33.8467 73.2947 34.0852 73.1755 34.2045 73.0564L35.0394 71.6263L35.1586 71.388L35.3972 71.0304L40.0486 62.9268C40.0486 62.8076 40.0485 62.4501 39.81 62.4501Z"
            fill="#2E3640"
        />
        <path
            d="M46.4887 85.3309L44.4612 84.1392C44.2227 84.02 43.9841 84.1392 43.8649 84.2584L39.452 92.0046L35.397 89.7403C35.1585 89.6211 34.9199 89.7403 34.8007 89.8595L33.608 91.8854C33.4887 92.1237 33.608 92.3621 33.7273 92.4812L40.2869 96.0564C40.5254 96.1756 40.764 96.0564 40.8832 95.9372L41.7181 94.5072L41.8374 94.2688L42.0759 93.9113L46.7273 85.8076C46.7273 85.5693 46.608 85.3309 46.4887 85.3309Z"
            fill="#2E3640"
        />
        <path
            d="M10.2324 78.5379L47.9205 119.652C48.159 119.891 48.5168 119.891 48.7554 119.652L51.1407 117.507C51.3792 117.269 51.3792 116.911 51.1407 116.673L13.4526 75.5586L13.3333 75.4395L10.2324 78.5379C10.2324 78.4187 10.2324 78.4187 10.2324 78.5379Z"
            fill="#2E3640"
        />
        <path
            d="M50.1862 118.461L51.1404 117.626C51.3789 117.388 51.3789 117.03 51.1404 116.792L13.4523 75.5586L13.333 75.4395L12.3789 76.2737C12.3789 76.2737 12.3789 76.2737 12.4982 76.3928L50.1862 117.626C50.4248 117.865 50.4248 118.222 50.1862 118.461Z"
            fill="#52A1FF"
        />
        <path
            d="M11.4254 77.4652L49.1135 118.579C49.352 118.818 49.5905 118.937 49.7098 118.937L50.5447 118.222C50.6639 118.103 50.5447 117.864 50.3061 117.626L12.4988 76.3926C12.4988 76.3926 12.4988 76.3926 12.3795 76.2734L11.4254 77.4652C11.3061 77.346 11.3061 77.4652 11.4254 77.4652Z"
            fill="#0D6EFD"
        />
        <path
            d="M13.333 75.4396C13.333 75.4396 8.08525 72.5795 7.96599 72.8178C7.72745 72.937 10.1128 78.4189 10.1128 78.4189L13.333 75.4396Z"
            fill="#EAC78E"
        />
        <path
            d="M9.87402 73.6521C9.87402 73.6521 7.96576 72.6988 7.96576 72.8179C7.8465 72.9371 8.68136 74.8438 8.68136 74.8438L9.87402 73.6521Z"
            fill="#282928"
        />
        <path
            opacity="0.5"
            d="M93.4794 119.533C105.731 119.533 115.663 118.68 115.663 117.626C115.663 116.573 105.731 115.72 93.4794 115.72C81.2278 115.72 71.2959 116.573 71.2959 117.626C71.2959 118.68 81.2278 119.533 93.4794 119.533Z"
            fill="#48505A"
        />
        <path
            d="M120.911 113.098H64.2598C61.1589 113.098 58.6543 110.596 58.6543 107.497V60.0669C58.6543 55.0616 62.8286 50.8906 67.8378 50.8906H117.333C122.342 50.8906 126.517 55.0616 126.517 60.0669V107.378C126.517 110.596 124.012 113.098 120.911 113.098Z"
            fill="#CED6DA"
        />
        <path
            d="M126.636 58.0406V59.5898C126.636 54.5846 122.462 50.2944 117.453 50.2944H67.8379C62.8287 50.2944 58.5352 54.5846 58.5352 59.5898V58.0406C58.5352 52.9162 62.8287 48.626 67.9572 48.626H117.214C122.342 48.626 126.636 52.9162 126.636 58.0406Z"
            fill="#283244"
        />
        <path
            d="M126.636 59.3511V105.709C126.636 108.807 124.012 111.668 120.911 111.668H64.3792C61.2783 111.668 58.5352 108.927 58.5352 105.709V59.3511C58.5352 54.3459 62.8287 50.0557 67.8379 50.0557H117.333C122.462 50.0557 126.636 54.3459 126.636 59.3511Z"
            fill="#F1F1F1"
        />
        <path
            d="M126.636 59.3511V66.1439H58.5352V59.3511C58.5352 54.3459 62.8287 50.0557 67.8379 50.0557H117.333C122.462 50.0557 126.636 54.3459 126.636 59.3511Z"
            fill="#0D6EFD"
        />
        <path
            d="M68.7917 71.2685C69.648 71.2685 70.3421 70.5215 70.3421 69.6C70.3421 68.6786 69.648 67.9316 68.7917 67.9316C67.9354 67.9316 67.2412 68.6786 67.2412 69.6C67.2412 70.5215 67.9354 71.2685 68.7917 71.2685Z"
            fill="#121111"
        />
        <path
            d="M68.7918 61.3769C69.714 61.3769 70.4615 60.5766 70.4615 59.5893C70.4615 58.6021 69.714 57.8018 68.7918 57.8018C67.8696 57.8018 67.1221 58.6021 67.1221 59.5893C67.1221 60.5766 67.8696 61.3769 68.7918 61.3769Z"
            fill="#121111"
        />
        <path
            d="M116.38 71.2685C117.236 71.2685 117.93 70.5215 117.93 69.6C117.93 68.6786 117.236 67.9316 116.38 67.9316C115.523 67.9316 114.829 68.6786 114.829 69.6C114.829 70.5215 115.523 71.2685 116.38 71.2685Z"
            fill="#121111"
        />
        <path
            d="M116.379 61.3769C117.301 61.3769 118.048 60.5766 118.048 59.5893C118.048 58.6021 117.301 57.8018 116.379 57.8018C115.457 57.8018 114.709 58.6021 114.709 59.5893C114.709 60.5766 115.457 61.3769 116.379 61.3769Z"
            fill="#121111"
        />
        <path
            d="M68.9114 70.4347C68.1958 70.4347 67.7188 69.9581 67.7188 69.243V59.8284C67.7188 59.2326 68.1958 58.6367 68.9114 58.6367H69.0307C69.627 58.6367 70.2233 59.1134 70.2233 59.8284V69.243C69.9848 69.9581 69.5077 70.4347 68.9114 70.4347Z"
            fill="#C3C3C3"
        />
        <path
            d="M116.379 70.4347C115.664 70.4347 115.187 69.9581 115.187 69.243V59.8284C115.187 59.2326 115.664 58.6367 116.379 58.6367H116.498C117.095 58.6367 117.691 59.1134 117.691 59.8284V69.243C117.572 69.9581 116.976 70.4347 116.379 70.4347Z"
            fill="#C3C3C3"
        />
        <path
            d="M120.792 94.3877L104.811 111.191C104.811 111.191 105.407 108.212 105.407 106.305C105.407 105.113 105.168 104.041 105.049 102.372C104.811 97.6053 107.554 96.4136 108.866 95.6986C110.893 94.626 114.113 95.4602 116.141 95.3411C116.976 95.3411 120.196 95.2219 120.792 94.3877Z"
            fill="#B9B9BA"
        />
        <path
            d="M126.159 93.3154L104.811 111.191C104.811 111.191 105.884 107.735 106.122 105.59C106.242 104.16 106.122 102.968 106.122 100.942C106.361 95.3414 109.82 94.2688 111.489 93.5538C113.994 92.4812 117.93 93.7921 120.434 93.9113C121.389 94.0305 125.324 94.1496 126.159 93.3154Z"
            fill="#E7E5E5"
        />
        <path d="M74.7556 74.9629H65.6914V80.8023H74.7556V74.9629Z" fill="#CDD2D6" />
        <path d="M74.7556 85.4502H65.6914V91.2896H74.7556V85.4502Z" fill="#CDD2D6" />
        <path d="M89.1863 85.4502H80.1221V91.2896H89.1863V85.4502Z" fill="#EE5A58" />
        <path d="M103.499 85.4502H94.4346V91.2896H103.499V85.4502Z" fill="#CDD2D6" />
        <path d="M117.691 85.4502H108.627V91.2896H117.691V85.4502Z" fill="#CDD2D6" />
        <path d="M74.7556 95.9375H65.6914V101.777H74.7556V95.9375Z" fill="#CDD2D6" />
        <path d="M89.1863 95.9375H80.1221V101.777H89.1863V95.9375Z" fill="#CDD2D6" />
        <path d="M103.499 95.9375H94.4346V101.777H103.499V95.9375Z" fill="#CDD2D6" />
        <path d="M89.1863 74.9629H80.1221V80.8023H89.1863V74.9629Z" fill="#CDD2D6" />
        <path d="M103.499 74.9629H94.4346V80.8023H103.499V74.9629Z" fill="#CDD2D6" />
        <path d="M117.929 74.9629H108.865V80.8023H117.929V74.9629Z" fill="#CDD2D6" />
    </svg>
);
