import { Form, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { useHistory, useParams } from "react-router";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    CreatePasswordSvg,
    ShareButton,
    SharedInput,
    StyledCreatePassword,
    SwitchLang,
} from "@components";
import { IAdminChangePassword, IChangePassword, INitCreatePassword } from "@interfaces";
import { adminAPI } from "@api";
import { setLoading, selectAuth } from "@redux";
import { enumValidation, PATH_LOGIN } from "@configs";
import { useNotify } from "@utils";

export const PageChangePassword = () => {
    //page Hooks
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { success, error } = useNotify();
    const organization_id = useSelector(selectAuth).auth?.organization_data?._id;
    const adminId = useSelector(selectAuth).auth?.admin_data?._id;
    const email = useSelector(selectAuth).auth?.admin_data?.username;
    //page state
    const [toggleLoading, setToggleLoading] = useState<boolean>(false);
    //page variable
    const data: IAdminChangePassword = {
        admin_id: "",
        organization_id: "",
        old_password: "",
        new_password: "",
        confirm_password: "",
    };
    const createPasswordSchema = Yup.object().shape({
        new_password: Yup.string()
            .required(
                t("validation.required", {
                    returnObjects: true,
                    name: t("page.password"),
                })
            )
            .min(
                6,
                t("validation.min", {
                    returnObjects: true,
                    name: t("page.password"),
                    number: 6,
                })
            )
            .max(
                enumValidation.MAX_PASSWORD,
                t("validation.max_length_number", {
                    returnObjects: true,
                    name: t("page.password"),
                    number: enumValidation.MAX_PASSWORD,
                })
            ),
        confirm_password: Yup.string()
            .oneOf([Yup.ref("new_password"), null], t("validation.password_must_match"))
            .required(
                t("validation.required", {
                    returnObjects: true,
                    name: t("page.confirm_password"),
                })
            ),
    });

    const handleCreatePassword = async (
        values: IAdminChangePassword,
        { setSubmitting }: FormikHelpers<IAdminChangePassword>
    ) => {
        try {
            dispatch(setLoading(true));
            const res = await adminAPI.adminChangePassword({
                ...values,
                ...{ admin_id: adminId || "" },
                ...{ organization_id: organization_id || "" },
            });
            if (res.data.error === "0") {
                success(res.data.msg);
                history.push(PATH_LOGIN);
                dispatch(setLoading(false));
            } else {
                error(res.data.msg);
                dispatch(setLoading(false));
            }
        } catch (err: any) {
            if (err) {
                error(err.msg);
                dispatch(setLoading(false));
            }
        } finally {
            setSubmitting(false);
            dispatch(setLoading(false));
        }
    };

    //formik
    const { values, handleChange, handleBlur, handleSubmit, isSubmitting, errors, touched } =
        useFormik({
            initialValues: data,
            validationSchema: createPasswordSchema,
            onSubmit: handleCreatePassword,
        });
    return (
        <Spin spinning={toggleLoading} size="large">
            <StyledLayout>
                <div className="lang-switch">
                    <SwitchLang />
                </div>
                <StyledContainer>
                    <StyledCreatePassword>
                        <div className="password-icon-layout">
                            <CreatePasswordSvg />
                        </div>
                        <div className="title_welcome main-color">{`เปลี่ยนรหัสผ่าน`}</div>
                        {/* <div className="signing">{t("page.you_are_signing")}</div> */}
                        <div className="signing-email">
                            {`บัญชีของคุณคือ`} <span className="email">{email}</span>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <Form.Item colon={false} label={`รหัสผ่านเก่า*`} className="main-color">
                                <SharedInput
                                    name="old_password"
                                    style={{ width: "100%" }}
                                    type="password"
                                    onChange={handleChange}
                                    value={values.old_password}
                                    onBlur={handleBlur}
                                    errors={errors.old_password}
                                    touched={touched.old_password}
                                />
                            </Form.Item>
                            <Form.Item colon={false} label={`รหัสผ่านใหม่*`} className="main-color">
                                <SharedInput
                                    name="new_password"
                                    style={{ width: "100%" }}
                                    type="password"
                                    onChange={handleChange}
                                    value={values.new_password}
                                    onBlur={handleBlur}
                                    errors={errors.new_password}
                                    touched={touched.new_password}
                                />
                            </Form.Item>
                            <Form.Item
                                colon={false}
                                label={`ยืนยันรหัสผ่านใหม่*`}
                                className="main-color"
                            >
                                <SharedInput
                                    name="confirm_password"
                                    style={{ width: "100%" }}
                                    type="password"
                                    onChange={handleChange}
                                    value={values.confirm_password}
                                    onBlur={handleBlur}
                                    errors={errors.confirm_password}
                                    touched={touched.confirm_password}
                                />
                            </Form.Item>
                            <div className="btn-layout">
                                <ShareButton
                                    type="primary"
                                    size="large"
                                    text={t("page.continue")}
                                    htmlType="submit"
                                    disable={isSubmitting}
                                    className="btn-text"
                                />
                            </div>
                            {/* <div className="login_instead">
                                <span style={{ cursor: "pointer" }} onClick={handleMoveLogin}>
                                    {t("page.login")}
                                </span>{" "}
                            </div> */}
                        </form>
                    </StyledCreatePassword>
                </StyledContainer>
            </StyledLayout>
        </Spin>
    );
};
const StyledLayout = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
    align-items: end;
    @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
        display: unset;
    }
    .lang-switch {
        display: flex;
        flex-direction: row-reverse;
        margin-top: 30px;
        margin-right: 30px;
        @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            margin: 0;
        }
    }
`;
const StyledContainer = styled.div`
    background: #f7f7f8;
    text-align: center;
    max-width: 80vw;
    margin: auto;
    .err-input {
    }
    .err-text {
        font-weight: 500;
        color: ${(p) => p.theme.colors.danger};
        font-size: 12px;
    }
    .invalid-input {
        border-color: ${(p) => p.theme.colors.danger}!important;
        &:focus-within {
            border-color: ${(p) => p.theme.colors.danger}!important;
        }
    }
    .valid-input {
    }
    .ant-form-item-control {
        line-height: 1.5 !important;
    }
    @media (max-width: ${(p) => p.theme.breakPoints.breakMobilePhone}) {
        max-width: 100%;
        padding: 16px;
        margin: unset;
        height: 100%;
        padding: 0;
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
`;
