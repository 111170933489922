import styled from "styled-components";

export const StyledSignUp = styled.div`
    display: flex;
    justify-content: space-between;
    background: #fff;

    .signup-layout {
        flex: 4;
        text-align: center;
        max-height: 100vh;
        overflow-y: scroll;
        .signup-container {
            padding: 64px;
            max-width: 600px;
            margin: auto;
            .signup-header {
                display: flex;
                flex-direction: column;
                row-gap: 8px;
                margin-bottom: 48px;
                align-items: center;
            }
        }
        img {
            width: 120px;
            height: auto;
        }
        h3 {
            font-style: normal;
            font-weight: 600;
            font-size: 35px;
            line-height: 53px;
            text-align: center;
            color: #000000;
        }
        p {
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #8a959e;
        }

        .ant-form-item-label {
            width: 100% !important;
            text-align: left;
        }
        .ant-form-item {
            width: 450px;
            @media (max-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                width: 100%;
                input {
                    height: 48px;
                }
            }
        }
        input {
            border-color: #d9d9d9 !important;
            box-shadow: none !important;
        }
        .ant-form-item-label > label {
            font-weight: 600;
            font-size: 16px;
            line-height: 21px;
            color: #000000;
        }
        .ant-input {
            height: ${(p) => p.theme.heights.input};
            font-weight: 600;
            font-size: 20px;
            line-height: 27px;
            color: #000;
            padding: 15px;
            @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                font-size: 18px;
                height: 45px;
                padding: 10px;
            }
            @media (max-width: ${(p) => p.theme.breakPoints.breakMobilePhone}) {
                font-size: 16px;
            }
        }
        .main-color {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 27px;
            color: #a5a5a5;
            @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                font-size: 18px;
            }
            @media (max-width: ${(p) => p.theme.breakPoints.breakMobilePhone}) {
                font-size: 16px;
            }
        }
        .log_in {
            margin-top: 22px;
            text-decoration: none;
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            color: ${(p) => p.theme.colors.main};
            .have-account {
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                text-align: center;
                color: #a5a5a5;
                margin-right: 4px;
            }
            cursor: pointer;
            @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                font-size: 18px;
                margin-top: 25px;
                /* margin-bottom: 26px; */
            }
            @media (max-width: ${(p) => p.theme.breakPoints.breakMobilePhone}) {
                font-size: 16px;
                margin-top: 20px;
            }
        }
        .term_condition {
            display: flex;
            align-items: baseline;
            input {
                flex: 1;
            }
            p {
                flex: 16;
                text-align: initial;
                a {
                    text-decoration: none;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    color: #646464;
                    .color-blue {
                        color: #0263e0;
                    }
                }
            }
        }
    }
`;
