
import { HomeCloseIcon, SharedButtonSub, SharedInput, StyledCancelButton, StyledCard } from "@components";
import { Col, Row } from "antd";
import { IBannerData, IInfoData, IInfoDelete, IInfoItemUpdate } from "src/Interfaces/homeMenu";
import styled from "styled-components";
import { ModuleUploadImage } from "../form";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth, setLoading } from "@redux";
import { homeMenuAPI } from "@api";
import { showErrorMessage, useNotify } from "@utils";
import { ModuleAddSectionModal } from "./addSectionModal";
import { useState } from "react";
import { ModuleChangeTabModal, ModuleSectionDetail } from "@modules";

interface IProps {
  bannerData: IBannerData;
  setBanner: (data: IBannerData) => void;
  infoList: IInfoData[];
  setInfoList: (data: IInfoData[]) => void;
  infoType: string;
  fetchInfo: () => void;
}

export const ModuleHomeDetailForm = (props: IProps) => {
  const {
    bannerData,
    setBanner,
    infoList,
    infoType,
    setInfoList,
    fetchInfo,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const org_id = useSelector(selectAuth).auth?.organization_data?._id;
  const { error, success } = useNotify();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectRadio, setSelectRadio] = useState<number>(0);
  const [menuTab, setMenuTab] = useState<number>(0);
  const [isChangeData, setIsChangeData] = useState<boolean>(false);
  const [notSelectType, setNotSelectType] = useState<number[]>([]);
  const [openChangeTab, setOpenChangeTab] = useState<boolean>(false);
  const [newTab, setNewTab] = useState<number>(0);

  const uploadCoverImg = (images: string) => {
    setBanner({
      ...bannerData,
      image_url: images,
    });
  };



  const onChangeData = () => {
    if (isChangeData === false) {
      setIsChangeData(true);
    }
  };

  const changeCoverData = (e: any) => {
    let arr = bannerData;
    arr[e.target.id] = e.target.value;
    setBanner({ ...arr });
  };

  const saveCover = async () => {
    if (
      bannerData.detail_in !== "" &&
      bannerData.image_url !== "" &&
      bannerData.text_in !== ""
    ) {
      try {
        dispatch(setLoading(true));
        if (org_id) {
          let raw = {
            ...bannerData,
            organization_id: org_id || ""
          };
          await homeMenuAPI.updateBanner(raw);
          success(t("message.create.success"));
        }
      } catch (err: any) {
        if (err.response) {
          error(showErrorMessage(err.response));
        } else error(t("message.error"));
      } finally {
        dispatch(setLoading(false));
      }
    }
  };

  const addInfoItem = () => {
    // setOpenModal(true);
    try {
      dispatch(setLoading(true));
      let arr = infoList;
      arr.push({
        organization_id: org_id || "",
        header: "header",
        info_type: infoType,
        is_delete: false,
        is_enable: true,
        created_date: "",
        created_by: "",
        updated_date: "",
        updated_by: "",
        deleted_date: "",
        deleted_by: "",
        _id: "",
        detail_list: [],
      });
      onChangeData();
      setInfoList([...arr]);
      setMenuTab(arr.length - 1);

    } catch {
      error("เพื่มไม่สำเร็จ");
    } finally {
      dispatch(setLoading(false));
    }

  };

  const clickAddSection = () => {
    setOpenModal(true);
  };

  const addNewDetail = () => {
    if (infoList[menuTab]?.detail_list) {
      dispatch(setLoading(true));
      let arr: IInfoData[] = infoList;
      arr[menuTab].detail_list.push({
        detail_type: selectRadio.toString(),
        image_url: "",
        fouth_type_data: {
          title: "",
          is_single: true,
          docs: [],
        },
        html_text: "",
        fifth_type_data: [],
        _id: ""
      });
      if (selectRadio === 4) {
        setNotSelectType([...notSelectType, arr[menuTab].detail_list.length - 1]);
      }
      onChangeData();
      setSelectRadio(0);
      setOpenModal(false);
      dispatch(setLoading(false));
    }
  };

  const clickTab = (index: number) => {
    if (index !== menuTab) {
      try {
        setNewTab(index);
        if (isChangeData) {
          console.log('test', menuTab);
          setOpenChangeTab(true);
        } else {
          setMenuTab(index);
          setIsChangeData(false);
        }
      } catch {
        console.log('err');
      }
    }
  };

  const notSaveChangeTab = () => {
    setMenuTab(newTab);
    fetchInfo();
    setOpenChangeTab(false);
    setIsChangeData(false);

  };

  const onSaveChangeTab = async (detail: IInfoData) => {
    // setMenuTab(newTab);
    console.log('detail', detail);
    try {
      dispatch(setLoading(true));
      await saveInfoDetail(detail);
      setMenuTab(newTab);
      fetchInfo();
      setIsChangeData(false);
    } catch (err: any) {
      error("บันทึกไม่สำเร็จ");
      console.log(err);
    } finally {
      setOpenChangeTab(false);
      dispatch(setLoading(false));
    }
  };

  const deleteTab = async (index: number) => {
    console.log('delete tab', index);
    if (infoList[index]._id === "") {

      let arr = infoList;
      arr = arr.filter((data, id: number) => id !== index);
      console.log('arr', arr);
      console.log('infoList', infoList.length);
      setInfoList([...arr]);
      // setInfo([...arr]);
    } else {
      try {
        let raw: IInfoDelete = {
          organization_id: org_id || "",
          home_information_id: infoList[index]._id,
        };
        await homeMenuAPI.deleteInfoItem(raw);
        success(t("message.delete.success"));
        fetchInfo();
        setMenuTab(0);
      } catch (err: any) {
        if (err.response) {
          error(showErrorMessage(err.response));
        } else error(t("message.error"));
      } finally {
        dispatch(setLoading(false));
      }
    }
  };

  const saveInfoDetail = async (detail: IInfoData) => {
    if (detail?._id !== "") {
      setIsChangeData(false);
      try {
        if (detail?.header !== "" &&
          detail?.header !== undefined &&
          detail?._id !== undefined
        ) {

          let arr = detail.detail_list.map(function (item) {
            if (item._id !== undefined) {
              delete item._id;
            }
            return item;
          });
          let raw: IInfoItemUpdate = {
            organization_id: org_id || "",
            header: detail?.header,
            info_type: detail?.info_type,
            is_enable: detail?.is_enable,
            detail_list: arr,
            home_information_id: detail?._id,
          };
          await homeMenuAPI.updateInfoItem(raw);

          success("แก้ไขสำเร็จ");
          fetchInfo();
        }
      } catch (err: any) {
        if (err.response) {
          error(showErrorMessage(err.response));
        } else error(t("message.error"));
      } finally {
        dispatch(setLoading(false));
      }
    } else {
      try {
        if (
          detail?.header !== "" &&
          detail?.header !== undefined
        ) {

          let arr = detail.detail_list.map(function (item) {
            if (item._id !== undefined) {
              delete item._id;
            }
            return item;
          });

          let raw = {
            organization_id: org_id || "",
            header: detail?.header,
            info_type: detail?.info_type,
            is_enable: detail?.is_enable,
            detail_list: arr,
          };
          await homeMenuAPI.createInfoItem(raw);
          success(t("message.create.success"));
          fetchInfo();
        }
      } catch (err: any) {
        if (err.response) {
          error(showErrorMessage(err.response));
        } else error(t("message.error"));
      } finally {
        dispatch(setLoading(false));
      }
    }
  };

  return (
    <div className="form-wrap">
      <div className="info-card">
        <div className="page-header">
          <h3>เมนูแนะนำเทศบาล</h3>
          <p>โปรดระบุรายละเอียดให้ครบถ้วน</p>
        </div>
        <StyledCard maxWidth="100%">
          <StyledBannerSection>
            <div className="header">
              <p className="title">{t("page.homeMenu.cover_headline")}</p>
              <SharedButtonSub
                type="default"
                style={{
                  fontSize: "16px",
                  lineHeight: "0px",
                  height: "40px",
                }}
                text={t("page.save")}
                htmlType="submit"
                onClick={saveCover}
              />
            </div>
          </StyledBannerSection>
          <Row gutter={16}>
            <Col xs={12} sm={12} md={24}>
              <ModuleUploadImage
                title={t("page.homeMenu.cover_img")}
                images={bannerData.image_url}
                IsCanResetImage={true}
                onResetImage={() => {
                  setBanner({ ...bannerData, image_url: "" });
                }}
                handleGetImage={uploadCoverImg}
              />
              <br />
              <br />
              <SharedInput
                label={t("page.homeMenu.cover_text")}
                name="text_in"
                onChange={changeCoverData}
                value={bannerData.text_in}
                errors={bannerData.text_in}
                parentClassName="mb-6"
              />
              <SharedInput
                label={t("page.homeMenu.cover_detail")}
                name="detail_in"
                onChange={changeCoverData}
                value={bannerData.detail_in}
                errors={bannerData.detail_in}
                parentClassName="mb-6"
              />
            </Col>
          </Row>
        </StyledCard>
        <br />
        <br />
        <StyledPageBody>
          <div>
            <StyledMenuHeader>
              <div className="header">
                <p className="title">{t("page.homeMenu.introduce_headline")}</p>
                <SharedButtonSub
                  type="default"
                  style={{
                    fontSize: "16px",
                    lineHeight: "0px",
                    height: "40px",
                  }}
                  text="เพิ่มเมนูอื่นๆ"
                  // htmlType="submit"
                  onClick={addInfoItem}
                />
              </div>
            </StyledMenuHeader>
            <p>
              สามารถเพิ่มเมนูรายละเอียดได้มากกว่า 1 เมนู โดยกดเลือกปุ่ม
              “เพิ่มเมนูอื่นๆ”
            </p>
          </div>
        </StyledPageBody>
        <ModuleAddSectionModal
          openModal={openModal}
          selectRadio={selectRadio}
          setOpenModal={setOpenModal}
          setSelectRadio={setSelectRadio}
          addNewDetail={addNewDetail}
        />
        <StyleMenuTab>
          <div className="parent-tab">
            {infoList.length > 0 &&
              infoList?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`menu-tab ${menuTab === index && "selected"}`}
                  >
                    <span onClick={() => clickTab(index)}>
                      {item.header}
                    </span>
                    <span
                      className="delete-icon"
                      onClick={() => deleteTab(index)}
                    >
                      <HomeCloseIcon />
                    </span>
                  </div>
                );
              })
            }
          </div>

        </StyleMenuTab>
        {infoList?.length > 0 &&
          <ModuleSectionDetail
            infoProps={infoList[menuTab]}
            handleChangeData={onChangeData}
            menuTab={menuTab}
            notSelectType={notSelectType}
            setNotSelectType={setNotSelectType}
            saveInfoDetail={saveInfoDetail}
            openChangeTab={openChangeTab}
            notSaveChangeTab={notSaveChangeTab}
            onSaveChangeTab={onSaveChangeTab}
          />
        }
        <br />
        <StyledCancelButton
          type="sub"
          text="+ เพิ่มรายละเอียด"
          htmlType="button"
          onClick={clickAddSection}
          style={{ backgroundColor: "white", width: "200px" }}
        />
      </div>

    </div>
  );
};

const StyledPageBody = styled.div`
  display: grid;
  grid-gap: 15px;
`;

const StyledBannerSection = styled.div`
  .title {
    font-weight: 700;
    font-size: 35px;
    line-height: 38px;
    color: black;
  }
  .header {
    display: flex;
    justify-content: space-between;
    .switch {
      margin: 0px 10px;
    }
  }
  .disabled {
    background-color: #e4e4e4;
  }
`;

const StyledMenuHeader = styled.div`
  .title {
    font-weight: 700;
    font-size: 35px;
    line-height: 38px;
    color: black;
  }
  .header {
    display: flex;
    justify-content: space-between;
    .switch {
      margin: 0px 10px;
    }
  }
  .disabled {
    background-color: #e4e4e4;
  }
`;


const StyleMenuTab = styled.div`
  .parent-tab::-webkit-scrollbar {
    width: 0px;
    // background: transparent; /* make scrollbar transparent */
    display: none;
  }
    .parent-tab {
      position: relative;
      width: 100%;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      /* display: flex; */
      
    }
    .menu-tab {
      display: table-cell;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      margin-right: 10px;
      padding: 10px;
      cursor: pointer;
      border-top: 5px solid #ffffff00;
    }
    .delete-icon {
        margin-left: 10px;
    }
    .selected {
        background-color: #fff;
        border-top: 5px solid #0d6efd;
        color: #0d6efd;
        border-radius: 5px 5px 0 0;
    }
`;