export const GoogleLoginIcon = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.0004 6.18667C19.0048 6.18667 21.0315 7.48445 22.187 8.56889L26.7026 4.16C23.9293 1.58222 20.3204 0 16.0004 0C9.74259 0 4.33814 3.59111 1.70703 8.81778L6.88036 12.8356C8.17814 8.97778 11.7693 6.18667 16.0004 6.18667Z"
            fill="#D3261F"
        />
        <path
            d="M31.36 16.3555C31.36 15.04 31.2533 14.08 31.0222 13.0844H16V19.0222H24.8178C24.64 20.4978 23.68 22.72 21.5467 24.2133L26.5956 28.1244C29.6178 25.3333 31.36 21.2267 31.36 16.3555Z"
            fill="#2E67EA"
        />
        <path
            d="M6.89778 19.1645C6.56 18.1689 6.36444 17.1022 6.36444 16C6.36444 14.8978 6.56 13.8311 6.88 12.8356L1.70667 8.81779C0.622222 10.9867 0 13.4222 0 16C0 18.5778 0.622222 21.0134 1.70667 23.1822L6.89778 19.1645Z"
            fill="#EAA600"
        />
        <path
            d="M16.0002 32C20.3202 32 23.9468 30.5778 26.5957 28.1245L21.5468 24.2134C20.1957 25.1556 18.3824 25.8134 16.0002 25.8134C11.7691 25.8134 8.17794 23.0223 6.89794 19.1645L1.72461 23.1823C4.35572 28.4089 9.74239 32 16.0002 32Z"
            fill="#319437"
        />
    </svg>
);
