export const FBShare = () => (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M20.9999 41.5799C32.3659 41.5799 41.5799 32.3659 41.5799 20.9999C41.5799 9.6339 32.3659 0.419922 20.9999 0.419922C9.6339 0.419922 0.419922 9.6339 0.419922 20.9999C0.419922 32.3659 9.6339 41.5799 20.9999 41.5799Z"
            fill="#195FE1"
        />
        <path
            d="M18.8041 31.343V21.9073H15.6289V18.2298H18.8041V15.5182C18.8041 12.3708 20.7269 10.6572 23.5341 10.6572C24.4819 10.6537 25.4292 10.7019 26.3717 10.8016V14.0911H24.4245C22.8974 14.0911 22.6017 14.8168 22.6017 15.8811V18.2331H26.2431L25.7694 21.9106H22.6017V31.343H18.8041Z"
            fill="white"
        />
    </svg>
);
