import styled from "styled-components";

export const InfoPage = styled.div`
    padding: 60px 34px;
    .form-wrap {
        height: initial;
    }
    form {
        padding: 0;
    }
`;
