import styled from "styled-components";

export const StyledInfoTable = styled.div`
    h5,
    p {
        margin: 0;
    }
    .page-body {
        display: flex;
        column-gap: 20px;
        width: 1200px;
        /* @media (max-width: ${(p) => p.theme.breakPoints.break13Laptop}) {
            width: 1000px;
        } */
        @media (max-width: ${(p) => p.theme.breakPoints.break14Laptop}) {
            width: 1000px;
        }
        @media (max-width: ${(p) => p.theme.breakPoints.breakMacLaptop}) {
            width: 1000px;
        }

        .left-side {
            flex: 4;
        }
        .right-side {
            flex: 3;
        }
    }
    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        h5 {
            font-style: normal;
            font-weight: 500;
            font-size: 25px;
            line-height: 38px;
            color: #000000;
        }
    }
    .table-header {
        margin-bottom: 16px;
        .col-item {
            display: flex;
            align-items: center;
            column-gap: 6px;
            p {
                font-style: normal;
                font-weight: 800;
                font-size: 12px;
                line-height: 16px;
                color: #a5a5a5;
            }
            .filter-button {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
    }
    .table-body {
        .ant-row {
            padding: 24px;
            margin-bottom: 16px;
            border: 0.5px solid transparent;
            border-radius: 4px;
            border-bottom: 1px solid #f3f3f3;
            &:hover {
                background: #f7f9fc;
                border: 0.5px solid #8a959e;
                box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.08);
                border-radius: 4px;
            }
            .col-item {
                p {
                    font-style: normal;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 24px;
                    color: #29304d;
                }
                &.row-title {
                    p {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                        color: #29304d;
                    }
                }
                &.icon {
                    display: flex;
                    justify-content: end;
                }
            }
        }
    }
    .detail-info {
        min-width: 500px;
        background: #fff;
        padding: 36px 60px;
        border-radius: 8px;
        .detail-title {
            font-style: normal;
            font-weight: 500;
            font-size: 25px;
            line-height: 38px;
            color: #000000;
            padding-bottom: 16px;
            border-bottom: 0.5px solid #8a959e;
        }
        .info-layout {
            display: flex;
            .left {
                flex: 1;
            }
            .right {
                flex: 1;
            }
        }
        .item {
            padding-top: 16px;
            .label {
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 24px;
                color: #8a959e;
            }
            .value {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #29304d;
                display: flex;
                align-items: center;
                column-gap: 8px;
            }
        }
    }
`;
