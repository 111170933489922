import { useEffect, memo, useState, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Row, Col, Select, Modal } from "antd";
import styled from "styled-components";
import moment from "moment";
import { IContactListParams, IDocumentUpdate, IResultCreate, IResultUpdate } from "@interfaces";
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import {
  StyledCancelButton,
  StyledCard,
  SharedInput,
  SharedButtonSub,
  ComponentRowShortCut,
} from "@components";
import { useYup } from "@validations";
import { setLoading, selectAuth, selectContact } from "@redux";
import { ModuleUploadImage } from "@modules";
import { showErrorMessage, useNotify } from "@utils";
import { PATH_BIDDING_RESULT_LIST } from "@configs";
import { biddingAPI, homeMenuAPI } from "@api";
import ScrollContainer from "react-indiana-drag-scroll";
import {
  ICalendarDataGet,
  ICoverDataUpdate,
  IDecorData,
  IHomeNewsItem,
  IHomeNewsItemUpdate,
  IHomeNewsPatch,
  IHomeOrganization,
  IShortCutMenuItem,
  IShortCutPatch
} from "src/Interfaces/homeMenu";
import { ModuleUploadImageWithID } from "../form/UploadImagesWithId";

interface IProps {
  organization: IHomeOrganization | undefined;
}
const newDate = new Date();
const initData: IResultUpdate = {
  organization_id: "",
  title: "",
  image_url: "",
  is_enable: true,
  information_id: "",
  created_date: new Date().toString(),
  documents: [],
};

const initCoverData: ICoverDataUpdate = {
  image_url: "",
  detail_in: "",
  text_in: "",
  organization_id: "",
};

const initCalendarData: ICalendarDataGet = {
  organization_id: "",
  image_url: "",
  text_in: "",
};

const initShortcutItem: IShortCutMenuItem = {
  name: "",
  link_url: "",
  image_url: "",
  _id: "",
};

const initDecorData: IDecorData = {
  organization_id: "",
  detail_in: "",
  text_in: "",
  image_url: "",
};

const { Option } = Select;
export const ModuleHomeMenuForm = memo((props: IProps) => {
  //hook
  const { YupNews, YupBidding } = useYup();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { error, success } = useNotify();
  const search_params = useSelector(selectContact).paramsContact;
  const [params, setParams] = useState<IContactListParams>(search_params);
  const [showModal, setShowModal] = useState<boolean>(false);
  const organization_id = useSelector(selectAuth).auth?.admin_data?.organization_id;
  const searchInputRef = useRef<any | null>(null);
  const pathName = window.location.pathname;
  const createNews = Yup.object().shape(YupNews);
  const createBidding = Yup.object().shape(YupBidding);
  const { organization } = props;
  const [menuTab, setMenuTab] = useState(0);
  const [fileName, setFileName] = useState<string>("");
  const [fileList, setFileList] = useState<IDocumentUpdate[]>([]);
  const [coverValues, setCoverValues] = useState<ICoverDataUpdate>(initCoverData);
  const [shortCuts, setShortCuts] = useState<IShortCutMenuItem[]>([]);
  const [homeNews, setHomeNews] = useState<IHomeNewsItem[]>([]);
  const [calendarValues, setCalendarValues] = useState<ICalendarDataGet>(initCalendarData);
  const [decorValues, setDecorValues] = useState<IDecorData>(initDecorData);
  useEffect(() => {
    if (organization) {
      // console.log("res", organization);

      // console.log("decorate_data", organization.decorate_data);
      setCoverValues({
        ...organization.cover_data,
        organization_id: organization_id,
      });

      const arrSC = Array(6).fill(initShortcutItem);
      organization.menu_shortcuts.map((item, index) => {
        arrSC[index] = item;
      });
      setShortCuts(arrSC);
      setHomeNews(organization.general_informations);
      setCalendarValues({
        ...organization.calendar_data,
        organization_id: organization_id
      });
      setDecorValues({
        ...organization.decorate_data,
        organization_id: organization_id
      });
    }
  }, [organization]);

  const addHomeNews = () => {
    const arr = homeNews;
    arr.push({
      link_url: "",
      image_url: "",
      _id: "",
    });
    setHomeNews([...arr]);
  };

  const saveHomeNews = async () => {
    let arr: IHomeNewsItemUpdate[] = [];
    for (let i = 0; i < homeNews.length; i++) {
      if (homeNews[i].image_url !== "") {
        arr.push({
          link_url: homeNews[i].link_url,
          image_url: homeNews[i].image_url
        });
      }

    };
    if (organization_id) {
      let raw: IHomeNewsPatch = {
        organization_id: organization_id,
        general_informations: arr
      };
      try {
        await homeMenuAPI.updateHomeNewsData(raw);
        success(t("message.create.success"));
      }
      catch (err: any) {
        if (err.response) {
          error(showErrorMessage(err.response));
        } else error(t("message.error"));
      } finally {
        dispatch(setLoading(false));
      }
    }
  };

  const uploadHomeNewsImg = (images: string, id: number) => {
    // console.log(id, images);
    let arr = homeNews;
    arr[id].image_url = images;
    setHomeNews([...arr]);
  };

  const changeHomeNews = (item: any, index: number, e: any) => {
    let arr = homeNews;
    arr[index].link_url = e.target.value;
    setHomeNews([...arr]);
  };
  const saveCover = async () => {
    if (
      coverValues.detail_in !== "" &&
      coverValues.image_url !== "" &&
      coverValues.text_in !== ""
    ) {
      try {
        await homeMenuAPI.updateCoverData(coverValues);
        success(t("message.create.success"));
      } catch (err: any) {
        if (err.response) {
          error(showErrorMessage(err.response));
        } else error(t("message.error"));
      } finally {
        dispatch(setLoading(false));
      }
    }
  };

  const saveDecor = async () => {
    if (
      decorValues.detail_in !== "" &&
      decorValues.image_url !== "" &&
      decorValues.text_in !== ""
    ) {
      try {
        await homeMenuAPI.updateDecorData(decorValues);
        success(t("message.create.success"));
      } catch (err: any) {
        if (err.response) {
          error(showErrorMessage(err.response));
        } else error(t("message.error"));
      } finally {
        dispatch(setLoading(false));
      }
    }
  };

  const handleBiddingSubmit = (values: IResultUpdate) => {
    console.log("hello submit", values);
  };

  const addMoreFile = () => {
    // console.log("fl", fileList);
    const arrFileList = fileList;
    arrFileList.push({
      document_name: "",
      document_url: "",
    });
    setFileList([...arrFileList]);
  };

  const handleClose = () => {
    history.push(PATH_BIDDING_RESULT_LIST);
  };

  const { values, handleChange, handleBlur, handleSubmit, errors, touched, setFieldValue } =
    useFormik({
      initialValues: initData,
      validationSchema: createBidding,
      enableReinitialize: true,
      onSubmit: handleBiddingSubmit,
    });

  const handleChangeDateFrom = (date: Date | string) => {
    setFieldValue("bid_date_from", moment(date).toISOString());
  };

  const handleSort = () => {
    // console.log("sort");
  };

  const handleChangeDateTo = (date: Date | string) => {
    setFieldValue("bid_date_to", moment(date).toISOString());
  };

  const handleChangeGuideLine = (event: any, editors: any) => {
    const data = editors.getData();
    setFieldValue("detail", data);
  };

  const changeCoverData = (e: any) => {
    let arr = coverValues;
    arr[e.target.id] = e.target.value;
    setCoverValues({ ...arr });
  };

  const uploadCoverImg = (images: string) => {
    setCoverValues({
      ...coverValues,
      image_url: images,
    });
  };

  const uploadDecorImg = (images: string) => {
    setDecorValues({
      ...decorValues,
      image_url: images,
    });
  };

  const changeDecorData = (e: any) => {
    let arr = decorValues;
    arr[e.target.id] = e.target.value;
    setDecorValues({ ...arr });
  };

  const uploadCalendarImg = (images: string) => {
    setCalendarValues({
      ...calendarValues,
      image_url: images
    });
  };

  const changeCalendarData = (e: any) => {
    let arr = calendarValues;
    arr[e.target.id] = e.target.value;
    setCalendarValues({ ...arr });
  };

  const saveCalendar = async () => {
    if (
      calendarValues.image_url !== "" &&
      calendarValues.text_in !== ""
    ) {
      try {
        await homeMenuAPI.updateCalendarData(calendarValues);
        success(t("message.create.success"));
      } catch (err: any) {
        if (err.response) {
          error(showErrorMessage(err.response));
        } else error(t("message.error"));
      } finally {
        dispatch(setLoading(false));
      }
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(TouchSensor)
  );

  const onGetFile = (file: string, index: number) => {
    let fileUrlSplit = file.split("/");
    let pdfName = fileUrlSplit[fileUrlSplit.length - 1];
    let nameOnly = pdfName.split("-").slice(1).join();
    // console.log(index, pdfName, file, fileList[index]);
    const arrFileList = fileList;
    arrFileList[index] = {
      document_name: nameOnly,
      document_url: file,
    };
  };

  const setShortCutsItem = (item: IShortCutMenuItem, index: number) => {
    let arr = shortCuts;
    arr[index] = item;
    setShortCuts([...arr]);
  };


  const saveShortCuts = async () => {
    let arr = shortCuts.map(function (item) {
      delete item._id;
      return item;
    });
    arr = arr.filter((item: any) => {
      if (item.name !== "" && item.image_url !== "", item.link_url !== "") {
        return item;
      }
    });
    if (organization_id) {
      let raw: IShortCutPatch = {
        organization_id: organization_id,
        menu_shortcuts: arr
      };
      try {
        await homeMenuAPI.updateShortcutsData(raw);
        success(t("message.create.success"));
      } catch (err: any) {
        if (err.response) {
          error(showErrorMessage(err.response));
        } else error(t("message.error"));
      } finally {
        dispatch(setLoading(false));
      }
    }
  };

  const saveResult = async () => {
    // dispatch(setLoading(true));
    try {
      const payload: IResultCreate = {
        organization_id: organization_id || "",
        title: values.title,
        image_url: values.image_url,
        documents: fileList,
        is_enable: true,
      };
      await biddingAPI.createResult(payload);

      success(t("message.create.success"));
      handleClose();
    } catch (err: any) {
      if (err.response) {
        error(showErrorMessage(err.response));
      } else error(t("message.error"));
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <div className="form-wrap">

      {/* เมนูแนะนำเทศบาล */}
      <form onSubmit={handleSubmit}>
        <div className="page-header">
          <h3>{t("page.homeMenu.home_menu_headline")}</h3>
        </div>
        {/* รายละเอียดหน้าปก */}
        <StyledPageBody>
          <StyledCard>
            <StyledBidderList>
              <div className="header">
                <p className="title">{t("page.homeMenu.cover_headline")}</p>
                <SharedButtonSub
                  type="default"
                  style={{
                    fontSize: "16px",
                    lineHeight: "0px",
                    height: "40px",
                  }}
                  text={t("page.save")}
                  htmlType="submit"
                  onClick={saveCover}
                />
              </div>
            </StyledBidderList>
            <div>
              <Row gutter={16}>
                <Col xs={12} sm={12} md={24}>
                  <ModuleUploadImage
                    title={t("page.homeMenu.cover_img")}
                    images={coverValues.image_url}
                    IsCanResetImage={true}
                    onResetImage={() => {
                      setCoverValues({ ...coverValues, image_url: "" });
                    }}
                    handleGetImage={uploadCoverImg}
                  />
                  <br />
                  <br />
                  <SharedInput
                    // label={t("page.bidding.title_headline")}
                    label={t("page.homeMenu.cover_text")}
                    // descLabel={t("page._2_100_characters")}
                    name="text_in"
                    onBlur={handleBlur}
                    onChange={changeCoverData}
                    value={coverValues.text_in}
                    errors={coverValues.text_in}
                    // touched={touched.title}
                    parentClassName="mb-6"
                  />
                  <SharedInput
                    // label={t("page.bidding.title_headline")}
                    label={t("page.homeMenu.cover_detail")}
                    // descLabel={t("page._2_100_characters")}
                    // name="desc"
                    name="detail_in"
                    onBlur={handleBlur}
                    onChange={changeCoverData}
                    value={coverValues.detail_in}
                    errors={coverValues.detail_in}
                    // touched={touched.title}
                    parentClassName="mb-6"
                  />
                </Col>
              </Row>
            </div>
            <br />
          </StyledCard>
        </StyledPageBody>
        <br></br>
        {/* เมนูทางลัด */}
        <StyledPageBody>
          <StyledCard>
            <StyledBidderList>
              <div className="header">
                <p className="title">{t("page.homeMenu.short_menu_headline")}</p>
                <SharedButtonSub
                  type="default"
                  style={{
                    fontSize: "16px",
                    lineHeight: "0px",
                    height: "40px",
                  }}
                  text={t("page.save")}
                  htmlType="submit"
                  onClick={saveShortCuts}
                />
              </div>
            </StyledBidderList>
            <div>
              <Row gutter={16}>
                <Col xs={12} sm={12} md={24}>
                  <div className="table">
                    <ScrollContainer vertical={false}>
                      <div className="table_ground">
                        <div className="table-body">
                          <div className="table-header">
                            <Row
                              gutter={24}
                              type="flex"
                              justify="space-between"
                              align="middle"
                              className="header-row"
                            >
                              <Col className="col-item" span={5}>
                                {/* <p>{t("page.bidding.bidding_headline")}</p> */}
                                <p>{t("page.homeMenu.menu_num")}</p>
                              </Col>
                              <Col className="col-item" span={3}>
                                {/* <p>{t("page.bidding.document_amount")}</p> */}
                                <p>{t("page.homeMenu.menu_name")}</p>
                              </Col>

                              <Col className="col-item" span={3}>
                                {/* <p>{t("page.bidding.update_day")}</p> */}
                                <p>{t("page.homeMenu.menu_link")}</p>
                              </Col>
                              <Col className="col-item" span={3}>
                                {/* <p>{t("page.active")}</p> */}
                                <p>{t("page.homeMenu.menu_menu")}</p>
                              </Col>
                            </Row>
                          </div>
                          <DndContext
                            sensors={sensors}
                            collisionDetection={closestCenter}
                          // onDragEnd={handleDragEnd}
                          >
                            {shortCuts.map((item, index) => {
                              return (
                                <ComponentRowShortCut
                                  key={index}
                                  data={item}
                                  itemJson={JSON.stringify(item)}
                                  index={index}
                                  setItem={setShortCutsItem}
                                />
                              );
                            })}
                          </DndContext>
                        </div>
                      </div>
                    </ScrollContainer>
                  </div>
                </Col>
              </Row>
            </div>

            <br />
          </StyledCard>
        </StyledPageBody>
        <br></br>
        {/* ประชาสัมพันธ์เทศบาล* */}
        <StyledPageBody>
          <StyledCard>
            <StyledBidderList>
              <div className="header">
                <p className="title">{t("page.homeMenu.gen_headline")}</p>
                <SharedButtonSub
                  type="default"
                  style={{
                    fontSize: "16px",
                    lineHeight: "0px",
                    height: "40px",
                  }}
                  text={t("page.save")}
                  htmlType="submit"
                  onClick={saveHomeNews}
                />
              </div>
            </StyledBidderList>
            {homeNews?.map((item, index) => {
              return (
                <StyledNews key={index}>
                  <div className="img">
                    <ModuleUploadImageWithID
                      title=""
                      id={index}
                      images={item.image_url}
                      // images=""
                      IsCanResetImage={true}
                      onResetImage={(id: number) => {
                        let arr = homeNews;
                        arr[id].image_url = "";
                        setHomeNews([...arr]);
                        // setHomeNews({...homeNews, {

                        // }})
                        // setCoverValues({ ...coverValues, image_url: '' });
                      }}
                      handleGetImage={uploadHomeNewsImg}
                    />
                  </div>
                  <div className="url">
                    <SharedInput
                      // label={t("page.bidding.title_headline")}
                      label="url*"
                      // descLabel={t("page._2_100_characters")}
                      name="text_in"
                      onBlur={handleBlur}
                      onChange={(e) => changeHomeNews(item, index, e)}
                      value={item.link_url}
                      // errors={item.link_url}
                      // touched={touched.title}
                      parentClassName="mb-6"
                    />
                  </div>
                </StyledNews>
              );
            })}
            <br />
            <StyledCancelButton
              type="sub"
              text={t("page.homeMenu.gen_add_more")}
              htmlType="button"
              onClick={addHomeNews}
            />
          </StyledCard>
        </StyledPageBody>
        <br></br>
        { /* ปฏิทินกิจกรรม */}
        <StyledPageBody>
          <StyledCard>
            <StyledBidderList>
              <div className="header">
                <p className="title">{t("page.homeMenu.calendar_headline")}</p>
                <SharedButtonSub
                  type="default"
                  style={{
                    fontSize: "16px",
                    lineHeight: "0px",
                    height: "40px",
                  }}
                  text={t("page.save")}
                  htmlType="submit"
                  onClick={saveCalendar}
                />
              </div>
            </StyledBidderList>
            <div>
              <Row gutter={16}>
                <Col xs={12} sm={12} md={24}>
                  <ModuleUploadImage
                    title={t("page.homeMenu.calendar_image")}
                    images={calendarValues.image_url}
                    // images=""
                    IsCanResetImage={true}
                    onResetImage={() => {
                      setCalendarValues({ ...calendarValues, image_url: "" });
                    }}
                    handleGetImage={uploadCalendarImg}
                  />
                  <br />
                  <br />
                  <SharedInput
                    label={t("page.homeMenu.calendar_name")}
                    // descLabel={t("page._2_100_characters")}
                    // name="desc"

                    name="text_in"
                    onBlur={handleBlur}
                    onChange={changeCalendarData}
                    value={calendarValues.text_in}
                    errors={calendarValues.text_in}
                    // touched={touched.title}
                    parentClassName="mb-6"
                  />
                </Col>
              </Row>
            </div>
            <br />
          </StyledCard>
        </StyledPageBody>
        <br />
        {/** รูปภาพประกอบ */}
        <StyledPageBody>
          <StyledCard>
            <StyledBidderList>
              <div className="header">
                <p className="title">{t("page.homeMenu.decor_headline")}</p>
                <SharedButtonSub
                  type="default"
                  style={{
                    fontSize: "16px",
                    lineHeight: "0px",
                    height: "40px",
                  }}
                  text={t("page.save")}
                  htmlType="submit"
                  onClick={saveDecor}
                />
              </div>
            </StyledBidderList>
            <div>
              <Row gutter={16}>
                <Col xs={12} sm={12} md={24}>
                  <ModuleUploadImage
                    title={t("page.homeMenu.decor_image")}
                    images={decorValues.image_url}
                    IsCanResetImage={true}
                    onResetImage={() => {
                      setDecorValues({ ...decorValues, image_url: "" });
                    }}
                    handleGetImage={uploadDecorImg}
                  />
                  <br />
                  <br />
                  <SharedInput
                    label={t("page.homeMenu.decor_text")}
                    // descLabel={t("page._2_100_characters")}
                    name="text_in"
                    onBlur={handleBlur}
                    onChange={changeDecorData}
                    value={decorValues.text_in}
                    errors={decorValues.text_in}
                    // touched={touched.title}
                    parentClassName="mb-6"
                  />
                  <SharedInput
                    label={t("page.homeMenu.decor_detail")}
                    // descLabel={t("page._2_100_characters")}
                    name="detail_in"
                    onBlur={handleBlur}
                    onChange={changeDecorData}
                    value={decorValues.detail_in}
                    errors={decorValues.detail_in}
                    // touched={touched.title}
                    parentClassName="mb-6"
                  />
                </Col>
              </Row>
            </div>
            <br />
          </StyledCard>
        </StyledPageBody>
      </form>
    </div>
  );
});
const StyledAddCategoryModal = styled(Modal)`
    .ant-modal-header {
        border-bottom: 0.5px solid #8a959e !important;
        margin-bottom: 20px;
    }
    .form-input {
        .input-with-search {
            width: 100%;
        }
        .ant-select-selection__rendered {
            height: 100%;
        }
    }
    .footer {
        display: flex;
        justify-content: center;
        .add-cat-btn {
            width: 170px;
        }
    }
`;
const StyledSwitch = styled.div`
    display: flex;
    align-items: center;
    p {
        margin-bottom: 0;
    }
`;

const StyleMenuTab = styled.div`
    span {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin-right: 10px;
        padding: 10px;
        cursor: pointer;
    }
    .selected {
        background-color: #fff;
        border-top: 5px solid #0d6efd;
        color: #0d6efd;
        border-radius: 5px 5px 0 0;
    }
`;

const StyledBidderNum = styled.div`
    height: 30px;
    display: flex;
    .title {
        margin-left: 5px;
    }

    .number {
        font-weight: 600;
        font-size: 35px;
        line-height: 53px;
        margin-left: auto;
    }
`;

const StyledBidderList = styled.div`
    .header {
        display: flex;
        justify-content: space-between;
    }
    .disabled {
        background-color: #e4e4e4;
    }
`;

const StyledPageBody = styled.div`
    display: grid;
    /* grid-template-columns: 760px 2fr; */
    grid-gap: 15px;
`;

const StyledDetailBody = styled.div`
    background: #ffffff;
    border-radius: 4px;
    padding: 30px;
    max-width: 700px;
    .title {
        font-weight: 500;
        font-size: 25px;
        line-height: 38px;
    }
    .period {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }

    hr {
        border-top: 1px solid #f3f3f3;
    }

    .detail-headline {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }
    .detail-desc {
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
    }
`;

const StyledDocument = styled.div`
    background-color: #f4f4f4;
    border-radius: 5px;
    margin: 10px 0px;
    display: flex;
    justify-content: space-between;
    .name {
        margin: auto 30px;
        font-weight: 600;
    }
    button {
        background-color: #0d6efd;
        border: none;
        padding: 10px;
        width: 100px;
        border-radius: 5px;
        color: white;
        margin: 15px;
    }
`;

const StyledFileName = styled.p`
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
`;

const StyledNews = styled.div`
    /* display: flex; */
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 20px;
    .img {
        /* max-height: 100px; */
    }
    .url {
        margin: auto 0px;
    }
`;
