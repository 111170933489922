import { useState, useEffect } from "react";
import {
    StyledTable,
    ComponentEmptyData,
    StyledTableButtonTop,
    ComponentRowNewsCategory,
} from "@components";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Row, Col, Drawer } from "antd";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import { IActivityCategory, IActivityCreateCategory, INewsCategoryParams } from "@interfaces";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth, selectOrg, getCategories, setLoading } from "@redux";

import { SharedInput, StyledSubmitButton, StyledCancelButton } from "@components";
import { useYup } from "@validations";
import styled from "styled-components";
import { orgApi } from "@api";
import { useNotify, usePermission } from "@utils";

const formData: INewsCategoryParams = {
    organization_id: "",
    category_name: "",
    is_enable: true,
};
export const PageNewsCategory = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const organization_id = useSelector(selectAuth).auth?.organization_data?._id;
    const categories = useSelector(selectOrg).category;
    const pemisssion = useSelector(selectAuth).permission;
    const [showForm, setShowForm] = useState(false);
    const { YupActivityCategory } = useYup();
    const createActivityCategory = Yup.object().shape(YupActivityCategory);

    const [editCategory, setEditCategory] = useState<IActivityCategory>();
    const { success, error } = useNotify();

    usePermission({ permission: pemisssion?.permission_list.news.is_manage });
    useEffect(() => {
        fetchNewsCategory();
    }, []);

    const fetchNewsCategory = async () => {
        await dispatch(getCategories({ organization_id: organization_id || "" }));
    };

    const handleDivisionSubmit = async (values) => {
        if (editCategory) {
            try {
                dispatch(setLoading(true));
                const { data } = await orgApi.updateNewsCategory({
                    ...values,
                    ...{ organization_id: organization_id || "" },
                    ...{ news_category_id: editCategory._id },
                });
                if (data.error === "0") {
                    success(data.msg);
                    fetchNewsCategory();
                    dispatch(setLoading(false));
                    // onClose();
                    resetForm();
                    handleClose();
                } else {
                    error(data.msg);
                    dispatch(setLoading(false));
                }
            } catch (err: any) {
                dispatch(setLoading(false));
            }
        } else {
            try {
                dispatch(setLoading(true));
                const { data } = await orgApi.createCat({
                    ...values,
                    ...{ organization_id: organization_id || "" },
                });
                if (data.error === "0") {
                    success(data.msg);
                    fetchNewsCategory();
                    dispatch(setLoading(false));
                    // onClose();
                    resetForm();
                    handleClose();
                } else {
                    error(data.msg);
                    dispatch(setLoading(false));
                }
            } catch (err: any) {
                dispatch(setLoading(false));
            }
        }
    };
    const {
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
        setFieldValue,
        resetForm,
    } = useFormik({
        initialValues: formData,
        validationSchema: createActivityCategory,
        enableReinitialize: true,
        onSubmit: handleDivisionSubmit,
    });
    const handleEditActivityCategory = (value: IActivityCategory) => {
        setShowForm(true);
        setEditCategory(value);
        setFieldValue("category_name", value.category_name);
    };
    const handleClose = () => {
        setEditCategory(undefined);
        setShowForm(false);
        resetForm();
    };
    return (
        <StyledTable>
            <div className="page-layout small-layout">
                <div className="page-header-layout">
                    <div className="page-header">
                        <div>
                            <h3>{t("page.agency")}</h3>
                        </div>
                        <div className="flex-header">
                            <StyledTableButtonTop
                                type="danger"
                                size="default"
                                text={t("page.add_division")}
                                onClick={() => setShowForm(true)}
                                className="add-btn"
                                icon="plus"
                            />
                        </div>
                    </div>
                </div>
                <div className="page-table-layout">
                    <div className="table">
                        <ScrollContainer vertical={false}>
                            <div className="table_ground">
                                <div className="table-body">
                                    <div className="table-header">
                                        <Row
                                            // gutter={24}
                                            type="flex"
                                            justify="space-between"
                                            align="middle"
                                            className="header-row"
                                        >
                                            <Col className="col-item" span={12}>
                                                <p>{t("page.division_name")}</p>
                                            </Col>
                                            <Col className="col-item division-manage" span={12}>
                                                <p>{t("page.manage")}</p>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div>
                                        {categories && categories.length > 0 ? (
                                            <div className="data-table">
                                                {categories.map((item, index) => (
                                                    <ComponentRowNewsCategory
                                                        key={index}
                                                        item={item}
                                                        itemJson={JSON.stringify(item)}
                                                        index={index}
                                                        editCategory={handleEditActivityCategory}
                                                    />
                                                ))}
                                                <div style={{ width: "100%", height: 10 }}></div>
                                            </div>
                                        ) : (
                                            <ComponentEmptyData />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </ScrollContainer>
                    </div>
                </div>
            </div>
            <StyledDrawer
                width={538}
                visible={showForm}
                onClose={handleClose}
                title={t("page.add_division")}
            >
                <form onSubmit={handleSubmit}>
                    <SharedInput
                        label={t("page.category_name")}
                        // descLabel={t("page._2_100_characters")}
                        name="category_name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.category_name}
                        errors={errors.category_name}
                        touched={touched.category_name}
                        parentClassName="mb-6"
                    />
                    <div className="btn-layout">
                        <StyledSubmitButton
                            type="default"
                            text={t("page.save")}
                            htmlType="submit"
                            // disable={isSubmitting}
                        />
                        <StyledCancelButton
                            type="sub"
                            text={t("page.cancel")}
                            htmlType="button"
                            onClick={handleClose}
                        />
                    </div>
                </form>
            </StyledDrawer>
        </StyledTable>
    );
};

const StyledDrawer = styled(Drawer)`
    .btn-layout {
        position: fixed;
        bottom: 14px;
    }
`;
