export const LogoutOutline = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.3193 22H6.43331C3.98931 22 2.00031 20.011 2.00031 17.565V6.436C2.00031 3.99 3.98931 2 6.43331 2H11.3083C13.7543 2 15.7443 3.99 15.7443 6.436V7.368C15.7443 7.782 15.4083 8.118 14.9943 8.118C14.5803 8.118 14.2443 7.782 14.2443 7.368V6.436C14.2443 4.816 12.9273 3.5 11.3083 3.5H6.43331C4.81631 3.5 3.50031 4.816 3.50031 6.436V17.565C3.50031 19.184 4.81631 20.5 6.43331 20.5H11.3193C12.9313 20.5 14.2443 19.188 14.2443 17.576V16.633C14.2443 16.219 14.5803 15.883 14.9943 15.883C15.4083 15.883 15.7443 16.219 15.7443 16.633V17.576C15.7443 20.016 13.7583 22 11.3193 22Z"
            fill="#F22F46"
        />
        <mask
            id="mask0_2019_44482"
            // style="mask-type:alpha"
            maskUnits="userSpaceOnUse"
            x="8"
            y="11"
            width="15"
            height="2"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.99609 11.25H22.537V12.75H8.99609V11.25Z"
                fill="white"
            />
        </mask>
        <g mask="url(#mask0_2019_44482)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.7871 12.75H9.74609C9.33209 12.75 8.99609 12.414 8.99609 12C8.99609 11.586 9.33209 11.25 9.74609 11.25H21.7871C22.2011 11.25 22.5371 11.586 22.5371 12C22.5371 12.414 22.2011 12.75 21.7871 12.75Z"
                fill="#F22F46"
            />
        </g>
        <mask
            id="mask1_2019_44482"
            // style="mask-type:alpha"
            maskUnits="userSpaceOnUse"
            x="18"
            y="8"
            width="5"
            height="8"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.1096 8.33521H22.537V15.666H18.1096V8.33521Z"
                fill="white"
            />
        </mask>
        <g mask="url(#mask1_2019_44482)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.8594 15.666C18.6674 15.666 18.4744 15.593 18.3284 15.445C18.0364 15.151 18.0374 14.677 18.3304 14.385L20.7244 12L18.3304 9.61596C18.0374 9.32396 18.0354 8.84996 18.3284 8.55596C18.6204 8.26196 19.0944 8.26196 19.3884 8.55396L22.3164 11.469C22.4584 11.609 22.5374 11.801 22.5374 12C22.5374 12.199 22.4584 12.391 22.3164 12.531L19.3884 15.447C19.2424 15.593 19.0504 15.666 18.8594 15.666Z"
                fill="#F22F46"
            />
        </g>
    </svg>
);
