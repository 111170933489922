import {
    IClaimCreate,
    IClaimDelete,
    IClaimDetailParams,
    IClaimParams,
    IClaimUpdate,
} from "src/Interfaces/claim";

import axiosClient from "./axiosClient";

export const claimAPI = {
    getClaims: (params: IClaimParams) => {
        const url = "claim/getClaims";
        return axiosClient.get(url, { params });
    },
    detail: (params: IClaimDetailParams) => {
        const url = `claim/getClaim`;
        return axiosClient.get(url, { params });
    },
    create: (params: IClaimCreate) => {
        const url = "claim/createClaim";
        return axiosClient.post(url, params);
    },
    update: (params: IClaimUpdate) => {
        const url = "claim/updateClaim";
        return axiosClient.patch(url, params);
    },
    delete: (params: IClaimDelete) => {
        const url = `claim/deleteClaim`;
        return axiosClient.post(url, params);
    },
};
