import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { openToast } from "../Redux";

export const useNotify = () => {
    //hook
    const dispatch = useDispatch();

    const errorMessage = useCallback((message: string, duration?: number) => {
        dispatch(
            openToast({
                message: message,
                type: "error",
                autoHideDuration: duration || 5000,
            })
        );
    }, []);

    const successMessage = useCallback((message: string, duration?: number) => {
        dispatch(
            openToast({
                message: message,
                type: "success",
                autoHideDuration: duration || 5000,
            })
        );
    }, []);
    const warningMessage = useCallback((message: string, duration?: number) => {
        dispatch(
            openToast({
                message: message,
                type: "warning",
                autoHideDuration: duration || 5000,
            })
        );
    }, []);
    const infoMessage = useCallback((message: string, duration?: number) => {
        dispatch(
            openToast({
                message: message,
                type: "info",
                autoHideDuration: duration || 5000,
            })
        );
    }, []);
    return {
        error: errorMessage,
        success: successMessage,
        warning: warningMessage,
        info: infoMessage,
    };
};
