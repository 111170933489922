import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Switch, Col, Row, Avatar, Menu, Dropdown, Button, Checkbox } from "antd";
import { MoreHorizontal } from "react-feather";
import moment from "moment";

import { IBooking, INews, INewsCategory, IService } from "@interfaces";
import { newsAPI, serviceAPI } from "@api";
import {
    dateFormat,
    dateFormatHM,
    PATH_CREATE_NEWS,
    enumNewsType,
    defaultImage,
    PATH_SERVICES,
} from "@configs";
import { selectAuth } from "@redux";
import { useSelector } from "react-redux";
import { IconDrag } from "@components";
import { useNotify } from "@utils";
import { useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import styled from "styled-components";

interface IProps {
    data: IService;
    itemJson: string;
    handleEdit: (status: boolean, data: IService) => void;
    callbackGetList: () => void;
    index: number;
    newType?: enumNewsType;
    categories: INewsCategory[];
}

export const ComponentRowService = (props: IProps) => {
    //hooks
    const { t } = useTranslation();
    const { success, error } = useNotify();
    const organization_id = useSelector(selectAuth).auth?.organization_data?._id;
    //props
    const { data, handleEdit, callbackGetList, itemJson } = props;
    const [show, setShowModal] = useState(false);

    const { attributes, listeners, setNodeRef, transform } = useSortable({
        id: itemJson,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
    };

    const handleDelete = async () => {
        try {
            const res = await serviceAPI.deleteService({
                organization_id: organization_id || "",
                service_id: data._id || "",
            });
            if (res.data.error === "0") {
                success(res.data.msg);
                callbackGetList();
            } else {
                error(res.data.msg);
            }
        } catch (err: any) {
            if (err) {
                error(err.msg);
            } else error(t("message.delete.fail"));
        }
    };

    const menu = (
        <Menu>
            <Menu.Item key={1} onClick={handleDelete}>
                <div className="duplicate-btn">{t("page.delete")}</div>
            </Menu.Item>
        </Menu>
    );
    const handleUpdateStatus = (e: boolean) => {
        handleEdit(e, data);
    };
    return (
        <StyledLayout>
            <div className="table-element" ref={setNodeRef} style={style}>
                <div className="drag-icon" {...attributes} {...listeners}>
                    <IconDrag />
                </div>

                <div className="table-row">
                    <Row
                        style={{ height: "100%" }}
                        gutter={24}
                        type="flex"
                        align="middle"
                        justify="space-between"
                    >
                        <Col className="col-item" span={10}>
                            <Checkbox />
                            <Link to={`${PATH_SERVICES}/${data._id}`}>
                                <p className="news-title">{data.name || t("page.empty_text")}</p>
                            </Link>
                        </Col>
                        <Col className="col-item" span={6}>
                            <p>{data.code || t("page.empty_text")}</p>
                        </Col>
                        <Col className="col-item visible-md" span={6}>
                            <Switch onChange={handleUpdateStatus} checked={data.is_enable} />
                        </Col>
                        <Col className="col-item" span={2}>
                            <Dropdown
                                //@ts-ignore
                                getPopupContainer={(trigger) => trigger.parentNode}
                                overlay={menu}
                                trigger={["click"]}
                                placement="bottomRight"
                            >
                                <Button style={{ border: "unset" }}>
                                    <MoreHorizontal />
                                </Button>
                            </Dropdown>
                        </Col>
                    </Row>
                </div>
            </div>
        </StyledLayout>
    );
};
const StyledLayout = styled.div`
    .table-element {
        /* height: 64px; */
    }
`;
