import { useEffect, memo, useState, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Row, Col, Select, Modal } from "antd";
import styled from "styled-components";
import moment from "moment";
import {
    IContactListParams,
    IDocumentUpdate,
    IResult,
    IResultCreate,
    IResultUpdate,
} from "@interfaces";
import { StyledSubmitButton, StyledCancelButton, StyledCard, SharedInput } from "@components";
import { useYup } from "@validations";
import { setLoading, selectAuth, selectContact } from "@redux";
import { ModuleUploadImage } from "@modules";
import { showErrorMessage, useNotify } from "@utils";
import { PATH_BIDDING_RESULT_LIST, PATH_CREATE_BIDDING_RESULT } from "@configs";
import { biddingAPI } from "@api";
import { ModuleUploadFile } from "../form/UploadFile";

interface IProps {
    result: IResult | undefined;
}

const initData: IResultUpdate = {
    organization_id: "",
    title: "",
    image_url: "",
    is_enable: true,
    information_id: "",
    created_date: new Date().toString(),
    documents: [],
};
const { Option } = Select;
export const ModuleBiddingResultForm = memo((props: IProps) => {
    //hook
    const { YupNews, YupBidding } = useYup();

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const { error, success } = useNotify();
    const search_params = useSelector(selectContact).paramsContact;

    const organization_id = useSelector(selectAuth).auth?.admin_data?.organization_id;

    const pathName = window.location.pathname;

    const createBidding = Yup.object().shape(YupBidding);
    const { result } = props;
    const [fileList, setFileList] = useState<IDocumentUpdate[]>([]);
    useEffect(() => {
        if (result) {
            setFieldValue("title", result.title);
            setFieldValue("is_enable", result.is_enable);
            setFieldValue("information_id", result._id);
            setFieldValue("created_date", result.created_date);
            setFieldValue("documents", result.documents);
            setFieldValue("image_url", result.image_url);
        }
    }, [result]);

    const handleBiddingSubmit = (values: IResultUpdate) => {
        console.log("hello submit", values);
    };

    const addMoreFile = () => {
        console.log("fl", fileList);
        const arrFileList = fileList;
        arrFileList.push({
            document_name: "",
            document_url: "",
        });
        setFileList([...arrFileList]);
    };

    const handleClose = () => {
        history.push(PATH_BIDDING_RESULT_LIST);
    };

    const { values, handleChange, handleBlur, handleSubmit, errors, touched, setFieldValue } =
        useFormik({
            initialValues: initData,
            validationSchema: createBidding,
            enableReinitialize: true,
            onSubmit: handleBiddingSubmit,
        });

    const handleChangeDateFrom = (date: Date | string) => {
        setFieldValue("bid_date_from", moment(date).toISOString());
    };

    const handlesort = () => {
        console.log("sort");
    };

    const handleChangeDateTo = (date: Date | string) => {
        setFieldValue("bid_date_to", moment(date).toISOString());
    };

    const handleChangeGuideLine = (event: any, editors: any) => {
        const data = editors.getData();
        setFieldValue("detail", data);
    };

    const handleUploadImage = (images: string) => {
        // console.log(images);
        setFieldValue("image_url", images);
    };

    const onGetFile = (file: string, index: number) => {
        let fileUrlSplit = file.split("/");
        let pdfName = fileUrlSplit[fileUrlSplit.length - 1];
        let nameOnly = pdfName.split("-").slice(1).join();
        // console.log(index, pdfName, file, fileList[index]);
        const arrFileList = fileList;
        arrFileList[index] = {
            document_name: nameOnly,
            document_url: file,
        };
    };

    const saveResult = async () => {
        // dispatch(setLoading(true));
        try {
            const payload: IResultCreate = {
                organization_id: organization_id || "",
                title: values.title,
                image_url: values.image_url,
                documents: fileList,
                is_enable: true,
            };
            await biddingAPI.createResult(payload);

            success(t("message.create.success"));
            handleClose();
        } catch (err: any) {
            if (err.response) {
                error(showErrorMessage(err.response));
            } else error(t("message.error"));
        } finally {
            dispatch(setLoading(false));
        }
    };

    return (
        <div className="form-wrap">
            <form onSubmit={handleSubmit}>
                <div className="page-header">
                    <h3>
                        {pathName === PATH_CREATE_BIDDING_RESULT
                            ? t("page.bidding.create_result_headline")
                            : t("page.bidding.result_detail_header")}
                    </h3>
                </div>
                {/* สร้างรายการประกาศผล */}
                {pathName === PATH_CREATE_BIDDING_RESULT ? (
                    <StyledPageBody>
                        <StyledCard>
                            <div className="title">
                                {pathName === PATH_CREATE_BIDDING_RESULT
                                    ? t("page.bidding.create_result_headline")
                                    : t("page.bidding.result_detail_header")}
                            </div>
                            <div>
                                <Row gutter={16}>
                                    <Col xs={12} sm={12} md={24}>
                                        <ModuleUploadImage
                                            images={values.image_url}
                                            handleGetImage={handleUploadImage}
                                        />
                                        <br />
                                        <br />
                                        <SharedInput
                                            label={t("page.bidding.title_headline")}
                                            // descLabel={t("page._2_100_characters")}
                                            name="title"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.title}
                                            errors={errors.title}
                                            touched={touched.title}
                                            parentClassName="mb-6"
                                        />
                                    </Col>
                                </Row>
                            </div>

                            <br />
                            <div className="card-wrap">
                                <StyledFileName>
                                    {t("page.bidding.upload_file_result_headline")}
                                </StyledFileName>
                                {fileList.map((item, index) => (
                                    <ModuleUploadFile
                                        title=""
                                        handleGetFile={onGetFile}
                                        index={index}
                                        key={index}
                                        defaultFileName={item.document_name}
                                        placeholder={t("page.bidding.please_select_file")}
                                        btnText={t("page.bidding.upload_file")}
                                    />
                                ))}
                            </div>
                            <br />
                            <StyledCancelButton
                                type="sub"
                                text={t("page.bidding.add_more_file")}
                                htmlType="button"
                                onClick={addMoreFile}
                            />
                        </StyledCard>
                        <div className="btn-layout">
                            <StyledSubmitButton
                                type="default"
                                text={t("page.save")}
                                // htmlType="submit"
                                onClick={saveResult}
                                // onClick={handleSubmit}
                                disable={values.title === ""}
                            />
                            <StyledCancelButton
                                type="sub"
                                text={t("page.cancel")}
                                htmlType="button"
                                onClick={handleClose}
                            />
                        </div>
                    </StyledPageBody>
                ) : (
                    <StyledPageBody>
                        <StyledCard>
                            <StyledBidderList>
                                <div className="header">
                                    <div className="title">{values.title}</div>
                                    {/* <StyledSubmitButton
                      type="default"
                      text={t("page.export")}
                    // disable={isSubmitting}
                    /> */}
                                </div>
                                {result?.image_url && <img src={result?.image_url} />}
                                {result?.documents &&
                                    result?.documents?.length > 0 &&
                                    result.documents.map((item, index) => {
                                        return (
                                            <StyledDocument key={index}>
                                                <p className="name">{item.document_name}</p>
                                                <button
                                                    onClick={() => window.open(item.document_url)}
                                                >
                                                    {t("page.download")}
                                                </button>
                                            </StyledDocument>
                                        );
                                    })}
                            </StyledBidderList>
                        </StyledCard>
                    </StyledPageBody>
                )}
            </form>
        </div>
    );
});
const StyledAddCategoryModal = styled(Modal)`
    .ant-modal-header {
        border-bottom: 0.5px solid #8a959e !important;
        margin-bottom: 20px;
    }
    .form-input {
        .input-with-search {
            width: 100%;
        }
        .ant-select-selection__rendered {
            height: 100%;
        }
    }
    .footer {
        display: flex;
        justify-content: center;
        .add-cat-btn {
            width: 170px;
        }
    }
`;
const StyledSwitch = styled.div`
    display: flex;
    align-items: center;
    p {
        margin-bottom: 0;
    }
`;

const StyleMenuTab = styled.div`
    span {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin-right: 10px;
        padding: 10px;
        cursor: pointer;
    }
    .selected {
        background-color: #fff;
        border-top: 5px solid #0d6efd;
        color: #0d6efd;
        border-radius: 5px 5px 0 0;
    }
`;

const StyledBidderNum = styled.div`
    height: 30px;
    display: flex;
    .title {
        margin-left: 5px;
    }

    .number {
        font-weight: 600;
        font-size: 35px;
        line-height: 53px;
        margin-left: auto;
    }
`;

const StyledBidderList = styled.div`
    .header {
        display: flex;
        justify-content: space-between;
    }
`;

const StyledPageBody = styled.div`
    display: grid;
    /* grid-template-columns: 760px 2fr; */
    grid-gap: 15px;
`;

const StyledDetailBody = styled.div`
    background: #ffffff;
    border-radius: 4px;
    padding: 30px;
    max-width: 700px;
    .title {
        font-weight: 500;
        font-size: 25px;
        line-height: 38px;
    }
    .period {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }

    hr {
        border-top: 1px solid #f3f3f3;
    }

    .detail-headline {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }
    .detail-desc {
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
    }
`;

const StyledDocument = styled.div`
    background-color: #f4f4f4;
    border-radius: 5px;
    margin: 10px 0px;
    display: flex;
    justify-content: space-between;
    .name {
        margin: auto 30px;
        font-weight: 600;
    }
    button {
        background-color: #0d6efd;
        border: none;
        padding: 10px;
        width: 100px;
        border-radius: 5px;
        color: white;
        margin: 15px;
    }
`;

const StyledFileName = styled.p`
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
`;
