import { ALL } from "@configs";
import { IDivision, IService, IServiceDepartment } from "@interfaces";
import { Input, Select, DatePicker } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
const { Search } = Input;
const { Option } = Select;
const { MonthPicker } = DatePicker;
interface Iprops {
    setTextSearch: (value: string) => void;
    handleSearchDept: (value: string) => void;
    handleSearchService: (value: string) => void;
    departments: IServiceDepartment[] | undefined;
    services: IService[] | undefined;
    onChangeDateRange?: (dates, dateStrings: [string, string]) => void;
    onChangeDate?: (dates, dateStrings: string) => void;
    total: number;
    divisions?: IDivision[];
    isRangePicker: boolean;
    yearMonth: string;
    setYearMonth: (date: string) => void;
}
export const ComponentSearchBooking = (props: Iprops) => {
    const {
        setTextSearch,
        handleSearchDept,
        handleSearchService,
        departments,
        services,
        total,
        yearMonth,
        setYearMonth,
    } = props;
    const { t } = useTranslation();
    const onChange = (date, dateString) => {
        if (dateString) {
            setYearMonth(dateString);
        }
    };
    return (
        <StyledSearchLayout>
            <div className="filter">
                <div className="result">
                    <p>
                        <span>{`ยอดจองทำธุรกรรมวันนี้`}</span>
                        <span>{":"}</span>
                        <span className="eng count">{total}</span>
                    </p>
                </div>
                <div className="search-row">
                    <div className="col-item">
                        <div className="filter-element">
                            <div className="input">
                                <Select onChange={handleSearchDept} placeholder={`หน่วยงานทั้งหมด`}>
                                    <Option value={ALL} key={"all"}>
                                        {`ทั้งหมด`}
                                    </Option>
                                    {departments &&
                                        departments.map((item, index) => (
                                            <Option value={item._id} key={index}>
                                                {item.name}
                                            </Option>
                                        ))}
                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-item">
                        <div className="filter-element">
                            <div className="input">
                                <Select
                                    onChange={handleSearchService}
                                    placeholder={`ธุรกรรมทั้งหมด`}
                                >
                                    <Option value={ALL} key={"all"}>
                                        {`ทั้งหมด`}
                                    </Option>
                                    {services &&
                                        services.map((item, index) => (
                                            <Option value={item._id} key={index}>
                                                {item.name}
                                            </Option>
                                        ))}
                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-item">
                        <div className="filter-element">
                            <div className="input">
                                <MonthPicker
                                    onChange={onChange}
                                    placeholder="Select month"
                                    format={"YYYY-MM"}
                                    value={moment(yearMonth)}
                                    allowClear={false}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-item">
                        <div className="filter-element">
                            <div className="input">
                                <Search
                                    placeholder={`ค้นหารายชื่อ, เบอร์โทรศัพท์...`}
                                    onSearch={(value) => setTextSearch(value)}
                                    className="search-input"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </StyledSearchLayout>
    );
};
const StyledSearchLayout = styled.div`
    margin-top: 36px;
    .filter {
        background: #fff;
        padding: 20px;
        border-radius: 4px;
        margin-bottom: 26px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .search-row {
            flex: 11;
            display: flex;
            column-gap: 16px;
            align-items: center;
            justify-content: end;
        }
        .filter-element {
            flex: 2;
            display: flex;
            align-items: center;
            column-gap: 10px;
            p {
                margin: 0;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                color: #646464;
            }
            .filter-input {
                width: 240px;
                @media (max-width: ${(p) => p.theme.breakPoints.breakMacLaptop}) {
                    width: 150px;
                }
            }
            .ant-input-search {
                height: 44px;
                width: 100%;
            }
            .label {
                p {
                    @media (max-width: ${(p) => p.theme.breakPoints.breakMacLaptop}) {
                        text-overflow: ellipsis;
                        max-width: 70px;
                        white-space: nowrap;
                    }
                    @media (max-width: ${(p) => p.theme.breakPoints.break13Laptop}) {
                        max-width: 80px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }
            .input {
                .search-input {
                    width: 200px;
                    @media (max-width: ${(p) => p.theme.breakPoints.breakMacLaptop}) {
                        width: 150px;
                    }
                }
                .ant-select {
                    width: 200px;
                    @media (max-width: ${(p) => p.theme.breakPoints.breakMacLaptop}) {
                        width: 150px;
                    }
                }
            }
            .ant-select {
                /* min-width: 160px; */
                width: 100%;
                .ant-select-selection--single {
                    height: 44px;
                    .ant-select-selection__rendered {
                        height: 100%;
                        align-items: center;
                        display: flex;
                    }
                }
            }
            .ant-calendar-picker-input {
                height: 44px;
                .ant-calendar-range-picker-separator {
                    vertical-align: unset !important;
                }
            }
        }
        .result {
            display: flex;
            flex: 4;
            p {
                display: flex;
                column-gap: 10px;
                margin: 0;
            }
            .count {
                color: ${(p) => p.theme.colors.main};
            }
        }
    }
`;
