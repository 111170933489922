import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { Switch, Col, Row, Menu, Dropdown, Button } from "antd";
import { MoreHorizontal } from "react-feather";
import moment from "moment";

import { IBidding, INewsCategory, ITrashfeeArea, ItrashfeeAreaDelete } from "@interfaces";

import { enumTypeFetchList, dateFormat, dateFormatHM, PATH_BIDDING, PATH_TRASH_FEE_AREA } from "@configs";
import { IconDrag } from "@components";
import { useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { trashfeeAPI } from "@api";
import { useNotify } from "@utils";
interface IProps {
  data: ITrashfeeArea;
  itemJson: string;
  handleEdit: (userId: string, points: number) => void;
  callbackGetList: () => void;
  index: number;
}

export const ComponentRowAreaList = (props: IProps) => {
  //hooks
  const { t } = useTranslation();
  const { error, success } = useNotify();
  const history = useHistory();

  //props
  const { data, itemJson, callbackGetList } = props;

  const { attributes, listeners, setNodeRef, transform } = useSortable({
    id: itemJson,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
  };
  const deleteArea = async () => {
    console.log(data?._id);
    try {
      let payload: ItrashfeeAreaDelete = {
        organization_id: data?.organization_id,
        area_id: data?._id,
      };
      await trashfeeAPI.deleteArea(payload);
      success(t("message.delete.success"));
      callbackGetList();
    } catch {
      error(t("message.delete.fail"));
    }
  };

  const menu = (
    <Menu>
      <Menu.Item key={1}>
        <div className="duplicate-btn" onClick={deleteArea}>{t("page.delete")}</div>
      </Menu.Item>
    </Menu>
  );
  const handleUpdateStatus = () => {
    console.log("update fun");
  };
  return (
    <div className="table-element" ref={setNodeRef} style={style}>
      <div className="drag-icon" {...attributes} {...listeners}>
        <IconDrag />
      </div>
      <div className="table-row">
        <Row
          style={{ height: "100%" }}
          gutter={24}
          type="flex"
          align="middle"
          justify="space-between"
        >
          <Col className="col-item" lg={5} md={5}>
            <Link to={`${PATH_TRASH_FEE_AREA}/${data._id}`}>
              <p className="news-title">{data.area_name || t("page.empty_text")}</p>
            </Link>
          </Col>
          <Col className="col-item" span={12}>
            หมู่บ้าน {data.village} ซอย {data.soi} ถนน {data.street} อำเภอ {data.district_name} รหัสไปรษณีย์ {data.zipcode}
          </Col>
          <Col className="col-item" span={3}>
            <Dropdown
              //@ts-ignore
              getPopupContainer={(trigger) => trigger.parentNode}
              overlay={menu}
              trigger={["click"]}
              placement="bottomRight"
            >
              <Button style={{ border: "unset" }}>
                <MoreHorizontal />
              </Button>
            </Dropdown>
          </Col>
        </Row>
      </div>
    </div>
  );
};
