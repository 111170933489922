import { useEffect, memo, useState, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Tooltip, Button, Avatar } from "antd";
import { ITheme, IThemeCreate } from "@interfaces";
import {
    StyledSubmitButton,
    StyledCancelButton,
    StyledCard,
    InfoIcon,
    ComponentTheme,
    IconCamera,
} from "@components";
import { DeleteOutlined } from "@ant-design/icons";
import { useYup } from "@validations";
import { setLoading, selectAuth, selectTheme, setLogo, setMainColor } from "@redux";
import { showErrorMessage, useNotify } from "@utils";
import { enumValidation, IMAGE_TYPE, PATH_HOME } from "@configs";
import { newsAPI, orgApi } from "@api";
const textLogoString = "200px × 200px Maximum 10MB";
interface IProps {
    theme: ITheme | undefined;
}
const initData: IThemeCreate = {
    organization_id: "",
    theme_color_code: "",
    logo_image_url: "",
};
export const ModuleThemeForm = memo((props: IProps) => {
    //hook
    const { YupTheme } = useYup();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { error, success } = useNotify();
    const organization_id = useSelector(selectAuth).auth?.admin_data?.organization_id;
    const searchInputRef = useRef<any | null>(null);
    const [cat, setCat] = useState<string>("");
    const pathName = window.location.pathname;
    const Theme = useSelector(selectTheme);
    const urlLogo = Theme.logo;
    const createTheme = Yup.object().shape(YupTheme);
    const { theme } = props;
    useEffect(() => {
        if (theme) {
            setFieldValue("theme_color_code", theme.theme_color_code);
            setFieldValue("logo_image_url", theme.logo_image_url);
            dispatch(setLogo(theme.logo_image_url));
        }
    }, [theme]);

    const handleThemeSubmit = async (values: ITheme) => {
        dispatch(setLoading(true));
        try {
            const res = await orgApi.updateTheme({
                ...values,
                ...{ organization_id: organization_id || "" },
                ...{ logo_image_url: urlLogo },
            });
            if (res.data.error === "0") {
                success(t("message.create.success"));
                dispatch(setLogo(res.data.result.logo_image_url));
                dispatch(setMainColor(res.data.result.theme_color_code));
            } else {
                error(res.data.msg);
            }
        } catch (err: any) {
            if (err) {
                error(err.msg);
            }
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleCancel = () => {
        history.push(PATH_HOME);
    };

    const { values, handleSubmit, setFieldValue } = useFormik({
        initialValues: initData,
        validationSchema: createTheme,
        enableReinitialize: true,
        onSubmit: handleThemeSubmit,
    });

    const handleRemoveImageLogo = () => {
        dispatch(setLogo(""));
    };
    const handleChangeLogo = async (e: React.ChangeEvent<HTMLInputElement>) => {
        try {
            //@ts-ignore
            const image = e.target.files[0];
            if (!image) {
                //WHAT: image empty
                error(t("validation.image.empty"));
                return;
            }
            if (!IMAGE_TYPE.some((imageType) => imageType === image.type)) {
                error(t("validation.image.wrong_type"));
                return;
            }
            if (image.size >= enumValidation.MAX_FILE_SIZE) {
                error(t("validation.image.max_size"));
                return;
            }
            const formData = new FormData();
            formData.append("file", image);
            const res = await newsAPI.uploadImage(formData);
            setFieldValue("logo_image_url", res.data.result.publicUrl);
            dispatch(setLogo(res.data.result.publicUrl));
        } catch (error: any) {
            //
            // const data = error.response.data;
            if (error.response) {
                error(showErrorMessage(error.response));
            } else {
                error(t("page.image_error"));
            }
        }
    };
    const handleChangeColor = (value: string) => {
        setFieldValue("theme_color_code", value);
    };
    return (
        <div className="form-wrap">
            <form onSubmit={handleSubmit}>
                <div className="page-header">
                    <h3>{t("page.theme_setting")}</h3>
                    <p className="sub-title">{t("page.please_complete_the_setting")}</p>
                </div>
                <div className="page-body">
                    <div className="card-wrap">
                        <StyledCard>
                            <div className="title">{t("page.theme_details")}</div>
                            <ComponentTheme
                                title={t("page.municipal_color_theme")}
                                callbackColor={handleChangeColor}
                                initColor={values.theme_color_code}
                                style={{
                                    fontWeight: 600,
                                    fontSize: "16px",
                                    lineHeight: "21px",
                                    marginBottom: "14px",
                                }}
                            />
                            <div className="avatar">
                                <div className="box_title">
                                    <p>{t("page.logo_image")}</p>
                                    <Tooltip placement="topLeft" title={`${textLogoString}`}>
                                        <Button>
                                            <InfoIcon />
                                        </Button>
                                    </Tooltip>
                                </div>
                                <div className="avatar-layout">
                                    <Avatar size={110} src={values.logo_image_url} shape="square" />
                                    {values.logo_image_url && (
                                        <div className="remove-icon-layout">
                                            <DeleteOutlined
                                                style={{ color: "white", fontSize: 20 }}
                                                onClick={handleRemoveImageLogo}
                                            />
                                        </div>
                                    )}
                                </div>
                                <input
                                    id="file_update_logo"
                                    type="file"
                                    hidden
                                    onChange={handleChangeLogo}
                                />
                                <label className="camera" htmlFor="file_update_logo">
                                    <IconCamera />
                                </label>
                            </div>
                        </StyledCard>
                    </div>
                    <div className="btn-layout">
                        <StyledSubmitButton
                            type="default"
                            text={t("page.save")}
                            htmlType="submit"
                            // disable={isSubmitting}
                        />
                        <StyledCancelButton
                            type="sub"
                            text={t("page.cancel")}
                            htmlType="button"
                            onClick={handleCancel}
                        />
                    </div>
                </div>
            </form>
        </div>
    );
});
