interface Iprops {
	color?: string;
}
export const LoadIcon = (props: Iprops) => (
	<svg width="18"
		height="17"
		viewBox="0 0 18 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd"
			clip-rule="evenodd"
			d="M8.66119 12.226C8.30268 12.226 8.01172 11.9351 8.01172 11.5766V1.14947C8.01172 0.790964 8.30268 0.5 8.66119 0.5C9.0197 0.5 9.31067 0.790964 9.31067 1.14947V11.5766C9.31067 11.9351 9.0197 12.226 8.66119 12.226Z"
			fill={props.color || "#29304D"}
		/>
		<path fill-rule="evenodd"
			clip-rule="evenodd"
			d="M8.66042 12.2259C8.48809 12.2259 8.32182 12.1575 8.20059 12.0345L5.67543 9.49982C5.42257 9.24523 5.42344 8.83389 5.67717 8.58103C5.93176 8.32817 6.34223 8.32817 6.59509 8.58276L8.66042 10.6567L10.7257 8.58276C10.9786 8.32817 11.3891 8.32817 11.6437 8.58103C11.8974 8.83389 11.8983 9.24523 11.6454 9.49982L9.12024 12.0345C8.99901 12.1575 8.83274 12.2259 8.66042 12.2259Z"
			fill={props.color || "#29304D"}
		/>
		<g mask="url(#mask0_4071_13302)">
			<path fill-rule="evenodd"
				clip-rule="evenodd"
				d="M13.4874 16.5H3.84055C1.72327 16.5 0 14.7776 0 12.6594V8.42917C0 6.31622 1.71894 4.59814 3.83276 4.59814H4.64763C5.00614 4.59814 5.29711 4.88911 5.29711 5.24762C5.29711 5.60613 5.00614 5.89709 4.64763 5.89709H3.83276C2.43509 5.89709 1.29895 7.03237 1.29895 8.42917V12.6594C1.29895 14.0614 2.43856 15.201 3.84055 15.201H13.4874C14.8833 15.201 16.0203 14.064 16.0203 12.6681V8.4387C16.0203 7.0367 14.8799 5.89709 13.4796 5.89709H12.6725C12.314 5.89709 12.0231 5.60613 12.0231 5.24762C12.0231 4.88911 12.314 4.59814 12.6725 4.59814H13.4796C15.5969 4.59814 17.3193 6.32141 17.3193 8.4387V12.6681C17.3193 14.781 15.5995 16.5 13.4874 16.5Z"
				fill={props.color || "#29304D"}
			/>
		</g>
	</svg>

);