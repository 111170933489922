export const SuccessIcon = () => (
    <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M29.0031 58C28.6687 58 28.348 57.8672 28.1115 57.6307C27.875 57.3942 27.7422 57.0735 27.7422 56.7391C27.7422 56.4047 27.875 56.084 28.1115 55.8475C28.348 55.611 28.6687 55.4782 29.0031 55.4782C36.0255 55.4782 42.7604 52.6885 47.726 47.7229C52.6916 42.7573 55.4813 36.0225 55.4813 29C55.4813 21.9775 52.6916 15.2427 47.726 10.2771C42.7604 5.31146 36.0255 2.5218 29.0031 2.5218C28.6687 2.5218 28.348 2.38895 28.1115 2.15249C27.875 1.91603 27.7422 1.59531 27.7422 1.2609C27.7422 0.926488 27.875 0.605774 28.1115 0.369309C28.348 0.132844 28.6687 0 29.0031 0C36.6944 1.14609e-07 44.0706 3.05535 49.5092 8.4939C54.9477 13.9325 58.0031 21.3087 58.0031 29C58.0031 36.6913 54.9477 44.0675 49.5092 49.5061C44.0706 54.9447 36.6944 58 29.0031 58Z"
            fill="#9EE09E"
        />
        <path
            d="M1.37544 32.5635C1.05802 32.5645 0.751881 32.4458 0.518181 32.231C0.284482 32.0162 0.140422 31.7211 0.114774 31.4047C0.0617659 30.6884 0 29.8522 0 28.9986C0 28.1449 0.0617659 27.3116 0.119844 26.5953C0.1481 26.2628 0.306952 25.9551 0.561611 25.7395C0.816271 25.5239 1.14598 25.418 1.47854 25.4451C1.8111 25.4721 2.11941 25.6298 2.33594 25.8836C2.55247 26.1375 2.65958 26.4668 2.63381 26.7994C2.58126 27.4683 2.5218 28.2436 2.5218 28.9986C2.5218 29.7536 2.57988 30.5317 2.63381 31.1987C2.64731 31.3638 2.62813 31.5299 2.57737 31.6876C2.52661 31.8453 2.44527 31.9915 2.33799 32.1177C2.23071 32.244 2.0996 32.3478 1.95216 32.4234C1.80471 32.4989 1.64383 32.5446 1.47869 32.558C1.44504 32.5621 1.40955 32.5635 1.37544 32.5635Z"
            fill="#C5EDC5"
        />
        <path
            d="M14.4795 6.65876C14.2053 6.65879 13.9385 6.56938 13.7197 6.4041C13.5008 6.23882 13.3418 6.00668 13.2668 5.74288C13.1918 5.47908 13.2049 5.19802 13.304 4.94232C13.4032 4.68661 13.583 4.47022 13.8163 4.32595C15.1818 3.47721 16.6156 2.74377 18.103 2.13326C18.413 2.00735 18.7604 2.00974 19.0686 2.13993C19.3769 2.27011 19.6208 2.51741 19.7467 2.82744C19.8726 3.13746 19.8702 3.48481 19.74 3.79306C19.6098 4.10132 19.3625 4.34523 19.0525 4.47115C17.6961 5.02751 16.3886 5.69636 15.1438 6.4707C14.9442 6.59441 14.7139 6.65958 14.4791 6.65876H14.4795Z"
            fill="#C5EDC5"
        />
        <path
            d="M4.25803 17.7646C4.06007 17.7649 3.86495 17.7175 3.68923 17.6263C3.39149 17.4749 3.16601 17.2115 3.0623 16.894C2.95859 16.5765 2.98514 16.2308 3.13611 15.9328C3.86223 14.5013 4.70523 13.1321 5.65652 11.8392C5.85659 11.5745 6.15284 11.3992 6.48117 11.3512C6.80951 11.3032 7.14354 11.3864 7.41101 11.5828C7.67847 11.7792 7.85785 12.073 7.91036 12.4006C7.96286 12.7283 7.88427 13.0634 7.69157 13.3336C6.82064 14.5148 6.04885 15.7661 5.38411 17.0746C5.27857 17.2825 5.11745 17.457 4.91866 17.5788C4.71986 17.7006 4.49118 17.7649 4.25803 17.7646Z"
            fill="#C5EDC5"
        />
        <path
            d="M6.62734 46.6091C6.42858 46.6095 6.23257 46.5628 6.05529 46.4729C5.87801 46.383 5.7245 46.2525 5.60728 46.092C4.65813 44.7921 3.81731 43.4165 3.09332 41.979C3.01432 41.8306 2.96577 41.668 2.95052 41.5006C2.93526 41.3332 2.95362 41.1644 3.00451 41.0042C3.0554 40.844 3.13779 40.6955 3.24685 40.5676C3.35591 40.4397 3.48943 40.3349 3.63957 40.2593C3.78971 40.1837 3.95345 40.1389 4.12116 40.1274C4.28887 40.116 4.45717 40.1382 4.61617 40.1927C4.77518 40.2473 4.92169 40.333 5.04708 40.445C5.17248 40.5569 5.27424 40.6928 5.34639 40.8446C6.00748 42.1582 6.77635 43.4148 7.64509 44.6013C7.78248 44.7892 7.86514 45.0116 7.88391 45.2436C7.90268 45.4757 7.85683 45.7084 7.75144 45.916C7.64604 46.1235 7.48523 46.2979 7.28682 46.4197C7.08841 46.5415 6.86015 46.6059 6.62734 46.6059V46.6091Z"
            fill="#C5EDC5"
        />
        <path
            d="M18.5423 55.9448C18.3789 55.9455 18.217 55.9142 18.0657 55.8526C16.5746 55.2377 15.1373 54.4996 13.7688 53.646C13.628 53.5589 13.5058 53.4448 13.4091 53.3104C13.3125 53.1759 13.2432 53.0238 13.2053 52.8626C13.1674 52.7014 13.1617 52.5343 13.1884 52.3709C13.2151 52.2075 13.2737 52.0509 13.3609 51.9102C13.4481 51.7694 13.5621 51.6472 13.6966 51.5505C13.831 51.4538 13.9831 51.3845 14.1443 51.3467C14.3055 51.3088 14.4726 51.303 14.636 51.3297C14.7994 51.3564 14.956 51.415 15.0968 51.5022C16.3462 52.2822 17.6582 52.957 19.0194 53.5198C19.2899 53.6302 19.5137 53.8313 19.6524 54.0885C19.7911 54.3457 19.8361 54.6432 19.7797 54.9299C19.7234 55.2167 19.5692 55.475 19.3434 55.6606C19.1177 55.8462 18.8345 55.9476 18.5423 55.9475V55.9448Z"
            fill="#C5EDC5"
        />
        <path
            d="M23.0628 41.7611L12.4552 31.1522C12.3381 31.0351 12.2453 30.8961 12.1819 30.7432C12.1185 30.5902 12.0859 30.4263 12.0859 30.2607C12.0859 30.0951 12.1185 29.9312 12.1819 29.7782C12.2453 29.6253 12.3381 29.4863 12.4552 29.3692L14.2381 27.5863C14.3552 27.4693 14.4942 27.3764 14.6471 27.313C14.8001 27.2497 14.964 27.2171 15.1296 27.2171C15.2951 27.2171 15.4591 27.2497 15.612 27.313C15.765 27.3764 15.904 27.4693 16.021 27.5863L23.9556 35.5214L41.9784 17.4987C42.0954 17.3816 42.2344 17.2887 42.3874 17.2254C42.5403 17.162 42.7043 17.1294 42.8698 17.1294C43.0354 17.1294 43.1993 17.162 43.3523 17.2254C43.5052 17.2887 43.6442 17.3816 43.7613 17.4987L45.5442 19.2816C45.6613 19.3986 45.7541 19.5376 45.8175 19.6906C45.8809 19.8435 45.9135 20.0075 45.9135 20.173C45.9135 20.3386 45.8809 20.5025 45.8175 20.6555C45.7541 20.8084 45.6613 20.9474 45.5442 21.0645L24.848 41.7607C24.7309 41.8781 24.5918 41.9713 24.4387 42.0349C24.2855 42.0985 24.1213 42.1313 23.9555 42.1313C23.7897 42.1314 23.6255 42.0987 23.4723 42.0352C23.3191 41.9716 23.1799 41.8785 23.0628 41.7611Z"
            fill="#49C549"
        />
    </svg>
);
