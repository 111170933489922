import { useEffect, memo, useState, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Switch, Row, Col, Select, Input } from "antd";
import styled from "styled-components";
import { INews, INewsData, INewsCategory, INewsCategoryParams } from "@interfaces";
import {
    SharedCKEditor,
    StyledSubmitButton,
    StyledCancelButton,
    StyledCard,
    SharedInput,
    // ComponentPeriod,
    ShareButton,
    ComponentModal,
} from "@components";
import { useYup } from "@validations";
import { getCategories, setLoading, selectAuth } from "@redux";
import { MutlipleModuleUploadImage } from "@modules";
import { showErrorMessage, useNotify } from "@utils";
import { enumCategory, MAX_CHAR, PATH_CREATE_NEWS, PATH_NEWS } from "@configs";
import { newsAPI, orgApi } from "@api";
import { ComponentNewsPreview } from "src/Components/news";

interface IProps {
    news: INews | undefined;
    categories: INewsCategory[] | undefined;
}
const newDate = new Date();
const initData: INewsData = {
    organization_id: "",
    title: "",
    detail: "",
    image_url_list: [],
    is_hot_news: false,
    news_category_id: "",
    date_from: new Date(),
    date_to: new Date(newDate.setFullYear(newDate.getFullYear() + 1)),
    is_enable: true,
};
const { Option } = Select;
export const ModuleNewsForm = memo((props: IProps) => {
    //hook
    const { YupNews } = useYup();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const { error, success } = useNotify();
    const [showModal, setShowModal] = useState<boolean>(false);
    const organization_id = useSelector(selectAuth).auth?.admin_data?.organization_id;
    const searchInputRef = useRef<any | null>(null);
    const [cat, setCat] = useState<string>("");
    const pathName = window.location.pathname;
    const createNews = Yup.object().shape(YupNews);
    const { news, categories } = props;
    useEffect(() => {
        if (news) {
            setFieldValue("title", news.title);
            setFieldValue("detail", news.detail);
            setFieldValue("date_from", news.date_from);
            setFieldValue("date_to", news.date_to);
            setFieldValue("news_category_id", news.news_category_id);
            setFieldValue("is_hot_news", news.is_hot_news);
            setFieldValue("image_url_list", news.image_url_list);
        }
    }, [news, categories]);
    const handleEnter = (e: string) => {
        if (e === "Enter") {
            createCategory();
            searchInputRef.current.focus();
        }
    };
    const createCategory = async () => {
        dispatch(setLoading(true));
        // if (news?.organization_id === "") return;
        try {
            const payload: INewsCategoryParams = {
                category_name: cat,
                organization_id: organization_id || "",
                is_enable: true,
            };
            const response = await orgApi.createCat(payload);
            success(response.data.msg);
            dispatch(getCategories({ organization_id: organization_id || "" }));
        } catch (err: any) {
            if (err) {
                error(err.msg);
            } else error(t("message.create.fail"));
        } finally {
            dispatch(setLoading(false));
        }
    };
    const handleNewsSubmit = async (values: INewsData) => {
        dispatch(setLoading(true));
        try {
            if (pathName === PATH_CREATE_NEWS) {
                const res = await newsAPI.create({
                    ...values,
                    ...{ organization_id: organization_id || "" },
                });
                if (res.data.error === "0") {
                    success(res.data.msg);
                    history.push(PATH_NEWS);
                } else {
                    error(res.data.msg);
                }
            } else {
                const res = await newsAPI.update({
                    ...values,
                    ...{ organization_id: organization_id || "" },
                    ...{ news_id: news?._id },
                });
                if (res.data.error === "1") {
                    error(res.data.msg);
                } else {
                    success(res.data.msg);
                    history.push(PATH_NEWS);
                }
            }
        } catch (err: any) {
            if (err.response) {
                error(showErrorMessage(err.response));
            } else error(t("message.error"));
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleCancel = () => {
        history.push(PATH_NEWS);
    };

    const { values, handleChange, handleBlur, handleSubmit, errors, touched, setFieldValue } =
        useFormik({
            initialValues: initData,
            validationSchema: createNews,
            enableReinitialize: true,
            onSubmit: handleNewsSubmit,
        });

    const handleChangeStatus = (e: boolean) => {
        setFieldValue("is_hot_news", e);
    };

    const handleUploadImage = (images: string[]) => {
        setFieldValue("image_url_list", [...images]);
    };

    const handleChangeGuideLine = (event: any, editors: any) => {
        const data = editors.getData();
        setFieldValue("detail", data);
    };
    const handleSelectType = (value: string) => {
        if (value === enumCategory.ADD_NEWS) {
            setShowModal(true);
        } else {
            setFieldValue("news_category_id", value);
        }
    };
    const handleSearchInput = (value: string) => {
        setCat(value);
    };
    const handleRemoveCategory = async (value: string) => {
        const removeItem = categories?.find((item) => item.category_name === value);
        if (removeItem) {
            const payload = {
                organization_id: organization_id || "",
                news_category_id: removeItem._id,
            };
            await orgApi.deleteCat(payload);
            dispatch(getCategories({ organization_id: organization_id || "" }));
        }
    };
    return (
        <div className="form-wrap">
            <form onSubmit={handleSubmit}>
                <div className="page-header">
                    <h3>
                        {pathName.includes(PATH_NEWS)
                            ? t("page.create_headline")
                            : t("page.edit_headline")}
                    </h3>
                    <p className="sub-title">{t("page.specify_detail")}</p>
                </div>
                <div className="page-body">
                    <StyledCard>
                        <div className="title">{t("page.news_detail")}</div>
                        <div className="card-wrap">
                            <MutlipleModuleUploadImage
                                images={values.image_url_list}
                                handleGetImage={handleUploadImage}
                            />
                        </div>
                        <div>
                            <Row gutter={16}>
                                <Col xs={12} sm={12} md={24}>
                                    <SharedInput
                                        label={t("page.headlines")}
                                        // descLabel={t("page._2_100_characters")}
                                        name="title"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.title}
                                        errors={errors.title}
                                        touched={touched.title}
                                        parentClassName="mb-6"
                                        suffixIcon={
                                            <span>{`${values.title.length} / ${MAX_CHAR}`}</span>
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row className="select-layout">
                                <Col md={24}>
                                    <div className="label">{t("page.news_type")}</div>
                                    <div className="input-field">
                                        <Select
                                            style={{ width: "100%", height: 48 }}
                                            placeholder={t("page.news_type")}
                                            onChange={handleSelectType}
                                            defaultActiveFirstOption={true}
                                            value={values.news_category_id}
                                        >
                                            {categories &&
                                                categories.map((item, index) => {
                                                    return (
                                                        <Option key={index} value={item._id}>
                                                            {item.category_name}
                                                        </Option>
                                                    );
                                                })}
                                            <Option key={"add-new"} value={enumCategory.ADD_NEWS}>
                                                {"+"} {t("page.specify_news_type")}
                                            </Option>
                                        </Select>
                                        <div className="error">
                                            {/* {errors.businessTypeName} */}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="card-wrap">
                            <div className="title">
                                {t("page.descriptions")}
                                {/* <span>{t("page._2_2000_characters")}</span> */}
                            </div>
                            <SharedCKEditor
                                editor={values.detail || ""}
                                handleChangeEditor={handleChangeGuideLine}
                                name="description"
                            />
                        </div>
                        <StyledSwitch className="switch-field">
                            <Switch
                                checked={values.is_hot_news}
                                className="is-hot-news"
                                onChange={handleChangeStatus}
                            />
                            <p>{t("page.show_in_highlight")}</p>
                        </StyledSwitch>
                    </StyledCard>
                    <div className="btn-layout">
                        <StyledSubmitButton
                            type="default"
                            text={t("page.save")}
                            htmlType="submit"
                            // disable={isSubmitting}
                        />
                        <StyledCancelButton
                            type="sub"
                            text={t("page.cancel")}
                            htmlType="button"
                            onClick={handleCancel}
                        />
                    </div>
                </div>
            </form>
            <ComponentNewsPreview news={values} />
            <ComponentModal
                isVisible={showModal}
                title={t("page.add_news_type")}
                width={680}
                style={{ marginTop: "14vh" }}
                className={"add-category"}
                onCancel={() => setShowModal(false)}
            >
                <StyledBody>
                    <div className="form-input">
                        <p>{t("page.news_type")}</p>
                        <Select
                            maxTagCount={5}
                            mode="multiple"
                            showSearch
                            className="input-with-search"
                            placeholder={t("page.choose_variants")}
                            optionFilterProp="children"
                            onSearch={handleSearchInput}
                            onInputKeyDown={(e) => handleEnter(e.key)}
                            notFoundContent={false}
                            onDeselect={handleRemoveCategory}
                            filterOption={(input, option) =>
                                //@ts-ignore
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                0
                            }
                        >
                            {categories?.map((item) => (
                                <Option key={item._id} value={item.category_name}>
                                    {item.category_name}
                                </Option>
                            ))}
                        </Select>
                        <Input ref={searchInputRef} style={{ opacity: "0" }} readOnly={true} />
                    </div>
                </StyledBody>
                <div className="footer">
                    <ShareButton
                        type="primary"
                        size="large"
                        className="add-cat-btn"
                        text={t("page.agree")}
                        onClick={() => setShowModal(false)}
                    />
                </div>
            </ComponentModal>
        </div>
    );
});

const StyledSwitch = styled.div`
    display: flex;
    align-items: center;
    p {
        margin-bottom: 0;
    }
`;

const StyledBody = styled.div`
    .form-input {
        margin-top: 20px;
        p {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #29304d;
        }
    }
`;
