import React, { useEffect, memo, useState, useCallback } from "react";
import { Formik, Form, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Row, Select, Col, Input, Spin, Modal, Tooltip, Button, Avatar } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import styled from "styled-components";
import {
    IDepartment,
    IGroupContact,
    IOrganizationDetail,
    IProvince,
    // IPhone_list,
} from "@interfaces";
import {
    StyledSubmitButton,
    StyledCancelButton,
    StyledCard,
    SharedInput,
    ShareButton,
    IconDelete,
    IconDrag,
    IconCopy,
    SharedButtonDefault,
    LocationIcon,
    ComponentSearchMap,
    IconCamera,
} from "@components";
import { useYup } from "@validations";
import { setLoading, selectAuth, setFullName } from "@redux";
import { useNotify } from "@utils";
import { defautLocation, PATH_HOME, IMAGE_TYPE, enumValidation } from "@configs";
import { orgApi, newsAPI } from "@api";
import Geocode from "react-geocode";
import { copyText } from "@utils";

Geocode.setApiKey("AIzaSyAW-yZSy9ibuh4jq9QmiV5_cLqC9VKxdKQ");
Geocode.setLanguage("en");
Geocode.setRegion("th");
Geocode.setLocationType("ROOFTOP");

interface IProps {
    org: IOrganizationDetail | undefined;
}
const externalDepartment: IDepartment = {
    name: "",
    link_url: "",
};
const groupContact: IGroupContact = {
    name: "",
    phone_no: "",
    phone_list: [
        {
            sub_name: "",
            sub_phone_no: "",
        },
    ],
};
// const subPhone: IPhone_list = {
//     sub_name: "",
//     sub_phone_no: "",
// };

const initData: IOrganizationDetail = {
    organization_code: "",
    organization_name: "",
    organization_name_th: "",
    organization_phone_no: "",
    organization_line: "",
    organization_facebook: "",
    organization_instagram: "",
    organization_email: "",
    location_lat: 13.7710578,
    location_lon: 100.519741,
    address: "",
    working_time: "",
    image_cover: "",
    external_department: [
        {
            name: "",
            link_url: "",
        },
    ],
    group_of_contact: [
        {
            name: "",
            phone_no: "",
            phone_list: [
                {
                    sub_name: "",
                    sub_phone_no: "",
                },
            ],
        },
    ],
    location: {
        type: "",
        latlon: [],
    },
};
const { Option } = Select;
const { TextArea } = Input;
export const ModuleSettingForm = memo((props: IProps) => {
    //hook
    const { YupSetting } = useYup();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { error, success } = useNotify();
    const [showModal, setShowModal] = useState<boolean>(false);
    const organization_id = useSelector(selectAuth).auth?.admin_data?.organization_id;
    const [cat, setCat] = useState<string>("");
    const [isLoadingInput, setLoadingInput] = useState(false);
    const [lat, setLat] = useState(13.7710578);
    const [lon, setLon] = useState(100.519741);
    const [currentAddress, setCurrentAddress] = useState("");
    const updateSetting = Yup.object().shape(YupSetting);
    const [formInit, setFormInit] = useState<IOrganizationDetail>(initData);
    const [coverImage, setCoverImage] = useState("");
    const { org } = props;
    const [isCopied, setIsCopied] = useState(false);
    const [provinceList, setProvinceList] = useState<IProvince[]>([]);
    const [selectProvince, setSelectProvince] = useState<string>("");
    const handleCopyText = useCallback((params) => {
        copyText(params, () => showTooltipCopy());
    }, []);
    const showTooltipCopy = () => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 5000);
    };
    useEffect(() => {
        getProvinceList();
    }, []);
    useEffect(() => {
        if (org) {
            console.log('org', org.province_id);
            setFormInit({
                organization_code: org.organization_code,
                organization_name: org.organization_name,
                organization_name_th: org.organization_name_th,
                organization_phone_no: org.organization_phone_no,
                organization_line: org.organization_line,
                organization_facebook: org.organization_facebook,
                organization_instagram: org.organization_instagram,
                organization_email: org.organization_email,
                location_lat: org.location_lat,
                location_lon: org.location_lon,
                address: org.address,
                working_time: org.working_time,
                external_department: org.external_department,
                group_of_contact: org.group_of_contact,
                location: org.location,
                image_cover: org.image_cover,
            });
            if (org?.province_id) {
                setSelectProvince(org.province_id);
            }
            if (org.location.latlon.length > 0) {
                setLat(org.location.latlon[1]);
            }
            if (org.image_cover) {
                setCoverImage(org.image_cover);
            }
            if (org.location.latlon.length > 0) {
                setLon(org.location.latlon[0]);
            }
            dispatch(setFullName(org.organization_name_th));
            setCurrentAddress(org.address);
        }
    }, [org]);
    const limit_options = [
        {
            label: `${t("page.limit")}`,
            value: "limit",
        },
        {
            label: `${t("page.no_limit")}`,
            value: "no_limit",
        },
    ];
    const getProvinceList = async () => {
        try {
            let response = await orgApi.getProvinceList({
                xpage: 1,
                xlimit: 100,
            });
            console.log('province list', response.data.result[0]);
            if (response.data.error === "0") {
                setProvinceList(response.data.result);
            } else {
                error(response.data.msg);
            }
        } catch (error: any) {
            if (error) {
                error(error.msg);
            }
        }
    };
    const handleSettingSubmit = async (values: IOrganizationDetail) => {
        dispatch(setLoading(true));
        try {
            const res = await orgApi.updateOrganizationDetail({
                ...values,
                ...{ organization_id: organization_id },
                ...{ address: currentAddress },
                ...{ location_lat: lat },
                ...{ location_lon: lon },
                ...{ image_cover: coverImage },
                ...{ province_id: selectProvince },
            });
            if (res.data.error === "1") {
                error(res.data.msg);
            } else {
                success(res.data.msg);
                dispatch(setFullName(res.data.result.organization_name_th));
            }
            // history.push(PATH_NEWS);
        } catch (err: any) {
            if (err) {
                error(err.msg);
            }
        } finally {
            dispatch(setLoading(false));
        }
    };
    const provinceChange = (id: string) => {
        setSelectProvince(id);
        // setValues({
        //     ...value,
        //     area_id: id,
        // });
    };
    const handleCancel = () => {
        history.push(PATH_HOME);
    };

    const handleGetLocation = () => {
        setShowModal(true);
    };
    const onChangeLocation = (lat: number, lon: number, address: string) => {
        // setFieldValue("location_lat", lat);
        // setFieldValue("location_lon", lon);
        setLat(lat);
        setLon(lon);
        if (address === "") {
            formatAddress(lat, lon);
        } else {
            setCurrentAddress(address);
            // setFieldValue("address", address);
        }
    };
    const formatAddress = (lat, long) => {
        setLoadingInput(true);
        Geocode.fromLatLng(lat, long).then(
            (response) => {
                const res = response.results[0].formatted_address;
                setCurrentAddress(res);
                // setFieldValue("address", res);
                setLoadingInput(false);
            },
            (error) => {
                console.error(error);
            }
        );
    };
    const handleChangeAddress = (e) => {
        setCurrentAddress(e.target.value);
    };
    const handleRemoveImageLogo = () => {
        setCoverImage("");
    };
    const handleChangeLogo = async (e: React.ChangeEvent<HTMLInputElement>) => {
        try {
            //@ts-ignore
            const image = e.target.files[0];
            if (!image) {
                //WHAT: image empty
                error(t("validation.image.empty"));
                return;
            }
            if (!IMAGE_TYPE.some((imageType) => imageType === image.type)) {
                error(t("validation.image.wrong_type"));
                return;
            }
            if (image.size >= enumValidation.MAX_FILE_SIZE) {
                error(t("validation.image.max_size"));
                return;
            }
            const formData = new FormData();
            formData.append("file", image);
            const res = await newsAPI.uploadImage(formData);
            setCoverImage(res.data.result.publicUrl);
        } catch (error: any) {
            if (error) {
                error(error.msg);
            }
        }
    };
    return (
        <div className="form-wrap">
            <Modal
                title={t("page.conplaint_position")}
                visible={showModal}
                width={660}
                footer={null}
                onCancel={() => setShowModal(false)}
            >
                <ComponentSearchMap
                    location_lat={lat || defautLocation.lat}
                    location_lon={lon || defautLocation.long}
                    onChangeLocation={onChangeLocation}
                />
                <SharedButtonDefault
                    style={{
                        background: "white",
                        fontSize: 16,
                        padding: 14,
                        fontWeight: 600,
                        height: 49,
                        backgroundColor: "#0263E0",
                        color: "#fff",
                    }}
                    text={t("page.confirm_location")}
                    type="default"
                    size="default"
                    className="default-btn"
                    onClick={() => setShowModal(false)}
                />
            </Modal>
            <Formik
                initialValues={formInit}
                onSubmit={handleSettingSubmit}
                enableReinitialize={true}
                validationSchema={updateSetting}
            >
                {({ values, touched, handleChange, handleBlur, errors }) => (
                    <Form>
                        <div className="page-header">
                            <h3>{t("page.tessabarn_information")}</h3>
                            <p className="sub-title">{t("page.please_provide_complete")}</p>
                        </div>
                        <div className="page-body">
                            <div className="card-wrap">
                                <StyledCard>
                                    <div className="card-title">{t("page.tessabarn_details")}</div>
                                    <SharedInput
                                        label={t("page.tessabarn_name_thai")}
                                        // descLabel={t("page._2_100_characters")}
                                        name="organization_name_th"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.organization_name_th}
                                        errors={errors.organization_name_th}
                                        touched={touched.organization_name_th}
                                        parentClassName="mb-6"
                                    />
                                    <SharedInput
                                        label={t("page.tessabarn_name_eng")}
                                        // descLabel={t("page._2_100_characters")}
                                        name="organization_name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.organization_name}
                                        errors={errors.organization_name}
                                        touched={touched.organization_name}
                                        parentClassName="mb-6"
                                    />
                                    <SharedInput
                                        label={t("page.tessabarn_prefix")}
                                        // descLabel={t("page._2_100_characters")}
                                        name="organization_code"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.organization_code}
                                        errors={errors.organization_code}
                                        touched={touched.organization_code}
                                        parentClassName="mb-6"
                                    />
                                    <div className="copy-layout">
                                        <div className="copy-input">
                                            <SharedInput
                                                label={t("page.tessabarn_prefix_url")}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={`https://${values.organization_code}.smart-tessabarn.app`}
                                                parentClassName="mb-6"
                                                disable={true}
                                            />
                                        </div>
                                        <div className="copy-btn">
                                            <Tooltip
                                                title={
                                                    isCopied
                                                        ? t("message.copied")
                                                        : t("message.copy")
                                                }
                                            >
                                                <div
                                                    className="copy"
                                                    onClick={() =>
                                                        handleCopyText(
                                                            `https://${values.organization_code}.smart-tessabarn.app`
                                                        )
                                                    }
                                                >
                                                    <IconCopy />
                                                </div>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </StyledCard>
                            </div>
                            <div className="card-wrap">
                                <StyledCard>
                                    <div className="card-title">{t("page.contact_details")}</div>
                                    <SharedInput
                                        label={t("page.tessabarn_phone")}
                                        // descLabel={t("page._2_100_characters")}
                                        name="organization_phone_no"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.organization_phone_no}
                                        errors={errors.organization_phone_no}
                                        touched={touched.organization_phone_no}
                                        parentClassName="mb-6"
                                        className="eng"
                                    />
                                    <SharedInput
                                        label={t("page.working_time")}
                                        // descLabel={t("page._2_100_characters")}
                                        name="working_time"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.working_time}
                                        errors={errors.working_time}
                                        touched={touched.working_time}
                                        parentClassName="mb-6"
                                        className="eng"
                                    />
                                </StyledCard>
                            </div>
                            <div className="card-wrap">
                                <StyledCard>
                                    <div className="card-title">{t("page.tessabarn_address")}</div>
                                    <div className="custom-input">
                                        <div className="label">{t("page.tessabarn_location")}</div>
                                        <TextArea
                                            name="address"
                                            onBlur={handleBlur}
                                            onChange={handleChangeAddress}
                                            value={currentAddress}
                                            rows={4}
                                        />
                                        <div className="errors"></div>
                                    </div>
                                    <div className="custom-input">
                                        <div className="label">{t("page.tessabarn_location")}</div>
                                        <div className="location-input">
                                            <div className="input-layout">
                                                {isLoadingInput && (
                                                    <div className="loading-input">
                                                        <Spin />
                                                    </div>
                                                )}
                                                <SharedInput
                                                    // label={}
                                                    // name="location"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={currentAddress}
                                                    // errors={errors.title}
                                                    // touched={touched.title}
                                                    placeholder={t("page.select_pin_button")}
                                                    disable={true}
                                                    parentClassName="mb-6"
                                                />
                                            </div>
                                            <SharedButtonDefault
                                                type="default"
                                                size="large"
                                                className="ant-btn-primary"
                                                text={t("page.select_pin")}
                                                disable={isLoadingInput}
                                                onClick={handleGetLocation}
                                                style={{ height: 48 }}
                                                customIcon={<LocationIcon />}
                                            />
                                        </div>
                                        <div className="errors"></div>
                                    </div>
                                    <div className="avatar">
                                        <div className="box_title">
                                            <p>{t("page.municipal_image")}</p>
                                            {/* <Tooltip
                                                placement="topLeft"
                                                title={`${textLogoString}`}
                                            >
                                                <Button>
                                                    <InfoIcon />
                                                </Button>
                                            </Tooltip> */}
                                        </div>
                                        <div className="avatar-layout">
                                            <Avatar size={110} src={coverImage} shape="square" />
                                            {coverImage && (
                                                <div className="remove-icon-layout">
                                                    <DeleteOutlined
                                                        style={{ color: "white", fontSize: 20 }}
                                                        onClick={handleRemoveImageLogo}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <input
                                            id="file_update_logo"
                                            type="file"
                                            hidden
                                            onChange={handleChangeLogo}
                                        />
                                        <label className="camera" htmlFor="file_update_logo">
                                            <IconCamera />
                                        </label>
                                    </div>
                                    <div className="avatar">
                                        <Col md={12}>
                                            <label className="label">
                                                <span className="label-name">จังหวัด*</span>
                                                <br /> <br />
                                            </label>
                                            <Select
                                                value={selectProvince}
                                                onChange={provinceChange}
                                            >
                                                {provinceList.length > 0 &&
                                                    provinceList.map((item: IProvince, index: number) => {
                                                        return (
                                                            <Option value={item.province_id} key={index}>
                                                                {item.province_name_tha}
                                                            </Option>
                                                        );
                                                    })
                                                }


                                            </Select>
                                        </Col>
                                    </div>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                </StyledCard>
                            </div>
                            <div className="card-wrap">
                                <StyledCard>
                                    <div className="card-title">{t("page.online_channel")}</div>
                                    <SharedInput
                                        label={t("page.LINEOA_chat")}
                                        // descLabel={t("page._2_100_characters")}
                                        name="organization_line"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.organization_line}
                                        errors={errors.organization_line}
                                        touched={touched.organization_line}
                                        parentClassName="mb-6"
                                    />
                                    <SharedInput
                                        label={t("page.facebook")}
                                        // descLabel={t("page._2_100_characters")}
                                        name="organization_facebook"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.organization_facebook}
                                        errors={errors.organization_facebook}
                                        touched={touched.organization_facebook}
                                        parentClassName="mb-6"
                                    />
                                    <SharedInput
                                        label={t("page.instagram")}
                                        // descLabel={t("page._2_100_characters")}
                                        name="organization_instagram"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.organization_instagram}
                                        errors={errors.organization_instagram}
                                        touched={touched.organization_instagram}
                                        parentClassName="mb-6"
                                    />
                                    <SharedInput
                                        label={t("page.email")}
                                        // descLabel={t("page._2_100_characters")}
                                        name="organization_email"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.organization_email}
                                        errors={errors.organization_email}
                                        touched={touched.organization_email}
                                        parentClassName="mb-6"
                                    />
                                </StyledCard>
                            </div>
                            <div className="card-wrap">
                                <StyledCard>
                                    <div className="card-title">
                                        {t("page.relevant_division_name")}
                                    </div>
                                    <FieldArray name="external_department">
                                        {({ remove, push }) => (
                                            <div>
                                                {values.external_department.length > 0 &&
                                                    values.external_department.map(
                                                        (item, index) => (
                                                            <div key={index}>
                                                                <Row gutter={20}>
                                                                    <Col span={2}>
                                                                        <div className="row-icon">
                                                                            <IconDrag color="#29304D" />
                                                                        </div>
                                                                    </Col>
                                                                    <Col span={10}>
                                                                        <SharedInput
                                                                            label={t(
                                                                                "page.add_relevant_division"
                                                                            )}
                                                                            name={`external_department.${index}.name`}
                                                                            onBlur={handleBlur}
                                                                            onChange={handleChange}
                                                                            value={
                                                                                values
                                                                                    .external_department[
                                                                                    index
                                                                                ].name
                                                                            }
                                                                            parentClassName="mb-6"
                                                                        />
                                                                        <ErrorMessage
                                                                            name={`external_department.${index}.name`}
                                                                            component="div"
                                                                            className="field-error"
                                                                        />
                                                                    </Col>
                                                                    <Col span={10}>
                                                                        <SharedInput
                                                                            label={t(
                                                                                "page.related_agencies"
                                                                            )}
                                                                            name={`external_department.${index}.link_url`}
                                                                            onBlur={handleBlur}
                                                                            onChange={handleChange}
                                                                            value={
                                                                                values
                                                                                    .external_department[
                                                                                    index
                                                                                ].link_url
                                                                            }
                                                                            parentClassName="mb-6"
                                                                        />
                                                                        <ErrorMessage
                                                                            name={`external_department.${index}.link_url`}
                                                                            component="div"
                                                                            className="field-error"
                                                                        />
                                                                    </Col>
                                                                    <Col span={2}>
                                                                        <div
                                                                            className="row-icon"
                                                                            onClick={() =>
                                                                                remove(index)
                                                                            }
                                                                        >
                                                                            <IconDelete color="#000000" />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        )
                                                    )}
                                                <div className="add-btn-layout">
                                                    <ShareButton
                                                        type="default"
                                                        size="large"
                                                        icon="plus"
                                                        className="ant-btn-primary"
                                                        text={t("page.add_relevant_division")}
                                                        onClick={() => push(externalDepartment)}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </FieldArray>
                                </StyledCard>
                            </div>
                            <div className="card-wrap">
                                <StyledCard>
                                    <div className="card-title">
                                        {t("page.name_of_phone_number_group")}
                                    </div>
                                    <FieldArray name="group_of_contact">
                                        {({ remove, push }) => (
                                            <div className="phone-group">
                                                {values.group_of_contact.length > 0 &&
                                                    values.group_of_contact.map((item, index) => (
                                                        <div
                                                            key={index}
                                                            className="phone-group-item"
                                                        >
                                                            <div className="phone-header">
                                                                <div className="label-name">
                                                                    {t("page.phone_number_group", {
                                                                        number: index + 1,
                                                                    })}
                                                                </div>
                                                                <div className="action-btn">
                                                                    <IconCopy />
                                                                    <div
                                                                        className="delete-icon"
                                                                        onClick={() =>
                                                                            remove(index)
                                                                        }
                                                                    >
                                                                        <IconDelete color="#000000" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <SharedInput
                                                                label={t(
                                                                    "page.name_of_phone_number_group"
                                                                )}
                                                                name={`group_of_contact.${index}.name`}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={
                                                                    values.group_of_contact[index]
                                                                        .name
                                                                }
                                                                parentClassName="mb-6"
                                                            />
                                                            <SharedInput
                                                                label={t("page.phone_no")}
                                                                name={`group_of_contact.${index}.phone_no`}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                type="number"
                                                                value={
                                                                    values.group_of_contact[index]
                                                                        .phone_no
                                                                }
                                                                parentClassName="mb-6"
                                                                className="eng"
                                                            />
                                                        </div>
                                                    ))}
                                                <div className="add-btn-layout">
                                                    <ShareButton
                                                        type="default"
                                                        size="large"
                                                        icon="plus"
                                                        className="ant-btn-primary"
                                                        text={t("page.add_relevant_division")}
                                                        onClick={() => push(groupContact)}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </FieldArray>
                                </StyledCard>
                            </div>
                            <div className="btn-layout">
                                <StyledSubmitButton
                                    type="default"
                                    text={t("page.save")}
                                    htmlType="submit"
                                // disable={isSubmitting}
                                />
                                <StyledCancelButton
                                    type="sub"
                                    text={t("page.cancel")}
                                    htmlType="button"
                                    onClick={handleCancel}
                                />
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
            {/* </form> */}
        </div>
    );
});

const StyledSwitch = styled.div`
    display: flex;
    align-items: center;
    p {
        margin-bottom: 0;
    }
`;
