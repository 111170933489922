import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { Col, Row, Avatar, Button } from "antd";
import moment from "moment";

import { IConplaint } from "@interfaces";
import {
    dateFormat,
    defaultImage,
    enumNewsType,
    enumProcessStatus,
    PATH_COMPLAINT,
} from "@configs";

import { ShareButton, IconDrag } from "@components";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import styled from "styled-components";
interface IProps {
    data: IConplaint;
    itemJson: string;
    callbackGetList: () => void;
    index: number;
    newType?: enumNewsType;
}

export const ComponentRowConpliantMobile = (props: IProps) => {
    //hooks
    const { t } = useTranslation();
    const history = useHistory();
    //props
    const { data, itemJson } = props;

    const { attributes, listeners, setNodeRef, transform } = useSortable({
        id: itemJson,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
    };

    const getProcessClass = (value: enumProcessStatus) => {
        if (value === enumProcessStatus.PENDING) return "status-button warning";
        if (value === enumProcessStatus.DOING) return "status-button doing";
        if (value === enumProcessStatus.COMPLETE) return "status-button complete";
        if (value === enumProcessStatus.CANCELED) return "status-button canceled";
    };
    return (
        <StyledMobileItem>
            <div className="m-table-element">
                <div className="m-table-row">
                    <Row gutter={20}>
                        <Col className="col-item" span={12}>
                            ผู้แจ้งปัญหาร้องทุกข์
                        </Col>
                        <Col className="col-item right" span={12}>
                            <p>{data?.complainer.name || t("page.empty_text")}</p>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col className="col-item " span={12}>
                            หัวข้อรายงาน
                        </Col>
                        <Col className="col-item right" span={12}>
                            <p>{data.header || t("page.empty_text")}</p>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col className="col-item" span={12}>
                            วันที่รายงาน
                        </Col>
                        <Col className="col-item right" span={12}>
                            <p className="eng">
                                {moment(data.created_date).format(dateFormat) ||
                                    t("page.empty_text")}
                            </p>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col className="col-item" span={12}>
                            สถานะ
                        </Col>
                        <Col className="col-item right" span={12}>
                            <div className={getProcessClass(data.status)}>
                                <p>{t(`page.${data.status}`) || t("page.empty_text")}</p>
                            </div>
                        </Col>
                    </Row>
                    {/* <Row gutter={20}>
                        <Col className="col-item" span={12}>
                        ผู้รับเรื่อง
                        </Col>
                        <Col className="col-item right" span={12}>
                        
                        </Col>
                    </Row> */}
                    <div className="button-layout">
                        <Button
                            className="carryout-btn"
                            onClick={() => history.push(PATH_COMPLAINT + "/" + data._id)}
                        >
                            {t("page.carry_out")}
                        </Button>
                    </div>
                </div>
            </div>
        </StyledMobileItem>
    );
};

const StyledMobileItem = styled.div`
    p {
        margin: 0px;
    }
    .m-table-element {
        padding: 16px;
        background: #fff;
        margin-bottom: 20px;
        border-radius: 8px;
        .m-table-row {
            .ant-row {
                padding: 8px 0;
                border-bottom: 0.5px solid #f3f3f3;
                .ant-col {
                    font-style: normal;
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 22px;
                    color: #a5a5a5;
                    &.right {
                        justify-content: right;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 19px;
                        display: flex;
                        align-items: center;
                        color: #29304d;
                    }
                }
            }
            .button-layout {
                .carryout-btn {
                    width: 100%;
                    height: 40px;
                    background: #ffffff;
                    border: 0.5px solid #8a959e;
                    border-radius: 4px;
                }
            }
        }
        .status-button {
            display: flex;
            padding: 8px 16px;
            height: 40px;
            border-radius: 40px;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            p {
                @media (max-width: ${(p) => p.theme.breakPoints.breakMacLaptop}) {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    width: 76px;
                }
            }
        }
        .warning {
            background: rgba(235, 173, 15, 0.12);
            p {
                color: #ebad0f;
            }
        }
        .doing {
            background: rgba(13, 110, 253, 0.12);
            p {
                color: #0d6efd;
            }
        }
        .complete {
            background: rgba(73, 197, 73, 0.12);
            p {
                color: #49c549;
            }
        }
        .canceled {
            background: #e1e1e1;
            p {
                color: #646464;
            }
        }
    }
`;
