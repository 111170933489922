import { useEffect, memo, useState, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Row, Col, Select, Modal, DatePicker, ConfigProvider, Button } from "antd";
import styled from "styled-components";
import moment from "moment";
import "moment/locale/th";
import locale from "antd/es/locale-provider/th_TH";
import {
  IAmphur,
  IResultUpdate,
  ITrashfeeArea,
  ITrashfeeAreaCreate,
  ITrashfeeDistrict,
  ITrashfeeHouse,
  ITrashfeeHouseCreate,
  ITrashfeeManualCreate,
} from "@interfaces";
import { StyledSubmitButton, StyledCancelButton, StyledCard, SharedInput, SharedButtonDefault } from "@components";
import { useYup } from "@validations";
import { setLoading, selectAuth, selectContact } from "@redux";
import { showErrorMessage, useNotify } from "@utils";
import { PATH_TRASH_FEE_BILL, PATH_TRASH_FEE_BILL_CREATE, PATH_TRASH_FEE_BILL_MANUAL_CREATE, PATH_TRASH_FEE_HOUSE, PATH_TRASH_FEE_HOUSE_CREATE } from "@configs";
import { trashfeeAPI } from "@api";



const MONTHlIST = [
  'มกราคม',
  'กุมภาพันธ์',
  'มีนาคม',
  'เมษายน',
  'พฤษภาคม',
  'มิถุนายน',
  'กรกฎาคม',
  'สิงหาคม',
  'กันยายน',
  'ตุลาคม',
  'พฤศจิกายน',
  'ธันวาคม'
];
const _year = (new Date()).getFullYear();
const YEARLIST = Array.from(new Array(100), (val, index) => index + _year - 98 + 543).reverse();

const dateFormat = 'DD/MM/YYYY';

interface IProps {
  // result: IResult | undefined;
  // districtList: ITrashfeeDistrict[];
  areaList: ITrashfeeArea[];
}

const initData: IResultUpdate = {
  organization_id: "",
  title: "",
  image_url: "",
  is_enable: true,
  information_id: "",
  created_date: new Date().toString(),
  documents: [],
};
const { Option } = Select;
export const ModuleBillForm = memo((props: IProps) => {
  //hook
  const { YupNews, YupBidding } = useYup();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { error, success } = useNotify();
  const search_params = useSelector(selectContact).paramsContact;

  const organization_id = useSelector(selectAuth).auth?.admin_data?.organization_id;

  const pathName = window.location.pathname;
  const org_id = useSelector(selectAuth).auth?.organization_data?._id;

  const createBidding = Yup.object().shape(YupBidding);
  const { areaList } = props;
  const prefix = ['คุณ', 'นาย', 'นาง', 'นางสาว'];
  const [autoFill, setAutoFill] = useState<boolean>(false);
  const [focusAmphur, setFocusAmphur] = useState<string>("");
  const [value, setValue] = useState({
    organization_id: "",
    profile_id: "",
    area_id: "",
    register_no: "",
    prefix: "",
    name: "",
    surname: "",
    card_no: "",
    house_no: "",
    moo: "",
    soi: "",
    road: "",
    district_id: "",
    amphur_id: "",
    house_type: "",
    trash_fee: 0,
    id_invoice: "",
    month: "",
    year: "",
    end_date: "",
  });
  const [amphurList, setAmphurList] = useState<IAmphur[]>([]);
  const [districtList, setDistrictList] = useState<ITrashfeeDistrict[]>([]);
  useEffect(() => {
    if (org_id && org_id !== "") {
      console.log('UE', org_id);
      fetchAmphur();
    }
  }, [org_id]);
  useEffect(() => {
    if (value.amphur_id !== "") {
      console.log('amphur id', value.amphur_id);
      fetchDistrict(value.amphur_id);
    };
  }, [value]);

  useEffect(() => {
    if (focusAmphur !== "") {
      fetchDistrict(focusAmphur);
    }
  }, [focusAmphur]);

  const fetchDistrict = async (aId: string) => {
    try {
      const response = await trashfeeAPI.getDistrictList({
        amphur_id: aId,
        xpage: 1,
        xlimit: 30,
      });
      console.log(response.data.result);
      setDistrictList(response.data.result);
    } catch (err: any) {
      error(err.message);
    }
  };

  const fetchAmphur = async () => {
    try {
      let response = await trashfeeAPI.getAllAmphurByOrganizationId({
        organization_id: org_id || "",
      });
      console.log('fetch amphur', response.data.result);
      if (response.data.error === "0") {
        setAmphurList(response.data.result);
        //
      } else {
        error(response.data.msg);
      }
    } catch (error: any) {
      if (error) {
        error(error.msg);
      }
    }
  };

  const handleBiddingSubmit = (values: IResultUpdate) => {
    // console.log("hello submit", values);
  };

  const handleClose = () => {
    history.push(PATH_TRASH_FEE_BILL);
  };

  const prefixChange = (text: string) => {
    setValue({
      ...value,
      prefix: text,
    });
  };

  const monthChange = (index: string) => {
    setValue({
      ...value,
      month: index,
    });
  };

  const yearChange = (text: string) => {
    setValue({
      ...value,
      year: text,
    });
  };

  const AmphurChange = (id: string) => {
    setValue({
      ...value,
      amphur_id: id,
      district_id: "",
    });
    setFocusAmphur(id);
  };

  const districtChange = (id: string) => {
    setValue({
      ...value,
      district_id: id,
    });
  };
  const areaChange = (id: string) => {
    setValue({
      ...value,
      area_id: id,
    });
  };

  const { values, handleChange, handleBlur, handleSubmit, errors, touched, setFieldValue } =
    useFormik({
      initialValues: initData,
      validationSchema: createBidding,
      enableReinitialize: true,
      onSubmit: handleBiddingSubmit,
    });

  const saveHouse = async () => {
    // save house
    if (value.id_invoice === "" ||
      value.month === "" ||
      value.year === "" ||
      value.end_date === "") {
      error("กรุณากรอกข้อมูลให้ครบ");
    } else {
      if (pathName === PATH_TRASH_FEE_HOUSE_CREATE) {
        try {
          let payload: ITrashfeeHouseCreate = {
            organization_id: organization_id || "",
            profile_id: "",
            area_id: value.area_id,
            register_no: value.register_no,
            prefix: value.prefix,
            name: value.name,
            surname: value.surname,
            card_no: value.card_no,

            house_no: value.house_no,
            moo: value.moo,
            soi: value.soi,
            road: value.road,
            district_id: value.district_id,
            house_type: "home",
            trash_fee: 0,
          };
          const response = await trashfeeAPI.createHouse(payload);
          if (response.data.error === "0") {
            success(t("message.create.success"));
            handleClose();
          } else {
            error(response.data.msg);
          }

        } catch (err: any) {
          if (err.response) {
            err(showErrorMessage(err.response));
          } else {
            error(t("message.error"));
          }
        } finally {
          dispatch(setLoading(false));
        }
      }
    }

  };

  const saveArea = () => {
    dispatch(setLoading(true));
    try {
      // const payload: ITrashfeeAreaCreate = {
      //   organization_id: organization_id || "",
      //   area_name: 
      // }
    } catch {

    }
  };
  const createManual = async () => {
    if (value.id_invoice === "" ||
      value.month === "" ||
      value.year === "" ||
      value.end_date === "") {
      error("กรุณากรอกข้อมูลให้ครบ");
    } else {
      try {
        dispatch(setLoading(true));
        let year_cycle = (parseInt(value.year) - 543).toString();
        let getDate = value.end_date.split("/");
        let expired_date = [getDate[2], getDate[1], getDate[0]].join("-");

        let payload: ITrashfeeManualCreate = {
          organization_id: organization_id || "",
          register_no: value.register_no,
          month_cycle: value.month,
          year_cycle: year_cycle,
          expired_date: expired_date,
          invoice_no: value.id_invoice,
          trash_fee: value.trash_fee,
        };

        let response = await trashfeeAPI.createTrashfeeManual(payload);
        console.log(response.data.result);
        if (response.data.error === "0") {
          success(t("message.create.success"));
          handleClose();
        } else {
          error(response.data.msg);
        }
      } catch (error: any) {
        error(error?.msg);
      } finally {
        dispatch(setLoading(false));
      }
    }

  };

  const changeField = (e: any) => {
    let arr = value;
    arr[e.target.id] = e.target.value;
    setValue({ ...arr });
  };

  const fetchHouse = async () => {
    try {
      dispatch(setLoading(true));
      const response = await trashfeeAPI.getHouseByRegisterNo({
        organization_id: org_id || "",
        register_no: value.register_no,
      });
      if (response.data.error === "0") {
        console.log(response.data.result);
        success("ค้นหาข้อมูลสำเร็จ");
        setValue({
          ...value,
          area_id: response.data.result.area_id,
          register_no: response.data.result.register_no,
          prefix: response.data.result.owner.prefix,
          name: response.data.result.owner.name,
          surname: response.data.result.owner.surname,
          card_no: response.data.result.owner.card_no,
          house_no: response.data.result.house_no,
          moo: response.data.result.moo,
          soi: response.data.result.soi,
          road: response.data.result.road,
          district_id: response.data.result.district_id,
          trash_fee: response.data.result.trash_fee,
          house_type: response.data.result.house_type,
          amphur_id: response.data.result.amphur_id,
          ...(response.data.result.amphur_id === "" && {
            district_id: "",
            amphur_id: "",
          }),
        });
        setAutoFill(true);
      } else {
        setValue({
          ...value,
          area_id: "",
          prefix: "",
          name: "",
          surname: "",
          card_no: "",
          house_no: "",
          moo: "",
          soi: "",
          road: "",
          district_id: "",
          trash_fee: 0,
          house_type: "",
          amphur_id: "",
        });
        setAutoFill(false);
        error(response.data.msg);
      }

    } catch (error: any) {
      if (error) {
        error(error.msg);
      }
      setValue({
        ...value,
        area_id: "",
        prefix: "",
        name: "",
        surname: "",
        card_no: "",
        house_no: "",
        moo: "",
        soi: "",
        road: "",
        district_id: "",
        trash_fee: 0,
        house_type: "",
      });
      setAutoFill(false);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const changeDate = (date, dateString) => {
    setValue({
      ...value,
      end_date: dateString,
    });
  };

  const renderDate = () => {
    // value={value.end_date === "" ? undefined : moment(value.end_date, dateFormat)}
    if (value.end_date === "") {
      return undefined;
    } else {
      let date = moment(value.end_date, dateFormat);
      date.add(543, 'years');
      // return moment(value.end_date, dateFormat);
      return date;
    }
  };

  return (
    <div className="form-wrap">
      <form onSubmit={handleSubmit}>
        <div className="page-header">
          <h3>
            {pathName === PATH_TRASH_FEE_BILL_MANUAL_CREATE
              ? "สร้างรายการชำระค่าขยะ"
              : "แก้ไขรายการชำระค่าขยะ"
            }
          </h3>
          <p>โปรดระบุรายละเอียดให้ครบถ้วน</p>
        </div>
        {pathName === PATH_TRASH_FEE_BILL_MANUAL_CREATE ? (
          <StyledPageBody>
            <StyledCard>
              <div className="title">
                ข้อมูลที่อยู่
              </div>
              <div>
                <Row gutter={16}>
                  <Col md={18}>
                    <SharedInput
                      label="เลขที่ทะเบียนบ้าน*"
                      // descLabel={t("page._2_100_characters")}
                      name="register_no"
                      value={value.register_no}
                      onBlur={handleBlur}
                      onChange={changeField}
                      parentClassName="mb-6"
                    />
                  </Col>
                  <Col md={6}>
                    <br />
                    <br />
                    <SharedButtonDefault
                      type="default"
                      text={t("page.search")}
                      style={{
                        height: "48px",
                        width: "100%",
                        background: "#0263e0",
                        color: "#ffffff",
                      }}
                      onClick={fetchHouse}
                    />
                  </Col>

                </Row>
                <Row gutter={16}>
                  <Col md={12}>
                    <label className="label">
                      <span className="label-name">ประเภทที่อยู่*</span>
                      <br /> <br />
                    </label>
                    <Select
                      value={value.house_type}
                      onChange={areaChange}
                      disabled={autoFill}
                    >

                      <Option value="house">
                        ครัวเรือน
                      </Option>
                      <Option value="business">
                        กิจการ
                      </Option>

                    </Select>
                  </Col>
                  <Col md={12}>
                    <label className="label">
                      <span className="label-name">หมวดพื้นที่*</span>
                      <br /> <br />
                    </label>
                    <Select
                      value={value.area_id}
                      onChange={areaChange}
                      disabled={autoFill}
                    >
                      {areaList.length > 0 &&
                        areaList.map((item, index) => {
                          return (
                            <Option value={item._id} key={index}>
                              {item.area_name}
                            </Option>
                          );
                        })
                      }
                    </Select>
                  </Col>
                </Row>
                <br />
                <Row gutter={16}>
                  <Col md={12}>
                    <SharedInput
                      label="บ้านเลขที่*"
                      // descLabel={t("page._2_100_characters")}
                      name="house_no"
                      value={value.house_no}
                      onBlur={handleBlur}
                      onChange={changeField}
                      parentClassName="mb-6"
                      disable={autoFill}
                    />
                  </Col>
                  <Col md={12}>
                    <SharedInput
                      label="ซอย"
                      // descLabel={t("page._2_100_characters")}
                      name="soi"
                      value={value.soi}
                      onBlur={handleBlur}
                      onChange={changeField}
                      parentClassName="mb-6"
                      disable={autoFill}
                    />
                  </Col>
                  <Col md={12}>
                    <SharedInput
                      label="หมู่"
                      // descLabel={t("page._2_100_characters")}
                      name="moo"
                      value={value.moo}
                      onBlur={handleBlur}
                      onChange={changeField}
                      parentClassName="mb-6"
                      disable={autoFill}
                    />
                  </Col>
                  <Col md={12}>
                    <SharedInput
                      label="ถนน"
                      // descLabel={t("page._2_100_characters")}
                      name="road"
                      value={value.road}
                      onBlur={handleBlur}
                      onChange={changeField}
                      parentClassName="mb-6"
                      disable={autoFill}
                    />
                  </Col>
                  <Col md={12}>
                    <label className="label">
                      <span className="label-name">อำเภอ*</span>
                      <br /> <br />
                    </label>
                    <Select
                      value={value.amphur_id}
                      onChange={AmphurChange}
                      disabled={autoFill}
                    >
                      {amphurList.length > 0 &&
                        amphurList.map((item, index) => {
                          return (
                            <Option value={item.amphur_id} key={index}>
                              {item.amphur_name_tha}
                            </Option>
                          );
                        })
                      }
                    </Select>
                  </Col>
                  <Col md={12}>
                    <label className="label">
                      <span className="label-name">ตำบล*</span>
                      <br /> <br />
                    </label>
                    <Select
                      value={value.district_id}
                      onChange={districtChange}
                      disabled={autoFill}
                    >
                      {districtList.length > 0 &&
                        districtList.map((item, index) => {
                          return (
                            <Option value={item.district_id} key={index}>
                              {item.district_name_tha}
                            </Option>
                          );
                        })
                      }
                    </Select>
                  </Col>
                </Row>
              </div>
            </StyledCard>
            <StyledCard>
              <div className="title">
                ข้อมูลผู้ชำระค่าขยะ
              </div>
              <div>
                <Row gutter={16}>
                  <Col md={4}>
                    <label className="label">
                      <span className="label-name">คำนำหน้า*</span>
                      <br /> <br />
                    </label>
                    <Select
                      value={value.prefix}
                      onChange={prefixChange}
                      disabled={autoFill}
                    >
                      {prefix.length > 0 &&
                        prefix.map((item, index) => {
                          return (
                            <Option value={item} key={index}>
                              {item}
                            </Option>
                          );
                        })
                      }
                    </Select>
                  </Col>
                  <Col md={10}>
                    <SharedInput
                      label="ชื่อ*"
                      name="name"
                      value={value.name}
                      onBlur={handleBlur}
                      onChange={changeField}
                      parentClassName="mb-6"
                      disable={autoFill}
                    />
                  </Col>
                  <Col md={10}>
                    <SharedInput
                      label="นามสกุล*"
                      name="surname"
                      value={value.surname}
                      onBlur={handleBlur}
                      onChange={changeField}
                      parentClassName="mb-6"
                      disable={autoFill}
                    />
                  </Col>
                  <Col md={24}>
                    <SharedInput
                      label="เลขบัตรประชาชน*"
                      name="card_no"
                      value={value.card_no}
                      onBlur={handleBlur}
                      onChange={changeField}
                      parentClassName="mb-6"
                      disable={autoFill}
                    />
                  </Col>
                </Row>
              </div>
            </StyledCard>
            <StyledCard>
              <div className="title">
                จำนวนค่าขยะเดือนล่าสุด
              </div>
              <Row gutter={16}>
                <Col md={12}>
                  <SharedInput
                    label="ID Invoice*"
                    name="id_invoice"
                    value={value.id_invoice}
                    onBlur={handleBlur}
                    onChange={changeField}
                    parentClassName="mb-6"
                  // disable={autoFill}
                  />
                </Col>
                <Col md={12}>
                  <SharedInput
                    label="จำนวนค่าภาษีขยะ"
                    name="trash_fee"
                    value={value.trash_fee}
                    onBlur={handleBlur}
                    onChange={changeField}
                    parentClassName="mb-6"
                  />
                </Col>
                <Col md={12}>
                  <label className="label">
                    <span className="label-name">ค่าขยะประจำเดือน / ปี*</span>
                    <br /> <br />
                  </label>
                  <Row gutter={16}>
                    <Col md={12}>
                      <Select
                        value={value.month}
                        onChange={monthChange}
                      >
                        {MONTHlIST.length > 0 &&
                          MONTHlIST.map((item, index) => {
                            return (
                              <Option value={index + 1} key={index}>
                                {item}
                              </Option>
                            );
                          })
                        }
                      </Select>
                    </Col>
                    <Col md={12}>
                      <Select
                        value={value.year}
                        onChange={yearChange}
                      >
                        {YEARLIST.length > 0 &&
                          YEARLIST.map((item, index) => {
                            return (
                              <Option value={item} key={index}>
                                {item}
                              </Option>
                            );
                          })
                        }
                      </Select>
                    </Col>
                  </Row>
                </Col>
                <Col md={12}>
                  <label className="label">
                    <span className="label-name">วันที่หมดเขต*</span>
                    <br /> <br />
                  </label>
                  {/* @ts-ignore */}
                  <ConfigProvider locale={locale}>
                    <DatePicker
                      onChange={changeDate}
                      // value={value.end_date === "" ? undefined : moment(value.end_date, dateFormat)}
                      // value={renderDate()}
                      format={dateFormat}
                    />
                  </ConfigProvider>
                </Col>
              </Row>

            </StyledCard>

            <div className="btn-layout">
              <StyledSubmitButton
                type="default"
                text={t("page.save")}
                // htmlType="submit"
                onClick={createManual}
              // onClick={handleSubmit}
              />
              <StyledCancelButton
                type="sub"
                text={t("page.cancel")}
                htmlType="button"
                onClick={handleClose}
              />
            </div>
          </StyledPageBody>
        ) : (
          <StyledPageBody>
            edit page
          </StyledPageBody>
        )}
      </form>
    </div>
  );
});




const StyledPageBody = styled.div`
    display: grid;
    /* grid-template-columns: 760px 2fr; */
    grid-gap: 15px;
    .ant-calendar-picker-input{
      height: 48px;
    }
`;

const StyledDetailBody = styled.div`
    background: #ffffff;
    border-radius: 4px;
    padding: 30px;
    max-width: 700px;
    .title {
        font-weight: 500;
        font-size: 25px;
        line-height: 38px;
    }
    .period {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }

    hr {
        border-top: 1px solid #f3f3f3;
    }

    .detail-headline {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }
    .detail-desc {
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
    }
`;

const StyledDocument = styled.div`
    background-color: #f4f4f4;
    border-radius: 5px;
    margin: 10px 0px;
    display: flex;
    justify-content: space-between;
    .name {
        margin: auto 30px;
        font-weight: 600;
    }
    button {
        background-color: #0d6efd;
        border: none;
        padding: 10px;
        width: 100px;
        border-radius: 5px;
        color: white;
        margin: 15px;
    }
`;

const StyledFileName = styled.p`
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
`;
