import {
    IConplaintParams,
    IConplaintDetailParams,
    IConplaint,
    IConplaintOperationParams,
    IConplaintParam,
    IUpdateComment,
} from "@interfaces";

import axiosClient from "./axiosClient";

export const conplaintAPI = {
    getList: (params: IConplaintParams) => {
        const url = "complain/getComplains";
        return axiosClient.get(url, { params });
    },
    detail: (params: IConplaintDetailParams) => {
        const url = `complain/getComplain`;
        return axiosClient.get(url, { params });
    },
    create: (params: IConplaintParam) => {
        const url = "complain/createComplain";
        return axiosClient.post(url, params);
    },
    update: (params: IConplaint) => {
        const url = "complain/updateComplain";
        return axiosClient.patch(url, params);
    },
    processing: (params: IConplaintOperationParams) => {
        const url = `complain/doingComplain`;
        return axiosClient.post(url, params);
    },
    cancel: (params: IConplaintOperationParams) => {
        const url = `complain/cancelComplain`;
        return axiosClient.post(url, params);
    },
    complete: (params: IConplaintOperationParams) => {
        const url = `complain/completeComplain`;
        return axiosClient.post(url, params);
    },
    adminUpdateCommentComplain: (params: IUpdateComment) => {
        const url = `complain/adminUpdateCommentComplain`;
        return axiosClient.post(url, params);
    },
};
