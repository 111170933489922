import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { StyledNewDetail } from "@components";
import { INews } from "@interfaces";
import { PATH_CREATE_NEWS } from "@configs";
import { ModuleNewsForm } from "@modules";
import { useNotify, usePermission } from "@utils";
import { newsAPI } from "@api";
import { setLoading, selectAuth, selectOrg, getCategories } from "@redux";

export const PageNewsCreate = () => {
    const { id } = useParams<{ id: string }>();
    const org_id = useSelector(selectAuth).auth?.organization_data?._id;
    const pemisssion = useSelector(selectAuth).permission;
    const categories = useSelector(selectOrg).category;
    const dispatch = useDispatch();
    const history = useHistory();
    const { warning } = useNotify();
    // page state
    const [news, setNews] = useState<INews>();
    // variable
    const pathName = history.location.pathname;
    usePermission({ permission: pemisssion?.permission_list.news.is_manage });

    useEffect(() => {
        fetchNewsCategory();
        fetchNews();
    }, []);
    const fetchNewsCategory = async () => {
        await dispatch(getCategories({ organization_id: org_id || "" }));
    };
    const fetchNews = async () => {
        if (pathName !== PATH_CREATE_NEWS) {
            dispatch(setLoading(true));
            try {
                const response = await newsAPI.detail({
                    organization_id: org_id || "",
                    news_id: id,
                });
                const data: INews = response.data.result;
                setNews(data);
                dispatch(setLoading(false));
            } catch (err: any) {
                if (err) {
                    warning(err.msg);
                    dispatch(setLoading(false));
                }
            } finally {
                dispatch(setLoading(false));
            }
        }
    };

    return (
        <StyledNewDetail>{<ModuleNewsForm news={news} categories={categories} />}</StyledNewDetail>
    );
};
