export const HotNewsIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="5" width="24" height="13" rx="3" fill="#E95C60" />
        <path
            d="M3 8.72745C3 8.40937 3.25786 8.15152 3.57594 8.15152C3.89402 8.15152 4.15188 8.40937 4.15188 8.72745V11.0808H7.19936V8.72292C7.19936 8.40734 7.45519 8.15152 7.77077 8.15152C8.08635 8.15152 8.34217 8.40734 8.34217 8.72292V14.6407C8.34217 14.9563 8.08635 15.2121 7.77077 15.2121C7.45519 15.2121 7.19936 14.9563 7.19936 14.6407V12.1414H4.15188V14.6362C4.15188 14.9543 3.89402 15.2121 3.57594 15.2121C3.25786 15.2121 3 14.9543 3 14.6362V8.72745Z"
            fill="white"
        />
        <path
            d="M12.614 15.3636C11.9791 15.3636 11.4046 15.2121 10.8907 14.9091C10.3828 14.5993 9.98066 14.165 9.68438 13.6061C9.39414 13.0471 9.24902 12.4074 9.24902 11.6869C9.24902 10.9663 9.39414 10.3266 9.68438 9.76768C9.98066 9.20875 10.3828 8.77441 10.8907 8.46465C11.4046 8.15488 11.9791 8 12.614 8C13.2428 8 13.8112 8.15488 14.3191 8.46465C14.8331 8.77441 15.2352 9.20875 15.5254 9.76768C15.8217 10.3266 15.9698 10.9663 15.9698 11.6869C15.9698 12.4074 15.8217 13.0471 15.5254 13.6061C15.2352 14.1582 14.8331 14.5892 14.3191 14.899C13.8112 15.2088 13.2428 15.3636 12.614 15.3636ZM12.614 14.202C13.0191 14.202 13.3879 14.101 13.7205 13.899C14.053 13.6902 14.3161 13.3973 14.5096 13.0202C14.7091 12.6364 14.8089 12.1919 14.8089 11.6869C14.8089 11.1886 14.7121 10.7508 14.5186 10.3737C14.3251 9.9899 14.0621 9.6936 13.7296 9.48485C13.397 9.27609 13.0251 9.17172 12.614 9.17172C12.2028 9.17172 11.8279 9.27609 11.4893 9.48485C11.1567 9.6936 10.8937 9.9899 10.7002 10.3737C10.5067 10.7508 10.41 11.1886 10.41 11.6869C10.41 12.1919 10.5067 12.6364 10.7002 13.0202C10.8997 13.3973 11.1658 13.6902 11.4984 13.899C11.837 14.101 12.2088 14.202 12.614 14.202Z"
            fill="white"
        />
        <path
            d="M17.9344 9.27385C17.9344 9.23976 17.9067 9.21212 17.8726 9.21212H16.5509C16.258 9.21212 16.0206 8.9747 16.0206 8.68182C16.0206 8.38894 16.258 8.15152 16.5509 8.15152H20.4697C20.7626 8.15152 21 8.38894 21 8.68182C21 8.9747 20.7626 9.21212 20.4697 9.21212H19.148C19.1139 9.21212 19.0863 9.23976 19.0863 9.27385V14.6362C19.0863 14.9543 18.8284 15.2121 18.5103 15.2121C18.1922 15.2121 17.9344 14.9543 17.9344 14.6362V9.27385Z"
            fill="white"
        />
    </svg>
);
