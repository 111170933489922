import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { StyledNewDetail } from "@components";
import { IAdminUser, IPermission } from "@interfaces";
import { PATH_ADMIN_CREATE, PATH_CREATE_NEWS } from "@configs";
import { ModuleAdminForm, ModuleNewsForm } from "@modules";
import { useNotify, usePermission } from "@utils";
import { adminAPI, newsAPI, permissionAPI } from "@api";
import { setLoading, selectAuth, selectOrg, getCategories } from "@redux";

export const PageCreateAdmin = () => {
    const { id } = useParams<{ id: string }>();
    const org_id = useSelector(selectAuth).auth?.organization_data?._id;
    const isPermission = useSelector(selectAuth).permission;
    const dispatch = useDispatch();
    const history = useHistory();
    const { warning } = useNotify();
    // page state
    const [admin, setAdmin] = useState<IAdminUser>();
    const [permission, setPermission] = useState<IPermission[]>();
    // variable
    const pathName = history.location.pathname;
    usePermission({ permission: isPermission?.permission_list.organization.is_manage_permission });
    useEffect(() => {
        fetchAdmin();
        fetchPermission();
    }, []);
    const fetchPermission = async () => {
        dispatch(setLoading(true));
        try {
            const response = await permissionAPI.getPermissions({
                organization_id: org_id || "",
                xpage: 1,
                xlimit: 10,
            });
            const data: IPermission[] = response.data.result;
            setPermission(data);
            dispatch(setLoading(false));
        } catch (err: any) {
            if (err) {
                warning(err.msg);
                dispatch(setLoading(false));
            }
        } finally {
            dispatch(setLoading(false));
        }
    };
    const fetchAdmin = async () => {
        if (pathName !== PATH_ADMIN_CREATE) {
            dispatch(setLoading(true));
            try {
                const response = await adminAPI.getAdmin({
                    organization_id: org_id || "",
                    admin_id: id,
                });
                const data: IAdminUser = response.data.result;
                setAdmin(data);
                dispatch(setLoading(false));
            } catch (err: any) {
                if (err) {
                    warning(err.msg);
                    dispatch(setLoading(false));
                }
            } finally {
                dispatch(setLoading(false));
            }
        }
    };

    return (
        <StyledNewDetail>
            {<ModuleAdminForm admin={admin} permission={permission} />}
        </StyledNewDetail>
    );
};
