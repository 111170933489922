import { ComponentModal, SharedButtonDefault } from "@components";
import { Divider, Modal } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useNotify } from "@utils";
import QrReader from "modern-react-qr-reader";
import { selectApp, useAppSelector } from "@redux";

export const ModuleModalQRMobile = ({ isVisible, handleOk, handleSearchFromQr }) => {
    const { t } = useTranslation();
    const app = useAppSelector(selectApp);
    const { error } = useNotify();
    const handleScan = (data) => {
        if (data === null) return;
        handleSearchFromQr(data);
    };
    const handleError = (err) => {
        error(err);
    };
    return (
        <ComponentModal
            title={
                <>
                    <h4>{t("page.member_points.scan_QR_code")}</h4>
                    <p>{t("page.member_points.scan_qr_code_des")}</p>
                </>
            }
            isVisible={isVisible}
            onOk={handleOk}
            onCancel={handleOk}
            width="358px"
        >
            {isVisible ? (
                <QrReader
                    delay={300}
                    facingMode={"environment"}
                    onError={handleError}
                    onScan={handleScan}
                    style={{ width: "100%" }}
                />
            ) : (
                <div className="qr-code-placeholder" />
            )}
            <Divider className="qr-divider" />
            <SharedButtonDefault
                style={{
                    width: app.mobile ? "100%" : 110,
                    background: "white",
                    // borderColor: "black",
                    fontSize: 16,
                    padding: 14,
                    fontWeight: 600,
                    height: 49,
                    backgroundColor: "#0263E0",
                    color: "#fff",
                }}
                text={t("page.member_points.ok")}
                type="default"
                size="default"
                className="default-btn"
                onClick={handleOk}
            />
        </ComponentModal>
    );
};
