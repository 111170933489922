import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNotify } from "@utils";
import { homeMenuAPI } from "@api";
import { setLoading, selectAuth } from "@redux";
import { StyledBiddingDetail } from "@components";
import { IBannerData, IHomeOrganization, IInfoData } from "src/Interfaces/homeMenu";
import { ModuleHomeDetailForm, ModuleHomeORGForm } from "src/Modules/homeMenu";

export const HomeORGPage = () => {
    const org_id = useSelector(selectAuth).auth?.organization_data?._id;
    const dispatch = useDispatch();
    const { warning } = useNotify();
    // page state
    const [organization, setOrganization] = useState<IHomeOrganization>();
    const [banner, setBanner] = useState<IBannerData>({
        "image_url": "",
        "text_in": "",
        "detail_in": "",
        "info_type": "ORG_CHART",
    });

    const [info, setInfo] = useState<IInfoData[]>([]);

    useEffect(() => {
        fetchHomeMenu();
    }, []);

    const fetchHomeMenu = async () => {
        dispatch(setLoading(true));
        try {
            const res = await homeMenuAPI.getBannerAbout({
                organization_id: org_id || "",
                info_type: 'ORG_CHART',
            });
            // console.log(res.data.result);
            const data = res.data.result;
            const infoRes = await homeMenuAPI.getInfoAbout({
                organization_id: org_id || "",
                info_type: 'ORG_CHART',
            });
            const infoData: IInfoData[] = infoRes.data.result;
            // console.log('info', infoData);
            if (data?.length === 0) {
                //
            } else {
                setBanner(data);
            }
            setInfo([...infoData]);
            dispatch(setLoading(false));

        } catch (err: any) {
            if (err) {
                warning(err.msg);
                dispatch(setLoading(false));
            }    // const data: IHomeOrganization = res.data.result;
            // setOrganization(data);
            // dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    return (
        <StyledBiddingDetail>
            <ModuleHomeDetailForm
                bannerData={banner}
                setBanner={setBanner}
                infoList={info}
                infoType="ORG_CHART"
                fetchInfo={fetchHomeMenu}
                setInfoList={setInfo}
            />
            {/* <ModuleHomeORGForm
                organization={organization}
                bannerData={banner}
                infoData={info}
                setBanner={setBanner}
                setInfo={setInfo}
                fetchInfo={fetchHomeMenu}
            /> */}
        </StyledBiddingDetail>
    );
};
