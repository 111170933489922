import { useState, useRef, useEffect } from "react";
import {
    ComponentContactSortIcon,
    StyledTable,
    ComponentEmptyData,
    StyledTableButtonTop,
    ComponentStatusFilter,
    ComponentRowReward,
} from "@components";
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    TouchSensor,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    rectSortingStrategy,
    //@ts-ignore
} from "@dnd-kit/sortable";
import { Row, Col, Pagination } from "antd";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import {
    enumSortBy,
    enumContactSortFields,
    PAGINATION,
    PAGE_START,
    enumNewsStatus,
    INITIAL_LIST_PARAMS,
    enumRewardType,
    PATH_PRIVILEGES_CREATE,
} from "@configs";
import { IStatusCount, IPaginationNext, IReward } from "@interfaces";
import { useDispatch, useSelector } from "react-redux";
import { getCategories, selectAuth, selectContact, selectOrg, setLoading } from "@redux";
import { useNotify, usePermission } from "@utils";
import { useHistory } from "react-router";
import { rewardAPI } from "@api";
import { boolean, date } from "yup";

const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: PAGINATION,
};

export const PageRewardList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const search_params = useSelector(selectContact).paramsContact;
    const [params, setParams] = useState(search_params);
    const permission = useSelector(selectAuth).permission;
    const organization_id = useSelector(selectAuth).auth?.organization_data?._id;
    const categories = useSelector(selectOrg).category;
    const [textSearch, setTextSearch] = useState<string>("");
    const [status, setStatus] = useState<string | boolean>("");
    const [filterStatus, setFilterStatus] = useState<boolean>(true);
    const [pagination, setPagination] = useState<IPaginationNext>(INITAL_PAGE);
    const [rewards, setRewards] = useState<IReward[]>([]);
    const [rewardCount, setRewardCount] = useState<IStatusCount>();
    const [total, setTotal] = useState<number>(0);
    const { success, error } = useNotify();
    const history = useHistory();
    usePermission({ permission: permission?.permission_list.claim_reward.is_view });
    useEffect(() => {
        fetchClaim(pagination, textSearch, status);
    }, [pagination, textSearch, status]);

    useEffect(() => {
        fetchRewardCountStatus();
    }, []);
    const fetchClaim = async (
        pagination: IPaginationNext,
        textSearch: string,
        status: string | boolean
    ) => {
        dispatch(setLoading(true));
        try {
            const response = await rewardAPI.getRewards({
                organization_id: organization_id || "",
                ...pagination,
                ...{ type: enumRewardType.BENEFIT },
                ...(typeof status === "boolean" && { is_enable: status }),
            });
            setRewards(response.data.result);
            setTotal(response.data.total);
            // success(response.data.msg);
        } catch (err: any) {
            error(err.msg);
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const fetchRewardCountStatus = async () => {
        try {
            const res = await rewardAPI.getRewardEnableCount({
                organization_id: organization_id || "",
            });
            if (res.data.error === "0") setRewardCount(res.data.result);
        } catch (error) {}
    };

    const handleSort = (sortField: enumContactSortFields, sortBy: enumSortBy) => {
        const newSortParams = { ...params, sortField, sortBy };
        setParams(newSortParams);
        // fetchContacts(newSortParams);
    };
    const updateReward = async (isEnable: boolean, data: IReward) => {
        try {
            dispatch(setLoading(true));
            const payload = {
                name: data.name,
                description: data.description,
                image_url_list: data.image_url_list,
                date_from: data.date_from,
                date_to: data.date_to,
                client_limit: data.client_limit,
                client_limit_type: data.client_limit_type,
                reward_limit: data.reward_limit,
                reward_limit_type: data.reward_limit_type,
                condition: data.condition,
                is_self_receive: data.is_self_receive,
                is_delivery: data.is_delivery,
                organization_id: organization_id || "",
                reward_id: data._id,
                is_enable: isEnable,
            };
            const res = await rewardAPI.update(payload);
            if (res.data.error === "0") {
                success(res.data.msg);
                await callbackGetList();
            } else {
                error(res.data.msg);
            }
        } catch (error) {
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };
    const callbackGetList = async () => {
        await fetchClaim(pagination, textSearch, status);
        await fetchRewardCountStatus();
    };
    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            const swapItem = (items: IReward[]) => {
                const oldIndex = items.findIndex((item) => JSON.stringify(item) === active.id);
                const newIndex = items.findIndex((item) => JSON.stringify(item) === over.id);
                return arrayMove(items, oldIndex, newIndex);
            };
            setRewards(swapItem);
        }
    };
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
        useSensor(TouchSensor)
    );
    const handlePagination = (page) => {
        setPagination({ xpage: page, xlimit: pagination.xlimit });
    };
    const handleChangeStatus = (params: enumNewsStatus) => {
        switch (params) {
            case enumNewsStatus.ALL:
                setStatus("");
                break;
            case enumNewsStatus.ACTIVE:
                setStatus(true);
                break;
            case enumNewsStatus.INACTIVE:
                setStatus(false);
                break;
            default:
                break;
        }
    };
    const getStatus = () => {
        if (status === "") return enumNewsStatus.ALL;
        if (status === true) return enumNewsStatus.ACTIVE;
        if (status === false) return enumNewsStatus.INACTIVE;
    };
    return (
        <StyledTable>
            <div className="page-layout">
                <div className="page-header-layout">
                    <div className="page-header">
                        <div>
                            <h3>{t("page.giveaway_list")}</h3>
                        </div>
                        <div className="flex-header">
                            <StyledTableButtonTop
                                type="danger"
                                size="default"
                                text={t("page.giveaway_benefit")}
                                onClick={() => history.push(PATH_PRIVILEGES_CREATE)}
                                className="add-btn"
                                icon="plus"
                            />
                        </div>
                    </div>
                    <div className="search-layout">
                        <ComponentStatusFilter
                            setStatus={handleChangeStatus}
                            setFilterStatus={setFilterStatus}
                            activeCount={rewardCount?.enable_true || 0}
                            inactiveCount={rewardCount?.enable_false || 0}
                            status={getStatus()}
                        />
                    </div>
                </div>
                <div className="page-table-layout">
                    <div className="table">
                        <ScrollContainer vertical={false}>
                            <div className="table_ground">
                                <div className="table-body">
                                    <div className="table-header">
                                        <Row
                                            gutter={24}
                                            type="flex"
                                            justify="space-between"
                                            align="middle"
                                            className="header-row"
                                        >
                                            <Col className="col-item" span={5}>
                                                <p>{t("page.giveaway_subject")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.FULL_NAME}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.FULL_NAME}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={3}>
                                                <p>{t("page.signup_person")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.TEL}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.TEL}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={3}>
                                                <p>{t("page.availability_period")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={3}>
                                                <p>{t("page.date_of_creation")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={3}>
                                                <p>{t("page.active")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.CREATED_AT}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.CREATED_AT}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={3}>
                                                <p>{t("page.news_manage")}</p>
                                            </Col>
                                        </Row>
                                    </div>
                                    <DndContext
                                        sensors={sensors}
                                        collisionDetection={closestCenter}
                                        onDragEnd={handleDragEnd}
                                    >
                                        <SortableContext
                                            items={rewards.map((item) => JSON.stringify(item))}
                                            strategy={rectSortingStrategy}
                                        >
                                            {rewards && rewards.length > 0 ? (
                                                <div className="data-table">
                                                    {rewards.map((item, index) => (
                                                        <ComponentRowReward
                                                            key={index}
                                                            data={item}
                                                            itemJson={JSON.stringify(item)}
                                                            categories={categories}
                                                            handleEdit={updateReward}
                                                            callbackGetList={callbackGetList}
                                                            index={index}
                                                        />
                                                    ))}
                                                    <div
                                                        style={{ width: "100%", height: 10 }}
                                                    ></div>
                                                </div>
                                            ) : (
                                                <ComponentEmptyData />
                                            )}
                                        </SortableContext>
                                    </DndContext>
                                </div>
                            </div>
                        </ScrollContainer>
                    </div>
                </div>
                <div className="page-bottom">
                    {total > 0 && (
                        <div className="pagination">
                            <Pagination
                                defaultCurrent={INITIAL_LIST_PARAMS.page || 1}
                                pageSize={PAGINATION}
                                total={total}
                                onChange={handlePagination}
                                current={pagination.xpage}
                            />
                        </div>
                    )}
                </div>
            </div>
        </StyledTable>
    );
};
