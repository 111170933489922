import { useEffect, memo, useState, useRef } from "react";
import styled from "styled-components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Row, Col, Select, Input } from "antd";
import moment from "moment";
import {
    INewsCategoryParams,
    IActivity,
    IActivityCreate,
    IActivityCategory,
    IActivityCreateCategory,
} from "@interfaces";
import {
    SharedCKEditor,
    StyledSubmitButton,
    StyledCancelButton,
    StyledCard,
    SharedInput,
    ComponentPeriod,
    ComponentActivityPreview,
    ComponentRadio,
    ComponentModal,
    ShareButton,
} from "@components";
import { useYup } from "@validations";
import { getCategories, setLoading, selectAuth, getActivityCategory } from "@redux";
import { MutlipleModuleUploadImage } from "@modules";
import { enumCategory, PATH_ACTIVITY } from "@configs";
import { activityApi, orgApi } from "@api";
import { useNotify } from "@utils";

interface IProps {
    activity: IActivity | undefined;
    categories: IActivityCategory[] | undefined;
}
const newDate = new Date();
const initData: IActivityCreate = {
    organization_id: "",
    title: "",
    detail: "",
    condition: "",
    image_url_list: [],
    enroll_qty: 0,
    is_full: false,
    activity_category_id: "",
    date_from: new Date(),
    date_to: new Date(newDate.setFullYear(newDate.getFullYear() + 1)),
    register_date_from: new Date(),
    register_date_to: new Date(newDate.setFullYear(newDate.getFullYear() + 1)),
    activity_address: "",
    is_enable: true,
};
const { Option } = Select;
const { TextArea } = Input;
export const ModuleActivityForm = memo((props: IProps) => {
    //hook
    const { YupActivity } = useYup();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { error, success } = useNotify();
    const organization_id = useSelector(selectAuth).auth?.admin_data?.organization_id;
    const searchInputRef = useRef<any | null>(null);
    const [cat, setCat] = useState<string>("");
    const [limit, setLimit] = useState<string>("limit");
    const [showModal, setShowModal] = useState<boolean>(false);
    const createNews = Yup.object().shape(YupActivity);
    const { activity, categories } = props;
    useEffect(() => {
        if (activity) {
            setFieldValue("title", activity.title);
            setFieldValue("detail", activity.detail);
            setFieldValue("date_from", activity.date_from);
            setFieldValue("date_to", activity.date_to);
            setFieldValue("condition", activity.condition);
            setFieldValue("register_date_from", activity.register_date_from);
            setFieldValue("register_date_to", activity.register_date_to);
            setFieldValue("image_url_list", activity.image_url_list);
            setFieldValue("activity_address", activity.activity_address);
            setFieldValue("activity_category_id", activity.activity_category_id);
            if (activity.enroll_qty === -1) {
                setLimit("no_limit");
            } else {
                setLimit("limit");
            }
            // setFieldValue("news_category_id", activity.news_category_id);
            // setFieldValue("is_hot_news", activity.is_hot_news);
        }
    }, [activity, categories]);

    const handleEnter = (e: string) => {
        if (e === "Enter") {
            createCategory();
            searchInputRef.current.focus();
        }
    };
    const limit_options = [
        {
            label: `${t("page.limit")}`,
            value: "limit",
        },
        {
            label: `${t("page.no_limit")}`,
            value: "no_limit",
        },
    ];
    const createCategory = async () => {
        dispatch(setLoading(true));
        // if (news?.organization_id === "") return;
        try {
            const payload: IActivityCreateCategory = {
                category_name: cat,
                organization_id: organization_id || "",
                is_enable: true,
            };
            const response = await orgApi.createActivityCategory(payload);
            success(response.data.msg);
            dispatch(getActivityCategory({ organization_id: organization_id || "" }));
        } catch (err: any) {
            if (err) {
                error(err.msg);
            } else error(t("message.create.fail"));
        } finally {
            dispatch(setLoading(false));
        }
    };
    const handleActivitySubmit = async (values: IActivityCreate) => {
        try {
            dispatch(setLoading(true));
            await activityApi.createActivity({
                ...values,
                ...{ organization_id: organization_id || "" },
            });
            success(t("message.create.success"));
            history.push(PATH_ACTIVITY);
        } catch (err: any) {
            if (err) {
                error(err.msg);
            } else error(t("message.error"));
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleCancel = () => {
        history.push(PATH_ACTIVITY);
    };

    const { values, handleChange, handleBlur, handleSubmit, errors, touched, setFieldValue } =
        useFormik({
            initialValues: initData,
            validationSchema: createNews,
            enableReinitialize: true,
            onSubmit: handleActivitySubmit,
        });

    const handleChangeStatus = (e: boolean) => {
        setFieldValue("is_hot_news", e);
    };

    const handleChangeDateFrom = (date: Date | string) => {
        setFieldValue("date_from", moment(date).toISOString());
    };

    const handleChangeDateTo = (date: Date | string) => {
        setFieldValue("date_to", moment(date).toISOString());
    };
    const handleChangeDateFromRegister = (date: Date | string) => {
        setFieldValue("register_date_from", moment(date).toISOString());
    };

    const handleChangeDateToRegister = (date: Date | string) => {
        setFieldValue("register_date_to", moment(date).toISOString());
    };

    const handleUploadImage = (images: string[]) => {
        setFieldValue("image_url_list", [...images]);
    };

    const handleChangeGuideLine = (event: any, editors: any) => {
        const data = editors.getData();
        setFieldValue("detail", data);
    };
    const handleChangeGuideLineCondition = (event: any, editors: any) => {
        const data = editors.getData();
        setFieldValue("condition", data);
    };
    const handleSelectType = (value: string) => {
        if (value === enumCategory.ADD_NEWS) {
            setShowModal(true);
        } else {
            setFieldValue("activity_category_id", value);
        }
    };
    const handleSearchInput = (value: string) => {
        setCat(value);
    };

    const handleRemoveCategory = async (value: string) => {
        const removeItem = categories?.find((item) => item.category_name === value);
        if (removeItem) {
            const payload = {
                organization_id: organization_id || "",
                news_category_id: removeItem._id,
            };
            await orgApi.deleteCat(payload);
            dispatch(getActivityCategory({ organization_id: organization_id || "" }));
        }
    };
    const handleChangeRadio = (value) => {
        setLimit(value);
        if (value !== "limit") {
            setFieldValue("enroll_qty", -1);
        }
    };
    return (
        <div className="form-wrap">
            <form onSubmit={handleSubmit}>
                <div className="page-header">
                    <h3>{t("page.activity_details")}</h3>
                </div>
                <div className="page-body">
                    <StyledCard>
                        <div className="title">{t("page.activity_details")}</div>
                        <div className="card-wrap">
                            <MutlipleModuleUploadImage
                                title={t("page.activity_pictures")}
                                images={values.image_url_list}
                                handleGetImage={handleUploadImage}
                            />
                        </div>
                        <div>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <SharedInput
                                        label={t("page.activity_topic")}
                                        // descLabel={t("page._2_100_characters")}
                                        // placeholder={t("page.activity_title_placeholder")}
                                        name="title"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.title}
                                        errors={errors.title}
                                        touched={touched.title}
                                        parentClassName="mb-6"
                                    />
                                </Col>
                            </Row>
                            <Row className="select-layout">
                                <Col md={12}>
                                    <div className="label">{t("page.activity_type")}</div>
                                    <div className="input-field">
                                        <Select
                                            style={{ width: "100%", height: 48 }}
                                            placeholder={t("page.activity_type")}
                                            onChange={handleSelectType}
                                            defaultActiveFirstOption={true}
                                            value={values.activity_category_id}
                                        >
                                            {categories &&
                                                categories.map((item, index) => {
                                                    return (
                                                        <Option key={index} value={item._id}>
                                                            {item.category_name}
                                                        </Option>
                                                    );
                                                })}
                                            <Option key={"add-new"} value={enumCategory.ADD_NEWS}>
                                                {"+"} {t("page.specify_news_type")}
                                            </Option>
                                        </Select>
                                        <div className="error">
                                            {/* {errors.businessTypeName} */}
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col span={12}>
                                    <ComponentRadio
                                        title={t("page.activity_details")}
                                        options={limit_options}
                                        value={limit}
                                        onChange={handleChangeRadio}
                                    />
                                </Col>
                            </Row>

                            {limit === "limit" && (
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <SharedInput
                                            label={t("page.capacity")}
                                            // descLabel={t("page._2_100_characters")}
                                            // placeholder={t("page.activity_title_placeholder")}
                                            name="enroll_qty"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.enroll_qty}
                                            errors={errors.enroll_qty}
                                            touched={touched.enroll_qty}
                                            parentClassName="mb-6"
                                            className="eng"
                                        />
                                    </Col>
                                </Row>
                            )}
                            <Row gutter={16}>
                                <Col span={24}>
                                    <div className="label">{t("page.address")}</div>
                                    <TextArea
                                        name="activity_address"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.activity_address}
                                        rows={4}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <ComponentPeriod
                            label={t("page.activity_duration")}
                            dateFrom={values.date_from}
                            dateTo={values.date_to}
                            limitDate={{ minDate: new Date() }}
                            handleDateFrom={handleChangeDateFrom}
                            handleDateTo={handleChangeDateTo}
                        />
                        <ComponentPeriod
                            label={t("page.event_registration_period")}
                            dateFrom={values.register_date_from}
                            dateTo={values.register_date_to}
                            limitDate={{ minDate: new Date() }}
                            handleDateFrom={handleChangeDateFromRegister}
                            handleDateTo={handleChangeDateToRegister}
                        />
                        <div className="card-wrap">
                            <div className="title">
                                {t("page.activity_details")}
                                {/* <span>{t("page._2_2000_characters")}</span> */}
                            </div>
                            <SharedCKEditor
                                editor={values.detail || ""}
                                handleChangeEditor={handleChangeGuideLine}
                                name="description"
                            />
                        </div>
                        <div className="card-wrap">
                            <div className="title">
                                {t("page.condition")}
                                {/* <span>{t("page._2_2000_characters")}</span> */}
                            </div>
                            <SharedCKEditor
                                editor={values.condition || ""}
                                handleChangeEditor={handleChangeGuideLineCondition}
                                name="condition"
                            />
                        </div>
                        {/* <StyledSwitch className="switch-field">
                            <p>{t("page.activate")}</p>
                            <Switch checked={values.is_enable} onChange={handleChangeStatus} />
                        </StyledSwitch> */}
                    </StyledCard>
                    <div className="btn-layout">
                        <StyledSubmitButton
                            type="default"
                            text={t("page.save")}
                            htmlType="submit"
                            // disable={isSubmitting}
                        />
                        <StyledCancelButton
                            type="sub"
                            text={t("page.cancel")}
                            htmlType="button"
                            onClick={handleCancel}
                        />
                    </div>
                </div>
            </form>
            <ComponentActivityPreview activity={values} />
            <ComponentModal
                isVisible={showModal}
                title={t("page.add_news_type")}
                width={680}
                style={{ marginTop: "14vh" }}
                className={"add-category"}
                onCancel={() => setShowModal(false)}
            >
                <StyledBody>
                    <div className="form-input">
                        <p>{t("page.news_type")}</p>
                        <Select
                            maxTagCount={5}
                            mode="multiple"
                            showSearch
                            className="input-with-search"
                            placeholder={t("page.choose_variants")}
                            optionFilterProp="children"
                            onSearch={handleSearchInput}
                            onInputKeyDown={(e) => handleEnter(e.key)}
                            notFoundContent={false}
                            onDeselect={handleRemoveCategory}
                            filterOption={(input, option) =>
                                //@ts-ignore
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                0
                            }
                        >
                            {categories?.map((item) => (
                                <Option key={item._id} value={item.category_name}>
                                    {item.category_name}
                                </Option>
                            ))}
                        </Select>
                        <Input ref={searchInputRef} style={{ opacity: "0" }} readOnly={true} />
                    </div>
                </StyledBody>
                <div className="footer">
                    <ShareButton
                        type="primary"
                        size="large"
                        className="add-cat-btn"
                        text={t("page.agree")}
                        onClick={() => setShowModal(false)}
                    />
                </div>
            </ComponentModal>
        </div>
    );
});
const StyledBody = styled.div`
    .form-input {
        margin-top: 20px;
        p {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #29304d;
        }
    }
`;
