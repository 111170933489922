import { useState, useEffect } from "react";
import {
    ComponentContactSortIcon,
    StyledTable,
    ComponentEmptyData,
    StyledTableButtonTop,
    ComponentRowConpliant,
    ComponentRowConpliantMobile,
} from "@components";
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    TouchSensor,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    rectSortingStrategy,
    //@ts-ignore
} from "@dnd-kit/sortable";
import { Row, Col, Pagination } from "antd";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import {
    enumSortBy,
    enumContactSortFields,
    PAGINATION,
    PAGE_START,
    INITIAL_LIST_PARAMS,
    enumProcessStatus,
    PATH_COMPLAINT_CREATE,
    ALL,
} from "@configs";
import { IPaginationNext, INews, IConplaint } from "@interfaces";
import { useDispatch, useSelector } from "react-redux";
import {
    getComplaintType,
    getConplaintCategories,
    getDivisions,
    selectApp,
    selectAuth,
    selectContact,
    selectOrg,
    setLoading,
    useAppSelector,
} from "@redux";
import { useNotify, usePermission } from "@utils";
import { useHistory } from "react-router";
import { conplaintAPI } from "@api";
import { ConpliantTableSearch } from "@modules";

const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: PAGINATION,
};

export const PageConplaintList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const search_params = useSelector(selectContact).paramsContact;
    const [params, setParams] = useState(search_params);
    const org_id = useSelector(selectAuth).auth?.organization_data?._id;
    const adminId = useSelector(selectAuth).auth?.admin_data?._id;
    const divisions = useSelector(selectOrg).division;
    const complainTypes = useSelector(selectOrg).complainTypes;
    const permisssion = useSelector(selectAuth).permission;
    const app = useAppSelector(selectApp);
    const [textSearch, setTextSearch] = useState<string>("");
    const [searchDate, setSearchDate] = useState<string>("");
    const [filterId, setFilterId] = useState<string | enumProcessStatus>(enumProcessStatus.ALL);
    const [divSearch, setDivSearch] = useState<string>("");
    const [pagination, setPagination] = useState<IPaginationNext>(INITAL_PAGE);
    const [conplaints, setConplaints] = useState<IConplaint[]>([]);
    const [total, setTotal] = useState<number>(0);
    const { error } = useNotify();
    const history = useHistory();

    usePermission({ permission: permisssion?.permission_list.complain.is_view });

    useEffect(() => {
        fetchConplaint(pagination, textSearch, searchDate, divSearch, filterId);
    }, [pagination, textSearch, searchDate, divSearch, filterId]);

    useEffect(() => {
        fetchDivision();
        fetchConplaintCategory();
        fetchComplaintType();
    }, []);
    const fetchConplaintCategory = async () => {
        await dispatch(
            getConplaintCategories({ organization_id: org_id || "", admin_id: adminId || "" })
        );
    };
    const fetchDivision = async () => {
        await dispatch(getDivisions({ organization_id: org_id || "", admin_id: adminId || "" }));
    };
    const fetchComplaintType = async () => {
        await dispatch(getComplaintType({ organization_id: org_id || "" }));
    };
    const fetchConplaint = async (
        pagination: IPaginationNext,
        textSearch: string,
        searchDate: string,
        divSearch: string,
        filterId: string | enumProcessStatus
    ) => {
        dispatch(setLoading(true));
        try {
            const response = await conplaintAPI.getList({
                organization_id: org_id || "",
                ...pagination,
                ...(textSearch && { text_search: textSearch }),
                ...(searchDate !== "" && { created_date: searchDate }),
                ...(divSearch && { division_id: divSearch }),
                ...(filterId !== enumProcessStatus.ALL && {
                    status: filterId,
                }),
            });
            setConplaints(response.data.result);
            setTotal(response.data.total);
            // success(response.data.msg);
        } catch (err: any) {
            error(err.msg);
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleSort = (sortField: enumContactSortFields, sortBy: enumSortBy) => {
        // const newSortParams = { ...params, sortField, sortBy };
        // setParams(newSortParams);
        // fetchContacts(newSortParams);
    };

    const callbackGetList = () => {
        fetchConplaint(pagination, textSearch, searchDate, divSearch, status);
    };
    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            const swapItem = (items: INews[]) => {
                const oldIndex = items.findIndex((item) => JSON.stringify(item) === active.id);
                const newIndex = items.findIndex((item) => JSON.stringify(item) === over.id);
                return arrayMove(items, oldIndex, newIndex);
            };
        }
    };
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
        useSensor(TouchSensor)
    );
    const handleSearchCategory = (value) => {
        if (value === ALL) {
            setDivSearch("");
        } else {
            setDivSearch(value);
        }
    };
    const onChangeDate = (dates, dateStrings: string) => {
        setSearchDate(dateStrings);
    };
    const handlePagination = (page) => {
        setPagination({ xpage: page, xlimit: pagination.xlimit });
    };
    return (
        <StyledTable>
            <div className="page-layout">
                <div className="page-header-layout">
                    <div className="page-header">
                        <div>
                            <h3>{t("page.complaint_list")}</h3>
                        </div>
                        <div className="flex-header">
                            <StyledTableButtonTop
                                type="danger"
                                size="default"
                                text={t("page.add_a_complaint")}
                                onClick={() => history.push(PATH_COMPLAINT_CREATE)}
                                className="add-btn"
                                icon="plus"
                            />
                        </div>
                    </div>
                    <div className="search-layout">
                        <div className="status-filter">
                            {complainTypes.map((item, index) => (
                                <div
                                    onClick={() => setFilterId(item.status)}
                                    className={
                                        filterId === item.status
                                            ? "active filter-elem"
                                            : "filter-elem"
                                    }
                                    key={index}
                                >
                                    <p className="name">{item.status_th}</p>
                                    <span className="count eng">{item.count}</span>
                                </div>
                            ))}
                        </div>
                        <ConpliantTableSearch
                            setTextSearch={setTextSearch}
                            handleSearchCategory={handleSearchCategory}
                            divisions={divisions}
                            onChangeDate={onChangeDate}
                            total={total}
                        />
                    </div>
                </div>
                <div className="page-table-layout">
                    <div className="table">
                        <ScrollContainer vertical={false}>
                            <div className="table_ground">
                                <div className="table-body">
                                    <div className="table-header">
                                        <Row
                                            gutter={10}
                                            type="flex"
                                            justify="space-between"
                                            align="middle"
                                            className="header-row"
                                        >
                                            <Col className="col-item" span={6}>
                                                <p>{t("page.report_topic")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.TEL}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.TEL}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={4}>
                                                <p>{t("page.report_date")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={4}>
                                                <p>{t("page.status")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={4}>
                                                <p>{t("page.recipient")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.CREATED_AT}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.CREATED_AT}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={4}>
                                                <p>{t("page.manage")}</p>
                                            </Col>
                                        </Row>
                                    </div>
                                    {!app.mobile && (
                                        <DndContext
                                            sensors={sensors}
                                            collisionDetection={closestCenter}
                                            onDragEnd={handleDragEnd}
                                        >
                                            <SortableContext
                                                items={conplaints.map((item) =>
                                                    JSON.stringify(item)
                                                )}
                                                strategy={rectSortingStrategy}
                                            >
                                                {conplaints && conplaints.length > 0 ? (
                                                    <div className="data-table">
                                                        {conplaints.map((item, index) => (
                                                            <ComponentRowConpliant
                                                                key={index}
                                                                data={item}
                                                                itemJson={JSON.stringify(item)}
                                                                callbackGetList={callbackGetList}
                                                                index={index}
                                                            />
                                                        ))}
                                                        <div
                                                            style={{ width: "100%", height: 10 }}
                                                        ></div>
                                                    </div>
                                                ) : (
                                                    <ComponentEmptyData />
                                                )}
                                            </SortableContext>
                                        </DndContext>
                                    )}
                                    {app.mobile && (
                                        <div>
                                            {conplaints && conplaints.length > 0 ? (
                                                <div className="data-table">
                                                    {conplaints.map((item, index) => (
                                                        <ComponentRowConpliantMobile
                                                            key={index}
                                                            data={item}
                                                            itemJson={JSON.stringify(item)}
                                                            callbackGetList={callbackGetList}
                                                            index={index}
                                                        />
                                                    ))}
                                                </div>
                                            ) : (
                                                <ComponentEmptyData />
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </ScrollContainer>
                    </div>
                </div>
                <div className="page-bottom">
                    {total > 0 && (
                        <div className="pagination">
                            <Pagination
                                defaultCurrent={INITIAL_LIST_PARAMS.page || 1}
                                pageSize={PAGINATION}
                                total={total}
                                onChange={handlePagination}
                                current={pagination.xpage}
                            />
                        </div>
                    )}
                </div>
            </div>
        </StyledTable>
    );
};
