import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
//...1
import { StyledNewDetail } from "@components";
import { IActivity } from "@interfaces";

import { ModuleActivityInfoForm } from "@modules";
import { useNotify } from "@utils";
import { activityApi } from "@api";
import { setLoading, selectAuth, selectOrg, getActivityCategory } from "@redux";

export const PageActivityInfo = () => {
    const { id } = useParams<{ id: string }>();
    const organization_id = useSelector(selectAuth).auth?.organization_data?._id;
    const activityCategory = useSelector(selectOrg).activityCategory;
    const dispatch = useDispatch();
    const { warning } = useNotify();
    const { t } = useTranslation();
    // page state
    const [activity, setActivity] = useState<IActivity>();

    useEffect(() => {
        fetchActivityCategory();
        fetchActivity();
    }, []);
    const fetchActivityCategory = async () => {
        await dispatch(getActivityCategory({ organization_id: organization_id || "" }));
    };
    const fetchActivity = async () => {
        dispatch(setLoading(true));
        try {
            const response = await activityApi.getActivity({
                organization_id: organization_id || "",
                activity_id: id,
            });
            const data: IActivity = response.data.result;
            setActivity(data);
            dispatch(setLoading(false));
        } catch (err: any) {
            if (err) {
                warning(err.msg);
                dispatch(setLoading(false));
            }
        } finally {
            dispatch(setLoading(false));
        }
    };
    const callbackFun = () => {
        fetchActivity();
    };
    return (
        <StyledNewDetail>
            {
                <ModuleActivityInfoForm
                    activity={activity}
                    categories={activityCategory}
                    callbackFun={callbackFun}
                />
            }
        </StyledNewDetail>
    );
};
