import { ISvg } from "@interfaces";
export const CalendarIcon = (props: ISvg) => (
    <svg
        width={props.size || "24"}
        height={props.size || "24"}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="12" cy="12" r="12" fill="#F7F9FC" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.1552 10.7983H6.54892C6.27934 10.7983 6.06055 10.5795 6.06055 10.3099C6.06055 10.0403 6.27934 9.82153 6.54892 9.82153H18.1552C18.4248 9.82153 18.6436 10.0403 18.6436 10.3099C18.6436 10.5795 18.4248 10.7983 18.1552 10.7983Z"
            fill="#4F62C0"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.3695 13.4652C15.01 13.4652 14.7148 13.1734 14.7148 12.814C14.7148 12.4546 15.0022 12.1628 15.3617 12.1628H15.3695C15.7289 12.1628 16.0206 12.4546 16.0206 12.814C16.0206 13.1734 15.7289 13.4652 15.3695 13.4652Z"
            fill="#4F62C0"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.4808 13.4652C12.1214 13.4652 11.8262 13.1734 11.8262 12.814C11.8262 12.4546 12.1136 12.1628 12.473 12.1628H12.4808C12.8402 12.1628 13.132 12.4546 13.132 12.814C13.132 13.1734 12.8402 13.4652 12.4808 13.4652Z"
            fill="#4F62C0"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.58519 13.4652C9.22575 13.4652 8.92969 13.1734 8.92969 12.814C8.92969 12.4546 9.21794 12.1628 9.57738 12.1628H9.58519C9.94463 12.1628 10.2364 12.4546 10.2364 12.814C10.2364 13.1734 9.94463 13.4652 9.58519 13.4652Z"
            fill="#4F62C0"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.3695 15.9959C15.01 15.9959 14.7148 15.7042 14.7148 15.3448C14.7148 14.9853 15.0022 14.6936 15.3617 14.6936H15.3695C15.7289 14.6936 16.0206 14.9853 16.0206 15.3448C16.0206 15.7042 15.7289 15.9959 15.3695 15.9959Z"
            fill="#4F62C0"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.4808 15.9959C12.1214 15.9959 11.8262 15.7042 11.8262 15.3448C11.8262 14.9853 12.1136 14.6936 12.473 14.6936H12.4808C12.8402 14.6936 13.132 14.9853 13.132 15.3448C13.132 15.7042 12.8402 15.9959 12.4808 15.9959Z"
            fill="#4F62C0"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.58519 15.9959C9.22575 15.9959 8.92969 15.7042 8.92969 15.3448C8.92969 14.9853 9.21794 14.6936 9.57738 14.6936H9.58519C9.94463 14.6936 10.2364 14.9853 10.2364 15.3448C10.2364 15.7042 9.94463 15.9959 9.58519 15.9959Z"
            fill="#4F62C0"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.9806 8.11972C14.711 8.11972 14.4922 7.90093 14.4922 7.63135V5.48837C14.4922 5.21879 14.711 5 14.9806 5C15.2501 5 15.4689 5.21879 15.4689 5.48837V7.63135C15.4689 7.90093 15.2501 8.11972 14.9806 8.11972Z"
            fill="#C5C5C5"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.72079 8.11972C9.45121 8.11972 9.23242 7.90093 9.23242 7.63135V5.48837C9.23242 5.21879 9.45121 5 9.72079 5C9.99038 5 10.2092 5.21879 10.2092 5.48837V7.63135C10.2092 7.90093 9.99038 8.11972 9.72079 8.11972Z"
            fill="#C5C5C5"
        />
        <mask id="mask0_14_8840" maskUnits="userSpaceOnUse" x="6" y="6" width="13" height="13">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 6.02808H18.6977V18.9998H6V6.02808Z"
                fill="white"
            />
        </mask>
        <g mask="url(#mask0_14_8840)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.59512 7.00507C7.90665 7.00507 6.97679 7.90562 6.97679 9.54069V15.4331C6.97679 17.1039 7.90665 18.0234 9.59512 18.0234H15.1027C16.7911 18.0234 17.721 17.1209 17.721 15.4826V9.54069C17.7236 8.73651 17.5074 8.11139 17.0783 7.68162C16.6368 7.23883 15.9563 7.00507 15.1085 7.00507H9.59512ZM15.1026 18.9999H9.59507C7.37786 18.9999 6 17.6331 6 15.4328V9.54045C6 7.37338 7.37786 6.02808 9.59507 6.02808H15.1085C16.2213 6.02808 17.1414 6.36147 17.7698 6.99115C18.3799 7.60389 18.7009 8.48491 18.6977 9.54175V15.4823C18.6977 17.652 17.3198 18.9999 15.1026 18.9999Z"
                fill="#C5C5C5"
            />
        </g>
    </svg>
);
