import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { PATH_CREATE_NEWS } from "@configs";
import { useNotify } from "@utils";
import { homeMenuAPI } from "@api";
import { setLoading, selectAuth, setInfo } from "@redux";
import { StyledBiddingDetail } from "@components";
import { IBannerData, IHomeOrganization, IInfoData } from "src/Interfaces/homeMenu";
import { ModuleHomeAboutForm, ModuleHomeDetailForm } from "src/Modules/homeMenu";

export const HomeAboutPage = () => {
    const org_id = useSelector(selectAuth).auth?.organization_data?._id;
    const dispatch = useDispatch();
    const history = useHistory();
    const { warning } = useNotify();
    const { t } = useTranslation();
    // page state
    const [organization, setOrganization] = useState<IHomeOrganization>();
    const [banner, setBanner] = useState<IBannerData>({
        "image_url": "",
        "text_in": "",
        "detail_in": "",
        "info_type": "ABOUT",
    });
    const [info, setInfo] = useState<IInfoData[]>([]);
    const pathName = history.location.pathname;

    useEffect(() => {
        fetchHomeMenu();
    }, []);

    const fetchHomeMenu = async () => {
        dispatch(setLoading(true));
        try {
            const res = await homeMenuAPI.getBannerAbout({
                organization_id: org_id || "",
                info_type: 'ABOUT',
            });
            // console.log(res.data.result);
            const data: IBannerData = res.data.result;
            const infoRes = await homeMenuAPI.getInfoAbout({
                organization_id: org_id || "",
                info_type: 'ABOUT',
            });
            const infoData: IInfoData[] = infoRes.data.result;
            // console.log('info', infoData);
            setBanner(data);
            setInfo([...infoData]);
            dispatch(setLoading(false));

        } catch (err: any) {
            if (err) {
                warning(err.msg);
                dispatch(setLoading(false));
            }    // const data: IHomeOrganization = res.data.result;
            // setOrganization(data);
            // dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }

    };

    return (
        <StyledBiddingDetail>
            <ModuleHomeDetailForm
                bannerData={banner}
                setBanner={setBanner}
                infoList={info}
                infoType={'ABOUT'}
                fetchInfo={fetchHomeMenu}
                setInfoList={setInfo}
            />
            {/* <ModuleHomeAboutForm
                organization={organization}
                bannerData={banner}
                infoData={info}
                setBanner={setBanner}
                setInfo={setInfo}
                fetchInfo={fetchHomeMenu}
            /> */}
        </StyledBiddingDetail>
    );
};
