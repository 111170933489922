import styled from "styled-components";

export const StyledLogin = styled.div`
    display: flex;
    justify-content: space-between;
    background: #fff;
    .login-layout {
        flex: 4;
        text-align: center;
        .login-container {
            padding: 64px;
            max-width: 600px;
            margin: auto;
            .login-header {
                display: flex;
                flex-direction: column;
                row-gap: 8px;
                margin-bottom: 48px;
                align-items: center;
            }
        }
        img {
            width: 120px;
            height: auto;
        }
        h3 {
            font-style: normal;
            font-weight: 600;
            font-size: 35px;
            line-height: 53px;
            text-align: center;
            color: #000000;
        }
        p {
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #8a959e;
        }
        .ant-form-item-label {
            width: 100% !important;
            text-align: left;
        }
        .ant-form-item {
            width: 450px;
            @media (max-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                width: 100%;
                input {
                    height: 48px;
                }
            }
        }
        .ant-form-item-label > label {
            font-weight: 600;
            font-size: 16px;
            line-height: 21px;
            color: #000000;
        }

        input {
            border-color: #d9d9d9 !important;
            box-shadow: none !important;
        }
        .ant-input {
            height: ${(p) => p.theme.heights.input};
            font-weight: 600;
            font-size: 20px;
            line-height: 27px;
            color: #000;
            padding: 15px;
            @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                font-size: 18px;
                height: 45px;
                padding: 10px;
            }
            @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                font-size: 16px;
            }
        }
        .main-color {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #a5a5a5;
            @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                font-size: 18px;
            }
            @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                font-size: 16px;
            }
            @media (max-width: ${(p) => p.theme.breakPoints.breakMobile}) {
                margin: 12px 0;
            }
        }
        .flex-layout {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 30px;
        }
        .forgot {
            margin: 13px 0;
            font-weight: bold;
            font-size: 16px;
            line-height: 21px;
            color: #646464;
            span {
                color: ${(p) => p.theme.colors.main};
                cursor: pointer;
                margin-left: 4px;
            }
        }

        .label__remember {
            font-weight: 400;
            font-size: 16px;
            line-height: 21px;
            color: #646464;
            margin-bottom: 30px;
            @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                font-size: 14px;
            }
            @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                font-size: 12px;
            }
        }
        .create {
            margin-top: 16px;
            .need-account {
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                text-align: center;
                color: #a5a5a5;
                margin-right: 4px;
            }
            a {
                text-decoration: none;
                font-weight: 700;
                font-size: 16px;
                line-height: 22px;
                text-align: center;
                color: ${(p) => p.theme.colors.main};
            }
            @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                font-size: 16px;
            }
            @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                font-size: 14px;
                /* padding-bottom: 26px; */
            }
        }
        .btn-login {
            margin-top: 8px;
        }
    }
    @media (max-width: ${(p) => p.theme.breakPoints.breakOnlyMobile}) {
        .login-layout {
            flex: 4;
            text-align: center;
            .login-container {
                padding: 0px;
                .login-header {
                    margin-top: 32px;
                    margin-bottom: 42px;
                    img {
                        width: 64px;
                    }
                }
            }
        }
    }
`;
