import { useTranslation } from "react-i18next";
import * as Yup from "yup";

export const YupInviteAdmin = () => {
    //page hook
    const { t } = useTranslation();

    return {
        surname: Yup.string().required(
            t("validation.required", {
                surname: t("page.surname"),
            })
        ),
        name: Yup.string().required(
            t("validation.required", {
                name: t("page.headlines"),
            })
        ),
        tel_no: Yup.string().required(
            t("validation.required", {
                tel_no: t("page.tel_no"),
            })
        ),
    };
};
