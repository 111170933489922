import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { enumValidation } from "@configs";
export const YupReward = () => {
    //page hook
    const { t } = useTranslation();

    return {
        name: Yup.string()
            .required(
                t("validation.required", {
                    name: t("page.reward_name"),
                })
            )
            .max(
                enumValidation.MAX_50,
                t("validation.max", {
                    name: t("page.reward_name"),
                    number: enumValidation.MAX_50,
                })
            ),
        client_limit: Yup.number()
            .typeError(t("validation.is_Number"))
            .max(
                enumValidation.MAX_NUMBER,
                t("validation.max_number", {
                    name: t("page.quantity_limit"),
                    number: enumValidation.MAX_NUMBER,
                })
            )
            .min(
                enumValidation.MIN_NUMBER,
                t("validation.min_number", {
                    name: t("page.quantity_limit"),
                    number: enumValidation.MIN_NUMBER,
                })
            ),
        reward_limit: Yup.number()
            .typeError(t("validation.is_Number"))
            .max(
                enumValidation.MAX_NUMBER,
                t("validation.max_number", {
                    name: t("page.quantity_limit"),
                    number: enumValidation.MAX_NUMBER,
                })
            )
            .min(
                enumValidation.MIN_NUMBER,
                t("validation.min_number", {
                    name: t("page.quantity_limit"),
                    number: enumValidation.MIN_NUMBER,
                })
            ),
    };
};
