import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Switch, Col, Row, Avatar, Menu, Dropdown, Button, Select } from "antd";
import { MoreHorizontal } from "react-feather";
import moment from "moment";

import { INews, INewsCategory, ITrashfeeArea, ITrashfeeBillList, ItrashfeeBillModal } from "@interfaces";
import { newsAPI } from "@api";
import { dateFormat, dateFormatHM, PATH_CREATE_NEWS, enumNewsType, defaultImage } from "@configs";
import { selectAuth } from "@redux";
import { useSelector } from "react-redux";
import { IconDrag, StyledCancelButton, StyledCard } from "@components";
import { showErrorMessage, useNotify } from "@utils";
import { useEffect, useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import styled from "styled-components";
import { LoadIcon } from "../icon/LoadIcon";
import { CaretDownOutlined } from "@ant-design/icons";
interface IProps {
  data: ItrashfeeBillModal;
  itemJson: string;
  handleEdit: (status: boolean, data: ItrashfeeBillModal) => void;
  callbackGetList: () => void;
  index: number;
  newType?: enumNewsType;
  payBillByCash: (id: string) => void;
  toggleCancleStatus: (id: string, status: boolean) => void;
}
const MONTHlIST = [
  'มกราคม',
  'กุมภาพันธ์',
  'มีนาคม',
  'เมษายน',
  'พฤษภาคม',
  'มิถุนายน',
  'กรกฎาคม',
  'สิงหาคม',
  'กันยายน',
  'ตุลาคม',
  'พฤศจิกายน',
  'ธันวาคม'
];
export const ComponentRowBillDetail = (props: IProps) => {
  //hooks
  const { t } = useTranslation();
  const { success, error } = useNotify();
  const org_id = useSelector(selectAuth).auth?.organization_data?._id;
  //props
  const { data,
    handleEdit,
    callbackGetList,
    itemJson,
    newType,
    payBillByCash,
    toggleCancleStatus,
  } = props;
  const [show, setShowModal] = useState(false);
  const { Option } = Select;
  const { attributes, listeners, setNodeRef, transform } = useSortable({
    id: itemJson,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
  };
  const showModal = () => {
    setShowModal(!show);
  };
  useEffect(() => {
    console.log('inrow', data?.is_enable);
  }, []);

  const menu = (
    <Menu
      style={{ width: "120px" }}
    >
      {(data?.is_paid === false || data?.is_enable === false) &&
        <Menu.Item key={1}>
          <div
            className="duplicate-btn"
            onClick={() => payBillByCash(data?._id)}
          >
            ชำระเงิน
          </div>
        </Menu.Item>
      }
      {/* {!data?.is_paid &&
        <Menu.Divider />
      } */}
      {(data?.is_paid === false && data?.is_enable === true) &&
        <Menu.Item key={1} >
          <div
            className="duplicate-btn"
            onClick={() => toggleCancleStatus(data?._id, false)}
          >
            ยกเลิกการชำระ
          </div>
        </Menu.Item>
      }

    </Menu>
  );
  const handleUpdateStatus = (e: boolean) => {
    // handleEdit(e, data);
  };
  return (
    <div className="table-element" ref={setNodeRef} style={style}>
      <div className="table-row">
        <StyledRow>
          <Row
            style={{ height: "100%" }}
            gutter={24}
            type="flex"
            align="middle"
            justify="space-between"
          >
            <Col className="col-item" span={4}>
              <b>{data?.invoice_no}</b>
            </Col>
            <Col className="col-item m-member-code" span={4}>
              <p className="visible-md eng">
                <b>{MONTHlIST[data?.month_cycle - 1]} {data?.year_cycle + 543}</b>
              </p>
            </Col>
            <Col className="col-item" span={4}>
              {moment(data?.expired_date).date()}/{moment(data?.expired_date).month() + 1}/{moment(data?.expired_date).year() + 543}
            </Col>
            <Col className="col-item" span={3}>
              {data?.trash_fee} บาท
            </Col>
            <Col className="col-item" span={4}>
              <StyledPaidStatus>
                <Dropdown
                  //@ts-ignore
                  getPopupContainer={(trigger) => trigger.parentNode}
                  overlay={menu}
                  trigger={["click"]}
                  placement="bottomRight"
                  disabled={data?.is_paid === true}
                >
                  {data?.is_enable ?
                    data?.is_paid ?
                      <div className="paid-btn">
                        <span>ชำระแล้ว</span>
                      </div>
                      :
                      <div className="no-paid-btn"
                      //  onClick={() => payBillByCash(data?._id)}
                      >
                        <span>ค้างจ่าย &#160;&#160; <CaretDownOutlined /></span>
                      </div>
                    :
                    <div className="cancle-btn">
                      <span>ยกเลิกการชำระ  &#160;&#160; <CaretDownOutlined /></span>
                    </div>
                  }
                </Dropdown>
              </StyledPaidStatus>

            </Col>
            <Col className="col-item" span={5}>
              <StyledButton>
                <Button className="load-btn">
                  <LoadIcon color="" /> &#160;
                  <span>ดาวน์โหลดใบแจ้งหนี้รอบบิลนี้</span>
                </Button>
              </StyledButton>

            </Col>
          </Row>
        </StyledRow>
      </div>
    </div >
  );
};

const StyledPaidStatus = styled.div`
 
  .paid-btn {
    cursor: pointer;
    span {
      background: #34C759;
      padding: 10px 15px;
      border-radius: 6px;
      color: white;
    }
  }
  .no-paid-btn {
    cursor: pointer;
     span {
      background: #0D6EFD;
      padding: 10px 15px;
      padding-right: 5px;
      border-radius: 6px; 
      color: white;
    }
  }
  .cancle-btn {
    cursor: pointer;
     span {
      background: #ce4f4f;
      padding: 10px 15px;
      padding-right: 5px;
      border-radius: 6px; 
      color: white;
    }
  }
`;

const StyledButton = styled.div`
  text-align: right;
  .load-btn{
    height: 49px;
    border: 0.5px solid #8A959E;
    border-radius: 4px;
    span {
      font-weight: 500;
      font-size: 16px;
      color: #29304D;
    }
  }
`;

const StyledRow = styled.div`
  padding: 15px;
  border-radius: 4px;
  background-color: white;
  margin-bottom: 15px;
`;