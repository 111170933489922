import { ISvg } from "@interfaces";
export const MenuCalendar = (props: ISvg) => (
    <svg
        width={props.size || "24"}
        height={props.size || "24"}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.6665 9.9043H2.84253C2.42853 9.9043 2.09253 9.5683 2.09253 9.1543C2.09253 8.7403 2.42853 8.4043 2.84253 8.4043H20.6665C21.0805 8.4043 21.4165 8.7403 21.4165 9.1543C21.4165 9.5683 21.0805 9.9043 20.6665 9.9043"
            fill="#0D6EFD"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.2012 13.8096C15.7872 13.8096 15.4472 13.4736 15.4472 13.0596C15.4472 12.6456 15.7782 12.3096 16.1922 12.3096H16.2012C16.6152 12.3096 16.9512 12.6456 16.9512 13.0596C16.9512 13.4736 16.6152 13.8096 16.2012 13.8096"
            fill="#0D6EFD"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.7637 13.8096C11.3497 13.8096 11.0097 13.4736 11.0097 13.0596C11.0097 12.6456 11.3407 12.3096 11.7547 12.3096H11.7637C12.1777 12.3096 12.5137 12.6456 12.5137 13.0596C12.5137 13.4736 12.1777 13.8096 11.7637 13.8096"
            fill="#0D6EFD"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.31689 13.8096C6.90289 13.8096 6.56189 13.4736 6.56189 13.0596C6.56189 12.6456 6.89389 12.3096 7.30789 12.3096H7.31689C7.73089 12.3096 8.06689 12.6456 8.06689 13.0596C8.06689 13.4736 7.73089 13.8096 7.31689 13.8096"
            fill="#0D6EFD"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.2012 17.6963C15.7872 17.6963 15.4472 17.3603 15.4472 16.9463C15.4472 16.5323 15.7782 16.1963 16.1922 16.1963H16.2012C16.6152 16.1963 16.9512 16.5323 16.9512 16.9463C16.9512 17.3603 16.6152 17.6963 16.2012 17.6963"
            fill="#0D6EFD"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.7637 17.6963C11.3497 17.6963 11.0097 17.3603 11.0097 16.9463C11.0097 16.5323 11.3407 16.1963 11.7547 16.1963H11.7637C12.1777 16.1963 12.5137 16.5323 12.5137 16.9463C12.5137 17.3603 12.1777 17.6963 11.7637 17.6963"
            fill="#0D6EFD"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.31689 17.6963C6.90289 17.6963 6.56189 17.3603 6.56189 16.9463C6.56189 16.5323 6.89389 16.1963 7.30789 16.1963H7.31689C7.73089 16.1963 8.06689 16.5323 8.06689 16.9463C8.06689 17.3603 7.73089 17.6963 7.31689 17.6963"
            fill="#0D6EFD"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.7935 5.791C15.3795 5.791 15.0435 5.455 15.0435 5.041V1.75C15.0435 1.336 15.3795 1 15.7935 1C16.2075 1 16.5435 1.336 16.5435 1.75V5.041C16.5435 5.455 16.2075 5.791 15.7935 5.791"
            fill="#0D6EFD"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.71527 5.791C7.30127 5.791 6.96527 5.455 6.96527 5.041V1.75C6.96527 1.336 7.30127 1 7.71527 1C8.12927 1 8.46527 1.336 8.46527 1.75V5.041C8.46527 5.455 8.12927 5.791 7.71527 5.791"
            fill="#0D6EFD"
        />
        <mask id="mask0_3420_49298" maskUnits="userSpaceOnUse" x="2" y="2" width="20" height="21">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 2.5791H21.5V22.5H2V2.5791Z"
                fill="white"
            />
        </mask>
        <g mask="url(#mask0_3420_49298)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.521 4.0791C4.928 4.0791 3.5 5.4621 3.5 7.9731V17.0221C3.5 19.5881 4.928 21.0001 7.521 21.0001H15.979C18.572 21.0001 20 19.6141 20 17.0981V7.9731C20.004 6.7381 19.672 5.7781 19.013 5.1181C18.335 4.4381 17.29 4.0791 15.988 4.0791H7.521ZM15.979 22.5001H7.521C4.116 22.5001 2 20.4011 2 17.0221V7.9731C2 4.6451 4.116 2.5791 7.521 2.5791H15.988C17.697 2.5791 19.11 3.0911 20.075 4.0581C21.012 4.9991 21.505 6.3521 21.5 7.9751V17.0981C21.5 20.4301 19.384 22.5001 15.979 22.5001V22.5001Z"
                fill="#0D6EFD"
            />
        </g>
    </svg>
);
