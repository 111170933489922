import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { Switch, Col, Row, Avatar, Menu, Dropdown, Button } from "antd";
import { MoreHorizontal } from "react-feather";
import moment from "moment";

import { INews, INewsCategory, ITrashfeeArea, ITrashfeeHouse } from "@interfaces";
import { newsAPI, trashfeeAPI } from "@api";
import { dateFormat, dateFormatHM, PATH_CREATE_NEWS, enumNewsType, defaultImage, PATH_TRASH_FEE_HOUSE } from "@configs";
import { selectAuth } from "@redux";
import { useSelector } from "react-redux";
import { IconDrag } from "@components";
import { showErrorMessage, useNotify } from "@utils";
import { useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
interface IProps {
  data: ITrashfeeHouse;
  itemJson: string;
  handleEdit: (status: boolean, data: ITrashfeeHouse) => void;
  callbackGetList: () => void;
  index: number;
  newType?: enumNewsType;
  categories: INewsCategory[];
  areaList: ITrashfeeArea[];
}

export const ComponentRowHouse = (props: IProps) => {
  //hooks
  const { t } = useTranslation();
  const { success, error } = useNotify();
  const organization_id = useSelector(selectAuth).auth?.organization_data?._id;
  //props
  const history = useHistory();
  const { data, handleEdit, callbackGetList, itemJson, newType, areaList } = props;
  const [show, setShowModal] = useState(false);

  const { attributes, listeners, setNodeRef, transform } = useSortable({
    id: itemJson,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
  };


  const areaName = () => {
    const item = areaList.filter((item) => item._id === data.area_id);
    if (item.length > 0) {
      return item[0].area_name;
    }
    return "...";
  };

  const handleDelete = async () => {
    try {
      await trashfeeAPI.deleteHouse({
        organization_id: organization_id || "",
        house_id: data?._id
      });
      callbackGetList();
      setShowModal(false);
      success(t("message.delete.success"));
    } catch (err: any) {
      if (err) {
        error(err.msg);
      } else error(t("message.delete.fail"));
    }
  };

  const handleRemove = async () => {
    // try {
    //   const payload = {
    //     organization_id: data.organization_id,
    //     title: data.title,
    //     detail: data.detail,
    //     image_url_list: data.image_url_list,
    //     is_hot_news: false,
    //     news_category_id: data.news_category_id,
    //     date_from: data.date_from,
    //     date_to: data.date_to,
    //     is_enable: data.is_enable,
    //     news_id: data._id,
    //   };
    //   await newsAPI.update(payload);
    //   callbackGetList();
    //   success(t("message.duplicate.success"));
    // } catch (err: any) {
    //   if (err.response) {
    //     error(showErrorMessage(err.response));
    //   } else error(t("message.duplicate.fail"));
    // }
  };

  const showModal = () => {
    setShowModal(!show);
    // Modal.confirm({
    //     title: t("message.delete_title"),
    //     icon: <ExclamationCircleOutlined />,
    //     content: t("message.delete_confirm", {
    //         returnObjects: true,
    //         name: t("object._contact"),
    //     }),
    //     okText: t("page.delete"),
    //     cancelText: t("page.cancel"),
    //     centered: true,
    //     onOk: handleDelete,
    // });
  };

  const menu = (
    <Menu>
      {newType && newType === enumNewsType.HOT_NEWS && (
        <Menu.Item key={2} onClick={handleRemove}>
          <div className="delete-btn">{t("page.remove")}</div>
        </Menu.Item>
      )}
      <Menu.Divider />
      <Menu.Item key={1} onClick={handleDelete}>
        <div className="duplicate-btn">{t("page.delete")}</div>
      </Menu.Item>
    </Menu>
  );
  const handleUpdateStatus = (e: boolean) => {
    handleEdit(e, data);
  };
  return (
    <div className="table-element" ref={setNodeRef} style={style}>
      <div className="drag-icon" {...attributes} {...listeners}>
        <IconDrag />
      </div>
      <div className="table-row">
        <Row
          style={{ height: "100%" }}
          gutter={24}
          type="flex"
          align="middle"
          justify="space-between"
        >
          <Col className="col-item" span={3}
            onClick={() => history.push(`${PATH_TRASH_FEE_HOUSE}/${data._id}`)}
          >
            <b style={{ cursor: "pointer" }}>{data.register_no}</b>

            {/* <p>{catName() || t("page.empty_text")}</p> */}
          </Col>
          <Col className="col-item" span={4}>
            {data.owner.prefix} {data.owner.name} {data.owner.surname}
          </Col>
          <Col className="col-item" span={3}>
            {data.owner.card_no}
          </Col>
          <Col className="col-item" span={3}>
            <p>{areaName() || t("page.empty_text")}</p>
          </Col>
          <Col className="col-item" span={6}>
            {data.full_address}
          </Col>
          {/* <Col className="col-item m-member-code" xs={10} sm={10} lg={3} md={3}>
            <p className="visible-md eng">
              {moment(data.created_date).format(dateFormat) || t("page.empty_text")}
            </p>
          </Col> */}
          {/* <Col className="col-item visible-md" span={3}>
            <p className="eng">
              {moment(data.date_from).format(dateFormatHM)}
              <br />
              {moment(data.date_to).format(dateFormatHM)}
            </p>
          </Col> */}

          <Col className="col-item" span={2}>
            <Dropdown
              //@ts-ignore
              getPopupContainer={(trigger) => trigger.parentNode}
              overlay={menu}
              trigger={["click"]}
              placement="bottomRight"
            >
              <Button style={{ border: "unset" }}>
                <MoreHorizontal />
              </Button>
            </Dropdown>
          </Col>
        </Row>
      </div>
    </div >
  );
};
