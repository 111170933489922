import { ISvg } from "@interfaces";
export const HomeIcon = ({ color, size }: ISvg) => (
    <svg
        width={size || "24"}
        height={size || "25"}
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <mask
            id="mask0_4224_40748"
            // style="mask-type:alpha"
            maskUnits="userSpaceOnUse"
            x="2"
            y="1"
            width="21"
            height="23"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 1.83105H22.4998V23.3361H2V1.83105Z"
                fill="white"
            />
        </mask>
        <g mask="url(#mask0_4224_40748)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.717 16.1223C14.921 16.1223 15.901 17.0953 15.901 18.2913V21.3673C15.901 21.6243 16.107 21.8303 16.371 21.8363H18.277C19.779 21.8363 21 20.6303 21 19.1483V10.4243C20.993 9.9143 20.75 9.4343 20.333 9.1153L13.74 3.8573C12.855 3.1563 11.617 3.1563 10.729 3.8593L4.181 9.11331C3.748 9.44231 3.505 9.92231 3.5 10.4413V19.1483C3.5 20.6303 4.721 21.8363 6.223 21.8363H8.147C8.418 21.8363 8.638 21.6213 8.638 21.3573C8.638 21.2993 8.645 21.2413 8.657 21.1863V18.2913C8.657 17.1023 9.631 16.1303 10.826 16.1223H13.717ZM18.277 23.3363H16.353C15.251 23.3103 14.401 22.4453 14.401 21.3673V18.2913C14.401 17.9223 14.094 17.6223 13.717 17.6223H10.831C10.462 17.6243 10.157 17.9253 10.157 18.2913V21.3573C10.157 21.4323 10.147 21.5043 10.126 21.5723C10.018 22.5623 9.172 23.3363 8.147 23.3363H6.223C3.894 23.3363 2 21.4573 2 19.1483V10.4343C2.01 9.44031 2.468 8.5303 3.259 7.9313L9.794 2.6863C11.233 1.5463 13.238 1.5463 14.674 2.6843L21.256 7.9343C22.029 8.5233 22.487 9.43131 22.5 10.4133V19.1483C22.5 21.4573 20.606 23.3363 18.277 23.3363Z"
                fill={color || "#0D6EFD"}
            />
        </g>
    </svg>
);
