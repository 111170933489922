import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { Col, Row, Avatar } from "antd";
import moment from "moment";

import { IConplaint } from "@interfaces";
import {
    dateFormat,
    defaultImage,
    enumNewsType,
    enumProcessStatus,
    PATH_COMPLAINT,
} from "@configs";

import { ShareButton, IconDrag } from "@components";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
interface IProps {
    data: IConplaint;
    itemJson: string;
    callbackGetList: () => void;
    index: number;
    newType?: enumNewsType;
}

export const ComponentRowConpliant = (props: IProps) => {
    //hooks
    const { t } = useTranslation();
    const history = useHistory();
    //props
    const { data, itemJson } = props;

    const { attributes, listeners, setNodeRef, transform } = useSortable({
        id: itemJson,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
    };

    const getProcessClass = (value: enumProcessStatus) => {
        if (value === enumProcessStatus.PENDING) return "status-button warning";
        if (value === enumProcessStatus.DOING) return "status-button doing";
        if (value === enumProcessStatus.COMPLETE) return "status-button complete";
        if (value === enumProcessStatus.CANCELED) return "status-button canceled";
    };
    return (
        <div className="table-element" ref={setNodeRef} style={style}>
            <div className="drag-icon vs-dm" {...attributes} {...listeners}>
                <IconDrag />
            </div>
            <div className="table-row">
                <Row
                    style={{ height: "100%" }}
                    gutter={10}
                    type="flex"
                    align="middle"
                    justify="space-between"
                >
                    <Col className="col-item" span={6}>
                        <Avatar
                            shape="square"
                            src={data?.image_url_list[0] || defaultImage}
                            size={84}
                            style={{ minWidth: 84 }}
                        />
                        <p>{data.header || t("page.empty_text")}</p>
                        {/* <Link to={PATH_COMPLAINT + "/" + data._id}>
                            <p>{data.complainer.name || t("page.empty_text")}</p>
                        </Link> */}
                    </Col>
                    {/* <Col className="col-item" span={4}>
                        <p>{data.header || t("page.empty_text")}</p>
                    </Col> */}
                    <Col className="col-item m-member-code" span={4}>
                        <p className="visible-md eng">
                            {moment(data.created_date).format(dateFormat) || t("page.empty_text")}
                        </p>
                    </Col>
                    <Col className="col-item visible-md" span={4}>
                        <div className={getProcessClass(data.status)}>
                            <p>{t(`page.${data.status}`) || t("page.empty_text")}</p>
                        </div>
                    </Col>
                    <Col className="col-item visible-md" span={4}>
                        <p>{data.petitioner.name || t("page.empty_text")}</p>
                    </Col>
                    <Col className="col-item" span={4}>
                        <ShareButton
                            type="default"
                            size="large"
                            // style={{ padding: "16px 50px" }}
                            className="ant-btn-primary carryout-btn"
                            text={t("page.carry_out")}
                            onClick={() => history.push(PATH_COMPLAINT + "/" + data._id)}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
};
