import {
    IOrganizationId,
    IRewardCreate,
    IRewardDelete,
    IRewardManageEnrollParams,
    IRewardParam,
    IRewardParams,
    IRewardUpdate,
    RewardProfileDetailParams,
    RewardProfileParams,
} from "@interfaces";

import axiosClient from "./axiosClient";

export const rewardAPI = {
    create: (params: IRewardCreate) => {
        const url = "reward/createReward";
        return axiosClient.post(url, params);
    },
    update: (params: IRewardUpdate) => {
        const url = "reward/updateReward";
        return axiosClient.patch(url, params);
    },
    delete: (params: IRewardDelete) => {
        const url = `reward/deleteReward`;
        return axiosClient.post(url, params);
    },
    getRewards: (params: IRewardParams) => {
        const url = "reward/getRewards";
        return axiosClient.get(url, { params });
    },
    detail: (params: IRewardParam) => {
        const url = `reward/getReward`;
        return axiosClient.get(url, { params });
    },
    adminManageEnrollReward: (params: IRewardManageEnrollParams) => {
        const url = "reward/adminManageEnrollReward";
        return axiosClient.post(url, params);
    },
    getProfileRewards: (params: RewardProfileParams) => {
        const url = "reward/getProfileRewards";
        return axiosClient.get(url, { params });
    },
    getProfileReward: (params: RewardProfileDetailParams) => {
        const url = "reward/getProfileReward";
        return axiosClient.get(url, { params });
    },
    getProfileRewardByBookingCode: (params: RewardProfileDetailParams) => {
        const url = "reward/getProfileRewardByBookingCode";
        return axiosClient.get(url, { params });
    },
    getRewardEnableCount: (params: IOrganizationId) => {
        const url = `reward/getRewardEnableCount`;
        return axiosClient.get(url, { params });
    },
};
