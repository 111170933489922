import { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ScanIcon, SharedButtonDefault, StyledCard, ComponentInfoBox } from "@components";
import ScrollContainer from "react-indiana-drag-scroll";
import { Input } from "antd";
import { ModuelEmptyState } from "./EmptyState";
import { ModuleMemberCard } from "./Card";
import { ModuleModalQRMobile } from "./Modal";
import { setLoading, selectAuth } from "@redux";
import { rewardAPI } from "@api";
import { showErrorMessage, useNotify, usePermission } from "@utils";
import { IRewardProfileDetail } from "@interfaces";
import { dateFormatHM, enumNotificationAction, enumProcessStatus } from "@configs";
import { ModuleConfirmModal } from "./ConfirmModal";
import { ModuleAlertSuccess } from "./AlertSuccess";
import { useSelector } from "react-redux";
import moment from "moment";

const { Search } = Input;
const defaultImage = "/images/newUser/default-reward.png";

export const PagePrivilegeRedeem = () => {
    //page hook
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { error, success } = useNotify();
    const organization_id = useSelector(selectAuth).auth?.organization_data?._id;
    const permission = useSelector(selectAuth).permission;
    const [rewardProfile, setRewardProfile] = useState<IRewardProfileDetail>();
    const [searchValue, setSearchValue] = useState("");
    const [isVisibleMobileQr, setVisibleMobileQr] = useState(false);
    const [isVisibleWebQr, setVisibleWebQr] = useState(false);
    const [alertSuccess, setAlertSuccess] = useState(false);
    const [confirmType, setConfirmAction] = useState<enumProcessStatus>(enumProcessStatus.COMPLETE);
    const [confirmModal, setConfirmModal] = useState(false);
    usePermission({ permission: permission?.permission_list.claim_reward.is_manage_reward });
    const handleClickQr = () => {
        setVisibleMobileQr(!isVisibleMobileQr);
        setVisibleWebQr(!isVisibleWebQr);
    };

    const handleSearch = async (value) => {
        if (value) {
            try {
                dispatch(setLoading(true));
                const res = await rewardAPI.getProfileRewardByBookingCode({
                    organization_id: organization_id || "",
                    booking_code: value,
                });
                if (res.data.error === "0") {
                    const data: IRewardProfileDetail = res.data.result;
                    setRewardProfile(data);
                } else {
                    error(res.data.msg);
                    setRewardProfile(undefined);
                }
            } catch (err: any) {
                if (err) {
                    error(err.msg);
                } else error(t("message.customer_not_found"));
            } finally {
                dispatch(setLoading(false));
            }
        }
    };
    const handleClearCustomer = () => {
        setRewardProfile(undefined);
        setSearchValue("");
    };
    const handleSearchChange = (e) => {
        setSearchValue(e.target.value);
    };
    const handleSearchFromQr = (value) => {
        setSearchValue(value);
        setVisibleMobileQr(false);
        setVisibleWebQr(false);
    };

    const updateRedeem = async (status: enumNotificationAction) => {
        try {
            await rewardAPI.adminManageEnrollReward({
                organization_id: organization_id || "",
                profile_reward_id: rewardProfile?.profile_reward?._id || "",
                new_status: status,
            });
            success(t("message.update.success"));
            setConfirmModal(false);
            setAlertSuccess(true);
        } catch (err: any) {
            if (err.response) {
                error(showErrorMessage(err.response));
            } else err("message.error");
        }
    };

    const handleConfirmModal = () => {
        setConfirmModal(false);
    };
    const handleAlertSuccess = () => {
        setAlertSuccess(false);
    };
    const handleConfirmActions = (param: enumProcessStatus) => {
        if (param === enumProcessStatus.COMPLETE) {
            setConfirmAction(enumProcessStatus.COMPLETE);
            setConfirmModal(true);
        } else {
            setConfirmAction(enumProcessStatus.CANCELED);
            setConfirmModal(true);
        }
    };

    return (
        <StyledPageWrapper>
            <ModuleAlertSuccess
                isVisible={alertSuccess}
                handleCancel={handleAlertSuccess}
                confirmType={confirmType}
            />
            <ModuleModalQRMobile
                handleOk={handleClickQr}
                isVisible={isVisibleWebQr}
                handleSearchFromQr={handleSearchFromQr}
            />
            <ModuleConfirmModal
                handleOk={updateRedeem}
                confirmType={confirmType}
                isVisible={confirmModal}
                rewardProfile={rewardProfile}
                handleCancel={handleConfirmModal}
            />
            <div className="page-layout">
                <div className="page-header">
                    <h3>{t("page.redeem_privilege")}</h3>
                </div>
                <ScrollContainer vertical={true}>
                    <div className="member-layout">
                        <StyledLayout>
                            <div className="left-col">
                                <div className="card-element">
                                    <StyledCard maxWidth="unset">
                                        <h3>{t("page.search_redeem")}</h3>
                                        {/* <p>{t("page.search_for_user")}</p> */}
                                        <div>
                                            <div className="search-member">
                                                <Search
                                                    className="search-input"
                                                    onSearch={handleSearch}
                                                    onChange={handleSearchChange}
                                                    value={searchValue}
                                                    placeholder={t("page.search_for_user")}
                                                />
                                                <SharedButtonDefault
                                                    style={btnStyle}
                                                    text={t("page.member_points.qr_code")}
                                                    type="default"
                                                    size="default"
                                                    className="search-btn"
                                                    onClick={handleClickQr}
                                                    // disable={isSubmitting}
                                                    customIcon={<ScanIcon />}
                                                ></SharedButtonDefault>
                                            </div>
                                            <div className="search-result">
                                                {rewardProfile ? (
                                                    <ModuleMemberCard
                                                        rewardProfile={rewardProfile}
                                                        callBack={handleClearCustomer}
                                                    />
                                                ) : (
                                                    <ModuelEmptyState />
                                                )}
                                            </div>
                                        </div>
                                    </StyledCard>
                                </div>
                                {rewardProfile && (
                                    <>
                                        <div className="card-element">
                                            <StyledCard maxWidth="unset">
                                                <h3>{t("page.redeem.details")}</h3>
                                                <StyledDetailLayout>
                                                    <div className="detail-item">
                                                        <div className="detail-info">
                                                            <img
                                                                alt="reward images"
                                                                src={
                                                                    rewardProfile?.reward
                                                                        ?.image_url_list[0] ||
                                                                    defaultImage
                                                                }
                                                            />
                                                            <div className="detail-des">
                                                                <div className="detail-title">
                                                                    {t("page.privilege_id")}
                                                                    <span className="code">
                                                                        {
                                                                            rewardProfile
                                                                                .profile_reward
                                                                                ?.booking_code
                                                                        }
                                                                    </span>
                                                                </div>
                                                                <h5>
                                                                    {rewardProfile?.reward?.name}
                                                                </h5>
                                                            </div>
                                                        </div>
                                                        <div className="redeem-attributes">
                                                            <div className="item-row">
                                                                <p>{t("page.date_redeem")}</p>
                                                                <p>
                                                                    {moment(
                                                                        rewardProfile?.reward
                                                                            ?.date_to
                                                                    ).format(dateFormatHM)}
                                                                </p>
                                                            </div>
                                                            <div className="item-row">
                                                                <p>{t("page.status")}</p>
                                                                <p>
                                                                    {
                                                                        rewardProfile
                                                                            ?.profile_reward?.status
                                                                    }
                                                                </p>
                                                            </div>
                                                            <div className="item-row">
                                                                <p>
                                                                    {t(
                                                                        "page.where_to_get_redemption"
                                                                    )}
                                                                </p>
                                                                <p>
                                                                    {
                                                                        rewardProfile
                                                                            ?.profile_reward
                                                                            ?.receive_type
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="action-layout">
                                                            <SharedButtonDefault
                                                                style={rejectStyle}
                                                                text={t("page.redeem.reject")}
                                                                type="default"
                                                                size="default"
                                                                disable={
                                                                    rewardProfile?.profile_reward
                                                                        ?.status ===
                                                                    enumProcessStatus.COMPLETE
                                                                }
                                                                onClick={() =>
                                                                    handleConfirmActions(
                                                                        enumProcessStatus.CANCELED
                                                                    )
                                                                }
                                                                className="action-btn"
                                                            ></SharedButtonDefault>
                                                            <SharedButtonDefault
                                                                style={redeemStyle}
                                                                text={t("page.redeem.redeem")}
                                                                type="default"
                                                                size="default"
                                                                disable={
                                                                    rewardProfile?.profile_reward
                                                                        ?.status ===
                                                                    enumProcessStatus.COMPLETE
                                                                }
                                                                onClick={() =>
                                                                    handleConfirmActions(
                                                                        enumProcessStatus.COMPLETE
                                                                    )
                                                                }
                                                                className="action-btn"
                                                            ></SharedButtonDefault>
                                                        </div>
                                                    </div>
                                                </StyledDetailLayout>
                                            </StyledCard>
                                        </div>
                                    </>
                                )}
                            </div>
                        </StyledLayout>
                    </div>
                </ScrollContainer>
            </div>
        </StyledPageWrapper>
    );
};
const StyledPageWrapper = styled.div`
    padding: 2rem 3.5rem 3.5rem 3.5rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 3.5rem;
    height: 84vh;
    overflow: auto;
    @media (max-width: ${(p) => p.theme.breakPoints.break14Laptop}) {
        height: 80vh;
    }
    @media (max-width: ${(p) => p.theme.breakPoints.breakOnlyMobile}) {
        padding: 16px;
    }
    .page-header {
        display: flex;
        flex-wrap: wrap;
        padding: 1.5rem 0 14px 0;
        .add-btn {
            background-color: ${(p) => p.theme.colors.danger};
            color: white;
            justify-content: space-evenly;
        }

        h3 {
            margin-right: 10px;
            font-weight: 700;
            font-size: 35px;
            margin-bottom: 0;
            color: black;
        }
        p {
            color: ${(p) => p.theme.colors.fadedText};
            font-size: 16px;
            font-weight: 600;
        }
        @media (max-width: ${(p) => p.theme.breakPoints.breakOnlyMobile}) {
            /* flex-direction: column; */
            /* align-items: flex-start; */
            align-items: center;
            div {
                justify-content: flex-start;
            }
            div:nth-child(2) {
                margin-top: 12px;
            }
            h3 {
                font-size: 16px;
                margin: 0;
            }
        }
    }
`;
const btnStyle = {
    background: "#0263E0",
    width: 145,
    color: "white",
    fontSize: 16,
    fontWeight: 600,
    height: 49,
};
const rejectStyle = {
    background: "#fff",
    color: "#646464",
    fontSize: 16,
    fontWeight: 600,
    height: 49,
};
const redeemStyle = {
    background: "#0263E0",
    color: "white",
    fontSize: 16,
    fontWeight: 600,
    height: 49,
};
const StyledDetailLayout = styled.div`
    display: flex;
    flex-direction: column;
    .detail-info {
        display: flex;
        margin-bottom: 5px;
        padding-bottom: 30px;
        border-bottom: 1px dashed #e1e1e1;
        img {
            /* border: 1px solid #ddd; */
            width: 120px;
            height: 120px;
            @media (max-width: ${(p) => p.theme.breakPoints.breakOnlyMobile}) {
                width: 74px;
                height: 74px;
            }
        }
        .detail-des {
            padding: 4px 10px;
            .detail-title {
                margin-bottom: 12px;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                display: flex;
                align-items: center;
                color: #a5a5a5;
                .code {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 22px;
                    display: flex;
                    align-items: center;
                    color: #000000;
                    padding-left: 4px;
                }
                .redeem-type {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    padding: 4px 12px;
                    gap: 10px;
                    max-width: 130px;
                    height: 22px;
                    background: #6cd14e;
                    border-radius: 70px;
                    margin-left: 10px;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 10px;
                    line-height: 14px;
                    color: #ffffff;
                }
            }
        }
        h5 {
            height: 44px;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            /* display: flex; */
            /* align-items: center; */
            color: #000000;
        }
        .points {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            display: flex;
            align-items: center;
            color: #000000;
            .point-value {
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 22px;
                color: #0263e0;
                padding-left: 8px;
            }
        }
    }

    .redeem-attributes {
        margin-bottom: 30px;
        .item-row {
            display: flex;
            justify-content: space-between;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 19px;
            color: #a5a5a5;
            border-bottom: 0.5px solid #e1e1e1;
            padding: 15px 0;
            p {
                margin: 0;
            }
        }
    }
    .action-layout {
        display: flex;
        justify-content: space-between;
        column-gap: 15px;
        div {
            flex: 1;
            button {
                width: 100%;
            }
        }
    }
`;
const StyledLayout = styled.div`
    display: flex;
    max-width: 727px;
    width: 100%;
    flex: 1;
    @media (max-width: ${(p) => p.theme.breakPoints.breakOnlyMobile}) {
        display: initial;
    }
    .left-col {
        flex: 4;
        .card-element {
            margin-bottom: 19px;
            .add-point-field {
                p {
                    margin: 0;
                }
                display: flex;
                align-items: center;
                justify-content: space-between;
                .input-col {
                    flex: 4;
                    position: relative;
                    .float-label {
                        position: absolute;
                        top: 15px;
                        right: 14px;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        color: #646464;
                    }
                }
                input {
                    height: 48px;
                }
                .middle-or {
                    margin: 0 12px 14px 12px;
                    text-align: center;
                    /* margin-bottom: 14px; */
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                }
            }
            .use-point-field {
                width: 50%;

                .w-90 {
                    width: 100%;
                }
                .point-input {
                    position: relative;
                    .float-label {
                        position: absolute;
                        top: 56px;
                        right: 14px;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        color: #646464;
                    }
                }
            }
            .ant-tabs-bar.ant-tabs-top-bar {
                border-bottom: 0;
            }
            .ant-tabs-nav .ant-tabs-tab-active {
                font-weight: 700;
                color: #f22f46;
            }
            .ant-tabs-nav .ant-tabs-tab {
                padding: 0;
                padding-bottom: 6px;
            }
            .ant-tabs-nav .ant-tabs-tab:hover {
                color: #f22f46;
            }
            .ant-tabs-nav .ant-tabs-tab:focus-visible {
                outline: 0;
            }
            .ant-tabs-ink-bar {
                background-color: #f22f46;
                width: 72px;
            }
            @media (max-width: ${(p) => p.theme.breakPoints.breakOnlyMobile}) {
                h3 {
                    font-size: 14px;
                }
                .search-input {
                    input::placeholder {
                        width: 170px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }
        }
    }
    .right-col {
        flex: 3;
        @media (max-width: ${(p) => p.theme.breakPoints.breakOnlyMobile}) {
            display: none;
        }
    }
    .search-result {
        background: #f7f7f8;
        /* padding: 8px; */
    }
    .search-member {
        display: flex;
        column-gap: 16px;
        margin-bottom: 28px;
        .search-input {
            height: 48px;
            background: #ffffff;
            border: 0.5px solid #a5a5a5;
            border-radius: 5px;
            &:hover {
                border: 0;
            }
        }
        .ant-input-suffix {
            i {
                font-size: 24px;
            }
        }
        .search-btn {
            border-radius: 4px;
            padding: 0;
            .btn-text {
                margin-left: 5px;
            }
            @media (max-width: ${(p) => p.theme.breakPoints.breakOnlyMobile}) {
                width: 49px !important;
                .btn-text {
                    display: none;
                }
            }
        }
    }
`;
