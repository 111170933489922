import { IconDelete, SharedButtonSub, SharedCKEditor, SharedInput, StyledCancelButton, StyledCard } from "@components";
import { useNotify } from "@utils";
import { Select, Switch } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IInfoData } from "src/Interfaces/homeMenu";
import styled from "styled-components";
import { ModuleUploadFile, ModuleUploadImage } from "../form";
import { ModuleUploadFileWithText } from "../form/UploadFileWithText";
import { ModuleChangeTabModal } from "./changeTabModal";

interface IProps {
  infoProps: IInfoData;
  menuTab: number;
  handleChangeData: () => void;
  notSelectType: any;
  setNotSelectType: (data: any) => void;
  saveInfoDetail: (infoDetail: IInfoData) => void;
  openChangeTab: boolean;
  onSaveChangeTab: (infoDetail: IInfoData) => void;
  notSaveChangeTab: () => void;
}

export const ModuleSectionDetail = (props: IProps) => {
  const {
    infoProps,
    menuTab,
    handleChangeData,
    notSelectType,
    setNotSelectType,
    saveInfoDetail,
    openChangeTab,
    onSaveChangeTab,
    notSaveChangeTab,
  } = props;
  const { t } = useTranslation();
  const { Option } = Select;
  const { error, success } = useNotify();
  const [infoDetail, setInfoDetail] = useState<IInfoData>();
  const [selectIndex, setSelectIndex] = useState<number>(0);
  // const [notSelectType, setNotSelectType] = useState<number[]>([]);

  useEffect(() => {
    if (infoProps) {
      setInfoDetail(infoProps);
      console.log('props', infoProps);
    }
  }, [infoProps]);

  const confirmChangeTab = () => {
    if (infoDetail) {
      onSaveChangeTab(infoDetail);
    }
  };

  const setSwitch = () => {
    let arr = infoDetail;
    if (arr !== undefined) {
      arr.is_enable = !arr?.is_enable;
      setInfoDetail({ ...arr });
      handleChangeData();
    }
  };

  const changeIndex = (oldIndex: number, newIndex: number) => {
    if (infoDetail && infoDetail?.detail_list?.length > 0) {
      const length = infoDetail?.detail_list?.length;
      // console.log('length', length);
      // console.log('old ', oldIndex, 'new', newIndex - 1);
      let arr = infoDetail.detail_list;
      let item = infoDetail.detail_list[oldIndex];
      arr = arr.filter((data, id: number) => id !== oldIndex);
      arr.splice(newIndex - 1, 0, item);
      setInfoDetail({
        ...infoDetail,
        detail_list: [...arr]
      });
      success(t("ย้ายที่สำเร็จ"));
    }
  };

  const changeInfoValues = (e: any) => {
    if (infoDetail) {
      let arr = infoDetail;
      arr[e.target.id] = e.target.value;
      setInfoDetail({ ...arr });
      handleChangeData();
    }
  };

  const deleteDetail = (index: number) => {
    let arr = infoDetail;
    if (arr?.detail_list[index]) {
      arr.detail_list = arr.detail_list.filter((data, id: number) => id !== index);
      setInfoDetail({ ...arr });
      handleChangeData();
    }
  };

  const uploadSectionImg = (img: string, index?: number) => {
    let arr = infoDetail;
    if (index !== undefined) {
      if (arr?.detail_list[index]) {
        handleChangeData();
        arr.detail_list[index].image_url = img;
        setInfoDetail({ ...arr });
      }
    }
  };
  const handleChangeGuideLine = (event: any, editors: any) => {
    let arr = infoDetail;
    const data = editors.getData();
    if (arr?.detail_list[selectIndex]) {
      handleChangeData();
      arr.detail_list[selectIndex].html_text = data;
      setInfoDetail({ ...arr });
    }
  };

  const clickIsSingle = (check: boolean, index: number) => {
    let arr = infoDetail;
    if (arr?.detail_list[index]) {
      handleChangeData();
      arr.detail_list[index].fouth_type_data.is_single = check;
      setNotSelectType([...notSelectType.filter((num) => num !== index)]);
      setInfoDetail({ ...arr });
    }
  };

  const onChangeFouthTitle = (e: any) => {
    let num = parseInt(e.target.id);
    let data = e.target.value;
    let arr = infoDetail;
    if (arr?.detail_list[num]) {
      handleChangeData();
      arr.detail_list[num].fouth_type_data.title = data;
      setInfoDetail({ ...arr });
    }
  };

  const onGetFileFouth = (file: string, index: number) => {
    let fileUrlSplit = file.split("/");
    let pdfName = fileUrlSplit[fileUrlSplit.length - 1];
    let nameOnly = pdfName.split("-").slice(1).join();

    let arr = infoDetail;
    if (arr?.detail_list[index]) {
      arr.detail_list[index].fouth_type_data.docs = [{
        file_url: file,
        text: nameOnly
      }];
      setInfoDetail({ ...arr });
      handleChangeData();
    }
  };

  const onGetFileFouthMulti = (file: string, parentIndex: number, childIndex: number) => {
    let arr = infoDetail;
    if (arr?.detail_list[parentIndex]) {
      handleChangeData();
      arr.detail_list[parentIndex].fouth_type_data.docs[childIndex].file_url = file;
      setInfoDetail({ ...arr });
    }
  };

  const changeTextFouthMulti = (e: any, parentIndex: number, childIndex: number) => {
    let data = e.target.value;
    let arr = infoDetail;
    if (arr?.detail_list[parentIndex]) {
      handleChangeData();
      arr.detail_list[parentIndex].fouth_type_data.docs[childIndex].text = data;
      setInfoDetail({ ...arr });
    }
    // console.log(e.target.value, parentIndex, childIndex);
  };

  const deleteFouthDocs = (parentIndex: number, childIndex: number) => {
    let arr = infoDetail;
    if (arr?.detail_list[parentIndex]?.fouth_type_data.docs) {
      arr.detail_list[parentIndex].fouth_type_data.docs = arr.detail_list[parentIndex].fouth_type_data.docs.filter((data, id: number) => id !== childIndex);
      setInfoDetail({ ...arr });
      handleChangeData();
    }
  };
  const addFouthDocs = (index: number) => {
    let arr = infoDetail;
    if (arr?.detail_list[index]) {
      handleChangeData();
      arr.detail_list[index].fouth_type_data.docs.push({
        file_url: "",
        text: "",
      });
      setInfoDetail({ ...arr });
    }
  };

  const setFifthValue = (arr: IFifthItem[], amount: number, parentIndex: number, isEdit: boolean) => {
    let arrList = arr;
    if (amount === 3) {
      arrList = arr.slice(0, 3);
      // console.log(arr)
    }
    let infoArr = infoDetail;
    if (infoArr?.detail_list[parentIndex]) {
      infoArr.detail_list[parentIndex].fifth_type_data = arrList;
      setInfoDetail({ ...infoArr });
      if (isEdit) {
        handleChangeData();
      }
    }
  };

  const clickSaveInfo = () => {
    if (infoDetail) {
      saveInfoDetail(infoDetail);
    }
  };

  return (
    <StyledPageBody>
      <ModuleChangeTabModal
        openChangeTab={openChangeTab}
        onSaveChangeTab={confirmChangeTab}
        notSaveChangeTab={notSaveChangeTab}
      />
      <StyledCard maxWidth="100%">
        <StyledMenuHeader>
          <div className="header">
            <p className="title">
              รายละเอียด
            </p>
            <div>
              ปิดกั้นการมองทั้งหมด
              <Switch
                className="switch"
                onClick={setSwitch}
                checked={!infoDetail?.is_enable}
              />
              <SharedButtonSub
                type="default"
                style={{
                  fontSize: "16px",
                  lineHeight: "0px",
                  height: "40px",
                }}
                text={t("page.save")}
                htmlType="submit"
                onClick={clickSaveInfo}
              />
            </div>
          </div>
        </StyledMenuHeader>
        <SharedInput
          label={t("page.homeMenu.header_field")}
          name="header"
          onChange={changeInfoValues}
          value={infoDetail?.header}
          parentClassName="mb-6"
        />
        <br />
      </StyledCard>
      {infoDetail?.detail_list && infoDetail.detail_list.length > 0 &&
        infoDetail?.detail_list?.map((item, index) => {
          return (
            <StyledCard maxWidth="100%" key={index}>
              <StyledCardWrap className="card-wrap" key={index}>
                <div className="title-flex">
                  <div className="title">
                    {t("page.homeMenu.desc_field")} {index + 1}
                  </div>
                  <p onClick={() => deleteDetail(index)}>
                    <IconDelete color="#6C7084" size={20} />
                  </p>
                </div>
                <div className="select-index">
                  <p className="title">ลำดับที่</p>
                  <Select
                    value={index + 1}
                    onChange={(e) => changeIndex(index, e)}
                  // onChange={ }
                  >
                    {infoDetail?.detail_list?.map((selectItem, selectIndex: number) => {
                      return (
                        <Option value={selectIndex + 1} key={index}>{selectIndex + 1}</Option>
                      );
                    })}
                  </Select>
                </div>
                {item.detail_type === "1" &&
                  <>
                    <ModuleUploadImage
                      title=""
                      images={item.image_url}
                      IsCanResetImage={true}
                      index={index}
                      onResetImage={() => {
                        uploadSectionImg("", index);
                      }}
                      handleGetImage={uploadSectionImg}
                    />
                    <SharedCKEditor
                      clickEditor={() => setSelectIndex(index)}
                      index={index}
                      editor={item.html_text}
                      handleChangeEditor={handleChangeGuideLine}
                      name='desc'
                    />
                  </>
                }
                {item.detail_type === "2" &&
                  <>
                    <ModuleUploadImage
                      title=""
                      images={item.image_url}
                      IsCanResetImage={true}
                      index={index}
                      onResetImage={() => {
                        uploadSectionImg("", index);
                      }}
                      handleGetImage={uploadSectionImg}
                    />
                    <SharedCKEditor
                      clickEditor={() => setSelectIndex(index)}
                      index={index}
                      editor={item.html_text}
                      handleChangeEditor={handleChangeGuideLine}
                      name='desc'
                    />
                  </>
                }
                {item.detail_type === "3" &&
                  <>
                    <ModuleUploadImage
                      title=""
                      images={item.image_url}
                      IsCanResetImage={true}
                      index={index}
                      onResetImage={() => {
                        uploadSectionImg("", index);
                      }}
                      handleGetImage={uploadSectionImg}
                    />
                    <SharedCKEditor
                      clickEditor={() => setSelectIndex(index)}
                      index={index}
                      editor={item.html_text}
                      handleChangeEditor={handleChangeGuideLine}
                      name='desc'
                    />
                  </>
                }
                {item.detail_type === "4" &&
                  <StyledSection4>
                    {notSelectType.find(num => {
                      return num === index;
                    }) !== undefined ?
                      <div className="button-section">
                        <StyledCancelButton
                          type="sub"
                          text="+ เพิ่มเอกสารประเภทชุดเดียว"
                          htmlType="button"
                          onClick={() => clickIsSingle(true, index)}
                        />
                        <p className="button-or">หรือ</p>
                        <StyledCancelButton
                          type="sub"
                          text="+ เพิ่มเอกสารประเภทกลุ่ม"
                          htmlType="button"
                          onClick={() => clickIsSingle(false, index)}
                        />
                      </div>
                      :
                      (item.fouth_type_data.is_single ?
                        <div className="show-section4">
                          <SharedInput
                            label="ชื่อเอกสาร (หัวข้อหลัก)*"
                            name={`${index}`}
                            onChange={onChangeFouthTitle}
                            value={item.fouth_type_data.title}
                            errors={item.fouth_type_data.title}
                            parentClassName="mb-6"
                          />
                          <ModuleUploadFile
                            title=""
                            handleGetFile={onGetFileFouth}
                            index={index}
                            key={index}
                            defaultFileName={item.fouth_type_data.docs[0] ? item.fouth_type_data.docs[0].text : ""}
                            // defaultFileName={item.fouth_type_data.docs[0]?.file_url || ""}
                            placeholder={t("page.bidding.please_select_file")}
                            btnText={t("page.bidding.upload_file")}
                          />
                        </div>
                        :
                        <div className="show-section4">
                          <SharedInput
                            // label={t("page.bidding.title_headline")}
                            label="ชื่อเอกสาร (หัวข้อหลัก)*"
                            // descLabel={t("page._2_100_characters")}
                            name={`${index}`}
                            onChange={onChangeFouthTitle}
                            value={item.fouth_type_data.title}
                            errors={item.fouth_type_data.title}
                            // touched={touched.title}
                            parentClassName="mb-6"
                          />
                          {item.fouth_type_data.docs.length > 0 &&
                            item.fouth_type_data.docs.map((docsItem, docsIndex) => {
                              return (
                                <ModuleUploadFileWithText
                                  title=""
                                  handleGetFile={onGetFileFouthMulti}
                                  parentIndex={index}
                                  changeText={changeTextFouthMulti}
                                  index={docsIndex}
                                  key={docsIndex}
                                  defaultFileName={docsItem.text}
                                  deleteField={deleteFouthDocs}
                                  // deleteField={deleteFouthDocs}
                                  // defaultFileName={item.fouth_type_data.docs[0]?.file_url || ""}
                                  placeholder={t("page.bidding.please_select_file")}
                                  btnText={t("page.bidding.upload_file")}
                                />

                              );
                            })

                          }
                          <StyledCancelButton
                            type="sub"
                            text="+ เพิ่มเอกสาร"
                            htmlType="button"
                            onClick={() => addFouthDocs(index)}
                          />
                        </div>
                      )
                    }
                  </StyledSection4>
                }
                {item.detail_type === "5" &&
                  <Section5
                    parentIndex={index}
                    setFifthInfo={setFifthValue}
                    fifthList={item.fifth_type_data}
                    menuTab={menuTab}
                  />

                }
              </StyledCardWrap>
            </StyledCard>
          );
        })
      }
    </StyledPageBody>
  );
};


interface ISection5Props {
  parentIndex: number;
  setFifthInfo: (arr: IFifthItem[], amount: number, parentIndex: number, isEdit: boolean) => void;
  fifthList: IFifthItem[];
  menuTab: number;
}
interface IFifthItem {
  image_url: string;
  file_url: string;
  text: string
}

const initFifth: IFifthItem[] = [
  {
    image_url: "",
    file_url: "",
    text: "",
  }, {
    image_url: "",
    file_url: "",
    text: "",
  }, {
    image_url: "",
    file_url: "",
    text: "",
  }, {
    image_url: "",
    file_url: "",
    text: "",
  }, {
    image_url: "",
    file_url: "",
    text: "",
  }, {
    image_url: "",
    file_url: "",
    text: "",
  }
];

const Section5 = (props: ISection5Props) => {
  const { t } = useTranslation();
  const { Option } = Select;
  const [selectValue, setSelectValue] = useState<number>(3);
  const { parentIndex, setFifthInfo, fifthList, menuTab } = props;
  const [arrValue, setArrValue] = useState<IFifthItem[]>(initFifth);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  useEffect(() => {
    setIsEdit(false);
    if (fifthList.length > 0) {
      let arr = fifthList;
      if (fifthList.length === 3) {
        arr.push({
          image_url: "",
          file_url: "",
          text: "",
        });
        arr.push({
          image_url: "",
          file_url: "",
          text: "",
        });
        arr.push({
          image_url: "",
          file_url: "",
          text: "",
        });
        setSelectValue(3);
      } else {
        setSelectValue(6);
      }
      setArrValue([...arr]);
    }
  }, [menuTab]);

  useEffect(() => {
    setFifthInfo(fifthList, selectValue, parentIndex, isEdit);
  }, [selectValue]);


  const handleChange = (value: string) => {
    setIsEdit(true);
    setSelectValue(parseInt(value));
  };

  const uploadSectionImg = (img: string, index?: number) => {
    setIsEdit(true);
    let arr = arrValue;
    if (index !== undefined) {
      if (arr[index]) {
        arr[index].image_url = img;
        setArrValue([...arr]);
        setFifthInfo(arr, selectValue, parentIndex, true);
      }
    }
  };
  const onGetFileFifth = (file: string, index: number) => {
    let arr = arrValue;
    setIsEdit(true);
    if (index !== undefined) {
      if (arr[index]) {
        arr[index].file_url = file;
        setArrValue([...arr]);
        setFifthInfo(arr, selectValue, parentIndex, true);
      }
    }
  };
  const onChangeFifthTitle = (e: any) => {
    setIsEdit(true);
    let index = parseInt(e.target.id);
    let data = e.target.value;
    let arr = arrValue;
    if (index !== undefined) {
      if (arr[index]) {
        arr[index].text = data;
        setArrValue([...arr]);
        setFifthInfo(arr, selectValue, parentIndex, true);
      }
    }
  };
  return (
    <StyledSection5>
      <div className="select-div">
        <p className="title">จำนวนหัวข้อ</p>
        <Select value={selectValue.toString()} onChange={handleChange}>
          <Option value="3">3</Option>
          <Option value="6">6</Option>
        </Select>
      </div>

      {Array.from(Array(selectValue).keys()).map((index: number) => {
        return (
          <div className="value-div" key={index}>
            <div className="img-sec">
              <ModuleUploadImage
                title=""
                images={arrValue[index].image_url}
                IsCanResetImage={true}
                index={index}
                onResetImage={() => {
                  uploadSectionImg("", index);
                }}
                handleGetImage={uploadSectionImg}
              />
            </div>
            <div className="file-sec">
              <SharedInput
                // label={t("page.bidding.title_headline")}
                label={`ชื่อหัวข้อที่ ${index + 1}*`}
                // descLabel={t("page._2_100_characters")}
                name={`${index}`}
                onChange={onChangeFifthTitle}
                value={arrValue[index].text}
                errors={arrValue[index].text}
                // touched={touched.title}
                parentClassName="mb-6"
              />
              <ModuleUploadFile
                title=""
                handleGetFile={onGetFileFifth}
                index={index}
                key={index}
                defaultFileName={arrValue[index].file_url}
                // defaultFileName={item.fouth_type_data.docs[0]?.file_url || ""}
                placeholder={t("page.bidding.please_select_file")}
                btnText={t("page.bidding.upload_file")}
              />
            </div>
          </div>
        );
      })}
    </StyledSection5>
  );
};

const StyledPageBody = styled.div`
  display: grid;
  grid-gap: 15px;
`;

const StyledMenuHeader = styled.div`
    .title {
        font-weight: 700;
        font-size: 35px;
        line-height: 38px;
        color: black;
    }
    .header {
        display: flex;
        justify-content: space-between;
        .switch {
            margin: 0px 10px;
        }
    }
    .disabled {
        background-color: #e4e4e4;
    }
`;

const StyledCardWrap = styled.div`
    .title-flex{
        display: flex;
        justify-content: space-between;
        .title{
            font-weight: 500;
            font-size: 25px;
            line-height: 38px;
        }
    }
    .select-index {
      width: 100px;
      padding-bottom: 30px;
      .title {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #29304D;
            padding: 0px;
            margin-bottom: 10px;
        }
    }

`;


const StyledSection4 = styled.div`
  /* max-width: 65vw; */
  .button-section{
    display: grid;
    grid-template-columns: 2fr 1fr 2fr;
    .button-or{
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        margin: auto;
    }
  }  
  .show-section4{
    border: 1px solid #8a959e9f;
    border-radius: 8px;
    padding: 20px;
    width: 100%;
  }
`;


const StyledSection5 = styled.div`
    .select-div {
        width: 100px;
        .title {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #29304D;
            padding: 0px;
            margin-bottom: 10px;
        }
    }
    .value-div {
        display: grid;
        grid-template-columns: 1fr 3fr;
        grid-gap: 20px;
    }
    .file-sec{
      width: 50vw !important;
    }
`;

