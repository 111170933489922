import { useTranslation } from "react-i18next";
import { Col, Row, Menu, Dropdown, Button } from "antd";
import { MoreHorizontal } from "react-feather";
import { IActivityCategory, INewsCategory } from "@interfaces";
import { orgApi } from "@api";
import { enumNewsType } from "@configs";
import { getActivityCategory, getCategories, getDivisions, selectAuth, setLoading } from "@redux";
import { useDispatch, useSelector } from "react-redux";
import { useNotify } from "@utils";
interface IProps {
    item: INewsCategory;
    itemJson: string;
    index: number;
    newType?: enumNewsType;
    editCategory: (params: IActivityCategory) => void;
}

export const ComponentRowNewsCategory = (props: IProps) => {
    //hooks
    const { t } = useTranslation();
    const { success, error } = useNotify();
    const organization_id = useSelector(selectAuth).auth?.organization_data?._id;
    //props
    const { item, editCategory } = props;
    const dispatch = useDispatch();

    const handleDelete = async () => {
        dispatch(setLoading(true));
        try {
            const { data } = await orgApi.deleteCat({
                organization_id: organization_id || "",
                news_category_id: item._id || "",
            });
            if (data.error === "0") {
                success(data.msg);
                dispatch(
                    getCategories({
                        organization_id: organization_id || "",
                    })
                );
                dispatch(setLoading(false));
            } else {
                error(data.msg);
                dispatch(setLoading(false));
            }
        } catch (err: any) {
            dispatch(setLoading(false));
        }
    };

    const menu = (
        <Menu>
            <Menu.Item key={1} onClick={handleDelete}>
                <div className="duplicate-btn">{t("page.delete")}</div>
            </Menu.Item>
        </Menu>
    );

    return (
        <div className="table-element">
            <div className="table-row">
                <Row
                    style={{ height: "100%" }}
                    gutter={24}
                    type="flex"
                    align="middle"
                    justify="space-between"
                >
                    <Col className="col-item" span={12}>
                        <p className="row-name" onClick={() => editCategory(item)}>
                            {item.category_name || t("page.empty_text")}
                        </p>
                    </Col>

                    <Col className="option-dropdown col-item last-item" span={12}>
                        <Dropdown
                            //@ts-ignore
                            getPopupContainer={(trigger) => trigger.parentNode}
                            overlay={menu}
                            trigger={["click"]}
                            placement="bottomRight"
                        >
                            <Button style={{ border: "unset" }}>
                                <MoreHorizontal />
                            </Button>
                        </Dropdown>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
