import styled from "styled-components";
export const StyledSearchLayout = styled.div`
    .filter {
        background: #fff;
        padding: 20px;
        border-radius: 4px;
        margin-bottom: 26px;
        display: flex;
        justify-content: space-between;
        .search-row {
            flex: 11;
            display: flex;
            align-items: center;
            column-gap: 10px;
            .col-item {
                flex: 1;
            }
        }

        .filter-element {
            flex: 2;
            display: flex;
            align-items: center;
            column-gap: 10px;
            .label {
                p {
                    @media (max-width: ${(p) => p.theme.breakPoints.breakMacLaptop}) {
                        text-overflow: ellipsis;
                        max-width: 70px;
                        white-space: nowrap;
                    }
                    @media (max-width: ${(p) => p.theme.breakPoints.break13Laptop}) {
                        max-width: 80px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }
            .input {
                .search-input {
                    width: 200px;
                    @media (max-width: ${(p) => p.theme.breakPoints.breakMacLaptop}) {
                        width: 150px;
                    }
                }
                .ant-select {
                    width: 200px;
                    @media (max-width: ${(p) => p.theme.breakPoints.breakMacLaptop}) {
                        width: 150px;
                    }
                    @media (max-width: ${(p) => p.theme.breakPoints.breakOnlyMobile}) {
                        width: 100%;
                    }
                }
            }
            @media (max-width: ${(p) => p.theme.breakPoints.breakOnlyMobile}) {
                .input {
                    width: 100%;
                }
            }
            p {
                margin: 0;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                color: #646464;
            }
            .ant-input-search {
                height: 44px;
                width: 100%;
            }
            .ant-select {
                /* min-width: 160px; */
                width: 100%;
                .ant-select-selection--single {
                    height: 44px;
                    .ant-select-selection__rendered {
                        height: 100%;
                        align-items: center;
                        display: flex;
                    }
                }
            }
            .ant-calendar-picker-input {
                height: 44px;
            }
            &.result {
                display: flex;
                justify-content: end;
                p {
                    display: flex;
                    column-gap: 10px;
                }
            }
        }
    }
`;
