import { SharedButtonSub, StyledCancelButton } from "@components";
import { Modal } from "antd";
import styled from "styled-components";

interface IProps {
  openChangeTab: boolean;
  onSaveChangeTab: () => void;
  notSaveChangeTab: () => void;
}

export const ModuleChangeTabModal = (props: IProps) => {
  const {
    openChangeTab,
    onSaveChangeTab,
    notSaveChangeTab,
  } = props;
  return (
    <Modal
      visible={openChangeTab}
      closable={false}
      footer={[
        <StyledCancelButton
          type="sub"
          text="ยกเลิก"
          style={{
            fontSize: "16px",
            lineHeight: "0px",
            height: "52px",
          }}
          htmlType="button"

          onClick={notSaveChangeTab}
        />,
        <SharedButtonSub
          type="default"
          style={{
            fontSize: "16px",
            lineHeight: "0px",
            height: "52px",
          }}
          text="ตกลง"
          htmlType="submit"
          onClick={onSaveChangeTab}
        />
      ]}
    >
      <StyledModal>
        <br />
        <p className="title">
          คุณต้องการ "บันทึก" ข้อมูลก่อนเปลี่ยนเมนูหรือไม่
        </p>
        <p className="sub-title">โปรดยืนยันการบันทึก</p>
      </StyledModal>

    </Modal>
  );
};


const StyledModal = styled.div`
    .title {
        font-weight: 700;
        font-size: 25px;
        line-height: 38px;
        text-align: center;
        color: #000000;
        margin-bottom: 0px;
    }
    .sub-title {
        font-size: 14px;
        line-height: 21px;
        color: #8A959E;
        text-align: center;
    }
    .grid-three{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 20px;
        .item {
            .item-img {
                margin-top: 10px;
                width: 230px;
            }
        }
    }
`;
