export const MemberAddIcon = () => (
    <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask
            id="mask0_2004_24374"
            // style="mask-type:alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="81"
            height="80"
        >
            <rect x="0.5" width="80" height="80" fill="#C4C4C4" />
        </mask>
        <g mask="url(#mask0_2004_24374)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M53.5782 51.9367L63.4221 67.5415C64.1504 68.696 63.8049 70.2222 62.6504 70.9505C61.4959 71.6788 59.9696 71.3333 59.2414 70.1788L49.3975 54.5741L53.5782 51.9367Z"
                fill="#E1E1E1"
            />
            <path
                d="M60.8515 19.796C68.4465 31.8357 64.8433 47.7527 52.8036 55.3477C40.7639 62.9426 24.8469 59.3395 17.2519 47.2998C9.65694 35.2601 13.2601 19.3431 25.2998 11.7481C37.3395 4.15311 53.2565 7.75626 60.8515 19.796Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M51.2966 52.9587C62.0168 46.196 65.2251 32.0233 58.4625 21.303C51.6998 10.5827 37.5271 7.37445 26.8069 14.1371C16.0866 20.8997 12.8783 35.0725 19.6409 45.7927C26.4036 56.513 40.5763 59.7213 51.2966 52.9587ZM52.8036 55.3477C64.8433 47.7527 68.4465 31.8357 60.8515 19.796C53.2565 7.75626 37.3395 4.15311 25.2998 11.7481C13.2601 19.3431 9.65694 35.2601 17.2519 47.2998C24.8469 59.3395 40.7639 62.9426 52.8036 55.3477Z"
                fill="#E1E1E1"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M45.3949 27.2356C45.3949 31.0623 42.3666 34.1297 38.5888 34.1297C34.811 34.1297 31.7827 31.0623 31.7827 27.2356C31.7827 23.4089 34.811 20.3414 38.5888 20.3414C42.3666 20.3414 45.3949 23.4089 45.3949 27.2356ZM28.3027 41.9372C28.3027 38.3928 33.0401 37.5057 38.5887 37.5057C44.1662 37.5057 48.8748 38.4234 48.8748 41.9678C48.8748 45.5122 44.136 46.3994 38.5887 46.3994C33.0113 46.3994 28.3027 45.4802 28.3027 41.9372Z"
                fill="#A5A5A5"
            />
            <path
                d="M54.1668 28.5602H52.3121V26.7468C52.3121 25.9727 51.6912 25.3414 50.9261 25.3414C50.1627 25.3414 49.5402 25.9727 49.5402 26.7468V28.5602H47.6887C46.9236 28.5602 46.3027 29.1914 46.3027 29.9656C46.3027 30.7398 46.9236 31.371 47.6887 31.371H49.5402V33.186C49.5402 33.9602 50.1627 34.5914 50.9261 34.5914C51.6912 34.5914 52.3121 33.9602 52.3121 33.186V31.371H54.1668C54.9303 31.371 55.5527 30.7398 55.5527 29.9656C55.5527 29.1914 54.9303 28.5602 54.1668 28.5602Z"
                fill="#F22F46"
                stroke="white"
                strokeWidth="1.4"
            />
        </g>
    </svg>
);
