import styled from "styled-components";
import { memo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Upload, Button, Row, Col, Modal, Input, Spin } from "antd";
import { Skeleton } from "@mui/material";
import { UploadChangeParam } from "antd/lib/upload/interface";

import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    rectSortingStrategy,
    //@ts-ignore
} from "@dnd-kit/sortable";

import { biddingAPI, newsAPI } from "@api";

import { showErrorMessage, useNotify } from "@utils";
import { SortableItem } from "@modules";
import { UploadImageIcon } from "@components";
import { UploadFileIcon } from "src/Components/icon/UploadFile";
import { IFileUrl, IUploadFiles } from "@interfaces";

interface IProps {
    index: number;
    handleGetFile: (file: IUploadFiles) => void;
    title?: string | JSX.Element;
    defaultFileName?: IFileUrl[];
    lable?: string;
    placeholder?: string;
    btnText?: string;
    onChangePdfTitle: (value: string, index: number) => void;
}

export const ModuleUploadFiles = memo((props: IProps) => {
    const { Dragger } = Upload;
    //page hook
    const { t } = useTranslation();
    const { error } = useNotify();
    // props
    const {
        // handleGetImage,
        // images,
        btnText,
        placeholder,
        handleGetFile,
        index,
        title,
        defaultFileName,
        onChangePdfTitle,
    } = props;

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onUploadFile = async (file: any) => {
        setIsLoading(true);
        const formData = new FormData();
        //@ts-ignore
        formData.append("file", file);
        try {
            const res = await biddingAPI.uploadFile(formData);

            handleGetFile(res.data.result);
        } catch (err: any) {
            if (err.response) {
                error(showErrorMessage(err.response));
            } else error(t("message.error"));
        } finally {
            setIsLoading(false);
        }
    };

    const uploadProps = {
        name: "file",
        accept: ".pdf",
        maxCount: 1,
        beforeUpload() {
            return false;
        },
        onChange({ file }) {
            if (file.status !== "uploading") {
                onUploadFile(file);
            }
        },
        showUploadList: false,
    };
    const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>, index) => {
        if (defaultFileName) {
            onChangePdfTitle(e.target.value, index);
        }
    };

    return (
        <>
            <div className="title">{title}</div>
            <StyledContainer>
                <div className="file-bg">
                    {isLoading && <Spin />}
                    <p className="file-name">
                        {defaultFileName?.map((item, index) => (
                            <div>
                                <Input
                                    key={index}
                                    // value={item.name}
                                    onChange={(e) => handleChangeName(e, index)}
                                    defaultValue={item.name}
                                />
                                <Upload multiple={false} {...uploadProps}>
                                    <button
                                        className="file-btn"
                                        // onClick={clickUpload}
                                        type="button"
                                    >
                                        <UploadFileIcon /> &#160;
                                        <span>{btnText}</span>
                                    </button>
                                </Upload>
                            </div>
                        ))}
                    </p>
                </div>
            </StyledContainer>
        </>
    );
});

const StyledContainer = styled.div`
    background-color: #f7f7f7;
    margin-bottom: 16px;
    .file-bg {
        padding: 20px;
        display: flex;
        justify-content: space-between;
        column-gap: 16px;
        .file-name {
            margin: auto 0;
            flex: 2;
            .ant-input {
                height: 44px;
            }
        }
    }
    .file-btn {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        background-color: #0d6efd;
        color: white;
        font-weight: 500;
        font-size: 16px;
        padding: 10px 20px;
        border: none;
        border-radius: 4px;
    }
    .ant-upload .ant-upload-btn {
        padding: 40px;
        background-color: #f7f7f8;
        p {
            font-size: 12px;
            text-align: center;
            color: #6c7084;
        }
    }
    .btn-drag {
        &:hover {
            color: white !important;
            background: #6c7084;
        }
    }
    .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
        border-color: #6c7084;
    }
    .ant-radio-wrapper {
        display: flex;
        align-items: center;
        height: 48px;
    }
    .preview-field {
        padding: 40px 20px;
        position: relative;
        border: 1px dashed #6c7084;
        border-radius: 12px;
        .img-wrap {
            overflow: hidden;
            position: relative;
            border-radius: 10px;
            cursor: pointer;
            height: 100%;
            img {
                object-fit: cover;
                width: 100%;
                height: 136px;
                object-position: center;
                @media (max-width: ${(p) => p.theme.breakPoints.breakOnlyMobile}) {
                    height: 250px;
                }
            }
            .img-layer {
                position: absolute;
                z-index: 1;
                background-color: transparent;
                width: 100%;
                height: 100%;
                :hover {
                    background-color: rgba(68, 61, 65, 0.45);
                }
                :hover .icon {
                    display: block;
                    z-index: 100;
                }
                .icon {
                    display: none;
                }
                .icon-full-screen {
                    position: absolute;
                    left: 10px;
                    top: 10px;
                }
                .icon-delete {
                    position: absolute;
                    right: 10px;
                    top: 10px;
                }
            }
        }
        .button-upload-preview {
            overflow: hidden;
            position: absolute;
            right: 20px;
            bottom: 14px;
            border: none;
            cursor: pointer;
            button {
                border: none;
                font-size: 14px;
                font-weight: 600;
                background-color: transparent !important;
                :hover,
                :active,
                :focus {
                    color: rgba(0, 0, 0, 0.65);
                }
            }
            input {
                position: absolute;
                z-index: 1;
                opacity: 0;
                cursor: pointer;
            }
        }
    }
    .ant-upload .ant-upload-btn {
        padding: 40px;
        background-color: #f7f7f8;
        p {
            font-size: 12px;
            text-align: center;
            color: #6c7084;
        }
    }
    .btn-drag {
        &:hover {
            color: white !important;
            background: #6c7084;
        }
    }
    .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
        border-color: #6c7084;
    }
    .drag-field {
        height: 100%;
    }
    .ant-upload-drag-container {
        .label {
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            color: #a5a5a5;
            margin-top: 16px;
        }
    }
    @media (max-width: ${(p) => p.theme.breakPoints.breakOnlyMobile}) {
        .preview-field {
            padding: 20px;
            .img-wrap {
                img {
                    height: 100px;
                    width: 100px;
                }
            }
        }
        .ant-upload-btn {
            padding: 20px;
        }
    }
`;
