import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { InfoPage, StyledNewDetail } from "@components";
import { IProfileReward, IPaginationNext } from "@interfaces";
import { PAGINATION, PAGE_START, enumProfileReward, enumProcessStatus } from "@configs";
import { useNotify, usePermission } from "@utils";
import { rewardAPI } from "@api";
import { setLoading, selectAuth } from "@redux";
import { PageRewardCreate } from "./Create";
import { ComponentRewardInfo } from "src/Modules/reward/Info";
import { useParams } from "react-router";
const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: PAGINATION,
};
export const PageRewardUpdate = () => {
    const org_id = useSelector(selectAuth).auth?.organization_data?._id;
    const permission = useSelector(selectAuth).permission;
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch();
    const { warning } = useNotify();
    const { t } = useTranslation();
    // page state
    const [viewTab, setViewTab] = useState(0);
    const [pagination, setPagination] = useState<IPaginationNext>(INITAL_PAGE);
    const [profileRewrads, setProfileRewards] = useState<IProfileReward[]>([]);
    const [receiveType, setReceiveType] = useState<enumProfileReward>();
    const [receiveStatus, setReceiveStatus] = useState<enumProcessStatus>();

    usePermission({ permission: permission?.permission_list.claim_reward.is_view });
    // variable
    useEffect(() => {
        fetchProfileRewards(pagination, receiveType, receiveStatus);
    }, [pagination, receiveType, receiveStatus]);
    const fetchProfileRewards = async (
        pagination: IPaginationNext,
        receiveType: enumProfileReward | undefined,
        receiveStatus: enumProcessStatus | undefined
    ) => {
        dispatch(setLoading(true));
        try {
            const response = await rewardAPI.getProfileRewards({
                organization_id: org_id || "",
                reward_id: id || "",
                ...pagination,
                ...(receiveType && { receive_type: receiveType }),
                ...(receiveStatus && { status: receiveStatus }),
            });
            const data: IProfileReward[] = response.data.result;
            setProfileRewards(data);
            dispatch(setLoading(false));
        } catch (err: any) {
            if (err) {
                warning(err.msg);
                dispatch(setLoading(false));
            }
        } finally {
            dispatch(setLoading(false));
        }
    };
    return (
        <StyledNewDetail>
            <InfoPage>
                <div className="form-wrap">
                    <div className="page-header">
                        <h3>{t("page.privilege_details")}</h3>
                    </div>
                    <header className="page-tab">
                        <button
                            type="button"
                            className={viewTab === 0 ? "active" : ""}
                            onClick={() => setViewTab(0)}
                        >
                            {t("page.privileges")}
                        </button>
                        <button
                            type="button"
                            className={viewTab === 1 ? "active" : ""}
                            onClick={() => setViewTab(1)}
                        >
                            {t("page.edit_special_rights_information")}
                        </button>
                    </header>
                    {viewTab === 0 ? (
                        <ComponentRewardInfo profileRewrads={profileRewrads} />
                    ) : (
                        <PageRewardCreate />
                    )}
                </div>
            </InfoPage>
        </StyledNewDetail>
    );
};
