export const HomeDecsIcon = () => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10.6248 3.5305L10.647 3.60002H10.72H17C17.7691 3.60002 18.5068 3.90556 19.0506 4.44941C19.5945 4.99327 19.9 5.73089 19.9 6.50002V16.5C19.9 17.2691 19.5945 18.0068 19.0506 18.5506C18.5068 19.0945 17.7691 19.4 17 19.4H3C2.23087 19.4 1.49325 19.0945 0.94939 18.5506C0.405535 18.0068 0.1 17.2691 0.1 16.5V3.50002C0.1 2.73089 0.405535 1.99327 0.94939 1.44941C1.49325 0.905557 2.23087 0.600022 3 0.600022L7.56 0.600023L7.56039 0.600022C8.162 0.597702 8.74944 0.782546 9.24132 1.12894C9.7328 1.47505 10.1046 1.96533 10.3052 2.53195L10.6248 3.5305ZM8.60499 3.14875L8.60474 3.14802L7.56 2.50002V2.40002H7.55988H3C2.70826 2.40002 2.42847 2.51592 2.22218 2.72221C2.01589 2.9285 1.9 3.20828 1.9 3.50002V16.5C1.9 16.7918 2.01589 17.0716 2.22218 17.2778C2.42847 17.4841 2.70826 17.6 3 17.6H17C17.2917 17.6 17.5715 17.4841 17.7778 17.2778C17.9841 17.0716 18.1 16.7918 18.1 16.5V6.50002C18.1 6.20828 17.9841 5.92849 17.7778 5.72221C17.5715 5.51592 17.2917 5.40002 17 5.40002L10 5.40002L9.99974 5.40002C9.81106 5.40051 9.627 5.34169 9.47358 5.23186C9.32024 5.12211 9.20526 4.96697 9.14485 4.78834C9.14481 4.78824 9.14478 4.78813 9.14474 4.78802L8.60499 3.14875Z"
            fill="#0D6EFD"
            stroke="white"
            strokeWidth="0.2"
        />
    </svg>

);