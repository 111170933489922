import { useEffect, memo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Row, Col, Select, Checkbox } from "antd";
import moment from "moment";
import { IReward, IRewardCreate } from "@interfaces";
import {
    SharedCKEditor,
    StyledSubmitButton,
    StyledCancelButton,
    StyledCard,
    SharedInput,
    ComponentPeriod,
    ComponentRewardPreview,
} from "@components";
import { useYup } from "@validations";
import { setLoading, selectAuth } from "@redux";
import { MutlipleModuleUploadImage } from "@modules";
import { useNotify } from "@utils";
import {
    enumTimeLimit,
    PATH_PRIVILEGES_CREATE,
    PATH_PRIVILEGES,
    timeLimit,
    PATH_CLAIM,
} from "@configs";
import { rewardAPI } from "@api";

interface IProps {
    reward: IReward | undefined;
}
const newDate = new Date();
const initData: IRewardCreate = {
    organization_id: "",
    name: "",
    description: "",
    image_url_list: [],
    date_from: newDate.toISOString(),
    date_to: new Date(newDate.setFullYear(newDate.getFullYear() + 1)).toISOString(),
    client_limit: 1,
    client_limit_type: enumTimeLimit.ALL_TIME,
    reward_limit: 100,
    reward_limit_type: enumTimeLimit.ALL_TIME,
    is_enable: true,
    condition: "",
    is_self_receive: true,
    is_delivery: true,
};
const { Option } = Select;
export const ModuleRewardForm = memo((props: IProps) => {
    //hook
    const { YupReward } = useYup();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const { error, success } = useNotify();
    const organization_id = useSelector(selectAuth).auth?.admin_data?.organization_id;
    const pathName = window.location.pathname;
    const createReward = Yup.object().shape(YupReward);
    const { reward } = props;
    useEffect(() => {
        if (reward) {
            setFieldValue("name", reward.name);
            setFieldValue("description", reward.description);
            setFieldValue("date_to", reward.date_to);
            setFieldValue("condition", reward.condition);
            setFieldValue("client_limit", reward.client_limit);
            setFieldValue("client_limit_type", reward.client_limit_type);
            setFieldValue("reward_limit", reward.reward_limit);
            setFieldValue("reward_limit_type", reward.reward_limit_type);
            setFieldValue("is_enable", reward.is_enable);
            setFieldValue("is_self_receive", reward.is_self_receive);
            setFieldValue("is_delivery", reward.is_delivery);
            setFieldValue("image_url_list", reward.image_url_list);
            if (reward.date_from === null) {
                setFieldValue("date_from", newDate.toISOString());
            } else {
                setFieldValue("date_from", reward.date_from);
            }
        }
    }, [reward]);
    const handleRewardSubmit = async (values: IRewardCreate) => {
        dispatch(setLoading(true));
        try {
            const payload = {
                ...values,
                organization_id: organization_id || "",
            };
            if (pathName === PATH_PRIVILEGES_CREATE) {
                const res = await rewardAPI.create(payload);
                if (res.data.error === "1") {
                    error(t(res.data.msg));
                } else {
                    success(t(res.data.msg));
                    history.push(PATH_PRIVILEGES);
                }
            } else {
                const res = await rewardAPI.update({ ...payload, ...{ reward_id: id } });
                if (res.data.error === "1") {
                    error(t(res.data.msg));
                } else {
                    success(t(res.data.msg));
                    history.push(PATH_PRIVILEGES);
                }
            }
        } catch (err: any) {
            if (err) {
                error(err.msg);
            } else error(t("message.error"));
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleCancel = () => {
        history.push(PATH_CLAIM);
    };

    const { values, handleChange, handleBlur, handleSubmit, errors, touched, setFieldValue } =
        useFormik({
            initialValues: initData,
            validationSchema: createReward,
            enableReinitialize: true,
            onSubmit: handleRewardSubmit,
        });

    const handleChangeDateFrom = (date: Date | string) => {
        setFieldValue("date_from", moment(date).toISOString());
    };

    const handleChangeDateTo = (date: Date | string) => {
        setFieldValue("date_to", moment(date).toISOString());
    };

    const handleUploadImage = (images: string[]) => {
        setFieldValue("image_url_list", [...images]);
    };

    const handleChangeDescription = (event: any, editors: any) => {
        const data = editors.getData();
        setFieldValue("description", data);
    };
    const handleChangeCondition = (event: any, editors: any) => {
        const data = editors.getData();
        setFieldValue("condition", data);
    };
    const handleSelectClientLimit = (value: string) => {
        setFieldValue("client_limit_type", value);
    };
    const handleSelectRewardLimit = (value: string) => {
        setFieldValue("reward_limit_type", value);
    };
    const onchangePickUp = (values) => {
        console.log(values);
    };
    const pickupPoint = [
        { label: t("page.pickup_at_tessabarn"), value: "is_self_receive" },
        { label: t("page.receive_at_home"), value: "is_delivery" },
    ];
    return (
        <div className="form-wrap">
            <form onSubmit={handleSubmit}>
                {pathName.includes(PATH_PRIVILEGES_CREATE) && (
                    <div className="page-header">
                        <h3>{t("page.create_a_privilege_list")}</h3>
                        <p className="sub-title">
                            {t("page.please_provide_complete_details_of_privileges")}
                        </p>
                    </div>
                )}
                {/* form basic setting */}
                <div style={{ borderRadius: "20px" }}>
                    <div className="page-body">
                        <StyledCard>
                            <div className="title">{t("page.privilege_details")}</div>
                            <div className="card-wrap">
                                <MutlipleModuleUploadImage
                                    images={values.image_url_list}
                                    handleGetImage={handleUploadImage}
                                />
                            </div>
                            <div>
                                <Row gutter={16}>
                                    <Col xs={12} sm={12} md={24}>
                                        <SharedInput
                                            label={t("page.benefit_subject")}
                                            // descLabel={t("page._2_100_characters")}
                                            name="name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.name}
                                            errors={errors.name}
                                            touched={touched.name}
                                            parentClassName="mb-6"
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={16} className="select-layout">
                                    <Col span={12}>
                                        <Row gutter={16}>
                                            <Col span={16}>
                                                <SharedInput
                                                    label={t("page.right_per_person")}
                                                    // descLabel={t("page._2_100_characters")}
                                                    name="client_limit"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.client_limit}
                                                    errors={errors.client_limit}
                                                    touched={touched.client_limit}
                                                    parentClassName="mb-6"
                                                    type="number"
                                                    className="eng"
                                                />
                                            </Col>
                                            <Col span={8}>
                                                <div className="label type-hidden">
                                                    {t("page.news_type")}
                                                </div>
                                                <div className="input-field">
                                                    <Select
                                                        style={{ width: "100%", height: 48 }}
                                                        placeholder={t("page.news_type")}
                                                        onChange={handleSelectClientLimit}
                                                        defaultActiveFirstOption={true}
                                                        value={values.client_limit_type}
                                                    >
                                                        {timeLimit.map((item, index) => {
                                                            return (
                                                                <Option
                                                                    key={index}
                                                                    value={item.value}
                                                                >
                                                                    {t(`page.${item.name}`)}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={12}>
                                        <Row gutter={16}>
                                            <Col span={16}>
                                                <SharedInput
                                                    label={t("page.total_rights")}
                                                    // descLabel={t("page._2_100_characters")}
                                                    name="reward_limit"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.reward_limit}
                                                    errors={errors.reward_limit}
                                                    touched={touched.reward_limit}
                                                    parentClassName="mb-6"
                                                    type="number"
                                                    className="eng"
                                                />
                                            </Col>
                                            <Col span={8}>
                                                <div className="label type-hidden">
                                                    {t("page.news_type")}
                                                </div>
                                                <div className="input-field">
                                                    <Select
                                                        style={{ width: "100%", height: 48 }}
                                                        placeholder={t("page.news_type")}
                                                        onChange={handleSelectRewardLimit}
                                                        defaultActiveFirstOption={true}
                                                        value={values.reward_limit_type}
                                                    >
                                                        {timeLimit.map((item, index) => {
                                                            return (
                                                                <Option
                                                                    key={index}
                                                                    value={item.value}
                                                                >
                                                                    {t(`page.${item.name}`)}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            <div className="card-wrap">
                                <div className="title">{t("page.place_to_receive_Benefit")}</div>
                                <div>
                                    <Checkbox.Group
                                        options={pickupPoint}
                                        defaultValue={["is_self_receive", "is_delivery"]}
                                        onChange={onchangePickUp}
                                    />
                                </div>
                            </div>
                            <ComponentPeriod
                                label={t("page.privilege_period")}
                                dateFrom={values.date_from}
                                dateTo={values.date_to}
                                limitDate={{ minDate: new Date() }}
                                handleDateFrom={handleChangeDateFrom}
                                handleDateTo={handleChangeDateTo}
                            />
                            <div className="card-wrap">
                                <div className="title">{t("page.descriptions")}</div>
                                <SharedCKEditor
                                    editor={values.description || ""}
                                    handleChangeEditor={handleChangeDescription}
                                    name="description"
                                />
                            </div>
                            <div className="card-wrap">
                                <div className="title">{t("page.special_condition")}</div>
                                <SharedCKEditor
                                    editor={values.condition || ""}
                                    handleChangeEditor={handleChangeCondition}
                                    name="description"
                                />
                            </div>
                            {/* period */}

                            {/* <StyledSwitch className="switch-field">
                            <p>{t("page.activate")}</p>
                            <Switch checked={values.is_hot_news} onChange={handleChangeStatus} />
                        </StyledSwitch> */}
                        </StyledCard>
                        <div className="btn-layout">
                            <StyledSubmitButton
                                type="default"
                                text={t("page.save")}
                                htmlType="submit"
                                // disable={isSubmitting}
                            />
                            <StyledCancelButton
                                type="sub"
                                text={t("page.cancel")}
                                htmlType="button"
                                onClick={handleCancel}
                            />
                        </div>
                    </div>
                </div>
            </form>
            <ComponentRewardPreview reward={values} />
        </div>
    );
});
