import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StyledNewDetail } from "@components";
import { IAdminUser } from "@interfaces";
import { ModuleProfileForm } from "@modules";
import { useNotify } from "@utils";
import { adminAPI } from "@api";
import { setLoading, selectAuth } from "@redux";

export const PageProfileCreate = () => {
    const org_id = useSelector(selectAuth).auth?.organization_data?._id;
    const adminId = useSelector(selectAuth).auth?.admin_data?._id;

    const dispatch = useDispatch();
    const { warning } = useNotify();

    const [admin, setAdmin] = useState<IAdminUser>();

    useEffect(() => {
        fetchAdmin();
    }, []);

    const fetchAdmin = async () => {
        dispatch(setLoading(true));
        try {
            const response = await adminAPI.getAdmin({
                organization_id: org_id || "",
                admin_id: adminId || "",
            });
            const data: IAdminUser = response.data.result;
            setAdmin(data);
            dispatch(setLoading(false));
        } catch (err: any) {
            if (err) {
                warning(err.msg);
                dispatch(setLoading(false));
            }
        } finally {
            dispatch(setLoading(false));
        }
    };

    return <StyledNewDetail>{<ModuleProfileForm admin={admin} />}</StyledNewDetail>;
};
