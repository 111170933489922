import { ISvg } from "@interfaces";
export const IconConnect = (props: ISvg) => (
    <svg
        width={props.size || "24"}
        height={props.size || "24"}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M15 19H9.5C7.84315 19 6.5 17.6569 6.5 16L6.5 8C6.5 6.34315 7.84315 5 9.5 5H15"
            stroke={props.color || "#0D6EFD"}
            strokeWidth="1.8"
            strokeLinecap="round"
        />
        <rect
            x="15.4"
            y="9.9"
            width="6.2"
            height="4.2"
            rx="2.1"
            stroke={props.color || "#0D6EFD"}
            strokeWidth="1.8"
        />
        <rect
            x="15.4"
            y="2.9"
            width="6.2"
            height="4.2"
            rx="2.1"
            stroke={props.color || "#0D6EFD"}
            strokeWidth="1.8"
        />
        <rect
            x="15.4"
            y="16.9"
            width="6.2"
            height="4.2"
            rx="2.1"
            stroke={props.color || "#0D6EFD"}
            strokeWidth="1.8"
        />
        <path
            d="M2.5 11.1C2.00294 11.1 1.6 11.5029 1.6 12C1.6 12.4971 2.00294 12.9 2.5 12.9V11.1ZM15 12.9H15.9V11.1H15V12.9ZM2.5 12.9H15V11.1H2.5V12.9Z"
            fill={props.color || "#0D6EFD"}
        />
    </svg>
);
