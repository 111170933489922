export const EditIcon = () => (
    <svg width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path fillRule="evenodd"
            clipRule="evenodd"
            d="M10.051 13.9131H4.16834C1.87434 13.9131 0.333008 12.3031 0.333008 9.90706V4.3644C0.333008 1.9684 1.87434 0.358398 4.16834 0.358398H6.66101C6.93701 0.358398 7.16101 0.582398 7.16101 0.858398C7.16101 1.1344 6.93701 1.3584 6.66101 1.3584H4.16834C2.44567 1.3584 1.33301 2.5384 1.33301 4.3644V9.90706C1.33301 11.7331 2.44567 12.9131 4.16834 12.9131H10.051C11.7737 12.9131 12.887 11.7331 12.887 9.90706V7.22173C12.887 6.94573 13.111 6.72173 13.387 6.72173C13.663 6.72173 13.887 6.94573 13.887 7.22173V9.90706C13.887 12.3031 12.345 13.9131 10.051 13.9131Z"
            fill="#29304D"
        />
        <path fillRule="evenodd"
            clipRule="evenodd"
            d="M4.91126 9.28599H6.89592C7.14926 9.28599 7.38659 9.18799 7.56592 9.00865L12.5719 4.00265C12.7773 3.79732 12.8906 3.52398 12.8906 3.23332C12.8906 2.94198 12.7773 2.66798 12.5719 2.46265L11.7606 1.65132C11.3359 1.22798 10.6453 1.22798 10.2199 1.65132L5.23792 6.63332C5.06526 6.80598 4.96726 7.03532 4.96126 7.27865L4.91126 9.28599ZM6.89592 10.286H4.39859C4.26392 10.286 4.13459 10.2313 4.04059 10.1347C3.94659 10.0387 3.89526 9.90865 3.89859 9.77332L3.96126 7.25398C3.97392 6.75265 4.17592 6.28132 4.53059 5.92598H4.53126L9.51326 0.943984C10.3279 0.130651 11.6526 0.130651 12.4673 0.943984L13.2786 1.75532C13.6739 2.14998 13.8913 2.67465 13.8906 3.23332C13.8906 3.79198 13.6733 4.31598 13.2786 4.70998L8.27259 9.71598C7.90526 10.0833 7.41592 10.286 6.89592 10.286Z"
            fill="#29304D"
        />
        <path fillRule="evenodd"
            clipRule="evenodd"
            d="M12.1532 5.61188C12.0252 5.61188 11.8972 5.56322 11.7999 5.46522L8.75588 2.42122C8.56054 2.22588 8.56054 1.90922 8.75588 1.71388C8.95121 1.51855 9.26721 1.51855 9.46254 1.71388L12.5065 4.75855C12.7019 4.95388 12.7019 5.26988 12.5065 5.46522C12.4092 5.56322 12.2812 5.61188 12.1532 5.61188Z"
            fill="#29304D"
        />
    </svg>

);