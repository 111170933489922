import { useState, useEffect } from "react";
import {
    ComponentContactSortIcon,
    StyledTable,
    ComponentEmptyData,
    StyledTableButtonTop,
    ComponentRowAcitivity,
    ComponentStatusFilter,
} from "@components";
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    TouchSensor,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    rectSortingStrategy,
    //@ts-ignore
} from "@dnd-kit/sortable";
import { Row, Col, Pagination } from "antd";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import {
    enumSortBy,
    enumContactSortFields,
    PAGINATION,
    PAGE_START,
    INITIAL_LIST_PARAMS,
    enumNewsStatus,
    PATH_ACTIVITY_CREATE,
    PATH_HOME,
} from "@configs";
import { IPaginationNext, IActivity, IStatusCount } from "@interfaces";
import { useDispatch, useSelector } from "react-redux";
import { getActivityCategory, selectAuth, selectContact, setLoading } from "@redux";
import { useNotify, usePermission } from "@utils";
import { useHistory } from "react-router";
import { activityApi } from "@api";
const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: PAGINATION,
};

export const PageActivityList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const search_params = useSelector(selectContact).paramsContact;
    const [params, setParams] = useState(search_params);
    const organization_id = useSelector(selectAuth).auth?.organization_data?._id;
    const pemisssion = useSelector(selectAuth).permission;
    const [searchDate, setSearchDate] = useState<string>("");
    const [pagination, setPagination] = useState<IPaginationNext>(INITAL_PAGE);
    const [activities, setActivities] = useState<IActivity[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [isFull, setIsFull] = useState<boolean>(false);
    const [activityCount, setActivityCount] = useState<IStatusCount>();
    const [filterStatus, setFilterStatus] = useState<boolean>(true);
    const [status, setStatus] = useState<string | boolean>("");
    const { error } = useNotify();
    const history = useHistory();
    usePermission({ permission: pemisssion?.permission_list.activity.is_view });

    useEffect(() => {
        fetchActivity(pagination, isFull, searchDate, status);
    }, [pagination, isFull, searchDate, status]);
    useEffect(() => {
        fetchActivityCategory();
        fetchActivityCountStatus();
    }, []);

    const fetchActivityCategory = async () => {
        await dispatch(getActivityCategory({ organization_id: organization_id || "" }));
    };
    const fetchActivityCountStatus = async () => {
        try {
            const res = await activityApi.getActivityEnableCount({
                organization_id: organization_id || "",
            });
            if (res.data.error === "0") setActivityCount(res.data.result);
        } catch (error) {}
    };

    const fetchActivity = async (
        pagination: IPaginationNext,
        isFull: boolean,
        searchDate: string,
        status: string | boolean
    ) => {
        dispatch(setLoading(true));
        try {
            const response = await activityApi.getActivities({
                organization_id: organization_id || "",
                ...pagination,
                ...(isFull && { is_full: isFull }),
                ...(searchDate.length > 1 &&
                    searchDate[0] !== "" && {
                        date_from: searchDate[0],
                    }),
                ...(searchDate.length > 1 &&
                    searchDate[1] !== "" && {
                        date_to: searchDate[1],
                    }),
                ...(typeof status === "boolean" && { is_enable: status }),
            });
            setActivities(response.data.result);
            setTotal(response.data.total);
            // success(response.data.msg);
        } catch (err: any) {
            error(err.msg);
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleSort = (sortField: enumContactSortFields, sortBy: enumSortBy) => {
        const newSortParams = { ...params, sortField, sortBy };
        setParams(newSortParams);
    };
    const callbackGetList = async () => {
        await fetchActivity(pagination, isFull, searchDate, status);
        await fetchActivityCountStatus();
    };
    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            const swapItem = (items: IActivity[]) => {
                const oldIndex = items.findIndex((item) => JSON.stringify(item) === active.id);
                const newIndex = items.findIndex((item) => JSON.stringify(item) === over.id);
                return arrayMove(items, oldIndex, newIndex);
            };
            setActivities(swapItem);
        }
    };
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
        useSensor(TouchSensor)
    );
    const handlePagination = (page) => {
        setPagination({ xpage: page, xlimit: pagination.xlimit });
    };
    const handleStatus = (params: enumNewsStatus) => {
        switch (params) {
            case enumNewsStatus.ALL:
                setStatus("");
                break;
            case enumNewsStatus.ACTIVE:
                setStatus(true);
                break;
            case enumNewsStatus.INACTIVE:
                setStatus(false);
                break;
            default:
                break;
        }
    };
    const getStatus = () => {
        if (status === "") return enumNewsStatus.ALL;
        if (status === true) return enumNewsStatus.ACTIVE;
        if (status === false) return enumNewsStatus.INACTIVE;
    };
    return (
        <StyledTable>
            <div className="page-layout">
                <div className="page-header-layout">
                    <div className="page-header">
                        <div>
                            <h3>{t("page.activity_list")}</h3>
                        </div>
                        <div className="flex-header">
                            <StyledTableButtonTop
                                type="danger"
                                size="default"
                                text={t("page.create_activity")}
                                onClick={() => history.push(PATH_ACTIVITY_CREATE)}
                                className="add-btn"
                                icon="plus"
                            />
                        </div>
                    </div>
                    <div className="search-layout">
                        <ComponentStatusFilter
                            setStatus={handleStatus}
                            setFilterStatus={setFilterStatus}
                            activeCount={activityCount?.enable_true || 0}
                            inactiveCount={activityCount?.enable_false || 0}
                            status={getStatus()}
                        />
                    </div>
                </div>
                <div className="page-table-layout">
                    <div className="table">
                        <ScrollContainer vertical={false}>
                            <div className="table_ground">
                                <div className="table-body">
                                    <div className="table-header">
                                        <Row
                                            gutter={2}
                                            type="flex"
                                            justify="space-between"
                                            align="middle"
                                            className="header-row"
                                        >
                                            <Col className="col-item" span={6}>
                                                <p>{t("page.activity")}</p>
                                            </Col>
                                            <Col className="col-item" span={3}>
                                                <p>{t("page.participant_number")}</p>
                                            </Col>
                                            <Col className="col-item" span={3}>
                                                <p>{t("page.fully_booked")}</p>
                                            </Col>
                                            <Col className="col-item" span={4}>
                                                <p>{t("page.activity_period")}</p>
                                            </Col>
                                            <Col className="col-item" span={3}>
                                                <p>{t("page.status")}</p>
                                            </Col>
                                            <Col className="col-item" span={2}>
                                                <p>{t("page.manage")}</p>
                                            </Col>
                                        </Row>
                                    </div>
                                    <DndContext
                                        sensors={sensors}
                                        collisionDetection={closestCenter}
                                        onDragEnd={handleDragEnd}
                                    >
                                        <SortableContext
                                            items={activities.map((item) => JSON.stringify(item))}
                                            strategy={rectSortingStrategy}
                                        >
                                            {activities && activities.length > 0 ? (
                                                <div className="data-table">
                                                    {activities.map((item, index) => (
                                                        <ComponentRowAcitivity
                                                            key={index}
                                                            item={item}
                                                            itemJson={JSON.stringify(item)}
                                                            callbackGetList={callbackGetList}
                                                            index={index}
                                                        />
                                                    ))}
                                                    <div
                                                        style={{ width: "100%", height: 10 }}
                                                    ></div>
                                                </div>
                                            ) : (
                                                <ComponentEmptyData />
                                            )}
                                        </SortableContext>
                                    </DndContext>
                                </div>
                            </div>
                        </ScrollContainer>
                    </div>
                </div>
                <div className="page-bottom">
                    {total > 0 && (
                        <div className="pagination">
                            <Pagination
                                defaultCurrent={INITIAL_LIST_PARAMS.page || 1}
                                pageSize={PAGINATION}
                                total={total}
                                onChange={handlePagination}
                                current={pagination.xpage}
                            />
                        </div>
                    )}
                </div>
            </div>
        </StyledTable>
    );
};
