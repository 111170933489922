import { useEffect, memo, useState, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Row, Col, Select } from "antd";
import styled from "styled-components";
import moment from "moment";
import { IContactListParams, IBidding, IbiddingUpdate } from "@interfaces";
import {
  SharedCKEditor,
  StyledSubmitButton,
  StyledCancelButton,
  StyledCard,
  SharedInput,
  ComponentPeriod,
  FriendIcon,
  ComponentContactSortIcon,
  ComponentRowBidder,
  ComponentEmptyData,
} from "@components";
import { useYup } from "@validations";
import { setLoading, selectAuth, selectContact } from "@redux";
import { showErrorMessage, useNotify, usePermission } from "@utils";
import {
  dateFormatHM,
  enumContactSortFields,
  enumSortBy,
  PATH_BIDDING,
  PATH_BIDDING_EMPLOY_LIST,
  PATH_BIDDING_PURCHASE_LIST,
  PATH_CREATE_EMPLOY,
  PATH_CREATE_PURCHASE,
} from "@configs";
import { biddingAPI } from "@api";
import ScrollContainer from "react-indiana-drag-scroll";
import { ModuleUploadFile } from "../form/UploadFile";

interface IProps {
  bidding: IBidding | undefined;
  // categories: INewsCategory[] | undefined;
}
const newDate = new Date();
const initData: IbiddingUpdate = {
  organization_id: "",
  bidding_id: "",
  title: "",
  detail: "",
  template_url: "",
  bid_date_from: new Date().toString(),
  bid_date_to: new Date(newDate.setFullYear(newDate.getFullYear() + 1)).toString(),
  is_enable: true,
  bidding_type: "",
};
const { Option } = Select;
export const ModuleBiddingForm = memo((props: IProps) => {
  //hook
  const { YupNews, YupBidding } = useYup();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { error, success } = useNotify();
  const search_params = useSelector(selectContact).paramsContact;
  const permisssion = useSelector(selectAuth).permission;
  const [params, setParams] = useState<IContactListParams>(search_params);
  const [showModal, setShowModal] = useState<boolean>(false);
  const organization_id = useSelector(selectAuth).auth?.admin_data?.organization_id;
  const searchInputRef = useRef<any | null>(null);
  const pathName = window.location.pathname;
  const createNews = Yup.object().shape(YupNews);
  const createBidding = Yup.object().shape(YupBidding);
  const { bidding } = props;
  const [menuTab, setMenuTab] = useState(0);
  const [fileName, setFileName] = useState<string>("");
  usePermission({ permission: permisssion?.permission_list.bidding.is_manage_bidding });
  useEffect(() => {
    if (bidding) {
      setFieldValue("title", bidding.title);
      setFieldValue("detail", bidding.detail);
      setFieldValue("bid_date_from", bidding.bid_date_from);
      setFieldValue("bid_date_to", bidding.bid_date_to);
      setFieldValue("bidding_id", bidding._id);
      setFieldValue("organization_id", bidding.organization_id);
      setFieldValue("bidding_type", bidding.bidding_type);
      let fileUrlSplit = bidding?.template_url.split("/");
      let pdfName = fileUrlSplit[fileUrlSplit.length - 1];
      let nameOnly = pdfName.split("-").slice(1).join();
      setFileName(nameOnly);
    }
    if (pathName === PATH_CREATE_PURCHASE || pathName === PATH_CREATE_EMPLOY) {
      setMenuTab(1);
    }
    // console.log(pathName === );

    // console.log('value', values);
  }, [bidding]);

  const handleBiddingSubmit = (values: IbiddingUpdate) => {
    // dispatch(setLoading(true))
    console.log("hello submit", values);
  };

  const handleClose = () => {
    if (bidding?.bidding_type === "PURCHASE" || pathName === PATH_CREATE_PURCHASE) {
      history.push(PATH_BIDDING_PURCHASE_LIST);
    } else if (bidding?.bidding_type === "EMPLOY" || PATH_CREATE_EMPLOY) {
      history.push(PATH_BIDDING_EMPLOY_LIST);
    } else {
      history.push(PATH_BIDDING_PURCHASE_LIST);
    }
  };

  const { values, handleChange, handleBlur, handleSubmit, errors, touched, setFieldValue } =
    useFormik({
      initialValues: initData,
      validationSchema: createBidding,
      enableReinitialize: true,
      onSubmit: handleBiddingSubmit,
    });

  const handleChangeDateFrom = (date: Date | string) => {
    setFieldValue("bid_date_from", moment(date).toISOString());
  };

  const handlesort = () => {
    console.log("sort");
  };

  const handleChangeDateTo = (date: Date | string) => {
    setFieldValue("bid_date_to", moment(date).toISOString());
  };

  const handleChangeGuideLine = (event: any, editors: any) => {
    const data = editors.getData();
    setFieldValue("detail", data);
  };

  const onGetFile = (file: string, index: number) => {
    let fileUrlSplit = file.split("/");
    let pdfName = fileUrlSplit[fileUrlSplit.length - 1];
    let nameOnly = pdfName.split("-").slice(1).join();
    // console.log("name", nameOnly);
    setFileName(nameOnly);
    setFieldValue("template_url", file);
  };

  const saveBidding = async () => {
    dispatch(setLoading(true));
    try {
      if (pathName === PATH_CREATE_PURCHASE) {
        console.log("create_purchase");
        const payload = {
          organization_id: organization_id || "",
          title: values.title,
          detail: values.detail,
          bid_date_from: values.bid_date_from,
          bid_date_to: values.bid_date_to,
          is_enable: true,
          bidding_type: "PURCHASE",
          template_url: values.template_url,
        };
        await biddingAPI.create(payload);
        success(t("message.create.success"));
        handleClose();
      } else if (pathName === PATH_CREATE_EMPLOY) {
        console.log("create_employ");
        const payload = {
          organization_id: organization_id || "",
          title: values.title,
          detail: values.detail,
          bid_date_from: values.bid_date_from,
          bid_date_to: values.bid_date_to,
          is_enable: true,
          bidding_type: "EMPLOY",
          template_url: values.template_url,
        };
        await biddingAPI.create(payload);
        success(t("message.create.success"));
        handleClose();
      } else {
        // update bidding
        await biddingAPI.update(values);
        success(t("message.create.success"));
        handleClose();
      }
    } catch (err: any) {
      if (err.response) {
        error(showErrorMessage(err.response));
      } else error(t("message.error"));
    } finally {
      dispatch(setLoading(false));
    }
  };
  return (
    <div className="form-wrap">
      <form onSubmit={handleSubmit}>
        <div className="page-header">
          <h3>
            {pathName === PATH_CREATE_EMPLOY ?
              t("page.bidding.create_bidding_headline_e")
              : pathName === PATH_CREATE_PURCHASE ?
                t("page.bidding.create_bidding_headline_p")
                : bidding?.bidding_type === "PURCHASE" ?
                  t("page.bidding.view_purchase_headline") :
                  t("page.bidding.view_employ_headline")
            }
          </h3>
        </div>
        {pathName !== PATH_CREATE_PURCHASE && pathName !== PATH_CREATE_EMPLOY && (
          <div className="menu-tab">
            <StyleMenuTab>
              <span
                onClick={() => setMenuTab(0)}
                className={`${menuTab === 0 && "selected"}`}
              >
                {t("page.bidding.bidder_name_tab")}
              </span>
              <span
                onClick={() => setMenuTab(1)}
                className={`${menuTab === 1 && "selected"}`}
              >
                {bidding?.bidding_type === "PURCHASE"
                  ? t("page.bidding.bidding_edit_tab_p")
                  : t("page.bidding.bidding_edit_tab_e")}
              </span>
            </StyleMenuTab>
          </div>
        )}

        {menuTab === 0 && (
          <StyledPageBody>
            <StyledCard>
              <StyledBidderNum>
                <FriendIcon />
                <div className="title">{t("page.bidding.bidder_number")}</div>
                <div className="number">
                  {bidding?.bidder_list && bidding?.bidder_list?.length > 0
                    ? bidding?.bidder_list?.length
                    : 0}
                </div>
              </StyledBidderNum>
            </StyledCard>
            <br />
            <StyledCard>
              <StyledBidderList>
                <div className="header">
                  <div className="title">{t("page.bidding.name_list")}</div>
                  <StyledSubmitButton
                    type="default"
                    text={t("page.export")}
                  // disable={isSubmitting}
                  />
                </div>
                <div className="table">
                  <ScrollContainer vertical={false}>
                    <div className="table_ground">
                      <div className="table-body">
                        <div className="table-header">
                          <Row
                            gutter={24}
                            type="flex"
                            justify="space-between"
                            align="middle"
                            className="header-row"
                          >
                            <Col className="col-item" span={2}>
                              <p>ลำดับที่</p>
                              <div className="filter-button">
                                <ComponentContactSortIcon
                                  handleSort={handlesort}
                                  sortBy={enumSortBy.ASC}
                                  sortField={
                                    enumContactSortFields.FULL_NAME
                                  }
                                  searchParams={params}
                                />
                                <ComponentContactSortIcon
                                  handleSort={handlesort}
                                  sortBy={enumSortBy.DESC}
                                  sortField={
                                    enumContactSortFields.FULL_NAME
                                  }
                                  searchParams={params}
                                />
                              </div>
                            </Col>
                            <Col className="col-item" span={5}>
                              <p>รายชื่อผู้เข้าร่วมการประมูล</p>
                              <div className="filter-button">
                                <ComponentContactSortIcon
                                  handleSort={handlesort}
                                  sortBy={enumSortBy.ASC}
                                  sortField={
                                    enumContactSortFields.TEL
                                  }
                                  searchParams={params}
                                />
                                <ComponentContactSortIcon
                                  handleSort={handlesort}
                                  sortBy={enumSortBy.DESC}
                                  sortField={
                                    enumContactSortFields.TEL
                                  }
                                  searchParams={params}
                                />
                              </div>
                            </Col>
                            <Col className="col-item" span={3}>
                              เวลาที่เข้าร่วมประมูล
                              <div className="filter-button">
                                <ComponentContactSortIcon
                                  handleSort={handlesort}
                                  sortBy={enumSortBy.ASC}
                                  sortField={
                                    enumContactSortFields.POINTS
                                  }
                                  searchParams={params}
                                />
                                <ComponentContactSortIcon
                                  handleSort={handlesort}
                                  sortBy={enumSortBy.DESC}
                                  sortField={
                                    enumContactSortFields.POINTS
                                  }
                                  searchParams={params}
                                />
                              </div>
                            </Col>
                            <Col className="col-item" span={3}>
                              รายละเอียดของผู้เข้าประมูล
                              <div className="filter-button">
                                <ComponentContactSortIcon
                                  handleSort={handlesort}
                                  sortBy={enumSortBy.ASC}
                                  sortField={
                                    enumContactSortFields.POINTS
                                  }
                                  searchParams={params}
                                />
                                <ComponentContactSortIcon
                                  handleSort={handlesort}
                                  sortBy={enumSortBy.DESC}
                                  sortField={
                                    enumContactSortFields.POINTS
                                  }
                                  searchParams={params}
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                        {/* <ComponentEmptyData /> */}
                        {bidding?.bidder_list &&
                          bidding?.bidder_list.length > 0 ? (
                          <>
                            {bidding.bidder_list.map((item, index) => {
                              return (
                                <ComponentRowBidder
                                  key={index}
                                  data={item}
                                  itemJson={JSON.stringify(item)}
                                  // callbackGetList={callbackGetList}
                                  index={index}
                                />
                              );
                            })}
                          </>
                        ) : (
                          <ComponentEmptyData />
                        )}
                        {/* {bidding && bidding.bidder_list.length > 0 ? (
                          <div className="data-table">
                            {bidding.bidder_list.map((item, index) => (
                              <ComponentRowBidder
                                key={index}
                                data={item}
                                itemJson={JSON.stringify(item)}
                                // callbackGetList={callbackGetList}
                                index={index}
                              />
                            ))}
                            <div style={{ width: "100%", height: 10 }}></div>
                          </div>
                        ) : (
                          <ComponentEmptyData />
                        )} */}
                      </div>
                    </div>
                  </ScrollContainer>
                </div>
              </StyledBidderList>
            </StyledCard>
            <StyledDetailBody>
              <h3 className="title">{bidding?.title}</h3>
              <p className="period">
                {t("page.bidding.period")} :{" "}
                {moment(bidding?.bid_date_from).format(dateFormatHM)} -{" "}
                {moment(bidding?.bid_date_to).format(dateFormatHM)}
              </p>
              <hr />
              <p className="detail-headline">{t("page.bidding.bidding_detail")}</p>
              <p className="detail-desc">{bidding?.detail}</p>
            </StyledDetailBody>
          </StyledPageBody>
        )}
        {menuTab === 1 && (
          <StyledPageBody>
            <StyledCard>
              <div className="title">
                {pathName.includes(PATH_BIDDING)
                  ? pathName === PATH_CREATE_PURCHASE
                    ? t("page.bidding.create_bidding_headline_p")
                    : t("page.bidding.create_bidding_headline_e")
                  : bidding?.bidding_type === "PURCHASE"
                    ? t("page.bidding.view_purchase_headline")
                    : t("page.bidding.view_employ_headline")}
              </div>
              <div>
                <Row gutter={16}>
                  <Col xs={12} sm={12} md={24}>
                    <SharedInput
                      label={t("page.bidding.bidding_title")}
                      // descLabel={t("page._2_100_characters")}
                      name="title"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.title}
                      errors={errors.title}
                      touched={touched.title}
                      parentClassName="mb-6"
                    />
                  </Col>
                </Row>
                <ComponentPeriod
                  label="ระยะเวลาการประมูล"
                  dateFrom={values.bid_date_from}
                  dateTo={values.bid_date_to}
                  limitDate={{ minDate: new Date() }}
                  handleDateFrom={handleChangeDateFrom}
                  handleDateTo={handleChangeDateTo}
                />
              </div>
              <div className="card-wrap">
                <div className="sub-title">{"รายละเอียดการประมูล"}</div>
                <SharedCKEditor
                  editor={values.detail || ""}
                  handleChangeEditor={handleChangeGuideLine}
                  name="description"
                />
              </div>
              <br />
              <div className="card-wrap">
                <ModuleUploadFile
                  title={t("page.bidding.upload_file_bidding_headline")}
                  handleGetFile={onGetFile}
                  index={0}
                  defaultFileName={fileName}
                  placeholder={t("page.bidding.please_select_file")}
                  btnText={t("page.bidding.upload_file")}
                />
              </div>
            </StyledCard>
            <div></div>
            <div className="btn-layout">
              <StyledSubmitButton
                type="default"
                text={t("page.save")}
                // htmlType="submit"
                onClick={saveBidding}
                // onClick={handleSubmit}
                disable={values.title === ""}
              />
              <StyledCancelButton
                type="sub"
                text={t("page.cancel")}
                htmlType="button"
                onClick={handleClose}
              />
            </div>
          </StyledPageBody>
        )}
      </form>
    </div>
  );
});

const StyleMenuTab = styled.div`
    span {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin-right: 10px;
        padding: 10px;
        cursor: pointer;
    }
    .selected {
        background-color: #fff;
        border-top: 5px solid #0d6efd;
        color: #0d6efd;
        border-radius: 5px 5px 0 0;
    }
`;

const StyledBidderNum = styled.div`
    height: 30px;
    display: flex;
    .title {
        margin-left: 5px;
    }

    .number {
        font-weight: 600;
        font-size: 35px;
        line-height: 53px;
        margin-left: auto;
    }
`;

const StyledBidderList = styled.div`
    .header {
        display: flex;
        justify-content: space-between;
    }
`;

const StyledPageBody = styled.div`
    display: grid;
    grid-template-columns: 760px 2fr;
    grid-gap: 15px;
`;

const StyledDetailBody = styled.div`
    background: #ffffff;
    border-radius: 4px;
    padding: 30px;
    max-width: 700px;
    .title {
        font-weight: 500;
        font-size: 25px;
        line-height: 38px;
    }
    .period {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }

    hr {
        border-top: 1px solid #f3f3f3;
    }

    .detail-headline {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }
    .detail-desc {
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
    }
`;
