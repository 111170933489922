import { ISvg } from "@interfaces";
export const LineLoginIcon = (props: ISvg) => (
    <svg
        width={props.size || "34"}
        height={props.size || "33"}
        viewBox="0 0 34 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M17.0001 0.5C7.67433 0.5 0.0859375 6.61309 0.0859375 14.1271C0.0859375 20.8634 6.10335 26.5049 14.2316 27.5717C14.7825 27.6896 15.5322 27.9323 15.7219 28.3997C15.8924 28.8243 15.8334 29.4894 15.7763 29.9182C15.7763 29.9182 15.5779 31.1031 15.5351 31.3555C15.4615 31.7801 15.1953 33.0159 17.0001 32.2607C18.8055 31.5055 26.7411 26.5664 30.2897 22.5113C32.7404 19.8427 33.9145 17.1344 33.9145 14.1271C33.9145 6.61309 26.3271 0.5 17.0001 0.5Z"
            fill="#2BB800"
        />
        <path
            d="M12.2984 10.5571C12.1182 10.5571 11.9717 10.7084 11.9717 10.8941V18.4487C11.9717 18.6348 12.1182 18.7857 12.2984 18.7857H13.4739C13.6541 18.7857 13.8003 18.6348 13.8003 18.4487V10.8941C13.8003 10.7084 13.6541 10.5571 13.4739 10.5571H12.2984Z"
            fill="white"
        />
        <path
            d="M21.6855 10.5571H20.4481C20.2584 10.5571 20.1045 10.7084 20.1045 10.8941V15.3823L16.582 10.707C16.5739 10.6951 16.5647 10.6838 16.5553 10.6728L16.5529 10.6708C16.5461 10.6632 16.5394 10.6565 16.5323 10.6499C16.5302 10.6479 16.5282 10.6462 16.5258 10.6442C16.5201 10.6389 16.514 10.6339 16.5076 10.6293C16.5049 10.6266 16.5018 10.6246 16.4988 10.6223C16.4927 10.6183 16.4869 10.614 16.4809 10.6103C16.4775 10.608 16.4741 10.606 16.4704 10.6043C16.4643 10.6007 16.4582 10.597 16.4521 10.594C16.4484 10.5924 16.445 10.5904 16.4413 10.589C16.4349 10.586 16.4284 10.5831 16.4217 10.5807C16.4176 10.5794 16.4142 10.5781 16.4105 10.5767C16.4037 10.5744 16.397 10.5721 16.3899 10.5704C16.3862 10.5691 16.3821 10.5684 16.378 10.5671C16.3713 10.5658 16.3649 10.5641 16.3584 10.5628C16.3537 10.5621 16.3486 10.5614 16.3439 10.5611C16.3378 10.5598 16.3317 10.5595 16.3256 10.5588C16.3199 10.5581 16.3141 10.5581 16.308 10.5578C16.3036 10.5578 16.3003 10.5571 16.2959 10.5571H15.0588C14.8691 10.5571 14.7148 10.7084 14.7148 10.8941V18.4487C14.7148 18.6348 14.8691 18.7857 15.0588 18.7857H16.2959C16.4859 18.7857 16.6398 18.6348 16.6398 18.4487V13.9618L20.1667 18.6431C20.1911 18.677 20.2212 18.7046 20.254 18.7265C20.255 18.7272 20.2564 18.7282 20.2574 18.7292C20.2645 18.7335 20.2716 18.7378 20.2787 18.7418C20.2821 18.7438 20.2851 18.7452 20.2885 18.7468C20.2936 18.7498 20.2993 18.7525 20.3047 18.7548C20.3105 18.7571 20.3155 18.7594 20.3216 18.7618C20.325 18.7631 20.3284 18.7644 20.3318 18.7654C20.3399 18.7684 20.3473 18.7707 20.3551 18.7731C20.3568 18.7731 20.3585 18.7737 20.3602 18.7741C20.3882 18.7814 20.4177 18.7857 20.4481 18.7857H21.6855C21.8756 18.7857 22.0291 18.6348 22.0291 18.4487V10.8941C22.0291 10.7084 21.8756 10.5571 21.6855 10.5571Z"
            fill="white"
        />
        <path
            d="M10.7229 16.894H7.44665V10.8948C7.44665 10.7083 7.29672 10.5571 7.11218 10.5571H5.90609C5.72123 10.5571 5.57129 10.7083 5.57129 10.8948V18.4477V18.4484C5.57129 18.5391 5.60721 18.6212 5.66488 18.6817C5.66619 18.6833 5.66751 18.685 5.66949 18.6867C5.67114 18.6883 5.67278 18.6897 5.67443 18.6913C5.73474 18.7498 5.8158 18.7857 5.90576 18.7857H10.7229C10.9077 18.7857 11.057 18.6341 11.057 18.4477V17.2316C11.057 17.0452 10.9077 16.894 10.7229 16.894Z"
            fill="white"
        />
        <path
            d="M28.0949 12.4488C28.2798 12.4488 28.4291 12.298 28.4291 12.1112V10.8951C28.4291 10.7087 28.2798 10.5571 28.0949 10.5571H23.2782H23.2775C23.1872 10.5571 23.1058 10.5937 23.0455 10.6525C23.0442 10.6538 23.0425 10.6548 23.0416 10.6562C23.0396 10.6582 23.0379 10.6602 23.0363 10.6622C22.9789 10.7226 22.9434 10.8044 22.9434 10.8948V10.8951V18.448V18.4484C22.9434 18.5391 22.9793 18.6212 23.0369 18.6817C23.0383 18.6833 23.0399 18.6853 23.0416 18.6867C23.0429 18.6883 23.0449 18.69 23.0465 18.6913C23.1065 18.7495 23.1879 18.7857 23.2775 18.7857H28.0949C28.2798 18.7857 28.4291 18.6341 28.4291 18.448V17.2316C28.4291 17.0455 28.2798 16.894 28.0949 16.894H24.8191V15.6171H28.0949C28.2798 15.6171 28.4291 15.4659 28.4291 15.2794V14.0634C28.4291 13.8769 28.2798 13.7254 28.0949 13.7254H24.8191V12.4488H28.0949Z"
            fill="white"
        />
    </svg>
);
