interface IProps {
    permission: boolean | undefined;
}
import { PATH_HOME } from "@configs";
import { setLoading } from "@redux";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useNotify } from "./notifyHook";
export const usePermission = (props: IProps) => {
    const { permission } = props;
    const { t } = useTranslation();
    const { warning } = useNotify();
    const history = useHistory();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setLoading(true));
        setTimeout(() => {
            if (permission === undefined || permission === false) {
                warning(t("message.not_permission"));
                history.push(PATH_HOME);
            }
        }, 1000);
        dispatch(setLoading(false));
    }, [permission]);
    return;
};
// check the permission's props, if not permission go back to home and show warming msg
