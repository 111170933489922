export const LocationIcon = () => (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.5037 19.3315C14.1341 18.4958 20.2105 14.9863 20.2105 9.10526C20.2105 4.62885 16.5817 1 12.1053 1C7.62885 1 4 4.62885 4 9.10526C4 14.9863 10.0765 18.4958 11.7068 19.3315C11.9603 19.4615 12.2502 19.4615 12.5037 19.3315ZM12.1053 12.5789C14.0237 12.5789 15.5789 11.0237 15.5789 9.10526C15.5789 7.1868 14.0237 5.63158 12.1053 5.63158C10.1868 5.63158 8.63158 7.1868 8.63158 9.10526C8.63158 11.0237 10.1868 12.5789 12.1053 12.5789Z"
            fill="white"
        />
        <path
            d="M19.1246 17.7896C19.836 18.3176 20.2105 18.9167 20.2105 19.5264C20.2105 20.1362 19.836 20.7352 19.1246 21.2633C18.4132 21.7913 17.3901 22.2298 16.1579 22.5347C14.9257 22.8396 13.528 23.0001 12.1053 23.0001C10.6825 23.0001 9.28479 22.8396 8.05263 22.5347C6.82048 22.2298 5.79729 21.7913 5.0859 21.2633C4.37451 20.7352 4 20.1362 4 19.5264C4 18.9167 4.37451 18.3176 5.0859 17.7896"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
        />
    </svg>
);
