import { useState, useEffect } from "react";
import {
    ComponentContactSortIcon,
    StyledTable,
    ComponentEmptyData,
    StyledTableButtonTop,
} from "@components";
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    TouchSensor,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    rectSortingStrategy,
    //@ts-ignore
} from "@dnd-kit/sortable";
import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import { enumSortBy, enumContactSortFields } from "@configs";
import { IContactListParams, IDivision, INews } from "@interfaces";
import { useDispatch, useSelector } from "react-redux";
import { getDivisions, selectAuth, selectContact, selectOrg } from "@redux";
import { ComponentRowDivision } from "src/Components/table/RowDivision";
import { ModuleDrawerForm } from "@modules";
import { usePermission } from "@utils";

export const PageDivisionList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const search_params = useSelector(selectContact).paramsContact;
    const [params, setParams] = useState<IContactListParams>(search_params);
    const org_id = useSelector(selectAuth).auth?.organization_data?._id;
    const adminId = useSelector(selectAuth).auth?.admin_data?._id;
    const permisssion = useSelector(selectAuth).permission;
    const divisions = useSelector(selectOrg).division;
    const [showForm, setShowForm] = useState(false);
    const [editDivision, setEditDivision] = useState<IDivision>();

    usePermission({ permission: permisssion?.permission_list.complain.is_manage });
    useEffect(() => {
        fetchDivision();
    }, []);

    const fetchDivision = async () => {
        await dispatch(getDivisions({ organization_id: org_id || "", admin_id: adminId || "" }));
    };

    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            const swapItem = (items: INews[]) => {
                const oldIndex = items.findIndex((item) => JSON.stringify(item) === active.id);
                const newIndex = items.findIndex((item) => JSON.stringify(item) === over.id);
                return arrayMove(items, oldIndex, newIndex);
            };
            // setNews(swapItem);
        }
    };
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
        useSensor(TouchSensor)
    );
    const handleSort = (sortField: enumContactSortFields, sortBy: enumSortBy) => {
        const newSortParams = { ...params, sortField, sortBy };
        setParams(newSortParams);
    };
    const handleCancel = () => {
        setShowForm(!showForm);
    };
    const handleEditDivision = (params: IDivision) => {
        setShowForm(!showForm);
        setEditDivision(params);
    };
    const handleCreateDivision = () => {
        setShowForm(true);
        setEditDivision(undefined);
    };
    return (
        <StyledTable>
            <div className="page-layout">
                <div className="page-header-layout">
                    <div className="page-header">
                        <div>
                            <h3>{t("page.agency")}</h3>
                        </div>
                        <div className="flex-header">
                            <StyledTableButtonTop
                                type="danger"
                                size="default"
                                text={t("page.add_division")}
                                onClick={handleCreateDivision}
                                className="add-btn"
                                icon="plus"
                            />
                        </div>
                    </div>
                </div>
                <div className="page-table-layout">
                    <div className="table">
                        <ScrollContainer vertical={false}>
                            <div className="table_ground">
                                <div className="table-body">
                                    <div className="table-header">
                                        <Row
                                            // gutter={24}
                                            type="flex"
                                            justify="space-between"
                                            align="middle"
                                            className="header-row"
                                        >
                                            <Col className="col-item" span={8}>
                                                <p>{t("page.division_name")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.FULL_NAME}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.FULL_NAME}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            {/* <Col className="col-item division-status" span={6}>
                                                <p>{t("page.line_id")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.TEL}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.TEL}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col> */}
                                            <Col className="col-item division-status" span={8}>
                                                <p>{t("page.active")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.TEL}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.TEL}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item division-manage" span={8}>
                                                <p>{t("page.manage")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <DndContext
                                        sensors={sensors}
                                        collisionDetection={closestCenter}
                                        onDragEnd={handleDragEnd}
                                    >
                                        <SortableContext
                                            items={divisions.map((item) => JSON.stringify(item))}
                                            strategy={rectSortingStrategy}
                                        >
                                            {divisions && divisions.length > 0 ? (
                                                <div className="data-table">
                                                    {divisions.map((item, index) => (
                                                        <ComponentRowDivision
                                                            key={index}
                                                            item={item}
                                                            itemJson={JSON.stringify(item)}
                                                            index={index}
                                                            editDivision={handleEditDivision}
                                                        />
                                                    ))}
                                                    <div
                                                        style={{ width: "100%", height: 10 }}
                                                    ></div>
                                                </div>
                                            ) : (
                                                <ComponentEmptyData />
                                            )}
                                        </SortableContext>
                                    </DndContext>
                                </div>
                            </div>
                        </ScrollContainer>
                    </div>
                </div>
            </div>
            <ModuleDrawerForm
                isVisible={showForm}
                editDivision={editDivision}
                onClose={handleCancel}
            />
        </StyledTable>
    );
};
