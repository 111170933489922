import { ComponentModal, SharedButtonDefault } from "@components";
import { enumNotificationAction, enumProcessStatus } from "@configs";
import { IRewardProfileDetail } from "@interfaces";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
interface Iprops {
    isVisible: boolean;
    handleOk: (enumNotificationAction) => void;
    rewardProfile: IRewardProfileDetail | undefined;
    handleCancel: () => void;
    confirmType: enumProcessStatus;
}
export const ModuleConfirmModal = (props: Iprops) => {
    const { isVisible, handleOk, rewardProfile, confirmType, handleCancel } = props;
    const { t } = useTranslation();
    return (
        <ComponentModal
            title={
                confirmType === enumProcessStatus.COMPLETE ? (
                    <h4>{t("page.redeem.confirm_redeem")}</h4>
                ) : (
                    <h4>{t("page.redeem.confirm_reject_redeem")}</h4>
                )
            }
            isVisible={isVisible}
            onOk={() => handleOk(confirmType)}
            onCancel={handleCancel}
            width={"500px"}
        >
            <StyledBody>
                <div className="user-info">
                    <p className="name">{rewardProfile?.profile?.name}</p>
                    <p className="phone">
                        {t("page.id_card_no")} {rewardProfile?.profile?.card_no}
                    </p>
                </div>
                <div className="code">
                    {t("page.privilege_code")}
                    <p className="number">{rewardProfile?.profile_reward?.booking_code}</p>
                </div>
                <div className="btn-layout">
                    <SharedButtonDefault
                        style={{
                            background: "white",
                            fontSize: 16,
                            padding: 14,
                            fontWeight: 600,
                            height: 49,
                            backgroundColor: "#0263E0",
                            color: "#fff",
                        }}
                        text={
                            confirmType === enumProcessStatus.COMPLETE
                                ? t("page.redeem.confirm_redeem")
                                : t("page.redeem.reject_redeem")
                        }
                        type="default"
                        size="default"
                        className="default-btn"
                        onClick={() => handleOk(confirmType)}
                    />
                </div>
            </StyledBody>
        </ComponentModal>
    );
};
const StyledBody = styled.div`
    display: flex;
    flex-direction: column;
    padding: 28px !important;
    align-items: center;
    text-align: center;
    p {
        margin: 0;
    }
    .user-info {
        width: 100%;
        padding-bottom: 20px;
        border-bottom: 1px dashed #e1e1e1;
        .avatar {
            width: 42px;
            height: 42px;
        }
        .name {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            color: #000000;
        }
        .phone {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #646464;
        }
    }
    .code {
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        color: #646464;
        padding: 16px;
        background: #f7f7f8;
        width: 100%;
        text-align: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        margin-top: 20px;
        border-radius: 8px;

        .number {
            font-style: normal;
            font-weight: 700;
            font-size: 35px;
            line-height: 48px;
            display: flex;
            align-items: center;
            color: #0263e0;
        }
    }
    .btn-layout {
        width: 100%;
        padding-top: 20px;
        border-top: 1px solid #e1e1e1;
        button {
            width: 100%;
        }
    }
`;
