import {
    IbiddingUpdate,
    IbiddingCreate,
    IBiddingResultDetailParams,
    IResultCreate,
    IResultChangeStatus,
    IResultDelete,
} from "./../Interfaces/bidding";

import { IBiddingDetailParams, IBiddingParams } from "@interfaces";

import axiosClient from "./axiosClient";

export const biddingAPI = {
    getList: (params: IBiddingParams) => {
        const url = "bidding/getBiddings";
        return axiosClient.get(url, { params });
    },
    getResultList: (params: IBiddingParams) => {
        const url = "information/getInformations";
        return axiosClient.get(url, { params });
    },
    detail: (params: IBiddingDetailParams) => {
        const url = "bidding/getBidding";
        return axiosClient.get(url, { params });
    },
    resultDetail: (params: IBiddingResultDetailParams) => {
        const url = "information/getInformation";
        return axiosClient.get(url, { params });
    },
    update: (params: IbiddingUpdate) => {
        const url = "bidding/updateBidding";
        return axiosClient.patch(url, params);
    },
    uploadFile: (params: FormData) => {
        const url = `uploads/upload_file`;
        return axiosClient.post(url, params);
    },
    uploadFilePdf: (params: FormData) => {
        const url = `uploads/upload_file_pdf`;
        return axiosClient.post(url, params);
    },
    create: (params: IbiddingCreate) => {
        const url = "bidding/createBidding";
        return axiosClient.post(url, params);
    },
    createResult: (params: IResultCreate) => {
        const url = "information/createInformation";
        return axiosClient.post(url, params);
    },
    resultChangeStatus: (params: IResultChangeStatus) => {
        const url = "information/setEnableInformation";
        return axiosClient.patch(url, params);
    },

    resultDelete: (params: IResultDelete) => {
        const url = "information/deleteInformation";
        return axiosClient.post(url, params);
    },
};
