import { ISvg } from "@interfaces";

export const IconBell = (props: ISvg) => {
    return (
        <svg
            width={props.size}
            height={props.size}
            viewBox="0 0 26 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.353 31.4129C14.1909 31.4105 14.9938 31.0766 15.5863 30.4842C16.1788 29.8917 16.5127 29.0888 16.515 28.2509H10.192C10.192 29.0894 10.525 29.8935 11.1178 30.4864C11.7106 31.0794 12.5146 31.4126 13.353 31.4129ZM22.838 21.9279V14.0279C22.838 9.17491 20.245 5.11191 15.724 4.03691V2.95791C15.724 2.32909 15.4742 1.72601 15.0296 1.28136C14.5849 0.836715 13.9819 0.586914 13.353 0.586914C12.7242 0.586914 12.1211 0.836715 11.6765 1.28136C11.2318 1.72601 10.982 2.32909 10.982 2.95791V4.03291C6.44503 5.10791 3.86803 9.15491 3.86803 14.0239V21.9239L0.707031 25.0869V26.6679H26V25.0869L22.838 21.9279Z"
                fill={props.color || "white"}
            />
        </svg>
    );
};
