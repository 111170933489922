export const User = () => (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.294 7.79105C17.294 10.7281 14.9391 13.0831 12 13.0831C9.0619 13.0831 6.70601 10.7281 6.70601 7.79105C6.70601 4.85402 9.0619 2.5 12 2.5C14.9391 2.5 17.294 4.85402 17.294 7.79105ZM12 22.5C7.66237 22.5 4 21.795 4 19.075C4 16.3539 7.68538 15.6739 12 15.6739C16.3386 15.6739 20 16.3789 20 19.099C20 21.82 16.3146 22.5 12 22.5Z"
            fill="white"
        />
    </svg>
);
