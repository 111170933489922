export const HomeCloseIcon = () => (
    <svg
        width="10"
        height="10"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1 1L13 13"
            stroke="#A5A5A5"
            strokeWidth="1.5"
            strokeLinecap="round"
        />
        <path
            d="M1 13L13 1"
            stroke="#A5A5A5"
            strokeWidth="1.5"
            strokeLinecap="round"
        />
    </svg>
);