import { Radio } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import styled from "styled-components";
interface IProps {
    options: ILimit[];
    onChange: (params) => void;
    value: string;
    title?: string;
}
interface ILimit {
    label: string;
    value: string;
}
export const ComponentRadio = (props: IProps) => {
    const { options, onChange, value, title } = props;
    const handleChange = (e: RadioChangeEvent) => {
        onChange(e.target.value);
    };
    return (
        <StyledLayout>
            <div className="label">{title || ""}</div>
            <Radio.Group options={options} onChange={handleChange} value={value} />
        </StyledLayout>
    );
};
const StyledLayout = styled.div`
    margin-bottom: 32px;
    .label {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        color: #000000;
        margin-bottom: 20px;
    }
    .ant-radio-wrapper {
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: #29304d;
    }
`;
