export const CheckingEmail = () => (
    <svg
        width="220"
        height="194"
        viewBox="0 0 220 194"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M114.054 193.272C165.799 193.272 207.747 151.443 207.747 99.8428C207.747 48.243 165.799 6.41315 114.054 6.41315C62.309 6.41315 20.3613 48.243 20.3613 99.8428C20.3613 151.443 62.309 193.272 114.054 193.272Z"
            fill="#E3E3E3"
        />
        <path
            d="M204.163 68.638V157.556C204.163 157.932 204.163 158.12 204.163 158.496C203.974 163.571 201.524 168.083 197.942 171.091C194.737 173.911 190.59 175.603 186.065 175.603H42.6044C39.3996 175.603 36.5718 174.851 33.9326 173.347C32.8015 172.783 31.6704 172.031 30.7278 171.091C28.6542 169.399 26.9575 167.143 26.0149 164.511C25.2609 162.632 24.6953 160.752 24.6953 158.684V68.638C24.6953 68.638 32.236 62.2464 42.9814 53.411C54.1039 44.0116 68.8082 31.9805 81.6273 21.2652C88.9794 15.0616 88.2253 15.8136 99.5363 6.22622C108.208 -1.10527 120.839 -1.10527 129.51 6.22622C131.773 8.1061 134.035 10.174 136.297 12.0538C141.01 15.8136 146.288 20.3253 151.755 24.837C163.255 34.4243 175.885 44.9516 185.877 53.223C196.057 61.6824 203.409 67.886 204.163 68.638Z"
            fill="#F22F46"
        />
        <path
            d="M185.878 53.2215V85.1794L154.584 103.978L145.158 109.618L125.364 121.461L115.938 127.1L111.98 129.544L108.209 127.1L99.3489 121.461L80.4973 109.618L71.637 103.978L42.6055 85.7433V53.4095C53.7279 44.0102 68.4322 31.979 81.2513 21.2637L151.379 24.8355C163.445 34.4228 176.075 44.9501 185.878 53.2215Z"
            fill="#BF1025"
        />
        <path
            d="M179.653 27.8462V83.8664L150.998 100.973L142.515 106.049L124.417 116.764L115.934 121.84L112.352 123.908L108.959 121.84L100.853 116.764L83.6977 106.049L75.5915 100.973L49.1992 84.4303V27.8462C49.1992 22.0186 53.9121 17.1309 59.9447 17.1309H168.907C174.751 17.1309 179.653 22.0186 179.653 27.8462Z"
            fill="#F9FBFE"
        />
        <path
            d="M156.277 58.6764H75.7808C74.4612 58.6764 73.3301 57.5484 73.3301 56.0445C73.3301 54.5406 74.4612 53.4127 75.7808 53.4127H156.277C157.597 53.4127 158.728 54.5406 158.728 56.0445C158.728 57.5484 157.597 58.6764 156.277 58.6764Z"
            fill="#E7EBF2"
        />
        <path
            d="M156.277 74.4678H75.7808C74.4612 74.4678 73.3301 73.3398 73.3301 71.8359C73.3301 70.332 74.4612 69.2041 75.7808 69.2041H156.277C157.597 69.2041 158.728 70.332 158.728 71.8359C158.728 73.3398 157.597 74.4678 156.277 74.4678Z"
            fill="#E7EBF2"
        />
        <path
            d="M156.277 90.2583H75.7808C74.4612 90.2583 73.3301 89.1304 73.3301 87.6265C73.3301 86.1226 74.4612 84.9947 75.7808 84.9947H156.277C157.597 84.9947 158.728 86.1226 158.728 87.6265C158.728 89.1304 157.597 90.2583 156.277 90.2583Z"
            fill="#E7EBF2"
        />
        <path d="M151 100.973L142.517 106.048H83.7L75.5938 100.973H75.7823H151Z" fill="#E7EBF2" />
        <path
            d="M124.417 116.764L115.934 122.028H108.959L100.853 116.764H124.417Z"
            fill="#E7EBF2"
        />
        <path
            d="M138.933 40.6295H93.312C91.9924 40.6295 90.8613 39.5016 90.8613 37.9977C90.8613 36.4938 91.9924 35.3659 93.312 35.3659H138.933C140.253 35.3659 141.384 36.4938 141.384 37.9977C141.384 39.5016 140.253 40.6295 138.933 40.6295Z"
            fill="url(#paint0_linear_879_42730)"
        />
        <path
            d="M204.163 68.6368V158.495C203.974 163.57 201.524 168.082 197.942 171.09C194.737 173.91 190.59 175.601 186.065 175.601H42.6044C39.3996 175.601 36.5718 174.85 33.9326 173.346C32.8015 172.782 31.6704 172.03 30.7278 171.09C28.6542 169.398 26.9575 167.142 26.0149 164.51C25.2609 162.63 24.6953 160.75 24.6953 158.683V158.495V68.6368L105.003 118.829L111.978 123.153L114.429 121.649L116.691 120.333L204.163 68.6368Z"
            fill="#738DFF"
        />
        <path
            d="M198.13 171.09C194.926 173.91 190.778 175.601 186.254 175.601H42.6044C39.3996 175.601 36.5718 174.85 33.9326 173.346C32.8015 172.782 31.6704 172.03 30.7278 171.09C28.6542 169.398 26.9575 167.142 26.0149 164.51C25.2609 162.63 24.6953 160.75 24.6953 158.683V158.495V68.6368L34.1211 74.2764L114.429 121.837L116.691 123.153L116.88 123.341L188.893 165.826L198.13 171.09Z"
            fill="url(#paint1_linear_879_42730)"
        />
        <path
            d="M116.694 120.334V126.538L33.9353 173.346C32.8042 172.782 31.6731 172.03 30.7305 171.091C28.6568 169.399 26.9602 167.143 26.0176 164.511L106.514 116.95L113.489 121.274L114.432 121.65L116.694 120.334Z"
            fill="url(#paint2_linear_879_42730)"
        />
        <path
            d="M24.6953 68.6368L114.429 121.837"
            stroke="#E53D51"
            strokeWidth="0.5"
            strokeMiterlimit="10"
        />
        <path
            d="M204.164 68.6368V158.495C203.975 163.57 201.524 168.082 197.943 171.09C194.738 173.91 190.59 175.601 186.066 175.601H42.6051C39.4003 175.601 36.5725 174.85 33.9333 173.346C32.8022 172.782 31.6711 172.03 30.7285 171.09L39.9658 165.638L111.979 123.153L114.43 121.649L116.692 120.333L194.738 74.0884L204.164 68.6368Z"
            fill="url(#paint3_linear_879_42730)"
        />
        <path
            d="M204.164 68.6368L116.692 120.333L114.43 121.649L111.979 123.153L30.7285 171.09"
            stroke="#E53D51"
            strokeWidth="0.5"
            strokeMiterlimit="10"
        />
        <path
            d="M182.673 131.425H134.978C133.847 131.425 132.904 130.485 132.904 129.357C132.904 128.229 133.847 127.289 134.978 127.289H182.673C183.804 127.289 184.746 128.229 184.746 129.357C184.746 130.485 183.804 131.425 182.673 131.425Z"
            fill="url(#paint4_linear_879_42730)"
        />
        <path
            d="M182.673 145.148H134.978C133.847 145.148 132.904 144.209 132.904 143.081C132.904 141.953 133.847 141.013 134.978 141.013H182.673C183.804 141.013 184.746 141.953 184.746 143.081C184.746 144.209 183.804 145.148 182.673 145.148Z"
            fill="url(#paint5_linear_879_42730)"
        />
        <path
            d="M212.836 60.1777C216.792 60.1777 219.999 56.9794 219.999 53.0342C219.999 49.0889 216.792 45.8907 212.836 45.8907C208.879 45.8907 205.672 49.0889 205.672 53.0342C205.672 56.9794 208.879 60.1777 212.836 60.1777Z"
            fill="#0D66D9"
        />
        <path
            d="M24.5059 34.0479C27.1087 34.0479 29.2188 31.9437 29.2188 29.3482C29.2188 26.7526 27.1087 24.6485 24.5059 24.6485C21.903 24.6485 19.793 26.7526 19.793 29.3482C19.793 31.9437 21.903 34.0479 24.5059 34.0479Z"
            fill="#FAC159"
        />
        <path
            d="M3.20478 46.0791C4.97473 46.0791 6.40956 44.6483 6.40956 42.8833C6.40956 41.1184 4.97473 39.6876 3.20478 39.6876C1.43483 39.6876 0 41.1184 0 42.8833C0 44.6483 1.43483 46.0791 3.20478 46.0791Z"
            fill="#E7F1FF"
        />
        <path
            d="M204.538 29.9121C206.204 29.9121 207.554 28.5655 207.554 26.9043C207.554 25.2432 206.204 23.8965 204.538 23.8965C202.872 23.8965 201.521 25.2432 201.521 26.9043C201.521 28.5655 202.872 29.9121 204.538 29.9121Z"
            fill="#E0F6EE"
        />
        <path
            d="M216.604 127.853C218.27 127.853 219.62 126.506 219.62 124.845C219.62 123.184 218.27 121.837 216.604 121.837C214.938 121.837 213.588 123.184 213.588 124.845C213.588 126.506 214.938 127.853 216.604 127.853Z"
            fill="#FAC159"
        />
        <path
            opacity="0.3"
            d="M194.739 74.0884V83.4878L204.165 78.7881V68.6368L194.739 74.0884ZM194.739 120.521V135.748L204.165 131.049V115.822L194.739 120.521ZM194.739 146.463V158.119C194.551 159.435 194.174 160.938 193.419 162.066C193.231 162.254 193.231 162.442 193.042 162.63L204.165 156.991V141.764L194.739 146.463ZM192.477 173.722L189.649 175.226C192.854 174.662 195.493 173.158 197.944 171.278C197.944 171.278 197.944 171.278 198.132 171.278C198.321 171.278 198.321 171.09 198.509 170.902L192.477 173.722ZM24.6973 145.899V158.495V158.683C24.6973 159.435 24.8858 160.187 25.0743 160.938L34.1231 156.239V141.012L24.6973 145.899ZM24.6973 93.6391V108.866L34.1231 104.166V88.9394L24.6973 93.6391ZM24.6973 68.6368V82.9238L34.1231 78.2241V74.2764L24.6973 68.6368ZM24.6973 119.769V134.996L34.1231 130.297V115.07L24.6973 119.769ZM42.6063 166.202C41.6637 166.202 40.7212 166.014 39.9671 165.826C39.4016 165.638 39.0245 165.45 38.459 165.262C38.459 165.262 38.459 165.262 38.2704 165.262L29.2217 169.962C29.7872 170.526 30.1642 170.902 30.7298 171.466C31.6724 172.406 32.8035 172.97 33.9346 173.722C34.6886 174.286 35.6312 174.662 36.5738 174.85L54.1058 166.39H42.6063V166.202ZM77.8589 166.202L58.0647 175.601H79.5556L99.3498 166.202H77.8589ZM122.726 166.202L102.932 175.601H124.422L144.217 166.202H122.726ZM167.781 166.202L147.987 175.601H165.331L185.125 166.202H167.781ZM194.739 94.391V109.618L204.165 104.918V89.6913L194.739 94.391Z"
            fill="#BF1025"
        />
        <path
            d="M192.473 173.723L198.317 170.715C198.128 170.903 198.128 170.903 197.94 171.091C197.94 171.091 197.94 171.091 197.751 171.091L192.473 173.723Z"
            fill="#526DFF"
        />
        <circle cx="34" cy="161.728" r="26" fill="#57BF94" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M47.1773 153.232C48.0957 154.046 48.1557 155.423 47.3114 156.308L33.0051 171.303C32.5885 171.74 32.0051 171.994 31.3896 172.006C30.7742 172.019 30.1802 171.789 29.7449 171.369L21.9643 163.871C21.0822 163.021 21.0822 161.643 21.9643 160.793C22.8465 159.943 24.2767 159.943 25.1589 160.793L31.2737 166.686L43.9856 153.361C44.8299 152.476 46.2589 152.419 47.1773 153.232Z"
            fill="white"
        />
        <defs>
            <linearGradient
                id="paint0_linear_879_42730"
                x1="90.9002"
                y1="38.0355"
                x2="141.259"
                y2="38.0355"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#A2CAFE" />
                <stop offset="1" stopColor="#0D66D9" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_879_42730"
                x1="26.7113"
                y1="122.07"
                x2="201.738"
                y2="122.07"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FD6E7E" />
                <stop offset="1" stopColor="#F22F46" />
            </linearGradient>
            <linearGradient
                id="paint2_linear_879_42730"
                x1="76.5524"
                y1="150.328"
                x2="69.5227"
                y2="140.039"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F22F46" stopOpacity="0.56" />
                <stop offset="1" stopColor="white" stopOpacity="0" />
            </linearGradient>
            <linearGradient
                id="paint3_linear_879_42730"
                x1="196.806"
                y1="122.07"
                x2="25.1111"
                y2="122.07"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F45064" />
                <stop offset="1" stopColor="#FC5266" />
            </linearGradient>
            <linearGradient
                id="paint4_linear_879_42730"
                x1="132.784"
                y1="129.365"
                x2="184.743"
                y2="129.365"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F5C4CA" />
                <stop offset="1" stopColor="#FD8997" />
            </linearGradient>
            <linearGradient
                id="paint5_linear_879_42730"
                x1="132.784"
                y1="143.004"
                x2="184.743"
                y2="143.004"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F5C4CA" />
                <stop offset="1" stopColor="#FD8997" />
            </linearGradient>
        </defs>
    </svg>
);
