interface IProps {
    color?: string;
    size?: number;
}

const defaultColor = "black";
const defaultSize = "27";

export const IconStarCircle = (props: IProps) => {
    const { color } = props;
    return (
        <svg
            width="26"
            height="27"
            viewBox="0 0 26 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.2539 26.199C9.40417 26.1936 6.65815 25.1295 4.54886 23.2132C2.43957 21.297 1.11753 18.6653 0.839506 15.8291C0.561484 12.9929 1.34732 10.1546 3.04434 7.86523C4.74136 5.57583 7.2285 3.99861 10.023 3.43993C11.1578 3.19825 12.3244 3.14323 13.477 3.27697C14.123 3.35897 14.6899 3.56193 14.6129 4.34093C14.5359 5.11993 13.962 5.22694 13.3 5.16894C11.4302 4.96484 9.54173 5.31423 7.86868 6.17358C6.19563 7.03292 4.81168 8.36449 3.88846 10.0032C2.96524 11.6418 2.54335 13.5155 2.6752 15.3917C2.80705 17.2679 3.48683 19.0642 4.63016 20.5576C5.77348 22.0511 7.33004 23.1759 9.10684 23.7928C10.8836 24.4097 12.8025 24.4915 14.6253 24.0279C16.4481 23.5644 18.0947 22.5758 19.3609 21.1849C20.627 19.7941 21.4571 18.0622 21.748 16.204C21.9102 15.221 21.9102 14.218 21.748 13.235C21.6692 13.0184 21.6542 12.7838 21.7045 12.559C21.7548 12.3341 21.8685 12.1283 22.032 11.9659C22.1532 11.8574 22.3015 11.7837 22.4612 11.7527C22.6209 11.7217 22.786 11.7347 22.939 11.79C23.1401 11.8694 23.313 12.0069 23.4356 12.1851C23.5581 12.3632 23.6248 12.5738 23.627 12.79C23.9036 14.441 23.8163 16.1323 23.3715 17.7461C22.9267 19.3598 22.1349 20.857 21.0515 22.133C19.9681 23.4091 18.6192 24.4333 17.099 25.134C15.5788 25.8347 13.9238 26.195 12.2499 26.1899L12.2539 26.199Z"
                fill="black"
            />
            <path
                d="M18.106 12.84C17.251 13.715 16.506 14.514 15.715 15.27C15.5501 15.4017 15.4278 15.5793 15.3634 15.7803C15.299 15.9814 15.2953 16.1969 15.3529 16.4C15.5809 17.468 15.73 18.553 15.94 19.8C14.797 19.209 13.79 18.715 12.813 18.168C12.6618 18.0622 12.4823 18.0042 12.2978 18.0015C12.1132 17.9989 11.9322 18.0517 11.778 18.153C10.938 18.632 10.07 19.064 9.21 19.507C9.073 19.577 8.87496 19.685 8.77396 19.636C8.55596 19.53 8.70504 19.308 8.73404 19.136C8.89004 18.209 9.03403 17.279 9.22403 16.36C9.27782 16.1682 9.27341 15.9646 9.21146 15.7752C9.14951 15.5858 9.03279 15.419 8.87601 15.296C8.20101 14.68 7.55602 14.03 6.91202 13.382C6.77502 13.244 6.49001 13.143 6.59501 12.888C6.67601 12.688 6.92699 12.762 7.10599 12.735C8.00599 12.602 8.91396 12.4651 9.82096 12.3541C10.0201 12.3434 10.212 12.2754 10.3734 12.1583C10.5349 12.0412 10.659 11.88 10.731 11.694C11.187 10.694 11.696 9.71902 12.283 8.53302C12.797 9.57402 13.283 10.446 13.661 11.363C13.7689 11.6762 13.9732 11.9472 14.2447 12.1369C14.5162 12.3266 14.8409 12.4254 15.172 12.419C16.125 12.497 17.065 12.686 18.106 12.84Z"
                fill={color}
            />
            <path
                d="M23.7368 6.286C22.3658 6.286 22.3668 6.28606 22.3648 7.62706C22.386 7.97521 22.3766 8.32449 22.3368 8.67101C22.325 8.79947 22.2879 8.92435 22.2275 9.03832C22.1671 9.15229 22.0846 9.25315 21.9848 9.33495C21.8851 9.41674 21.7701 9.47783 21.6465 9.51476C21.5229 9.55169 21.3931 9.5637 21.2649 9.55004C21.136 9.55875 21.0066 9.54105 20.8847 9.49803C20.7629 9.45502 20.6512 9.38764 20.5564 9.29991C20.4615 9.21219 20.3856 9.106 20.3332 8.9879C20.2809 8.8698 20.2531 8.74221 20.2518 8.61302C20.2139 8.02345 20.2075 7.43228 20.2328 6.84203C20.2448 6.42703 20.0858 6.26202 19.6668 6.28002C19.1308 6.30302 18.5929 6.29002 18.0559 6.28002C17.9137 6.29055 17.7708 6.27182 17.6361 6.22496C17.5014 6.1781 17.3778 6.10409 17.2728 6.00756C17.1678 5.91102 17.0837 5.79398 17.0257 5.66368C16.9678 5.53338 16.9372 5.39259 16.9358 5.24999C16.9328 5.10178 16.9608 4.95457 17.0178 4.81774C17.0748 4.6809 17.1596 4.55743 17.2669 4.45519C17.3743 4.35294 17.5017 4.27417 17.6411 4.22386C17.7805 4.17355 17.929 4.15291 18.0769 4.16307C18.4787 4.12665 18.8829 4.12665 19.2848 4.16307C20.0898 4.27207 20.3309 3.92598 20.2389 3.17198C20.1976 2.74398 20.1976 2.313 20.2389 1.885C20.2603 1.62218 20.3794 1.3769 20.5727 1.19762C20.7661 1.01833 21.0196 0.918119 21.2833 0.916613C21.547 0.915106 21.8016 1.0125 21.997 1.18956C22.1924 1.36662 22.3144 1.61047 22.3388 1.87303C22.3761 2.46267 22.3804 3.05391 22.3518 3.64403C22.3388 4.02803 22.4878 4.17204 22.8678 4.15904C23.4048 4.14004 23.9429 4.14004 24.4789 4.15904C24.6265 4.1445 24.7755 4.16153 24.9161 4.20909C25.0567 4.25665 25.1855 4.33357 25.294 4.4348C25.4026 4.53603 25.4883 4.65932 25.5455 4.79625C25.6027 4.93319 25.6301 5.08065 25.6258 5.22899C25.6198 5.85999 25.1969 6.24206 24.4529 6.27806C24.2209 6.29506 23.9788 6.286 23.7368 6.286Z"
                fill="black"
            />
        </svg>
    );
};

export const IconStarSub = (props: IProps) => {
    const { color, size } = props;
    return (
        <>
            <svg
                width={size || defaultSize}
                height={size || defaultSize}
                viewBox="0 0 27 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M22.2571 7.456C19.3891 7.256 17.4101 6.24099 16.6031 3.35599C16.4306 2.87126 16.1751 2.42024 15.8481 2.02302C15.5385 1.56364 15.1184 1.18956 14.6262 0.935279C14.1341 0.681001 13.5859 0.554688 13.0321 0.568C12.175 0.590485 11.3506 0.902215 10.693 1.45249C10.0354 2.00277 9.58333 2.75925 9.4101 3.59901C9.13663 4.73726 8.4679 5.74145 7.52308 6.43262C6.57826 7.1238 5.41873 7.45703 4.2511 7.37299C2.5581 7.40299 0.995066 7.89501 0.336066 9.66401C-0.395934 11.63 0.55406 13.113 2.05506 14.244C3.05794 14.8577 3.80708 15.8111 4.16608 16.9307C4.52508 18.0503 4.47006 19.2616 4.01105 20.344C3.45205 21.992 3.49807 23.738 5.17707 24.898C5.84839 25.3727 6.6604 25.6067 7.48139 25.562C8.30238 25.5174 9.0842 25.1967 9.70008 24.652C12.0311 22.875 14.1721 23.052 16.4791 24.652C17.0997 25.1881 17.8814 25.5018 18.7004 25.5437C19.5195 25.5855 20.3291 25.353 21.0011 24.883C21.665 24.3956 22.1393 23.6931 22.3431 22.8951C22.547 22.0971 22.4678 21.2531 22.1191 20.507C21.1281 17.623 22.0681 15.599 24.3701 13.897C24.7731 13.6377 25.1197 13.2997 25.3889 12.9033C25.6582 12.5069 25.8446 12.0602 25.9371 11.59C26.3081 9.287 25.0981 7.656 22.2571 7.456ZM22.3971 12.573C22.172 12.8638 21.909 13.1231 21.6151 13.344C19.1441 14.944 18.6841 17.132 19.6081 19.796C19.7971 20.4297 19.9006 21.0859 19.9161 21.747C19.9861 22.832 19.4261 23.275 18.4161 22.905C17.2194 22.5989 16.0819 22.0961 15.0501 21.417C13.4991 20.117 12.2061 20.44 10.7371 21.395C9.87098 21.9455 8.96087 22.4234 8.01606 22.824C6.84306 23.334 6.17707 22.938 6.19807 21.088C8.21107 17.354 6.46006 14.382 3.06007 11.835C2.18007 11.176 2.40807 10.256 3.60407 9.89002C3.81288 9.81861 4.03145 9.77981 4.25208 9.775C8.15208 9.837 10.5791 8.065 11.8041 4.275C12.3731 2.517 13.4411 2.601 14.3631 4.188C14.4833 4.37292 14.579 4.57259 14.6481 4.78202C15.5781 8.21702 17.8721 9.74699 21.3691 9.72199C22.1301 9.71699 23.1231 9.64699 23.4691 10.58C23.8181 11.541 22.9111 11.996 22.3971 12.578V12.573Z"
                    fill="#050505"
                />
                <path
                    d="M12.9791 18.458C12.3406 18.4718 11.7088 18.3254 11.1414 18.0321C10.574 17.7388 10.0891 17.308 9.73116 16.779C9.61779 16.6564 9.53003 16.5123 9.47304 16.3553C9.41605 16.1984 9.39096 16.0316 9.39925 15.8648C9.40754 15.698 9.44905 15.5345 9.52132 15.384C9.5936 15.2334 9.69518 15.0988 9.82015 14.988C9.92874 14.8861 10.0572 14.8078 10.1975 14.7579C10.3378 14.708 10.4869 14.6876 10.6354 14.6981C10.7839 14.7085 10.9287 14.7495 11.0606 14.8185C11.1926 14.8875 11.3089 14.983 11.4022 15.099C12.5732 16.289 13.6601 16.228 14.8021 15.055C14.8977 14.9407 15.0172 14.8488 15.1521 14.7857C15.2871 14.7227 15.4342 14.69 15.5832 14.69C15.7321 14.69 15.8792 14.7227 16.0142 14.7857C16.1491 14.8488 16.2686 14.9407 16.3642 15.055C16.5778 15.2637 16.707 15.5437 16.7271 15.8417C16.7472 16.1396 16.6568 16.4345 16.4732 16.67C16.0992 17.2487 15.5793 17.7186 14.9659 18.0325C14.3525 18.3464 13.6673 18.4932 12.9791 18.458Z"
                    fill={color || defaultColor}
                />
            </svg>
        </>
    );
};
