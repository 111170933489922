export const TwitterShare = () => (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M21 42C32.598 42 42 32.598 42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 32.598 9.40202 42 21 42Z"
            fill="#49A1F9"
        />
        <path
            d="M20.9999 41.5799C32.3659 41.5799 41.5799 32.3659 41.5799 20.9999C41.5799 9.6339 32.3659 0.419922 20.9999 0.419922C9.6339 0.419922 0.419922 9.6339 0.419922 20.9999C0.419922 32.3659 9.6339 41.5799 20.9999 41.5799Z"
            fill="#49A1F9"
        />
        <path
            d="M31.9375 14.3466C31.1154 14.6971 30.2459 14.9288 29.3562 15.0345C30.2949 14.4835 30.9983 13.6166 31.3359 12.5946C30.4539 13.1106 29.4883 13.4742 28.4812 13.6695C27.8081 12.9521 26.9117 12.4745 25.9326 12.3118C24.9535 12.149 23.9472 12.3101 23.0713 12.77C22.1955 13.2299 21.4998 13.9624 21.0932 14.8527C20.6867 15.743 20.5924 16.7407 20.825 17.6894C19.0416 17.6008 17.297 17.1444 15.7048 16.35C14.1125 15.5556 12.7081 14.4409 11.5828 13.0783C11.1881 13.7556 10.9807 14.5227 10.9812 15.3032C10.9799 16.0281 11.1609 16.7421 11.5082 17.3815C11.8555 18.021 12.3583 18.5662 12.9719 18.9685C12.2587 18.9494 11.5608 18.7614 10.9375 18.4203V18.4741C10.9428 19.4897 11.305 20.4723 11.9627 21.2558C12.6204 22.0392 13.5332 22.5753 14.5469 22.7735C14.1567 22.8902 13.7516 22.9517 13.3438 22.9562C13.0614 22.953 12.7798 22.9278 12.5016 22.881C12.7902 23.7546 13.3488 24.5182 14.0996 25.0653C14.8504 25.6124 15.7561 25.9159 16.6906 25.9336C15.1126 27.1538 13.1642 27.8198 11.1562 27.8253C10.7906 27.8265 10.4253 27.805 10.0625 27.7608C12.1127 29.0617 14.5018 29.7523 16.9422 29.7493C18.6262 29.7665 20.2969 29.4538 21.8565 28.8293C23.4162 28.2049 24.8336 27.2813 26.026 26.1125C27.2184 24.9438 28.1618 23.5532 28.8012 22.0221C29.4406 20.491 29.7632 18.85 29.75 17.195C29.75 17.0123 29.75 16.8188 29.75 16.6253C30.6083 15.9963 31.3485 15.2252 31.9375 14.3466Z"
            fill="white"
        />
    </svg>
);
