import { enumNewsStatus } from "@configs";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
interface Iprops {
    setStatus: (params: enumNewsStatus) => void;
    setFilterStatus: (params: boolean) => void;
    activeCount: number;
    inactiveCount: number;
    status?: enumNewsStatus;
}
export const ComponentStatusFilter = (props: Iprops) => {
    const { setStatus, setFilterStatus, activeCount, inactiveCount, status } = props;
    const { t } = useTranslation();

    return (
        <StyledFilter>
            <div className="status-filter">
                <div
                    onClick={() => setStatus(enumNewsStatus.ALL)}
                    className={status === enumNewsStatus.ALL ? "active filter-elem" : "filter-elem"}
                >
                    {t("page.all")}
                </div>
                <div
                    onClick={() => {
                        setFilterStatus(true);
                        setStatus(enumNewsStatus.ACTIVE);
                    }}
                    className={
                        status === enumNewsStatus.ACTIVE ? "active filter-elem" : "filter-elem"
                    }
                >
                    {t("page.active")}
                    <span className="eng">({activeCount})</span>
                </div>
                <div
                    onClick={() => {
                        setFilterStatus(false);
                        setStatus(enumNewsStatus.INACTIVE);
                    }}
                    className={
                        status === enumNewsStatus.INACTIVE ? "active filter-elem" : "filter-elem"
                    }
                >
                    {t("page.inactive")}
                    <span className="eng">({inactiveCount})</span>
                </div>
            </div>
        </StyledFilter>
    );
};
const StyledFilter = styled.div`
    .status-filter {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .filter-elem {
            padding: 12px;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            cursor: pointer;
            &.active {
                transition: 0.3s;
                border-bottom: 4px solid #0d6efd;
                padding-bottom: 11px;
                padding-bottom: 8px;
            }
            .count {
                margin-left: 6px;
                font-style: normal;
                font-weight: 400;
                font-size: 22px;
                line-height: 33px;
                color: #c5c5c5;
            }
        }
    }
`;
