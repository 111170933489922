import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { StyledNewDetail } from "@components";
import { IOrganizationDetail } from "@interfaces";
import { useNotify, usePermission } from "@utils";
import { orgApi } from "@api";
import { setLoading, selectAuth } from "@redux";
import { ModuleSettingForm } from "src/Modules/settings";

export const PageSettingsCreate = () => {
    const organization_id = useSelector(selectAuth).auth?.organization_data?._id;
    const permission = useSelector(selectAuth).permission;
    const dispatch = useDispatch();
    const { warning } = useNotify();
    // const { t } = useTranslation();
    // page state
    const [organization, setOrgnization] = useState<IOrganizationDetail>();
    // variable
    usePermission({ permission: permission?.permission_list.organization.is_manage_organization });

    useEffect(() => {
        fetchSettings();
    }, []);
    const fetchSettings = async () => {
        try {
            dispatch(setLoading(true));
            const response = await orgApi.getOrganizationDetail({
                organization_id: organization_id || "",
            });
            const data: IOrganizationDetail = response.data.result;
            setOrgnization(data);
            dispatch(setLoading(false));
        } catch (err: any) {
            warning(err.msg);
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    return <StyledNewDetail>{<ModuleSettingForm org={organization} />}</StyledNewDetail>;
};
