import { useState, useEffect } from "react";
import {
    StyledTable,
    ComponentEmptyData,
    ComponentRowBooking,
    ComponentModal,
    ComponentInfo,
    ComponentSearchBooking,
    ComponentDays,
} from "@components";
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    TouchSensor,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    rectSortingStrategy,
    //@ts-ignore
} from "@dnd-kit/sortable";
import { Row, Col, Pagination, Checkbox } from "antd";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import {
    enumSortBy,
    enumContactSortFields,
    PAGINATION,
    PAGE_START,
    INITIAL_LIST_PARAMS,
    enumStatus,
    ALL,
} from "@configs";
import {
    IPaginationNext,
    IBooking,
    IServiceDepartment,
    IService,
    IDays,
    IBookingDetail,
} from "@interfaces";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth, selectContact, selectOrg, setLoading } from "@redux";
import { useNotify, usePermission } from "@utils";
import { bookingAPI, departmentAPI, serviceAPI } from "@api";
import moment from "moment";

const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: PAGINATION,
};
const INIT_YEAR_MONTH = moment().format("YYYY-MM");
const INITIAL_DATE = moment().format("YYYY-MM-DD");
export const PageBookingList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const search_params = useSelector(selectContact).paramsContact;
    const [params, setParams] = useState(search_params);
    const org_id = useSelector(selectAuth).auth?.organization_data?._id;
    const categories = useSelector(selectOrg).category;
    const pemisssion = useSelector(selectAuth).permission;
    const [textSearch, setTextSearch] = useState<string>("");

    const [searchDate, setSearchDate] = useState<string[]>([]);
    const [filterDayFrom, setFilterDayFrom] = useState<string>(INITIAL_DATE);
    const [filterDayTo, setFilterDayTo] = useState<string>(INITIAL_DATE);
    const [departSearch, setDepartSearch] = useState<string>("");
    const [serviceSearch, setServiceSearch] = useState<string>("");

    const [pagination, setPagination] = useState<IPaginationNext>(INITAL_PAGE);
    const [bookings, setBookings] = useState<IBooking[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [viewDetail, setViewDetail] = useState(false);
    const [detail, setDetail] = useState<IBookingDetail>();
    const { success, error } = useNotify();
    const [departments, setDepartments] = useState<IServiceDepartment[]>();
    const [services, setServices] = useState<IService[]>();
    const [days, setDays] = useState<IDays[]>();
    const [yearMonth, setYearMonth] = useState<string>(INIT_YEAR_MONTH);
    usePermission({ permission: pemisssion?.permission_list.service.is_view });
    useEffect(() => {
        fetchBooking(pagination, departSearch, textSearch, filterDayFrom, filterDayTo);
    }, [pagination, departSearch, textSearch, filterDayFrom, filterDayTo]);

    useEffect(() => {
        fetchDayofMonth(yearMonth);
    }, [yearMonth]);

    useEffect(() => {
        fetchServiceDepartment();
        fetchServices();
    }, []);

    const fetchServiceDepartment = async () => {
        const res = await departmentAPI.getServiceDepartments({
            organization_id: org_id || "",
            ...pagination,
        });
        setDepartments(res.data.result);
    };
    const fetchServices = async () => {
        const res = await serviceAPI.getServices({
            organization_id: org_id || "",
            ...pagination,
        });
        setServices(res.data.result);
    };
    const fetchBooking = async (
        pagination: IPaginationNext,
        catSearch: string,
        textSearch: string,
        filterDayFrom: string,
        filterDayTo: string
    ) => {
        dispatch(setLoading(true));
        try {
            const response = await bookingAPI.getBookingServices({
                organization_id: org_id || "",
                ...pagination,
                ...(catSearch && { service_id: catSearch }),
                ...(textSearch && { text_search: textSearch }),
                ...(filterDayFrom && { date_from: filterDayFrom }),
                ...(filterDayTo && { date_to: filterDayTo }),
            });
            setBookings(response.data.result);
            setTotal(response.data.total);
        } catch (err: any) {
            error(err.msg);
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };
    const fetchDayofMonth = async (date: string) => {
        const res = await bookingAPI.getDayInMonthTemplate({
            this_month: parseInt(date.split("-")[1]),
            this_year: parseInt(date.split("-")[0]),
        });
        setDays(res.data.result);
    };
    const handleSort = (sortField: enumContactSortFields, sortBy: enumSortBy) => {
        const newSortParams = { ...params, sortField, sortBy };
        setParams(newSortParams);
        // fetchContacts(newSortParams);
    };
    const handleView = async (data: IBooking) => {
        setViewDetail(true);
        dispatch(setLoading(true));
        try {
            const res = await bookingAPI.getBookingService({
                organization_id: org_id || "",
                booking_service_id: data._id,
            });
            setDetail(res.data.result);
            dispatch(setLoading(false));
            // setDetail(res.data.result);
        } catch (err) {
            dispatch(setLoading(false));
        }
    };
    const handleStatus = async (value: string, id: string) => {
        if (value === enumStatus.CANCELED) {
            const res = await bookingAPI.cancelBookingService({
                organization_id: org_id || "",
                booking_service_id: id,
            });
            if (res.data.error === "0") {
                success(res.data.msg);
                callbackGetList();
            } else {
                error(res.data.msg);
            }
        }
        if (value === enumStatus.COMPLETE) {
            const res = await bookingAPI.completeBookingService({
                organization_id: org_id || "",
                booking_service_id: id,
            });
            if (res.data.error === "0") {
                success(res.data.msg);
                callbackGetList();
            } else {
                error(res.data.msg);
            }
        }
    };
    const callbackGetList = () => {
        fetchBooking(pagination, departSearch, textSearch, filterDayFrom, filterDayTo);
    };
    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            const swapItem = (items: IBooking[]) => {
                const oldIndex = items.findIndex((item) => JSON.stringify(item) === active.id);
                const newIndex = items.findIndex((item) => JSON.stringify(item) === over.id);
                return arrayMove(items, oldIndex, newIndex);
            };
            setBookings(swapItem);
        }
    };
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
        useSensor(TouchSensor)
    );
    const handleSearchDept = (value) => {
        if (value === ALL) {
            setDepartSearch("");
        } else {
            setDepartSearch(value);
        }
    };
    const handleSearchService = (value) => {
        if (value === ALL) {
            setServiceSearch("");
        } else {
            setServiceSearch(value);
        }
    };

    const onChangeDateRange = (dates, dateStrings: [string, string]) => {
        setSearchDate(dateStrings);
    };
    const handlePagination = (page) => {
        setPagination({ xpage: page, xlimit: pagination.xlimit });
    };
    const handleSelect = (date: string) => {
        setFilterDayFrom(date);
        setFilterDayTo(date);
    };
    const handleMonth = (date: string) => {
        setYearMonth(date);
    };
    return (
        <StyledTable>
            <div className="page-layout small-layout">
                <div className="page-header-layout">
                    <div className="page-header">
                        <div>
                            <h3>{`ตารางจองทำธุรกรรม`}</h3>
                        </div>
                        <div className="flex-header" />
                    </div>
                    <div className="search-layout">
                        <ComponentSearchBooking
                            setTextSearch={setTextSearch}
                            handleSearchDept={handleSearchDept}
                            handleSearchService={handleSearchService}
                            departments={departments}
                            services={services}
                            onChangeDateRange={onChangeDateRange}
                            total={total}
                            isRangePicker={true}
                            yearMonth={yearMonth}
                            setYearMonth={handleMonth}
                        />
                    </div>
                    <div>
                        <ComponentDays
                            days={days}
                            handleSelect={handleSelect}
                            value={filterDayFrom}
                        />
                    </div>
                </div>
                <div className="page-table-layout">
                    <div className="table">
                        <ScrollContainer vertical={false}>
                            <div className="table_ground">
                                <div className="table-body">
                                    <div className="table-header">
                                        <Row
                                            gutter={16}
                                            type="flex"
                                            justify="space-between"
                                            align="middle"
                                            className="header-row"
                                        >
                                            <Col className="col-item row-checkbox" span={8}>
                                                <Checkbox />
                                                <p>{`ชื่อผู้ทำธุรกรรม`}</p>
                                            </Col>
                                            <Col className="col-item" span={4}>
                                                <p>{`เลขบัตรประชาชน`}</p>
                                            </Col>
                                            <Col className="col-item" span={4}>
                                                <p>{`เบอร์โทรศัพท์`}</p>
                                            </Col>
                                            <Col className="col-item" span={3}>
                                                <p>{`เวลาที่จอง`}</p>
                                            </Col>
                                            <Col className="col-item" span={3}>
                                                <p>{`สถานะ`}</p>
                                            </Col>
                                            <Col className="col-item" span={1}>
                                                {/* <p>{t("page.news_manage")}</p> */}
                                            </Col>
                                        </Row>
                                    </div>
                                    <DndContext
                                        sensors={sensors}
                                        collisionDetection={closestCenter}
                                        onDragEnd={handleDragEnd}
                                    >
                                        <SortableContext
                                            items={bookings.map((item) => JSON.stringify(item))}
                                            strategy={rectSortingStrategy}
                                        >
                                            {bookings && bookings.length > 0 ? (
                                                <div className="data-table">
                                                    {bookings.map((item, index) => (
                                                        <ComponentRowBooking
                                                            key={index}
                                                            data={item}
                                                            itemJson={JSON.stringify(item)}
                                                            categories={categories}
                                                            handleView={handleView}
                                                            callbackGetList={callbackGetList}
                                                            index={index}
                                                            handleStatus={handleStatus}
                                                        />
                                                    ))}
                                                    <div
                                                        style={{ width: "100%", height: 10 }}
                                                    ></div>
                                                </div>
                                            ) : (
                                                <ComponentEmptyData />
                                            )}
                                        </SortableContext>
                                    </DndContext>
                                </div>
                            </div>
                        </ScrollContainer>
                    </div>
                    <ComponentModal
                        isVisible={viewDetail}
                        width={660}
                        title={`รายละเอียดผู้จองทำธุรกรรม`}
                        onCancel={() => setViewDetail(false)}
                    >
                        <ComponentInfo data={detail} />
                    </ComponentModal>
                </div>
                <div className="page-bottom">
                    {total > 0 && (
                        <div className="pagination">
                            <Pagination
                                defaultCurrent={INITIAL_LIST_PARAMS.page || 1}
                                pageSize={PAGINATION}
                                total={total}
                                onChange={handlePagination}
                                current={pagination.xpage}
                            />
                        </div>
                    )}
                </div>
            </div>
        </StyledTable>
    );
};
