import { useEffect, memo, useState, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Row, Col, Select, Modal, DatePicker, ConfigProvider } from "antd";
import styled from "styled-components";
import moment from "moment";
import "moment/locale/th";
import locale from "antd/es/locale-provider/th_TH";
import {
  IResultUpdate,
  ITrashfeeArea,
  ITrashfeeAreaCreate,
  ITrashfeeAutoCreate,
  ITrashfeeDistrict,
  ITrashfeeHouse,
  ITrashfeeHouseCreate,
} from "@interfaces";
import { StyledSubmitButton, StyledCancelButton, StyledCard, SharedInput } from "@components";
import { useYup } from "@validations";
import { setLoading, selectAuth, selectContact } from "@redux";
import { showErrorMessage, useNotify } from "@utils";
import { PATH_TRASH_FEE_BILL, PATH_TRASH_FEE_BILL_AUTO_CREATE, PATH_TRASH_FEE_BILL_CREATE, PATH_TRASH_FEE_BILL_MANUAL_CREATE, PATH_TRASH_FEE_HOUSE, PATH_TRASH_FEE_HOUSE_CREATE } from "@configs";
import { trashfeeAPI } from "@api";



const MONTHlIST = [
  'มกราคม',
  'กุมภาพันธ์',
  'มีนาคม',
  'เมษายน',
  'พฤษภาคม',
  'มิถุนายน',
  'กรกฎาคม',
  'สิงหาคม',
  'กันยายน',
  'ตุลาคม',
  'พฤศจิกายน',
  'ธันวาคม'
];
const _year = (new Date()).getFullYear();
const YEARLIST = Array.from(new Array(100), (val, index) => index + _year - 98 + 543).reverse();

const dateFormat = 'DD/MM/YYYY';

interface IProps {
  // result: IResult | undefined;
  districtList: ITrashfeeDistrict[];
  areaList: ITrashfeeArea[];
}

const initData: IResultUpdate = {
  organization_id: "",
  title: "",
  image_url: "",
  is_enable: true,
  information_id: "",
  created_date: new Date().toString(),
  documents: [],
};
const { Option } = Select;
export const ModuleBillAutoForm = memo((props: IProps) => {
  //hook
  const { YupNews, YupBidding } = useYup();
  moment.locale("th");

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { error, success } = useNotify();
  const search_params = useSelector(selectContact).paramsContact;

  const organization_id = useSelector(selectAuth).auth?.admin_data?.organization_id;

  const pathName = window.location.pathname;
  const org_id = useSelector(selectAuth).auth?.organization_data?._id;

  const createBidding = Yup.object().shape(YupBidding);
  const { districtList, areaList } = props;
  const prefix = ['คุณ', 'นาย', 'นาง', 'นางสาว'];
  const [autoFill, setAutoFill] = useState<boolean>(false);
  const [value, setValue] = useState({
    organization_id: "",
    profile_id: "",
    area_id: "",
    register_no: "",
    prefix: "",
    name: "",
    surname: "",
    card_no: "",
    house_no: "",
    moo: "",
    soi: "",
    road: "",
    district_id: "",
    house_type: "",
    trash_fee: 0,
    id_invoice: "",
    month: "",
    year: "",
    end_date: "",
  });

  const handleBiddingSubmit = (values: IResultUpdate) => {
    // console.log("hello submit", values);
  };

  const handleClose = () => {
    history.push(PATH_TRASH_FEE_BILL);
  };


  const prefixChange = (text: string) => {
    setValue({
      ...value,
      prefix: text,
    });
  };

  const monthChange = (index: string) => {
    console.log(index);
    setValue({
      ...value,
      month: index,
    });
  };
  const yearChange = (text: string) => {
    setValue({
      ...value,
      year: text,
    });
  };

  const districtChange = (id: string) => {
    setValue({
      ...value,
      district_id: id,
    });
  };
  const areaChange = (id: string) => {
    setValue({
      ...value,
      area_id: id,
    });
  };

  const { values, handleChange, handleBlur, handleSubmit, errors, touched, setFieldValue } =
    useFormik({
      initialValues: initData,
      validationSchema: createBidding,
      enableReinitialize: true,
      onSubmit: handleBiddingSubmit,
    });

  const saveTrashfee = async () => {
    try {
      let year_cycle = (parseInt(value.year) - 543).toString();
      let getDate = value.end_date.split("/");
      let expired_date = [getDate[2], getDate[1], getDate[0]].join("-");
      let payload: ITrashfeeAutoCreate = {
        organization_id: organization_id || "",
        month_cycle: value.month,
        year_cycle: year_cycle,
        expired_date: expired_date,
      };
      console.log('payload', payload);

      const response = await trashfeeAPI.createTrashfeeAuto(payload);
      // const r = await trashfeeAPI.createHouse(payload);
      if (response.data.error === "0") {
        success(t("message.create.success"));
        handleClose();
      } else {
        error(response.data.msg);
      }

    } catch (err: any) {
      if (err.response) {
        err(showErrorMessage(err.response));
      } else {
        error(t("message.error"));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

  const changeField = (e: any) => {
    let arr = value;
    arr[e.target.id] = e.target.value;
    setValue({ ...arr });
  };

  const changeDate = (date, dateString) => {
    setValue({
      ...value,
      end_date: dateString,
    });
  };

  const renderDate = () => {
    // value={value.end_date === "" ? undefined : moment(value.end_date, dateFormat)}
    if (value.end_date === "") {
      return undefined;
    } else {
      let date = moment(value.end_date, dateFormat);
      date.add(543, 'years');
      // return moment(value.end_date, dateFormat);
      return date;
    }
  };
  return (
    <div className="form-wrap">
      <form onSubmit={handleSubmit}>
        <div className="page-header">
          <h3>
            {pathName === PATH_TRASH_FEE_BILL_AUTO_CREATE
              ? "สร้างรายการชำระค่าขยะ"
              : "แก้ไขรายการชำระค่าขยะ"
            }
          </h3>
          <p>โปรดระบุรายละเอียดให้ครบถ้วน</p>
        </div>
        {pathName === PATH_TRASH_FEE_BILL_AUTO_CREATE ? (
          <StyledPageBody>
            <StyledCard>
              <div className="title">
                จำนวนค่าขยะเดือนล่าสุด
              </div>
              <Row gutter={16}>
                <Col md={12}>
                  <label className="label">
                    <span className="label-name">ค่าขยะประจำเดือน / ปี*</span>
                    <br /> <br />
                  </label>
                  <Row gutter={16}>
                    <Col md={12}>
                      <Select
                        value={value.month}
                        onChange={monthChange}
                      >
                        {MONTHlIST.length > 0 &&
                          MONTHlIST.map((item, index) => {
                            return (
                              <Option value={index + 1} key={index}>
                                {item}
                              </Option>
                            );
                          })
                        }
                      </Select>
                    </Col>
                    <Col md={12}>
                      <Select
                        value={value.year}
                        onChange={yearChange}
                      >
                        {YEARLIST.length > 0 &&
                          YEARLIST.map((item, index) => {
                            return (
                              <Option value={item} key={index}>
                                {item}
                              </Option>
                            );
                          })
                        }
                      </Select>
                    </Col>
                  </Row>
                </Col>
                <Col md={12}>
                  <label className="label">
                    <span className="label-name">วันที่หมดเขต*</span>
                    <br /> <br />
                  </label>
                  {/* @ts-ignore */}
                  <ConfigProvider locale={locale}>
                    <DatePicker
                      onChange={changeDate}
                      // value={value.end_date === "" ? undefined : moment(value.end_date, dateFormat)}
                      // value={renderDate()}
                      format={dateFormat}
                    />
                  </ConfigProvider>
                </Col>
              </Row>
            </StyledCard>
            <div className="btn-layout">
              <StyledSubmitButton
                type="default"
                text={t("page.save")}
                // htmlType="submit"
                onClick={saveTrashfee}
              // onClick={handleSubmit}
              />
              <StyledCancelButton
                type="sub"
                text={t("page.cancel")}
                htmlType="button"
                onClick={handleClose}
              />
            </div>
          </StyledPageBody>
        ) : (
          <StyledPageBody>
            edit page
          </StyledPageBody>
        )}
      </form>
    </div>
  );
});

const StyledPageBody = styled.div`
    display: grid;
    /* grid-template-columns: 760px 2fr; */
    grid-gap: 15px;
    .ant-calendar-picker-input{
      height: 48px;
    }
    .ant-calendar-input-wrap {
      display: none !important;
    }
`;