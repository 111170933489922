import { useState, useRef, useEffect } from "react";
import {
  ComponentContactSortIcon,
  ComponentRowNews,
  StyledTable,
  ComponentEmptyData,
  StyledTableButtonTop,
  ComponentStatusFilter,
  ComponentTableSearch,
  EditIcon,
  ComponentRowHouse,
  ComponentTableSearchHouse,
  ComponentRowBusiness,
} from "@components";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  TouchSensor,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
  //@ts-ignore
} from "@dnd-kit/sortable";
import { Row, Col, Pagination, Button } from "antd";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import {
  enumSortBy,
  enumContactSortFields,
  PAGINATION,
  PAGE_START,
  enumNewsStatus,
  INITIAL_LIST_PARAMS,
  PATH_TRASH_FEE_BUSINESS_CREATE,
} from "@configs";
import { IPaginationNext, INews, IStatusCount, ITrashfeeHouse, ITrashfeeArea } from "@interfaces";
import { useDispatch, useSelector } from "react-redux";
import { getCategories, selectAuth, selectContact, selectOrg, setLoading } from "@redux";
import { newsAPI } from "src/API/news";
import { useNotify, usePermission } from "@utils";
import { useHistory } from "react-router";
import { Grid } from "@mui/material";
import styled from "styled-components";
import { trashfeeAPI } from "@api";

const INITAL_PAGE = {
  xpage: PAGE_START,
  xlimit: PAGINATION,
};

export const PageBusinessList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const search_params = useSelector(selectContact).paramsContact;
  const pemisssion = useSelector(selectAuth).permission;
  const [params, setParams] = useState(search_params);
  const typingTimeoutRef = useRef(0);
  const org_id = useSelector(selectAuth).auth?.organization_data?._id;
  const categories = useSelector(selectOrg).category;
  const [textSearch, setTextSearch] = useState<string>("");

  const [searchDate, setSearchDate] = useState<string[]>([]);
  const [catSearch, setCatSearch] = useState<string>("");
  const [areaSearch, setAreaSearch] = useState<string>("");
  const [status, setStatus] = useState<enumNewsStatus>(enumNewsStatus.ALL);
  const [filterStatus, setFilterStatus] = useState<boolean>(true);
  const [pagination, setPagination] = useState<IPaginationNext>(INITAL_PAGE);
  const [news, setNews] = useState<INews[]>([]);
  const [houseList, setHouseList] = useState<ITrashfeeHouse[]>([]);
  const [areaList, setAreaList] = useState<ITrashfeeArea[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [newsCount, setNewsCount] = useState<IStatusCount>();
  const { success, error } = useNotify();
  const history = useHistory();
  usePermission({ permission: pemisssion?.permission_list.news.is_manage });
  useEffect(() => {
    // fetchNews(pagination, textSearch, searchDate, catSearch, status);
    fetchHouselist(pagination, textSearch, searchDate, areaSearch, status);
  }, [pagination, textSearch, searchDate, areaSearch, status]);

  useEffect(() => {
    fetchAreaList();
    // fetchNewsCountStatus();
  }, []);

  const fetchNewsCountStatus = async () => {
    try {
      const res = await newsAPI.getNewsEnableCount({ organization_id: org_id || "" });
      if (res.data.error === "0") setNewsCount(res.data.result);
    } catch (error) { }
  };

  const fetchAreaList = async () => {
    try {
      dispatch(setLoading(true));
      const payload = {
        organization_id: org_id || "",
        xpage: "1",
      };
      const response = await trashfeeAPI.getAreaList({ ...payload });
      setAreaList(response.data.result);
      dispatch(setLoading(false));
    } catch {
      dispatch(setLoading(false));
    }
  };

  const fetchHouselist = async (
    pagination: IPaginationNext,
    textSearch: string,
    searchDate: string[],
    areaSearch: string,
    status: enumNewsStatus
  ) => {
    dispatch(setLoading(true));
    console.log("fetch house list");
    try {
      const response = await trashfeeAPI.getHouseList({
        ...pagination,
        organization_id: org_id || "",
        house_type: 'business',
        is_verify: true,
        ...(textSearch && { text_search: textSearch }),
      });
      setHouseList(response.data.result);
      setTotal(response.data.total);
    } catch (err: any) {
      error(err.msg);
      dispatch(setLoading(false));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const fetchNews = async (
    pagination: IPaginationNext,
    textSearch: string,
    searchDate: string[],
    catSearch: string,
    status: enumNewsStatus
  ) => {
    dispatch(setLoading(true));
    try {
      const response = await newsAPI.getList({
        organization_id: org_id || "",
        ...pagination,
        ...(textSearch && { text_search: textSearch }),
        ...(searchDate.length > 1 &&
          searchDate[0] !== "" && {
          date_from: searchDate[0],
        }),
        ...(searchDate.length > 1 &&
          searchDate[1] !== "" && {
          date_to: searchDate[1],
        }),
        ...(catSearch && { news_category_id: catSearch }),
        ...(status !== enumNewsStatus.ALL && {
          is_enable: status === enumNewsStatus.ACTIVE,
        }),
      });
      setNews(response.data.result);
      setTotal(response.data.total);
      // success(response.data.msg);
    } catch (err: any) {
      error(err.msg);
      dispatch(setLoading(false));
    } finally {
      dispatch(setLoading(false));
    }
  };



  const handleSort = (sortField: enumContactSortFields, sortBy: enumSortBy) => {
    const newSortParams = { ...params, sortField, sortBy };
    setParams(newSortParams);
    // fetchContacts(newSortParams);
  };

  const updateHouse = (isEnable: boolean, data: ITrashfeeHouse) => {
    //

  };

  const updateNews = async (isEnable: boolean, data: INews) => {
    dispatch(setLoading(true));
    try {
      const payload = {
        organization_id: data.organization_id,
        title: data.title,
        detail: data.detail,
        image_url_list: data.image_url_list,
        is_hot_news: data.is_hot_news,
        news_category_id: data.news_category_id,
        date_from: data.date_from,
        date_to: data.date_to,
        is_enable: isEnable,
        news_id: data._id,
      };
      const res = await newsAPI.update(payload);
      callbackGetList();
      if (res.data.error === "1") {
        error(t(res.data.msg));
      } else {
        success(t(res.data.msg));
      }
    } catch (error) {
      dispatch(setLoading(false));
    } finally {
      dispatch(setLoading(false));
    }
  };
  const callbackGetList = () => {
    // fetchNews(pagination, textSearch, searchDate, catSearch, status);
    fetchHouselist(pagination, textSearch, searchDate, catSearch, status);
    // fetchNewsCountStatus();
  };
  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const swapItem = (items: ITrashfeeHouse[]) => {
        const oldIndex = items.findIndex((item) => JSON.stringify(item) === active.id);
        const newIndex = items.findIndex((item) => JSON.stringify(item) === over.id);
        return arrayMove(items, oldIndex, newIndex);
      };
      setHouseList(swapItem);
    }
  };
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(TouchSensor)
  );
  const handleSearchCategory = (value) => {
    setCatSearch(value);
  };
  const handleChangeArea = (value) => {
    setAreaSearch(value);
  };
  const onChangeDateRange = (dates, dateStrings: [string, string]) => {
    setSearchDate(dateStrings);
  };
  const handlePagination = (page) => {
    setPagination({ xpage: page, xlimit: pagination.xlimit });
  };

  return (
    <StyledTable>
      <div className="page-layout">
        <div className="page-header-layout">
          <div className="page-header">
            <div>
              <h3>รายการทะเบียนประเภทกิจการ</h3>
            </div>
            <div className="flex-header">
              <StyledTableButtonTop
                type="danger"
                size="default"
                text="รายการทะเบียนประเภทกิจการ"
                onClick={() => history.push(PATH_TRASH_FEE_BUSINESS_CREATE)}
                className="add-btn"
                icon="plus"
              />
            </div>
          </div>
          <br />
          <br />
          <div className="search-layout">
            <ComponentTableSearchHouse
              setTextSearch={setTextSearch}
              handleSearchCategory={handleSearchCategory}
              categories={categories}
              areaList={areaList}
              handleChangeArea={handleChangeArea}
              onChangeDateRange={onChangeDateRange}
              total={total}
              isRangePicker={true}
            />
          </div>
        </div>
        <div className="page-table-layout">
          <div className="table">
            <ScrollContainer vertical={false}>
              <div className="table_ground">
                <div className="table-body">
                  <div className="table-header">
                    <Row
                      gutter={24}
                      type="flex"
                      justify="space-between"
                      align="middle"
                      className="header-row"
                    >
                      <Col className="col-item" span={3}>
                        <p>เลขที่ทะเบียนบ้าน</p>
                        <div className="filter-button">
                          <ComponentContactSortIcon
                            handleSort={handleSort}
                            sortBy={enumSortBy.ASC}
                            sortField={enumContactSortFields.FULL_NAME}
                            searchParams={params}
                          />
                          <ComponentContactSortIcon
                            handleSort={handleSort}
                            sortBy={enumSortBy.DESC}
                            sortField={enumContactSortFields.FULL_NAME}
                            searchParams={params}
                          />
                        </div>
                      </Col>
                      <Col className="col-item" span={4}>
                        <p>รายชื่อกิจการ</p>
                        <div className="filter-button">
                          <ComponentContactSortIcon
                            handleSort={handleSort}
                            sortBy={enumSortBy.ASC}
                            sortField={enumContactSortFields.TEL}
                            searchParams={params}
                          />
                          <ComponentContactSortIcon
                            handleSort={handleSort}
                            sortBy={enumSortBy.DESC}
                            sortField={enumContactSortFields.TEL}
                            searchParams={params}
                          />
                        </div>
                      </Col>
                      <Col className="col-item" span={3}>
                        <p>เลขที่นิติบุคคล</p>
                        <div className="filter-button">
                          <ComponentContactSortIcon
                            handleSort={handleSort}
                            sortBy={enumSortBy.ASC}
                            sortField={enumContactSortFields.POINTS}
                            searchParams={params}
                          />
                          <ComponentContactSortIcon
                            handleSort={handleSort}
                            sortBy={enumSortBy.DESC}
                            sortField={enumContactSortFields.POINTS}
                            searchParams={params}
                          />
                        </div>
                      </Col>
                      <Col className="col-item" span={3}>
                        <p>หมวดพื้นที่</p>
                        <div className="filter-button">
                          <ComponentContactSortIcon
                            handleSort={handleSort}
                            sortBy={enumSortBy.ASC}
                            sortField={enumContactSortFields.POINTS}
                            searchParams={params}
                          />
                          <ComponentContactSortIcon
                            handleSort={handleSort}
                            sortBy={enumSortBy.DESC}
                            sortField={enumContactSortFields.POINTS}
                            searchParams={params}
                          />
                        </div>
                      </Col>
                      <Col className="col-item" span={6}>
                        <p>ที่อยู่</p>
                        <div className="filter-button">
                          <ComponentContactSortIcon
                            handleSort={handleSort}
                            sortBy={enumSortBy.ASC}
                            sortField={enumContactSortFields.CREATED_AT}
                            searchParams={params}
                          />
                          <ComponentContactSortIcon
                            handleSort={handleSort}
                            sortBy={enumSortBy.DESC}
                            sortField={enumContactSortFields.CREATED_AT}
                            searchParams={params}
                          />
                        </div>
                      </Col>
                      <Col className="col-item" span={2}>
                        <p>จำนวนค่าขยะ</p>
                        <div className="filter-button">
                          <ComponentContactSortIcon
                            handleSort={handleSort}
                            sortBy={enumSortBy.ASC}
                            sortField={enumContactSortFields.CREATED_AT}
                            searchParams={params}
                          />
                          <ComponentContactSortIcon
                            handleSort={handleSort}
                            sortBy={enumSortBy.DESC}
                            sortField={enumContactSortFields.CREATED_AT}
                            searchParams={params}
                          />
                        </div>
                      </Col>
                      <Col className="col-item" span={2}>
                        <p>{t("page.news_manage")}</p>
                      </Col>
                    </Row>
                  </div>
                  <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                  >
                    <SortableContext
                      items={houseList.map((item) => JSON.stringify(item))}
                      strategy={rectSortingStrategy}
                    >
                      {houseList && houseList.length > 0 ? (
                        <div className="data-table">
                          {houseList.map((item, index) => (
                            <ComponentRowBusiness
                              key={index}
                              data={item}
                              itemJson={JSON.stringify(item)}
                              categories={categories}
                              handleEdit={updateHouse}
                              callbackGetList={callbackGetList}
                              areaList={areaList}
                              index={index}
                            />
                          ))}
                          <div
                            style={{ width: "100%", height: 10 }}
                          ></div>
                        </div>
                      ) : (
                        <ComponentEmptyData />
                      )}
                    </SortableContext>
                  </DndContext>
                </div>
              </div>
            </ScrollContainer>
          </div>
        </div>
        <div className="page-bottom">
          {total > 0 && (
            <div className="pagination">
              <Pagination
                defaultCurrent={INITIAL_LIST_PARAMS.page || 1}
                pageSize={PAGINATION}
                total={total}
                onChange={handlePagination}
                current={pagination.xpage}
              />
            </div>
          )}
        </div>
      </div>
    </StyledTable>
  );
};

const StyledPriceSection = styled.div`
  .price-section{
    background-color: white;
    padding: 20px;
    border-radius: 4px;
    margin-bottom: 26px;
    height: 88px;
    display: flex;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    justify-content: space-between;
    .price-number {
      color: #0D6EFD;
      font-size:20px;
    }
    .price-item {
      margin: auto; 
    }
    .price-btn {
      height: 44px;
    }
  }
`;