import styled from "styled-components";
import { Avatar, Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import ScrollContainer from "react-indiana-drag-scroll";
import { PATH_HOME } from "@configs";
import { ComponentEmptyData } from "@components";
import { chooseAdminTessabarn, selectAuth, setLoading } from "@redux";
import { showErrorMessage, theme, useNotify } from "@utils";
import { useMediaQuery } from "react-responsive";
import { IAdmin } from "@interfaces";

export const PageAccountList = () => {
    // page hook
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { error, warning, success } = useNotify();
    const history = useHistory();
    const authUser = useSelector(selectAuth).authUser;
    const tessabarn_list = authUser?.result?.tessabarn_list || [];
    const secret_pass = authUser?.result?.secret_pass;
    useEffect(() => {
        if (!authUser) return history.goBack();
    }, [authUser]);

    const handleLogin = async (orgId: string) => {
        if (secret_pass) {
            dispatch(setLoading(true));
            try {
                const payload = {
                    admin_id: orgId,
                    secret_pass,
                };
                await dispatch(chooseAdminTessabarn(payload));
                success(t("page.login_successfully"));
                dispatch(setLoading(false));
                history.push(PATH_HOME);
            } catch (err: any) {
                if (err.data) {
                    error(showErrorMessage(err.data));
                }
                dispatch(setLoading(false));
            }
        }
    };
    return (
        <StyledContainer>
            <div className="page-layout">
                <Row className={"page-header"}>
                    <Col xs={{ span: 16 }} md={{ span: 12 }}>
                        <div>
                            <h3>{t("page.manage_account.accounts")}</h3>
                            <p className="sub-title visible-md">
                                {t("page.manage_account.select_account")}
                            </p>
                        </div>
                    </Col>
                </Row>
                <div>
                    <p className="sub-title visible-ms">
                        {t("page.manage_account.select_account")}
                    </p>
                </div>
                <div className="table-body">
                    <ScrollContainer style={{ width: "100%" }}>
                        {tessabarn_list.length > 0 ? (
                            <div className="data-table">
                                {tessabarn_list.map((item, index) => (
                                    <TableRow item={item} key={index} handleLogin={handleLogin} />
                                ))}
                                <div style={{ width: "100%", height: 20 }}></div>
                            </div>
                        ) : (
                            <ComponentEmptyData />
                        )}
                    </ScrollContainer>
                </div>
            </div>
        </StyledContainer>
    );
};
interface Iprops {
    item: IAdmin;
    handleLogin: (orgId: string) => void;
}
const TableRow = (props: Iprops) => {
    //page hook
    const { item, handleLogin } = props;
    const { t } = useTranslation();
    const isMobile = useMediaQuery({ query: `(max-width: ${theme.breakPoints.breakTablet})` });
    return (
        <div className="table-row" onClick={() => handleLogin(item._id)}>
            <Row gutter={24} type="flex" align="middle">
                {isMobile ? (
                    <>
                        <Col className="col-item" span={18}>
                            <Avatar src={"/user-avatar.png"} size={57} style={{ minWidth: 57 }} />
                            <div>
                                <p className="user-name">{item.name}</p>
                                {/* <p className="business-name">{item.businessName}</p> */}
                                <div className="sub-title">
                                    {t("page.manage_account.our_customer_m")}
                                    <p>{item.username}</p>
                                </div>
                            </div>
                        </Col>
                        <Col span={6}>
                            <p>{item.organization_code}</p>
                        </Col>
                    </>
                ) : (
                    <>
                        <Col className="col-item" span={9}>
                            <Avatar src={"/user-avatar.png"} size={57} style={{ minWidth: 57 }} />
                            <div className="user-name-layout">
                                <p className="user-name">{item.name}</p>
                                {/* <p className="business-name">{item.businessName}</p> */}
                            </div>
                        </Col>
                        <Col className="col-item" span={5}>
                            <p>{item.username}</p>
                        </Col>
                        <Col className="col-item" span={5}>
                            <p>{item.organization_code}</p>
                        </Col>
                        <Col className="col-item" span={5}>
                            <p>{item.admin_id}</p>
                        </Col>
                    </>
                )}
            </Row>
        </div>
    );
};
const TableRowHeader = () => {
    //page hook
    const { t } = useTranslation();

    return (
        <div className="table-row-header">
            <Row gutter={24} type="flex" align="middle">
                <Col className="col-item" span={9}>
                    <p>{t("page.manage_account.account_name")}</p>
                    <div className="sort">
                        <UpArrow />
                        <DownArrow />
                    </div>
                </Col>
                <Col className="col-item" span={5}>
                    <p>{t("page.manage_account.our_customer")}</p>
                    <div className="sort">
                        <UpArrow />
                        <DownArrow />
                    </div>
                </Col>
                <Col className="col-item" span={5}>
                    <p>{t("page.manage_account.role")}</p>
                    <div className="sort">
                        <UpArrow />
                        <DownArrow />
                    </div>
                </Col>
                <Col className="col-item plan" span={5}>
                    <p>{t("page.manage_account.plan")}</p>
                    <div className="sort">
                        <UpArrow />
                        <DownArrow />
                    </div>
                </Col>
            </Row>
        </div>
    );
};

const UpArrow = () => (
    <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.5 0.5L7 6.5H0L3.5 0.5Z" fill="#E1E1E1" />
    </svg>
);
const DownArrow = () => (
    <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.5 6.5L0 0.5L7 0.5L3.5 6.5Z" fill="#E1E1E1" />
    </svg>
);

const StyledContainer = styled.div`
    padding: 3.5rem;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 64px);
    overflow: auto;
    .visible-md {
        display: block;
    }
    .visible-ms {
        display: none;
    }
    .sub-title {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #a5a5a5;
    }
    @media screen and (max-width: ${(p) => p.theme.breakPoints.breakMobileMedium}) {
        padding: 14px;
        .visible-md {
            display: none;
        }
        .visible-ms {
            display: block;
        }
    }
    .page-layout {
        flex: 1;
        display: flex;
        flex-direction: column;
    }
    ::-webkit-scrollbar {
        width: 6px;
        height: 0;
    }

    ::-webkit-scrollbar-track {
        display: none;
    }

    ::-webkit-scrollbar-thumb {
        background: ${(p) => p.theme.colors.fadedText};
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        cursor: grab;
    }
    @media screen and (max-width: ${(p) => p.theme.breakPoints.breakMobileMedium}) {
        ::-webkit-scrollbar {
            display: none;
        }
    }
    p {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        overflow-wrap: anywhere;
    }
    .page-header {
        .add-btn {
            color: white !important;

            &:hover {
                color: white !important;
            }
        }

        margin-bottom: 17px;
        .justify-end {
            justify-content: flex-end;
            @media (max-width: 769px) {
                justify-content: flex-start;
            }
        }
        .flex-div {
            display: flex;
            align-items: center;
            button {
                background-color: ${(props) => props.theme.colors.danger};
                color: white;
                font-size: 16px;
                justify-content: space-around;
                width: 154px;
                height: 42px;
                &:hover {
                    color: white !important;
                }
                @media (max-width: ${(p) => p.theme.breakPoints.breakOnlyMobile}) {
                    width: 136px;
                }
            }
        }
        h3 {
            font-weight: 700;
            margin-right: 40px;
            font-size: 35px;
            margin-bottom: 10px;
            @media (max-width: ${(props) => props.theme.breakPoints.breakMobileMedium}) {
                font-size: 20px;
                margin-right: 0.75rem;
            }
        }
        .sub-title {
            color: ${(p) => p.theme.colors.fadedText};
            font-size: 16px;
            margin-bottom: 10px;
        }
        .text-danger {
            color: ${(p) => p.theme.colors.danger};
            font-size: 16px;
            &:hover {
                cursor: pointer;
                opacity: 0.8;
            }
        }
        @media (max-width: ${(p) => p.theme.breakPoints.breakOnlyMobile}) {
            margin-bottom: 0;
        }
    }
    .table-body {
        flex: 1;
        overflow-y: auto;
        display: flex;

        ::-webkit-scrollbar {
            width: 6px;
            height: 0;
        }

        ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px grey;
            border-radius: 10px;
        }

        ::-webkit-scrollbar-thumb {
            background: ${(p) => p.theme.colors.fadedText};
            border-radius: 10px;
        }

        ::-webkit-scrollbar-thumb:hover {
            cursor: grab;
        }
        .table-body::after {
            content: "";
            white-space: pre;
            margin: 0px 5px;
            height: 100%;
        }
        .data-table {
            padding: 2px;
            flex: 1;
        }
    }
    .page-bottom {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 14px;
    }
    .table-row,
    .table-header {
        padding: 10px 18px;
        border-radius: 1px;
        margin: 9px 0;
    }
    .table-row {
        /* min-width: 900px; */
        min-width: 680px;
        border-radius: 6px;
        background-color: white;
        margin: 18px 0;
        margin-top: 0;
        @media (max-width: ${(p) => p.theme.breakPoints.breakOnlyMobile}) {
            min-width: 100%;
            padding: 16px;
        }
        .col-item {
            display: flex;
            align-items: center;
            p {
                margin-bottom: 0;
                font-size: 16px;
                color: #6c7084;
            }
            .user-name {
                font-weight: 600;
                margin-left: 20px;
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 22px;
                color: #000000;
            }
            .business-name {
                margin-left: 20px;
            }
            .sub-title {
                margin-left: 20px;
                display: flex;
                column-gap: 6px;
            }
        }
    }
    .table-row-header {
        min-width: 680px;
        border-radius: 6px;
        background-color: transparent;
        margin: 18px 0%;
        padding: 0 2px;
        .col-item {
            display: flex;
            align-items: center;
            &:hover {
                p {
                    font-style: normal;
                    font-weight: 800;
                    font-size: 12px;
                    line-height: 16px;
                    color: #3e3d3d;
                    margin: 0;
                }
            }
            p {
                font-style: normal;
                font-weight: 800;
                /* font-size: 12px; */
                line-height: 16px;
                color: #a5a5a5;
                margin: 0;
                /* text-transform: uppercase; */
                color: #a5a5a5;
                font-size: 14px;
                /* font-weight: 700; */
                margin-bottom: 0;
                /* margin-right: 10px; */
            }
            .user-name {
                font-weight: 600;
                margin-left: 20px;
            }
            .sort {
                display: flex;
                flex-direction: column;
                width: 10px;
                margin-left: 10px;
                row-gap: 3px;
            }
        }
        .plan {
            padding: 0 !important;
        }
    }
    .table-row:hover {
        /* box-shadow: 0 5px 7px rgba(0, 0, 0, 0.07), 0 5px 7px rgba(0, 0, 0, 0.07); */
        box-shadow: 0px 0px #1e7cf5, 0px 0 5px #1e7cf5;
        cursor: pointer;
    }
    .btn-action {
        border-color: transparent !important;
    }
`;
