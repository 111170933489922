export const TrashfeeIcon = () => (
    <svg width="19"
        height="20"
        viewBox="0 0 19 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.24733 19.9992C7.89233 19.9992 6.57133 19.9842 5.26433 19.9572C3.59233 19.9242 2.43533 18.8402 2.24633 17.1282C1.93133 14.2882 1.39233 7.59417 1.38733 7.52717C1.35333 7.11417 1.66133 6.75217 2.07433 6.71917C2.48133 6.70817 2.84933 6.99417 2.88233 7.40617C2.88733 7.47417 3.42533 14.1452 3.73733 16.9632C3.84433 17.9362 4.36933 18.4382 5.29533 18.4572C7.79533 18.5102 10.3463 18.5132 13.0963 18.4632C14.0803 18.4442 14.6123 17.9522 14.7223 16.9562C15.0323 14.1622 15.5723 7.47417 15.5783 7.40617C15.6113 6.99417 15.9763 6.70617 16.3853 6.71917C16.7983 6.75317 17.1063 7.11417 17.0733 7.52717C17.0673 7.59517 16.5253 14.3062 16.2133 17.1212C16.0193 18.8682 14.8653 19.9312 13.1233 19.9632C11.7903 19.9862 10.5043 19.9992 9.24733 19.9992Z"
            fill="#0D6EFD"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.708 4.98828H0.75C0.336 4.98828 0 4.65228 0 4.23828C0 3.82428 0.336 3.48828 0.75 3.48828H17.708C18.122 3.48828 18.458 3.82428 18.458 4.23828C18.458 4.65228 18.122 4.98828 17.708 4.98828Z"
            fill="#0D6EFD"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.4406 4.989C13.3026 4.989 12.3146 4.178 12.0906 3.062L11.8476 1.846C11.7966 1.661 11.5856 1.5 11.3456 1.5H7.11258C6.87258 1.5 6.66158 1.661 6.60058 1.892L6.36758 3.062C6.14458 4.178 5.15558 4.989 4.01758 4.989C3.60358 4.989 3.26758 4.653 3.26758 4.239C3.26758 3.825 3.60358 3.489 4.01758 3.489C4.44358 3.489 4.81358 3.185 4.89758 2.767L5.14058 1.551C5.38758 0.619 6.19458 0 7.11258 0H11.3456C12.2636 0 13.0706 0.619 13.3076 1.506L13.5616 2.767C13.6446 3.185 14.0146 3.489 14.4406 3.489C14.8546 3.489 15.1906 3.825 15.1906 4.239C15.1906 4.653 14.8546 4.989 14.4406 4.989Z"
            fill="#0D6EFD"
        />
    </svg>

);