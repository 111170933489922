import { useTranslation } from "react-i18next";
import * as Yup from "yup";

export const YupComplaintCategory = () => {
    //page hook
    const { t } = useTranslation();

    return {
        category_name: Yup.string().required(
            t("validation.required", {
                name: t("page.category_name"),
            })
        ),
    };
};
