export const IconCopy = () => {
    return (
        <>
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M17 3H11C8.8 3 7 4.8 7 7C4.8 7 3 8.8 3 11V17C3 19.2 4.8 21 7 21H13C15.2 21 17 19.2 17 17C19.2 17 21 15.2 21 13V7C21 4.8 19.2 3 17 3ZM19 13C19 14.1 18.1 15 17 15V11C17 8.8 15.2 7 13 7H9C9 5.9 9.9 5 11 5H17C18.1 5 19 5.9 19 7V13Z"
                    fill="#C5C5C5"
                />
            </svg>
        </>
    );
};
