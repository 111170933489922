import { Modal, Button } from "antd";
import { useState } from "react";
import { useHistory } from "react-router";

export const PageNotCompleted = () => {
    const history = useHistory();
    // page state
    const [visible] = useState(true);
    // const handleBack
    return (
        <div>
            <Modal
                visible={visible}
                centered={true}
                closable={false}
                footer={""}
                style={{ textAlign: "center" }}
            >
                <svg
                    viewBox="0 0 375 500"
                    style={{ height: "310px", marginTop: "-150px" }}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clip-path="url(#clip0)">
                        <g opacity="0.8">
                            <path
                                d="M194.656 358.997H183.943V466.367H194.656V358.997Z"
                                fill="#828282"
                            />
                            <path
                                d="M194.656 359.038H183.901V362.751H194.656V359.038Z"
                                fill="#5F5F5F"
                            />
                            <path
                                d="M252.013 272.175V323.335L215.69 359.511H164.321L128 323.335V272.175L164.321 236H215.69L252.013 272.175Z"
                                fill="#BDBDBD"
                            />
                            <path
                                d="M251.995 272.627V323.787L215.672 359.963H164.303L140 335.759C231.342 349.164 232.289 253 232.289 253L251.995 272.627Z"
                                fill="#B7B7B7"
                            />
                            <path
                                d="M252.013 297.756C252.013 297.756 234.551 362.331 164.321 359.511H215.69L252.013 323.336V297.756Z"
                                fill="#828282"
                            />
                            <path
                                d="M245.987 274.662V320.849L213.196 353.509H166.818L134.026 320.849V274.662L166.818 242.002H213.196L245.987 274.662Z"
                                stroke="white"
                                strokeWidth="4"
                                strokeMiterlimit="10"
                            />
                            <path
                                d="M194.656 358.997H190.355V466.367H194.656V358.997Z"
                                fill="#5F5F5F"
                            />
                            <path
                                d="M269.433 470.001H109.799C108.716 470.001 107.837 469.125 107.837 468.047V467.99C107.837 466.912 108.716 466.036 109.799 466.036H269.433C270.516 466.036 271.395 466.912 271.395 467.99V468.047C271.395 469.125 270.516 470.001 269.433 470.001Z"
                                fill="#787878"
                            />
                            <path
                                d="M112.823 470.001H86.9735C85.8908 470.001 85.0117 469.125 85.0117 468.047V467.99C85.0117 466.912 85.8908 466.036 86.9735 466.036H112.823C113.906 466.036 114.785 466.912 114.785 467.99V468.047C114.785 469.125 113.908 470.001 112.823 470.001Z"
                                fill="#787878"
                            />
                            <path
                                d="M75.1209 470.001H47.9618C46.8791 470.001 46 469.125 46 468.047V467.99C46 466.912 46.8791 466.036 47.9618 466.036H75.1209C76.2036 466.036 77.0827 466.912 77.0827 467.99V468.047C77.0827 469.125 76.2036 470.001 75.1209 470.001Z"
                                fill="#787878"
                            />
                            <path
                                d="M303.894 470.001H281.601C280.518 470.001 279.639 469.125 279.639 468.047V467.99C279.639 466.912 280.518 466.036 281.601 466.036H303.894C304.977 466.036 305.856 466.912 305.856 467.99V468.047C305.856 469.125 304.979 470.001 303.894 470.001Z"
                                fill="#787878"
                            />
                            <path
                                d="M333.038 470.001H316.082C314.999 470.001 314.12 469.125 314.12 468.047V467.99C314.12 466.912 314.999 466.036 316.082 466.036H333.038C334.121 466.036 335 466.912 335 467.99V468.047C335 469.125 334.121 470.001 333.038 470.001Z"
                                fill="#787878"
                            />
                            <path
                                d="M168.689 458.5H112.335L113.361 454.805L115.362 447.584L116.576 443.214L118.363 436.769L121.809 424.347L123.598 417.902L136.166 372.596H144.86L149.465 389.2L157.426 417.902L159.215 424.347L162.661 436.769L164.45 443.214L167.664 454.805L168.689 458.5Z"
                                fill="#BDBDBD"
                            />
                            <path
                                d="M168.689 458.5H112.335L113.361 454.803L115.362 447.584C154.631 445.274 149.465 389.2 149.465 389.2L167.664 454.804L168.689 458.5Z"
                                fill="#828282"
                            />
                            <path d="M180.22 458.5H100.806V466.02H180.22V458.5Z" fill="#828282" />
                            <path
                                d="M168.689 458.5H112.335L113.361 454.805H167.664L168.689 458.5Z"
                                fill="#828282"
                            />
                            <path
                                d="M159.214 424.347C159.214 424.347 158.035 449.151 129.42 454.825L167.665 454.805L159.214 424.347Z"
                                fill="#5F5F5F"
                            />
                            <path
                                d="M159.214 424.347H121.809L123.597 417.901H157.426L159.214 424.347Z"
                                fill="white"
                            />
                            <path
                                d="M164.449 443.214H116.576L118.363 436.769H162.661L164.449 443.214Z"
                                fill="white"
                            />
                            <path
                                d="M161.85 458.5C161.85 458.5 151.324 466.414 112.335 466.02H180.218V458.5H161.85Z"
                                fill="#5F5F5F"
                            />
                            <path
                                d="M191 320.5C203.426 320.5 213.5 310.426 213.5 298C213.5 285.574 203.426 275.5 191 275.5C178.574 275.5 168.5 285.574 168.5 298C168.5 310.426 178.574 320.5 191 320.5Z"
                                stroke="white"
                                strokeWidth="5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M191 307V298"
                                stroke="white"
                                strokeWidth="5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M191 289H191.023"
                                stroke="white"
                                strokeWidth="5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </g>
                    </g>
                </svg>
                <h1 style={{ fontSize: 18, fontWeight: 500, textAlign: "center" }}>
                    This feature is developing
                </h1>
                <p style={{ fontSize: 16, fontWeight: 400, textAlign: "center", margin: "auto" }}>
                    Please try again later...
                </p>
                <Button
                    type="primary"
                    onClick={history.goBack}
                    style={{ marginTop: "15px" }}
                    className="btn-home btn"
                >
                    Go Back
                </Button>
            </Modal>
        </div>
    );
};
