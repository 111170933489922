import { IBookingDetail } from "@interfaces";
import { memo } from "react";
import styled from "styled-components";
import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";
interface IProps {
    data: IBookingDetail | undefined;
}

export const ComponentInfo = memo((props: IProps) => {
    const { data } = props;
    const { t } = useTranslation();
    return (
        <StyledInfo>
            <div className="info-body">
                <Row>
                    <Col xs={12} sm={12} md={12}>
                        <div className="item">
                            <label className="info-label">{`ชื่อ - นามสกุล`}</label>
                            <div className="info-value">
                                {data?.profile.name || t("page.empty_text")}
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={12}>
                        <div className="item">
                            <label className="info-label">{`เลขบัตรประชาชน`}</label>
                            <div className="info-value eng">
                                {data?.profile.card_no || t("page.empty_text")}
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={12}>
                        <div className="item">
                            <label className="info-label">{`หมายเลขโทรศัพท์`}</label>
                            <div className="info-value">
                                {data?.profile.tel || t("page.empty_text")}
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={12}>
                        <div className="item">
                            <label className="info-label">{`อีเมล`}</label>
                            <div className="info-value eng">
                                {data?.profile.email || t("page.empty_text")}
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={12}>
                        <div className="item">
                            <label className="info-label">{`เวลา`}</label>
                            <div className="info-value">
                                {data?.booking_service.service_date || t("page.empty_text")}
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={12}>
                        <div className="item">
                            <label className="info-label">{`วัน / เวลาที่จอง`}</label>
                            <div className="info-value eng">
                                {`${data?.booking_service.service_from} - ${data?.booking_service.service_to}`}
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <div className="title">{`รายละเอียดการจองธุรกรรม`}</div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={12}>
                        <div className="item">
                            <label className="info-label">{`ธุรกรรม`}</label>
                            <div className="info-value">{t("page.empty_text")}</div>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={12}>
                        <div className="item">
                            <label className="info-label">{`หน่วยงานที่รับผิดชอบ`}</label>
                            <div className="info-value eng">{t("page.empty_text")}</div>
                        </div>
                    </Col>
                </Row>
            </div>
        </StyledInfo>
    );
});

const StyledInfo = styled.div`
    .info-body {
        padding: 20px 0;
    }
    .item {
        margin-bottom: 32px;
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        .info-label {
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            color: #8a959e;
        }
        .info-value {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #29304d;
            img {
                width: 200px;
                height: 200px;
                border-radius: 12px;
            }
        }
        .image-layout {
            display: flex;
            column-gap: 20px;
        }
    }
`;
