import styled from "styled-components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SwiperSlide } from "swiper/react";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton } from "@mui/material";

import {
    getAdminGetPermission,
    selectApp,
    selectAuth,
    setLoading,
    setLogo,
    setMainColor,
    useAppSelector,
} from "@redux";
import { INavigationBox } from "@interfaces";
import { navBox, mobileNavBox } from "@configs";
import {
    ContactIcon,
    GiftIcon,
    ManageIcon,
    PointIcon,
    SettingIcon,
    SuccessIcon,
} from "src/Components/icon/dashboard";
import { useHistory } from "react-router";
import { orgApi } from "@api";

export const PageHome = () => {
    // page hook
    const { t } = useTranslation();
    // redux state
    const organization_id = useSelector(selectAuth).auth?.organization_data?._id;
    const adminId = useSelector(selectAuth).auth?.admin_data?._id;
    const permission = useSelector(selectAuth).permission;
    const app = useAppSelector(selectApp);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const dispatch = useDispatch();
    useEffect(() => {
        getListPaginationBoxs();
        if (organization_id) {
            fetchTheme();
        }
        if (permission === null) {
            dispatch(
                getAdminGetPermission({
                    organization_id: organization_id || "",
                    admin_id: adminId || "",
                })
            );
        }
    }, [organization_id, permission]);

    const fetchTheme = async () => {
        try {
            dispatch(setLoading(true));
            const res = await orgApi.getTheme({ organization_id: organization_id || "" });
            dispatch(setLogo(res.data.result.logo_image_url));
            dispatch(setMainColor(res.data.result.theme_color_code));
            dispatch(setLoading(false));
        } catch (err: any) {
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const getListPaginationBoxs = () => {
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
        }, 100);
    };
    const loadingComponent = (
        <>
            <SwiperSlide>
                <Skeleton variant="rectangular" width={"100%"} height={"100%"} />
            </SwiperSlide>
            <SwiperSlide>
                <Skeleton variant="rectangular" width={"100%"} height={"100%"} />
            </SwiperSlide>
            <SwiperSlide>
                <Skeleton variant="rectangular" width={"100%"} height={"100%"} />
            </SwiperSlide>
            <SwiperSlide>
                <Skeleton variant="rectangular" width={"100%"} height={"100%"} />
            </SwiperSlide>
            <SwiperSlide>
                <Skeleton variant="rectangular" width={"100%"} height={"100%"} />
            </SwiperSlide>
        </>
    );
    return (
        <StyledContainer scrollable={true}>
            <div className="body-field">
                <div className="title">
                    <h4>{t("page.dashboard.welcome_to_loyalty")}</h4>
                    <small>{t("page.dashboard.deshboard_samll")}</small>
                </div>
                <div className="dashboard-layout">
                    {app.mobile ? (
                        <div className="services">
                            {isLoading
                                ? loadingComponent
                                : mobileNavBox.map((item, index) => (
                                      <ActivityCard data={item} key={index} />
                                  ))}
                        </div>
                    ) : (
                        <div className="services">
                            {isLoading
                                ? loadingComponent
                                : navBox.map((item, index) => (
                                      <ActivityCard data={item} key={index} />
                                  ))}
                        </div>
                    )}
                </div>
            </div>
        </StyledContainer>
    );
};

interface IProps {
    data: INavigationBox;
}
const ActivityCard = (props: IProps) => {
    const { data } = props;
    const history = useHistory();
    return (
        <StyledCard onClick={() => history.push(data.link)}>
            <div className="image">
                {data.iconUrl === "manage" && <ManageIcon />}
                {data.iconUrl === "point" && <PointIcon />}
                {data.iconUrl === "success" && <SuccessIcon />}
                {data.iconUrl === "gift" && <GiftIcon />}
                {data.iconUrl === "contact" && <ContactIcon />}
                {data.iconUrl === "setting" && <SettingIcon />}
            </div>
            <h5>{data.title}</h5>
            <p className="visible-md">{data.text}</p>
        </StyledCard>
    );
};
const StyledCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    gap: 16px;
    /* height: 258px; */
    background: #ffffff;
    border-radius: 8px;
    &:hover {
        cursor: pointer;
    }
    .image {
        width: 100%;
        display: flex;
        justify-content: center;
        background: #f7f7f8;
        border-radius: 8px;
        padding: 16px;
        min-height: 160px;
        align-items: center;
        img {
            width: 108.5px;
            height: 114.72px;
        }
    }
    h5,
    p {
        margin: 0;
    }
    h5 {
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 27px;
        text-align: center;
        color: #000000;
    }
    p {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #a5a5a5;
    }
    .visible-md {
        display: block;
    }
    @media screen and (max-width: ${(p) => p.theme.breakPoints.breakOnlyMobile}) {
        .visible-md {
            display: none;
        }
        text-align: center;
        .image {
            svg {
                height: 58px;
            }
        }
        h5 {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            color: #000000;
        }
        p {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            text-align: center;
            color: #a5a5a5;
        }
        .image {
            img {
                width: 82px;
                height: 57px;
            }
        }
    }
`;
const StyledContainer = styled.div<{ scrollable: boolean }>`
    height: 86vh;
    overflow-y: ${(p) => (p.scrollable ? "hidden" : "auto")};
    padding-right: ${(p) => (p.scrollable ? "6px" : "0")};
    overflow-x: hidden;
    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        @media (max-width: ${(p) => p.theme.breakPoints.breakMobileMedium}) {
            display: none;
        }
    }

    ::-webkit-scrollbar-track {
        display: none;
    }

    ::-webkit-scrollbar-thumb {
        background: ${(p) => p.theme.colors.fadedText};
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        cursor: grab;
    }
    .marginX-8 {
        margin: 8px 0;
    }
    .top-field {
        background-color: white;
        padding: 5rem;
        h4 {
            color: ${(p) => p.theme.colors.black};
            font-weight: 600;
            font-size: 25px;
            padding: 14px 0;
        }
        .ant-divider-horizontal {
            margin: 2.5rem 0;
        }
        .swiper-button {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .search-input {
            display: flex;
            justify-content: flex-end;

            input {
                height: 49px;
                width: 331px;
                min-width: 300px;
                max-width: 340px;
                font-size: 16px;
                color: #646464;
            }
            @media (max-width: ${(p) => p.theme.breakPoints.breakOnlyMobile}) {
                margin-top: 20px;
                justify-content: flex-start;
            }
        }
        .user-info {
            display: flex;
            .avatar {
                margin-right: 26px;
            }
            .name-position {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
            .user-name {
                font-weight: 700;
                font-size: 35px;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .user-position {
                padding: 7px 23px;
                background-color: #e1e1e1;
                border-radius: 40px;
                width: fit-content;
                max-width: 300px;
                font-size: 16px;
                font-weight: 600;
                color: black;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    .body-field {
        padding: 5rem;
        padding-top: 2.5rem;
        .title {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            h4 {
                font-style: normal;
                font-weight: 700;
                font-size: 35px;
                line-height: 48px;
                color: #000000;
                padding-right: 14px;
            }
            small {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                color: #a5a5a5;
            }
            h4,
            small {
                margin: 0;
            }
        }
        @media screen and (max-width: ${(p) => p.theme.breakPoints.breakOnlyMobile}) {
            .title {
                flex-direction: column;
                align-items: baseline;
                margin-bottom: 10px;
                h4 {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 27px;
                    color: #000000;
                }
                small {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    color: #a5a5a5;
                }
            }
        }
    }
    @media screen and (max-width: ${(p) => p.theme.breakPoints.breakMobileMedium}) {
        .top-field,
        .body-field {
            padding: 1.5rem 1.5rem 4rem 1.5rem;
        }
    }
    .dashboard-layout {
        display: flex;
        column-gap: 16px;
        .benefit {
            flex: 1;
        }
        .services {
            flex: 3;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 16px;
        }
    }
    .point-layout {
        display: flex;
        column-gap: 16px;
        .progress-card {
            flex: 3;
        }
        .point-card {
            flex: 2;
        }
    }
    @media screen and (max-width: ${(p) => p.theme.breakPoints.breakOnlyMobile}) {
        .dashboard-layout {
            flex-direction: column-reverse;
            row-gap: 16px;
            .benefit {
            }
            .services {
                grid-template-columns: repeat(1, 1fr);
            }
        }
        .point-layout {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 10px;
            .progress-card {
                flex: 3;
            }
            .point-card {
                flex: 2;
            }
        }
    }
`;
