import { ISvg } from "@interfaces";
export const IconExport = (props: ISvg) => {
    return (
        <svg
            width={props.size || "24"}
            height={props.size || "25"}
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask
                id="mask0_1564_12262"
                maskUnits="userSpaceOnUse"
                x="4"
                y="8"
                width="16"
                height="12"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4 8.93531H20V19.9307H4V8.93531Z"
                    fill={props.color || "white"}
                />
            </mask>
            <g mask="url(#mask0_1564_12262)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.4522 19.9307H7.54807C5.59203 19.9307 4 18.3395 4 16.3827V12.4826C4 10.5265 5.59203 8.93531 7.54807 8.93531H8.29449C8.62569 8.93531 8.8945 9.20412 8.8945 9.53532C8.8945 9.86653 8.62569 10.1353 8.29449 10.1353H7.54807C6.25285 10.1353 5.20002 11.1882 5.20002 12.4826V16.3827C5.20002 17.6779 6.25285 18.7307 7.54807 18.7307H16.4522C17.7467 18.7307 18.8003 17.6779 18.8003 16.3827V12.4754C18.8003 11.185 17.7507 10.1353 16.4611 10.1353H15.7066C15.3754 10.1353 15.1066 9.86653 15.1066 9.53532C15.1066 9.20412 15.3754 8.93531 15.7066 8.93531H16.4611C18.4123 8.93531 20.0003 10.5233 20.0003 12.4754V16.3827C20.0003 18.3395 18.4083 19.9307 16.4522 19.9307"
                    fill={props.color || "white"}
                />
            </g>
            <mask
                id="mask1_1564_12262"
                maskUnits="userSpaceOnUse"
                x="11"
                y="3"
                width="2"
                height="12"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.3999 3.5H12.5999V14.3329H11.3999V3.5Z"
                    fill={props.color || "white"}
                />
            </mask>
            <g mask="url(#mask1_1564_12262)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.9999 14.3329C11.6687 14.3329 11.3999 14.0641 11.3999 13.7329V4.09991C11.3999 3.76871 11.6687 3.4999 11.9999 3.4999C12.3311 3.4999 12.5999 3.76871 12.5999 4.09991V13.7329C12.5999 14.0641 12.3311 14.3329 11.9999 14.3329"
                    fill={props.color || "white"}
                />
            </g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.66769 7.04295C9.51488 7.04295 9.36128 6.98455 9.24448 6.86774C9.01007 6.63414 9.00847 6.25493 9.24288 6.01973L11.5749 3.67728C11.7997 3.45088 12.1997 3.45088 12.4245 3.67728L14.7574 6.01973C14.991 6.25493 14.9902 6.63414 14.7558 6.86774C14.5206 7.10135 14.1414 7.10135 13.9078 6.86614L11.9997 4.95091L10.0925 6.86614C9.97569 6.98455 9.82129 7.04295 9.66769 7.04295"
                fill={props.color || "white"}
            />
        </svg>
    );
};
