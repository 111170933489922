import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router";
import { useSelector } from "react-redux";

import { StyledNewDetail } from "@components";
import { IConplaint } from "@interfaces";
import { PATH_COMPLAINT } from "@configs";
import { useNotify, usePermission } from "@utils";
import { conplaintAPI } from "@api";
import { selectAuth } from "@redux";
import { ModuleComplainInfo } from "@modules";

export const PageComplaintInfo = () => {
    const { id } = useParams<{ id: string }>();
    const org_id = useSelector(selectAuth).auth?.organization_data?._id;
    const history = useHistory();
    const { error } = useNotify();
    // page state
    const [complaint, setComplaint] = useState<IConplaint>();
    const permisssion = useSelector(selectAuth).permission;
    // should check the premission at api
    usePermission({ permission: permisssion?.permission_list.complain.is_manage });
    useEffect(() => {
        fetchComplaint();
    }, []);
    const fetchComplaint = async () => {
        try {
            const response = await conplaintAPI.detail({
                organization_id: org_id || "",
                complain_id: id,
            });
            const data: IConplaint = response.data.result;
            setComplaint(data);
        } catch (err: any) {
            error(err.msg);
            history.push(PATH_COMPLAINT);
        }
    };
    const updateData = (data: IConplaint) => {
        setComplaint(data);
    };

    return (
        <StyledNewDetail>
            <ModuleComplainInfo complaint={complaint} updateData={updateData} />
        </StyledNewDetail>
    );
};
