import { IServiceParams, IService, IServicesParams, ISortService } from "@interfaces";

import axiosClient from "./axiosClient";

export const serviceAPI = {
    getServices: (params: IServicesParams) => {
        const url = "service/getServices";
        return axiosClient.get(url, { params });
    },
    getService: (params: IServiceParams) => {
        const url = "service/getService";
        return axiosClient.get(url, { params });
    },
    deleteService: (params: IServiceParams) => {
        const url = `service/deleteService`;
        return axiosClient.post(url, params);
    },
    updateService: (params: IService) => {
        const url = "service/updateService";
        return axiosClient.patch(url, params);
    },
    createService: (params: IService) => {
        const url = "service/createService";
        return axiosClient.post(url, params);
    },
    setSortingService: (params: ISortService) => {
        const url = "service/setSortingService";
        return axiosClient.patch(url, params);
    },
};
