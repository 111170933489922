import { ComponentMaps } from "./Maps";
import { ComponentSearchInput } from "./Search";

interface IProps {
    location_lat: number;
    location_lon: number;
    onChangeLocation: (lat: number, lon: number, address: string) => void;
}

export const ComponentSearchMap = (props: IProps) => {
    return (
        <div>
            <ComponentMaps
                onChangeLocation={props.onChangeLocation}
                location_lat={props.location_lat}
                location_lon={props.location_lon}
            />
            <ComponentSearchInput onChangeLocation={props.onChangeLocation} />
        </div>
    );
};
