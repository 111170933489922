export const Share = () => (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M21 42C32.598 42 42 32.598 42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 32.598 9.40202 42 21 42Z"
            fill="#F7F9FC"
        />
        <path
            d="M20.9999 41.5799C32.3659 41.5799 41.5799 32.3659 41.5799 20.9999C41.5799 9.6339 32.3659 0.419922 20.9999 0.419922C9.6339 0.419922 0.419922 9.6339 0.419922 20.9999C0.419922 32.3659 9.6339 41.5799 20.9999 41.5799Z"
            fill="#F7F9FC"
        />
        <mask id="mask0_14_8868" maskUnits="userSpaceOnUse" x="9" y="16" width="24" height="17">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.84375 16.3464H32.8494V32.1563H9.84375V16.3464Z"
                fill="white"
            />
        </mask>
        <g mask="url(#mask0_14_8868)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.7483 32.1563H14.9454C12.1329 32.1563 9.84375 29.8683 9.84375 27.0546V21.4469C9.84375 18.6344 12.1329 16.3464 14.9454 16.3464H16.0186C16.4948 16.3464 16.8813 16.7329 16.8813 17.2092C16.8813 17.6854 16.4948 18.0719 16.0186 18.0719H14.9454C13.083 18.0719 11.5692 19.5857 11.5692 21.4469V27.0546C11.5692 28.917 13.083 30.4308 14.9454 30.4308H27.7483C29.6095 30.4308 31.1244 28.917 31.1244 27.0546V21.4365C31.1244 19.5811 29.6152 18.0719 27.7609 18.0719H26.6762C26.2 18.0719 25.8135 17.6854 25.8135 17.2092C25.8135 16.7329 26.2 16.3464 26.6762 16.3464H27.7609C30.5665 16.3464 32.8499 18.6298 32.8499 21.4365V27.0546C32.8499 29.8683 30.5608 32.1563 27.7483 32.1563Z"
                fill="#C5C5C5"
            />
        </g>
        <mask id="mask1_14_8868" maskUnits="userSpaceOnUse" x="20" y="8" width="3" height="17">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.4844 8.53125H22.2098V24.1074H20.4844V8.53125Z"
                fill="white"
            />
        </mask>
        <g mask="url(#mask1_14_8868)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.3471 24.1076C20.8709 24.1076 20.4844 23.7211 20.4844 23.2448V9.39398C20.4844 8.91775 20.8709 8.53125 21.3471 8.53125C21.8233 8.53125 22.2098 8.91775 22.2098 9.39398V23.2448C22.2098 23.7211 21.8233 24.1076 21.3471 24.1076Z"
                fill="#2651FF"
            />
        </g>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.9933 13.6255C17.7736 13.6255 17.5527 13.5415 17.3848 13.3736C17.0478 13.0377 17.0455 12.4924 17.3825 12.1542L20.7356 8.78614C21.0589 8.46061 21.634 8.46061 21.9573 8.78614L25.3116 12.1542C25.6474 12.4924 25.6463 13.0377 25.3093 13.3736C24.9711 13.7095 24.4258 13.7095 24.0899 13.3713L21.3464 10.6174L18.6041 13.3713C18.4362 13.5415 18.2142 13.6255 17.9933 13.6255Z"
            fill="#2651FF"
        />
    </svg>
);
