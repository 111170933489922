import { Route, useHistory, useLocation } from "react-router";
import { useSelector } from "react-redux";
import { useEffect } from "react";

import { IRoute } from "@interfaces";
import { LayoutApp } from "@layouts";
import { authRoutes, PATH_LOGIN } from "@configs";
import { selectAuth } from "@redux";

export const ComponentAppRoute = (props: IRoute) => {
    //page props
    const { path, exact } = props;
    //page hook
    const history = useHistory();
    const { pathname } = useLocation();
    //redux state
    const token = useSelector(selectAuth).admin?.token;
    const orgId = useSelector(selectAuth).admin?.organization_data?._id;

    const Component = props.component;
    const Layout = props.layout || LayoutApp;

    useEffect(() => {
        const isAuthRoute = authRoutes.some((item: string) => pathname.includes(item));
        if (!token && !orgId && !isAuthRoute) {
            history.push(PATH_LOGIN);
        }
    }, [token, orgId]);

    return (
        <>
            <Route
                path={path}
                exact={exact}
                render={() => (
                    <Layout>
                        <Component />
                    </Layout>
                )}
            />
        </>
    );
};
