import { useEffect, memo, useState, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Row, Col, Select, TimePicker, Checkbox, Input, Spin, Modal, Switch } from "antd";
import styled from "styled-components";
import moment from "moment";
import {
    INewsCategoryParams,
    IService,
    IDepartment,
    IUploadFiles,
    IServiceDepartment,
    IDepartmentCreate,
} from "@interfaces";
import {
    SharedCKEditor,
    StyledSubmitButton,
    StyledCancelButton,
    StyledCard,
    SharedInput,
    ComponentServicePreview,
    ShareButton,
    SharedButtonDefault,
    LocationIcon,
    ComponentSearchMap,
} from "@components";
import { useYup } from "@validations";
import { getCategories, setLoading, selectAuth } from "@redux";
import { showErrorMessage, useNotify } from "@utils";
import {
    enumCategory,
    MAX_CHAR,
    PATH_DEPARTMENT_CREATE,
    PATH_NEWS,
    PATH_SERVICES,
    PATH_SERVICES_CREATE,
    serviceHours,
    defautLocation,
    PATH_DEPARTMENT,
} from "@configs";
import { departmentAPI, orgApi, serviceAPI } from "@api";
import Geocode from "react-geocode";

Geocode.setApiKey("AIzaSyAW-yZSy9ibuh4jq9QmiV5_cLqC9VKxdKQ");
Geocode.setLanguage("en");
Geocode.setRegion("th");
Geocode.setLocationType("ROOFTOP");

const { TextArea } = Input;
interface IProps {
    department: IServiceDepartment | undefined;
    template: IDepartmentCreate | undefined;
}
const format = "HH:mm";

const initData: IDepartmentCreate = {
    organization_id: "",
    code: "",
    name: "",
    phone_no: "",
    address: "",
    is_enable: true,
    location_lat: 13.1234,
    location_lon: 100.1234,
    service_list: [],
    business_hours: {
        monday: {
            is_active: true,
            open_hour: "09:00",
            close_hour: "18:00",
        },
        tuesday: {
            is_active: true,
            open_hour: "09:00",
            close_hour: "18:00",
        },
        wednesday: {
            is_active: true,
            open_hour: "09:00",
            close_hour: "18:00",
        },
        thursday: {
            is_active: true,
            open_hour: "09:00",
            close_hour: "18:00",
        },
        friday: {
            is_active: true,
            open_hour: "09:00",
            close_hour: "18:00",
        },
        saturday: {
            is_active: true,
            open_hour: "09:00",
            close_hour: "18:00",
        },
        sunday: {
            is_active: true,
            open_hour: "09:00",
            close_hour: "18:00",
        },
    },
};
const { Option } = Select;
export const ModuleServiceDeptForm = memo((props: IProps) => {
    //hook
    const { YupDept } = useYup();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const { error, success } = useNotify();
    const [showModal, setShowModal] = useState<boolean>(false);
    const organization_id = useSelector(selectAuth).auth?.admin_data?.organization_id;
    const searchInputRef = useRef<any | null>(null);
    const [cat, setCat] = useState<string>("");
    const pathName = window.location.pathname;
    const createDept = Yup.object().shape(YupDept);
    const [currentAddress, setCurrentAddress] = useState("");
    const [isLoadingInput, setLoadingInput] = useState(false);
    const [lat, setLat] = useState(13.7710578);
    const [lon, setLon] = useState(100.519741);
    const [formValues, setFormValues] = useState(initData);
    const { department, template } = props;
    useEffect(() => {
        if (template) {
            setFieldValue("service_list", template.service_list);
        }
        if (department) {
            setFieldValue("name", department.name);
            setFieldValue("code", department.code);
            setFieldValue("phone_no", department.phone_no);
            setFieldValue("service_list", department.service_list);
            setFieldValue("business_hours", department.business_hours);
            setFieldValue("location_lat", department.location.latlon[0]);
            setFieldValue("location_lon", department.location.latlon[1]);
            setFieldValue("address", department.address);
            setFieldValue("is_enable", department.is_enable);
            setCurrentAddress(department.address);
        }
    }, [department, template]);
    const handleEnter = (e: string) => {
        if (e === "Enter") {
            createCategory();
            searchInputRef.current.focus();
        }
    };
    const createCategory = async () => {
        dispatch(setLoading(true));
        // if (news?.organization_id === "") return;
        try {
            const payload: INewsCategoryParams = {
                category_name: cat,
                organization_id: organization_id || "",
                is_enable: true,
            };
            const response = await orgApi.createCat(payload);
            success(response.data.msg);
            dispatch(getCategories({ organization_id: organization_id || "" }));
        } catch (err: any) {
            if (err) {
                error(err.msg);
            } else error(t("message.create.fail"));
        } finally {
            dispatch(setLoading(false));
        }
    };
    const handleDeptSubmit = async (values: IDepartmentCreate) => {
        console.log(values);
        dispatch(setLoading(true));
        try {
            if (pathName === PATH_DEPARTMENT_CREATE) {
                const res = await departmentAPI.createServiceDepartment({
                    ...values,
                    ...{ organization_id: organization_id || "" },
                });
                if (res.data.error === "0") {
                    success(res.data.msg);
                    history.push(PATH_DEPARTMENT);
                } else {
                    error(res.data.msg);
                }
            } else {
                const res = await departmentAPI.updateServiceDepartment({
                    ...values,
                    ...{ organization_id: organization_id || "" },
                    ...{ service_department_id: department?._id },
                });
                if (res.data.error === "1") {
                    error(res.data.msg);
                } else {
                    success(res.data.msg);
                    history.push(PATH_DEPARTMENT);
                }
            }
        } catch (err: any) {
            if (err.response) {
                error(showErrorMessage(err.response));
            } else error(t("message.error"));
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleCancel = () => {
        history.push(PATH_DEPARTMENT);
    };

    const { values, handleChange, handleBlur, handleSubmit, errors, touched, setFieldValue } =
        useFormik({
            initialValues: initData,
            validationSchema: createDept,
            enableReinitialize: true,
            onSubmit: handleDeptSubmit,
        });

    const handleChangeStatus = (e: boolean) => {
        setFieldValue("is_enable", e);
    };

    const handleUploadImage = (images: string[]) => {
        setFieldValue("image_url_list", [...images]);
    };

    const handleChangeGuideLine = (event: any, editors: any) => {
        const data = editors.getData();
        setFieldValue("description", data);
    };
    const handleSelectType = (value: string) => {
        setFieldValue("news_category_id", value);
    };
    const handleChangeAddress = (e) => {
        setCurrentAddress(e.target.value);
    };
    const handleGetLocation = () => {
        setShowModal(true);
    };
    const onChangeLocation = (lat: number, lon: number, address: string) => {
        setFieldValue("location_lat", lat);
        setFieldValue("location_lon", lon);
        setLat(lat);
        setLon(lon);
        if (address === "") {
            formatAddress(lat, lon);
        } else {
            setCurrentAddress(address);
            setFieldValue("address", address);
        }
    };
    const formatAddress = (lat, long) => {
        setLoadingInput(true);
        Geocode.fromLatLng(lat, long).then(
            (response) => {
                const res = response.results[0].formatted_address;
                setCurrentAddress(res);
                // setFieldValue("address", res);
                setFieldValue("address", res);
                setLoadingInput(false);
            },
            (error) => {
                console.error(error);
            }
        );
    };
    const handleCheckDay = (value, index) => {
        setFieldValue(`business_hours[${index}].is_active`, value);
    };
    const handleChangeHours = (time, timeString, day, type) => {
        setFieldValue(`business_hours[${day}][${type}]`, timeString);
    };

    return (
        <div className="form-wrap">
            <form onSubmit={handleSubmit}>
                <div className="page-header">
                    <h3>
                        {pathName.includes(PATH_SERVICES_CREATE)
                            ? `สร้างหน่วยงานทำธุรกรรม`
                            : `สร้างหน่วยงานทำธุรกรรม`}
                    </h3>
                    <p className="sub-title">{`โปรดระบุรายละเอียดให้ครบถ้วน`}</p>
                </div>
                <div className="page-body">
                    <div className="card-wrap">
                        <StyledCard>
                            <div className="title">{`ตั้งค่าธุรกรรม`}</div>
                            <SharedInput
                                label={`ชื่อหน่วยงาน*`}
                                // descLabel={t("page._2_100_characters")}
                                name="name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.name}
                                errors={errors.name}
                                touched={touched.name}
                                parentClassName="mb-6"
                                suffixIcon={<span>{`${values.name.length} / ${MAX_CHAR}`}</span>}
                            />
                            <Row gutter={10}>
                                <Col span={12}>
                                    <SharedInput
                                        label={`รหัสหน่วยงาน`}
                                        // descLabel={t("page._2_100_characters")}
                                        name="code"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.code}
                                        errors={errors.code}
                                        touched={touched.code}
                                        parentClassName="mb-6"
                                    />
                                </Col>
                                <Col span={12}>
                                    <SharedInput
                                        label={`เบอร์โทรศัพท์*`}
                                        // descLabel={t("page._2_100_characters")}
                                        name="phone_no"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.phone_no}
                                        errors={errors.phone_no}
                                        touched={touched.phone_no}
                                        parentClassName="mb-6"
                                        type="number"
                                    />
                                </Col>
                            </Row>
                        </StyledCard>
                    </div>
                    <div className="card-wrap">
                        <StyledCard>
                            <div className="title">{`เวลาเปิดทำการ`}</div>
                            <Row className="time-layout">
                                <Col md={3}>
                                    <Checkbox
                                        checked={values.business_hours.tuesday.is_active}
                                        onChange={(e) =>
                                            handleCheckDay(e.target.checked, "tuesday")
                                        }
                                    >{`วันจันทร์`}</Checkbox>
                                </Col>
                                <Col md={6}>
                                    <TimePicker
                                        defaultValue={moment(
                                            values.business_hours.tuesday.open_hour,
                                            format
                                        )}
                                        onChange={(time, timeString) =>
                                            handleChangeHours(
                                                time,
                                                timeString,
                                                "tuesday",
                                                "open_hour"
                                            )
                                        }
                                        format={format}
                                    />
                                </Col>
                                <Col span={1}>
                                    <div className="time-divider">-</div>
                                </Col>
                                <Col md={6}>
                                    <TimePicker
                                        defaultValue={moment(
                                            values.business_hours.tuesday.close_hour,
                                            format
                                        )}
                                        onChange={(time, timeString) =>
                                            handleChangeHours(
                                                time,
                                                timeString,
                                                "tuesday",
                                                "close_hour"
                                            )
                                        }
                                        format={format}
                                    />
                                </Col>
                                <Col md={5}></Col>
                            </Row>
                            <Row className="time-layout">
                                <Col md={3}>
                                    <Checkbox
                                        checked={values.business_hours.wednesday.is_active}
                                        onChange={(e) =>
                                            handleCheckDay(e.target.checked, "wednesday")
                                        }
                                    >{`วันจันทร์`}</Checkbox>
                                </Col>
                                <Col md={6}>
                                    <TimePicker
                                        defaultValue={moment(
                                            values.business_hours.wednesday.open_hour,
                                            format
                                        )}
                                        onChange={(time, timeString) =>
                                            handleChangeHours(
                                                time,
                                                timeString,
                                                "wednesday",
                                                "open_hour"
                                            )
                                        }
                                        format={format}
                                    />
                                </Col>
                                <Col span={1}>
                                    <div className="time-divider">-</div>
                                </Col>
                                <Col md={6}>
                                    <TimePicker
                                        defaultValue={moment(
                                            values.business_hours.wednesday.close_hour,
                                            format
                                        )}
                                        onChange={(time, timeString) =>
                                            handleChangeHours(
                                                time,
                                                timeString,
                                                "wednesday",
                                                "close_hour"
                                            )
                                        }
                                        format={format}
                                    />
                                </Col>
                                <Col md={5}></Col>
                            </Row>
                            <Row className="time-layout">
                                <Col md={3}>
                                    <Checkbox
                                        checked={values.business_hours.thursday.is_active}
                                        onChange={(e) =>
                                            handleCheckDay(e.target.checked, "thursday")
                                        }
                                    >{`วันจันทร์`}</Checkbox>
                                </Col>
                                <Col md={6}>
                                    <TimePicker
                                        defaultValue={moment(
                                            values.business_hours.thursday.open_hour,
                                            format
                                        )}
                                        onChange={(time, timeString) =>
                                            handleChangeHours(
                                                time,
                                                timeString,
                                                "thursday",
                                                "open_hour"
                                            )
                                        }
                                        format={format}
                                    />
                                </Col>
                                <Col span={1}>
                                    <div className="time-divider">-</div>
                                </Col>
                                <Col md={6}>
                                    <TimePicker
                                        defaultValue={moment(
                                            values.business_hours.thursday.close_hour,
                                            format
                                        )}
                                        onChange={(time, timeString) =>
                                            handleChangeHours(
                                                time,
                                                timeString,
                                                "thursday",
                                                "close_hour"
                                            )
                                        }
                                        format={format}
                                    />
                                </Col>
                                <Col md={5}></Col>
                            </Row>
                            <Row className="time-layout">
                                <Col md={3}>
                                    <Checkbox
                                        checked={values.business_hours.friday.is_active}
                                        onChange={(e) => handleCheckDay(e.target.checked, "friday")}
                                    >{`วันจันทร์`}</Checkbox>
                                </Col>
                                <Col md={6}>
                                    <TimePicker
                                        defaultValue={moment(
                                            values.business_hours.friday.open_hour,
                                            format
                                        )}
                                        onChange={(time, timeString) =>
                                            handleChangeHours(
                                                time,
                                                timeString,
                                                "friday",
                                                "open_hour"
                                            )
                                        }
                                        format={format}
                                    />
                                </Col>
                                <Col span={1}>
                                    <div className="time-divider">-</div>
                                </Col>
                                <Col md={6}>
                                    <TimePicker
                                        defaultValue={moment(
                                            values.business_hours.friday.close_hour,
                                            format
                                        )}
                                        onChange={(time, timeString) =>
                                            handleChangeHours(
                                                time,
                                                timeString,
                                                "friday",
                                                "close_hour"
                                            )
                                        }
                                        format={format}
                                    />
                                </Col>
                                <Col md={5}></Col>
                            </Row>
                            <Row className="time-layout">
                                <Col md={3}>
                                    <Checkbox
                                        checked={values.business_hours.saturday.is_active}
                                        onChange={(e) =>
                                            handleCheckDay(e.target.checked, "saturday")
                                        }
                                    >{`วันจันทร์`}</Checkbox>
                                </Col>
                                <Col md={6}>
                                    <TimePicker
                                        defaultValue={moment(
                                            values.business_hours.saturday.open_hour,
                                            format
                                        )}
                                        onChange={(time, timeString) =>
                                            handleChangeHours(
                                                time,
                                                timeString,
                                                "saturday",
                                                "open_hour"
                                            )
                                        }
                                        format={format}
                                    />
                                </Col>
                                <Col span={1}>
                                    <div className="time-divider">-</div>
                                </Col>
                                <Col md={6}>
                                    <TimePicker
                                        defaultValue={moment(
                                            values.business_hours.saturday.close_hour,
                                            format
                                        )}
                                        onChange={(time, timeString) =>
                                            handleChangeHours(
                                                time,
                                                timeString,
                                                "saturday",
                                                "close_hour"
                                            )
                                        }
                                        format={format}
                                    />
                                </Col>
                                <Col md={5}></Col>
                            </Row>
                            <Row className="time-layout">
                                <Col md={3}>
                                    <Checkbox
                                        checked={values.business_hours.sunday.is_active}
                                        onChange={(e) => handleCheckDay(e.target.checked, "sunday")}
                                    >{`วันจันทร์`}</Checkbox>
                                </Col>
                                <Col md={6}>
                                    <TimePicker
                                        defaultValue={moment(
                                            values.business_hours.sunday.open_hour,
                                            format
                                        )}
                                        onChange={(time, timeString) =>
                                            handleChangeHours(
                                                time,
                                                timeString,
                                                "sunday",
                                                "open_hour"
                                            )
                                        }
                                        format={format}
                                    />
                                </Col>
                                <Col span={1}>
                                    <div className="time-divider">-</div>
                                </Col>
                                <Col md={6}>
                                    <TimePicker
                                        defaultValue={moment(
                                            values.business_hours.sunday.close_hour,
                                            format
                                        )}
                                        onChange={(time, timeString) =>
                                            handleChangeHours(
                                                time,
                                                timeString,
                                                "sunday",
                                                "close_hour"
                                            )
                                        }
                                        format={format}
                                    />
                                </Col>
                                <Col md={5}></Col>
                            </Row>
                            <Row className="time-layout">
                                <Col md={3}>
                                    <Checkbox
                                        checked={values.business_hours.monday.is_active}
                                        onChange={(e) => handleCheckDay(e.target.checked, "monday")}
                                    >{`วันจันทร์`}</Checkbox>
                                </Col>
                                <Col md={6}>
                                    <TimePicker
                                        defaultValue={moment(
                                            values.business_hours.monday.open_hour,
                                            format
                                        )}
                                        onChange={(time, timeString) =>
                                            handleChangeHours(
                                                time,
                                                timeString,
                                                "monday",
                                                "open_hour"
                                            )
                                        }
                                        format={format}
                                    />
                                </Col>
                                <Col span={1}>
                                    <div className="time-divider">-</div>
                                </Col>
                                <Col md={6}>
                                    <TimePicker
                                        defaultValue={moment(
                                            values.business_hours.monday.close_hour,
                                            format
                                        )}
                                        onChange={(time, timeString) =>
                                            handleChangeHours(
                                                time,
                                                timeString,
                                                "monday",
                                                "close_hour"
                                            )
                                        }
                                        format={format}
                                    />
                                </Col>
                                <Col md={5}></Col>
                            </Row>
                            <Row className="time-layout">
                                <Col md={3}>
                                    <Checkbox
                                        checked={values.business_hours.monday.is_active}
                                        onChange={(e) => handleCheckDay(e.target.checked, "monday")}
                                    >{`วันจันทร์`}</Checkbox>
                                </Col>
                                <Col md={6}>
                                    <TimePicker
                                        defaultValue={moment(
                                            values.business_hours.monday.open_hour,
                                            format
                                        )}
                                        onChange={(time, timeString) =>
                                            handleChangeHours(
                                                time,
                                                timeString,
                                                "monday",
                                                "open_hour"
                                            )
                                        }
                                        format={format}
                                    />
                                </Col>
                                <Col span={1}>
                                    <div className="time-divider">-</div>
                                </Col>
                                <Col md={6}>
                                    <TimePicker
                                        defaultValue={moment(
                                            values.business_hours.monday.close_hour,
                                            format
                                        )}
                                        onChange={(time, timeString) =>
                                            handleChangeHours(
                                                time,
                                                timeString,
                                                "monday",
                                                "close_hour"
                                            )
                                        }
                                        format={format}
                                    />
                                </Col>
                                <Col md={5}></Col>
                            </Row>
                        </StyledCard>
                    </div>
                    <div className="card-wrap">
                        <StyledCard>
                            <div className="card-title">{`รายการธุรกรรมที่เปิดให้บริการ`}</div>
                            <div className="service-list">
                                {values.service_list.map((item, index) => (
                                    <Checkbox
                                        checked={item.is_selected}
                                        key={index}
                                        onChange={(e) => {
                                            setFieldValue(
                                                `service_list[${index}].is_selected`,
                                                e.target.checked
                                            );
                                        }}
                                    >
                                        {item.service_name}
                                    </Checkbox>
                                ))}
                            </div>
                        </StyledCard>
                    </div>
                    <div className="card-wrap">
                        <StyledCard>
                            <div className="card-title">{t("page.tessabarn_address")}</div>
                            <div className="custom-input">
                                <div className="label">{t("page.tessabarn_location")}</div>
                                <TextArea
                                    name="address"
                                    onBlur={handleBlur}
                                    onChange={handleChangeAddress}
                                    value={currentAddress}
                                    rows={4}
                                />
                                <div className="errors"></div>
                            </div>
                            <div className="custom-input">
                                <div className="label">{t("page.tessabarn_location")}</div>
                                <div className="location-input">
                                    <div className="input-layout">
                                        {isLoadingInput && (
                                            <div className="loading-input">
                                                <Spin />
                                            </div>
                                        )}
                                        <SharedInput
                                            // label={}
                                            // name="location"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={currentAddress}
                                            // errors={errors.title}
                                            // touched={touched.title}
                                            placeholder={t("page.select_pin_button")}
                                            disable={true}
                                            parentClassName="mb-6"
                                        />
                                    </div>
                                    <SharedButtonDefault
                                        type="default"
                                        size="large"
                                        className="ant-btn-primary"
                                        text={t("page.select_pin")}
                                        disable={isLoadingInput}
                                        onClick={handleGetLocation}
                                        style={{ height: 48 }}
                                        customIcon={<LocationIcon />}
                                    />
                                </div>
                                <div className="errors"></div>
                            </div>
                        </StyledCard>
                    </div>
                    <StyledSwitch className="switch-field">
                        <p>{`เปิดใช้งานหน่วยงานทำธุรกรรม`}</p>
                        <Switch
                            checked={values.is_enable}
                            className="is-hot-news"
                            onChange={handleChangeStatus}
                        />
                    </StyledSwitch>
                    <div className="btn-layout">
                        <StyledSubmitButton
                            type="default"
                            text={t("page.save")}
                            htmlType="submit"
                            // disable={isSubmitting}
                        />
                        <StyledCancelButton
                            type="sub"
                            text={t("page.cancel")}
                            htmlType="button"
                            onClick={handleCancel}
                        />
                    </div>
                </div>
            </form>
            <Modal
                title={t("page.conplaint_position")}
                visible={showModal}
                width={660}
                footer={null}
                onCancel={() => setShowModal(false)}
            >
                <ComponentSearchMap
                    location_lat={lat || defautLocation.lat}
                    location_lon={lon || defautLocation.long}
                    onChangeLocation={onChangeLocation}
                />
                <SharedButtonDefault
                    style={{
                        background: "white",
                        fontSize: 16,
                        padding: 14,
                        fontWeight: 600,
                        height: 49,
                        backgroundColor: "#0263E0",
                        color: "#fff",
                    }}
                    text={t("page.confirm_location")}
                    type="default"
                    size="default"
                    className="default-btn"
                    onClick={() => setShowModal(false)}
                />
            </Modal>
        </div>
    );
});

const StyledSwitch = styled.div`
    display: flex;
    align-items: center;
    p {
        margin-bottom: 0;
    }
`;
