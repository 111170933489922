import { useTranslation } from "react-i18next";
import { Col, Row, Menu, Dropdown, Button } from "antd";
import { MoreHorizontal } from "react-feather";
import { Link } from "react-router-dom";
import { INewsCategory, IPermission } from "@interfaces";
import { permissionAPI } from "@api";
import { enumNewsType, PATH_PERMISSIONS } from "@configs";
import { selectAuth } from "@redux";
import { useSelector } from "react-redux";
import { useNotify } from "@utils";

interface IProps {
    data: IPermission;
    itemJson: string;
    callbackGetList: () => void;
    index: number;
    newType?: enumNewsType;
    categories: INewsCategory[];
}

export const ComponentRowPermission = (props: IProps) => {
    //hooks
    const { t } = useTranslation();
    const { success, error } = useNotify();
    const organization_id = useSelector(selectAuth).auth?.organization_data?._id;
    //props
    const { data, callbackGetList } = props;
    const handleDelete = async () => {
        try {
            const res = await permissionAPI.deletePermission({
                organization_id: organization_id || "",
                permission_id: data._id || "",
            });
            if (res.data.error === "0") {
                success(res.data.msg);
                callbackGetList();
            } else {
                error(res.data.msg);
            }
        } catch (err: any) {
            if (err) {
                error(err.msg);
            } else error(t("message.delete.fail"));
        }
    };

    const menu = (
        <Menu>
            <Menu.Item key={1} onClick={handleDelete}>
                <div className="duplicate-btn">{t("page.delete")}</div>
            </Menu.Item>
        </Menu>
    );
    return (
        <div className="table-element">
            <div className="table-row small">
                <Row
                    style={{ height: "100%" }}
                    gutter={24}
                    type="flex"
                    align="middle"
                    justify="space-between"
                >
                    <Col className="col-item" span={8}>
                        <Link to={`${PATH_PERMISSIONS}/${data._id}`}>
                            <p className="news-title">{data.name || t("page.empty_text")}</p>
                        </Link>
                    </Col>
                    <Col className="col-item" span={8}>
                        <p>{data.description || t("page.empty_text")}</p>
                    </Col>
                    <Col className="col-item last-item" span={8}>
                        <Dropdown
                            //@ts-ignore
                            getPopupContainer={(trigger) => trigger.parentNode}
                            overlay={menu}
                            trigger={["click"]}
                            placement="bottomRight"
                        >
                            <Button style={{ border: "unset" }}>
                                <MoreHorizontal />
                            </Button>
                        </Dropdown>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
