import { useEffect } from "react";
import { Drawer } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useFormik } from "formik";
import { useYup } from "@validations";
import { SharedInput, StyledSubmitButton, StyledCancelButton } from "@components";
import { IDivision, IDivisionParams } from "@interfaces";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getDivisions, selectAuth, setLoading } from "@redux";
import { orgApi } from "@api";
import { useState } from "react";
import { useNotify } from "@utils";
interface Iprops {
    isVisible: boolean;
    onClose: () => void;
    editDivision: IDivision | undefined;
}

const formData: IDivisionParams = {
    organization_id: "",
    division_name: "",
    line_notify_token: "",
};

export const ModuleDrawerForm = (props: Iprops) => {
    const { isVisible, onClose, editDivision } = props;
    const { t } = useTranslation();
    const { YupDivision } = useYup();
    const createDivision = Yup.object().shape(YupDivision);
    const organization_id = useSelector(selectAuth).auth?.organization_data?._id;
    const adminId = useSelector(selectAuth).auth?.admin_data?._id;
    const dispatch = useDispatch();
    const { success, error } = useNotify();
    const [initData, setInitData] = useState<IDivisionParams>(formData);
    useEffect(() => {
        if (editDivision === undefined) {
            setInitData(formData);
        } else {
            setInitData({
                organization_id: "",
                division_name: editDivision.division_name,
                line_notify_token: editDivision.line_notify_token,
            });
            // setFieldValue("division_name", editDivision.division_name);
            // setFieldValue("line_notify_token", editDivision.line_notify_token);
        }
    }, [editDivision]);

    const handleDivisionSubmit = async (values) => {
        if (editDivision) {
            try {
                dispatch(setLoading(true));
                const { data } = await orgApi.updateDivision({
                    ...values,
                    organization_id: organization_id || "",
                    division_id: editDivision._id,
                });
                if (data.error === "0") {
                    success(data.msg);
                    dispatch(
                        getDivisions({
                            organization_id: organization_id || "",
                            admin_id: adminId || "",
                        })
                    );
                    dispatch(setLoading(false));
                    onClose();
                    resetForm();
                } else {
                    error(data.msg);
                    dispatch(setLoading(false));
                }
            } catch (err: any) {
                dispatch(setLoading(false));
                onClose();
            }
        } else {
            try {
                dispatch(setLoading(true));
                const { data } = await orgApi.createDivision({
                    ...values,
                    organization_id: organization_id || "",
                });
                if (data.error === "0") {
                    success(data.msg);
                    dispatch(
                        getDivisions({
                            organization_id: organization_id || "",
                            admin_id: adminId || "",
                        })
                    );
                    dispatch(setLoading(false));
                    onClose();
                    resetForm();
                } else {
                    error(data.msg);
                    dispatch(setLoading(false));
                }
            } catch (err: any) {
                dispatch(setLoading(false));
                onClose();
            }
        }
    };
    const {
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
        setFieldValue,
        resetForm,
    } = useFormik({
        initialValues: initData,
        validationSchema: createDivision,
        enableReinitialize: true,
        onSubmit: handleDivisionSubmit,
    });
    const handleClose = () => {
        // setInitData(formData);
        onClose();
        // resetForm();
    };
    return (
        <StyledDrawer
            width={538}
            visible={isVisible}
            onClose={handleClose}
            title={t("page.add_division")}
        >
            <form onSubmit={handleSubmit}>
                <SharedInput
                    label={t("page.division_name")}
                    // descLabel={t("page._2_100_characters")}
                    name="division_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.division_name}
                    errors={errors.division_name}
                    touched={touched.division_name}
                    parentClassName="mb-6"
                />
                <SharedInput
                    label={t("page.line_access_token")}
                    // descLabel={t("page._2_100_characters")}
                    name="line_notify_token"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.line_notify_token}
                    errors={errors.line_notify_token}
                    touched={touched.line_notify_token}
                    parentClassName="mb-6"
                />
                <div className="btn-layout">
                    <StyledSubmitButton
                        type="default"
                        text={t("page.save")}
                        htmlType="submit"
                        // disable={isSubmitting}
                    />
                    <StyledCancelButton
                        type="sub"
                        text={t("page.cancel")}
                        htmlType="button"
                        onClick={onClose}
                    />
                </div>
            </form>
        </StyledDrawer>
    );
};
const StyledDrawer = styled(Drawer)`
    .btn-layout {
        position: fixed;
        bottom: 14px;
    }
`;
