export const PricingFeature = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.59206 5.1609C8.03429 6.44792 6.65778 7.94179 5.50128 9.602L4.12699 8.22771C4.83278 7.1181 5.85423 6.24201 7.06269 5.71389C7.86589 5.36288 8.72579 5.17692 9.59206 5.1609Z"
            stroke="#A5A5A5"
            strokeWidth="1.3"
        />
        <path
            d="M18.8377 14.4066C18.8217 15.2729 18.6358 16.1328 18.2847 16.936C17.7566 18.1444 16.8805 19.1659 15.7709 19.8717L14.3966 18.4974C16.0569 17.3409 17.5507 15.9643 18.8377 14.4066Z"
            stroke="#A5A5A5"
            strokeWidth="1.3"
            strokeLinejoin="round"
        />
        <path
            d="M2.93358 21.0673C3.24335 21.0292 3.70519 20.9619 4.20305 20.853C4.63252 20.759 5.07401 20.6372 5.46135 20.4831C5.85785 20.3253 6.14862 20.1527 6.32012 19.9812C6.32015 19.9812 6.32018 19.9811 6.32022 19.9811M2.93358 21.0673L6.32022 19.9811M2.93358 21.0673C2.93885 21.0241 2.94468 20.9781 2.95112 20.9294C2.99128 20.6255 3.05429 20.2233 3.14771 19.7961C3.24159 19.3667 3.36334 18.9253 3.51744 18.5381C3.67515 18.1418 3.8478 17.8511 4.01924 17.6796C4.32463 17.3748 4.73842 17.2037 5.16984 17.2037C5.60121 17.2037 6.01496 17.3748 6.32034 17.6795M2.93358 21.0673L6.32034 17.6795M6.32022 19.9811C6.6251 19.6757 6.79635 19.2618 6.79635 18.8302C6.79635 18.3987 6.62515 17.9849 6.32034 17.6795M6.32022 19.9811L6.32034 17.6795"
            stroke="#A5A5A5"
            strokeWidth="1.3"
        />
        <path
            d="M21.7558 2.58675C21.6418 4.79902 21.1322 6.97213 20.2519 9.00322C20.1963 9.13152 20.0292 9.16205 19.9303 9.06316L14.9178 4.04878L21.7558 2.58675ZM21.7558 2.58675C21.7583 2.54107 21.7511 2.49537 21.7347 2.45265M21.7558 2.58675L21.7347 2.45265M21.7347 2.45265C21.7183 2.40992 21.6932 2.37112 21.6608 2.33877M21.7347 2.45265L21.6608 2.33877M21.6608 2.33877C21.6285 2.30642 21.5897 2.28124 21.5469 2.26487M21.6608 2.33877L21.5469 2.26487M21.5469 2.26487C21.5042 2.2485 21.4585 2.2413 21.4128 2.24375L21.5469 2.26487ZM15.8579 4.06983C17.5307 3.42343 19.2928 3.0332 21.0845 2.9134C20.9562 4.70039 20.5602 6.45686 19.9108 8.1242L15.8579 4.06983ZM20.39 8.60362L20.3896 8.60323C20.3898 8.60336 20.3899 8.60349 20.39 8.60362Z"
            stroke="#A5A5A5"
            strokeWidth="1.3"
        />
        <path
            d="M12.9738 13.6247H12.9742C13.6634 13.6243 14.3257 13.3504 14.8135 12.8624C15.301 12.3747 15.5748 11.7133 15.5748 11.0237C15.5748 10.5093 15.4223 10.0064 15.1365 9.57866C14.8507 9.15093 14.4445 8.81755 13.9692 8.62069C13.4939 8.42383 12.9709 8.37232 12.4664 8.47268C11.9619 8.57304 11.4984 8.82076 11.1346 9.18451C10.7709 9.54827 10.5232 10.0117 10.4228 10.5163C10.3224 11.0208 10.374 11.5438 10.5708 12.0191C10.7677 12.4943 11.1011 12.9006 11.5288 13.1864C11.9565 13.4722 12.4594 13.6247 12.9738 13.6247ZM5.79552 13.5962C7.5562 9.9311 10.4146 6.90744 13.9706 4.94369L19.0325 10.0047C17.0673 13.5575 14.054 16.4208 10.4012 18.2019L5.79552 13.5962ZM10.5645 18.3652L10.5644 18.3651C10.5645 18.3651 10.5645 18.3651 10.5645 18.3652Z"
            stroke="#A5A5A5"
            strokeWidth="1.3"
        />
    </svg>
);
