import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { Switch, Col, Row, Avatar, Menu, Dropdown, Button } from "antd";
import { MoreHorizontal } from "react-feather";
import moment from "moment";

import { IResult, IResultChangeStatus } from "@interfaces";

import { enumTypeFetchList, dateFormat, PATH_BIDDING_RESULT_LIST } from "@configs";
import { IconDrag } from "@components";
import { showErrorMessage, useNotify } from "@utils";
import { useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { setLoading } from "@redux";
import { useDispatch } from "react-redux";
import { biddingAPI } from "@api";
interface IProps {
    data: IResult;
    itemJson: string;
    handleEdit: (userId: string, points: number) => void;
    fetchData: () => void;
    callbackGetList: (typeGetList: enumTypeFetchList) => void;
    index: number;
}

export const ComponentRowResultBidding = (props: IProps) => {
    //hooks
    const { t } = useTranslation();
    const { success, error } = useNotify();
    const history = useHistory();

    const dispatch = useDispatch();
    //props
    const { data, itemJson, fetchData } = props;

    const { attributes, listeners, setNodeRef, transform } = useSortable({
        id: itemJson,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
    };

    const deleteItem = async () => {
        dispatch(setLoading(true));
        try {
            const payload: IResultChangeStatus = {
                organization_id: data.organization_id,
                information_id: data._id,
                is_enable: !data.is_enable,
            };

            await biddingAPI.resultDelete(payload);
            success(t("message.create.success"));
            // history.push(PATH_BIDDING_RESULT_LIST);
            fetchData();
        } catch (err: any) {
            if (err.response) {
                error(showErrorMessage(err.response));
            } else error(t("message.error"));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const menu = (
        <Menu>
            <Menu.Item key={1}>
                <div className="duplicate-btn" onClick={deleteItem}>
                    {t("page.delete")}
                </div>
            </Menu.Item>
        </Menu>
    );
    const handleUpdateStatus = async () => {
        dispatch(setLoading(true));
        try {
            const payload: IResultChangeStatus = {
                organization_id: data.organization_id,
                information_id: data._id,
                is_enable: !data.is_enable,
            };

            await biddingAPI.resultChangeStatus(payload);
            success(t("message.create.success"));
            // history.push(PATH_BIDDING_RESULT_LIST);
            fetchData();
        } catch (err: any) {
            if (err.response) {
                error(showErrorMessage(err.response));
            } else error(t("message.error"));
        } finally {
            dispatch(setLoading(false));
        }
    };
    return (
        <div className="table-element" ref={setNodeRef} style={style}>
            <div className="drag-icon" {...attributes} {...listeners}>
                <IconDrag />
            </div>
            <div className="table-row">
                <Row
                    style={{ height: "100%" }}
                    gutter={24}
                    type="flex"
                    align="middle"
                    justify="space-between"
                >
                    <Col className="col-item" lg={5} md={5} xs={14} sm={14}>
                        <Avatar
                            shape="square"
                            src={data?.image_url}
                            size={84}
                            style={{ minWidth: 84 }}
                        />
                        <Link to={`${PATH_BIDDING_RESULT_LIST}/${data._id}`}>
                            <p className="news-title">{data.title || t("page.empty_text")}</p>
                        </Link>
                    </Col>
                    <Col className="col-item" span={3}>
                        {data.documents?.length || t("page.empty_text")}
                    </Col>

                    <Col className="col-item m-member-code" xs={10} sm={10} lg={3} md={3}>
                        <p className="visible-md eng">
                            {moment(data.created_date).format(dateFormat) || t("page.empty_text")}
                        </p>
                    </Col>
                    <Col className="col-item visible-md" span={3}>
                        <Switch onChange={handleUpdateStatus} checked={data.is_enable} />
                    </Col>
                    <Col className="col-item" span={3}>
                        <Dropdown
                            //@ts-ignore
                            getPopupContainer={(trigger) => trigger.parentNode}
                            overlay={menu}
                            trigger={["click"]}
                            placement="bottomRight"
                        >
                            <Button style={{ border: "unset" }}>
                                <MoreHorizontal />
                            </Button>
                        </Dropdown>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
