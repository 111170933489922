export default {
    logo_text: "เทศบาลเมืองสนั่นรักษ์",
    e_teesaban: "Smart-เทศบาล by Rocket",
    home: "หน้าหลัก",
    activity: "กิจกรรม",
    news: "ข่าวสาร",
    privilege: "สิทธิพิเศษ",
    conplaint: "เรื่องร้องทุกข์",
    transaction: "ธุรกรรม",
    procurement: "จัดซื้อจัดจ้าง",
    transaction_contact: "ผู้ติดต่อธุรกรรม",
    all_news: "รายการข่าวทั้งหมด",
    hightlight: "ไฮไลท์ข่าวเด็ด",
    member: "สมาชิก",
    redeem_count: "แลกรับสิทธิพิเศษ",
    complaint_count: "ร้องทุกข์",
    transaction_count: "จองทำธุรกรรม",
    headlines: "หัวข้อข่าว",
    news_type: "ประเภทข่าว",
    date_creation: "วันที่สร้าง",
    display_period: "การเเสดงผล",

    // status: "สถานะ",
    active: "เปิดใช้งาน",
    news_manage: "จัดการ",

    search_news: "ค้นหา :",
    result: "ผลลัพธ์",
    all: "ทั้งหมด",
    inactive: "ปิด",
    add_news: "Add News",
    create_news: "สร้างหัวข้อข่าว",
    // authenication
    sanan_rak_municipality_office: "สำนักงานเทศบาลเมืองสนั่นรักษ์",
    login: "ลงชื่อเข้าใช้งาน",
    forgot: "ลืม",
    login_des: "สำหรับเจ้าหน้าที่สำนักงานเทศบาลเมืองสนั่นรักษ์",
    user_account: "บัญชีผู้ใช้งาน",
    password: "รหัสผ่าน*",
    email_id_card: "ระบุอีเมล / รหัสบัตรประชาชน",
    enter_password: "ระบุรหัสผ่าน",
    line_login: "เข้าสู่ระบบด้วย LINE",
    fill_registration_info: "กรอกข้อมูลลงทะเบียน",
    prefix: "คำนำหน้า",
    verify_your_identity: "โปรดระบุอีเมลเพื่อยืนยันตัวตน",
    //
    create_headline: "สร้างหัวข้อข่าว",
    edit_headline: "แก้ไขพาดหัวข่าว",
    specify_detail: "โปรดระบุรายละเอียดข่าวให้ครบถ้วน",
    news_detail: "รายละเอียดข่าว",
    news_picture: "รูปภาพข่าว",
    up_to_8: "(รายละเอียดข่าว)",
    new_display_period: "ระยะเวลาการแสดงเนื้อหาข่าว",
    show_in_highlight: "แสดงในไฮไลท์ข่าวเด็ด",
    add_news_type: "เพิ่มประเภทข่าว*",
    specify_news_type: "ระบุประเภทข่าว",
    share: "แชร์:",
    agree: "ตกลง",

    complaint_list: "รายการร้องทุกข์",
    add_a_complaint: "เพิ่มเรื่องร้องทุกข์",
    report_topic: "หัวข้อรายงาน",
    report_date: "วันที่รายงาน",
    recipient: "ผู้รับเรื่อง",
    manage: "จัดการ",
    carry_out: "ดำเนินการ",
    pending: "รอการอนุมัติ",
    doing: "กำลังดำเนินการ",
    canceled: "สำเร็จ",
    complete: "ยกเลิก",
    PENDING: "รอรับเรื่อง",
    DOING: "กำลังดำเนินการ",
    CANCELED: "ยกเลิก",
    COMPLETE: "สำเร็จ",

    please_provide_complete: "โปรดระบุรายละเอียดร้องทุกข์ให้ครบถ้วน",
    complaint_topic_title: "รายละเอียดร้องทุกข์",
    complaint_topic: "รายละเอียดร้องทุกข์",
    grievance_topic: "หัวข้อร้องทุกข์*",
    complaint_details: "หัวข้อร้องทุกข์",
    complaint_image: "รูปภาพการร้องทุกข์",
    privilege_picture: "รูปภาพสิทธิพิเศษ",
    place_name: "ชื่อสถานที่*",
    pin_location_compliant: "ปักหมุดตำแหน่งเรื่องร้องเรียน",
    select_pin_button: "กรุณาเลือกปุ่มปักหมุด",
    select_pin: "เลือกปักหมุด",
    complainant_information: "ข้อมูลผู้ร้องทุกข์",

    name_thai: "ชื่อ(ภาษาไทย)*",
    last_name_thai: "นามสกุล(ภาษาไทย)*",
    id_card_number: "เลขบัตรประชาชน*",
    telephone_number: "เบอร์โทรศัพท์*",
    location_coordinate: "พิกัดสถานที่",
    // surname: "ชื่อ - นามสกุล",
    latitude: "Latitude",
    longitude: "Longitude",
    approve_action: "ดำเนินการ",
    // division
    add_division: "เพิ่มกลุ่มหน่วยงาน",
    edit_division: "แก้ไขส่วน",
    division_name: "ชื่อหน่วยงาน",
    complaint_subject: "หัวข้อร้องทุกข์",
    relevant_division: "หน่วยงานที่เกี่ยวข้อง",
    division_type: "หน่วยงาน",
    text_search: "ค้นหาเรื่องร้องทุกข์",
    add_unit: "เพิ่มหน่วยงาน",
    line_id: "Line ID",
    // Activity
    privilege_list: "รายการสิทธิพิเศษ",
    create_event_topic: "สร้างหัวข้อกิจกรรม",
    activity_list: "รายการกิจกรรม",
    activity_list_category: "รายการกิจกรรม",
    activity_type: "ประเภทกิจกรรม",
    participant_number: "จำนวนผู้เข้าร่วม",
    fully_booked: "ผู้เข้าร่วมเต็มแล้ว",
    activity_period: "ระยะเวลากิจกรรม",
    activity_pictures: " รูปภาพกิจกรรม",
    date_of_creation: "วันที่สร้าง",
    create_activity: "สร้างกิจกรรม",
    activity_details: "รายละเอียดกิจกรรม",
    edit_activity_details: "แก้ไขข้อมูลกิจกรรม",
    total_participant: "จำนวนผู้เข้าร่วมทั้งหมด",
    registration_closed: "ปิดรับผู้เข้าร่วมกิจกรรม",
    mark_full: "(ทำเครื่องหมายว่าเต็มแล้ว)",
    activity_title_placeholder: "มหกรรมศิลปะนานาชาติ THAILAND",
    person: "คน",
    list_of_participants: "รายชื่อผู้เข้าร่วมกิจกรรม",
    edit_event_info: "แก้ไขข้อมูลกิจกรรม",
    no: "ลำดับที่",
    date_time_of_participation: "วัน/เวลาที่ขอเข้าร่วม",
    activity_topic: "หัวข้อกิจกรรม*",
    limited: "จำกัดจำนวน",
    no_limited: "ไม่จำกัดจำนวน",
    capacity: "จำนวนเปิดรับผู้เข้าร่วม*",
    address: "ชื่อสถานที่ / ที่อยู่สถานที่*",
    activity_duration: "ระยะเวลากิจกรรม*",
    event_registration_period: "ระยะเวลาลงทะเบียนกิจกรรม*",
    sign_up_period: "ระยะเวลาลงทะเบียนกิจกรรม*",
    condition: "เงื่อนไขกิจกรรม",
    total_number_claim: "จำนวนผู้ขอรับสิทธิ์ทั้งหมด",
    total_number_participants: "จำนวนผู้ขอรับสิทธิ์ทั้งหมด",
    social_appointment_info: "ข้อมูลผู้ขอรับสิทธิพิเศษ",
    limit: "จำกัดจำนวน",
    no_limit: "ไม่จำกัดจำนวน",
    // privileges & calim
    privileges: "ผู้ขอรับสิทธิพิเศษ",
    edit_special_rights_information: "เเก้ไขข้อมูลสิทธิพิเศษ",
    various_privileges: "สิทธิพิเศษต่างๆ",
    // privilege_list:"privilege list",
    redeem_special_privileges: "แลกรับสิทธิพิเศษ",
    special_premium: "เบี้ยพิเศษ",
    claim_list: "รายการเคลมเบี้ย",
    giveaway_list: "รายการสิทธิพิเศษ",
    giveaway_benefit: "สร้างสิทธิพิเศษ",
    giveaway_subject: "หัวข้อสิทธิพิเศษ",
    signup_person: "จำนวนผู้รับสิทธิ",
    availability_period: "การเเสดงผล",
    privilege_details: "รายละเอียดสิทธิพิเศษ",
    benefit_subject: "หัวข้อรับสิทธิ์",
    total_availability: "จำกัดจำนวน (ทั้งหมด)",
    availability_per_person: "จำกัดจำนวน (ต่อคน)",
    take_out: "เดือน",
    place_to_receive_Benefit: "สถานที่รับสิทธิพิเศษ",
    pickup_at_tessabarn: "รับที่เทศบาล",
    receive_at_home: "รับที่บ้าน",
    privilege_period: "ระยะเวลาการรับสิทธิพิเศษ",
    Condition: "เงื่อนไขการรับ",
    create_a_privilege_list: "สร้างรายการสิทธิพิเศษ",
    please_provide_complete_details_of_privileges: "โปรดระบุรายละเอียดสิทธิพิเศษให้ครบถ้วน",
    all_time: "ตลอดกาล",
    month: "Monthly",
    redeem_privilege: "แลกรับสิทธิพิเศษ",
    search_redeem: "ค้นหาผู้ใช้งานแลกรับสิทธิพิเศษ",
    search_for_user: "ค้นหารหัสรับสิทธิ์",
    surname: "ชื่อ - นามสกุล",
    id_card_code: "รหัสบัตรประชาชน",
    participant_information: "ข้อมูลผู้เข้าร่วมกิจกรรม",
    // phone_number: "หมายเลขโทรศัพท์",
    Eemail: "อีเมล",
    details: "รายละเอียด",
    privilege_id: "รหัสสิทธิพิเศษ",
    date_redeem: "วันที่ขอรับสิทธิพิเศษ",
    status: "สถานะ",
    where_to_get_redemption: "สถานที่รับสิทธิพิเศษ",
    redeem_now: "แลกทันที",
    cancel: "ยกเลิก",
    confirm_redeem: "ยืนยันการแลกรับสิทธิพิเศษ",
    lastname: "ศิรวัชร ศรีลาโชติ",
    id_card_no: "รหัสบัตรประชาชน :",
    get_privilege: "รหัสสิทธิพิเศษ",
    privilege_code: "รหัสสิทธิพิเศษ :",
    allowance_details: "รายละเอียดหัวข้อการเคลมเบี้ย",
    allowance_list: "รายการเคลมเบี้ย",
    please_provide_complete_details: "โปรดระบุรายละเอียดให้ครบถ้วน",
    claim_details: "รายละเอียดการเคลมเบี้ย",
    allowance_subject: "หัวข้อเบี้ย",
    how_to_claim_allowance: "รายละเอียดกฎการเคลมเบี้ย*",
    place_name_location_address: "ชื่อสถานที่ / ที่อยู่สถานที่*",
    images_and_relevant_docs: "รูปภาพ และเอกสารประกอบ",
    relevant_form_to_claim_allowance: "โปรดแนบเอกสารฟอร์มการเคลมเบี้ย*",
    placeholder_upload_file: "กรุณาอัปโหลดไฟล์สกุล .PDF",
    upload_files: "อัปโหลดไฟล์",
    last_update: "วันที่อัปเดทข้อมูล",
    // settings
    tessabarn_info: "ข้อมูลเทศบาล",
    theme_setting: "ตั้งค่าธีม",
    profile: "ข้อมูลโปรไฟล์",
    permission_setting: "กำหนดสิทธิผู้ใช้งาน",
    admin_user_list: "รายชื่อผู้ใช้งาน",
    permission_list: "รายการสิทธิผู้ใช้งาน",
    integration: "บูรณาการ",
    permission_privileges: "ผู้ขอรับสิทธิพิเศษ",

    tessabarn_information: "ข้อมูลเทศบาล",
    tessabarn_details: "รายละเอียดเทศบาล",
    tessabarn_name_thai: "ชื่อเทศบาล (ภาษาไทย)",
    tessabarn_name_eng: "ชื่อเทศบาล (ภาษาอังกฤษ)",
    tessabarn_prefix: "Subdomain prefix",
    tessabarn_prefix_url: "URL ของ web app SMART Tessabarn",
    contact_details: "ช่องทางการติดต่อเทศบาล",
    tessabarn_phone: "เบอร์โทรศัพท์",
    working_time: "เวลาทำการสำนักงาน",
    phone_no: "เบอร์โทรศัพท์*",
    office_time: "เวลาทำการสำนักงาน*",
    tessabarn_address: "ที่อยู่เทศบาล",
    tessabarn_location: "ปักหมุดที่อยู่เทศบาล",
    tessabarn_location_sub: "ตัวอย่าง ตำบาล อำเภอ รหัสไปรษณีย์",
    tessabarn_map: "ปักหมุดที่อยู่เทศบาล",
    edit_map_location: "เลือกปักหมุด",
    online_channel: "ช่องทางการติดต่อออนไลน์",
    online_channel_url: "โปรดระบุ URL แต่ละช่องทาง",
    // relevant_division:"relevant division",
    relevant_division_name: "ชื่อหน่วยงานที่เกี่ยวข้อง*",
    add_relevant_division: "เพิ่มหน่วยงานที่เกี่ยวข้อง",
    related_agencies: "หน่วยงานที่เกี่ยวยวข้อง",
    phone_number_group: "กลุ่มเบอร์ที่ {{number}}",
    name_of_phone_number_group: "รายชื่อกลุ่มเบอร์โทรศัพท์*",
    count_phone_number: "{{number}} หมายเลขโทรศัพท์",
    morethan_1: "มากกว่า 1 หมายเลขโทรศัพท์",
    phone_number: "หมายเลขเลขโทรศัพท์*",
    // theme
    please_complete_the_setting: "โปรดระบุรายละเอียดให้ครบถ้วน",
    theme_details: "รายละเอียดธีม",
    municipal_color_theme: "ธีมสีเทศบาล",
    logo: "โลโก้เทศบาล",
    confirm_location: "ยืนยันตำแหน่ง",
    // booking
    transaction_booking_table: "ตารางจองทำธุรกรรม",
    list_of_departments: "รายชื่อหน่วยงาน",
    booking_list: "รายการทำธุรกรรม",
    service_booking_list: "ตารางจองทำธุรกรรม",
    today_total_service: "ยอดจองทำธุรกรรมวันนี้ :",
    gender: "เพศ",
    requested_date_time: "วัน/เวลาที่ขอเข้าร่วม",
    view_applicant_details: "ดูรายละเอียดผู้ขอรับ",
    closed_for_attendees: "ผู้เข้าร่วมเต็มแล้ว",
    make_up: "(marked as full)",
    age: "อายุ",
    conplaint_position: "ตำแหน่งเรื่องร้องเรียน",
    input_search_news: "ค้นข่าวข่าว",
    right_per_person: "จำนวนสิทธิ์ต่อคน",
    total_rights: "จำนวนสิทธิ์ทั้งหมด",
    number: "ลำดับ",
    special_condition: "เงื่อนไข",
    select_date: "เลือกวันที่",
    agency: "หน่วยงาน",
    line_access_token: "Line notify token",
    category: "หมวดหมู่",
    add_category: "เพิ่มหัวข้อร้องทุกข์",
    edit_category: "แก้ไขหมวดหมู่",
    category_name: "ชื่อหมวดหมู่",
    header: "รายละเอียดสถานที่",
    municipal_image: "รูปภาพเทศบาล*",
    start_date: "วันที่เริ่ม",
    end_date: "สิ้นสุด",
    // admin
    user_rights_list: "รายการสิทธิผู้ใช้งาน",
    license_list: "รายชื่อสิทธิการใช้งาน",
    // details:"รายละเอียด",
    create_user_rights: "สร้างสิทธิผู้ใช้งาน",
    //manage
    user_role: "บทบาทผู้ใช้งาน",
    role_name: "ชื่อบทบาท",
    role_details: "รายละเอียด",
    set_up_user_rights: "ตั้งค่าสิทธิผู้ใช้งาน",
    activity_menu: "เมนูกิจกรรม",
    is_view: "ดูรายละเอียด",
    is_manage: "สร้าง / แก้ไข",
    is_manage_claim: "สร้าง/แก้ไข เบี้ย",
    is_manage_reward: "สร้าง/แก้ไข สิทธิ์พิเศษ",
    is_manage_bidding: "สร้าง / แก้ไข",
    is_manage_information: "สร้าง / แก้ไข",
    is_manage_organization: "สร้าง / แก้ไข",
    is_manage_permission: "จัดการสิทธิผู้ใช้งาน",
    view_details_create_edit: "ดูรายละเอียดสร้าง / แก้ไข",
    is_check: "ตรวจสอบรายการค่าขยะ",
    approve: "อนุมัติ รายการร้องทุกข์",
    select_the_organization_to_be_displayed_in_the_complaint_list:
        "เลือกหน่วยงานที่ต้องการแสดงในรายการร้องทุกข์",
    service_booking: "เมนธุรกรรม",
    select_the_entity_you_want_to_show_in_the_transaction: "เลือกหน่วยงานที่ต้องการแสดงในธุรกรรม",
    check_the_list_of_junk_values: "Check the list of junk values.",
    tessabarn_settings: "เมนูตั้งค่า และข้อมูลเทศบาล",
    activite: "ยืนยันตัวตนแล้ว",
    inactivite: "รอยืนยันตัวตน",
    activite_msg: "ส่งอีเมลยืนยันตัวตนสำเร็จ!",
    signing_emial: "อีเมลของคุณ",
    bidding_label: "เมนูจัดซื้อจัดจ้าง",
    trash_fee: "เมนูประชาสัมพันธ์หน้าเว็บไซต์",
    custom_web: "เมนูชำระค่าขยะ",
    // sidebar
    sidebar: {
        special_premium_items: "รายการเบี้ยพิเศษ",
        contacts_list: "รายชื่อสมาชิก",
        segment: "Segment",
        merge_suggestions: "Merge suggestions",
        rewards: "ของรางวัล",
        member_benefits: "สิทธิพิเศษ",
        coupons: "คูปอง",
        merge_settings: "Merge settings",
        free_points: "Free points",
        loyalty_rules: "กฎเกณฑ์สมาชิก",
        user_sign_up_settings: "ตั้งค่าการสมัครสมาชิก",
        account_settings: "ตั้งค่าบัญชีร้านค้า",
        traffic_source: "Traffic source",
        features_settings: "ตั้งค่าฟีเจอร์",
        integrations: "การเชื่อมต่อ",
        manage_permissions: "ผู้ดูแลระบบ",
        settings: "ตั้งค่า",
        contacts: "สมาชิก",
        contacts_title: "รายชื่อ",
        loyalty: "Loyalty",
        current_plan: "Current plan",
        edit_points_member: "แก้ไข Points ของสมาชิก",
        accept_point: "ยืนยันการแลก",
        future_is_now: "The Future is now",
        transaction: "รายการแลก",
        points: "Points",
        user_info: "ข้อมูลของผู้ใช้งาน",
        noti_settings: "Notification Settings",
        edit_profile: "แก้ไขข้อมูลส่วนตัว",
        payments: "Payments",
        pricing_features: "แพลนที่กำลังใช้งานอยู่",
        set_theme: "ตั้งธีมร้าน",
        first_menu: "Menu",
        store_front: "Storefront",
        contact: "รายชื่อสมาชิก",
        loyalty_features: "Loyalty",
        gain_friends: "เพิ่มสมาชิก",
        all_news: "รายการข่าวทั้งหมด",
        hightlight: "ไฮไลท์ข่าวเด็ด",
        people_conplaint: "เรื่องร้องทุกข์ประชาชน",
        conplaint_list: "รายการสิทธิพิเศษ",
        create_category: "หมวดหมู่ & หน่วยงาน",
        group_department: "กลุ่มหน่วยงาน",
        privilege_list: "รายการสิทธิพิเศษ",
        activity: "กิจกรรม",
        create_event_topic: "สร้างหัวข้อกิจกรรม",
        various_privileges: "สิทธิพิเศษต่างๆ",
        redeem_special_privileges: "รายการสิทธิพิเศษ",
        special_premium: "แลกรับสิทธิพิเศษ",
        claim_list: "เบี้ยพิเศษ",
        redeem_privilege: "แลกรับสิทธิพิเศษ",
        bidding_result: "ประกาศผลการจัดซื้อจัดจ้าง",
        bidding_list: "รายการประมูล",
        bidding_purchase: "ประมูลจัดซื้อ",
        bidding_employ: "ประมูลจัดจ้าง",
        home_menu: "เมนูหน้าหลัก",
        about: "เมนูแนะนำเทศบาล",
        org: "เมนูโครงสร้างองค์กร",
        docs_report: "เมนูรายงาน/เอกสาร",
        ser_info: "เมนูข้อมูลบริการประชาชน",
        tessabarn_info: "ข้อมูลเทศบาล",
        theme_setting: "ตั้งค่าธีม",
        profile: "ข้อมูลโปรไฟล์",
        permission_setting: "กำหนดสิทธิผู้ใช้งาน",
        transaction_booking_table: "ตารางจองทำธุรกรรม",
        list_of_departments: "รายชื่อหน่วยงาน",
        booking_list: "รายการทำธุรกรรม",
        integration: "บูรณาการ",
        admin_user_list: "รายชื่อผู้ใช้งาน",
        permission_list: "รายการสิทธิผู้ใช้งาน",
        complaint_list_one: "รายการร้องทุกข์",
        activity_list: "รายการกิจกรรม",
        integration_main: "การเชื่อมต่อ",
        line_integration: "การเชื่อมต่อ LINE",
        complaint_topic: "รายการเรื่องร้องทุกข์",
        activity_list_category: "ประเภทกิจกรรม",
        news_category: "ประเภทข่าว",
        activity_category: "ประเภทกิจกรรม",
        trashfee: "ชำระค่าขยะ",
        home: "เมนูหลัก",
        complaint: "เรื่องร้องทุกข์",
        privilege: "สิทธิพิเศษ",
    },
    booking_status: {
        COMPLETE: "ยืนยันการจอง",
        PENDING: "รอดำเนินการ",
        CANCELED: "ยกเลิกการจอง",
    },
    //old
    _is_equal_to_any_of: "เท่ากับค่า",
    _contains_any_of: "มีส่วนของค่า",
    _is: "คือ",
    _is_equal_to: "เท่ากับค่า",
    _is_before: "ก่อนค่า",
    _is_after: "หลังค่า",
    _is_between: "ระหว่างค่า",
    _is_greater_than: "มากกว่าค่า",
    _is_less_than: "น้อยกว่าค่า",
    _no_expiry: "ไม่มีวันหมดอายุ",
    _1_month: "1 เดือน",
    _2_เดือน: "2 เดือน",
    _3_เดือน: "3 เดือน",
    _4_เดือน: "4 เดือน",
    _5_เดือน: "5 เดือน",
    _6_เดือน: "6 เดือน",
    _7_เดือน: "7 เดือน",
    _8_เดือน: "8 เดือน",
    _9_เดือน: "9 เดือน",
    _10_เดือน: "10 เดือน",
    _11_เดือน: "11 เดือน",
    _12_เดือน: "12 เดือน",
    notifications: "แจ้งเตือน",
    settings: "ตั้งค่า",
    manage_contacts_edit_points: "จัดการรายชื่อ & แก้ไข Point ",
    create_loyalty_items: "สร้างรายการของรางวัล ",
    member_account_settings: "ตั้งค่าบัญชีสมาชิก",
    change_account_settings: "เปลี่ยนการตั้งค่าบัญชี",
    manage_roles: "จัดการสิทธิการใช้งาน",
    below_are_the_features_of_the_current_plan: "ข้างล่างนี้คือฟีเจอร์ของ Plan ปัจจุบัน",
    search_phone_number: "ค้นหาชื่อ, เบอร์โทร...",
    integrations: "Integrations",
    choose_option: "เลือกตัวเลือก",
    email: "อีเมล*",
    tel: "เบอร์โทร",
    Sign_up_settings: "ตั้งค่าการสมัครสมาชิก",
    tier_color: "สีของกลุ่มสมาชิก",
    tier_icon: "ไอคอนของกลุ่มสมา่ชิก",
    current_plan: "Plan ปัจจุบัน",
    file_segment_export: "นำไฟล์ออก",
    features_settings: "ตั้งค่าฟีเจอร์",

    all_month: "ทุกเดือน",
    search: "ค้นหา",
    tip_loading: "กำลังโหลด...",
    welcome_to_Admin: "ยินดีต้อนรับสู่เทศบาลเมืองสนั่นรักษ์",
    you_are_signing: "โปรดตั้งค่ารหัสผ่านเพื่อเข้าสู่ระบบ",
    instead: "แทนที่จะ",
    total: "ทั้งหมด",
    theme_color: "ธีมสี",
    cover_image: "ภาพหน้าปก",
    logo_image: "ภาพโลโก้",
    plan_settings: "ตั้งค่า Plan",
    you_are_on: "ตอนนี้คุณกำลังใช้งาน",
    free_plan: "Free plan",
    or: "หรือ",
    and: "และ",
    image_png: "รูปภาพจำเป็นต้องมีสกุลไฟล์ .png",
    image_empty: "ไม่ค้นพบรูปภาพ",
    image_error: "อัปโหลดรูปภาพเกิดการขัดข้อง",
    image_error_size: "รูปภาพไม่ควรมีขนาดเกิน 10MB",
    data_not_found: "ไม่ค้นพบข้อมูล",
    this_function_is_for_XXX_plan_users_only:
        "ฟีเจอร์นี้สำหรับผู้ใช้ Plan อื่น กรุณาติดต่อเพื่อสอบถามข้อมูลเพิ่มเติม",
    do_you_want_to_upgrade_your_plan_to_XXX: "คุณต้องการที่จะอัปเกรดไปยัง Plan XXX?",
    upgrade_now: "อัปเกรดเลย",
    all_member: "สมาชิกทั้งหมด",
    log_with: "ลงชื่อเข้าใช้งานด้วย",
    log_in: "ลงชื่อเข้าใช้งาน",
    need_account: "ต้องการสมัครสมาชิก?",
    free_sign_up: "สมัครสมาชิกเลย",
    register_free: "ฟรี สมัครสมาชิกได้ในทันที",
    change_password: "เปลี่ยนรหัสผ่าน",

    remember_me: "จดจำฉัน",
    sign_up_with: "สมัครสมาชิกด้วย",
    sign_up: "ลงทะเบียนฟรี",
    already_have_account: "มีบัญชีอยู่แล้ว?",
    name: "ชื่อ",
    create_an_account: "สร้างบัญชีของคุณ",
    text_verify_email: "กรุณายืนยันอีเมลของคุณ",
    sub_desc: "You've entered ",
    sub_desc_text: " as the email address for your account",
    desc_verify_email:
        "กรุณาตรวจสอบกล่องข้องความอีเมลของคุณเพื่อรับอีเมลยืนยัน. คุณยังไม่ได้รับอีเมลยืนยันฦ",
    resend_email: "ส่งอีเมลอีกครั้ง",
    what_is_your_name: "กรุณากรอกชื่อของคุณ",
    confirm_password: "ยืนยันรหัสผ่าน",
    continue: "ถัดไป",
    login_successfully: "ลงชื่อเข้าใช้งานสำเร็จ",
    login_failed: "ลงชื่อเข้าใช้งานไม่สำเร็จ",
    sign_up_successfully: "สมัครสมาชิกสำเร็จ",
    create_account_successfully: "สมัครสมาชิกไม่สำเร็จ",
    recover_password: "รับรหัสผ่านใหม่",
    email_recover_link: "ลิงค์สำหรับ รับรหัสผ่านใหม่",
    return_login: "กลับสู่หน้าลงเชื่อเข้าใช่งาน",
    new_password: "รหัสผ่านใหม่",
    new_confirm_password: "ยืนยันรหัสผ่านใหม่",
    reset_password_successfully: "รีเซ็ทรหัสผ่านใหม่สำเร็จ",
    update_profile_successfully: "อัปเดทข้อมูลส่วนตัวสำเร็จ",
    change_password_successfully: "เปลี่ยนรหัสผ่านสำเร็จ",
    current_password: "รหัสผ่านปัจจุบัน",
    confirm_new_password: "ยืนยันรหัสผ่านใหม่",
    store_front: "Storefront",
    contact_list: "Contact list",
    LINE: "LINE",
    connections: "Connections",
    inbox: "Inbox",
    workflow: "Workflow",
    report: "รายงาน",
    help: "ช่วยเหลือ",
    setting: "ตั้งต่า",
    done: "เสร็จสิ้น",
    edit: "แก้ไข",
    add: "เพิ่ม",
    save: "บันทึก",
    // cancel: "ยกเลิก",
    upgrade: "อัปเกรด",
    reset_password: "รีเซ็ทรหัสผ่าน",
    use: "ใช้",
    close: "ปิด",
    upload_text: "อัปโหลด {{name}}",
    delete: "ลบ",
    remove: "Remove from ข่าวเด็ด",
    duplicate: "คัดลอก",
    upload_image: "อัปโหลดรูปภาพ",
    sign_out: "ลงชื่อออก",
    back: "ย้อนกลับ",
    create: "สร้าง",
    apply: "ใช้งาน",
    verify: "ยืนยัน",
    merge: "Merge",
    confirm: "ยืนยัน",
    add_fields: "เพิ่มช่อง",
    choose_your_plan: "เลือก Plan ของคุณ",
    crm_loyalty_hub: "CRM + Loyalty hub",
    crm_standalone: "CRM standalone",
    loyalty_standalone: "Loyalty standalone",
    year_save_20: "สมัครรายปีลด 20%",
    monthly: "รายเดือน",
    choose_plan: "เลือก Plan",
    start_a_free_14_day_trial: "เริ่มต้นใช้งานฟรี 14 วัน",
    _month: "เดือน",
    desc: "สำหรับธุรกิจที่ต้องการ Opitimize web queries",
    free: "Free",
    standard: "Standard",
    professional: "Professional",
    $: "฿",
    loyalty_points: " Point สมาชิก",
    coupons: "คูปอง",
    member_benefits: "สิทธิพิเศษ",
    membership_tiering: "แบ่งกลุ่มสมาชิก",
    custom_form: "ปรับแต่งฟอร์มสมัครสมาชิก",
    multi_signup_method: "สมัครสมาชิกได้หลายช่องทาง",
    merchant_account_settings: "ตั้งค่าบัญชีร้านค้า",
    basic_settings: "ตั้งค่าทั่วไป",
    account_name: "ชื่อบัญชี",
    gozzy_fruit_shop: "Gozzy fruit shop",
    notification_settings: "ตั้งค่าแจ้งเตือน",
    channels: "ช่องทาง",
    events: "แจ้งเตือนเมื่อมีการ",
    client_link: "ลิงค์สมาชิก",
    overall_stats: "สถิติทั้งหมด",
    total_contacts: "จำนวนรายชื่อทั้งหมด",
    total_members: "จำนวนสมาชิกทั้งหมด",
    total_sales: "ยอดซื้อ",
    points_given: "จำนวน Point ทั้งหมดที่ให้ไป",
    points_redeemed: "จำนวน Point ทั้งหมดที่ถูกใช้แลก",
    vs_7_day_ago: "vs. 7 วันที่แล้ว",
    contacts: "รายชื่อ",
    contacts_title: "รายชื่อ",
    add_contact: "เพิ่มรายชื่อ",
    edit_type: "แก้ไข {{name}}",
    role: "สิทธิการใช้งาน",
    member_id: "เลขสมาชิก",
    points: " Point ",
    times: "times",
    sales_amount: "จำนวนยอดซื้อ",
    sales_amount_contact: "ยอดซื้อ",
    tier: "กลุ่มสมาชิก",
    basic_info: "ข้อมูลทั่วไป",
    date_of_birth: "วันเดือนปีเกิด",
    contact_add_date: "วันที่รายชื่อถูกเพิ่ม",
    contact_add_date_contact: "วันที่รายชื่อถูกสร้าง",
    member_registration_date: "วันที่สมัครสมาชิก",
    traffic_source: "Traffic source",
    last_activity_date: "วันที่ออนไลน์ล่าสุด",
    _points: " Point ",
    points_to_next_tier: "จำนวน Point ที่ต้องสะสมเพื่อเปลี่ยนเป็นกลุ่มสมาชิกถัดไป",
    membership: "กลุ่มสมาชิก",
    membership_tier: "กลุ่มสมาชิก",
    LINE_ID: "LINE ID",
    LINE_User_ID: "LINE User ID",
    LINE_chat_tags: "LINE chat tags",
    points_balance: " Point คงเหลือ",
    points_to_expire_in_1_month: " Point ที่จะหมดอายุภายใน 1 เดือน",
    last_points_collection_date: "วันที่สะสม Point ล่าสุด",
    last_points_redemption_date: "วันที่แลก Point ล่าสุด",
    coupon_balance: "คูปองคงเหลือ",
    coupon_used: "คูปองที่ใช้ไปแล้ว",
    coupons_to_expire_in_1_month: "คูปองที่จะหมดอายุภายใน 1 เดือน",
    last_coupon_collection_date: "วันที่เก็บ คูปองล่าสุด",
    last_coupon_used_date: "วันที่ใช้ คูปองล่าสุด",
    coupon: "คูปอง",
    sales: "ยอดซื้อ",
    sales_notification: "ยอดซื้อขายเกิดขึ้น",
    benefit_redemption: "รับสิทธิพิเศษ",
    sales_last_date: "วันที่ซื้อล่าสุด",
    form: "ฟอร์ม",
    field_tags: "ช่อง {{name}} - ติดแท็ก",
    points_edit: "แก้ไข Point ",
    change_points_to: "เปลี่ยน Point เป็น",
    current_points: " Point ปัจจุบัน ",
    edit_name: "แก้ไขขื่อ",
    edit_membership_tier: "แก้ไขกลุ่มสมาชิก",
    customer_is_on: "ลูกค้ากำลังใช้งาน",
    change_membership_tier: "เปลี่ยนกลุ่มสมาชิก",
    create_membership_tier: "สร้างกลุ่มสมาชิก",
    update_membership_tier: "อัปเดทกลุ่มสมาชิก",
    use_free_point: "ใช้ Free Points",
    preview: "ตัวอย่าง",
    good: "Gold",
    member_details: "รายละเอียดสมาชิก >",
    Expiration_month: "หมดอายุอีก 1 เดือน : 500",
    1820: "1,820",
    _5: "5",
    use_point: "ใช้ Point ",
    record: "ประวัติ",
    david: "David",
    collect_coupons: "เก็บคูปอง>",
    use_coupon: "ใช้คูปอง",
    coupon_code: "โค้ดคูปอง",
    manage_permissions: "จัดการสิทธิ์การใช้งาน",
    text_permission_sub_title: "เพิ่มสมาชิกใหม่และจัดการสิทธิ์การใช้งานที่นี่",
    permission_types: "ประเภทสิทธิ์การใช้งาน",
    add_member: "เพิ่มสมาชิก",
    choose_permission: "เลือกสิทธิ์การใช้งาน",
    edit_permissions_granted_to_this_account_member: "แก้ไขสิทธิ์การใช้งานสำหรับสมาชิกนี้.",
    delete_this_account_member: "ลบสมาชิก",
    grant_members_permissions_via_direct_URL_links: "ให้สิทธิ์การใช้งานผ่านลิงค์ URL",
    choose_permission_and_copy_link: "เลือกสิทธิ์การใช้งานและคัดลอกลิงค์",
    point_threshold: "เกณฑ์  Point สำหรับสมาชิกกลุ่มนี้นี้",
    choose_points_expiry: "เลือกระยะเวลาหมดอายุของ Points",
    membership_name: "ชื่อกลุ่มสมาชิก",
    baht_spent: "บาท",
    benefits: "สิทธิพิเศษ",
    loyalty_rules: "กฎการใช้งาน Loyalty",
    loyalty_settings: "ตั้งค่า Loyalty",
    set_point_expiry: "ตั้งค่า Point หมดอายุ",
    // loyalty rule expire feature
    point_expiry: "กำหนดระยะเวลาหมดอายุของ Points",
    point_expiry_label: "Point มีระยะเวลาหมดอายุ",
    fixed_frequency: "หมดอายุตามจำนวนเดือน",
    specific_month: "หมดอายุตามรอบเดือนที่กำหนด",
    tier_downgrade_rule: "กำหนดการลดกลุ่มสมาชิก",
    downgrade_tier_on_point_balance: "ลดกลุ่มสมาชิกตามยอด Points ที่มีอยู่",
    downgrade_calculation_frequency: "กำหนดช่วงเวลาที่จะลดกลุ่มสมาชิก",
    real_time: "ลดกลุ่มสมาชิกทันทีเมื่อยอด Point ต่ำกว่ากลุ่มสมาชิกปัจจุบัน",
    end_of_every_month: "ลดกลุ่มสมาชิกทุกสิ้นเดือน",
    end_of_specific_month: "ลดกลุ่มสมาชิกตามเดือนที่กำหนด",
    cycle: "Cycle",
    cycle_12_months: "รอบ 12 เดือน",
    cycle_24_months: "รอบ 24 เดือน",
    cycle_real_time: "ไม่มีรอบ",
    january: "มกราคม",
    february: "กุมภาพันธ์",
    march: "มีนาคม",
    april: "เมษายน",
    may: "พฤษภาคม",
    june: "มิถุนายน",
    july: "กรกฎาคม",
    august: "สิงหาคม",
    september: "กันยายน",
    october: "ตุลาคม",
    november: "พฤศจิกายน",
    december: "ธันวาคม",
    automatically_assign_starting_points_to_new_members: "ให้ Point สำหรับสมาชิกใหม่ทันที?",
    set_membership_tier: "ตั้งค่ากลุ่มสมาชิก",
    images: "รูปภาพ",
    max_images: "(ไม่เกิน {{name}} รูปภาพ)",
    image_ratio_support_only_1_1: "อัตราส่วนของรูปภาพเป็น 1: 1 (สีเหลี่ยม) เท่านั้น",
    the_proposed_size_is_640_x_640: "ควรจะมีขนาด 640 x 640 pixels",
    maximum_image_size_10_MB: "รูปภาพควรมีขนาดไม่เกิน 10 MB",
    benefit_name: "ชื่อสิทธิพิเศษ",
    broadcast: "Broadcast",
    create_coupons: "สร้างคูปอง",
    edit_coupons: "แก้ไขคูปอง",
    coupon_name: "ชื่อคูปอง",
    coupon_settings: "ตั้งค่าคูปอง",
    coupon_guideline: "แนวทางการใช้คูปอง",
    term_condition: "ข้อกำหนดการใช้งาน",
    add_coupon: "เพิ่มคูปอง",
    coupon_benefit: "สิทธิพิเศษของคูปอง",
    unit: "หน่วย",
    detail_coupons: "รายละเอียดคูปอง",
    display_in_coupon_collection: "แสดงคูปองนี้ในหน้าเมนูคูปอง",
    turn_on_for_users_to_see_coupon_in_their_collection_without_collect_through_link:
        "เปิดใช้งานเพื่อให้ผู้ใช้งานได้เห็นคูปองนี้ในหน้าเมนูคูปองโดยไม่ต้องเก็บคูปองล่วงหน้าผ่านลิงค์",
    update_segment: "เงื่อนไขของ Segment",
    segment_list: "รายชื่อ Segment",
    segment_name: "ชื่อ Segment",
    volume: "จำนวน",
    create_date: "วันที่ถูกสร้าง",
    // status: "สถานะ",
    add_segment: "เพิ่ม Segment",
    create_segment: "สร้าง Segment",
    include: "รวม",
    exclude: "ไม่รวม",
    choose_value: "เลือกค่าที่ต้องการ",
    is_equal_to_any_of: "เท่ากับค่า",
    contains_any_of: "มีส่วนของค่า",
    _is_known: "ทราบค่านี้",
    _is_unknown: "ไม่ทราบค่านี้",
    coupons_balance: "คูปองคงเหลือ",
    coupons_used: "คูปองที่ใช้ไปแล้ว",
    last_sales_date: "วันที่ซื้อล่าสุด",
    field_1_tag_values: "Tag ของฟอร์มช่อง 1 ",
    field_2_tag_values: "Tag ของฟอร์มช่อง 2",
    field_3_tag_values: "Tag ของฟอร์มช่อง 3",
    LINE_properties: "LINE properties",
    is_equal_to: "เท่ากับค่า",
    is_before: "ก่อนค่า",
    is_after: "หลังค่า",
    is_between: "ระหว่างค่า",
    is_greater_than: "มากกว่าค่า",
    is_less_than: "น้อยกว่าค่า",
    segments_settings: "ตั้งค่า Segments",
    webhook: "Webhook",
    segment_conditions: "เงื่อนไข Segment",
    export: "นำไฟล์ออก",
    export_csv: "นำไฟล์ csv ออก",
    activate: "เปิดใช้งาน",
    merge_settings: "ตั้งค่า Merge",
    select_merge_metrics: "เลือกเงื่อนไขการ merge",
    merge_method: "วิธี Merge",
    the_contact_will_be_merged_automatically_if_any_of_the_following_values_match_with_other_contacts:
        "Contact จะถูก merge อัตโนมัติถ้าเกิดว่าเงื่อนไขตรงไปถามที่ตั้งไว้",
    LINE_user_id: "LINE User ID",
    email_address: "อีเมล",
    auto_merge: "Merge อัตโนมัติ",
    confirm_merge_number_contacts: "ยืนยันการ Merge {{number}} contacts?",
    this_action_cannot_be_undone: "การกระทำนี้ไม่สามารถย้่อนคืนได้",
    merge_into: "Merge ไปยัง",
    merge_metrics: "เงื่อนไขการ Merge",
    match_date: "วันที่ Matched",
    merge_suggestions: "แนะนำการ Merge",
    bulk_merge: "Merge หลายบัญชี",
    no_contacts_are_selected: "ไม่มี Contacts ที่ถูกเลือก",
    total_click: "จำนวนคลิกทั้งหมด",
    point: "Point",
    quantity_limit: "จำนวนที่จำกัด",
    quantity_limit_user: "จำนวนที่จำกัดต่อผู้ใช้งาน",
    quantity_limit_user_required: "จำนวนที่จำกัดต่อผู้ใช้งาน",
    validity_period: "ระยะเวลาการใช้งาน",
    from: "จาก",
    till: "ถึง",
    max_8_images: "(ไม่เกิน 8 ภาพ)",
    VALIDITY_PERIOD: "ระยะเวลาการใช้งาน",
    STATUS: "สถานะ",
    link: "ลิงค์",
    description: "รายละเอียด",
    desc_link:
        "กรุณาคัดลอกลิงค์นี้และแชร์ไปยังช่องทางโซเชียล มีเดีย, อีเมล, หรือส่งให้ผู้ใช้งานโดยตรง",
    qr_code: "QR code",
    desc_qr_code:
        "ดาวน์โหลด QR Code นี้ เพื่อให้ผู้ใช้งานสามารถสแกนรูปภาพนี้เพื่อเก็บคูปองด้วย หรือ คัดลอก html tag เพื่อแชร์ไปยังช่องทางโซเชี่ยลมีเดีย, อีเมล, และอื่นๆ",
    _2_100_ตัวอักษร: "(2-100 ตัวอักษร)",
    _2_2000_ตัวอักษร: "(2-2000 ตัวอักษร)",
    download: "ดาวน์โหลด",
    choose_unit: "เลือกหน่วย",
    detail_rewards: "รายละเอียดของรางวัล",
    edit_rewards: "แก้ไขรางวัล",
    reward_settings: "ตั้งค่าของรางวัล",
    reward_code: "โค้ดรางวัล",
    birth_month: "เดือนเกิด",
    quantity_limit_per_user: "จำนวนจำกัดต่อผู้ใช้งาน",
    to: "ถึง",
    variants: "ตัวเลือก",
    descriptions: "รายละเอียด",
    max_character: "(2-2000 ตัวอักษร)",
    choose_membership: "เลือกกลุ่มสมาชิก",
    all_members: "สมาชิกทั้งหมด",
    choose_birth_month: "เลือกเดือนเกิด",
    create_reward: "สร้างของรางวัล",
    add_reward: "เพิ่มของรางวัล",
    add_more_tier: "เพิ่มกลุ่มสมาชิก",
    reward_detail: "รายละเอียดของรางวัล",
    quantity: "จำนวน",
    have_points_left: "คุณจะเหลือ {{number}} points",
    select_type: "เลือกประเภท",
    quantity_available: "รายการนี้มี ({{number}} จำนวนทั้งหมดที่เหลืออยู่",
    add_variant: "เพิ่มตัวเลือก",
    variant_name: "ชื่อตัวเลือก",
    reward_name: "ชื่อของรางวัล",
    benefit_settings: "ตั้งค่าสิทธิพิเศษ",
    create_benefit: "สร้างสิทธิพิเศษ",
    detail_benefit: "รายละเอียดสิทธิพิเศษ",
    edit_benefit: "แก้ไขผลประโยชน์",
    choose_variants: "เลือกตัวเลือก",
    benefit_code: "โค้ดสิทธิพิเศษ",
    add_benefit: "เพิ่มสิทธิพิเศษ",
    create_free_points: "สร้าง Free Points ",
    edit_free_points: "แก้ไข Free  Points ",
    free_points_settings: "ตั้งค่า Free points",
    free_point_name: "ชื่อ Free points",
    free_points: "Free points",
    free_point: "Free points",
    add_free_points: "เพิ่ม Free points",
    point_given: "Points ที่ได้รับ",
    detail_free_points: "รายละเอียด Free points",
    share_traffic_source: "แชร์ Traffic source",
    traffic_source_name: "ชื่อ Traffic source",
    traffic_source_url: "Traffic source url",
    enter_URL: "ใส่ URL",
    add_source: "เพิ่ม Source",
    LINE_Messaging: "LINE Messaging",
    the_settings_for_connecting_your_LINE_account: "การตั้งค่าสำหรับเชื่อมต่อ LINE account",
    channel_access_token_long_live: "Channel access token (long-lived)",
    LINE_login_API: "LINE login API",
    channel_ID: "Channel ID",
    channel_Secret: "Channel Secret",
    callback_URL: "Callback URL",
    LINE_login_Sample_page: "LINE login Sample page",
    LIFF_Endpoint_URL: "LIFF Endpoint URL",
    LIFF_URL: "LIFF URL",
    Webhook_URL: "Webhook URL",
    get_email_addresses_of_LINE_friends: "เข้าถึงอีเมลของเพื่อนบน LINE",
    field_name_question: "ชื่อช่อง / คำถาม",
    property_name: "ชื่อ Property",
    choose_selection_type: "กรุณาเลือกประเภท",
    single: "มีคำตอบเดียว",
    multiple: "มีตัวเลือกคำตอบ",
    option_name: "ชื่อตัวเลือก",
    tag: "Tag",
    edit_form: "แก้ไข form",
    created_fields: "สร้างช่อง / คำถาม",
    customer_sign_up_method: "ช่องทางสำหรับการสมัครสมาชิก",
    LINEOA_chat: "Line",
    facebook: "Facebook",
    google: "Google",
    instagram: "Instagram",
    // email_tel: "อีเมล/เบอร์โทร",
    telephone: "โทรศัพท์",
    welcome_screen_images: "รูปบนหน้า Welcome ",
    welcome_to_loyalty: "Welcome to Loyalty Program !",
    turn_on_form: "เปิดใช้งานฟอร์ม",
    create_form_field: "สร้างช่อง / คำถาม",
    all_notifications: "การแจ้งเตือนทั้งหมด",
    show_resolved: "แสดง Resolved",
    show_used: "ใช้แล้ว",
    at_least_one_select_option: "จำเป็นต้องมีอย่างน้อยหนึ่งตัวเลือก",
    this_function_is_for_xxx_plan_users_only: "ฟีเจอร์นี้สำหรับผู้ใช้งาน {{name}} เท่านั้น",
    do_you_want_to_upgrade_your_plan_to_xxx: "คุณต้องการที่จะอัปเกรด Plan ของคุณหรือไม่?",
    contacts_list: "Contacts list",
    segment: "Segment",
    loyalty: "Loyalty",
    rewards: "ของรางวัล",
    user_signup_settings: "ตั้งค่าวิธีสมัครสมาชิก",
    account_settings: "ตั้งค่าบัญชี",
    dob: "วันเดือนปีเกิด",
    contactAddDate: "วันที่รายชื่อถูกเพิ่ม",
    memberRegistrationDate: "วันที่สมัครสมาชิก",
    trafficSource: "Traffic source",
    lastActivityDate: "วันที่ออนไลน์ล่าสุด",
    membershipTier: "กลุ่มสมาชิก",
    pointsToNextTier: "จำนวน Point ที่ต้องสะสมเพื่อเปลี่ยนเป็นกลุ่มสมาชิกถัดไป",
    LINEId: "LINE ID",
    LINEUserId: "LINE User ID",
    LINEChatTags: "LINE chat tags",
    pointsBalance: " Point คงเหลือ",
    pointsToExpireIn1Month: "Points ที่จะหมดอายุภายใน 1 เดือน",
    lastPointsCollectionDate: "วันที่สะสม Point ล่าสุด",
    lastPointsRedemptionDate: "วันที่แลกใช้ Point ล่าสุด",
    couponsBalance: "คูปองคงเหลือ",
    couponsUsed: "คูปองที่ใช้ไปแล้ว",
    couponsToExpireIn1Month: "คูปองที่จะหมดอายุภายใน 1 เดือน",
    lastCouponCollectionDate: "วันที่เก็บคูปองล่าสุด",
    lastCouponUsedDate: "วันที่ใช้คูปองล่าสุด",
    salesAmount: "ยอดซื้อทั้งหมด",
    lastSalesDate: "วันที่ซื้อล่าสุด",
    field1TagValues: "Tag ของฟอร์มช่อง 1",
    field2TagValues: "Tag ของฟอร์มช่อง 2",
    field3TagValues: "Tag ของฟอร์มช่อง 3",
    empty_text: "---",
    _10_months: "10 เดือน",
    _11_months: "11 เดือน",
    _12_months: "12 เดือน",
    _2_100_characters: "(1-50 ตัวอักษร)",
    _2_2000_characters: "(2-2000 ตัวอักษร)",
    _2_months: "2 เดือน",
    _3_months: "3 เดือน",
    _4_months: "4 เดือน",
    _5_months: "5 เดือน",
    _6_months: "6 เดือน",
    _7_months: "7 เดือน",
    _8_months: "8 เดือน",
    _9_months: "9 เดือน",
    _or: "_or",
    admin_pane: "Admin Panel",
    collect_benefit: "รับสิทธิพิเศษ",
    collect_free_point: "รับ Free Points",
    constants_columns: "Constants columns: name, points, date of birth, phone number, email ... ",
    coupon_add_or_delete: "เพิ่ม หรือ ลบคูปอง",
    coupon_collection: "เก็บคูปอง",
    coupon_usage: "ใช้คูปอง",
    csv_excel_import: "นำเข้าไฟล์ CSV / Excel",
    download_file_template: "ดาวน์โหลด File Template",
    export_a_csv_file_or_excel: "ดาวน์โหลดไฟล์ CSV หรือ Excel",
    export_as_csv_file_or_excel: "ดาวน์โหลดไฟล์ CSV หรือ Excel",
    export_customer_list: "ดาวน์โหลดรายชื่อลูกค้า",
    file_import_contact_should_format: "ไฟล์ควรอยู่ในรูปแบบ:",
    file_list_contact: "รายชื่อ-สมาชิก",
    history: "ประวัติ",
    import_export: "นำเข้า/ดาวน์โหลด",
    import_file: "นำเข้าไฟล์",
    import_file_template: "นำเข้า-file-template",
    line_chat: "Line Chat",
    max_size_10MB: "ไฟล์ควรมีขนาดน้อยกว่า 10MB",
    membership_upgrade_or_downgrade: "อัปเกรด หรือ ดาวน์เกรดกลุ่มสมาชิก",
    merchant_can_not_use_this_channel_notify: "บัญชีของคุณไม่สามารถใช้ช่องทางการแจ้งเตือนนี้ได้",
    new_user_sign_up: "มีผู้ใช้งานใหม่สมัครสมาชิก",
    points_collection: "สะสม Point ",
    redeem_benefit: "รับสิทธิพิเศษ",
    redeem_reward: "แลกรับของรางวัล",
    refresh_data: "รีเฟรชข้อมูล",
    refresh_loading: "กำลังรีเฟรช",
    reward_add_or_delete: " เพิ่ม หรือ ลบของรางวัล",
    reward_coupons: "ของรางวัล / คูปอง",
    redemptions: "รายการแลก",
    reward_redemption: "แลกรับของรางวัล",
    select_options: "เลือกตัวเลือก",
    this_function_is_not_for_xxx_plan_users:
        "ฟีเจอร์นี้ไม่ได้เปิดใช้งานสำหรับผู้ใช้งาน Plan {{name}} ",
    user_id: "เลขรหัสสมาชิก",
    support_message:
        "ก่อนที่ได้ใช้บริการจากทาง Rocket ยอดขายของเราไม่สู้ดีซักเท่าไหร่ แต่หลังจากที่เริ่มใช้บริการแล้วลูกค้าก็เริ่มมีปฎิสัมพันธ์กับเรามากขึ้นอย่างที่ไม่เคยเป็นมาก่อน และผมก็ค่อนข้างมั่นใจว่ายอดนี้จะต้องเติบโตมากยิ่งขึ้นเรื่อยๆ",
    director_name: "ชนุตม์ มัชฌัณติกะ (Marketing Director)",
    select_new_plan: "เลือกแพลนใหม่",
    do_you_wish_use_feature:
        "คุณสนใจที่จะใช้งานฟีเจอร์นี้? กรุณาเลือกแพลนใหม่ถ้าต้องการใช้งานฟีเจอร์นี้",
    feature_not_your_content: "ฟีเจอร์นี้ไม่ได้อยู่ในแพลนการใช้งานของคุณ",
    add_images: "+ เพิ่มรูปภาพ",
    id: "รหัสสมาชิก :",
    invite: "Invite",
    delete_user: "Delete User",
    select_account: "Select Account",
    user_guide: "User Guide",
    limit_amount: "*Limit {{value}}",
    total_transication: "Total Transication",

    // create new account feature
    new_account: {
        question_mark: " ",
        additional_q_mark: " ?",
        company_name_question: "บริษัทของคุณชื่ออะไร",
        business_type_question: "ประเภทธุรกิจ",
        business_industry: "ของคุณ",
        telephone_number: "เบอร์โทรศัพท",
        number: "ของคุณ",
        free_setup: "ให้ Account Executive ของเราได้ช่วยคุณตั้งค่า Loyalty Program ฟรี!",
        number_of_people: "มีพนักงานจำนวนกี่คนในธุรกิจของคุณ",
        level_experience: "ประสบการณ์การใช้ระบบสมาชิกที่ผ่านมา",
        connect_rocket_loyalty: "เชื่อมต่อธุรกิจของคุณ กับ Rocket Loyalty",
        rocket_loyalty_des:
            "กรอกข้อมูลส่วนตัวของคุณ เพื่อให้เราได้ออกแบบ Engagement Plan ที่เหมาะสมกับ Loyalty Program ของคุณ",
        question: "ชื่อธุรกิจ",
        program: "ของคุณ",
        do_later: "ฉันจะตั้งค่าเองภายหลัง",
        reward_list: "รายชื่อของราวัล",
        manage_reward: "สร้างและจัดการของรางวัล",
        manage_reward_des:
            "You can start givingของรางวัล to your loytaly and dedicated customers by coming up with a reward program for them",
        add_more: "เพิ่มของรางวัล",
        add_more_tier: "เพิ่มกลุ่มสมาชิก",
        loyal_title: "กฎการใช้งาน Loyalty",
        loytal_program: "แบ่งกลุ่มสมาชิกให้ลูกค้าคนพิเศษ",
        loytal_program_des:
            "แต่ละกลุ่มสมาชิกสามารถถูกเลือกให้ได้รับของรางวัล หรือ สิทธิพิเศษที่แตกต่างกัน ซึ่งจะกระตุ้นให้แต่ละกลุ่มสมาชิกสะสมคะแนนมากขึ้น เพื่อจะได้รับสิทธิพิเศษที่เหนือชั้นกว่าเดิม",
        loyal_name: "มอบ Points ให้กับสมาชิก",
        loyal_name_concat: "ทันทีหลังจากสมัครสมาชิก",
        tier_name: "คุณต้องการที่จะแบ่งกลุ่มสมาชิก",
        call_tier: "ของธุรกิจคุณอย่างไรบ้าง",
        tier_rule: "ชื่อกลุ่มสมาชิก",
        full_mode: "ไปยัง Full Mode",
        signup_method_title: "ลูกค้าของคุณจะสามารถสมัครสมาชิกได้ผ่านช่องทาง?",
        signup_method: "จัดการช่องทางที่ลูกค้าของคุณ สามารถเลือกใช้ในการสมัครสมาชิก",
        signup_des:
            "ไม่ใช่แค่คุณเท่านั้นที่สามารถเข้าถึงลูกค้าของคุณได้ ลูกค้าของคุณเองก็สามารถเข้าถึงธุรกิจคุณได้ผ่านช่องทางต่างๆเช่นกัน",
        preparing: "บัญชีของคุณกำลังถูกตั้งค่ากรุณารอซักครู่...",
        congratulations: "ยินดีด้วย,",
        create_program: "คุณได้สร้าง Loyalty Program ของธุรกิจคุณแล้ว!",
        go_to_online: "เชื่อมต่อ Loyalty Program ของคุณกับ LINE OA คลิกเลย!",
        line_massage: "Line Messeging",
        line_account_handbook: "LINE Official Account Handbook",
        line_account_sub_title: "คู่มือการใช้งาน",
        line_qa: "LINE QA",
        business_name: "Business industry",
        threshold: "Threshold",
        restaurant_cafe: "รัานอาหาร & คาเฟ่",
        retails: "ค้าปลีก",
        hotel_motel: "โรงแรม & ที่พัก",
        spa_massage: "สปา & ร้านนวด",
        salon_beauty: "ร้านทำผม & เสริมสวย",
        clinics: "คลีนิค",
        others: "อื่นๆ",
        what_is_your: "อีเมลธุรกิจ",
        work_email: "ของคุณ",
        full_name: "ชื่อจริง",
        loyalty_program: "Loyalty program ",
        loyalty_program_setting: "ของคุณมีชื่อว่าอะไร",
        one_50: "1-50 คน",
        fivety_200: "50-200 คน",
        more_than_200: "มากกว่า 200 คน",
        new_to_loyalty_hub: "ไม่เคยใช้เลย",
        CRM_OR_Have_used_loylaty_hub: "เคยใช้ CRM หรือ Loyalty Hub มาก่อน",
        point_threshold: "เกณฑ์ Point",
        need_help: "นัดผู้เชี่ยวชาญสอนใช้ได้เลย!",
        download: "นัดรับคำปรึกษา",
        book_appoinment: "นัดรับคำปรึกษา",
        fill_information: "กรอกข้อมูลสำหรับร้านค้า",
        color_theme: "ธีมสีร้านค้า",
        terms: "ฉันยินยออมให้ข้อมูลส่วนตัวเหล่านี้ ตามนโยบายความเป็นส่วนตัวของทางบริษัท อ่านเพิ่มเติม",
    },
    language: {
        en: "ภาษาอังกฤษ",
        th: "ภาษาไทย",
        lang_en: "อังกฤษ",
        lang_th: "ไทย",
    },
    select_your_plan: "เลือกแพลน",
    choose_the_plan_right_for_you: "เลือกแพลนที่ใช่สำหรับธุรกิจของคุณ",
    top_free_column_title: "ฟรี",
    members_columns_title: "สมาชิก",
    top_free_column_members: "ฟรี 100 คน",
    admin_columns_title: "ผู้ดูแลระบบ",
    top_free_column_admin: "1 คน",
    transaction_columns_title: "รายการแลกของรางวัล",
    top_free_column_transaction: "100 รายการ",
    memtires_columns_title: "กลุ่มสมาชิก",
    top_free_column_memtiers: "1 กลุ่ม",
    rewards_columns_title: "ของรางวัล",
    top_free_column_rewards: "10 รางวัล",
    free_choose_plan_button_text: "เลือกแพลนนี้",
    get_a_demo_button_text: "เลือกแพลนนี้",
    top_premium_column_title: "พรีเมียม",
    months_columns_title: "/เดือน",
    premium_plan_price: "990 ฿",
    top_premium_column_members: "ฟรี 1000 คน",
    top_premium_column_members_sub: "ตั้งแต่สมาชิกคนที่ 1,001 เป็นต้น 1 บาท ต่อ สมาชิก 1 คน",
    top_premium_column_admin: "5 คน",
    top_premium_column_transaction: "1,000 รายการ",
    top_premium_column_transaction_sub: "ตั้งแต่รายการที่ 1,001 เป็นต้นไป 0.10 บาท ต่อ รายการ",
    top_premium_column_memtiers: "3 กลุ่ม",
    top_premium_column_rewards: "ไม่จำกัดจำนวน",
    recommened_text: "ดีลักซ์",
    popular_text: "ยอดฮิต",
    top_deluxe_column_title: "ดีลักซ์",
    deluxe_plan_price: "2,490 ฿",
    top_deluxe_column_members: "ไม่จำกัดจำนวน",
    top_deluxe_column_admin: "10 คน",
    top_deluxe_column_transaction: "3,000 รายการ",
    top_deluxe_column_transaction_sub: "ตั้งแต่รายการที่ 3,001 เป็นต้นไป 0.10 บาท ต่อ รายการ",
    top_deluxe_column_memtires: "5 กลุ่ม",
    top_deluxe_column_rewards: "ไม่จำกัดจำนวน",
    top_coporate_column_title: "องค์กร",
    ask_for_quote: "ขอใบเสนอราคา",
    top_coporate_column_members: "ไม่จำกัดจำนวน",
    top_coporate_column_admin: "ไม่จำกัดจำนวน",
    top_coporate_column_transaction: "Custom",
    top_coporate_column_memtires: "ไม่จำกัดจำนวน",
    top_coporate_column_rewards: "ไม่จำกัดจำนวน",
    compare_features: "ตารางเปรียบเทียบฟีเจอร์",
    features_title: "ฟีเจอร์",
    bot_column_benefits_title: "สร้างสิทธิเศษ",
    bot_column_coupons_title: "สร้างคูปอง",
    bot_column_freepoints_title: "แจก Free points",
    bot_column_multiUser_title: "สมาชิกสามารถสมัครได้หลายช่องทาง(Line, Google, Facebook, SMS)",
    bot_column_signupCustom_title: "ปรับแต่งฟอร์มสมาชิก",
    bot_column_pAssistance_title: "ผู้ช่วยส่วนตัว",
    bot_column_graphicMP_title: "ถ่ายภาพ และทำภาพกราฟฟิก",
    bot_column_ePlanS_title: "ออกแบบ Engagemnent Plan",
    bot_column_customFeatures_title: "ออกแบบฟีเจอร์ & ดีไซน์ใหม่",
    bot_column_coporate_custom_pricing: "Custom pricing",
    empowering_innovative_brands: "ลูกค้าที่ใช้งาน Loyalty Program ของเรา",
    paid_plan_popup_title_first_row: "พนักงานขาย",
    paid_plan_popup_title_second_row: "จะติดต่อคุณกลับไปภายใน 2 ชั่วโมง!",
    paid_plan_popup_body_first_row: "ระหว่างนี้ เริ่มสร้าง Loyalty Program",
    paid_plan_popup_body_second_row: "ของคุณภายในคลิกไนไม่กี่คลิก",
    start_now: "เริ่มต้นใช้งานเลย",
    free_plan_popup_title: "เริ่มสร้าง Loyalty Program ของคุณเลย!",
    free_plan_popup_body: "สร้างระบบสมาชิกที่สมบูรณ์แบบภายในไม่กี่คลิก",
    product_loyalty: "ยินดีต้อนรับสู่หน้าแอดมิน จัดการระบบสมาชิก/สะสมแต้มของคุณ",
    product_loyalty_text: "คุณสามารถใช้ระบบแอดมินนี้เพื่อ :",
    product_loyalty_text_1: "-ตั้งกฎสมาชิก / คะแนน",
    product_loyalty_text_2: "-สร้างของรางวัล คูปอง และสิทธิพิเศษสมาชิก",
    product_loyalty_text_3: "-เลือกธีมร้าน",
    product_loyalty_text_4: "-บริหารจัดการสมาชิก",
    product_loyalty_text_5: "-ยืนยันการแลกของรางวัลและคูปอง",
    product_loyalty_text_6: "และตั้งค่าอื่นๆอีกมากมาย",
    product_point: "นำระบบสมาชิกคุณไปใช้จริงกับลูกค้าเลย!",
    product_point_1: "คัดลอกลิงค์สมาชิกข้างล่างและนำไปวางในช่องทางต่างๆได้เลย :",
    product_point_2: "-ส่งลิ้งค์ผ่านแชท ",
    product_point_3: "-เชื่อมลิงก์กับ LINEOA Rich Menu",
    product_point_4: "-แปะลิงค์บน เพจ Facebook, IG หรือ Tiktok",
    product_point_5: "ลูกค้าก็สามารถเข้าถึงระบบสมาชิกของคุณผ่านโทรศัพท์มือถืออย่างง่ายดาย",
    m_popup_title: "ขอบคุณสำหรับการลงทะเบียน",
    m_popup_concat: "พนักงานขายจะติดต่อคุณกลับไปภายใน 2 ชั่วโมง!",
    m_popup_des: "เพื่อแนะนำการใช้งาน Loyalty เบื้องต้น",
    m_popup_des_2: "หรือ คุณสามารถตั้งค่าต่อด้วยตนเองผ่าน Desktop",
    back_m_desktop: "รับลิงค์เข้าใช้งานผ่าน Desktop",
    login_link_has_been_sent: "ลิงก์ได้ส่งเข้าไปยังอีเมล",
    check_mail_box: "กรุณาตรวจสอบกล่องข้อความ",
    open_link_via_desktop: "เพื่อง่ายต่อการใช้งานกรุณาเข้าลิงก์ด้วยหน้าจอขนาด Desktop",
    back_to_main_page: "กลับสู่หน้าหลัก",
    add_rocket_line_account: "Add Line Account",
    signup: {
        what_does_your: "เปิด Loyalty Program ฟรี!",
        free_account_include: "ฟังก์ชั่นของ account ฟรีมีอะไรบ้าง?",
        for_an_unlimited_time: "ไม่จำกัดระยะเวลาใช้งาน ",
        you_get_access:
            "ไม่ต้องเสียค่าใช้จ่าย แถมยังได้ใช้งานฟีเจอร์เหนือระดับแบบบริษัทยักษ์ใหญ่ในประเทศไทย เสียค่าใช้จ่ายก็ต่อเมื่อมีสมาชิกครบ 100 คนขึ้นไป",
        access_features_like: "ฟีเจอร์ที่คุณสามารถใช้งานได้ที่ Rocket Loyalty :",
        manage_member: "จัดการ",
        points_and_redemptions: "คะแนนสมาชิก และ ตรวจสอบการแลกของรางวัล",
        create: "สร้าง",
        member_rewards_benefits: "ของรางวัล, สิทธิพิเศษ, ",
        and: "และ",
        coupons: "คูปอง",
        membership_tier: "กำหนดกลุ่มสมาชิก",
        customize: "ออกแบบและ",
        vip_vvip: "ที่เหมาะกับธุรกิจคุณ เช่น กลุ่ม VIP หรือ VVIP",
        set_loyalty_progam:
            "กำหนดเกณฑ์การเป็นสมาชิก เช่น กำหนดค่าใช้จ่ายขั้นต่ำในการเลื่อนขั้นกลุ่มสมาชิก",
        customize_loyalty: "ออกแบบรูปลักษณ์",
        loyalty_program: " Loyalty Program ให้ตรงตามธุรกิจคุณ",
        connect_user_signup: "เชื่อมต่อการสมัครสมาชิก",
        line_fb_google:
            "กับหลากหลายช่องทาง ไม่ว่าจะเป็น LINE, Facebook, Google, หรือ เบอร์โทรศัพท์",
        full_free_support_from_dedicated: "Account Manager",
        account_manager: " ช่วยดูแลระบบให้แบบเต็มรูปแบบ ไม่ต้องเสียค่าใช้จ่ายเพิ่ม",
        crate_your_loyalty_and_membership_program:
            "สร้างระบบสมาชิก และ สะสมคะแนนได้ภายในไม่กี่คลิก สมัครเลย!",
        retain_and_delight_your_customers:
            "กระตุ้นยอดขายด้วย การมัดใจลูกค้าไม่ว่าจะเป็นลูกค้าใหม่หรือลูกค้าประจำ Rocket Loyalty Hub ทำให้คุณได้! ",
        read_accepted: "I’ve read and accepted",
        terms_of_service: "Terms of Service ",
        privacy_policy: " Privacy Policy.",
    },
    member_points: {
        edit_points_member: "แก้ไข Points ของสมาชิก",
        search_customer: "ค้นหาสมาชิก",
        activity_edit_points: "ประวัติการใช้งาน Points",
        search_placeholder: "ค้นหาโดยเบอร์โทร, ชื่อผู้ใช้งาน, รหัสสมาชิก...",
        qr_code: "QR Code",
        user: "ผู้ใช้งาน",
        activity: "รายการ",
        time: "เวลา",
        points: "Points",
        no_activity: "ไม่มีประวัติการทำรายการ",
        search_qrcode: "พิมพ์ค้นหาลูกค้า หรือสแกน QR-Code เพื่อเลือกลูกค้าที่ต้องการปรับพอทย์",
        scan_QR_code: "Scan QR Code",
        scan_qr_code_des: "เพื่อเพิ่ม ลด หรือเปลี่ยนคะแนนของสมาชิก",
        ok: "ปิด",
        edit_points: "แก้ไข Points",
        add_points: "เพิ่ม Points",
        use_points: "ใช้ Points",
        sales: "ยอดซื้อ",
        baht: "บาท",
        or: "Or",
        point: "Point",
        point_giving_description:
            "อัตราแปลงพ้อยท์เท่ากับ 50 : 1 เช่นซื้อครบจำนวน 5,000 : 100 พอยท์ ",
        notation: "หมายเหตุ",
        notes: "โน้ตเพิ่มเติม",
        specail_birthday: "เช่น ส่วนลดวันเกิด",
        attach_images: "แนบรูปภาพ",
        attach_images_desc: "ตัวอย่าง ใบเสร็จรับเงิน รูปภาพคูปอง หรือ หลักฐานการรับแต้ม",
        add_images: "+ เพิ่มรูปภาพ",
        send_points: "ส่ง Points",
        id: "รหัสสมาชิก :",
        confirm_points: "Confirm points",
        name: "Name",
        confirm: "Confirm",
        pls_fill_form: "need to fill the form",
        send_point_successful: "Send points successful !",
        history_points: "History points",
        activity_time: "Activity Time",
        plus: "+ {{point}}",
        minute: "- {{point}}",
        pts: "{{point}} Pts",
    },
    redeem: {
        accept_redeem: "ยืนยันการแลก",
        search_redem: "ค้นหาของรางวัล / คูปอง / สิทธิพิเศษ",
        search_placeholder: "ค้นหาด้วยโค้ดของรางวัล, โค้ดคูปอง, และโค้ดสิทธิพิเศษ....",
        details: "รายละเอียด",
        code: "โค้ด :",
        coupon_usage: "Coupon usage",
        activity: "รายการแลก",
        redeem_at: "แลกเมื่อ",
        status: "สถานะ",
        period: "ระยะเวลาที่แลกได้",
        reject: "ปฏิเสธการแลก",
        redeem: "ยืนยันการแลก",
        confirm_redeem: "ยืนยันการแลก",
        reject_redeem: "ปฏิเสธการแลก",
        redeem_successful: "ยืนยันการแลกสำเร็จ!",
        reject_successful: "ปฏิเสธการแลกสำเร็จ!",
        confirm_accept_redeem: "ยืนยันการแลก",
        confirm_reject_redeem: "ปฏิเสธการแลก",
        points: "Points :",
        tel: "เบอร์โทร",
        email: "อีเมล",
        birthday: "วันเกิด",
    },
    manage_account: {
        accounts: "บัญชีของคุณ",
        select_account: "โปรดเลือกบัญชีที่คุณต้องการใช้งาน",
        account_name: "ชื่อบัญชี",
        our_customer: "จำนวนลูกค้า",
        add_account: "เพิ่มบัญชี",
        our_customer_m: "จำนวนลูกค้า :",
        role: "สิทธิการใช้งาน",
        plan: "Plan การใช้งาน",
        add_member: "สร้างบัญชีใหม่",
        enter_company_info: "กรอกข้อมูลบริษัท",
        confirm_info: "ยืนยันข้อมูล",
        complete: "เสร็จสิ้น",
        account_mame: "ชื่อบัญชี",
        account_owner: "เจ้าของบัญชี",
        appear_on_storefront: "ชื่อนี้จะเป็นชื่อร้านของคุณ",
        owner_account: "Owner account",
        digitalrocket_gmail: "Digitalrocket@gmail.com",
        logout: "Logout",
        business_info: "ข้อมูลธุรกิจของคุณ",
        company_name: "ชื่อบริษัทของคุณ",
        full_name: "ชื่อของคุณ",
        types_business_industries: "ประเภทธุรกิจ",
        david_dondonki: "David Dondonki",
        contact_business_account: "Contact for business account",
        telephone_number: "เบอร์โทรศัพท์",
        placeholder_number: "02-222-2222",
        how_many_people: "บริษัทคุณมีพนักงานจำนวนเท่าไหร่?",
        create_business_account: "Create business account",
        back: "ย้อนกลับ",
        next: "ถัดไป",
        business_industries: "ประเภทธุรกิจ",
        fullname_contact: "ชื่อผู้ติดต่อ",
        people_your_company: "บริษัทคุณมีพนักงานจำนวนเท่าไหร่?",
        success_create_account: "คุณได้สร้างบัญชีใหม่สำเร็จแล้ว!",
        name_account: "Name Account",
        congratulations: "ยินดีด้วย!",
        your_program: "คุณได้สร้างบัญชีใหม่ของคุณสำเร็จ!",

        back_main_page: "กลับสู่หน้าเลือกบัญชี",
        faq_note:
            "FAQ : Note Verify Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,",
    },
    dashboard: {
        dashboard: "Dashboard",
        deshboard_samll: "เมนูทางลัด",
        welcome_to_loyalty: "ยินดีต้อนรับสู่เทศบาลเมืองสนั่นรักษ์",
    },
    edit_profile: {
        personal_information: "ข้อมูลส่วนตัว",
        fullname: "ชื่อ",
        email: "อีเมล",
        password: "รหัสผ่าน",
        contact_phone: "เบอร์โทร",
        change_password: "เปลี่ยนรหัสผ่าน",
        change: "Change",
    },
    create_merchant: {
        shop_name: "ชื่อร้านของคุณ",
        business_industries: "ประเภทธุรกิจของคุณ",
        fullname: "ชื่อจริงของคุณ",
        your_work_email: "อีเมล",
        your_telephone_number: "เบอร์โทรศัพท์ของคุณ",
        how_many_people: "ธุรกิจของคุณมีพนักงานทั้งหมดกี่คน",
        theme_shop: "ธีมสีร้านค้า",
        fill_information: "กรอกข้อมูลสำหรับร้านค้า",
        color_theme: "ธีมสีร้านค้า",
        terms: "ฉันยินยออมให้ข้อมูลส่วนตัวเหล่านี้ ตามนโยบายความเป็นส่วนตัวของทางบริษัท",
        read_more: "อ่านเพิ่มเติม",
        screen_client: "ตัวอย่างของหน้าสมาชิก",
        client: "Client",
    },
    gain_friend: {
        get_your_member: "เพิ่มสมาชิก",
        advertise_online: "ผ่านช่องทางออนไลน์",
        create_URL: "สร้างลิงค์สมาชิก",
        share_your_url:
            "นำลิงค์สมาชิกไปวางในช่องทางออนไลน์ลูกค้าก็สามารถเข้าถึงระบบสมาชิกผ่านโทรศัพท์มือถืออย่างง่ายดาย",
        create_QR_code: "สร้าง QR code",
        create_QR_code_post: "นำ QR code ไปวางหน้าร้าน ให้ลูกค้าสแกนเเพื่อสมัครสมาชิก",
        create_rich_menu: "สร้าง LINE Rich Menu",
        create_rich_menu_post: "สร้างกราฟฟิค Rich Menu เพื่อนำไปใช้ใน LINEOA",
        advertise_your_store: "ผ่านหน้าร้านออฟไลน์",
        store_poster: "โปสเตอร์หน้าร้าน",
        download_print: "ดาวน์โหลดโปสเตอร์ ปริ้น และนำไปวางหน้าร้าน ให้ลูกค้าสแกนเเพื่อสมัครสมาชิก",
        create_url_link: "สร้างลิงค์สมาชิก",
        copy_URL: "คัดลอก URL",
        share_your_URL:
            "นำลิงค์สมาชิกไปวางในช่องทางออนไลน์ลูกค้าก็สามารถเข้าถึงระบบสมาชิกผ่านโทรศัพท์มือถืออย่างง่ายดาย",
        how_to_use_your_URL: "วิธีใช้ลิงค์",
        share_throught_social: "แปะลิงค์สมาชิกบน LINE หรือเพจ Facebook / IG",
        custom_chat: "ส่งลิงค์สมาชิกให้ลูกค้าผ่านแชท",
        liked: "Liked",
        share: "Share",
        // create_QR_code:"Create a QR code", // สร้าง QR code
        QR_code: "QR Code", //QR Code
        qr_poster: "โปสเตอร์พร้อม QR code",
        users_scan_image:
            "ดาวน์โหลดคิวอาร์โค้ด หรือโปสเตอร์ด้านล่าง และนำไปวางหน้าร้าน ลูกค้าสามารถสมัครสมาชิกผ่านการสแกนคิวอาร์โค้ด",
        //ลูกค้าสามารถแสกน QR Code เพื่อเป็นสมาชิกกับทางร้าน
        download: "ดาวน์โหลด",
        customize_graphic: "รูปภาพ QR Code",
        started_official_account: "official account",
        earn_store_points: "Earn store points on ",
        get_exclusive_coupons: "Get exclusive coupons on",
        get_useful_tips: "Get useful tips on The Future is now",
        download_graphic: "ดาวน์โหลดรูปภาพ",
        how_to_use_your_code: "วิธีใช้ QR code หรือโปสเตอร์",
        how_to_use_qr_code_title: "สมัครสมัครได้ที่นี่",
        how_to_use_short_des: "รับคูปองส่วนลดต่างๆ ตามเงื่อนไขของทางร้านเพียงเป็นสมาชิก",
        post_social_media: "โพสต์บนโซเชียลมีเดีย",
        display_in_your_store: "ปรินท์และนำไปวางไว้หน้าร้าน",
        create_poster: "สร้างโปสเตอร์",
        customize_poster: "โปสเตอร์ของร้านคุณ",
        download_print_members: "ดาวน์โหลดและนำโปสเตอร์นี้ไปปรินท์เพื่อนำไปใช้ที่หน้าร้าน",
        download_poster: "ดาวน์โหลดโปสเตอร์",
        post_social_media_banner: "นำไปโพสต์กับแบนเนอร์บน Social Media",
        // create_rich_menu:"Create Rich Menu",
        customize_rich_menu: "ดาวน์โหลดกราฟิกสำหรับ Rich Menu",
        select_size_rich_menu: "Select Size Rich Menu for Download",
        download_rich_menu: "Download Rich Menu",
        how_to_use_your_rich_menu: "วิธีใช้รูป Rich Menu",
        upload_rich_menu_image: "Upload Rich Menu image",
        line_QA_account: "on LINE OA account",
        rich_menu_will_displyed_line: "Rich Menu will be displayed on your LINE OA account",
        become_member: "สมัครสมาชิก ",
        earn_point: "รับคะแนนกับ ",
        get_discount: "รับคูปองส่วนลด ฟรี! ",
        recive_exclusive: "รับสิทธิพิเศษจากร้าน ",
        become_member_now: "Become a member now",
        subscribe: "สมัครสมาชิก",
        register: "Register",
        contact_us: "ติดต่อเรา",
        paste_graphic_menu: "อัพโหลดรูปภาพ Rich Menu บน LINE OA",
        rich_menu_account: "Rich Menu จะปรากฎบน Line Official Account ของคุณ",
        shop_mermbership: "สมัครสมาชิกร้าน",
        got_it_today: "ได้เลยวันนี้!",
        create_rich_menu_second: "สร้าง Rich Menu",
        how_to_use_poster: "วิธีใช้โปสเตอร์",
        how_to_use_poster_thai: "สะสมแต้ม และรับสิทธิพิเศษต่างๆกับทางร้านได้เลย",
    },
    box_info: {
        // accept redeem
        accept_redeem_title: "ยืนยันการแลกของรางวัล คูปอง และ สิทธิพิเศษ",
        accept_redeem_body: "ค้นหาของรางวัล, คูปอง และ ",
        accept_redeem_body_1: "สิทธิพิเศษด้วยโค้ดที่อยู่บน ",
        accept_redeem_body_2: "or",
        accept_redeem_body_3: "Confirmation Slip ของลูกค้า",
        accept_redeem_body_4: "หรือ",
        accept_redeem_body_5: "สแกน Qr Code บน Confirmation Slip",
        // edit point member
        edit_point_title: "แก้ไข Points ของสมาชิก",
        edit_point_body: "ค้นหาสมาชิกผ่านการใส่เบอร์โทร / เลขสมาชิก",
        edit_point_body_1: "",
        edit_point_body_2: "หรือ",
        edit_point_body_3: "สแกน QR Code บนของสมาชิก",
        // import
        import_title: "นำเข้ารายชื่อสมาชิกที่คุณมีอยู่แล้ว",
        import_body_1: "โดยการดาวน์โหลดไฟล์ CSV ข้างล่าง ",
        import_body_2: "และคัดลอกรายชื่อสมาชิกใส่ไฟล์ CSV",
        import_body_3: "ก่อนจะอัปโหลดไฟล์ CSV กลับเข้ามายังระบบ",
        import_footer: "นำออกรายชื่อสมาชิก",
        import_footer_body: "โดยการกดปุ่มดาวน์โหลดรายชื่อลูกค้า",
        //
        reward_membership_title: "กลุ่มสมาชิก",
        reward_membership_body_1: "กำหนดกลุ่มสมาชิกที่คุณต้องการจะ",
        reward_membership_body_2: "มอบให้ ถ้าไม่ต้องการที่จะกำหนด เลือกสมาชิกทั้งหมด",

        reward_quantity_title: "จำนวนที่จำกัด",
        reward_quantity_body: "ใส่จำนวนทั้งหมดของของรางวัล",

        reward__birth_month: "เดือน",
        reward_birth_body_1: "กำหนดเดือนเกิดของสมาชิกที่คุณต้องการจะมอบให้ ",
        reward_birth_body_2: "ถ้าไม่ต้องการที่จะกำหนด เลือกเดือนทั้งหมด",

        reward__quantity_month: "จำนวนที่จำกัด ต่อ ผู้ใช้งาน",
        reward__quantity_body: "ใส่จำนวนที่จำกัดต่อผู้ใช้งาน 1 คนจะสามารถแลกได้",

        variant: "ตัวเลือก",
        variant_body:
            "ใส่ตัวเลือกที่ผู้ใช้งานสามารถเลือกได้ ทำให้ของรางวัลมีความหลากหลายมากยิ่งขึ้น เช่น ของรางวัล = เสื้อ, ตัวเลือก: สีแดง, สีขาว",

        benefit_coupon_title: "สิทธิพิเศษของคูปอง",
        benefit_coupon_body: "ใส่มูลค่าของคูปองที่ต้องการมอบให้สมาชิกเป็นตัวเลข ",
        unit: "The unit of coupon can be selected as  1. Baht 2. Percent",
        benefit_membershipt: "กลุ่มสมาชิก",
        benefit_body_1: "กำหนดกลุ่มสมาชิกที่คุณต้องการจะ",
        benefit_body_2: "มอบให้ ถ้าไม่ต้องการที่จะกำหนด เลือกสมาชิกทั้งหมด",
        benefit_quantity: "จำนวนที่จำกัด",
        benefit_quantity_body_1: "ใส่จำนวนทั้งหมดของคูปอง",
        benefit_quantity_user: "จำนวนที่จำกัด ต่อ ผู้ใช้งาน",
        benefit_quantity_body_2: "ใส่จำนวนที่จำกัดต่อผู้ใช้งาน 1 คนจะสามารถแลกได้",

        point: "Point",
        point_body: "กำหนดคะแนนที่จะมอบให้กับสมาชิก",

        loyalty_title: "ตั้งค่ากลุ่มสมาชิก",
        loyalty_body: "แบ่งกลุ่มสมาชิกของคุณ โดยสามารถตั้งค่าแต่ละกลุ่มได้ผ่านปุ่ม รูปดินสอ",
        loyalty_body_2: "",
        loyalty_body_3: "",
        loyalty_body_4: "",
        // point expiry
        point_expiry: "กำหนดระยะเวลาหมดอายุของ Points",
        point_expiry_body:
            "สามารถเลือกให้ Point หมดอายุตามระยะเวลาที่กำหนด 1. ตามจำนวนเดือน 2. ตามรอบเดือน",
        point_expiry_body_1: "",
        point_expiry_body_2: "",
        // downgrade
        down_grade: "กำหนดการลดกลุ่มสมาชิก",
        down_grade_body:
            "สามารถเลือกรอบตัดกลุ่มสมาชิก 1. ตัดทันทีเมื่อยอดต่ำกว่าเกณฑ์กลุ่มสมาชิก 2. ตัดทุกสิ้นเดือน 3. ตัดทุกเดือนที่กำหนด",
        down_grade_body_1: "",
        down_grade_body_2: "",
        down_grade_body_3: "",
        // point add

        threshold: "เกณฑ์ Point สำหรับสมาชิกกลุ่มนี้",
        threshold_body:
            "กำหนดจำนวน Point ที่สมาชิกจะต้องมีเพื่อเป็นกลุ่มสมาชิกนี้ได้เช่น กลุ่มสมาชิก Gold = 5,000 Points สมาชิกจำเป็นต้องมี 5,000 Points เพื่อเป็นกลุ่มสมาชิก Gold",
        signup_form: "แก้ไขฟอร์มสมัครสมาชิก",
        signup_form_body: "เพิ่มช่องคำถามในหน้าสมัครสมาชิก เพื่อเก็บข้อมูลลูกค้าตามที่ต้องการ",
        signup_method: "ช่องทางสำหรับการสมัครสมาชิก",
        signup_method_body: "เลือกช่องทางที่สมาชิกของคุณสามารถเลือกใช้เป็นวิธี สมัครสมาชิก",
        welcome_image: "รูปบนหน้า Welcome",
        welcome_image_body:
            "อัพโหลดรูปเพื่อใส่ไปบนหน้า Welcome ลูกค้าจะเห็นหน้านี้เป็นหน้าแรกก่อนสมัครสมาชิก",
        contact_list: "รายชื่อสมาชิก",
        contact_list_body:
            "ดูรายชื่อสมาชิกทั้งหมดของคุณ สามารถกดเข้าไปยังชื่อรายชื่อสมาชิกรายบุคคลเพื่อดูข้อมูลโดยละเอียด",
        notification: "ตั้งค่าการแจ้งเตือน",
        notification_body:
            "เลือกช่องทางที่คุณจะรับการแจ้งเตือนและเลือกเหตุการณ์ ที่จะรับการแจ้งเตือน",
        integration: "การเชื่อมต่อ",
        integration_body:
            "เชื่อมต่อ Account ของคุณกับ 3rd Party Integration หากมีข้อสงสัยกรุณาติดต่อ Customer Success ของคุณผ่าน Live Chat ที่มุมขวาล่าง",
        line_message: "Line Messaging",
        line_message_body:
            "สร้าง Line Messaging บน Line Developer Console และนำ Token ต่างๆมาใส่ไว้ในช่องที่กำหนด",
        line_login_api: "Line Login Api",
        line_login_api_body:
            "สร้าง Line Login Api บน Line Developer Console และนำ Token ต่างๆมาใส่ไว้ในช่องที่กำหนด",
        account_setting: "ตั้งค่าบัญชีร้านค้า",
        account_setting_body: "เปลี่ยนแปลงรายละเอียดและหน้าตาที่สมาชิกของคุณจะเห็นได้ผ่านเมนูนี้",
    },
    copy_link: "คัดลอกลิงค์",
    bidding: {
        menu_header: "จัดซื้อจัดจ้าง",
        create_item: "สร้างรายการ",
        add_purchase: "สร้างการประมูล",
        add_employ: "สร้างการประมูล",
        bidding_headline: "หัวข้อการประมูล",
        bidding_join_number: "จำนวนผู้เข้าร่วมประมูล",
        bidding_join_date: "ระยะเวลาเข้าร่วมประมูล",
        bidding_create_date: "วันที่สร้าง",
        bidding_see_more: "ดูรายละเอียด",
        result_list_header: "รายการประกาศผลจัดซื้อ",
        result_detail_header: "ประกาศผลจัดซื้อ",
        create_bidding_headline_p: "สร้างประมูลการจัดซื้อ",
        create_bidding_headline_e: "สร้างประมูลการจัดจ้าง",
        create_result_headline: "สร้างรายการประกาศผล",
        view_purchase_headline: "รายละเอียดประมูลการจัดซื้อ",
        view_employ_headline: "รายละเอียดประมูลการจัดจ้าง",
        bidder_number: "จำนวนผู้เข้าร่วมประมูล",
        bidder_name_tab: "รายชื่อผู้เข้าร่วมการประมูล",
        bidding_edit_tab_p: "แก้ไขข้อมูลประมูลการจัดซื้อ",
        bidding_edit_tab_e: "แก้ไขข้อมูลประมูลการจัดจ้าง",
        name_list: "รายชื่อผู้เข้าร่วมการประมูล",
        bidding_list: "รายการประมูล",
        document_amount: "จำนวนเอกสาร",
        update_day: "วันที่อัปเดท",
        period: "ระยะเวลากิจกรรม",
        bidding_detail: "รายละเอียดการประมูล",
        bidding_title: "หัวข้อการประมูล*",
        bididng_period: "ระยะเวลาการประมูล",
        please_select_file: "กรุณาเลือกไฟล์",
        upload_file_bidding_headline: "อัปโหลดไฟล์แบบฟอร์มการประมูล*",
        upload_file_result_headline: "โปรดแนบเอกสารรายงานประกาศผล*",
        add_more_file: "+ เพิ่มเอกสาร",
        upload_file: "อัปโหลดไฟล์",
        title_headline: "ชื่อหัวข้อ*",
    },
    homeMenu: {
        menu_header: "ประชาสัมพันธ์หน้าเว็บไซต์",
        home_menu_headline: "เมนูแนะนำเทศบาล",
        cover_headline: "รายละเอียดแบนเนอร์",
        cover_img: "รูปภาพหน้าปก*",
        cover_text: "ข้อความประกอบหน้าปก*",
        cover_detail: "คำบรรยายประกอบหน้าปก*",
        short_menu_headline: "เมนูทางลัด",
        menu_num: "เมนูที่",
        menu_name: "ชื่อเมนู",
        menu_link: "ลิ้งค์",
        menu_menu: "จัดการ",
        gen_headline: "ประชาสัมพันธ์เทศบาล*",
        gen_add_more: "+ เพิ่ม URL",
        upload_shortcut_icon: "อัปโหลดไอคอนเมนูทางลัด",
        upload_shortcut_icon_detail: "โปรดไฟล์อัปโหลดสกุล .PNG ขนาด 512*512 px หรือ 1 : 1",
        shortcut_menu_name: "ชื่อเมนู*",
        calendar_headline: "ปฏิทินกิจกรรม",
        calendar_image: "รูปภาพประกอบหัวข้อปฎิทินกิจกรรม*",
        calendar_name: "จัดขึ้นโดย*",
        decor_headline: "รูปภาพประกอบ",
        decor_image: "รูปภาพประกอบตกแต่งเว็บไซต์*",
        decor_text: "ข้อความประกอบตกแต่งเว็บไซต์",
        decor_detail: "คำบรรยายประกอบ",
        introduce_headline: "ข้อมูลแนะนำเทศบาล",
        header_field: "ชื่อหัวข้อ*",
        desc_field: "รายละเอียดส่วนที่",
    },
};
