import { HomeSerPage } from "./../Pages/homeMenu/homeSer";
import { HomeDecsPage } from "./../Pages/homeMenu/homeDecs";
import { HomeAboutPage } from "./../Pages/homeMenu/homeabout";
import { AccountLayout, LayoutAuth, LayoutDefault, LayoutError } from "@layouts";
import { IRoute } from "@interfaces";
import {
    PageHome,
    PageLogin,
    PageError404,
    PageSignUp,
    PageVerifyEmail,
    PageCreatePassword,
    PageLineLogin,
    PageForgotPassword,
    PageResetPassword,
    PageNotCompleted,
    PageAccountList,
    PageNewsCreate,
    PageNewsList,
    PageHotNewsList,
    PageConplaintList,
    PageConplaintCreate,
    PageComplaintInfo,
    PageDivisionList,
    PageCategoryList,
    PageActivityList,
    HomeMenuPage,
    PageBiddingResultDetail,
    BiddingResultListPage,
    PageBiddingDetail,
    EmployListPage,
    PageActivityInfo,
    PageActivityCreate,
    PurchaseListPage,
    PageRewardList,
    PageRewardUpdate,
    PageRewardCreate,
    PagePrivilegeRedeem,
    PageSettingsCreate,
    PageThemeCreate,
    PageBookingList,
    PageServiceList,
    PageDepartmentList,
    PageIntegrationUpdate,
    PageAdminList,
    PagePermissionsList,
    PagePermissionCreate,
    PageCreateAdmin,
    PageProfileCreate,
    PageChangePassword,
    PageActivityCategory,
    PageNewsCategory,
    PageServiceCreate,
    PageAreaList,
    PageAreaDetail,
    PageHouseDetail,
    PageHouseList,
    PageBusinessList,
    PageBusinessDetail,
    PageBillList,
    PageBillDetail,
    PageBillAutoDetail,
    PageBidderDetail,
    PageVerrifyList,
    PageVerrifyDetail,
} from "@pages";
import { PageClaimCreate, PageClaimList, PageInfoUpdate } from "src/Pages/claim";
import { HomeORGPage } from "src/Pages/homeMenu/homeORG";
import { PageServiceDeptCreate } from "src/Pages/department/Create";
// new routes
export const PATH_HOME = "/";

export const PATH_NEWS = "/news";
export const PATH_CREATE_NEWS = "/news/create";
export const PATH_NEWS_ALL = "/news/all";
export const PATH_NEWS_HIGHLIGHT = "/news/highlight";
export const PATH_NEWS_CATEGORY = "/news-category";

// home menu route
export const PATH_EXAMPLE = "/example";
export const PATH_HOME_MENU = "/home-menu";
export const PATH_HOME_ABOUT = "/home-about";
export const PATH_HOME_ORG = "/home-org";
export const PATH_HOME_DECS_REPORT = "/home-docs-report";
export const PATH_HOME_SER_INFO = "/home-ser-info";

// biddng route
export const PATH_BIDDING_PURCHASE_LIST = "/purchase-list";
export const PATH_BIDDING_EMPLOY_LIST = "/employ-list";
export const PATH_BIDDING_RESULT_LIST = "/bidding-result";
export const PATH_BIDDING = "/bidding";
export const PATH_CREATE_PURCHASE = "/bidding/create-purchase";
export const PATH_CREATE_BIDDING_RESULT = "/bidding-result/create";
export const PATH_CREATE_EMPLOY = "/bidding/create-employ";

// trashfee route
export const PATH_TRASH_FEE = "/trashfee";
export const PATH_TRASH_FEE_AREA = "/trashfee/area";
export const PATH_TRASH_FEE_AREA_CREATE = "/trashfee/area/create";
export const PATH_TRASH_FEE_HOUSE = "/trashfee/house";
export const PATH_TRASH_FEE_HOUSE_CREATE = "/trashfee/house/create";
export const PATH_TRASH_FEE_BUSINESS = "/trashfee/business";
export const PATH_TRASH_FEE_BUSINESS_CREATE = "/trashfee/business/create";
export const PATH_TRASH_FEE_BILL = "/trashfee/bill";
export const PATH_TRASH_FEE_BILL_CREATE = "/trashfee/bill/create";
export const PATH_TRASH_FEE_BILL_MANUAL_CREATE = "/trashfee/bill/manual";
export const PATH_TRASH_FEE_BILL_AUTO_CREATE = '/trashfee/bill/auto';
export const PATH_TRASH_FEE_VERRIFY = "/trashfee/verrify";


export const PATH_COMPLAINT = "/complaints";
export const PATH_COMPLAINT_CREATE = "/complaint-create";
export const PATH_COMPLAINT_CATEGORY = "/complaint-category";
export const PATH_COMPLAINT_DIVISIONS = "/complaint-divisions";
export const PATH_ACTIVITY = "/activity";
export const PATH_ACTIVITY_CREATE = "/activity-create";
export const PATH_ACTIVITY_DETAIL = "/activity-detail";
export const PATH_ACTIVITY_CATEGORY = "/activity-category";
export const PATH_PRIVILEGES = "/privileges";
export const PATH_PRIVILEGES_CREATE = "/privileges-create";
export const PATH_REDEEM_PRIVILEGES = "/redeem-privileges";
export const PATH_CLAIM = "/claim";
export const PATH_CLAIM_CREATE = "/claim-create";
export const PATH_SETTINGS = "/settings";
export const PATH_THEMES = "/themes";
export const PATH_PROFILE = "/profile";
export const PATH_ADMIN = "/admin";
export const PATH_PERMISSIONS = "/permissions";
export const PATH_ADMIN_CREATE = "/admin-create";
export const PATH_PERMISSIONS_CREATE = "/permissions-create";
export const PATH_BOOKING = "/booking";
export const PATH_SERVICES = "/services";
export const PATH_SERVICES_CREATE = "/services-create";
export const PATH_DEPARTMENT = "/department";
export const PATH_DEPARTMENT_CREATE = "/department-create";
export const PATH_INTEGRATION_SETTINGS = "/integration";
export const PATH_INVITELINK = "/invitelink";
export const PATH_CHANGE_PASSWROD = "/change-password";

// old routes
export const PATH_HOME_NEW = "/new-user";
export const PATH_LOGIN = "/login";
export const PATH_SIGN_UP = "/sign-up";
export const PATH_VERIFY_EMAIL = "/verify-email";
export const PATH_CREATE_PASSWORD = "/create-password";
export const PATH_FORGOT_PASSWORD = "/forgot-password";
export const PATH_CHOOSE_PLAN = "/choose-plan";
export const PATH_LINE_LOGIN = "/line-login";
export const PATH_RESET_PASSWORD = "/reset-password";
export const PATH_SIGN_UP_ACCOUNT = "/sign-up/account";
export const PATH_ACCOUNTS = "/accounts";
export const PATH_CREATE_ACCOUNTS = "/create-accounts";

export const PATH_NOT_COMPLETED = "/not-completed";

export const PATH_404 = "*";

export const routes: Array<IRoute> = [
    { path: PATH_HOME, component: PageHome, exact: true },
    { path: PATH_HOME, component: PageHome, exact: true },

    // render eample page
    { path: PATH_HOME_MENU, component: HomeMenuPage, exact: true },
    { path: PATH_HOME_ABOUT, component: HomeAboutPage, exact: true },
    { path: PATH_HOME_ORG, component: HomeORGPage, exact: true },
    { path: PATH_HOME_DECS_REPORT, component: HomeDecsPage, exact: true },
    { path: PATH_HOME_SER_INFO, component: HomeSerPage, exact: true },
    { path: PATH_BIDDING_PURCHASE_LIST, component: PurchaseListPage, exact: true },
    { path: PATH_BIDDING_EMPLOY_LIST, component: EmployListPage, exact: true },
    { path: PATH_BIDDING_RESULT_LIST, component: BiddingResultListPage, exact: true },
    {
        path: PATH_BIDDING + "/:id",
        component: PageBiddingDetail,
        exact: true,
    },
    {
        path: PATH_BIDDING + "/:id/:bidder",
        component: PageBidderDetail,
        exact: true,
    },
    {
        path: PATH_BIDDING_RESULT_LIST + "/:id",
        component: PageBiddingResultDetail,
        exact: true,
    },
    // -----------------------trash fee -------------------------------------
    { path: PATH_TRASH_FEE_AREA, component: PageAreaList, exact: true },
    // { path: PATH_TRASH_FEE_AREA_CREATE, component: PageAreaDetail, exact: true },
    {
        path: PATH_TRASH_FEE_AREA + "/:id",
        component: PageAreaDetail,
        exact: true,
    },

    { path: PATH_TRASH_FEE_HOUSE, component: PageHouseList, exact: true },
    {
        path: PATH_TRASH_FEE_HOUSE + "/:id",
        component: PageHouseDetail,
        exact: true,
    },
    { path: PATH_TRASH_FEE_BUSINESS, component: PageBusinessList, exact: true },
    {
        path: PATH_TRASH_FEE_BUSINESS + "/:id",
        component: PageBusinessDetail,
        exact: true,
    },
    { path: PATH_TRASH_FEE_BILL, component: PageBillList, exact: true },
    { path: PATH_TRASH_FEE_BILL_MANUAL_CREATE, component: PageBillDetail, exact: true },
    { path: PATH_TRASH_FEE_BILL_AUTO_CREATE, component: PageBillAutoDetail, exact: true },

    { path: PATH_TRASH_FEE_VERRIFY, component: PageVerrifyList, exact: true },
    {
        path: PATH_TRASH_FEE_VERRIFY + "/:id",
        component: PageVerrifyDetail,
        exact: true,
    },


    // ---------------------- news module ------------------------------------------
    { path: PATH_NEWS, component: PageNewsList, exact: true },
    // { path: PATH_HOME_NEW, component: PageHome, exact: true },
    { path: PATH_CREATE_NEWS, component: PageNewsCreate, exact: true },
    { path: PATH_CREATE_NEWS + "/:id", component: PageNewsCreate, exact: true },
    { path: PATH_NEWS_HIGHLIGHT, component: PageHotNewsList, exact: true },
    { path: PATH_NEWS_CATEGORY, component: PageNewsCategory, exact: true },
    // ---------------------- complaint module ------------------------------------------
    { path: PATH_COMPLAINT, component: PageConplaintList, exact: true },
    { path: PATH_COMPLAINT + "/:id", component: PageComplaintInfo, exact: true },
    { path: PATH_COMPLAINT_CREATE, component: PageConplaintCreate, exact: true },
    { path: PATH_COMPLAINT_CATEGORY, component: PageCategoryList, exact: true },
    { path: PATH_COMPLAINT_DIVISIONS, component: PageDivisionList, exact: true },
    // ---------------------- activity module ------------------------------------------
    { path: PATH_ACTIVITY, component: PageActivityList, exact: true },
    { path: PATH_ACTIVITY + "/:id", component: PageActivityInfo, exact: true },
    { path: PATH_ACTIVITY_CREATE, component: PageActivityCreate, exact: true },
    { path: PATH_ACTIVITY_CATEGORY, component: PageActivityCategory, exact: true },
    // ----------------------Privileges & Claim module------------------------
    { path: PATH_PRIVILEGES, component: PageRewardList, exact: true },
    { path: PATH_PRIVILEGES + "/:id", component: PageRewardUpdate, exact: true },
    { path: PATH_PRIVILEGES_CREATE, component: PageRewardCreate, exact: true },
    { path: PATH_REDEEM_PRIVILEGES, component: PagePrivilegeRedeem, exact: true },
    { path: PATH_CLAIM, component: PageClaimList, exact: true },
    { path: PATH_CLAIM + "/:id", component: PageClaimCreate, exact: true },
    { path: PATH_CLAIM_CREATE, component: PageClaimCreate, exact: true },
    // ---------------------- setting -------------------------------
    { path: PATH_SETTINGS, component: PageSettingsCreate, exact: true },
    { path: PATH_THEMES, component: PageThemeCreate, exact: true },
    { path: PATH_INTEGRATION_SETTINGS, component: PageIntegrationUpdate, exact: true },
    { path: PATH_PROFILE, component: PageProfileCreate, exact: true },
    { path: PATH_ADMIN, component: PageAdminList, exact: true },
    { path: PATH_ADMIN + "/:id", component: PageCreateAdmin, exact: true },
    { path: PATH_ADMIN_CREATE, component: PageCreateAdmin, exact: true },
    { path: PATH_PERMISSIONS, component: PagePermissionsList, exact: true },
    { path: PATH_PERMISSIONS + "/:id", component: PagePermissionCreate, exact: true },
    { path: PATH_PERMISSIONS_CREATE, component: PagePermissionCreate, exact: true },

    // ---------------------- booking & service -------------------------------
    { path: PATH_BOOKING, component: PageBookingList, exact: true },
    { path: PATH_SERVICES, component: PageServiceList, exact: true },
    { path: PATH_SERVICES + "/:id", component: PageServiceCreate, exact: true },
    { path: PATH_SERVICES_CREATE, component: PageServiceCreate, exact: true },
    { path: PATH_DEPARTMENT, component: PageDepartmentList, exact: true },
    { path: PATH_DEPARTMENT + "/:id", component: PageServiceDeptCreate, exact: true },
    { path: PATH_DEPARTMENT_CREATE, component: PageServiceDeptCreate, exact: true },
    // ---------------------- login module ------------------------------------------
    { path: PATH_LOGIN, component: PageLogin, exact: true, layout: LayoutAuth },
    // { path: PATH_SIGN_UP, component: PageSignUp, exact: true, layout: LayoutAuth },
    // { path: PATH_SIGN_UP + "/:id", component: PageSignUp, exact: true, layout: LayoutAuth },
    { path: PATH_ACCOUNTS, component: PageAccountList, exact: true, layout: AccountLayout },
    {
        path: PATH_INVITELINK + "/:email-:active_code",
        component: PageCreatePassword,
        exact: true,
        layout: LayoutAuth,
    },
    {
        path: PATH_CHANGE_PASSWROD,
        component: PageChangePassword,
        exact: true,
        layout: LayoutAuth,
    },
    {
        path: PATH_VERIFY_EMAIL + "/:email",
        component: PageVerifyEmail,
        exact: true,
        layout: LayoutAuth,
    },
    {
        path: PATH_CREATE_PASSWORD + "/:resetPasswordId",
        component: PageCreatePassword,
        exact: true,
        layout: LayoutAuth,
    },
    {
        path: PATH_RESET_PASSWORD + "/:resetPasswordId",
        component: PageResetPassword,
        exact: true,
        layout: LayoutAuth,
    },
    { path: PATH_LINE_LOGIN, component: PageLineLogin, exact: true, layout: LayoutAuth },
    {
        path: PATH_FORGOT_PASSWORD,
        component: PageForgotPassword,
        exact: true,
        layout: LayoutAuth,
    },
    // err
    { path: PATH_NOT_COMPLETED, component: PageNotCompleted, layout: LayoutDefault, exact: true },
    { component: PageError404, exact: false, layout: LayoutError },
];

export const normalRoutes = [
    PATH_HOME,
    PATH_NEWS,
    PATH_CREATE_NEWS,
    PATH_NEWS_HIGHLIGHT,
    PATH_COMPLAINT,
    PATH_COMPLAINT_CREATE,
    PATH_COMPLAINT_CATEGORY,
    PATH_COMPLAINT_DIVISIONS,
];

export const authRoutes = [
    PATH_LOGIN,
    PATH_CREATE_PASSWORD,
    PATH_SIGN_UP,
    PATH_VERIFY_EMAIL,
    PATH_FORGOT_PASSWORD,
    PATH_ACCOUNTS,
    PATH_CREATE_ACCOUNTS,
    PATH_INVITELINK,
    PATH_CHANGE_PASSWROD,
];
export const premissionRoutes = [
    PATH_ACTIVITY,
    PATH_ACTIVITY_CREATE,
    PATH_NEWS,
    PATH_NEWS_HIGHLIGHT,
    PATH_CREATE_NEWS,
    PATH_PERMISSIONS,
    PATH_PERMISSIONS_CREATE,
    PATH_REDEEM_PRIVILEGES,
    PATH_COMPLAINT,
    PATH_COMPLAINT_CREATE,
    PATH_BIDDING,
    PATH_CREATE_BIDDING_RESULT,
    PATH_SETTINGS,
    PATH_INTEGRATION_SETTINGS,
];
