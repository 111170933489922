import { IConplaintCategory, IDivision, INewsCategory } from "@interfaces";
import { Input, Select } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
const { Search } = Input;
const { Option } = Select;

interface Iprops {
    setTextSearch: (value: string) => void;
    handleSearchCategory: (value: string) => void;
    categories: INewsCategory[] | IConplaintCategory[];
    onChangeDateRange?: (dates, dateStrings: [string, string]) => void;
    onChangeDate?: (dates, dateStrings: string) => void;
    total: number;
    divisions?: IDivision[];
    isRangePicker: boolean;
}
export const ComponentTableSearch = (props: Iprops) => {
    const { setTextSearch, handleSearchCategory, categories, total } = props;
    const { t } = useTranslation();
    return (
        <StyledSearchLayout>
            <div className="filter">
                <div className="search-row">
                    <div className="col-item">
                        <div className="filter-element">
                            <div className="label">
                                <p>{t("page.search_news")}</p>
                            </div>
                            <div className="input">
                                <Search
                                    placeholder={t("page.input_search_news")}
                                    onSearch={(value) => setTextSearch(value)}
                                    className="search-input"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-item">
                        <div className="filter-element">
                            <div className="label">
                                <p>
                                    {t("page.news_type")}
                                    <span>{" :"}</span>
                                </p>
                            </div>
                            <div className="input">
                                <Select onChange={handleSearchCategory} placeholder={t("page.all")}>
                                    {categories.map((item, index) => (
                                        <Option value={item._id} key={index}>
                                            {item.category_name}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="filter-element result">
                    <p>
                        <span>{t("page.result")}</span>
                        <span>{":"}</span>
                        <span className="eng">{total}</span>
                    </p>
                </div>
            </div>
        </StyledSearchLayout>
    );
};
const StyledSearchLayout = styled.div`
    .filter {
        background: #fff;
        padding: 20px;
        border-radius: 4px;
        margin-bottom: 26px;
        display: flex;
        justify-content: space-between;
        .search-row {
            flex: 11;
            display: flex;
            column-gap: 10px;
            align-items: center;
        }
        .filter-element {
            flex: 2;
            display: flex;
            align-items: center;
            column-gap: 10px;
            p {
                margin: 0;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                color: #646464;
            }
            .filter-input {
                width: 240px;
                @media (max-width: ${(p) => p.theme.breakPoints.breakMacLaptop}) {
                    width: 150px;
                }
            }
            .ant-input-search {
                height: 44px;
                width: 100%;
            }
            .label {
                p {
                    @media (max-width: ${(p) => p.theme.breakPoints.breakMacLaptop}) {
                        text-overflow: ellipsis;
                        max-width: 70px;
                        white-space: nowrap;
                    }
                    @media (max-width: ${(p) => p.theme.breakPoints.break13Laptop}) {
                        max-width: 80px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }
            .input {
                .search-input {
                    width: 200px;
                    @media (max-width: ${(p) => p.theme.breakPoints.breakMacLaptop}) {
                        width: 150px;
                    }
                }
                .ant-select {
                    width: 200px;
                    @media (max-width: ${(p) => p.theme.breakPoints.breakMacLaptop}) {
                        width: 150px;
                    }
                }
            }
            .ant-select {
                /* min-width: 160px; */
                width: 100%;
                .ant-select-selection--single {
                    height: 44px;
                    .ant-select-selection__rendered {
                        height: 100%;
                        align-items: center;
                        display: flex;
                    }
                }
            }
            .ant-calendar-picker-input {
                height: 44px;
                .ant-calendar-range-picker-separator {
                    vertical-align: unset !important;
                }
            }
            &.result {
                display: flex;
                justify-content: end;
                p {
                    display: flex;
                    column-gap: 10px;
                }
            }
        }
    }
`;
