import { enumBillStatus } from "@configs";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
interface Iprops {
  setStatus: (params: enumBillStatus) => void;
  setFilterStatus: (params: boolean) => void;
  activeCount: number;
  inactiveCount: number;
  status?: enumBillStatus;
}
export const ComponentBillFilter = (props: Iprops) => {
  const { setStatus, setFilterStatus, activeCount, inactiveCount, status } = props;
  const { t } = useTranslation();

  return (
    <StyledFilter>
      <div className="status-filter">
        <div
          onClick={() => setStatus(enumBillStatus.OVERDUE)}
          className={status === enumBillStatus.OVERDUE ? "active filter-elem" : "filter-elem"}
        >
          ค้างชำระ
        </div>
        <div
          onClick={() => {
            setFilterStatus(true);
            setStatus(enumBillStatus.PAID);
          }}
          className={
            status === enumBillStatus.PAID ? "active filter-elem" : "filter-elem"
          }
        >
          ชำระแล้ว
        </div>
        <div
          onClick={() => {
            setFilterStatus(false);
            setStatus(enumBillStatus.CANCEL);
          }}
          className={
            status === enumBillStatus.CANCEL ? "active filter-elem" : "filter-elem"
          }
        >
          ยกเลิกการชำระ
        </div>
      </div>
    </StyledFilter>
  );
};
const StyledFilter = styled.div`
    .status-filter {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .filter-elem {
            padding: 12px;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            cursor: pointer;
            &.active {
                transition: 0.3s;
                border-bottom: 4px solid #0d6efd;
                padding-bottom: 11px;
                padding-bottom: 8px;
            }
            .count {
                margin-left: 6px;
                font-style: normal;
                font-weight: 400;
                font-size: 22px;
                line-height: 33px;
                color: #c5c5c5;
            }
        }
    }
`;
