export const NewsIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M20.0002 20.75C20.4144 20.75 20.7502 20.4142 20.7502 20C20.7502 19.5858 20.4144 19.25 20.0002 19.25V20.75ZM2 18H1.25H2ZM20.0002 19.25H4V20.75H20.0002V19.25ZM4 19.25C3.66848 19.25 3.35054 19.1183 3.11612 18.8839L2.05546 19.9445C2.57118 20.4603 3.27065 20.75 4 20.75V19.25ZM3.11612 18.8839C2.8817 18.6495 2.75 18.3315 2.75 18H1.25C1.25 18.7293 1.53973 19.4288 2.05546 19.9445L3.11612 18.8839ZM2.75 18V7H1.25V18H2.75ZM5 4.75H15.0002V3.25H5V4.75ZM17.2502 7V18H18.7502V7H17.2502ZM17.2502 18C17.2502 18.7293 17.5399 19.4288 18.0556 19.9445L19.1163 18.8839C18.8819 18.6495 18.7502 18.3315 18.7502 18H17.2502ZM18.0556 19.9445C18.5714 20.4603 19.2708 20.75 20.0002 20.75V19.25C19.6687 19.25 19.3507 19.1183 19.1163 18.8839L18.0556 19.9445ZM15.0002 4.75C16.2428 4.75 17.2502 5.75736 17.2502 7H18.7502C18.7502 4.92893 17.0713 3.25 15.0002 3.25V4.75ZM2.75 7C2.75 5.75736 3.75736 4.75 5 4.75V3.25C2.92893 3.25 1.25 4.92893 1.25 7H2.75Z"
            fill="#0D6EFD"
        />
        <path
            d="M18 10H22V18C22 18.5304 21.7893 19.0391 21.4142 19.4142C21.0391 19.7893 20.5304 20 20 20"
            stroke="#0D6EFD"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M9 8H5V12H9V8Z"
            stroke="#0D6EFD"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M12 8H14"
            stroke="#0D6EFD"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M12 12H14"
            stroke="#0D6EFD"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M5 16L14 16"
            stroke="#0D6EFD"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
