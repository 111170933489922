import {
  ITrashfeeAreaDetailGet,
  ITrashfeeAreaListGet,
  ITrashfeeDistrictListGet,
  ITrashfeeAreaCreate,
  ItrashfeeAreaUpdate,
  ItrashfeeAreaDelete,
  ITrashfeeHouseListGet,
  ITrashfeeHouseCreate,
  ITrashFeeHouseGet,
  ITrashfeeBillGet,
  ITrashfeeGetHousebyRegisterNo,
  ITrashfeeAutoCreate,
  ITrashfeeManualCreate,
  ITrashfeeBillDetailListGet,
  IAmphurByOrganizationIdGet,
  IDistrictByAmphurGet,
  ITrashfeeHomePriceGet,
  ITrashfeeBillDetailListCancleGet,
  ITrashfeePayCash,
  ITrashfeeToggleCancle,
  ITrashfeeDeleteAddress,
  ITrashfeeHouseUpdate,
  ITrashfeeHousePriceUpdate,
} from "@interfaces";

import axiosClient from "./axiosClient";

export const trashfeeAPI = {
  getAreaList: (params: ITrashfeeAreaListGet) => {
    const url = "area/getAreas";
    return axiosClient.get(url, { params });
  },
  getAreaDetail: (params: ITrashfeeAreaDetailGet) => {
    const url = "area/getArea";
    return axiosClient.get(url, { params });
  },
  getDistrictList: (params: ITrashfeeDistrictListGet) => {
    const url = "geos/getAllDistrictByAmphurId";
    return axiosClient.post(url, params);
  },
  createArea: (params: ITrashfeeAreaCreate) => {
    const url = "area/createArea";
    return axiosClient.post(url, params);
  },
  updateArea: (params: ItrashfeeAreaUpdate) => {
    const url = "area/updateArea";
    return axiosClient.patch(url, params);
  },
  deleteArea: (params: ItrashfeeAreaDelete) => {
    const url = "area/deleteArea";
    return axiosClient.post(url, params);
  },
  getHouseList: (params: ITrashfeeHouseListGet) => {
    const url = "house/getHouses";
    return axiosClient.get(url, { params });
  },
  createHouse: (params: ITrashfeeHouseCreate) => {
    const url = "house/createHouseAdmin";
    return axiosClient.post(url, params);
  },
  getHouseDetail: (params: ITrashFeeHouseGet) => {
    const url = "house/getHouse";
    return axiosClient.get(url, { params });
  },
  getBillList: (params: ITrashfeeBillGet) => {
    const url = "trash_fee/getTrashFeesGroupByRegisterNo";
    return axiosClient.get(url, { params });
  },
  getHouseByRegisterNo: (params: ITrashfeeGetHousebyRegisterNo) => {
    const url = "house/getHouseByRegisterNo";
    return axiosClient.get(url, { params });
  },
  createTrashfeeManual: (params: ITrashfeeManualCreate) => {
    const url = "trash_fee/createTrashFeeManual";
    return axiosClient.post(url, params);
  },
  createTrashfeeAuto: (params: ITrashfeeAutoCreate) => {
    const url = "trash_fee/createTrashFeeAuto";
    return axiosClient.post(url, params);
  },
  getBillDetailList: (params: ITrashfeeBillDetailListGet) => {
    const url = "trash_fee/getTrashFees";
    return axiosClient.get(url, { params });
  },
  getBillDetailCancle: (params: ITrashfeeBillDetailListCancleGet) => {
    const url = "trash_fee/getTrashFees";
    return axiosClient.get(url, { params });
  },
  getAllAmphurByOrganizationId: (params: IAmphurByOrganizationIdGet) => {
    const url = "organization/getAllAmphurByOrganizationId";
    return axiosClient.get(url, { params });
  },
  getAllDistrictByAmphurId: (params: IDistrictByAmphurGet) => {
    const url = "geos/getAllDistrictByAmphurId";
    return axiosClient.post(url, params);
  },
  getHomePrice: (params: ITrashfeeHomePriceGet) => {
    const url = "organization/getTrashFee";
    return axiosClient.get(url, { params });
  },
  updatePayCash: (params: ITrashfeePayCash) => {
    const url = "trash_fee/adminReceiveCashTrashFee";
    return axiosClient.patch(url, params);
  },
  updateCancleBill: (params: ITrashfeeToggleCancle) => {
    const url = "trash_fee/setEnableTrashFee";
    return axiosClient.patch(url, params);
  },
  deleteHouse: (params: ITrashfeeDeleteAddress) => {
    const url = "house/deleteHouse";
    return axiosClient.post(url, params);
  },
  updateHouse: (params: ITrashfeeHouseUpdate) => {
    const url = "house/verifyHouseAdmin";
    return axiosClient.patch(url, params);
  },
  updateHouseTrashfee: (params: ITrashfeeHousePriceUpdate) => {
    const url = "organization/updateTrashFee";
    return axiosClient.patch(url, params);
  },
  // getResultList: (params: IBiddingParams) => {
  //     const url = "information/getInformations";
  //     return axiosClient.get(url, { params });
  // },
  // detail: (params: IBiddingDetailParams) => {
  //     const url = "bidding/getBidding";
  //     return axiosClient.get(url, { params });
  // },
  // resultDetail: (params: IBiddingResultDetailParams) => {
  //     const url = "information/getInformation";
  //     return axiosClient.get(url, { params });
  // },
  // update: (params: IbiddingUpdate) => {
  //     const url = "bidding/updateBidding";
  //     return axiosClient.patch(url, params);
  // },
  // uploadFile: (params: FormData) => {
  //     const url = `uploads/upload_file`;
  //     return axiosClient.post(url, params);
  // },
  // create: (params: IbiddingCreate) => {
  //     const url = "bidding/createBidding";
  //     return axiosClient.post(url, params);
  // },
  // createResult: (params: IResultCreate) => {
  //     const url = "information/createInformation";
  //     return axiosClient.post(url, params);
  // },
  // resultChangeStatus: (params: IResultChangeStatus) => {
  //     const url = "information/setEnableInformation";
  //     return axiosClient.patch(url, params);
  // },

  // resultDelete: (params: IResultDelete) => {
  //     const url = "information/deleteInformation";
  //     return axiosClient.post(url, params);
  // },
};
