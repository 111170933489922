import {
    IDepartmentsParams,
    IDepartmentDetailParams,
    IDepartmentCreate,
    IOrganizationId,
} from "@interfaces";

import axiosClient from "./axiosClient";

export const departmentAPI = {
    getServiceDepartments: (params: IDepartmentsParams) => {
        const url = "service_department/getServiceDepartments";
        return axiosClient.get(url, { params });
    },
    getServiceDepartment: (params: IDepartmentDetailParams) => {
        const url = "service_department/getServiceDepartment";
        return axiosClient.get(url, { params });
    },
    deleteServiceDepartment: (params: IDepartmentDetailParams) => {
        const url = `service_department/deleteServiceDepartment`;
        return axiosClient.post(url, params);
    },
    updateServiceDepartment: (params: IDepartmentCreate) => {
        const url = "service_department/updateServiceDepartment";
        return axiosClient.patch(url, params);
    },
    createServiceDepartment: (params: IDepartmentCreate) => {
        const url = "service_department/createServiceDepartment";
        return axiosClient.post(url, params);
    },
    getServiceDepartmentTemplate: (params: IOrganizationId) => {
        const url = "service_department/getServiceDepartmentTemplate";
        return axiosClient.get(url, { params });
    },
};
