import axiosClient from "./axiosClient";
import {
    INewsCategoryParams,
    IOrganizationId,
    INewsCategoryDelete,
    IConplainCategoryParams,
    IConplainCategoryDelete,
    IDivisionParams,
    IDivisionUpdateParams,
    IDivisionDelete,
    IDivisionStatus,
    IConplainCategoryStatus,
    IActivityCreateCategory,
    IActivityUpdateCategory,
    IOrganizationDetail,
    IThemeCreate,
    IIntegration,
    IConplainUpdateCategoryParams,
    IAdminParam,
    IActivityDeleteCategory,
    IGeosProvinceListGet,
} from "@interfaces";

export const orgApi = {
    getCat: (params: IOrganizationId) => {
        const url = "/organization/getNewsCategorys";
        return axiosClient.get(url, { params });
    },
    createCat: (params: INewsCategoryParams) => {
        const url = "/organization/createNewsCategory";
        return axiosClient.post(url, params);
    },
    deleteCat: (params: INewsCategoryDelete) => {
        const url = "/organization/deleteNewsCategory";
        return axiosClient.post(url, params);
    },
    updateNewsCategory: (params: INewsCategoryParams) => {
        const url = "organization/updateNewsCategory";
        return axiosClient.patch(url, params);
    },
    getConplainCat: (params: IAdminParam) => {
        const url = "/organization/getComplainCategorysByPermission";
        return axiosClient.get(url, { params });
    },
    createConplainCat: (params: IConplainCategoryParams) => {
        const url = "/organization/createComplainCategory";
        return axiosClient.post(url, params);
    },
    updateConplainCat: (params: IConplainUpdateCategoryParams) => {
        const url = "/organization/updateComplainCategory";
        return axiosClient.patch(url, params);
    },
    deleteConplainCat: (params: IConplainCategoryDelete) => {
        const url = "/organization/deleteComplainCategory";
        return axiosClient.post(url, params);
    },
    setEnableComplainCategory: (params: IConplainCategoryStatus) => {
        const url = "/organization/setEnableComplainCategory";
        return axiosClient.patch(url, params);
    },
    getDivision: (params: IAdminParam) => {
        const url = "/organization/getDivisionsByPermission";
        return axiosClient.get(url, { params });
    },
    createDivision: (params: IDivisionParams) => {
        const url = "/organization/createDivision";
        return axiosClient.post(url, params);
    },
    updateDivision: (params: IDivisionUpdateParams) => {
        const url = "/organization/updateDivision";
        return axiosClient.patch(url, params);
    },
    deleteDeivision: (params: IDivisionDelete) => {
        const url = "/organization/deleteDivision";
        return axiosClient.post(url, params);
    },
    setEnableDeivision: (params: IDivisionStatus) => {
        const url = "/organization/setEnableDivision";
        return axiosClient.patch(url, params);
    },
    getComplaintStatus: (params: IOrganizationId) => {
        const url = "/complain/getComplainStatus";
        return axiosClient.get(url, { params });
    },
    getActivityCategorys: (params: IOrganizationId) => {
        const url = "organization/getActivityCategorys";
        return axiosClient.get(url, { params });
    },
    deleteActivityCategory: (params: IActivityDeleteCategory) => {
        const url = "organization/deleteActivityCategory";
        return axiosClient.post(url, params);
    },
    updateActivityCategory: (params: IActivityUpdateCategory) => {
        const url = "organization/updateActivityCategory";
        return axiosClient.patch(url, params);
    },
    createActivityCategory: (params: IActivityCreateCategory) => {
        const url = "organization/createActivityCategory";
        return axiosClient.post(url, params);
    },
    getOrganizationDetail: (params: IOrganizationId) => {
        const url = "organization/getOrganizationDetail";
        return axiosClient.get(url, { params });
    },
    updateOrganizationDetail: (params: IOrganizationDetail) => {
        const url = "organization/updateOrganizationDetail";
        return axiosClient.patch(url, params);
    },
    getTheme: (params: IOrganizationId) => {
        const url = "organization/getThemeColorAndLogo";
        return axiosClient.get(url, { params });
    },
    updateTheme: (params: IThemeCreate) => {
        const url = "organization/updateThemeColorAndLogo";
        return axiosClient.patch(url, params);
    },
    updateLineIntegration: (params: IIntegration) => {
        const url = "organization/updateLineIntegration";
        return axiosClient.patch(url, params);
    },
    getLineIntegration: (params: IOrganizationId) => {
        const url = "organization/getLineIntegration";
        return axiosClient.get(url, { params });
    },
    getHeaderBar: (params: IOrganizationId) => {
        const url = `organization/getHeaderBar`;
        return axiosClient.get(url, { params });
    },
    getProvinceList: (params: IGeosProvinceListGet) => {
        const url = "geos/getAllProvince";
        return axiosClient.post(url, params);
    }
};
