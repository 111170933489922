import styled from "styled-components";
import { IDays } from "@interfaces";
import React, { useRef } from "react";
import moment from "moment";

interface IProps {
    days: IDays[] | undefined;
    handleSelect: (dateString: string) => void;
    value: string;
}
const sideScroll = (element: HTMLDivElement, speed: number, distance: number, step: number) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
        element.scrollLeft += step;
        scrollAmount += Math.abs(step);
        if (scrollAmount >= distance) {
            clearInterval(slideTimer);
        }
    }, speed);
};
export const ComponentDays = (props: IProps) => {
    const { days, handleSelect, value } = props;
    const contentWrapper = React.useRef(null);

    const handleMoveLeft = () => {
        //@ts-ignore
        sideScroll(contentWrapper.current, 25, 100, -10);
    };
    const handleMoveRight = () => {
        //@ts-ignore
        sideScroll(contentWrapper.current, 25, 100, 10);
    };
    return (
        <StyledDays>
            <div className="name-layout">
                <div className="name-move">
                    <LeftIcon />
                </div>
                <div className="days name">
                    {days &&
                        days.map((day, index) => (
                            <div className="count name" key={index}>
                                {day.thisdate_subname}
                            </div>
                        ))}
                </div>
                <div className="name-move">
                    <RightIcon />
                </div>
            </div>
            <div className="slide-layout">
                <div className="count" onClick={handleMoveLeft}>
                    <LeftIcon />
                </div>
                <div className="days" ref={contentWrapper}>
                    {days &&
                        days.map((day, index) => (
                            <div
                                className={
                                    value === day.thisdate_short_str
                                        ? "count active eng"
                                        : "count eng"
                                }
                                key={index}
                                onClick={() => handleSelect(day.thisdate_short_str)}
                            >
                                {day.thisdate}
                            </div>
                        ))}
                </div>
                <div className="count" onClick={handleMoveRight}>
                    <RightIcon />
                </div>
            </div>
        </StyledDays>
    );
};
const StyledDays = styled.div`
    .days-name {
        display: flex;
        overflow: hidden;
        width: 100%;
    }
    .slide-layout {
        display: flex;
        align-items: center;
        background: #fff;
        padding: 0 10px;
    }
    .name-layout {
        display: flex;
        align-items: center;
        background: transparent;
        padding: 0 10px;
    }
    .days {
        display: flex;
        overflow: hidden;
        width: 100%;
        padding: 12px;
        column-gap: 1px;
        background: #fff;
        &.name {
            background: transparent;
            padding: 0 12px;
        }
        .count {
            width: 50px;
            height: 46px;
            border-radius: 25px;
            background: #fff;
            color: #000;
            font-weight: 600;
            font-size: 16px;
            display: flex;
            align-items: center;
            text-align: center;
            cursor: pointer;
            justify-content: center;
            &.active {
                background: #0d6efd;
                color: #fff;
            }
            &.name {
                background: transparent;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 18px;
                display: flex;
                align-items: center;
                text-align: center;
                color: #c5c5c5;
            }
        }
    }
    .name-layout {
        .name-move {
            opacity: 0;
        }
    }
`;

const LeftIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_496_23490)">
            <path
                d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
                fill="#E1E1E1"
            />
            <path
                d="M13.1912 8.1898L9.38097 12L13.1912 15.8102"
                stroke="#8A959E"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_496_23490">
                <rect width="22" height="22" fill="white" transform="translate(1 1)" />
            </clipPath>
        </defs>
    </svg>
);

const RightIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_496_23495)">
            <path
                d="M12.0002 23C18.0754 23 23.0002 18.0751 23.0002 12C23.0002 5.92487 18.0754 1 12.0002 1C5.92511 1 1.00024 5.92487 1.00024 12C1.00024 18.0751 5.92511 23 12.0002 23Z"
                fill="#E1E1E1"
            />
            <path
                d="M11.0002 8L14.8104 11.8102L11.0002 15.6204"
                stroke="#8A959E"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_496_23495">
                <rect width="22" height="22" fill="white" transform="translate(1.00024 1)" />
            </clipPath>
        </defs>
    </svg>
);
