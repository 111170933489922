import { ISvg } from "@interfaces";
export const IconSubmit = (props: ISvg) => (
    <svg
        width={props.size || "24"}
        height={props.size || "24"}
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <mask id="mask0_3196_43009" maskUnits="userSpaceOnUse" x="2" y="3" width="20" height="20">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.5 3H21.999V22.499H2.5V3Z"
                fill={props.color || "white"}
            />
        </mask>
        <g mask="url(#mask0_3196_43009)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.3049 14.8175L14.9619 20.7505C15.1219 21.0105 15.3719 21.0075 15.4729 20.9935C15.5739 20.9795 15.8169 20.9175 15.9049 20.6225L20.4779 5.17753C20.5579 4.90453 20.4109 4.71853 20.3449 4.65253C20.2809 4.58653 20.0979 4.44553 19.8329 4.52053L4.37688 9.04653C4.08388 9.13253 4.01988 9.37853 4.00588 9.47953C3.99188 9.58253 3.98788 9.83753 4.24688 10.0005L10.2479 13.7535L15.5499 8.39553C15.8409 8.10153 16.3159 8.09853 16.6109 8.38953C16.9059 8.68053 16.9079 9.15653 16.6169 9.45053L11.3049 14.8175ZM15.3949 22.4995C14.6989 22.4995 14.0609 22.1455 13.6849 21.5375L9.80788 15.2465L3.45188 11.2715C2.76688 10.8425 2.40888 10.0785 2.51988 9.27553C2.62988 8.47253 3.18088 7.83453 3.95488 7.60753L19.4109 3.08153C20.1219 2.87353 20.8839 3.07053 21.4079 3.59253C21.9319 4.11953 22.1269 4.88953 21.9149 5.60353L17.3419 21.0475C17.1129 21.8245 16.4729 22.3735 15.6719 22.4805C15.5779 22.4925 15.4869 22.4995 15.3949 22.4995Z"
                fill={props.color || "white"}
            />
        </g>
    </svg>
);
