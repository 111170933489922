import { useEffect, memo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { IDivision, IPermission } from "@interfaces";
import {
    StyledSubmitButton,
    StyledCancelButton,
    StyledCard,
    SharedInput,
    ComponentCheckbox,
} from "@components";
import { useYup } from "@validations";
import { setLoading, selectAuth, getAdminGetPermission } from "@redux";
import { useNotify } from "@utils";
import { PATH_PERMISSIONS, PATH_PERMISSIONS_CREATE } from "@configs";
import { adminAPI, permissionAPI } from "@api";

interface IProps {
    permission: IPermission | undefined;
    divisions: IDivision[] | [];
}
const initData: IPermission = {
    organization_id: "",
    name: "",
    description: "",
    permission_list: {
        activity: {
            is_view: false,
            is_manage: false,
        },
        news: {
            is_view: false,
            is_manage: false,
        },
        claim_reward: {
            is_view: false,
            is_manage_claim: false,
            is_manage_reward: false,
        },
        complain: {
            is_view: false,
            is_manage: false,
            approve: false,
            divisions: [],
        },
        service: {
            is_view: false,
            is_manage: false,
            service_departments: [],
        },
        bidding: {
            is_view: false,
            is_manage_bidding: false,
            is_manage_information: false,
        },
        trash_fee: {
            is_view: false,
            is_manage: false,
            is_check: false,
        },
        home_information: {
            is_manage: false,
        },
        organization: {
            is_manage_organization: false,
            is_manage_permission: false,
        },
    },
};

export const ModulePermissionForm = memo((props: IProps) => {
    //hook
    const { YupPermission } = useYup();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { error, success } = useNotify();
    const organization_id = useSelector(selectAuth).auth?.admin_data?.organization_id;
    const adminId = useSelector(selectAuth).auth?.admin_data?._id;
    const pathName = window.location.pathname;
    const validatePermission = Yup.object().shape(YupPermission);
    const { permission } = props;
    useEffect(() => {
        if (permission) {
            setFieldValue("name", permission.name);
            setFieldValue("description", permission.description);
            setFieldValue("permission_list", permission.permission_list);
        }
    }, [permission]);
    const handlePermissionSubmit = async (values: IPermission) => {
        dispatch(setLoading(true));
        try {
            if (pathName === PATH_PERMISSIONS_CREATE) {
                const res = await permissionAPI.createPermission({
                    ...values,
                    ...{ organization_id: organization_id || "" },
                });
                if (res.data.error === "0") {
                    success(res.data.msg);
                    await dispatch(
                        getAdminGetPermission({
                            organization_id: organization_id || "",
                            admin_id: adminId || "",
                        })
                    );
                    history.push(PATH_PERMISSIONS);
                } else {
                    error(res.data.msg);
                }
            } else {
                const res = await permissionAPI.updatePermission({
                    ...values,
                    ...{ organization_id: organization_id || "" },
                    ...{ permission_id: permission?._id },
                });
                if (res.data.error === "1") {
                    error(res.data.msg);
                } else {
                    success(res.data.msg);
                    await dispatch(
                        getAdminGetPermission({
                            organization_id: organization_id || "",
                            admin_id: adminId || "",
                        })
                    );
                    history.push(PATH_PERMISSIONS);
                }
            }
        } catch (err: any) {
            if (err) {
                error(err.msg);
            }
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleCancel = () => {
        history.push(PATH_PERMISSIONS);
    };

    const { values, handleChange, handleBlur, handleSubmit, errors, touched, setFieldValue } =
        useFormik({
            initialValues: initData,
            validationSchema: validatePermission,
            enableReinitialize: true,
            onSubmit: handlePermissionSubmit,
        });
    const handleSelectDivision = (e, index) => {
        setFieldValue(`permission_list.complain.divisions[${index}].is_enable`, e.target.checked);
    };
    return (
        <div className="form-wrap">
            <form onSubmit={handleSubmit}>
                <div className="page-header">
                    <h3>
                        {pathName.includes(PATH_PERMISSIONS_CREATE)
                            ? t("page.create_user_rights")
                            : t("page.create_user_rights")}
                    </h3>
                    <p className="sub-title">{t("page.please_provide_complete_details")}</p>
                </div>
                <div className="page-body">
                    <div className="card-wrap">
                        <StyledCard>
                            <div className="title">{t("page.user_role")}</div>
                            <Row gutter={16}>
                                <Col span={16}>
                                    <SharedInput
                                        label={t("page.name")}
                                        // descLabel={t("page._2_100_characters")}
                                        name="name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.name}
                                        errors={errors.name}
                                        touched={touched.name}
                                        parentClassName="mb-6"
                                    />
                                </Col>
                                <Col span={16}>
                                    <SharedInput
                                        label={t("page.details")}
                                        // descLabel={t("page._2_100_characters")}
                                        name="description"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.description}
                                        errors={errors.description}
                                        touched={touched.description}
                                        parentClassName="mb-6"
                                    />
                                </Col>
                            </Row>
                        </StyledCard>
                    </div>
                    <div className="card-wrap">
                        <StyledCard>
                            <div className="title">{t("page.set_up_user_rights")}</div>
                            <ComponentCheckbox
                                label={t("page.activity")}
                                options={values.permission_list.activity}
                                callbackOnChange={(e) => {
                                    setFieldValue(
                                        `permission_list.activity.${e.target.name}`,
                                        e.target.checked
                                    );
                                }}
                            />
                            <ComponentCheckbox
                                label={t("page.news")}
                                options={values.permission_list.news}
                                callbackOnChange={(e) => {
                                    setFieldValue(
                                        `permission_list.news.${e.target.name}`,
                                        e.target.checked
                                    );
                                }}
                            />
                            <ComponentCheckbox
                                label={t("page.permission_privileges")}
                                options={values.permission_list.claim_reward}
                                callbackOnChange={(e) => {
                                    setFieldValue(
                                        `permission_list.claim_reward.${e.target.name}`,
                                        e.target.checked
                                    );
                                }}
                            />
                            <ComponentCheckbox
                                label={t("page.conplaint")}
                                options={values.permission_list.complain}
                                callbackOnChange={(e) => {
                                    setFieldValue(
                                        `permission_list.complain.${e.target.name}`,
                                        e.target.checked
                                    );
                                }}
                                divisions={values.permission_list.complain.divisions}
                                callbackSelectDivision={handleSelectDivision}
                            />
                            <ComponentCheckbox
                                label={t("page.service_booking")}
                                options={values.permission_list.service}
                                callbackOnChange={(e) => {
                                    setFieldValue(
                                        `permission_list.service.${e.target.name}`,
                                        e.target.checked
                                    );
                                }}
                            />
                            <ComponentCheckbox
                                label={t("page.bidding.bidding_headline")}
                                options={values.permission_list.bidding}
                                callbackOnChange={(e) => {
                                    setFieldValue(
                                        `permission_list.bidding.${e.target.name}`,
                                        e.target.checked
                                    );
                                }}
                            />
                            <ComponentCheckbox
                                label={t("page.bidding_label")}
                                options={values.permission_list.bidding}
                                callbackOnChange={(e) => {
                                    setFieldValue(
                                        `permission_list.trash_fee.${e.target.name}`,
                                        e.target.checked
                                    );
                                }}
                            />
                            <ComponentCheckbox
                                label={t("page.custom_web")}
                                options={values.permission_list.home_information}
                                callbackOnChange={(e) => {
                                    setFieldValue(
                                        `permission_list.home_information.${e.target.name}`,
                                        e.target.checked
                                    );
                                }}
                            />
                            <ComponentCheckbox
                                label={t("page.trash_fee")}
                                options={values.permission_list.trash_fee}
                                callbackOnChange={(e) => {
                                    setFieldValue(
                                        `permission_list.trash_fee.${e.target.name}`,
                                        e.target.checked
                                    );
                                }}
                            />

                            <ComponentCheckbox
                                label={t("page.tessabarn_settings")}
                                options={values.permission_list.organization}
                                callbackOnChange={(e) => {
                                    setFieldValue(
                                        `permission_list.organization.${e.target.name}`,
                                        e.target.checked
                                    );
                                }}
                            />
                        </StyledCard>
                    </div>
                    <div className="btn-layout">
                        <StyledCancelButton
                            type="sub"
                            text={t("page.cancel")}
                            htmlType="button"
                            onClick={handleCancel}
                        />
                        <StyledSubmitButton
                            type="default"
                            text={t("page.save")}
                            htmlType="submit"
                            // disable={isSubmitting}
                        />
                    </div>
                </div>
            </form>
        </div>
    );
});
