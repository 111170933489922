export default {
    delete: {
        success: "ลบสำเร็จ",
        fail: "ลบไม่สำเร็จ",
    },
    duplicate: {
        success: "คัดลอกสำเร็จ",
        fail: "คัดลอกไม่สำเร็จ",
    },
    create: {
        success: "สร้างสำเร็จ",
        fail: "สร้างไม่สำเร็จ",
    },
    update: {
        success: "อัปเดทสำเร็จ",
        fail: "อัปเดทไม่สำเร็จ",
    },
    form_validate: {
        warning: "ข้อมูลในบางช่องไม่ถูกต้อง กรุณาตรวจสอบ!",
    },
    reset_pass: {
        fail: "รีเซ็ทรหัสผ่านไม่สำเร็จ!",
        success: "รีเซ็ทรหัสผ่านสำเร็จ!",
    },
    sign_up: {
        success: "สมัครสมาชิกสำเร็จ!",
        fail: "สมัครสมาชิกไม่สำเร็จ!",
    },
    upload_image: {
        success: "อัปโหลดรูปภาพสำเร็จ!",
        fail: "อัปโหลดรูปภาพไม่สำเร็จ!",
    },
    merge: {
        success: "Merge {{number}} สำเร็จ! ",
    },
    upload_image_big_size: "ขนาดรูปภาพใหญ่เกินไป",
    delete_confirm: "คุณแน่ใจว่าจะลบ {{name}}?",
    exist_email: "อีเมลนี้ถูกใช้งานแล้ว!",
    not_found: "รายการ {{name}} ไม่สามารถค้นหาได้",
    delete_title: "คุณแน่ใจใช่ไหม?",
    try_again_text: "มีข้อผิดพลาดเกิดขึ้น กรุณาลองใหม่อีกครั้ง",
    invalid: "หน้าไม่ถูกต้อง",
    not_permission: "คุณไม่มีสิทธิ์เข้าถึงการใช้งาน",
    error: "มีข้อผิดพลาดเกิดขึ้นที่ระบุไม่ได้ กรุณาลองใหม่อีกครั้ง",
    you_need_login: "คุณต้องลงชื่อเข้าใช้งานเพื่อดำเนินการต่อไป",
    email_send: "อีเมลได้ถูกส่งแล้ว",
    forbidden: "คุณไม่มีสิทธิ์เข้าถึงการใช้งาน",
    copied: "คัดลอกสำเร็จ",
    export_file_fail: "นำไฟล์ออกไม่สำเร็จ",
    copy: "คัดลอก",
    feature_not_available: "ฟีเจอร์นี้ไม่ได้อยู่ใน Plan คุณไม่สามารถเข้าถึงได้",

    export_segment_success: "นำไฟล์ segment ออกสำเร็จ!",
    change_password_fail: "เปลี่ยนรหัสผ่านไม่สำเร็จ!",
    reset_password_fail: "รีเซ็ทรหัสผ่านไม่สำเร็จ!",
    sign_up_fail: "สมัครสมาชิกไม่สำเร็จ!",
    create_account_fail: "สร้างบัญชีไม่สำเร็จ!",
    update_profile_fail: "อัปเดทโปรไฟล์ไม่สำเร็จ!",
    phone_number_invalid: "เบอร์โทรของคุณไม่ถูกต้อง",
    admin_panel_notification_setting_is_turn_off:
        "การแจ้งเตือนผ่าน Admin Panel ถูกปิดใช้งาน กรุณาเปิดใช้งานที่เมนูการตั้งค่าแจ้งเตือน",
    export_list_contact_success: "ส่งออกรายชื่อสมาชิกสำเร็จ!",
    download_file_success: "ดาวน์โหลดไฟล์สำเร็จ!",
    import_successfully: "นำเข้าไฟล์สำเร็จ!",
    congrat_loyalty_program: "Congratultion!, your Loyalty Program has been Successfully!",
    fail_loyalty_program: "Update Loyalty Program fail",
    do_you_want_to_delete: "คุณต้องการลบข้อมูลหรือไม่?",
    please_confirm_to_delete: "กรุณายืนยันการลบ",
    customer_not_found: "Customer Not Found",
    user_not_found: "User not found",
    request_permission: "You will need to give a permission",
};
