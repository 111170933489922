import { useState, useRef, useEffect } from "react";
import {
  ComponentContactSortIcon,
  ComponentRowNews,
  StyledTable,
  ComponentEmptyData,
  StyledTableButtonTop,
  ComponentStatusFilter,
  ComponentTableSearch,
  EditIcon,
  ComponentRowHouse,
  ComponentTableSearchHouse,
  StyledCancelButton,
} from "@components";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  TouchSensor,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
  //@ts-ignore
} from "@dnd-kit/sortable";
import { Row, Col, Pagination, Button, Modal, Input } from "antd";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import {
  enumSortBy,
  enumContactSortFields,
  PAGINATION,
  PAGE_START,
  enumNewsStatus,
  INITIAL_LIST_PARAMS,
  PATH_CREATE_NEWS,
  PATH_TRASH_FEE_HOUSE_CREATE,
} from "@configs";
import { IPaginationNext, INews, IStatusCount, ITrashfeeHouse, ITrashfeeArea } from "@interfaces";
import { useDispatch, useSelector } from "react-redux";
import { getCategories, selectAuth, selectContact, selectOrg, setLoading } from "@redux";
import { newsAPI } from "src/API/news";
import { useNotify, usePermission } from "@utils";
import { useHistory } from "react-router";
import { Grid } from "@mui/material";
import styled from "styled-components";
import { trashfeeAPI } from "@api";
import { CloseOutlined } from "@ant-design/icons";

const INITAL_PAGE = {
  xpage: PAGE_START,
  xlimit: PAGINATION,
};

export const PageHouseList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const search_params = useSelector(selectContact).paramsContact;
  const pemisssion = useSelector(selectAuth).permission;
  const [params, setParams] = useState(search_params);
  const typingTimeoutRef = useRef(0);
  const org_id = useSelector(selectAuth).auth?.organization_data?._id;
  const categories = useSelector(selectOrg).category;
  const [textSearch, setTextSearch] = useState<string>("");

  const [searchDate, setSearchDate] = useState<string[]>([]);
  const [catSearch, setCatSearch] = useState<string>("");
  const [areaSearch, setAreaSearch] = useState<string>("");
  const [status, setStatus] = useState<enumNewsStatus>(enumNewsStatus.ALL);
  const [pagination, setPagination] = useState<IPaginationNext>(INITAL_PAGE);
  const [news, setNews] = useState<INews[]>([]);
  const [houseList, setHouseList] = useState<ITrashfeeHouse[]>([]);
  const [areaList, setAreaList] = useState<ITrashfeeArea[]>([]);
  const [total, setTotal] = useState<number>(0);
  const { success, error } = useNotify();
  const [housePrice, setHousePrice] = useState<number>(0);
  const [housePriceValue, setHousePriceValue] = useState<number>(0);
  const [priceModal, setPriceModal] = useState<boolean>(false);

  const history = useHistory();
  usePermission({ permission: pemisssion?.permission_list.news.is_manage });
  useEffect(() => {
    // fetchNews(pagination, textSearch, searchDate, catSearch, status);
    fetchHouselist(pagination, textSearch, searchDate, areaSearch, status);
  }, [pagination, textSearch, searchDate, areaSearch, status]);

  useEffect(() => {
    fetchAreaList();
    fetchHousePrice();
    // fetchNewsCountStatus();
  }, []);

  const fetchHousePrice = async () => {
    try {
      dispatch(setLoading(true));

      const response = await trashfeeAPI.getHomePrice({
        organization_id: org_id || "",
      });
      if (response.data.error === "0") {
        setHousePrice(response.data.result);
        setHousePriceValue(response.data.result);
      }
      dispatch(setLoading(false));

    } catch {
      dispatch(setLoading(false));
    }
  };
  const fetchAreaList = async () => {
    try {
      dispatch(setLoading(true));
      const payload = {
        organization_id: org_id || "",
        xpage: "1",
      };
      const response = await trashfeeAPI.getAreaList({ ...payload });
      setAreaList(response.data.result);
      dispatch(setLoading(false));
    } catch {
      dispatch(setLoading(false));
    }
  };

  const fetchHouselist = async (
    pagination: IPaginationNext,
    textSearch: string,
    searchDate: string[],
    areaSearch: string,
    status: enumNewsStatus
  ) => {
    dispatch(setLoading(true));
    try {
      const response = await trashfeeAPI.getHouseList({
        ...pagination,
        organization_id: org_id || "",
        house_type: 'home',
        is_verify: true,
        ...(textSearch && { text_search: textSearch }),
      });
      setHouseList(response.data.result);
      setTotal(response.data.total);
    } catch (err: any) {
      error(err.msg);
      dispatch(setLoading(false));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleSort = (sortField: enumContactSortFields, sortBy: enumSortBy) => {
    const newSortParams = { ...params, sortField, sortBy };
    setParams(newSortParams);
    // fetchContacts(newSortParams);
  };

  const updateHouse = (isEnable: boolean, data: ITrashfeeHouse) => {
    //

  };

  const callbackGetList = () => {
    // fetchNews(pagination, textSearch, searchDate, catSearch, status);
    fetchHouselist(pagination, textSearch, searchDate, catSearch, status);
    // fetchNewsCountStatus();
  };
  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const swapItem = (items: ITrashfeeHouse[]) => {
        const oldIndex = items.findIndex((item) => JSON.stringify(item) === active.id);
        const newIndex = items.findIndex((item) => JSON.stringify(item) === over.id);
        return arrayMove(items, oldIndex, newIndex);
      };
      setHouseList(swapItem);
    }
  };
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(TouchSensor)
  );
  const handleSearchCategory = (value) => {
    setCatSearch(value);
  };
  const handleChangeArea = (value) => {
    setAreaSearch(value);
  };
  const onChangeDateRange = (dates, dateStrings: [string, string]) => {
    setSearchDate(dateStrings);
  };
  const handlePagination = (page) => {
    setPagination({ xpage: page, xlimit: pagination.xlimit });
  };

  const changeTrashfee = (e: any) => {
    let price = parseInt(e.target.value);
    if (e.target.value === "") {
      price = 0;
    }
    console.log(price);
    setHousePriceValue(price);
  };

  const changeHousePrice = async () => {
    try {
      dispatch(setLoading(true));
      const response = await trashfeeAPI.updateHouseTrashfee({
        organization_id: org_id || "",
        trash_fee: housePriceValue,
      });
      console.log(response.data);
      if (response.data.error === "0") {
        setHousePrice(housePriceValue);
        //
      } else {
        error(response.data.msg);
      }
    } catch (err: any) {
      error(err.msg);
      setHousePriceValue(housePrice);
    } finally {
      dispatch(setLoading(false));
      setPriceModal(false);
    }
  };
  return (
    <StyledTable>
      <div className="page-layout">
        <div className="page-header-layout">
          <div className="page-header">
            <div>
              <h3>รายการทะเบียนประเภทครัวเรือน</h3>
            </div>
            <div className="flex-header">
              <StyledTableButtonTop
                type="danger"
                size="default"
                text="เพิ่มรายชื่อทะเบียนประเภทครัวเรือน"
                onClick={() => history.push(PATH_TRASH_FEE_HOUSE_CREATE)}
                className="add-btn"
                icon="plus"
              />
            </div>
          </div>
          <br />
          <br />
          <div className="search-layout">
            <Grid container spacing={2}>
              <Grid item md={8}>
                <ComponentTableSearchHouse
                  setTextSearch={setTextSearch}
                  handleSearchCategory={handleSearchCategory}
                  categories={categories}
                  areaList={areaList}
                  handleChangeArea={handleChangeArea}
                  onChangeDateRange={onChangeDateRange}
                  total={total}
                  isRangePicker={true}
                />
              </Grid>
              <Grid item md={4}>
                <StyledPriceSection>
                  <div className="price-section">
                    <div className="price-item">
                      รวมค่าขยะครัวเรือนละ : <span className="price-number">{housePrice}</span> บาท &#160;
                    </div>
                    <Button className="price-btn" onClick={() => setPriceModal(true)}>
                      <EditIcon /> &#160;
                      แก้ไข
                    </Button>

                  </div>
                </StyledPriceSection>
              </Grid>

            </Grid>
          </div>
        </div>
        <div className="page-table-layout">
          <div className="table">
            <ScrollContainer vertical={false}>
              <div className="table_ground">
                <div className="table-body">
                  <div className="table-header">
                    <Row
                      gutter={24}
                      type="flex"
                      justify="space-between"
                      align="middle"
                      className="header-row"
                    >
                      <Col className="col-item" span={3}>
                        <p>เลขที่ทะเบียนบ้าน</p>
                        <div className="filter-button">
                          <ComponentContactSortIcon
                            handleSort={handleSort}
                            sortBy={enumSortBy.ASC}
                            sortField={enumContactSortFields.FULL_NAME}
                            searchParams={params}
                          />
                          <ComponentContactSortIcon
                            handleSort={handleSort}
                            sortBy={enumSortBy.DESC}
                            sortField={enumContactSortFields.FULL_NAME}
                            searchParams={params}
                          />
                        </div>
                      </Col>
                      <Col className="col-item" span={4}>
                        <p>รายชื่อเจ้าของทะเบียนบ้าน</p>
                        <div className="filter-button">
                          <ComponentContactSortIcon
                            handleSort={handleSort}
                            sortBy={enumSortBy.ASC}
                            sortField={enumContactSortFields.TEL}
                            searchParams={params}
                          />
                          <ComponentContactSortIcon
                            handleSort={handleSort}
                            sortBy={enumSortBy.DESC}
                            sortField={enumContactSortFields.TEL}
                            searchParams={params}
                          />
                        </div>
                      </Col>
                      <Col className="col-item" span={3}>
                        <p>เลขบัตรประชาชน</p>
                        <div className="filter-button">
                          <ComponentContactSortIcon
                            handleSort={handleSort}
                            sortBy={enumSortBy.ASC}
                            sortField={enumContactSortFields.POINTS}
                            searchParams={params}
                          />
                          <ComponentContactSortIcon
                            handleSort={handleSort}
                            sortBy={enumSortBy.DESC}
                            sortField={enumContactSortFields.POINTS}
                            searchParams={params}
                          />
                        </div>
                      </Col>
                      <Col className="col-item" span={3}>
                        <p>หมวดพื้นที่</p>
                        <div className="filter-button">
                          <ComponentContactSortIcon
                            handleSort={handleSort}
                            sortBy={enumSortBy.ASC}
                            sortField={enumContactSortFields.POINTS}
                            searchParams={params}
                          />
                          <ComponentContactSortIcon
                            handleSort={handleSort}
                            sortBy={enumSortBy.DESC}
                            sortField={enumContactSortFields.POINTS}
                            searchParams={params}
                          />
                        </div>
                      </Col>
                      <Col className="col-item" span={6}>
                        <p>ที่อยู่</p>
                        <div className="filter-button">
                          <ComponentContactSortIcon
                            handleSort={handleSort}
                            sortBy={enumSortBy.ASC}
                            sortField={enumContactSortFields.CREATED_AT}
                            searchParams={params}
                          />
                          <ComponentContactSortIcon
                            handleSort={handleSort}
                            sortBy={enumSortBy.DESC}
                            sortField={enumContactSortFields.CREATED_AT}
                            searchParams={params}
                          />
                        </div>
                      </Col>
                      <Col className="col-item" span={2}>
                        <p>{t("page.news_manage")}</p>
                      </Col>
                    </Row>
                  </div>
                  <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                  >
                    <SortableContext
                      items={houseList.map((item) => JSON.stringify(item))}
                      strategy={rectSortingStrategy}
                    >
                      {houseList && houseList.length > 0 ? (
                        <div className="data-table">
                          {houseList.map((item, index) => (
                            <ComponentRowHouse
                              key={index}
                              data={item}
                              itemJson={JSON.stringify(item)}
                              categories={categories}
                              handleEdit={updateHouse}
                              callbackGetList={callbackGetList}
                              areaList={areaList}
                              index={index}
                            />
                          ))}
                          <div
                            style={{ width: "100%", height: 10 }}
                          ></div>
                        </div>
                      ) : (
                        <ComponentEmptyData />
                      )}
                    </SortableContext>
                  </DndContext>
                </div>
              </div>
            </ScrollContainer>
          </div>
        </div>
        <div className="page-bottom">
          {total > 0 && (
            <div className="pagination">
              <Pagination
                defaultCurrent={INITIAL_LIST_PARAMS.page || 1}
                pageSize={PAGINATION}
                total={total}
                onChange={handlePagination}
                current={pagination.xpage}
              />
            </div>
          )}
        </div>
      </div>
      <Modal
        visible={priceModal}
        closable={false}
        footer={null}
        width={400}
        onCancel={() => {
          setPriceModal(false);
          setHousePriceValue(housePrice);
          // setCreateStatus(0);
        }}
      >

        <StyledModal>
          <div className="close-icon"
            onClick={() => {
              setPriceModal(false);
              setHousePriceValue(housePrice);
            }}
          >
            <CloseOutlined />
          </div>
          <StyledButton>
            <p className="title">เฉลี่ยค่าขยะครัวเรือนละ</p>
            <Input
              suffix="บาท"
              className="price-input"
              value={housePriceValue}
              onChange={changeTrashfee}
            />
            <Button
              className="confirm-create"
              onClick={changeHousePrice}
            >
              บันทึก
            </Button>
          </StyledButton>

        </StyledModal>

      </Modal>
    </StyledTable>
  );
};

const StyledPriceSection = styled.div`
  .price-section{
    background-color: white;
    padding: 20px;
    border-radius: 4px;
    margin-bottom: 26px;
    height: 88px;
    display: flex;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    justify-content: space-between;
    .price-number {
      color: #0D6EFD;
      font-size:20px;
    }
    .price-item {
      margin: auto; 
    }
    .price-btn {
      height: 44px;
    }
  }
`;

const StyledModal = styled.div`
    .close-icon {
      font-size: 20px;
      position: absolute;
      right: 15px;
      top: 15px;
      cursor: pointer;
    }
    .title {
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        text-align: center;
        color: #000000;
        margin-bottom: 20px;
    }
`;

const StyledButton = styled.div`
  font-size: 25px;
  text-align: center;

  .confirm-create {
    width: 170px;
    height: 49px;
    background: #0D6EFD;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-top: 40px;
  }
  .price-input {
    height: 48px;
  }
`;