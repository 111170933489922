import { Drawer, Icon, Select } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useEffect } from "react";
import { Formik, Form, FieldArray } from "formik";
import { useYup } from "@validations";
import { SharedInput, StyledSubmitButton, StyledCancelButton, MinutesFilled } from "@components";
import { IConplainCategoryParams, IConplaintCategory } from "@interfaces";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getConplaintCategories, selectAuth, setLoading, selectOrg } from "@redux";
import { orgApi } from "@api";
import { useState } from "react";
import { useNotify } from "@utils";
const { Option } = Select;
interface Iprops {
    isVisible: boolean;
    onClose: () => void;
    editItem: IConplaintCategory | undefined;
}

const formData: IConplainCategoryParams = {
    organization_id: "",
    category_name: "",
    is_enable: true,
    divisions: [],
};

export const ModuleCategoryDrawerForm = (props: Iprops) => {
    const { isVisible, onClose, editItem } = props;
    const { t } = useTranslation();
    const { YupComplaintCategory } = useYup();
    const createComplaintCategory = Yup.object().shape(YupComplaintCategory);
    const organization_id = useSelector(selectAuth).auth?.organization_data?._id;
    const adminId = useSelector(selectAuth).auth?.admin_data?._id;
    const divisions = useSelector(selectOrg).division;
    const dispatch = useDispatch();
    const { success, error } = useNotify();
    const [initalData, setInitialData] = useState(formData);
    useEffect(() => {
        if (editItem === undefined) {
            setInitialData(formData);
        } else {
            setInitialData({
                organization_id: "",
                category_name: editItem.category_name,
                is_enable: editItem.is_enable,
                divisions: editItem.divisions,
            });
        }
    }, [editItem]);

    const handleCategorySubmit = async (values) => {
        dispatch(setLoading(true));
        try {
            if (editItem !== undefined) {
                const { data } = await orgApi.updateConplainCat({
                    ...values,
                    organization_id: organization_id || "",
                    complain_category_id: editItem._id,
                });
                if (data.error === "0") {
                    success(data.msg);
                    await dispatch(
                        getConplaintCategories({
                            organization_id: organization_id || "",
                            admin_id: adminId || "",
                        })
                    );
                    dispatch(setLoading(false));
                    onClose();
                } else {
                    error(data.msg);
                    dispatch(setLoading(false));
                }
            } else {
                const { data } = await orgApi.createConplainCat({
                    ...values,
                    organization_id: organization_id || "",
                });
                if (data.error === "0") {
                    success(data.msg);
                    await dispatch(
                        getConplaintCategories({
                            organization_id: organization_id || "",
                            admin_id: adminId || "",
                        })
                    );
                    dispatch(setLoading(false));
                    onClose();
                } else {
                    error(data.msg);
                    dispatch(setLoading(false));
                }
            }
        } catch (err: any) {
            dispatch(setLoading(false));
            onClose();
        }
    };
    const handleClose = () => {
        // setInitialData(formData);
        onClose();
    };
    return (
        <StyledDrawer
            width={538}
            visible={isVisible}
            onClose={handleClose}
            title={editItem ? t("page.edit_category") : t("page.add_category")}
        >
            <Formik
                initialValues={initalData}
                onSubmit={handleCategorySubmit}
                enableReinitialize={true}
                validationSchema={createComplaintCategory}
            >
                {({ values, touched, handleChange, handleBlur, setFieldValue, errors }) => (
                    <Form>
                        <SharedInput
                            label={t("page.complaint_subject")}
                            // descLabel={t("page._2_100_characters")}
                            name="category_name"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.category_name}
                            errors={errors.category_name}
                            touched={touched.category_name}
                            parentClassName="mb-6"
                        />
                        <FieldArray name="divisions">
                            {({ remove, push }) => (
                                <div className="division">
                                    <div className="label">{t("page.relevant_division")}</div>
                                    <div className="division-group">
                                        {values.divisions.length > 0 &&
                                            values.divisions.map((item, index) => (
                                                <div className="select-layout" key={index}>
                                                    <div className="input-field">
                                                        <Select
                                                            style={{ width: "100%", height: 48 }}
                                                            placeholder={t(
                                                                "page.relevant_division"
                                                            )}
                                                            onChange={(value) => {
                                                                const selectDivisions =
                                                                    values.divisions.filter(
                                                                        (value) => value !== ""
                                                                    );

                                                                setFieldValue("divisions", [
                                                                    ...selectDivisions,
                                                                    value,
                                                                ]);
                                                            }}
                                                            defaultActiveFirstOption={true}
                                                            value={values.divisions[index]}
                                                        >
                                                            {divisions &&
                                                                divisions.map((item, index) => {
                                                                    return (
                                                                        <Option
                                                                            key={index}
                                                                            value={item._id}
                                                                        >
                                                                            {item.division_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                        </Select>
                                                        <div className="error"></div>
                                                    </div>
                                                    <div
                                                        className={"remove-layout"}
                                                        onClick={() => remove(index)}
                                                    >
                                                        <MinutesFilled />
                                                    </div>
                                                </div>
                                            ))}
                                        <div className="select-layout">
                                            <div className="input-field">
                                                <Select
                                                    style={{ width: "100%", height: 48 }}
                                                    placeholder={t("page.relevant_division")}
                                                    onChange={(value) =>
                                                        setFieldValue("divisions", [
                                                            ...values.divisions,
                                                            value,
                                                        ])
                                                    }
                                                    defaultActiveFirstOption={true}
                                                    value={""}
                                                >
                                                    {divisions &&
                                                        divisions.map((item, index) => {
                                                            return (
                                                                <Option
                                                                    key={index}
                                                                    value={item._id}
                                                                >
                                                                    {item.division_name}
                                                                </Option>
                                                            );
                                                        })}
                                                </Select>
                                                <div className="error"></div>
                                            </div>
                                            <div className="remove-layout disable">
                                                <MinutesFilled />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="add-new"
                                        onClick={() =>
                                            setFieldValue("divisions", [...values.divisions, ""])
                                        }
                                    >
                                        <Icon type="plus" style={{ color: "#0D6EFD" }} />{" "}
                                        {t("page.add_unit")}
                                    </div>
                                </div>
                            )}
                        </FieldArray>
                        <div className="btn-layout">
                            <StyledSubmitButton
                                type="default"
                                text={t("page.save")}
                                htmlType="submit"
                                // disable={isSubmitting}
                            />
                            <StyledCancelButton
                                type="sub"
                                text={t("page.cancel")}
                                htmlType="button"
                                onClick={onClose}
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </StyledDrawer>
    );
};
const StyledDrawer = styled(Drawer)`
    .btn-layout {
        position: fixed;
        bottom: 14px;
    }
    .add-new {
        display: flex;
        align-items: center;
        column-gap: 8px;
        color: ${(p) => p.theme.colors.main};
        padding: 4px;
        margin-top: 16px;
        cursor: pointer;
    }
    .division {
        .label {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 21px;
            color: #000000;
            margin-bottom: 20px;
        }
        .division-group {
            display: flex;
            flex-direction: column;
            row-gap: 32px;
            .select-layout {
                display: flex;
                align-items: center;
                .input-field {
                    flex: 14;
                }
                .remove-layout {
                    flex: 1;
                    display: flex;
                    justify-content: end;
                    &.disable {
                        opacity: 0.3;
                    }
                }
            }
        }
    }
`;
