import { ISvg } from "@interfaces";
export const IconArrowUp = (props: ISvg) => {
    return (
        <svg
            width="94"
            height="24"
            viewBox="0 0 94 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M44.7537 4.88759L53.1767 4.88759C53.6936 4.88759 54.1126 5.3066 54.1126 5.82347L54.1126 14.2465C54.1126 14.7633 53.6936 15.1823 53.1767 15.1823C52.6598 15.1823 52.2408 14.7633 52.2408 14.2465L52.2408 8.0829L41.4854 18.8383C41.1199 19.2038 40.5273 19.2038 40.1618 18.8383C39.7964 18.4728 39.7964 17.8803 40.1618 17.5148L50.9173 6.75936L44.7537 6.75936C44.2368 6.75936 43.8178 6.34035 43.8178 5.82347C43.8178 5.3066 44.2368 4.88758 44.7537 4.88759Z"
                fill={props.color || "#0263E0"}
            />
        </svg>
    );
};
