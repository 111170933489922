import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { StyledNewDetail } from "@components";
import { IActivity } from "@interfaces";
import { PATH_ACTIVITY_CREATE } from "@configs";
import { ModuleActivityForm } from "@modules";
import { useNotify, usePermission } from "@utils";
import { activityApi } from "@api";
import { setLoading, selectAuth, selectOrg, getActivityCategory } from "@redux";

export const PageActivityCreate = () => {
    const { id } = useParams<{ id: string }>();
    const organization_id = useSelector(selectAuth).auth?.organization_data?._id;
    const activityCategory = useSelector(selectOrg).activityCategory;
    const permisssion = useSelector(selectAuth).permission;
    const dispatch = useDispatch();
    const history = useHistory();
    const { warning } = useNotify();
    // const { t } = useTranslation();
    // page state
    const [activity, setActivity] = useState<IActivity>();
    // variable
    const pathName = history.location.pathname;
    usePermission({ permission: permisssion?.permission_list?.activity.is_manage });

    useEffect(() => {
        fetchActivityCategory();
        fetchActivity();
    }, []);
    const fetchActivityCategory = async () => {
        await dispatch(getActivityCategory({ organization_id: organization_id || "" }));
    };
    const fetchActivity = async () => {
        if (pathName !== PATH_ACTIVITY_CREATE) {
            dispatch(setLoading(true));
            try {
                const response = await activityApi.getActivity({
                    organization_id: organization_id || "",
                    activity_id: id,
                });
                const data: IActivity = response.data.result;
                setActivity(data);
                dispatch(setLoading(false));
            } catch (err: any) {
                if (err) {
                    warning(err.msg);
                    dispatch(setLoading(false));
                }
            } finally {
                dispatch(setLoading(false));
            }
        }
    };

    return (
        <StyledNewDetail>
            {<ModuleActivityForm activity={activity} categories={activityCategory} />}
        </StyledNewDetail>
    );
};
