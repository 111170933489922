import { useTranslation } from "react-i18next";
import { Form, Spin } from "antd";
import * as Yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
    SharedSocialLine,
    SharedInput,
    StyledLogin,
    IconLoadingPage,
    AuthButton,
} from "@components";
import { ILineLogin, ILineState, ILineSignup, ILoginAdmin } from "@interfaces";
import {
    enumExternalAuthType,
    enumExternalMethod,
    LINE_AUTH_URL,
    PATH_ACCOUNTS,
    PATH_FORGOT_PASSWORD,
    PATH_HOME,
    PATH_SIGN_UP,
} from "@configs";
import {
    lineLogin,
    lineSignup,
    loginAdmin,
    logout,
    selectAuth,
    setRemember,
    setLoading,
    selectApp,
} from "@redux";
import { LineApi } from "@api";
import { showErrorMessage, theme, useNotify } from "@utils";
import { IExternalAuthState } from "@interfaces";
import { ModuleLine } from "@modules";
const initialData: ILoginAdmin = {
    username: "",
    password: "",
};

const state: IExternalAuthState = {
    type: enumExternalAuthType.LOGIN,
};

export const PageLogin = () => {
    //page Hooks
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { success, error } = useNotify();
    //redux state
    const { isRemember, line, externalLogin } = useSelector(selectAuth);
    // state
    // const [isLoading, setIsLoading] = useState<boolean>(false);
    const { loading } = useSelector(selectApp);
    //page variable
    const loginSchema = Yup.object().shape({
        username: Yup.string()
            .required(
                t("validation.required", {
                    returnObjects: true,
                    name: t("page.email"),
                })
            )
            // .email(t("validation.email_invalid"))
            .max(
                255,
                t("validation.max_length", {
                    returnObjects: true,
                    name: t("page.email"),
                })
            ),
        password: Yup.string()
            .required(
                t("validation.required", {
                    returnObjects: true,
                    name: t("page.password"),
                })
            )
            .min(
                5,
                t("validation.min", {
                    returnObjects: true,
                    name: t("page.password"),
                    number: 5,
                })
            ),
    });

    useEffect(() => {
        if (externalLogin === enumExternalMethod.LINE) {
            externalLine();
        }
    }, []);

    const handleMoveForgotPassword = useCallback(() => {
        history.push(PATH_FORGOT_PASSWORD);
    }, []);

    const handleChangeRemember = useCallback(() => {
        dispatch(setRemember(!isRemember));
    }, [isRemember]);

    const handleLogin = async (
        values: ILoginAdmin,
        { setSubmitting, setErrors }: FormikHelpers<ILoginAdmin>
    ) => {
        dispatch(setLoading(true));
        try {
            //WHAT: CALL API LOGIN
            const res = await dispatch(loginAdmin(values));
            //WHAT: wrap function in create async thunk
            //@ts-ignore
            unwrapResult(res);
            //@ts-ignore
            if (res.payload.error === "0") {
                dispatch(setLoading(false));
                success(t("page.login_successfully"));
                history.push(PATH_ACCOUNTS);
            } else {
                //@ts-ignore
                error(res.payload.msg);
            }
        } catch (err: any) {
        } finally {
            setSubmitting(false);
            dispatch(setLoading(false));
        }
    };

    const externalLine = async () => {
        const lineLoginApi = LineApi(LINE_AUTH_URL);
        try {
            if (line?.code) {
                const lineState = JSON.parse(line?.state) as ILineState;
                if (lineState.type === enumExternalAuthType.LOGIN) {
                    const lineAccessToken = await lineLoginApi.login(line?.code);
                    if (!lineAccessToken.data) {
                        dispatch(logout());
                    }
                    const lineLoginData: ILineLogin = {
                        token: lineAccessToken.data.access_token,
                        refreshToken: lineAccessToken.data.refresh_token,
                        tokenId: lineAccessToken.data.id_token,
                    };
                    const res = await dispatch(lineLogin(lineLoginData));
                    //WHAT: wrap function in create async thunk
                    //@ts-ignore
                    unwrapResult(res);

                    success(t("page.login_successfully"));
                    history.push(PATH_ACCOUNTS);
                    //@ts-ignore
                    if (res.data.x_token) {
                        success(t("page.login_successfully"));
                        history.push(PATH_ACCOUNTS);
                    } else {
                        error(t("page.email_password_correct"));
                    }
                } else if (lineState.type === enumExternalAuthType.SIGNUP) {
                    const lineAccessToken = await lineLoginApi.login(line?.code);
                    if (!lineAccessToken.data) {
                        dispatch(logout());
                    }

                    const lineSignupData: ILineSignup = {
                        token: lineAccessToken.data.access_token,
                        refreshToken: lineAccessToken.data.refresh_token,
                        tokenId: lineAccessToken.data.id_token,
                        inviteId: lineState?.inviteId,
                    };

                    const res = await dispatch(lineSignup(lineSignupData)); // 400
                    //WHAT: wrap function in create async thunk
                    //@ts-ignore
                    unwrapResult(res);
                    success(t("page.login_successfully"));
                    history.push(PATH_HOME);
                    //@ts-ignore
                    if (res.payload.accessToken) {
                        success(t("page.login_successfully"));
                        history.push(PATH_HOME);
                    } else {
                        error(t("page.email_password_correct"));
                    }
                    //@ts-ignore
                    // if (!res.data.x_token) {
                    //     error(t("page.email_password_correct"));
                    // } else {
                    //     success(t("page.login_successfully"));
                    // }
                } else {
                    dispatch(logout());
                }
            } else {
                dispatch(logout());
            }
        } catch (err) {
            errorHandler(err);
        }
    };

    const errorHandler = (err: any) => {
        //handle Error
        if (err.response) {
            const { status } = err.response;
            if (status === 200 || status === 201) {
                success(t("page.login_successfully"));
            } else {
                error(showErrorMessage(err.response)); // 500 400
            }
        }
    };
    // formik
    const { values, handleChange, handleBlur, handleSubmit, isSubmitting, errors, touched } =
        useFormik({
            initialValues: initialData,
            validationSchema: loginSchema,
            onSubmit: handleLogin,
        });

    return (
        <>
            <Spin
                style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                spinning={loading}
                size="large"
                indicator={<IconLoadingPage />}
            >
                <StyledLogin>
                    <StyledLeftLogin>
                        <img src="/logo64.png" />
                        <p>{t("page.sanan_rak_municipality_office")}</p>
                        <div className="cross-line" />
                        <h4>{t("page.e_teesaban")}</h4>
                    </StyledLeftLogin>
                    <div className="login-layout">
                        <div className="login-container">
                            <div className="login-header">
                                <img src="/logo120.png" alt="Tessabarn logo" />
                                <h3>{t("page.login")}</h3>
                                <p>{t("page.login_des")}</p>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <Form.Item
                                    colon={false}
                                    label={t("page.user_account")}
                                    className="main-color"
                                >
                                    <SharedInput
                                        name="username"
                                        style={{ width: "100%" }}
                                        onChange={handleChange}
                                        value={values.username}
                                        onBlur={handleBlur}
                                        errors={errors.username}
                                        touched={touched.username}
                                        placeholder={t("page.email_id_card")}
                                    />
                                </Form.Item>
                                <Form.Item
                                    colon={false}
                                    label={t("page.password")}
                                    className="main-color"
                                >
                                    <SharedInput
                                        name="password"
                                        style={{ width: "100%" }}
                                        type="password"
                                        onChange={handleChange}
                                        value={values.password}
                                        onBlur={handleBlur}
                                        errors={errors.password}
                                        touched={touched.password}
                                        placeholder={t("page.enter_password")}
                                    />
                                </Form.Item>
                                <div className="flex-layout">
                                    <div className="remember">
                                        <input
                                            style={{
                                                height: "initial",
                                                marginRight: "8px",
                                                position: "relative",
                                                top: "2px",
                                            }}
                                            // checked={remember}
                                            onClick={handleChangeRemember}
                                            type="checkbox"
                                            id="remember"
                                        />
                                        <label className="label__remember" htmlFor="remember">
                                            {t("page.remember_me")}
                                        </label>
                                    </div>
                                    <div className="forgot">
                                        {t("page.forgot")}
                                        <span onClick={handleMoveForgotPassword}>
                                            {t("page.password")}?
                                        </span>
                                    </div>
                                </div>
                                <AuthButton
                                    type="primary"
                                    size="large"
                                    text={t("page.login")}
                                    htmlType="submit"
                                    disable={isSubmitting}
                                />
                            </form>
                            {/* <SharedSocialLine /> */}
                            {/* <ModuleLine state={state} /> */}
                            {/* <div className="create">
                                <span className="need-account">{t("page.need_account")}</span>
                                <Link to={PATH_SIGN_UP}>{t("page.free_sign_up")}</Link>
                            </div> */}
                        </div>
                    </div>
                </StyledLogin>
            </Spin>
        </>
    );
};
const StyledLeftLogin = styled.div`
    flex: 3;
    background: ${theme.colors.main};
    height: 100vh;
    position: relative;
    background-image: url("images/meeting-hall.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 80px 0;
    p,
    h4 {
        margin: 0;
    }
    .cross-line {
        border-bottom: 1.5px solid #ffffff;
        height: 1px;
        width: 84px;
        padding-top: 20px;
    }
    p {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #ffffff;
    }
    h4 {
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 53px;
        text-align: center;
        color: #fff;
    }
    @media (max-width: ${(p) => p.theme.breakPoints.breakOnlyMobile}) {
        display: none;
    }
`;
