import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { IResult } from "@interfaces";
import { ModuleBiddingResultForm } from "@modules";
import { useNotify, usePermission } from "@utils";
import { biddingAPI } from "@api";
import { setLoading, selectAuth } from "@redux";
import { StyledBiddingDetail } from "@components";

export const PageBiddingResultDetail = () => {
    const { id } = useParams<{ id: string }>();
    const org_id = useSelector(selectAuth).auth?.organization_data?._id;
    const permisssion = useSelector(selectAuth).permission;
    const dispatch = useDispatch();
    const { warning } = useNotify();
    const { t } = useTranslation();

    const [result, setResult] = useState<IResult>();

    usePermission({ permission: permisssion?.permission_list.bidding.is_manage_bidding });
    useEffect(() => {
        fetchBidding();
    }, []);

    const fetchBidding = async () => {
        try {
            dispatch(setLoading(true));
            const response = await biddingAPI.resultDetail({
                organization_id: org_id || "",
                information_id: id,
            });
            const data: IResult = response.data.result;
            setResult(data);
            dispatch(setLoading(false));
        } catch (err: any) {
            if (err) {
                warning(err.msg);
                dispatch(setLoading(false));
            }
        } finally {
            dispatch(setLoading(false));
        }
    };

    return <StyledBiddingDetail>{<ModuleBiddingResultForm result={result} />}</StyledBiddingDetail>;
};
