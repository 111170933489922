export const MemberScanIcon = () => (
    <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="18.9775" y="1.15479" width="43.0447" height="77.6904" rx="5" fill="#E1E1E1" />
        <rect x="21.0771" y="4.30444" width="38.8452" height="72.441" rx="3" fill="white" />
        <rect x="33.9775" y="7.52148" width="2" height="2" rx="1" fill="#E1E1E1" />
        <rect x="36.9775" y="7.52148" width="10" height="2" rx="1" fill="#E1E1E1" />
        <path
            d="M50.9848 25.5215H53.6836C54.7882 25.5215 55.6836 26.4169 55.6836 27.5215V30.2203"
            stroke="#F22F46"
            strokeLinecap="round"
        />
        <path
            d="M50.9848 55.5215H53.6836C54.7882 55.5215 55.6836 54.6261 55.6836 53.5215V50.8227"
            stroke="#F22F46"
            strokeLinecap="round"
        />
        <path
            d="M30.7437 25.5215H28.0449C26.9404 25.5215 26.0449 26.4169 26.0449 27.5215V30.2203"
            stroke="#F22F46"
            strokeLinecap="round"
        />
        <path
            d="M30.7437 55.5215H28.0449C26.9404 55.5215 26.0449 54.6261 26.0449 53.5215V50.8227"
            stroke="#F22F46"
            strokeLinecap="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.0051 30.2528H32.5076C31.5514 30.2528 30.7763 31.028 30.7763 31.9842V36.4817C30.7763 37.4379 31.5514 38.213 32.5076 38.213H37.0051C37.9613 38.213 38.7365 37.4379 38.7365 36.4817V31.9842C38.7365 31.028 37.9613 30.2528 37.0051 30.2528ZM32.5076 28.5215C30.5952 28.5215 29.0449 30.0718 29.0449 31.9842V36.4817C29.0449 38.394 30.5952 39.9443 32.5076 39.9443H37.0051C38.9175 39.9443 40.4678 38.394 40.4678 36.4817V31.9842C40.4678 30.0718 38.9175 28.5215 37.0051 28.5215H32.5076Z"
            fill="#A5A5A5"
        />
        <path
            d="M32.3086 32.9396C32.3086 32.3022 32.8254 31.7854 33.4628 31.7854H36.0499C36.6874 31.7854 37.2041 32.3022 37.2041 32.9396V35.5267C37.2041 36.1641 36.6874 36.6809 36.0499 36.6809H33.4628C32.8254 36.6809 32.3086 36.1641 32.3086 35.5267V32.9396Z"
            fill="#A5A5A5"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M49.5823 30.2528H45.0848C44.1286 30.2528 43.3534 31.028 43.3534 31.9842V36.4817C43.3534 37.4379 44.1286 38.213 45.0848 38.213H49.5823C50.5385 38.213 51.3136 37.4379 51.3136 36.4817V31.9842C51.3136 31.028 50.5385 30.2528 49.5823 30.2528ZM45.0848 28.5215C43.1724 28.5215 41.6221 30.0718 41.6221 31.9842V36.4817C41.6221 38.394 43.1724 39.9443 45.0848 39.9443H49.5823C51.4947 39.9443 53.045 38.394 53.045 36.4817V31.9842C53.045 30.0718 51.4947 28.5215 49.5823 28.5215H45.0848Z"
            fill="#A5A5A5"
        />
        <path
            d="M44.8857 32.9396C44.8857 32.3022 45.4025 31.7854 46.04 31.7854H48.627C49.2645 31.7854 49.7813 32.3022 49.7813 32.9396V35.5267C49.7813 36.1641 49.2645 36.6809 48.627 36.6809H46.04C45.4025 36.6809 44.8857 36.1641 44.8857 35.5267V32.9396Z"
            fill="#A5A5A5"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.0051 42.83H32.5076C31.5514 42.83 30.7763 43.6051 30.7763 44.5613V49.0588C30.7763 50.015 31.5514 50.7901 32.5076 50.7901H37.0051C37.9613 50.7901 38.7365 50.015 38.7365 49.0588V44.5613C38.7365 43.6051 37.9613 42.83 37.0051 42.83ZM32.5076 41.0986C30.5952 41.0986 29.0449 42.6489 29.0449 44.5613V49.0588C29.0449 50.9712 30.5952 52.5215 32.5076 52.5215H37.0051C38.9175 52.5215 40.4678 50.9712 40.4678 49.0588V44.5613C40.4678 42.6489 38.9175 41.0986 37.0051 41.0986H32.5076Z"
            fill="#A5A5A5"
        />
        <path
            d="M32.3086 45.5168C32.3086 44.8793 32.8254 44.3625 33.4628 44.3625H36.0499C36.6874 44.3625 37.2041 44.8793 37.2041 45.5168V48.1038C37.2041 48.7413 36.6874 49.2581 36.0499 49.2581H33.4628C32.8254 49.2581 32.3086 48.7413 32.3086 48.1038V45.5168Z"
            fill="#A5A5A5"
        />
        <path
            d="M42.4277 43.059C42.4277 42.4216 42.9445 41.9048 43.582 41.9048H44.1108C44.7483 41.9048 45.2651 42.4215 45.2651 43.059V43.5879C45.2651 44.2253 44.7483 44.7421 44.1108 44.7421H43.582C42.9445 44.7421 42.4277 44.2253 42.4277 43.5879V43.059Z"
            fill="#A5A5A5"
        />
        <path
            d="M42.4277 46.6052C42.4277 45.9677 42.9445 45.4509 43.582 45.4509H44.1108C44.7483 45.4509 45.2651 45.9677 45.2651 46.6052V47.134C45.2651 47.7715 44.7483 48.2882 44.1108 48.2882H43.582C42.9445 48.2882 42.4277 47.7715 42.4277 47.134V46.6052Z"
            fill="#A5A5A5"
        />
        <path
            d="M42.4277 50.1518C42.4277 49.5143 42.9445 48.9976 43.582 48.9976H44.1108C44.7483 48.9976 45.2651 49.5143 45.2651 50.1518V50.6806C45.2651 51.3181 44.7483 51.8349 44.1108 51.8349H43.582C42.9445 51.8349 42.4277 51.3181 42.4277 50.6806V50.1518Z"
            fill="#A5A5A5"
        />
        <path
            d="M45.9746 43.059C45.9746 42.4216 46.4914 41.9048 47.1288 41.9048H47.6577C48.2952 41.9048 48.8119 42.4215 48.8119 43.059V43.5879C48.8119 44.2253 48.2952 44.7421 47.6577 44.7421H47.1288C46.4914 44.7421 45.9746 44.2253 45.9746 43.5879V43.059Z"
            fill="#A5A5A5"
        />
        <path
            d="M45.9746 46.6053C45.9746 45.9678 46.4914 45.451 47.1288 45.451H47.6577C48.2952 45.451 48.8119 45.9678 48.8119 46.6053V47.1341C48.8119 47.7716 48.2952 48.2884 47.6577 48.2884H47.1288C46.4914 48.2884 45.9746 47.7716 45.9746 47.1341V46.6053Z"
            fill="#A5A5A5"
        />
        <path
            d="M45.9746 50.1518C45.9746 49.5143 46.4914 48.9976 47.1288 48.9976H47.6577C48.2952 48.9976 48.8119 49.5143 48.8119 50.1518V50.6806C48.8119 51.3181 48.2952 51.8349 47.6577 51.8349H47.1288C46.4914 51.8349 45.9746 51.3181 45.9746 50.6806V50.1518Z"
            fill="#A5A5A5"
        />
        <path
            d="M49.5205 43.059C49.5205 42.4216 50.0373 41.9048 50.6747 41.9048H51.2036C51.8411 41.9048 52.3578 42.4215 52.3578 43.059V43.5879C52.3578 44.2253 51.8411 44.7421 51.2036 44.7421H50.6747C50.0373 44.7421 49.5205 44.2253 49.5205 43.5879V43.059Z"
            fill="#A5A5A5"
        />
        <path
            d="M49.5205 46.6052C49.5205 45.9677 50.0373 45.4509 50.6747 45.4509H51.2036C51.8411 45.4509 52.3578 45.9677 52.3578 46.6052V47.134C52.3578 47.7715 51.8411 48.2882 51.2036 48.2882H50.6747C50.0373 48.2882 49.5205 47.7715 49.5205 47.134V46.6052Z"
            fill="#A5A5A5"
        />
        <path
            d="M49.5205 50.1518C49.5205 49.5143 50.0373 48.9976 50.6747 48.9976H51.2036C51.8411 48.9976 52.3578 49.5143 52.3578 50.1518V50.6806C52.3578 51.3181 51.8411 51.8349 51.2036 51.8349H50.6747C50.0373 51.8349 49.5205 51.3181 49.5205 50.6806V50.1518Z"
            fill="#A5A5A5"
        />
    </svg>
);
