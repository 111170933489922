import { StyledSearchLayout } from "@components";
import { ALL } from "@configs";
import { IConplaintCategory, IDivision } from "@interfaces";
import { selectApp, useAppSelector } from "@redux";
import { Row, Col, Input, Select, DatePicker } from "antd";
import { useTranslation } from "react-i18next";

const { Search } = Input;
const { Option } = Select;

interface Iprops {
    setTextSearch: (value: string) => void;
    handleSearchCategory: (value: string) => void;
    onChangeDate?: (dates, dateStrings: string) => void;
    total: number;
    divisions?: IDivision[];
    categories?: IConplaintCategory[];
}
export const ConpliantTableSearch = (props: Iprops) => {
    const app = useAppSelector(selectApp);
    const { setTextSearch, handleSearchCategory, onChangeDate, total, divisions, categories } =
        props;
    const { t } = useTranslation();
    return (
        <StyledSearchLayout>
            <div className="filter">
                <div className="search-row">
                    {!app.mobile && (
                        <div className="col-item">
                            <div className="filter-element">
                                <div className="label">
                                    <p>
                                        {t("page.search")}
                                        <span>{" :"}</span>
                                    </p>
                                </div>
                                <div className="input">
                                    <Search
                                        placeholder={t("page.text_search")}
                                        onSearch={(value) => setTextSearch(value)}
                                        // style={{ width: 200 }}
                                        className="search-input"
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="col-item">
                        <div className="filter-element">
                            <div className="label vs-dm">
                                <p>
                                    {t("page.report_date")}
                                    <span>{" :"}</span>
                                </p>
                            </div>
                            <div className="input">
                                <DatePicker
                                    format={"YYYY-MM-DD"}
                                    onChange={onChangeDate}
                                    placeholder={t("page.select_date")}
                                />
                            </div>
                        </div>
                    </div>
                    {divisions && (
                        <div className="col-item">
                            <div className="filter-element">
                                <div className="label vs-dm">
                                    <p>
                                        {t("page.division_type")}
                                        <span>{" :"}</span>
                                    </p>
                                </div>
                                <div className="input">
                                    <Select
                                        onChange={handleSearchCategory}
                                        placeholder={t("page.all")}
                                    >
                                        <Option value={ALL} key={"all"}>
                                            {`ทั้งหมด`}
                                        </Option>
                                        {divisions?.length > 0 &&
                                            divisions.map((item, index) => (
                                                <Option value={item._id} key={index}>
                                                    {item.division_name}
                                                </Option>
                                            ))}
                                    </Select>
                                </div>
                            </div>
                        </div>
                    )}
                    {categories && (
                        <div className="col-item">
                            <div className="filter-element">
                                <div className="label">
                                    <p>
                                        {t("page.division_type")}
                                        <span>{" :"}</span>
                                    </p>
                                </div>
                                <div className="input">
                                    <Select
                                        onChange={handleSearchCategory}
                                        placeholder={t("page.all")}
                                    >
                                        {categories?.length > 0 &&
                                            categories.map((item, index) => (
                                                <Option value={item._id} key={index}>
                                                    {item.category_name}
                                                </Option>
                                            ))}
                                    </Select>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </StyledSearchLayout>
    );
};
