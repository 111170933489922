import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Row, Col, Select, Input } from "antd";
import { IConplaint } from "@interfaces";
import { StyledSubmitButton, StyledCancelButton, StyledCard, ComponentMaps } from "@components";
import { useYup } from "@validations";
import { setLoading, selectAuth } from "@redux";
import { useNotify } from "@utils";
import { enumProcessStatus } from "@configs";
import { conplaintAPI } from "@api";
import Parser from "html-react-parser";
import { defautLocation } from "@configs";

const { TextArea } = Input;
interface Iprops {
    complaint: IConplaint | undefined;
    updateData: (data: IConplaint) => void;
}
export const ModuleComplainInfo = memo((props: Iprops) => {
    //hook
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { error, success } = useNotify();
    const organization_id = useSelector(selectAuth).auth?.admin_data?.organization_id;
    const admin = useSelector(selectAuth).auth?.admin_data?.name;
    const { complaint, updateData } = props;
    const [comment, setComment] = useState<string>("");

    const handleCancel = async () => {
        dispatch(setLoading(true));
        try {
            const { data } = await conplaintAPI.cancel({
                organization_id: organization_id || "",
                complain_id: complaint?._id || "",
                petitioner: {
                    name: admin || "",
                    comment: "",
                },
            });
            if (data.error === "0") {
                success(data.msg);
                updateData(data.result);
            } else {
                error(data.msg);
            }
        } catch (err: any) {
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };
    const handleProcess = async () => {
        dispatch(setLoading(true));
        try {
            const { data } = await conplaintAPI.processing({
                organization_id: organization_id || "",
                complain_id: complaint?._id || "",
                petitioner: {
                    name: admin || "",
                    comment: "",
                },
            });
            // console.log(res);
            if (data.error === "0") {
                success(data.msg);
                updateData(data.result);
            } else {
                error(data.msg);
            }
        } catch (err: any) {
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };
    const handleApprove = async () => {
        dispatch(setLoading(true));
        try {
            const { data } = await conplaintAPI.complete({
                organization_id: organization_id || "",
                complain_id: complaint?._id || "",
                petitioner: {
                    name: admin || "",
                    comment: "",
                },
            });
            if (data.error === "0") {
                success(data.msg);
                updateData(data.result);
            } else {
                error(data.msg);
            }
        } catch (err: any) {
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };
    const handleChangeComment = (e) => {
        setComment(e.target.value);
    };
    const handleUpdateComment = async () => {
        try {
            dispatch(setLoading(true));
            const { data } = await conplaintAPI.adminUpdateCommentComplain({
                organization_id: organization_id || "",
                complain_id: complaint?._id || "",
                petitioner: {
                    name: admin || "",
                    comment: comment,
                },
            });
            if (data.error === "0") {
                success(data.msg);
                dispatch(setLoading(false));
            } else {
                error(data.msg);
                dispatch(setLoading(false));
            }
        } catch (err: any) {
            error(err.msg);
            dispatch(setLoading(false));
        }
    };
    const getProcessClass = (value: enumProcessStatus | undefined) => {
        if (value === enumProcessStatus.PENDING) return "status-button warning";
        if (value === enumProcessStatus.DOING) return "status-button doing";
        if (value === enumProcessStatus.COMPLETE) return "status-button complete";
        if (value === enumProcessStatus.CANCELED) return "status-button canceled";
    };
    return (
        <div className="form-wrap">
            <div className="info-card">
                <div className="page-header">
                    <h3>{t("page.add_a_complaint")}</h3>
                    <p className="sub-title vs-dm">{t("page.please_provide_complete")}</p>
                </div>
                {/* form basic setting */}
                <div className="page-body">
                    <StyledCard>
                        <div className="info-title">
                            <div className="title">{t("page.complaint_topic_title")}</div>
                            <div className={getProcessClass(complaint?.status)}>
                                <p>{t(`page.${complaint?.status}`) || t("page.empty_text")}</p>
                            </div>
                        </div>
                        <Row>
                            <Col xs={12} sm={12} md={12}>
                                <div className="item">
                                    <label className="info-label">{t("page.surname")}</label>
                                    <div className="info-value">
                                        {complaint?.complainer.surname || t("page.empty_text")}
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={12}>
                                <div className="item">
                                    <label className="info-label">{t("page.id_card_number")}</label>
                                    <div className="info-value eng">
                                        {complaint?.complainer.card_no || t("page.empty_text")}
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} sm={12} md={12}>
                                <div className="item">
                                    <label className="info-label">{t("page.phone_number")}</label>
                                    <div className="info-value eng">
                                        {complaint?.complainer?.phone_no || t("page.empty_text")}
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={12}>
                                <div className="item">
                                    <label className="info-label">{t("page.email")}</label>
                                    <div className="info-value">
                                        {complaint?.complainer?.email || t("page.empty_text")}
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <div className="title">{t("page.complaint_details")}</div>
                        <div className="item">
                            <label className="info-label">{t("page.complaint_topic_title")}</label>
                            <div className="info-value">
                                {complaint?.complain_category_id || t("page.empty_text")}
                            </div>
                        </div>
                        <div className="item">
                            <label className="info-label">{t("page.header")}</label>
                            <div className="info-value eng">
                                {complaint?.header || t("page.empty_text")}
                            </div>
                        </div>
                        <div className="item">
                            <label className="info-label">{t("page.complaint_details")}</label>
                            <div className="info-value">
                                {Parser(complaint?.detail || t("page.empty_text"))}
                            </div>
                        </div>
                        <div className="item">
                            <label className="info-label">{t("page.complaint_image")}</label>
                            {complaint?.image_url_list.length === 0 && t("page.empty_text")}
                            <div className="info-value image-layout">
                                {complaint?.image_url_list.map((item) => (
                                    <img src={item} />
                                ))}
                            </div>
                        </div>
                        <div className="title">{t("page.location_coordinate")}</div>
                        <div className="item">
                            <label className="info-label">{t("page.place_name")}</label>
                            <div className="info-value">
                                {complaint?.place_name || t("page.empty_text")}
                            </div>
                        </div>
                        <Row>
                            <Col span={12}>
                                <div className="item">
                                    <label className="info-label">{t("page.latitude")}</label>
                                    <div className="info-value eng">
                                        {complaint?.location.latlon[1] || t("page.empty_text")}
                                    </div>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className="item">
                                    <label className="info-label">{t("page.longitude")}</label>
                                    <div className="info-value eng">
                                        {complaint?.location.latlon[0] || t("page.empty_text")}
                                    </div>
                                </div>
                            </Col>
                            -
                        </Row>
                        <div className="maps-layout">
                            <ComponentMaps
                                location_lat={complaint?.location?.latlon[1] || defautLocation.lat}
                                location_lon={complaint?.location?.latlon[0] || defautLocation.long}
                                height={`400px`}
                            />
                        </div>
                        <div className="title">{`ผู้ดำเนินการรับเรื่อง`}</div>
                        <div className="item">
                            <label className="info-label">{`ผู้ดำเนินการรับเรื่อง`}</label>
                            <div className="info-value">{admin || t("page.empty_text")}</div>
                        </div>
                        <div className="item">
                            <div className="label">{t("page.address")}</div>
                            <TextArea
                                name="activity_address"
                                defaultValue={complaint?.petitioner.comment}
                                onChange={handleChangeComment}
                                value={comment}
                                rows={4}
                            />
                            <div>
                                <p>
                                    ท่านสามารถกดบันทึกหมายเหตุเพื่อส่งหมายเหตุไปยังผู้ร้องทุกข์
                                    ก่อนดำเนินการเสร็จสิ้น* :
                                </p>
                                <StyledSubmitButton
                                    type="default"
                                    text={t("page.save")}
                                    htmlType="button"
                                    onClick={handleUpdateComment}
                                />
                            </div>
                        </div>
                    </StyledCard>

                    <div className="btn-layout">
                        {complaint?.status !== enumProcessStatus.COMPLETE && (
                            <StyledCancelButton
                                type="sub"
                                text={t("page.cancel")}
                                htmlType="button"
                                className="cancel-btn"
                                onClick={handleCancel}
                            />
                        )}
                        {complaint?.status === enumProcessStatus.PENDING && (
                            <StyledSubmitButton
                                type="default"
                                text={t("page.approve_action")}
                                className="pending-btn"
                                // htmlType="submit"
                                onClick={handleProcess}
                                // disable={isSubmitting}
                            />
                        )}
                        {complaint?.status === enumProcessStatus.DOING && (
                            <StyledSubmitButton
                                type="default"
                                className="complete-btn"
                                text={t("page.COMPLETE")}
                                // htmlType="submit"
                                onClick={handleApprove}
                                // disable={isSubmitting}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
});
