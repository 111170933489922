import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { IBidding } from "@interfaces";
import { PATH_CREATE_NEWS } from "@configs";
import { ModuleBidderDetail, ModuleBiddingForm } from "@modules";
import { useNotify } from "@utils";
import { biddingAPI } from "@api";
import { setLoading, selectAuth } from "@redux";
import { StyledBiddingDetail } from "@components";

export const PageBidderDetail = () => {
  const { id } = useParams<{ id: string }>();
  const org_id = useSelector(selectAuth).auth?.organization_data?._id;
  const dispatch = useDispatch();
  const history = useHistory();
  const { warning } = useNotify();
  const { t } = useTranslation();

  const [bidding, setBidding] = useState<IBidding>();
  // variable
  const pathName = history.location.pathname;

  useEffect(() => {
    fetchBidding();
  }, []);

  const fetchBidding = async () => {
    dispatch(setLoading(true));
    if (id !== "create-purchase" && id !== "create-employ") {
      try {
        const response = await biddingAPI.detail({
          organization_id: org_id || "",
          bidding_id: id,
        });
        const data: IBidding = response.data.result;
        setBidding(data);
        dispatch(setLoading(false));
      } catch (err: any) {
        if (err) {
          warning(err.msg);
          dispatch(setLoading(false));
        }
      } finally {
        dispatch(setLoading(false));
      }
    } else {
      dispatch(setLoading(false));
    }
  };
  // สร้างประมูลการจัดจ้าง
  // สร้างประมูลการจัดซื้อ
  //ddddxxabc
  return <StyledBiddingDetail>{<ModuleBidderDetail bidding={bidding} />}</StyledBiddingDetail>;
};
