import { useTranslation } from "react-i18next";
import { useHistory, Link } from "react-router-dom";
import { Col, Row, Menu, Switch, Avatar, Dropdown, Button } from "antd";
import moment from "moment";
import { MoreHorizontal } from "react-feather";
import { IActivity } from "@interfaces";
import { activityApi } from "@api";
import { dateFormatHM, defaultImage, enumNewsType, PATH_ACTIVITY } from "@configs";
import { selectAuth, setLoading, selectOrg } from "@redux";
import { useDispatch, useSelector } from "react-redux";
import { IconDrag } from "@components";
import { showErrorMessage, useNotify } from "@utils";
import { useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
interface IProps {
    item: IActivity;
    itemJson: string;
    callbackGetList: () => void;
    index: number;
    newType?: enumNewsType;
    // categories: INewsCategory[];
}

export const ComponentRowAcitivity = (props: IProps) => {
    //hooks
    const { t } = useTranslation();
    const { success, error } = useNotify();
    const history = useHistory();
    const dispatch = useDispatch();
    const organization_id = useSelector(selectAuth).auth?.organization_data?._id;
    const activityCategory = useSelector(selectOrg).activityCategory;
    //props
    const { item, callbackGetList, itemJson, newType } = props;
    const [show, setShowModal] = useState(false);

    const { attributes, listeners, setNodeRef, transform } = useSortable({
        id: itemJson,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
    };

    // const catName = () => {
    //     const item = activityCategory.find((item) => item._id === props.item.activity_category_id);
    //     if (item) {
    //         return item.category_name;
    //     }
    //     return "...";
    // };

    const handleDelete = async () => {
        try {
            await activityApi.delete({
                organization_id: organization_id || "",
                activity_id: item._id,
            });
            callbackGetList();
            setShowModal(false);
            success(t("message.delete.success"));
        } catch (err: any) {
            if (err) {
                error(err.msg);
            } else error(t("message.delete.fail"));
        }
    };

    const menu = (
        <Menu>
            <Menu.Item key={1} onClick={handleDelete}>
                <div className="duplicate-btn">{t("page.delete")}</div>
            </Menu.Item>
        </Menu>
    );
    const handleUpdateStatus = async (e: boolean) => {
        try {
            dispatch(setLoading(true));
            const { data } = await activityApi.setEnableFullActivity({
                organization_id: organization_id || "",
                activity_id: item._id,
                is_enable: e,
                is_full: item.is_full,
            });
            if (data.error === "0") {
                callbackGetList();
                success(data.msg);
                dispatch(setLoading(false));
            } else {
                error(data.msg);
                dispatch(setLoading(false));
            }
        } catch (err: any) {
            if (err) {
                error(err.msg);
            } else error(t("message.delete.fail"));
        }
    };
    const handleUpdateFull = async (e: boolean) => {
        try {
            dispatch(setLoading(true));
            const { data } = await activityApi.setEnableFullActivity({
                organization_id: organization_id || "",
                activity_id: item._id,
                is_enable: item.is_enable,
                is_full: e,
            });
            if (data.error === "0") {
                callbackGetList();
                success(data.msg);
                dispatch(setLoading(false));
            } else {
                error(data.msg);
                dispatch(setLoading(false));
            }
        } catch (err: any) {
            if (err) {
                error(err.msg);
            } else error(t("message.delete.fail"));
        }
    };
    return (
        <div className="table-element" ref={setNodeRef} style={style}>
            <div className="drag-icon" {...attributes} {...listeners}>
                <IconDrag />
            </div>

            <div className="table-row">
                <Row
                    style={{ height: "100%" }}
                    gutter={2}
                    type="flex"
                    align="middle"
                    justify="space-between"
                >
                    <Col className="col-item" span={6}>
                        <Avatar
                            shape="square"
                            src={item.image_url_list[0] || defaultImage}
                            size={84}
                            style={{ minWidth: 84 }}
                        />
                        <Link to={`${PATH_ACTIVITY}/${item._id}`}>
                            <p>{item.title || t("page.empty_text")}</p>
                        </Link>
                    </Col>
                    {/* <Col className="col-item" span={3}>
                        <p>{catName() || t("page.empty_text")}</p>
                    </Col> */}
                    <Col className="col-item m-member-code" span={3}>
                        <p className="visible-md eng">{item.profile_enrolls.length}</p>
                    </Col>
                    <Col className="col-item visible-md" span={3}>
                        <Switch onChange={handleUpdateFull} checked={item.is_full} />
                    </Col>
                    <Col className="col-item visible-md" span={4}>
                        <div>
                            <p className="eng">
                                {moment(item.register_date_from).format(dateFormatHM)}
                            </p>
                            <p className="eng">
                                {moment(item.register_date_to).format(dateFormatHM)}
                            </p>
                        </div>
                    </Col>
                    {/* <Col className="col-item visible-md" span={3}>
                        <p className="eng">{moment(item.created_date).format(dateFormatHM)}</p>
                    </Col> */}
                    <Col className="col-item visible-md" span={3}>
                        <Switch onChange={handleUpdateStatus} checked={item.is_enable} />
                    </Col>
                    <Col className="col-item" span={2}>
                        <Dropdown
                            //@ts-ignore
                            getPopupContainer={(trigger) => trigger.parentNode}
                            overlay={menu}
                            trigger={["click"]}
                            placement="bottomRight"
                        >
                            <Button style={{ border: "unset" }}>
                                <MoreHorizontal />
                            </Button>
                        </Dropdown>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
