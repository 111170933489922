import {
    Avatar,
    Layout,
    Menu,
    Badge,
    Tooltip,
    // Divider
} from "antd";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
    StyledHeader,
    // EngIcon,
    // ThaiIcon,
    // UpArrow,
    DownArrow,
    HomeOutline,
    SettingOutLine,
    GuideOutline,
    PricingFeature,
    LogoutOutline,
    Users,
    IconPresent,
    CalendarIcon,
    IconSound,
    IconGift,
    IconDate,
    IconBuger,
} from "@components";
import {
    logout,
    selectApp,
    selectAuth,
    useAppSelector,
    setProfileDrawer,
    selectTheme,
    getHeaderBar,
    selectOrg,
    setMobileDrawer,
} from "@redux";
import { IconBell } from "@components";
import {
    // enumLanuage,
    PATH_LOGIN,
    PATH_HOME,
} from "@configs";
import { useNotify } from "@utils";
import { useEffect } from "react";

const { Header } = Layout;

export const ComponentHeader = () => {
    //page hook
    const dispatch = useDispatch();
    const {
        t,
        // i18n
    } = useTranslation();
    const history = useHistory();
    const { warning } = useNotify();
    //redux state
    const app = useAppSelector(selectApp);
    const { userInfo } = useSelector(selectAuth);
    const org_id = useSelector(selectAuth).auth?.organization_data?._id;
    const { transaction } = useSelector(selectOrg);
    const adminPane = userInfo?.notifSetting?.channels?.adminPanel;
    const Theme = useSelector(selectTheme);
    const urlLogo = Theme.logo;
    const title = Theme.fullName;

    const handleSignOut = () => {
        dispatch(logout());
        setTimeout(() => {
            history.push(PATH_LOGIN);
        }, 1000);
    };

    useEffect(() => {
        dispatch(getHeaderBar({ organization_id: org_id || "" }));
    }, []);

    const routeNotifiSetting = () => {
        if (adminPane) history.push(PATH_HOME);
        else warning(t("message.admin_panel_notification_setting_is_turn_off"), 3000);
    };

    const handleClickProfile = async () => {
        if (app.mobile) {
            await dispatch(setProfileDrawer(!app.profileDrawer));
        }
    };
    const handleClickBuger = async () => {
        await dispatch(setMobileDrawer(!app.mobileDrawer));
    };
    return (
        <StyledHeader>
            <Header>
                {app.mobile ? (
                    <div onClick={handleClickBuger} className="trigger">
                        <IconBuger />
                    </div>
                ) : (
                    <div className="flex-layout header-logo">
                        <Link to="/">
                            <div className="brand">
                                <img src={urlLogo || "/logo.png"} alt="teesaban logo" />
                                <p>{title || t("page.logo_text")}</p>
                            </div>
                        </Link>
                        <div className="future-layout">
                            <h4>{t("page.e_teesaban")}</h4>
                        </div>
                    </div>
                )}
                {app.mobile && (
                    <div>
                        <Link to="/" className="small-logo">
                            <div className="brand">
                                <img src={urlLogo || "/logo.png"} alt="teesaban logo" />
                            </div>
                        </Link>
                    </div>
                )}
                {!app.mobile && (
                    <Menu mode="horizontal" className="mobile-right-menu">
                        <Menu.SubMenu
                            className="noti-menu"
                            key={"member"}
                            title={
                                <Badge count={0}>
                                    <span
                                        className="submenu-title-wrapper notification-menu"
                                        onClick={routeNotifiSetting}
                                    >
                                        <Users color="#6C7084" size={28} />
                                        <span className="text"> {t("page.member")}</span>
                                        <span className="text eng">
                                            {" "}
                                            {transaction?.total_profile || 0}
                                        </span>
                                    </span>
                                </Badge>
                            }
                        ></Menu.SubMenu>
                        <Menu.SubMenu
                            className="noti-menu"
                            key={"redeem_count"}
                            title={
                                <Badge count={0}>
                                    <span
                                        className="submenu-title-wrapper notification-menu"
                                        onClick={routeNotifiSetting}
                                    >
                                        <IconGift color="#6C7084" size={28} />
                                        <span className="text"> {t("page.redeem_count")}</span>
                                        <span className="text eng">
                                            {" "}
                                            {transaction?.total_profile_reward || 0}
                                        </span>
                                    </span>
                                </Badge>
                            }
                        ></Menu.SubMenu>

                        <Menu.SubMenu
                            className="noti-menu"
                            key={"complaint_count"}
                            title={
                                <Badge count={0}>
                                    <span
                                        className="submenu-title-wrapper notification-menu"
                                        onClick={routeNotifiSetting}
                                    >
                                        <IconSound color="#6C7084" size={28} />
                                        <span className="text"> {t("page.complaint_count")}</span>
                                        <span className="text eng">
                                            {" "}
                                            {transaction?.total_complain || 0}
                                        </span>
                                    </span>
                                </Badge>
                            }
                        ></Menu.SubMenu>
                        <Menu.SubMenu
                            className="noti-menu"
                            key={"transaction_count"}
                            title={
                                <Badge count={0}>
                                    <span
                                        className="submenu-title-wrapper notification-menu"
                                        onClick={routeNotifiSetting}
                                    >
                                        <IconDate color="#6C7084" size={28} />
                                        <span className="text"> {t("page.transaction_count")}</span>
                                        <span className="text eng">
                                            {" "}
                                            {transaction?.total_booking_service || 0}
                                        </span>
                                    </span>
                                </Badge>
                            }
                        ></Menu.SubMenu>
                        {!app.mobile && (
                            <Menu.SubMenu
                                className="noti-menu"
                                key={"notification"}
                                title={
                                    <Badge count={0}>
                                        <Tooltip placement="bottom" title={t("page.notifications")}>
                                            <span
                                                className="submenu-title-wrapper notification-menu"
                                                onClick={routeNotifiSetting}
                                            >
                                                <IconBell color="#6C7084" size={28} />
                                            </span>
                                        </Tooltip>
                                    </Badge>
                                }
                            ></Menu.SubMenu>
                        )}
                        <Menu.SubMenu
                            key={"name"}
                            className="profile-menu"
                            title={
                                <div className="profile-title">
                                    <Avatar
                                        size={48}
                                        src={userInfo?.avatar || "/user-avatar.png"}
                                    />
                                    <p className="merchant-name">{userInfo?.fullName}</p>
                                    <DownArrow />
                                </div>
                            }
                            onTitleClick={handleClickProfile}
                        >
                            <Menu className="profile-layout">
                                <div className="profile-header">
                                    <div className="profile-image">
                                        <Avatar
                                            size={84}
                                            className="avatar"
                                            src={userInfo?.avatar || "/user-avatar.png"}
                                        />
                                    </div>
                                    <div className="profile-info">
                                        <p className="profile-name">{userInfo?.fullName}</p>
                                        <p className="profile-role">{userInfo?.role?.name}</p>
                                        <p className="profile-email">{userInfo?.email}</p>
                                    </div>
                                </div>
                                <Menu.Divider />
                                <Menu.Item
                                    onClick={() => history.push("/accounts")}
                                    style={{
                                        fontWeight: 400,
                                        fontSize: "16px",
                                        cursor: "pointer",
                                        color: "#000000",
                                    }}
                                >
                                    <div className="item-text">
                                        <HomeOutline />
                                        <span>{t("page.select_account")}</span>
                                    </div>
                                </Menu.Item>
                                <Menu.Item
                                    onClick={() => history.push(PATH_HOME)}
                                    style={{
                                        fontWeight: 400,
                                        fontSize: "16px",
                                        cursor: "pointer",
                                        color: "#000000",
                                    }}
                                >
                                    <div className="item-text">
                                        <SettingOutLine />
                                        <span>{t("page.sidebar.edit_profile")}</span>
                                    </div>
                                </Menu.Item>
                                <Menu.Item
                                    style={{
                                        fontWeight: 400,
                                        fontSize: "16px",
                                        cursor: "pointer",
                                        color: "#000000",
                                    }}
                                >
                                    <div className="item-text">
                                        <GuideOutline />
                                        <span>{t("page.user_guide")}</span>
                                    </div>
                                </Menu.Item>
                                <Menu.Item
                                    onClick={() => history.push(PATH_HOME)}
                                    style={{
                                        fontWeight: 400,
                                        fontSize: "16px",
                                        cursor: "pointer",
                                        color: "#000000",
                                    }}
                                >
                                    <div className="item-text">
                                        <PricingFeature />
                                        <span>{t("page.sidebar.pricing_features")}</span>
                                    </div>
                                </Menu.Item>
                                <Menu.Item
                                    style={{
                                        fontWeight: 400,
                                        fontSize: "16px",
                                        cursor: "pointer",
                                        color: "#F22F46",
                                    }}
                                    onClick={handleSignOut}
                                >
                                    <div className="item-text">
                                        <LogoutOutline />
                                        <span>{t("page.manage_account.logout")}</span>
                                    </div>
                                </Menu.Item>
                            </Menu>
                        </Menu.SubMenu>
                    </Menu>
                )}
                {app.mobile && (
                    <div className="profile-title mobile" onClick={handleClickProfile}>
                        <Avatar size={48} src={userInfo?.avatar || "/user-avatar.png"} />
                        <p className="merchant-name">{userInfo?.fullName}</p>
                        <DownArrow />
                    </div>
                )}
            </Header>
        </StyledHeader>
    );
};
