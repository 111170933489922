import { useState, useRef, useEffect } from "react";
import {
    ComponentContactSortIcon,
    ComponentRowNews,
    StyledTable,
    ComponentEmptyData,
    StyledTableButtonTop,
    ComponentStatusFilter,
    ComponentTableSearch,
    ComponentRowService,
} from "@components";
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    TouchSensor,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    rectSortingStrategy,
    //@ts-ignore
} from "@dnd-kit/sortable";
import { Row, Col, Pagination } from "antd";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import {
    enumSortBy,
    enumContactSortFields,
    PAGINATION,
    PAGE_START,
    INITIAL_LIST_PARAMS,
    PATH_SERVICES_CREATE,
} from "@configs";
import { IPaginationNext, IService, IServiceDepartment } from "@interfaces";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth, selectContact, selectOrg, setLoading } from "@redux";
import { useNotify, usePermission } from "@utils";
import { useHistory } from "react-router";
import { ComponentSearchService } from "@modules";
import { departmentAPI, serviceAPI } from "@api";

const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: PAGINATION,
};

export const PageServiceList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const search_params = useSelector(selectContact).paramsContact;
    const [params, setParams] = useState(search_params);
    const org_id = useSelector(selectAuth).auth?.organization_data?._id;
    const pemisssion = useSelector(selectAuth).permission;
    const categories = useSelector(selectOrg).category;
    const [textSearch, setTextSearch] = useState<string>("");

    const [searchDate, setSearchDate] = useState<string[]>([]);
    const [catSearch, setCatSearch] = useState<string>("");
    const [pagination, setPagination] = useState<IPaginationNext>(INITAL_PAGE);
    const [department, setDepartment] = useState<string>("");
    const [services, setServices] = useState<IService[]>([]);
    const [departments, setDepartments] = useState<IServiceDepartment[]>([]);
    const [total, setTotal] = useState<number>(0);
    const { success, error } = useNotify();
    const history = useHistory();
    usePermission({ permission: pemisssion?.permission_list.service.is_view });
    useEffect(() => {
        fetchServices(pagination, textSearch, department);
    }, [pagination, textSearch, department]);
    useEffect(() => {
        fetchServicesDept();
    }, []);

    const fetchServices = async (
        pagination: IPaginationNext,
        textSearch: string,
        department: string
    ) => {
        dispatch(setLoading(true));
        try {
            const response = await serviceAPI.getServices({
                organization_id: org_id || "",
                ...pagination,
                ...(textSearch && { text_search: textSearch }),
                ...(department && { service_department_id: department }),
            });
            setServices(response.data.result);
            setTotal(response.data.total);
            // success(response.data.msg);
        } catch (err: any) {
            error(err.msg);
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };
    const fetchServicesDept = async () => {
        const response = await departmentAPI.getServiceDepartments({
            organization_id: org_id || "",
            ...pagination,
        });
        setDepartments(response.data.result);
    };
    const handleSort = (sortField: enumContactSortFields, sortBy: enumSortBy) => {
        const newSortParams = { ...params, sortField, sortBy };
        setParams(newSortParams);
        // fetchContacts(newSortParams);
    };
    const updateService = async (isEnable: boolean, data: IService) => {
        dispatch(setLoading(true));
        try {
            const res = await serviceAPI.updateService({
                ...data,
                ...{ is_enable: isEnable },
                ...{ service_id: data._id },
            });
            if (res.data.error === "0") {
                success(res.data.msg);
            } else {
                error(res.data.msg);
            }
            callbackGetList();
        } catch (error) {
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };
    const callbackGetList = () => {
        fetchServices(pagination, textSearch, department);
    };
    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            const swapItem = (items: IService[]) => {
                const oldIndex = items.findIndex((item) => JSON.stringify(item) === active.id);
                const newIndex = items.findIndex((item) => JSON.stringify(item) === over.id);
                return arrayMove(items, oldIndex, newIndex);
            };
            // console.log(swapItem());s
            setServices(swapItem);
        }
    };
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
        useSensor(TouchSensor)
    );
    const handleSearchDept = (value) => {
        setCatSearch(value);
    };
    const onChangeDateRange = (dates, dateStrings: [string, string]) => {
        setSearchDate(dateStrings);
    };
    const handlePagination = (page) => {
        setPagination(page);
    };
    return (
        <StyledTable>
            <div className="page-layout">
                <div className="page-header-layout">
                    <div className="page-header">
                        <div>
                            <h3>{`รายการทำธุรกรรม`}</h3>
                        </div>
                        <div className="flex-header">
                            <StyledTableButtonTop
                                type="danger"
                                size="default"
                                text={`สร้างธุรกรรม`}
                                onClick={() => history.push(PATH_SERVICES_CREATE)}
                                className="add-btn"
                                icon="plus"
                            />
                        </div>
                    </div>
                    <div className="search-layout">
                        <ComponentSearchService
                            setTextSearch={setTextSearch}
                            handleSearchDept={handleSearchDept}
                            departments={departments}
                            onChangeDateRange={onChangeDateRange}
                            total={total}
                            isRangePicker={true}
                        />
                    </div>
                </div>
                <div className="page-table-layout">
                    <div className="table">
                        <ScrollContainer vertical={false}>
                            <div className="table_ground">
                                <div className="table-body">
                                    <div className="table-header">
                                        <Row
                                            gutter={24}
                                            type="flex"
                                            justify="space-between"
                                            align="middle"
                                            className="header-row"
                                        >
                                            <Col className="col-item" span={10}>
                                                <p>{`ชื่อธุรกรรม`}</p>
                                            </Col>
                                            <Col className="col-item" span={6}>
                                                <p>{`รหัสธุรกรรม`}</p>
                                            </Col>
                                            <Col className="col-item" span={6}>
                                                <p>{t("page.active")}</p>
                                            </Col>
                                            <Col className="col-item" span={2}>
                                                {/* <p>{t("page.news_manage")}</p> */}
                                            </Col>
                                        </Row>
                                    </div>
                                    <DndContext
                                        sensors={sensors}
                                        collisionDetection={closestCenter}
                                        onDragEnd={handleDragEnd}
                                    >
                                        <SortableContext
                                            items={services.map((item) => JSON.stringify(item))}
                                            strategy={rectSortingStrategy}
                                        >
                                            {services && services.length > 0 ? (
                                                <div className="data-table">
                                                    {services.map((item, index) => (
                                                        <ComponentRowService
                                                            key={index}
                                                            data={item}
                                                            itemJson={JSON.stringify(item)}
                                                            categories={categories}
                                                            handleEdit={updateService}
                                                            callbackGetList={callbackGetList}
                                                            index={index}
                                                        />
                                                    ))}
                                                    <div
                                                        style={{ width: "100%", height: 10 }}
                                                    ></div>
                                                </div>
                                            ) : (
                                                <ComponentEmptyData />
                                            )}
                                        </SortableContext>
                                    </DndContext>
                                </div>
                            </div>
                        </ScrollContainer>
                    </div>
                </div>
                <div className="page-bottom">
                    {total > 0 && (
                        <div className="pagination">
                            <Pagination
                                defaultCurrent={INITIAL_LIST_PARAMS.page || 1}
                                pageSize={PAGINATION}
                                total={total}
                                onChange={handlePagination}
                                current={params.page}
                            />
                        </div>
                    )}
                </div>
            </div>
        </StyledTable>
    );
};
