import { SharedButtonSub, StyledCancelButton } from "@components";
import { PATH_HOME_DECS_REPORT, PATH_HOME_ORG, PATH_HOME_SER_INFO } from "@configs";
import { Modal, Radio } from "antd";
import { useHistory } from "react-router";
import styled from "styled-components";

interface IProps {
  openModal: boolean;
  selectRadio: number;
  setOpenModal: (data: boolean) => void;
  setSelectRadio: (data: number) => void;
  addNewDetail: () => void;
}



export const ModuleAddSectionModal = (props: IProps) => {
  const {
    openModal,
    selectRadio,
    setOpenModal,
    setSelectRadio,
    addNewDetail,
  } = props;
  const history = useHistory();
  const pathName = history.location.pathname;
  const clickSubmit = () => {
    if (selectRadio !== 0) {
      addNewDetail();
    }
  };

  return (
    <Modal
      visible={openModal}
      closable={false}
      footer={[
        <StyledCancelButton
          type="sub"
          text="ยกเลิก"
          style={{
            fontSize: "16px",
            lineHeight: "0px",
            height: "52px",
          }}
          htmlType="button"
          onClick={() => {
            setOpenModal(false);
            setSelectRadio(1);
          }}
        />,
        <SharedButtonSub
          type="default"
          style={{
            fontSize: "16px",
            lineHeight: "0px",
            height: "52px",
          }}
          text="ตกลง"
          htmlType="submit"
          onClick={clickSubmit}
        />
      ]}
      width={850}
    >
      <StyledModal>
        <p className="title">เพิ่มรายละเอียดส่วนอื่น {pathName} {PATH_HOME_SER_INFO}</p>
        <p className="sub-title">โปรดเลือกแบบที่ต้องการเพิ่มเพื่อแสดงผลในหน้าเว็บไซต์</p>
        <div className="grid-three">
          {pathName !== PATH_HOME_ORG &&
            pathName !== PATH_HOME_DECS_REPORT &&
            pathName !== PATH_HOME_SER_INFO &&
            <div className="item" onClick={() => setSelectRadio(1)} >
              <Radio checked={selectRadio === 1} /> รายละเอียด รูปภาพประกอบแบบที่ 1
              <img src="/images/detail_type_1.png" className="item-img" />
            </div>
          }

          {pathName !== PATH_HOME_ORG &&
            pathName !== PATH_HOME_DECS_REPORT &&
            pathName !== PATH_HOME_SER_INFO &&
            <div className="item" onClick={() => setSelectRadio(2)} >
              <Radio checked={selectRadio === 2} /> รายละเอียด รูปภาพประกอบแบบที่ 2
              <img src="/images/detail_type_2.png" className="item-img" />
            </div>
          }
          {pathName !== PATH_HOME_DECS_REPORT &&
            pathName !== PATH_HOME_SER_INFO &&
            <div className="item" onClick={() => setSelectRadio(3)} >
              <Radio checked={selectRadio === 3} /> รายละเอียด แบบกำหนดเอง
              <img src="/images/detail_type_3.png" className="item-img" />
            </div>
          }

          <div className="item" onClick={() => setSelectRadio(4)} >
            <Radio checked={selectRadio === 4} /> แนบเอกสาร .pdf
            <img src="/images/detail_type_4.png" className="item-img" />
          </div>
          {pathName !== PATH_HOME_ORG &&
            <div className="item" onClick={() => setSelectRadio(5)} >
              <Radio checked={selectRadio === 5} /> รายหัวข้อ และแนบเอกสาร .pdf
              <img src="/images/detail_type_5.png" className="item-img" />
            </div>
          }
        </div>
      </StyledModal>
    </Modal>
  );
};


const StyledModal = styled.div`
    .title {
        font-weight: 700;
        font-size: 25px;
        line-height: 38px;
        text-align: center;
        color: #000000;
        margin-bottom: 0px;
    }
    .sub-title {
        font-size: 14px;
        line-height: 21px;
        color: #8A959E;
        text-align: center;
    }
    .grid-three{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 20px;
        .item {
            .item-img {
                margin-top: 10px;
                width: 230px;
            }
        }
    }
`;