import { REGEX_URL } from "@configs";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

export const YupIntegration = () => {
    //page hook
    const { t } = useTranslation();

    return {
        line_messaging: Yup.object({
            access_token: Yup.string().trim(),
        }),
        line_login_api: Yup.object({
            channel_id: Yup.string(),
            liff_url: Yup.string().trim().matches(REGEX_URL, t("validation.correct_url")),
        }),
    };
};
