import { useEffect, memo, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Row, Col } from "antd";
import styled from "styled-components";
import { IAdminUser, IAdminCreate } from "@interfaces";
import {
    StyledSubmitButton,
    StyledCard,
    SharedInput,
    ShareButton,
    ComponentModal,
} from "@components";
import { useYup } from "@validations";
import { setLoading, selectAuth } from "@redux";

import { useNotify } from "@utils";
import { PATH_CHANGE_PASSWROD } from "@configs";
import { adminAPI } from "@api";

interface IProps {
    admin: IAdminUser | undefined;
}

const initData: IAdminCreate = {
    organization_id: "",
    username: "",
    permission_id: "",
    name: "",
    surname: "",
    tel_no: "",
};

export const ModuleProfileForm = memo((props: IProps) => {
    //hook
    const { YupInviteAdmin } = useYup();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { error, success } = useNotify();
    const [showModal, setShowModal] = useState<boolean>(false);
    const organization_id = useSelector(selectAuth).auth?.admin_data?.organization_id;
    // const adminEmail = useSelector(selectAuth).admin?.admin_data?.username;
    // const pathName = window.location.pathname;
    const validateAdmin = Yup.object().shape(YupInviteAdmin);
    const { admin } = props;
    useEffect(() => {
        if (admin) {
            setFieldValue("surname", admin.surname);
            setFieldValue("name", admin.name);
            setFieldValue("username", admin.username);
            setFieldValue("tel_no", admin.tel_no);
            setFieldValue("permission_id", admin.permission_id);
        }
    }, [admin]);

    const handleNewsSubmit = async (values: IAdminCreate) => {
        dispatch(setLoading(true));
        try {
            const res = await adminAPI.updateAdmin({
                ...values,
                ...{ organization_id: organization_id || "" },
                ...{ admin_id: admin?._id },
            });
            if (res.data.error === "1") {
                error(res.data.msg);
            } else {
                success(res.data.msg);
            }
        } catch (err: any) {
            if (err) {
                error(err.data.msg);
            } else error(t("message.error"));
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const { values, handleChange, handleBlur, handleSubmit, errors, touched, setFieldValue } =
        useFormik({
            initialValues: initData,
            validationSchema: validateAdmin,
            enableReinitialize: true,
            onSubmit: handleNewsSubmit,
        });

    return (
        <div className="form-wrap">
            <form onSubmit={handleSubmit}>
                <div className="page-header">
                    <h3>ข้อมูลโปรไฟล์</h3>
                </div>
                <div className="page-body">
                    <div className="card-wrap">
                        <StyledCard>
                            <div className="title">ข้อมูลของคุณ</div>
                            <Row gutter={32}>
                                <Col span={12}>
                                    <SharedInput
                                        label={`ชื่อ (ภาษาไทย)`}
                                        // descLabel={t("page._2_100_characters")}
                                        name="surname"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.surname}
                                        errors={errors.surname}
                                        touched={touched.surname}
                                        parentClassName="mb-6"
                                    />
                                </Col>
                                <Col span={12}>
                                    <SharedInput
                                        label={`นามสกุล (ภาษาไทย)`}
                                        // descLabel={t("page._2_100_characters")}
                                        name="name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.name}
                                        errors={errors.name}
                                        touched={touched.name}
                                        parentClassName="mb-6"
                                    />
                                </Col>
                            </Row>
                            <Row gutter={32}>
                                <Col span={24}>
                                    <SharedInput
                                        label={`อีเมล`}
                                        // descLabel={t("page._2_100_characters")}
                                        name="username"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.username}
                                        errors={errors.username}
                                        touched={touched.username}
                                        parentClassName="mb-6"
                                    />
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={16}>
                                    <SharedInput
                                        label={`อีเมล`}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={`123456`}
                                        parentClassName="mb-6"
                                        disable={true}
                                        type="password"
                                    />
                                </Col>
                                <Col span={8}>
                                    <ShareButton
                                        type="default"
                                        size="large"
                                        style={{ marginTop: "42px" }}
                                        className="ant-btn-primary carryout-btn"
                                        text={`เปลี่ยนรหัสผ่าน`}
                                        onClick={() => history.push(PATH_CHANGE_PASSWROD)}
                                    />
                                </Col>
                            </Row>
                        </StyledCard>
                    </div>
                    <div className="btn-group-layout">
                        <div className="btn-group">
                            <StyledSubmitButton
                                type="default"
                                text={t("page.save")}
                                htmlType="submit"
                                // disable={isSubmitting}
                            />
                        </div>
                    </div>
                </div>
            </form>
            <ComponentModal
                isVisible={showModal}
                title={t("page.add_news_type")}
                width={680}
                style={{ marginTop: "14vh" }}
                className={"add-category"}
                onCancel={() => setShowModal(false)}
            >
                <StyledBody></StyledBody>
                <div className="footer">
                    <ShareButton
                        type="primary"
                        size="large"
                        className="add-cat-btn"
                        text={t("page.agree")}
                        onClick={() => setShowModal(false)}
                    />
                </div>
            </ComponentModal>
        </div>
    );
});

const StyledSwitch = styled.div`
    display: flex;
    align-items: center;
    p {
        margin-bottom: 0;
    }
`;

const StyledBody = styled.div`
    .form-input {
        margin-top: 20px;
        p {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #29304d;
        }
    }
`;
