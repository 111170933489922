import { StyleSocialButton, LineLoginIcon, GoogleLoginIcon } from "@components";
import { useTranslation } from "react-i18next";

interface ISocialButton {
    onClick: () => void;
}

export const ComponentLineButton = (props: ISocialButton) => {
    const { t } = useTranslation();
    return (
        <StyleSocialButton {...props}>
            <LineLoginIcon size={24} />
            <span>{t("page.line_login")}</span>
        </StyleSocialButton>
    );
};

export const ComponentFaceBookButton = (props: ISocialButton) => {
    // const FACEBOOK_COLOR = "#3B589E";
    // const FACEBOOK_ICON = "/images/icon/fb-login-icon.png";

    return (
        <StyleSocialButton {...props}>
            {/* <img src={FACEBOOK_ICON} className="social-icon" /> */}
        </StyleSocialButton>
    );
};

export const ComponentGoogleButton = (props: ISocialButton) => {
    // const GOOGLE_COLOR = "#E94132";
    // const GOOGLE_ICON = "/images/icon/gg-login-icon.png";

    return (
        <StyleSocialButton {...props}>
            <GoogleLoginIcon />
            {/* <img src={GOOGLE_ICON} className="social-icon" /> */}
        </StyleSocialButton>
    );
};
