import { useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
// import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/swiper.min.css"; // core Swiper
import "swiper/swiper-bundle.min.js";
import "swiper/swiper-bundle.css";
import Parser from "html-react-parser";
import { CaretDownFilled, CaretUpFilled } from "@ant-design/icons";
import { useState } from "react";
// import { useSelector } from "react-redux";

import { BLANK_IMAGE_URL } from "@configs";
import { StyledPreview, LineShare, FBShare, TwitterShare, Share } from "@components";
import { IClaimCreate } from "@interfaces";

import { useAppSelector, selectApp } from "@redux";
import { LineShareButton, FacebookShareButton, TwitterShareButton } from "react-share";
interface IProps {
    claim: IClaimCreate;
}
SwiperCore.use([Navigation, Pagination]);

export const ComponentClaimPreview = (props: IProps) => {
    //hook
    const { t } = useTranslation();
    // const Theme = useSelector(selectTheme);
    //state
    const app = useAppSelector(selectApp);
    const [toggleShow, setToggleShow] = useState<boolean>(true);

    useEffect(() => {
        if (app.mobile) {
            setToggleShow(false);
        }
    }, [app]);
    // props
    const { name, description, image_url } = props.claim;

    //variables
    // const mainColor = Theme.mainColor;

    const handleToggle = () => {
        setToggleShow(!toggleShow);
    };

    return (
        <StyledPreview toggleShow={toggleShow}>
            <div className="header" onClick={handleToggle}>
                {toggleShow ? <CaretDownFilled /> : <CaretUpFilled />}
                <span>{t("page.preview")}</span>
            </div>
            <div className="page_body">
                <div className="img-field">
                    {image_url ? (
                        <img className="empty-img" src={image_url} />
                    ) : (
                        <img className="empty-img" src={BLANK_IMAGE_URL} />
                    )}
                    {/* <Swiper
                        pagination={true}
                        grabCursor={true}
                        slidesPerView={1}
                        onRealIndexChange={handleChangeIndexImg}
                    >
                        {image_url.length > 0 && (
                            <div className="number-image">{`${indexImg}/${image_url.length}`}</div>
                        )}
                        {image_url.length ? (
                            image_url.map((item, index) => (
                                <SwiperSlide key={index}>
                                    <div className="img-wrap">
                                        <img src={item} />
                                    </div>
                                </SwiperSlide>
                            ))
                        ) : (
                            <SwiperSlide>
                                <div className="img-wrap">
                                    <img className="empty-img" src={BLANK_IMAGE_URL} />
                                </div>
                            </SwiperSlide>
                        )}
                    </Swiper> */}
                </div>
                <div className="name-field">
                    <h5>{name}</h5>
                    {/* <div className="date">
                        <CalendarIcon />
                        <p>{moment(date_from).format(dateFormatMonth)}</p>
                    </div> */}
                </div>
                <div className="description-field">
                    <p>{Parser(description)}</p>
                </div>
                <div className="footer">
                    <div className="btn-main">
                        <p>{t("page.share")}</p>
                        <div className="social">
                            <LineShareButton url="">
                                <LineShare />
                            </LineShareButton>
                            <FacebookShareButton url="">
                                <FBShare />
                            </FacebookShareButton>
                            <TwitterShareButton url="">
                                <TwitterShare />
                            </TwitterShareButton>
                            <StyledShare>
                                <Share />
                            </StyledShare>
                        </div>
                    </div>
                    <div className="footer-line">
                        <div className="line"></div>
                    </div>
                </div>
            </div>
        </StyledPreview>
    );
};
const StyledShare = styled.div`
    cursor: pointer;
`;
