import { Badge, Drawer, Layout, Select, Spin } from "antd";

import { useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { MenuMode, MenuTheme } from "antd/lib/menu";
import { useTranslation } from "react-i18next";
import { useState, useRef, useEffect } from "react";
import { capitalize } from "@utils";

import {
    MobileComplaintSidebar,
    MobileHomeSidebar,
    MobilePrivilegeSidebar,
    StyledSidebar,
    ThinArrow,
} from "@components";
import { selectApp, setMobileDrawer, setMobileMenu, useAppSelector } from "@redux";
import {
    PATH_HOME,
    PATH_NEWS_ALL,
    PATH_NEWS_HIGHLIGHT,
    PATH_NEWS,
    PATH_CREATE_NEWS,
    PATH_COMPLAINT,
    PATH_COMPLAINT_CREATE,
    PATH_COMPLAINT_CATEGORY,
    PATH_COMPLAINT_DIVISIONS,
    PATH_ACTIVITY,
    PATH_ACTIVITY_CREATE,
    PATH_BIDDING,
    PATH_BIDDING_PURCHASE_LIST,
    PATH_BIDDING_RESULT_LIST,
    PATH_BIDDING_EMPLOY_LIST,
    PATH_HOME_MENU,
    PATH_CLAIM,
    PATH_CLAIM_CREATE,
    PATH_PRIVILEGES,
    PATH_PRIVILEGES_CREATE,
    PATH_REDEEM_PRIVILEGES,
    PATH_HOME_ABOUT,
    PATH_HOME_ORG,
    PATH_HOME_DECS_REPORT,
    PATH_HOME_SER_INFO,
    PATH_SETTINGS,
    PATH_THEMES,
    PATH_PROFILE,
    PATH_PERMISSIONS,
    PATH_BOOKING,
    PATH_SERVICES,
    PATH_DEPARTMENT,
    PATH_SERVICES_CREATE,
    PATH_DEPARTMENT_CREATE,
    PATH_INTEGRATION_SETTINGS,
    PATH_ADMIN,
    PATH_PERMISSIONS_CREATE,
    PATH_ADMIN_CREATE,
    PATH_TRASH_FEE,
    enumMobileSidebar,
} from "@configs";
import { SettingsSidebar } from "./sidebar/SettingsSidebar";
import { BookingSidebar } from "./sidebar/BookingSidebar";
import { TrashFeeSidebar } from "./sidebar/TrashFeeSidebar";
import { ClaimSidebar } from "./sidebar/ClaimSidebar";
import { ComplaintSidebar } from "./sidebar/ComplainSidebar";
import styled from "styled-components";
import { ActivitySidebar } from "./sidebar/ActivitySidebar";
import { NewsSidebar } from "./sidebar/NewsSidebar";
import { BiddingSidebar } from "./sidebar/BiddingSidebar";
import { HomeSidebar } from "./sidebar/HomeSidebar";

const { Sider } = Layout;
const { Option } = Select;
const getKey = (name: string, index: number) => {
    const string = `${name}-${index}`;
    const key = string.replace(" ", "-");
    return key.charAt(0).toLowerCase() + key.slice(1);
};

interface IMobileMenu {
    name: enumMobileSidebar;
}
const mobileSidebarMenu: IMobileMenu[] = [
    // {
    //     name: enumMobileSidebar.HOME,
    // },
    {
        name: enumMobileSidebar.COMPLAINT,
    },
    {
        name: enumMobileSidebar.PRIVILEGE,
    },
];
interface IProps {
    sidebarTheme: MenuTheme;
    sidebarMode: MenuMode;
    sidebarIcons: boolean;
    collapsed: boolean;
}

export const ComponentSidebar = ({
    sidebarTheme,
    sidebarMode,
    sidebarIcons,
    collapsed,
}: IProps) => {
    //hook
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const sidebarRef = useRef();
    const [openKeys, setOpenKeys] = useState<string[]>([]);
    const app = useAppSelector(selectApp);
    const [sidebarLoading, setSidebarLoading] = useState<boolean>(false);
    const pathname = location.pathname;

    useEffect(() => {
        if (sidebarRef.current) {
            //@ts-ignore
            sidebarRef.current.scrollIntoView({
                behavior: "auto",
                block: "center",
                inline: "nearest",
            });
        }
    }, [pathname]);

    const noSidebarRoute = () => location.pathname === PATH_HOME;

    const isConplaintRoutes = (route: string) =>
        route === PATH_COMPLAINT ||
        route === PATH_COMPLAINT_CREATE ||
        route === PATH_COMPLAINT_CATEGORY ||
        route === PATH_COMPLAINT_DIVISIONS ||
        route.includes(PATH_COMPLAINT);

    const isTrashFeeRoutes = (route: string) => route.includes(PATH_TRASH_FEE);

    const isActivityRoutes = (route: string) =>
        route === PATH_ACTIVITY || route === PATH_ACTIVITY_CREATE || route.includes(PATH_ACTIVITY);

    const isClaimRoutes = (route: string) =>
        route === PATH_PRIVILEGES ||
        route === PATH_PRIVILEGES_CREATE ||
        route === PATH_REDEEM_PRIVILEGES ||
        route === PATH_CLAIM ||
        route === PATH_CLAIM_CREATE ||
        route.includes(PATH_CLAIM) ||
        route.includes(PATH_PRIVILEGES);

    const isNewsRoutes = (route: string) =>
        route === PATH_NEWS ||
        route === PATH_NEWS_ALL ||
        route === PATH_NEWS_HIGHLIGHT ||
        route === PATH_CREATE_NEWS ||
        route.includes(PATH_NEWS);

    const isBiddingRoutes = (route: string) =>
        route === PATH_BIDDING ||
        route === PATH_BIDDING_PURCHASE_LIST ||
        route === PATH_BIDDING_EMPLOY_LIST ||
        route === PATH_BIDDING_RESULT_LIST ||
        route.includes(PATH_BIDDING);

    const isHomeMenu = (route: string) =>
        route === PATH_HOME_MENU ||
        route === PATH_HOME_ABOUT ||
        route === PATH_HOME_ORG ||
        route === PATH_HOME_DECS_REPORT ||
        route === PATH_HOME_SER_INFO;

    const isSettings = (route: string) =>
        route === PATH_SETTINGS ||
        route === PATH_THEMES ||
        route === PATH_PROFILE ||
        route === PATH_INTEGRATION_SETTINGS ||
        route === PATH_PERMISSIONS ||
        route === PATH_PERMISSIONS_CREATE ||
        route === PATH_ADMIN ||
        route === PATH_ADMIN_CREATE ||
        route.includes(PATH_ADMIN) ||
        route.includes(PATH_PERMISSIONS);

    const isBooking = (route: string) =>
        route === PATH_BOOKING ||
        route === PATH_SERVICES ||
        route === PATH_DEPARTMENT ||
        route === PATH_SERVICES_CREATE ||
        route === PATH_DEPARTMENT_CREATE ||
        route.includes(PATH_DEPARTMENT) ||
        route.includes(PATH_SERVICES);
    const badgeTemplate = (badge: { value: string }) => <Badge count={badge.value} />;

    const handleChangeMenu = (value: enumMobileSidebar) => {
        setSidebarLoading(true);
        dispatch(setMobileMenu(value));
        setTimeout(() => {
            setSidebarLoading(false);
        }, 700);
    };
    return (
        <>
            {!noSidebarRoute() && (
                <div className="side-bar-menu">
                    <StyledSidebar>
                        {!app.mobile && (
                            <div className="sidebar-wrap">
                                <Sider
                                    width={240}
                                    className={`bg-${sidebarTheme}`}
                                    theme={sidebarTheme}
                                    collapsed={collapsed}
                                >
                                    {isNewsRoutes(pathname) && <NewsSidebar />}
                                    {isActivityRoutes(pathname) && <ActivitySidebar />}
                                    {isConplaintRoutes(pathname) && <ComplaintSidebar />}
                                    {isBiddingRoutes(pathname) && <BiddingSidebar />}
                                    {isHomeMenu(pathname) && <HomeSidebar />}
                                    {isClaimRoutes(pathname) && <ClaimSidebar />}
                                    {isSettings(pathname) && <SettingsSidebar />}
                                    {isBooking(pathname) && <BookingSidebar />}
                                    {isTrashFeeRoutes(pathname) && <TrashFeeSidebar />}
                                </Sider>
                            </div>
                        )}
                    </StyledSidebar>
                </div>
            )}
            <Drawer
                closable={false}
                width={275}
                placement={"left"}
                onClose={() => dispatch(setMobileDrawer(!app.mobileDrawer))}
                visible={app.mobileDrawer}
                // visible={true}
                className="sidebar-drawer"
            >
                <StyledSidebar>
                    <div
                        style={{
                            overflow: "hidden",
                            flex: "1 1 auto",
                            flexDirection: "column",
                            display: "flex",
                            height: "80vh",
                            maxHeight: "80vh",
                            overflowY: "scroll",
                        }}
                    >
                        <div className="sidebar-layout">
                            <ComponentSidebarDropdown
                                onChange={handleChangeMenu}
                                value={app.currentMenu}
                            />
                        </div>
                        {sidebarLoading ? (
                            <Spin />
                        ) : (
                            <div>
                                {/* {app.currentMenu === enumMobileSidebar.HOME && (
                                    <MobileHomeSidebar />
                                )} */}
                                {app.currentMenu === enumMobileSidebar.COMPLAINT && (
                                    <MobileComplaintSidebar />
                                )}
                                {app.currentMenu === enumMobileSidebar.PRIVILEGE && (
                                    <MobilePrivilegeSidebar />
                                )}
                            </div>
                        )}
                    </div>
                </StyledSidebar>
            </Drawer>
        </>
    );
};
interface Iprops {
    onChange: (value: enumMobileSidebar) => void;
    value: enumMobileSidebar;
}
const ComponentSidebarDropdown = (props: Iprops) => {
    const { t } = useTranslation();
    return (
        <StyledDropdown>
            <Select value={props.value} onChange={props.onChange} suffixIcon={<ThinArrow />}>
                {/*  */}
                {mobileSidebarMenu.map((item) => (
                    <Option key={item.name}>{capitalize(t(`page.sidebar.${item.name}`))}</Option>
                ))}
            </Select>
        </StyledDropdown>
    );
};

const StyledDropdown = styled.div`
    .ant-select {
        width: 244px;
        height: 45px;
        .ant-select-selection {
            height: 100%;
            background: #0d6efd;
            border-color: #0d6efd;
            color: #fff;
            font-weight: bold;
            &:hover,
            :focus,
            :focus-within {
                border-color: #0d6efd !important;
            }
            .ant-select-arrow {
                color: #fff;
            }
            .ant-select-selection__rendered {
                height: 100%;
                display: flex;
                align-items: center;
                font-size: 16px;
            }
            .ant-select-arrow {
                top: 39% !important;
                svg {
                    transition: 0.3s;
                }
            }
        }
    }
    .ant-select-open {
        .ant-select-selection {
            .ant-select-arrow {
                svg {
                    transform: rotate(180deg);
                }
            }
        }
    }
`;
