import { useEffect } from "react";
import { StyledNewDetail } from "@components";
import { ModuleComplainForm } from "@modules";
import { selectAuth } from "@redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { PATH_HOME } from "@configs";
import { useNotify, usePermission } from "@utils";
import { useTranslation } from "react-i18next";

export const PageConplaintCreate = () => {
    const permisssion = useSelector(selectAuth).permission;
    usePermission({ permission: permisssion?.permission_list.complain.is_manage });

    return <StyledNewDetail>{<ModuleComplainForm />}</StyledNewDetail>;
};
