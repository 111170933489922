import { useState, useEffect } from "react";
import {
    StyledTable,
    ComponentEmptyData,
    StyledTableButtonTop,
    ComponentRowPermission,
} from "@components";
import { Row, Col, Pagination } from "antd";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import { PAGINATION, PAGE_START, INITIAL_LIST_PARAMS, PATH_PERMISSIONS_CREATE } from "@configs";
import { IPaginationNext, IPermission } from "@interfaces";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth, selectOrg, setLoading } from "@redux";
import { useNotify, usePermission } from "@utils";
import { useHistory } from "react-router";
import { permissionAPI } from "@api";

const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: PAGINATION,
};

export const PagePermissionsList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const org_id = useSelector(selectAuth).auth?.organization_data?._id;
    const permission = useSelector(selectAuth).permission;
    const categories = useSelector(selectOrg).category;
    const [pagination, setPagination] = useState<IPaginationNext>(INITAL_PAGE);
    const [permissions, setPermissions] = useState<IPermission[]>([]);
    const [total, setTotal] = useState<number>(0);
    const { error } = useNotify();
    const history = useHistory();
    usePermission({ permission: permission?.permission_list.organization.is_manage_permission });
    useEffect(() => {
        fetchPermissions(pagination);
    }, [pagination]);

    const fetchPermissions = async (pagination: IPaginationNext) => {
        dispatch(setLoading(true));
        try {
            const response = await permissionAPI.getPermissions({
                organization_id: org_id || "",
                ...pagination,
            });
            setPermissions(response.data.result);
            setTotal(response.data.total);
            // success(response.data.msg);
        } catch (err: any) {
            error(err.msg);
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const callbackGetList = () => {
        fetchPermissions(pagination);
    };

    const handlePagination = (page) => {
        setPagination({ xpage: page, xlimit: pagination.xlimit });
    };

    return (
        <StyledTable>
            <div className="page-layout small-layout">
                <div className="page-header-layout">
                    <div className="page-header">
                        <div>
                            <h3>{`รายการสิทธิผู้ใช้งาน`}</h3>
                        </div>
                        <div className="flex-header">
                            <StyledTableButtonTop
                                type="danger"
                                size="default"
                                text={`สร้างสิทธิผู้ใช้งาน`}
                                onClick={() => history.push(PATH_PERMISSIONS_CREATE)}
                                className="add-btn"
                                icon="plus"
                            />
                        </div>
                    </div>
                </div>
                <div className="page-table-layout">
                    <div className="table">
                        <ScrollContainer vertical={false}>
                            <div className="table_ground">
                                <div className="table-body">
                                    <div className="table-header">
                                        <Row
                                            gutter={24}
                                            type="flex"
                                            justify="space-between"
                                            align="middle"
                                            className="header-row"
                                        >
                                            <Col className="col-item" span={8}>
                                                <p>{`รายชื่อสิทธิการใช้งาน`}</p>
                                            </Col>
                                            <Col className="col-item" span={8}>
                                                <p>{`รายละเอียด`}</p>
                                            </Col>
                                            <Col className="col-item last-item" span={8}>
                                                <p>{`จัดการ`}</p>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div>
                                        {permissions && permissions.length > 0 ? (
                                            <div className="data-table">
                                                {permissions.map((item, index) => (
                                                    <ComponentRowPermission
                                                        key={index}
                                                        data={item}
                                                        itemJson={JSON.stringify(item)}
                                                        categories={categories}
                                                        callbackGetList={callbackGetList}
                                                        index={index}
                                                    />
                                                ))}
                                                <div style={{ width: "100%", height: 10 }}></div>
                                            </div>
                                        ) : (
                                            <ComponentEmptyData />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </ScrollContainer>
                    </div>
                </div>
                <div className="page-bottom">
                    {total > 0 && (
                        <div className="pagination">
                            <Pagination
                                defaultCurrent={INITIAL_LIST_PARAMS.page || 1}
                                pageSize={PAGINATION}
                                total={total}
                                onChange={handlePagination}
                                current={pagination.xpage}
                            />
                        </div>
                    )}
                </div>
            </div>
        </StyledTable>
    );
};
