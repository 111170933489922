import { YupCoupon } from "./YupCoupon";
import { YupFreePoint } from "./YupFreePoint";
import { YupReward } from "./YupReward";
import { YupTrafficSource } from "./YupTrafficSource";
import { YupContact } from "./YupContact";
import { YupSignUpMethodField } from "./YupSignUpMethod";
import { YupIntegration } from "./YupIntegration";
import { YupBenefit } from "./YupBenefit";
import { YupChangePassword } from "./YupChangePassword";
import { YupMembershipTier } from "./YupMembershipTier";
import { YupCompany } from "./YupCompany";
import { YupNewMembershipTier } from "./YupNewMembershipTier";
import { YupNewMerchant } from "./YupNewMerchant";
import { YupNews } from "./YupNews";
import { YupComplaint } from "./YupComplaint";
import { YupDivision } from "./YupDivsion";
import { YupComplaintCategory } from "./YupComplaintCategory";
import { YupActivity } from "./YupActivity";
import { YupBidding } from "./YupBidding";
import { YupClaim } from "./YupClaim";
import { YupSetting } from "./YupSetting";
import { YupTheme } from "./YupTheme";
import { YupPermission } from "./YupPermission";
import { YupInviteAdmin } from "./YupInviteAdmin";
import { YupActivityCategory } from "./YupActivityCategory";
import { YupService } from "./YupService";
import { YupDept } from "./YupDept";
export const useYup = () => {
    return {
        YupCoupon: YupCoupon(),
        YupFreePoint: YupFreePoint(),
        YupReward: YupReward(),
        YupTrafficSource: YupTrafficSource(),
        YupContact: YupContact(),
        YupSignUpMethodField: YupSignUpMethodField(),
        YupIntegration: YupIntegration(),
        YupBenefit: YupBenefit(),
        YupChangePassword: YupChangePassword(),
        YupMembershipTier: YupMembershipTier(),
        YupNewMembershipTier: YupNewMembershipTier(),
        YupCompany: YupCompany(),
        YupNewMerchant: YupNewMerchant(),
        YupNews: YupNews(),
        YupComplaint: YupComplaint(),
        YupDivision: YupDivision(),
        YupComplaintCategory: YupComplaintCategory(),
        YupActivity: YupActivity(),
        YupBidding: YupBidding(),
        YupClaim: YupClaim(),
        YupSetting: YupSetting(),
        YupTheme: YupTheme(),
        YupPermission: YupPermission(),
        YupInviteAdmin: YupInviteAdmin(),
        YupActivityCategory: YupActivityCategory(),
        YupService: YupService(),
        YupDept: YupDept(),
    };
};
