import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { enumValidation } from "@configs";

export const YupClaim = () => {
    //page hook
    const { t } = useTranslation();

    return {
        name: Yup.string()
            .required(
                t("validation.required", {
                    name: t("page.headlines"),
                })
            )
            .max(
                enumValidation.MAX_TITLE_INPUT,
                t("validation.max", {
                    name: t("page.headlines"),
                    number: enumValidation.MAX_TITLE_INPUT,
                })
            ),
    };
};
