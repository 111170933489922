import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { ITrashfeeArea, ITrashfeeDistrict } from "@interfaces";
import { ModuleAreaForm } from "@modules";
import { useNotify, usePermission } from "@utils";
import { trashfeeAPI } from "@api";
import { setLoading, selectAuth } from "@redux";
import { StyledAreaDetail } from "@components";

export const PageAreaDetail = () => {
  const { id } = useParams<{ id: string }>();
  const org_id = useSelector(selectAuth).auth?.organization_data?._id;
  const permisssion = useSelector(selectAuth).permission;
  const dispatch = useDispatch();
  const { warning } = useNotify();
  const [areaDetail, setAreaDetail] = useState<ITrashfeeArea>();

  usePermission({ permission: permisssion?.permission_list.trash_fee.is_manage });
  useEffect(() => {
    fetchArea();
  }, []);

  const fetchArea = async () => {
    if (id === "create") {
      // create page
    }
    else {
      dispatch(setLoading(true));
      try {
        const response = await trashfeeAPI.getAreaDetail({
          organization_id: org_id || "",
          area_id: id,
        });
        setAreaDetail(response.data.result);
        dispatch(setLoading(false));
      } catch (err: any) {
        warning(err.message);
        dispatch(setLoading(false));
      }
    }
  };


  return <StyledAreaDetail>{<ModuleAreaForm detail={areaDetail} />}</StyledAreaDetail>;
};
