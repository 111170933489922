import { useState, useRef, useEffect } from "react";
import {
    ComponentContactSortIcon,
    ComponentRowNews,
    StyledTable,
    ComponentEmptyData,
    ComponentCategoriesFilter,
} from "@components";
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    TouchSensor,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    rectSortingStrategy,
    //@ts-ignore
} from "@dnd-kit/sortable";
import { Row, Col, Pagination } from "antd";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import {
    enumSortBy,
    enumContactSortFields,
    PAGINATION,
    PAGE_START,
    INITIAL_LIST_PARAMS,
    enumNewsType,
} from "@configs";
import { IContactListParams, IPaginationNext, INews } from "@interfaces";
import { useDispatch, useSelector } from "react-redux";
import { getCategories, selectAuth, selectContact, selectOrg, setLoading } from "@redux";
import { newsAPI } from "src/API/news";
import { useNotify, usePermission } from "@utils";

const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: PAGINATION,
};

export const PageHotNewsList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const search_params = useSelector(selectContact).paramsContact;
    const pemisssion = useSelector(selectAuth).permission;
    const [params, setParams] = useState<IContactListParams>(search_params);
    const typingTimeoutRef = useRef(0);
    const org_id = useSelector(selectAuth).auth?.organization_data?._id;
    const categories = useSelector(selectOrg).category;
    const [filterId, setFilterId] = useState<string>("");
    const [pagination, setPagination] = useState<IPaginationNext>(INITAL_PAGE);
    const [news, setNews] = useState<INews[]>([]);
    const [total, setTotal] = useState<number>(0);
    const { success, error } = useNotify();
    usePermission({ permission: pemisssion?.permission_list.news.is_manage });
    useEffect(() => {
        fetchNews(pagination, filterId);
    }, [pagination, filterId]);
    useEffect(() => {
        fetchNewsCategory();
    }, []);

    const fetchNewsCategory = async () => {
        await dispatch(getCategories({ organization_id: org_id || "" }));
    };
    const fetchNews = async (pagination: IPaginationNext, filterId: string) => {
        dispatch(setLoading(true));
        try {
            const response = await newsAPI.getList({
                organization_id: org_id || "",
                ...pagination,
                ...{ is_hot_news: true },
                ...(filterId && { news_category_id: filterId }),
            });

            setNews(response.data.result);
            setTotal(response.data.total);
            // success(response.data.msg);
        } catch (err: any) {
            error(err.msg);
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleSort = (sortField: enumContactSortFields, sortBy: enumSortBy) => {
        const newSortParams = { ...params, sortField, sortBy };
        setParams(newSortParams);
        // fetchContacts(newSortParams);
    };
    const updateNews = async (isEnable: boolean, data: INews) => {
        dispatch(setLoading(true));
        try {
            const payload = {
                organization_id: data.organization_id,
                title: data.title,
                detail: data.detail,
                image_url_list: data.image_url_list,
                is_hot_news: data.is_hot_news,
                news_category_id: data.news_category_id,
                date_from: data.date_from,
                date_to: data.date_to,
                is_enable: isEnable,
                news_id: data._id,
            };
            const res = await newsAPI.update(payload);
            callbackGetList();
            if (res.data.error === "1") {
                error(t(res.data.msg));
            } else {
                success(t(res.data.msg));
            }
        } catch (error) {
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };
    const callbackGetList = () => {
        fetchNews(pagination, filterId);
    };
    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            const swapItem = (items: INews[]) => {
                const oldIndex = items.findIndex((item) => JSON.stringify(item) === active.id);
                const newIndex = items.findIndex((item) => JSON.stringify(item) === over.id);
                return arrayMove(items, oldIndex, newIndex);
            };
            setNews(swapItem);
        }
    };
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
        useSensor(TouchSensor)
    );
    const handlePagination = (page) => {
        setPagination({ xpage: page, xlimit: pagination.xlimit });
    };
    return (
        <StyledTable>
            <div className="page-layout">
                <div className="page-header-layout">
                    <div className="page-header">
                        <div>
                            <h3>{t("page.hightlight")}</h3>
                        </div>
                    </div>
                    <div className="search-layout">
                        <ComponentCategoriesFilter
                            categories={categories}
                            filterId={filterId}
                            setFilterId={setFilterId}
                        />
                    </div>
                </div>
                <div className="page-table-layout">
                    <div className="table">
                        <ScrollContainer vertical={false}>
                            <div className="table_ground">
                                <div className="table-body">
                                    <div className="table-header">
                                        <Row
                                            gutter={24}
                                            type="flex"
                                            justify="space-between"
                                            align="middle"
                                            className="header-row"
                                        >
                                            <Col className="col-item" span={5}>
                                                <p>{t("page.headlines")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.FULL_NAME}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.FULL_NAME}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={3}>
                                                <p>{t("page.news_type")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.TEL}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.TEL}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={3}>
                                                <p>{t("page.date_creation")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={3}>
                                                <p>{t("page.display_period")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.POINTS}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={3}>
                                                <p>{t("page.active")}</p>
                                                <div className="filter-button">
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.ASC}
                                                        sortField={enumContactSortFields.CREATED_AT}
                                                        searchParams={params}
                                                    />
                                                    <ComponentContactSortIcon
                                                        handleSort={handleSort}
                                                        sortBy={enumSortBy.DESC}
                                                        sortField={enumContactSortFields.CREATED_AT}
                                                        searchParams={params}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-item" span={3}>
                                                <p>{t("page.news_manage")}</p>
                                            </Col>
                                        </Row>
                                    </div>
                                    <DndContext
                                        sensors={sensors}
                                        collisionDetection={closestCenter}
                                        onDragEnd={handleDragEnd}
                                    >
                                        <SortableContext
                                            items={news.map((item) => JSON.stringify(item))}
                                            strategy={rectSortingStrategy}
                                        >
                                            {news && news.length > 0 ? (
                                                <div className="data-table">
                                                    {news.map((item, index) => (
                                                        <ComponentRowNews
                                                            key={index}
                                                            data={item}
                                                            newType={enumNewsType.HOT_NEWS}
                                                            itemJson={JSON.stringify(item)}
                                                            categories={categories}
                                                            handleEdit={updateNews}
                                                            callbackGetList={callbackGetList}
                                                            index={index}
                                                        />
                                                    ))}
                                                    <div
                                                        style={{ width: "100%", height: 10 }}
                                                    ></div>
                                                </div>
                                            ) : (
                                                <ComponentEmptyData />
                                            )}
                                        </SortableContext>
                                    </DndContext>
                                </div>
                            </div>
                        </ScrollContainer>
                    </div>
                </div>
                <div className="page-bottom">
                    {total > 0 && (
                        <div className="pagination">
                            <Pagination
                                defaultCurrent={INITIAL_LIST_PARAMS.page || 1}
                                pageSize={PAGINATION}
                                total={total}
                                onChange={handlePagination}
                                current={pagination.xpage}
                            />
                        </div>
                    )}
                </div>
            </div>
        </StyledTable>
    );
};
