import { Badge, Menu } from "antd";

import { useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useState, useRef, useEffect } from "react";
import { capitalize } from "@utils";

import {
    StyledSidebar,
    HomeOutline,
    HomeAboutIcon,
    HomeORGIcon,
    HomeDecsIcon,
    HomeSerIcon,
} from "@components";
import { selectApp, setMobileDrawer, useAppSelector } from "@redux";
import {
    enumPermission,
    PATH_HOME_MENU,
    PATH_HOME_ABOUT,
    PATH_HOME_ORG,
    PATH_HOME_DECS_REPORT,
    PATH_HOME_SER_INFO,
} from "@configs";

interface ISideBarRoute {
    path?: string;
    name: string;
    icon: JSX.Element;
    permission?: string;
    children?: Array<{
        path: string;
        name: string;
        permission?: string;
        badge?: {
            value: string;
        };
    }>;
    badge?: {
        value: string;
    };
}
const homeMenuRoutes: ISideBarRoute[] = [
    {
        path: PATH_HOME_MENU,
        name: "home_menu",
        permission: enumPermission.MANAGE_HOME_MENU,
        icon: <HomeOutline />,
    },
    {
        path: PATH_HOME_ABOUT,
        name: "about",
        permission: enumPermission.MANAGE_HOME_MENU,
        icon: <HomeAboutIcon />,
    },
    {
        path: PATH_HOME_ORG,
        name: "org",
        permission: enumPermission.MANAGE_HOME_MENU,
        icon: <HomeORGIcon />,
    },
    {
        path: PATH_HOME_DECS_REPORT,
        name: "docs_report",
        permission: enumPermission.MANAGE_HOME_MENU,
        icon: <HomeDecsIcon />,
    },
    {
        path: PATH_HOME_SER_INFO,
        name: "ser_info",
        permission: enumPermission.MANAGE_HOME_MENU,
        icon: <HomeSerIcon />,
    },
];
const { SubMenu } = Menu;
export const HomeSidebar = () => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const sidebarRef = useRef();
    const [openKeys, setOpenKeys] = useState<string[]>([]);
    const app = useAppSelector(selectApp);
    const pathname = location.pathname;

    useEffect(() => {
        if (sidebarRef.current) {
            //@ts-ignore
            sidebarRef.current.scrollIntoView({
                behavior: "auto",
                block: "center",
                inline: "nearest",
            });
        }
    }, [pathname]);

    const badgeTemplate = (badge: { value: string }) => <Badge count={badge.value} />;

    const switchRoute = (path: string, permission?: string, index?: string) => {
        if (app.mobile) dispatch(setMobileDrawer(!app.mobileDrawer));
        if (permission) {
            history.push(path);
        } else {
            history.push(path);
        }
    };

    const handleOpenKey = (e) => {
        setOpenKeys([...openKeys, e.key]);
    };
    const getKey = (name: string, index: number) => {
        const string = `${name}-${index}`;
        const key = string.replace(" ", "-");
        return key.charAt(0).toLowerCase() + key.slice(1);
    };
    return (
        <StyledSidebar>
            <Menu
                theme={"light"}
                className="border-0 scroll-y "
                style={{ flex: 1 }}
                mode={"inline"}
                defaultOpenKeys={["0", "1"]}
                onClick={handleOpenKey}
            >
                {homeMenuRoutes.map((route, index) => {
                    return (
                        <SubMenu
                            className={`menu-after ${route.name}`}
                            key={index}
                            onTitleClick={() => {
                                if (route.path && route.permission) {
                                    switchRoute(
                                        route.path,
                                        route.permission || "",
                                        index.toString()
                                    );
                                }
                            }}
                            title={
                                <span>
                                    <span className="anticon" style={{ marginBottom: 5 }}>
                                        {route.icon}
                                    </span>
                                    <span
                                        className={
                                            pathname === route.path
                                                ? "selected-news menu-item-text"
                                                : "menu-item-text"
                                        }
                                        style={{
                                            color: "black",
                                            fontWeight: 700,
                                            fontSize: 16,
                                        }}
                                    >
                                        {capitalize(t("page.sidebar." + route.name))}
                                    </span>
                                    {route.badge && badgeTemplate(route.badge)}
                                </span>
                            }
                        ></SubMenu>
                    );
                })}
            </Menu>
        </StyledSidebar>
    );
};
