import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Switch, Col, Row, Avatar, Menu, Dropdown, Button } from "antd";
import { MoreHorizontal } from "react-feather";
import moment from "moment";

import { INewsCategory, IReward } from "@interfaces";
import { rewardAPI } from "@api";
import { dateFormat, dateFormatHM, defaultImage, enumNewsType, PATH_PRIVILEGES } from "@configs";
import { selectAuth } from "@redux";
import { useSelector } from "react-redux";
import { IconDrag } from "@components";
import { showErrorMessage, useNotify } from "@utils";
import { useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
interface IProps {
    data: IReward;
    itemJson: string;
    handleEdit: (status: boolean, data: IReward) => void;
    callbackGetList: () => void;
    index: number;
    newType?: enumNewsType;
    categories: INewsCategory[];
}

export const ComponentRowReward = (props: IProps) => {
    //hooks
    const { t } = useTranslation();
    const { success, error } = useNotify();
    const organization_id = useSelector(selectAuth).auth?.organization_data?._id;
    //props
    const { data, handleEdit, callbackGetList, itemJson, newType } = props;
    const [show, setShowModal] = useState(false);

    const { attributes, listeners, setNodeRef, transform } = useSortable({
        id: itemJson,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
    };

    const handleDelete = async () => {
        try {
            const res = await rewardAPI.delete({
                organization_id: organization_id || "",
                reward_id: data._id,
            });
            if (res.data.error === "0") {
                callbackGetList();
                success(res.data.msg);
                setShowModal(false);
            } else {
                error(res.data.msg);
            }
        } catch (err: any) {
            if (err) {
                error(err.msg);
            } else error(t("message.delete.fail"));
        }
    };

    const menu = (
        <Menu>
            <Menu.Item key={1} onClick={handleDelete}>
                <div className="duplicate-btn">{t("page.delete")}</div>
            </Menu.Item>
        </Menu>
    );
    const handleUpdateStatus = (e: boolean) => {
        handleEdit(e, data);
    };
    return (
        <div className="table-element" ref={setNodeRef} style={style}>
            <div className="drag-icon" {...attributes} {...listeners}>
                <IconDrag />
            </div>

            <div className="table-row">
                <Row
                    style={{ height: "100%" }}
                    gutter={24}
                    type="flex"
                    align="middle"
                    justify="space-between"
                >
                    <Col className="col-item" lg={5} md={5} xs={14} sm={14}>
                        <Avatar
                            shape="square"
                            src={data?.image_url_list[0] || defaultImage}
                            size={84}
                            style={{ minWidth: 84 }}
                        />
                        <Link to={`${PATH_PRIVILEGES}/${data._id}`}>
                            <p className="news-title">{data.name || t("page.empty_text")}</p>
                        </Link>
                    </Col>
                    <Col className="col-item" span={3}>
                        <p className="eng">
                            {data.total_claim}{" "}
                            <span className="eng">{`/ ${
                                data.reward_limit === 0 ? "-" : data.reward_limit
                            }`}</span>
                        </p>
                    </Col>

                    <Col className="col-item visible-md" span={3}>
                        <p className="eng">
                            {moment(data.created_date).format(dateFormatHM)}
                            <br />
                            {moment(data.updated_date).format(dateFormatHM)}
                        </p>
                    </Col>
                    <Col className="col-item m-member-code" xs={10} sm={10} lg={3} md={3}>
                        <p className="visible-md eng">
                            {moment(data.created_date).format(dateFormat) || t("page.empty_text")}
                        </p>
                    </Col>
                    <Col className="col-item visible-md" span={3}>
                        <Switch onChange={handleUpdateStatus} checked={data.is_enable} />
                    </Col>
                    <Col className="col-item" span={3}>
                        <Dropdown
                            //@ts-ignore
                            getPopupContainer={(trigger) => trigger.parentNode}
                            overlay={menu}
                            trigger={["click"]}
                            placement="bottomRight"
                        >
                            <Button style={{ border: "unset" }}>
                                <MoreHorizontal />
                            </Button>
                        </Dropdown>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
