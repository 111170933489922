import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { ITrashfeeArea, ITrashfeeDistrict, ITrashfeeHouse } from "@interfaces";
import { ModuleBillAutoForm, ModuleBillForm, ModuleBusinessForm } from "@modules";
import { useNotify, usePermission } from "@utils";
import { trashfeeAPI } from "@api";
import { setLoading, selectAuth } from "@redux";
import { StyledAreaDetail } from "@components";

export const PageBillAutoDetail = () => {
  const { id } = useParams<{ id: string }>();
  const org_id = useSelector(selectAuth).auth?.organization_data?._id;
  const permisssion = useSelector(selectAuth).permission;
  const dispatch = useDispatch();
  const { warning } = useNotify();
  const [areaDetail, setAreaDetail] = useState<ITrashfeeArea>();
  const [districtList, setDistrictList] = useState<ITrashfeeDistrict[]>([]);
  const [areaList, setAreaList] = useState<ITrashfeeArea[]>([]);
  const [houseDetail, setHouseDetail] = useState<ITrashfeeHouse>();

  usePermission({ permission: permisssion?.permission_list.trash_fee.is_manage });
  useEffect(() => {
    // fetchHouse();
    // fetchDistrict();
    // fetchAreaList();
  }, []);


  const fetchAreaList = async () => {
    try {
      const response = await trashfeeAPI.getAreaList({
        organization_id: org_id || "",
        xpage: "1",
      });
      setAreaList(response.data.result);
      dispatch(setLoading(false));
    } catch {
      dispatch(setLoading(false));
    }
  };

  return <StyledAreaDetail>
    <ModuleBillAutoForm
      districtList={districtList}
      areaList={areaList}
    />
  </StyledAreaDetail>;
};
