import { IPagination } from "@interfaces";
import { enumTimeLimit } from "./enum";
export const AWS_KEY = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
export const AWS_SECRET_KEY = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
export const AWS_BUCKET_NAME = process.env.REACT_APP_AWS_S3_BUCKET_NAME;
export const MAPS_API_KEY = process.env.REACT_APP_MAP_KEY;
export const PAGINATION = 10;
export const PAGE_START = 1;
export const DEFAULT_LANGUAGE = process.env.REACT_APP_LANGUAGE || "th";
export const IMAGE_TYPE = ["image/jpeg", "image/jpg", "image/png"];
export const ROCKET_CALENDLY = process.env.REACT_APP_ROCKET_CALENDLY;
export const ROCKET_LINE = process.env.REACT_APP_ROCKET_LINE;
export const ROCKET_LANDING_PAGE = process.env.REACT_APP_LANDING_PAGE;
export const LOU_ID = process.env.REACT_APP_LOU_ID;
export const MENU_BANNER_WIDTH = 1200;
export const MENU_BANNER_HEIGHT = 450;
export const MAX_CHAR = 120;
export const prefix = ["Mr", "Ms", "Mrs"];
export const ALL = "all";
export const serviceHours = [
    {
        value: 15,
        unit: "minutes",
    },
    {
        value: 30,
        unit: "minutes",
    },
    {
        value: 45,
        unit: "minutes",
    },
    {
        value: 1,
        unit: "hours",
    },
    {
        value: 2,
        unit: "hours",
    },
];
export const defautLocation = {
    lat: 13.7710578,
    long: 100.519741,
};
export const defaultImage = "./default-image.png";
// form ( time interval )
export const timeLimit = [
    {
        name: "all_time",
        value: enumTimeLimit.ALL_TIME,
    },
    // {
    //     name: "monthly",
    //     value: enumTimeLimit.MONTH,
    // },
];
//mobile
export const mobileNavBox = [
    {
        _id: "1",
        colorTheme: "",
        createdAt: "",
        iconUrl: "point",
        link: "/complaints",
        text: "ดูละเปลี่ยนสถานะรายการร้องทุกข์",
        title: "รายการร้องทุกข์",
        title_th: "รายการร้องทุกข์",
        text_th: "ดูละเปลี่ยนสถานะรายการร้องทุกข์",
        updatedAt: "",
        dropdownStatus: false,
    },
    {
        _id: "3",
        colorTheme: "",
        createdAt: "",
        iconUrl: "manage",
        link: "/redeem-privileges",
        text: "ยืนยันการรับสิทธิพิเศษให้ประชาชน",
        title: "คอนเฟิร์มรับสิทธิพิเศษ",
        title_th: "คอนเฟิร์มรับสิทธิพิเศษ",
        text_th: "ยืนยันการรับสิทธิพิเศษให้ประชาชน",
        updatedAt: "",
        dropdownStatus: false,
    },
];

// new
export const navBox = [
    {
        _id: "1",
        colorTheme: "",
        createdAt: "",
        iconUrl: "point",
        link: "/complaints",
        text: "ดูละเปลี่ยนสถานะรายการร้องทุกข์",
        title: "รายการร้องทุกข์",
        title_th: "รายการร้องทุกข์",
        text_th: "ดูละเปลี่ยนสถานะรายการร้องทุกข์",
        updatedAt: "",
        dropdownStatus: false,
    },
    {
        _id: "2",
        colorTheme: "",
        createdAt: "",
        iconUrl: "success",
        link: "/news/create",
        text: "เพิ่มข่าวสารใหม่",
        title: "สร้างข่าวสาร",
        title_th: "สร้างข่าวสาร",
        text_th: "เพิ่มข่าวสารใหม่",
        updatedAt: "",
        dropdownStatus: false,
    },
    {
        _id: "3",
        colorTheme: "",
        createdAt: "",
        iconUrl: "manage",
        link: "/redeem-privileges",
        text: "ยืนยันการรับสิทธิพิเศษให้ประชาชน",
        title: "คอนเฟิร์มรับสิทธิพิเศษ",
        title_th: "คอนเฟิร์มรับสิทธิพิเศษ",
        text_th: "ยืนยันการรับสิทธิพิเศษให้ประชาชน",
        updatedAt: "",
        dropdownStatus: false,
    },
    {
        _id: "4",
        colorTheme: "",
        createdAt: "",
        iconUrl: "gift",
        link: "/booking",
        text: "ดูตารางการจองธุรกรรม",
        title: "ตารางจองธุรกรรม",
        title_th: "ตารางจองธุรกรรม",
        text_th: "ดูตารางการจองธุรกรรม",
        updatedAt: "",
        dropdownStatus: false,
    },
    {
        _id: "5",
        colorTheme: "",
        createdAt: "",
        iconUrl: "contact",
        link: "/home-menu",
        text: "บริหารจัดการข้อมูลบนเว็บไซต์",
        title: "จัดการหน้าเว็บไซต์",
        title_th: "จัดการหน้าเว็บไซต์",
        text_th: "บริหารจัดการข้อมูลบนเว็บไซต์",
        updatedAt: "",
        dropdownStatus: false,
    },
    {
        _id: "6",
        colorTheme: "",
        createdAt: "",
        iconUrl: "setting",
        link: "/privileges-create",
        text: "เพิ่มกิจกรรมประชาชนใหม่",
        title: "สร้างกิจกรรม",
        title_th: "สร้างกิจกรรม",
        text_th: "เพิ่มกิจกรรมประชาชนใหม่",
        updatedAt: "",
        dropdownStatus: false,
    },
];

export const MONTH_EXPIRY = [
    // { name: "_no_expiry", value: -1 },
    { name: "_1_month", value: 1 },
    { name: "_2_months", value: 2 },
    { name: "_3_months", value: 3 },
    { name: "_4_months", value: 4 },
    { name: "_5_months", value: 5 },
    { name: "_6_months", value: 6 },
    { name: "_7_months", value: 7 },
    { name: "_8_months", value: 8 },
    { name: "_9_months", value: 9 },
    { name: "_10_months", value: 10 },
    { name: "_11_months", value: 11 },
    { name: "_12_months", value: 12 },
];

export const BIRTH_MONTH = [
    { name: "Jan", value: "1" },
    { name: "Feb", value: "2" },
    { name: "Mar", value: "3" },
    { name: "Apr", value: "4" },
    { name: "May", value: "5" },
    { name: "Jun", value: "6" },
    { name: "Jul", value: "7" },
    { name: "Aug", value: "8" },
    { name: "Sep", value: "9" },
    { name: "Oct", value: "10" },
    { name: "Nov", value: "11" },
    { name: "Dec", value: "12" },
];

export const FACEBOOK_GRAPH_URL = "https://graph.facebook.com/v12.0/oauth";
export const LINE_URL = "https://api.line.me/v2";
export const LINE_AUTH_URL = "https://api.line.me/oauth2/v2.1";
export const LINE_ACCESS_URL = "https://access.line.me/oauth2/v2.1";
export const LINE_SCOPE = "profile%20openid%20email";

// ------------------------------------- segment module -----------------------------------------

export const INITIAL_LIST_PARAMS: IPagination = {
    page: 1,
    limit: PAGINATION,
};
export const INVALID_DATE = "Invalid Date";

export const BLANK_IMAGE_URL = "/images/initImage.png";

// coupon unit
export const FIXED = "BAHT";
export const PERCENTAGE = "PERCENTAGE";
export const ALL_SELECT = "all";

export const COLOR_BENEFITS = [
    "#D2E1F2",
    "#A2A1A1",
    "#716A62",
    "#FFE25F",
    "#D79D6D",
    "#B99128",
    // "#AEAEAE",
    // "#80807E",ComponentTheme√In order : theme, first, silver, black iron, yellow, bronze, gold
];
export const FAMILY_COLOR_BENEFITS = {
    "#B99128": ["#F8E568", "#CFA831", "#B99128", "#936B10"],
    "#FFE25F": ["#FAEB60", "#FFE25F", "#F08B13"],
    "#D2E1F2": ["#D6E9F9", "#D2E1F2", "#62739A"],
    "#A2A1A1": ["#EAEAEA", "#A2A1A1", "#808080"],
    "#716A62": ["#CAC1B9", "#716A62", "#4B443E"],
    "#D79D6D": ["#FECCA1", "#D79D6D", "#8E5B26"],
    "#EF281C": ["#EF281C", "#EF281C"],
};

// loyalty rules setting
export const SPECIFIC_MONTHS = [
    { name: "january", value: "January" },
    { name: "february", value: "February" },
    { name: "march", value: "March" },
    { name: "april", value: "April" },
    { name: "may", value: "May" },
    { name: "june", value: "June" },
    { name: "july", value: "July" },
    { name: "august", value: "August" },
    { name: "september", value: "September" },
    { name: "october", value: "October" },
    { name: "november", value: "Dovember" },
    { name: "december", value: "December" },
];
export const CYCLE_TIME = [
    { name: "cycle_12_months", value: "MONTH_12_CYCLE" },
    { name: "cycle_24_months", value: "MONTH_24_CYCLE" },
    { name: "cycle_real_time", value: "MONTH_0_CYCLE" },
];
export const URL_INTEGRATION_SETTING = {
    webHookURL: `${process.env.REACT_APP_CLIENT_URL}/line-login`,
    callbackURL: `${process.env.REACT_APP_CLIENT_URL}/line-login`,
    LINELoginURL: `${process.env.REACT_APP_CLIENT_URL}/line-login`,
    LIFFEndPointURL: `${process.env.REACT_APP_CLIENT_URL}/line-login`,
};
export const BUSINESS_TYPE = [
    { name: "restaurant_cafe", value: "Restaurant & Cafe" },
    { name: "retails", value: "Retails" },
    { name: "hotel_motel", value: "Hotel & Motel" },
    { name: "spa_massage", value: "Spa & Massage" },
    { name: "salon_beauty", value: "Salon & Beauty" },
    { name: "clinics", value: "Clinics" },
    { name: "others", value: "Others" },
];

export const REGEX_URL =
    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+((\/)[\w#.-]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

export const COMPANY_SIZE = [
    { name: "one_50", value: "1TO49" },
    { name: "fivety_200", value: "50TO199" },
    { name: "more_than_200", value: "200MORE" },
];
export const LEVEL_EXP = [
    { name: "new_to_loyalty_hub", value: "NEW_LOYALTY" },
    { name: "CRM_OR_Have_used_loylaty_hub", value: "CRM_LOYALTY" },
];
export const new_account_step = [0, 1, 2, 3];
export const line_step = [0, 1];
// 1TO49, 50TO199, 200MORE
// NEW_LOYALTY, CRM_LOYALTY
export const dateFormat = "DD/MM/YYYY";
export const dateFormatHM = "DD/MM/YYYY HH:MM";
export const dateFormatMonth = "DD MMM YYYY";
export const shopThemeColors = [
    "#EF281C",
    "#AB1A31",
    "#003FBA",
    "#9EE4FF",
    "#47267F",
    "#6230E0",
    "#F57700",
    "#F46F91",
    "#6CD2D2",
    "#2DBF3D",
    "#067049",
    "#EBAD0F",
    "#FBF21D",
    "#000000",
    "#924C28",
    "#E5E5E5",
];
