import { ISvg } from "@interfaces";
export const BackArrow = (props: ISvg) => (
    <svg
        width={props.size || "42"}
        height={props.size || "42"}
        viewBox="0 0 42 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="21" cy="21" r="20.75" fill="white" stroke="#8A959E" strokeWidth="0.5" />
        <path
            d="M23 15L17 21L23 27.0008"
            stroke="#0D6EFD"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
