import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Switch, Col, Row, Avatar, Menu, Dropdown, Button } from "antd";
import { MoreHorizontal } from "react-feather";
import moment from "moment";

import { INews, INewsCategory, ITrashfeeArea, ITrashfeeBillList } from "@interfaces";
import { newsAPI } from "@api";
import { dateFormat, dateFormatHM, PATH_CREATE_NEWS, enumNewsType, defaultImage, enumBillStatus } from "@configs";
import { selectAuth } from "@redux";
import { useSelector } from "react-redux";
import { IconDrag, StyledCancelButton } from "@components";
import { showErrorMessage, useNotify } from "@utils";
import { useEffect, useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import styled from "styled-components";
interface IProps {
  data: ITrashfeeBillList;
  itemJson: string;
  handleEdit: (status: boolean, data: INews) => void;
  callbackGetList: () => void;
  index: number;
  newType?: enumNewsType;
  areaList: ITrashfeeArea[];
  openDetailModal: (data: ITrashfeeBillList) => void;
  billStatus: string;
}

export const ComponentRowBill = (props: IProps) => {
  //hooks
  const { t } = useTranslation();
  const { success, error } = useNotify();
  const org_id = useSelector(selectAuth).auth?.organization_data?._id;
  //props
  const { data, handleEdit, callbackGetList, itemJson, newType, areaList, openDetailModal, billStatus } = props;
  const [show, setShowModal] = useState(false);

  const { attributes, listeners, setNodeRef, transform } = useSortable({
    id: itemJson,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
  };
  // const catName = () => {
  //   const item = props.categories.filter((item) => item._id === props.data.news_category_id);
  //   if (item.length > 0) {
  //     return item[0].category_name;
  //   }
  //   return "...";
  // };

  const handleDelete = async () => {
    // try {
    //   await newsAPI.delete({ organization_id: organization_id || "", news_id: data._id });
    //   callbackGetList();
    //   setShowModal(false);
    //   success(t("message.delete.success"));
    // } catch (err: any) {
    //   if (err) {
    //     error(err.msg);
    //   } else error(t("message.delete.fail"));
    // }
  };

  const handleRemove = async () => {
    // try {
    //   const payload = {
    //     organization_id: org_id || "",
    //     title: data.title,
    //     detail: data.detail,
    //     image_url_list: data.image_url_list,
    //     is_hot_news: false,
    //     news_category_id: data.news_category_id,
    //     date_from: data.date_from,
    //     date_to: data.date_to,
    //     is_enable: data.is_enable,
    //     news_id: data._id,
    //   };
    //   await newsAPI.update(payload);
    //   callbackGetList();
    //   success(t("message.duplicate.success"));
    // } catch (err: any) {
    //   if (err.response) {
    //     error(showErrorMessage(err.response));
    //   } else error(t("message.duplicate.fail"));
    // }
  };
  const areaName = () => {
    const item = areaList.filter((item) => item._id === data?._id?.areaDetail?._id);
    if (item.length > 0) {
      return item[0].area_name;
    }
    return "...";
  };

  const showModal = () => {
    setShowModal(!show);
    // Modal.confirm({
    //     title: t("message.delete_title"),
    //     icon: <ExclamationCircleOutlined />,
    //     content: t("message.delete_confirm", {
    //         returnObjects: true,
    //         name: t("object._contact"),
    //     }),
    //     okText: t("page.delete"),
    //     cancelText: t("page.cancel"),
    //     centered: true,
    //     onOk: handleDelete,
    // });
  };

  const menu = (
    <Menu>
      {newType && newType === enumNewsType.HOT_NEWS && (
        <Menu.Item key={2} onClick={handleRemove}>
          <div className="delete-btn">{t("page.remove")}</div>
        </Menu.Item>
      )}
      <Menu.Divider />
      <Menu.Item key={1} onClick={handleDelete}>
        <div className="duplicate-btn">{t("page.delete")}</div>
      </Menu.Item>
    </Menu>
  );
  const handleUpdateStatus = (e: boolean) => {
    // handleEdit(e, data);
  };
  return (
    <div className="table-element" ref={setNodeRef} style={style}>
      <div className="drag-icon" {...attributes} {...listeners}>
        <IconDrag />
      </div>
      <div className="table-row">
        <Row
          style={{ height: "100%" }}
          gutter={24}
          type="flex"
          align="middle"
          justify="space-between"
        >
          <Col className="col-item" span={2}>
            <b>{data?._id?.houseDetail?.register_no}</b>

          </Col>
          <Col className="col-item m-member-code" span={3}>
            <p className="visible-md eng">
              <p>{areaName() || t("page.empty_text")}</p>
            </p>
          </Col>
          <Col className="col-item" span={2}>
            {data?._id?.houseDetail?.house_type}
          </Col>
          <Col className="col-item" span={5}>
            {data?._id?.houseDetail?.full_address}
          </Col>
          <Col className="col-item" span={3}>
            {data?.sumCount}
          </Col>
          <Col className="col-item" span={3}>
            {data?.sumPaid} บาท
          </Col>
          <Col className="col-item" span={3}>
            <StyledStatus>
              {billStatus === enumBillStatus.OVERDUE &&
                <div className="overdue">
                  ค้างจ่าย
                </div>
              }
              {billStatus === enumBillStatus.PAID &&
                <div className="paid">
                  ชำระครบแล้ว
                </div>
              }
              {billStatus === enumBillStatus.CANCEL &&
                <div className="cancle">
                  ยกเลิกการชำระ
                </div>
              }
            </StyledStatus>
          </Col>
          <Col className="col-item visible-md" span={3}>
            <StyledCancelButton
              type="sub"
              text="ดูรายละเอียด"
              onClick={() => openDetailModal(data)}
            // onClick={handleClose}
            />
          </Col>

        </Row>
      </div>
    </div>
  );
};

const StyledStatus = styled.div`
  display: flex;
  justify-content: flex-end;
  .overdue {
    background: #0D6EFD;
    border-radius: 36px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    padding: 5px 15px;
  }
  .paid {
    background: #34C759;
    border-radius: 36px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    padding: 5px 15px;
  }
  .cancle {
    background: #ce4f4f;
    border-radius: 36px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    padding: 5px 15px;
  }
`;