import {
    IAdminsParams,
    IAdminParam,
    IAdminCreate,
    IAdminActivate,
    IAdminForgetPassword,
    IAdminChangePassword,
} from "@interfaces";

import axiosClient from "./axiosClient";

export const adminAPI = {
    getAdmins: (params: IAdminsParams) => {
        const url = "admin/getAdmins";
        return axiosClient.get(url, { params });
    },
    getAdmin: (params: IAdminParam) => {
        const url = `admin/getAdmin`;
        return axiosClient.get(url, { params });
    },
    deleteAdmin: (params: IAdminParam) => {
        const url = `admin/deleteAdmin`;
        return axiosClient.post(url, params);
    },
    createAdmin: (params: IAdminCreate) => {
        const url = "admin/createAdmin";
        return axiosClient.post(url, params);
    },
    updateAdmin: (params: IAdminCreate) => {
        const url = "admin/updateAdmin";
        return axiosClient.patch(url, params);
    },
    activateAdmin: (params: IAdminActivate) => {
        const url = "beMiddle/activateAdmin";
        return axiosClient.patch(url, params);
    },
    sendMailActivateLink: (params: IAdminParam) => {
        const url = "admin/sendMailActivateLink";
        return axiosClient.post(url, params);
    },
    adminForgetPassword: (params: IAdminForgetPassword) => {
        const url = "beMiddle/adminForgetPassword";
        return axiosClient.patch(url, params);
    },
    adminChangePassword: (params: IAdminChangePassword) => {
        const url = "admin/adminChangePassword";
        return axiosClient.post(url, params);
    },
    adminGetPermission: (params: IAdminParam) => {
        const url = "admin/adminGetPermission";
        return axiosClient.get(url, { params });
    },
};
