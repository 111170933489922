import { Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import { ComponentAppRoute, ComponentToast } from "@components";
import { routes } from "@configs";
import { IRoute } from "@interfaces";
import { useWindowDimensions } from "@utils";
import { selectAuth, setMobile } from "@redux";

export const ModuleMain = () => {
    //page hooks
    const { width } = useWindowDimensions();
    const dispatch = useDispatch();
    // page  redux
    const auth = useSelector(selectAuth).auth;

    useEffect(() => {
        dispatch(setMobile(width < 992));
    }, [width]);

    return (
        <BrowserRouter>
            <Switch>
                {routes.map((e: IRoute, key) => (
                    <ComponentAppRoute key={key} {...e} />
                ))}
            </Switch>
            <ComponentToast />
        </BrowserRouter>
    );
};
