import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { StyledNewDetail } from "@components";
import { IDepartment, INews, IService } from "@interfaces";
import { PATH_CREATE_NEWS, PATH_SERVICES_CREATE } from "@configs";
import { ModuleNewsForm, ModuleServiceForm } from "@modules";
import { useNotify, usePermission } from "@utils";
import { departmentAPI, newsAPI, serviceAPI } from "@api";
import { setLoading, selectAuth, selectOrg, getCategories } from "@redux";

export const PageServiceCreate = () => {
    const { id } = useParams<{ id: string }>();
    const org_id = useSelector(selectAuth).auth?.organization_data?._id;
    const pemisssion = useSelector(selectAuth).permission;
    const categories = useSelector(selectOrg).category;
    const dispatch = useDispatch();
    const history = useHistory();
    const { warning } = useNotify();
    // page state
    const [service, setService] = useState<IService>();
    const [department, setDeprt] = useState<IDepartment[]>();
    // variable
    const pathName = history.location.pathname;
    usePermission({ permission: pemisssion?.permission_list.service.is_manage });

    useEffect(() => {
        fetchNewsCategory();
        fetchService();
    }, []);
    const fetchNewsCategory = async () => {
        try {
            const response = await departmentAPI.getServiceDepartments({
                organization_id: org_id || "",
                xpage: 1,
                xlimit: 10,
            });
            const data: IDepartment[] = response.data.result;
            setDeprt(data);
            dispatch(setLoading(false));
        } catch (err) {
            if (err) {
                dispatch(setLoading(false));
            }
        }
    };
    const fetchService = async () => {
        if (pathName !== PATH_SERVICES_CREATE) {
            dispatch(setLoading(true));
            try {
                const response = await serviceAPI.getService({
                    organization_id: org_id || "",
                    service_id: id,
                });
                const data: IService = response.data.result;
                setService(data);
                dispatch(setLoading(false));
            } catch (err: any) {
                if (err) {
                    warning(err.msg);
                    dispatch(setLoading(false));
                }
            } finally {
                dispatch(setLoading(false));
            }
        }
    };

    return (
        <StyledNewDetail>
            {<ModuleServiceForm service={service} department={department} />}
        </StyledNewDetail>
    );
};
