import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";
import { enumDrawerPlacement } from "@configs";
import { ComponentDrawer, SharedInput, StyledSubmitButton, StyledCancelButton } from "@components";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { IShortCutMenuItem } from "src/Interfaces/homeMenu";
import { EllipsisOutlined } from "@ant-design/icons";
import { ModuleUploadImage } from "@modules";
interface IProps {
    data: IShortCutMenuItem;
    itemJson: string;
    index: number;
    setItem?: (item: IShortCutMenuItem, index: number) => void;
}

export const ComponentRowShortCut = (props: IProps) => {
    //hooks
    const { t } = useTranslation();

    //props
    const { data, itemJson, index, setItem } = props;
    const [show, setShowModal] = useState(false);
    const [values, setValues] = useState<IShortCutMenuItem>(data);
    // const [values, setValues] = useState<IShortCutMenuItem>({
    //     image_url: "",
    //     link_url: "",
    //     name: "",
    // });
    // useEffect(() => {
    //     setValues({
    //         image_url: "",
    //         link_url: "",
    //         name: "",
    //     });
    // }, [data]);
    const { setNodeRef, transform } = useSortable({
        id: itemJson,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
    };

    const setImg = (images: string) => {
        setValues({ ...values, image_url: images });
    };


    const saveItem = () => {
        if (setItem) {
            setItem(values, index);
            setShowModal(!show);
        }
    };

    const showModal = () => {
        setShowModal(!show);
    };

    const changeShortcutData = (e: any) => {
        let arr = values;
        arr[e.target.id] = e.target.value;
        setValues({ ...arr });
    };

    return (
        <div className="table-row" ref={setNodeRef} style={style}>
            <Row
                style={{ height: "100%" }}
                gutter={24}
                type="flex"
                align="middle"
                justify="space-between"
            >
                <Col className="col-item" lg={1} md={1} xs={2} sm={2}>
                    {index + 1}
                </Col>
                <Col className="col-item" span={7}>
                    {data.image_url !== "" ? (
                        <div
                            style={{
                                display: "flex",
                            }}
                        >
                            <img src={data.image_url} style={{ maxWidth: "50%" }} />
                            <div
                                style={{
                                    margin: "auto",
                                    fontSize: "16px",
                                    fontWeight: "700",
                                    paddingLeft: "20px",
                                }}
                            >
                                {data.name || "-"}
                            </div>
                        </div>
                    ) : (
                        <div
                            style={{
                                fontSize: "16px",
                                fontWeight: "700",
                            }}
                        >
                            {data.name || "-"}
                        </div>
                    )}
                </Col>

                <Col className="col-item m-member-code" xs={5} sm={5} lg={2} md={2}>
                    {data.name || "-"}
                </Col>
                <Col className="col-item" span={3}>
                    {values.name === "" ? (
                        <StyledButton onClick={showModal}>ระบุเมนู</StyledButton>
                    ) : (
                        <div style={{ textAlign: "right" }}>
                            <EllipsisOutlined style={{ fontSize: "30px" }} onClick={showModal} />
                        </div>
                    )}
                </Col>
            </Row>
            <ComponentDrawer
                title="เพิ่มเมนูทางลัด"
                visible={show}
                handleClose={showModal}
                placement={enumDrawerPlacement.RIGHT}
            >
                <StyledDrawer>
                    <p className="title">{t("page.homeMenu.upload_shortcut_icon")}</p>
                    <p className="detail">{t("page.homeMenu.upload_shortcut_icon_detail")}</p>
                    <ModuleUploadImage
                        title=""
                        images={values.image_url || ""}
                        handleGetImage={setImg}
                        IsCanResetImage={true}
                        onResetImage={() => setValues({ ...values, image_url: "" })}
                    />
                    <SharedInput
                        label={t("page.homeMenu.shortcut_menu_name")}
                        name="name"
                        onChange={changeShortcutData}
                        value={values.name}
                        errors={values.name}
                        parentClassName="mb-6"
                    />
                    <SharedInput
                        label="url*"
                        name="link_url"
                        onChange={changeShortcutData}
                        value={values.link_url}
                        errors={values.link_url}
                        parentClassName="mb-6"
                    />
                    <div className="btn-layout">
                        <StyledSubmitButton
                            type="default"
                            text={t("page.save")}
                            onClick={saveItem}
                        />
                        <StyledCancelButton
                            type="sub"
                            text={t("page.cancel")}
                            htmlType="button"
                            onClick={showModal}
                        />
                    </div>
                </StyledDrawer>
            </ComponentDrawer>
            <br />
        </div>
    );
};

const StyledButton = styled.button`
    background-color: white;
    width: 100%;
    padding: 5px 0px;
    border: 1px solid #8a959e;
    border-radius: 5px;
    cursor: pointer;
`;

const StyledDrawer = styled.div`
    .title {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
    }
`;
