import {
    IHomeMenuGet,
    ICoverDataUpdate,
    IHomeNewsPatch,
    IShortCutPatch,
    ICalendarDataGet,
    IDecorData,
    IHomeBannerGet,
    IBannerUpdate,
    IInfoAboutGet,
    IInfoItemUpdate,
    IInfoItemCreate,
    IInfoDelete,
} from "./../Interfaces/homeMenu";

import { IBiddingParams } from "@interfaces";

import axiosClient from "./axiosClient";

export const homeMenuAPI = {
    getList: (params: IBiddingParams) => {
        const url = "bidding/getBiddings";
        return axiosClient.get(url, { params });
    },
    getorganization: (params: IHomeMenuGet) => {
        const url = "organization/getOrganization";
        return axiosClient.get(url, { params });
    },
    updateCoverData: (params: ICoverDataUpdate) => {
        const url = "organization/updateCoverData";
        return axiosClient.patch(url, params);
    },
    updateShortcutsData: (params: IShortCutPatch) => {
        const url = "organization/updateMenuShortcuts";
        return axiosClient.patch(url, params);
    },
    updateHomeNewsData: (params: IHomeNewsPatch) => {
        const url = "organization/updateGeneralInformation";
        return axiosClient.patch(url, params);
    },
    updateCalendarData: (params: ICalendarDataGet) => {
        const url = "organization/updateCalendarData";
        return axiosClient.patch(url, params);
    },
    updateDecorData: (params: IDecorData) => {
        const url = "organization/updateDecorateData";
        return axiosClient.patch(url, params);
    },
    getBannerAbout: (params: IHomeBannerGet) => {
        const url = "organization/getBannerData";
        return axiosClient.get(url, { params });
    },
    updateBanner: (params: IBannerUpdate) => {
        const url = "organization/updateBannerData";
        return axiosClient.patch(url, params);
    },
    getInfoAbout: (params: IInfoAboutGet) => {
        const url = "home_information/getHomeInformations";
        return axiosClient.get(url, { params });
    },

    updateInfoItem: (params: IInfoItemUpdate) => {
        const url = "home_information/updateHomeInformation";
        return axiosClient.patch(url, params);
    },
    createInfoItem: (params: IInfoItemCreate) => {
        const url = "home_information/createHomeInformation";
        return axiosClient.post(url, params);
    },
    deleteInfoItem: (params: IInfoDelete) => {
        const url = "home_information/deleteHomeInformation";
        return axiosClient.post(url, params);
    },
};
