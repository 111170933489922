import { Badge, Menu } from "antd";

import { useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useState, useRef, useEffect } from "react";
import { capitalize } from "@utils";

import { StyledSidebar, IconOffice, MenuCalendar, IconDepartment } from "@components";
import { selectApp, setMobileDrawer, useAppSelector } from "@redux";
import { enumPermission, PATH_BOOKING, PATH_SERVICES, PATH_DEPARTMENT } from "@configs";

interface ISideBarRoute {
    path?: string;
    name: string;
    icon: JSX.Element;
    permission?: string;
    children?: Array<{
        path: string;
        name: string;
        permission?: string;
        badge?: {
            value: string;
        };
    }>;
    badge?: {
        value: string;
    };
}
const bookingRoutes: ISideBarRoute[] = [
    {
        path: PATH_BOOKING,
        name: "transaction_booking_table",
        icon: <MenuCalendar size={24} color={"#0D6EFD"} />,
        permission: enumPermission.MANAGE_BIDDING,
    },
    {
        path: PATH_DEPARTMENT,
        name: "list_of_departments",
        icon: <IconOffice size={24} color={"#0D6EFD"} />,
        permission: enumPermission.MANAGE_BIDDING,
    },
    {
        path: PATH_SERVICES,
        name: "booking_list",
        icon: <IconDepartment size={24} color={"#0D6EFD"} />,
        permission: enumPermission.MANAGE_BIDDING,
    },
];
const { SubMenu } = Menu;
export const BookingSidebar = () => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const sidebarRef = useRef();
    const [openKeys, setOpenKeys] = useState<string[]>([]);
    const app = useAppSelector(selectApp);
    const pathname = location.pathname;

    useEffect(() => {
        if (sidebarRef.current) {
            //@ts-ignore
            sidebarRef.current.scrollIntoView({
                behavior: "auto",
                block: "center",
                inline: "nearest",
            });
        }
    }, [pathname]);

    const badgeTemplate = (badge: { value: string }) => <Badge count={badge.value} />;

    const switchRoute = (path: string, permission?: string, index?: string) => {
        if (app.mobile) dispatch(setMobileDrawer(!app.mobileDrawer));
        if (permission) {
            history.push(path);
        } else {
            history.push(path);
        }
    };

    const handleOpenKey = (e) => {
        setOpenKeys([...openKeys, e.key]);
    };

    return (
        <StyledSidebar>
            <Menu
                theme={"light"}
                className="border-0 scroll-y "
                style={{ flex: 1 }}
                mode={"inline"}
                defaultOpenKeys={["0", "1"]}
                onClick={handleOpenKey}
            >
                {bookingRoutes.map((route, index) => {
                    return (
                        <SubMenu
                            className={`menu-after ${route.name}`}
                            key={index}
                            onTitleClick={() => {
                                if (route.path && route.permission) {
                                    switchRoute(
                                        route.path,
                                        route.permission || "",
                                        index.toString()
                                    );
                                }
                            }}
                            title={
                                <span>
                                    <span className="anticon" style={{ marginBottom: 5 }}>
                                        {route.icon}
                                    </span>
                                    <span
                                        className="menu-item-text"
                                        style={{
                                            color: "black",
                                            fontWeight: 700,
                                            fontSize: 16,
                                        }}
                                    >
                                        {capitalize(t("page.sidebar." + route.name))}
                                    </span>
                                    {route.badge && badgeTemplate(route.badge)}
                                </span>
                            }
                        ></SubMenu>
                    );
                })}
            </Menu>
        </StyledSidebar>
    );
};
