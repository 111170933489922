export const ContactIcon = () => (
    <svg
        width="150"
        height="128"
        viewBox="0 0 150 128"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M80.2298 99.5049H67.1564H66.8812H53.8078C53.8078 99.5049 50.5051 112.578 49.1289 113.817H66.7436H67.0188H84.6335C83.5326 112.578 80.2298 99.5049 80.2298 99.5049Z"
            fill="#B5C8CA"
        />
        <path
            d="M89.4493 114.367C89.4493 115.055 89.1741 115.744 88.7612 115.744H45.275C44.8621 115.744 44.5869 115.193 44.5869 114.367C44.5869 113.679 44.8621 112.991 45.275 112.991H88.7612C89.1741 112.991 89.4493 113.679 89.4493 114.367Z"
            fill="#EAE7E7"
        />
        <path
            d="M3.30273 91.7979V99.3667C3.30273 101.431 5.91741 103.082 9.08255 103.082H124.954C128.119 103.082 130.734 101.431 130.734 99.3667V91.7979H3.30273Z"
            fill="#E4E4E4"
        />
        <path
            d="M130.734 28.0821C130.734 24.3665 128.119 21.6143 124.954 21.6143H9.08255C5.91741 21.6143 3.30273 24.3665 3.30273 28.0821V91.7977H130.734V28.0821Z"
            fill="#2E3640"
        />
        <path
            d="M125.505 31.6608C125.505 28.4956 123.028 26.0186 120.275 26.0186H13.7616C10.8717 26.0186 8.53223 28.4956 8.53223 31.6608V87.1195H125.505V31.6608Z"
            fill="#D1D2D3"
        />
        <path
            d="M67.2931 101.293C68.9652 101.293 70.3207 99.9379 70.3207 98.2658C70.3207 96.5938 68.9652 95.2383 67.2931 95.2383C65.6211 95.2383 64.2656 96.5938 64.2656 98.2658C64.2656 99.9379 65.6211 101.293 67.2931 101.293Z"
            fill="#2E3640"
        />
        <path
            opacity="0.16"
            d="M125.367 74.459L44.4492 26.1562H120.137C123.027 26.1562 125.367 28.4957 125.367 31.3856V74.459Z"
            fill="#D0D1D2"
        />
        <path
            d="M112.018 7.30273H97.7063C90.5503 7.30273 84.7705 13.0826 84.7705 20.2385V21.0642C84.7705 28.2202 90.5503 34 97.7063 34H102.385L99.4953 41.7064L110.504 34H112.018C119.174 34 124.816 28.2202 124.816 21.0642V20.2385C124.816 12.9449 119.037 7.30273 112.018 7.30273Z"
            fill="#FCFAFB"
        />
        <path
            d="M97.7063 33.8628H102.385L99.4953 41.5692L110.504 33.8628H112.018C119.037 33.8628 124.679 28.3582 124.816 21.3398H84.7705C84.9081 28.3582 90.5503 33.8628 97.7063 33.8628Z"
            fill="#DDE4E8"
        />
        <path
            d="M94.679 24.229C96.275 24.229 97.5689 22.9352 97.5689 21.3391C97.5689 19.7431 96.275 18.4492 94.679 18.4492C93.0829 18.4492 91.7891 19.7431 91.7891 21.3391C91.7891 22.9352 93.0829 24.229 94.679 24.229Z"
            fill="#EE5A58"
        />
        <path
            d="M104.725 24.229C106.321 24.229 107.615 22.9352 107.615 21.3391C107.615 19.7431 106.321 18.4492 104.725 18.4492C103.129 18.4492 101.835 19.7431 101.835 21.3391C101.835 22.9352 103.129 24.229 104.725 24.229Z"
            fill="#EE5A58"
        />
        <path
            d="M114.908 24.229C116.505 24.229 117.798 22.9352 117.798 21.3391C117.798 19.7431 116.505 18.4492 114.908 18.4492C113.312 18.4492 112.019 19.7431 112.019 21.3391C112.019 22.9352 113.312 24.229 114.908 24.229Z"
            fill="#EE5A58"
        />
        <path
            d="M127.293 99.3663C128.669 100.605 131.009 100.33 132.523 98.6783C134.036 97.0269 134.174 94.6875 132.798 93.3113L123.578 84.7792C122.89 83.6783 117.385 90.1462 118.073 90.6966L127.293 99.3663Z"
            fill="#5E9BA3"
        />
        <path
            d="M125.642 99.6413C125.367 99.3661 125.367 98.9532 125.504 98.8156L131.972 91.7972C132.248 91.522 132.523 91.522 132.798 91.7972L134.449 93.311L146.009 103.907C148.899 106.66 149.725 110.513 147.66 112.715C145.596 114.917 141.605 114.504 138.715 111.751L125.642 99.6413Z"
            fill="#283244"
        />
        <path
            d="M92.477 91.5224C101.422 99.7793 115.596 99.2289 123.853 90.2839C132.248 81.2013 131.697 67.1646 122.615 58.7702C113.532 50.3757 99.4954 50.9261 91.1009 60.0087C82.844 69.0913 83.3945 83.128 92.477 91.5224ZM101.284 90.2839C99.4954 89.5958 97.844 88.4949 96.4679 87.1188C89.8624 80.9261 89.4495 70.605 95.5046 63.8619C98.5321 60.5591 102.523 58.7702 106.651 58.6325C110.917 58.3573 115.321 59.8711 118.624 63.0362C125.229 69.2289 125.642 79.55 119.587 86.2931C114.771 91.5224 107.339 92.8986 101.284 90.2839Z"
            fill="#0D6EFD"
        />
        <path
            opacity="0.54"
            d="M115.48 89.4733C123.377 85.0633 126.203 75.0869 121.793 67.1903C117.383 59.2937 107.407 56.4673 99.5099 60.8773C91.6134 65.2873 88.7869 75.2637 93.1969 83.1603C97.6069 91.0569 107.583 93.8833 115.48 89.4733Z"
            fill="#73BCC4"
        />
        <path
            opacity="0.54"
            d="M96.3303 87.1191C97.844 88.4952 99.3578 89.4585 101.147 90.1466C96.8807 70.4677 106.376 59.0457 106.651 58.6328C102.523 58.908 98.5321 60.5594 95.5046 63.8622C89.1743 70.6053 89.5871 80.9264 96.3303 87.1191Z"
            fill="#5E9BA3"
        />
        <path opacity="0.8" d="M13.6234 77.2109H10.8711V84.7797H13.6234V77.2109Z" fill="#2F363E" />
        <path opacity="0.8" d="M17.4769 74.7344H14.7246V84.7802H17.4769V74.7344Z" fill="#2F363E" />
        <path opacity="0.8" d="M29.1742 74.7344H26.4219V84.7802H29.1742V74.7344Z" fill="#2F363E" />
        <path opacity="0.8" d="M21.3304 65.2383H18.5781V84.7796H21.3304V65.2383Z" fill="#2F363E" />
        <path opacity="0.8" d="M25.3207 69.2295H22.5684V84.78H25.3207V69.2295Z" fill="#2F363E" />
        <path opacity="0.8" d="M33.0277 62.4863H30.2754V84.9175H33.0277V62.4863Z" fill="#2F363E" />
        <path
            opacity="0.2"
            d="M36.0551 49.4127C36.0551 50.376 35.2294 51.2017 34.2661 51.2017H15.1376C14.1743 51.2017 13.3486 50.376 13.3486 49.4127V35.5136C13.3486 34.5503 14.1743 33.7246 15.1376 33.7246H34.2661C35.2294 33.7246 36.0551 34.5503 36.0551 35.5136V49.4127Z"
            fill="#919396"
        />
        <path
            d="M28.2084 44.5893L20.502 44.6035L20.5073 47.4934L28.2137 47.4792L28.2084 44.5893Z"
            fill="#2F383E"
        />
        <path
            d="M24.3674 46.11C26.7235 46.1056 28.6292 43.7609 28.6238 40.8728C28.6185 37.9847 26.7042 35.6469 24.3481 35.6513C21.992 35.6556 20.0864 38.0004 20.0917 40.8885C20.097 43.7766 22.0114 46.1144 24.3674 46.11Z"
            fill="#F5D4B2"
        />
        <path
            d="M19.9541 40.8807C19.9541 43.7706 21.8807 46.1101 24.2202 46.1101V35.789C21.8807 35.6514 19.9541 37.9908 19.9541 40.8807Z"
            fill="#DFC2A9"
        />
        <path
            d="M30.4128 50.9268H28.0734H27.9358H26.5596H26.2844C26.2844 50.7892 26.422 50.514 26.422 50.2388C26.5596 49.2755 26.8348 48.1746 26.9725 47.3489C27.1101 46.7984 27.1101 46.3856 27.1101 46.3856C27.2477 44.7342 27.1101 44.7342 27.1101 44.7342C27.2477 44.5966 27.2477 44.5966 27.2477 44.459C27.7981 43.6333 27.9358 41.9819 27.9358 41.9819C28.0734 39.7801 27.3853 38.9544 27.3853 38.9544C26.2844 39.2296 25.3211 39.3672 24.3578 39.3672C23.3945 39.3672 22.4312 39.3672 21.4679 38.9544C21.4679 38.9544 20.7798 39.7801 20.9174 41.9819C20.9174 41.9819 21.055 43.6333 21.4679 44.459C21.6055 44.5966 21.6055 44.7342 21.7431 44.8718C21.8807 45.0094 22.4312 45.6975 22.844 46.936C22.844 47.0736 22.844 47.0736 22.844 47.2113C22.844 47.3489 22.844 47.4865 22.844 47.6241C22.844 47.6241 22.5688 48.725 22.5688 49.9635C22.5688 50.2388 22.5688 50.3764 22.5688 50.6516H22.1559H20.9174H20.6422H18.3027L18.7156 48.4498C18.7156 48.4498 18.8532 48.1746 18.9908 47.8993C19.266 47.2113 19.6789 45.9727 19.6789 45.0094C19.6789 45.0094 19.5413 40.3305 19.5413 39.2296C19.5413 39.2296 19.5413 37.4406 20.5046 36.2021C20.5046 36.2021 21.055 35.514 22.0183 34.9635C22.0183 34.9635 22.7064 34.4131 23.9449 34.4131C23.9449 34.4131 24.0825 34.4131 24.4954 34.4131C24.9082 34.4131 25.0459 34.4131 25.0459 34.4131C26.1468 34.5507 26.8348 35.514 26.8348 35.514C27.7981 35.514 28.3486 36.3397 28.3486 36.3397C29.4495 37.5782 29.4495 39.3672 29.4495 39.3672C29.4495 40.4681 29.3119 45.147 29.3119 45.147C29.3119 46.1103 29.7248 47.3489 30 48.0369C30.1376 48.4498 30.2752 48.5874 30.2752 48.5874L30.4128 50.9268Z"
            fill="#2F383E"
        />
        <path
            d="M27.2476 47.4863L26.6971 50.2385V50.3762L26.5595 50.789H26.2843H22.5687H22.1558L22.0182 50.3762L21.4678 47.6239C21.6054 47.6239 21.743 47.6239 21.8806 47.6239C22.4311 47.4863 22.7063 47.4863 22.7063 47.4863V47.211L22.8439 45.8349C22.9815 45.8349 23.2568 45.9725 23.532 45.9725H23.6696C23.6696 45.9725 23.6696 45.9725 23.8072 45.9725H23.9448H24.0825C24.0825 45.9725 24.0825 45.9725 24.2201 45.9725C24.9081 45.9725 25.321 45.8349 25.5962 45.6973L25.8714 47.3486C25.8714 47.3486 26.1467 47.3486 26.6971 47.4863H26.8347C26.9724 47.4863 27.11 47.4863 27.2476 47.4863Z"
            fill="#F5D4B2"
        />
        <path
            d="M27.9353 50.5073L20.7793 50.5205L20.7806 51.2086L27.9365 51.1954L27.9353 50.5073Z"
            fill="white"
        />
        <path
            d="M22.1556 51.202H16.1006C16.5134 49.413 17.2015 49.2754 17.2015 49.2754C17.6143 48.8626 18.1648 48.5873 18.8529 48.3121C19.2657 48.1745 19.6786 48.3121 19.9538 47.8993C20.5043 47.7616 20.9171 47.624 21.3299 47.624L21.8804 50.3763L22.018 50.9268L22.1556 51.202Z"
            fill="#EC8457"
        />
        <path
            d="M32.477 51.2019H26.5596V51.0643L26.6972 50.5139V50.2386L27.2476 47.4863C27.6605 47.4863 28.2109 47.6239 28.7614 47.7616C29.1742 47.8992 29.5871 48.1744 29.9999 48.1744H29.7247V48.312C30.4128 48.5872 30.9632 49.0001 31.3761 49.2753C31.2385 49.1377 32.0642 49.4129 32.477 51.2019Z"
            fill="#EC8457"
        />
        <path
            d="M22.1559 50.9267H20.9174H20.6422L19.9541 47.7616H20.0917L21.4679 47.4863L22.0183 50.2386V50.3762L22.1559 50.9267Z"
            fill="#D67040"
        />
        <path
            d="M28.6238 47.7616L28.0733 50.9267H27.9357H26.5596V50.5139L26.6972 50.3762V50.2386L27.2476 47.4863L28.6238 47.7616Z"
            fill="#D67040"
        />
        <path
            opacity="0.15"
            d="M89.9997 45.5598C89.9997 46.2479 89.4492 46.7984 88.7612 46.7984H42.385C41.6969 46.7984 41.1465 46.2479 41.1465 45.5598C41.1465 44.8717 41.6969 44.3213 42.385 44.3213H88.7612C89.4492 44.3213 89.9997 44.8717 89.9997 45.5598Z"
            fill="#231F20"
        />
        <path
            opacity="0.15"
            d="M76.5135 50.5138C76.5135 51.2019 75.9631 51.7524 75.275 51.7524L42.5227 51.6148C41.8346 51.6148 41.2842 51.0643 41.2842 50.3762C41.2842 49.6882 41.8346 49.1377 42.5227 49.1377L75.275 49.2753C75.9631 49.2753 76.5135 49.8258 76.5135 50.5138Z"
            fill="#231F20"
        />
        <path
            opacity="0.15"
            d="M84.7704 60.8353C84.7704 61.3858 84.22 61.9362 83.6695 61.9362H42.3851C41.8346 61.9362 41.2842 61.3858 41.2842 60.8353C41.2842 60.2848 41.8346 59.7344 42.3851 59.7344H83.6695C84.3576 59.7344 84.7704 60.1472 84.7704 60.8353Z"
            fill="#231F20"
        />
        <path
            opacity="0.15"
            d="M72.11 66.6144C72.11 67.3025 71.5595 67.853 70.8714 67.853L42.6604 67.7153C41.9723 67.7153 41.4219 67.1649 41.4219 66.4768C41.4219 65.7887 41.9723 65.2383 42.6604 65.2383L71.009 65.3759C71.5595 65.3759 72.11 65.9264 72.11 66.6144Z"
            fill="#231F20"
        />
        <path
            opacity="0.15"
            d="M77.6145 71.9816C77.6145 72.6697 77.0641 73.2201 76.376 73.2201L42.6604 73.0825C41.9723 73.0825 41.4219 72.5321 41.4219 71.844C41.4219 71.1559 41.9723 70.6055 42.6604 70.6055L76.2384 70.7431C77.0641 70.7431 77.6145 71.2935 77.6145 71.9816Z"
            fill="#231F20"
        />
        <path
            d="M43.7612 35.2383L44.3116 37.1649H46.3758L44.7245 38.2658L45.4125 40.1924L43.7612 39.0915L42.1098 40.1924L42.7979 38.2658L41.1465 37.1649H43.2107L43.7612 35.2383Z"
            fill="#F7AB1B"
        />
        <path
            d="M49.1284 35.2383L49.6788 37.1649H51.743L50.0917 38.2658L50.7797 40.1924L49.1284 39.0915L47.477 40.1924L48.165 38.2658L46.5137 37.1649H48.5779L49.1284 35.2383Z"
            fill="#F7AB1B"
        />
        <path
            d="M54.4955 35.2383L55.046 37.1649H57.1102L55.4588 38.2658L56.1469 40.1924L54.4955 39.0915L52.8442 40.1924L53.5322 38.2658L51.8809 37.1649H53.9451L54.4955 35.2383Z"
            fill="#F7AB1B"
        />
        <path
            d="M59.8618 35.2383L60.4122 37.1649H62.4764L60.8251 38.2658L61.5131 40.1924L59.8618 39.0915L58.2104 40.1924L58.8984 38.2658L57.2471 37.1649H59.3113L59.8618 35.2383Z"
            fill="#F7AB1B"
        />
    </svg>
);
