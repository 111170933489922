import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { StyledNewDetail } from "@components";
import { IDepartment, IDepartmentCreate, INews, IService, IServiceDepartment } from "@interfaces";
import { PATH_CREATE_NEWS, PATH_DEPARTMENT_CREATE, PATH_SERVICES_CREATE } from "@configs";
import { ModuleNewsForm, ModuleServiceDeptForm, ModuleServiceForm } from "@modules";
import { useNotify, usePermission } from "@utils";
import { departmentAPI, newsAPI, serviceAPI } from "@api";
import { setLoading, selectAuth, selectOrg, getCategories } from "@redux";

export const PageServiceDeptCreate = () => {
    const { id } = useParams<{ id: string }>();
    const org_id = useSelector(selectAuth).auth?.organization_data?._id;
    const pemisssion = useSelector(selectAuth).permission;
    const categories = useSelector(selectOrg).category;
    const dispatch = useDispatch();
    const history = useHistory();
    const { warning } = useNotify();
    // page state
    const [serviceDept, setServiceDept] = useState<IServiceDepartment>();
    const [template, setTemplate] = useState<IDepartmentCreate>();
    // variable
    const pathName = history.location.pathname;
    usePermission({ permission: pemisssion?.permission_list.service.is_manage });

    useEffect(() => {
        fetchServiceDept();
        if (pathName === PATH_DEPARTMENT_CREATE) {
            fetchTemplate();
        }
    }, []);
    const fetchTemplate = async () => {
        try {
            const response = await departmentAPI.getServiceDepartmentTemplate({
                organization_id: org_id || "",
            });
            const data: IDepartmentCreate = response.data.result;
            setTemplate(data);
            dispatch(setLoading(false));
        } catch (err) {
            if (err) {
                dispatch(setLoading(false));
            }
        }
    };
    const fetchServiceDept = async () => {
        if (pathName !== PATH_DEPARTMENT_CREATE) {
            dispatch(setLoading(true));
            try {
                const response = await departmentAPI.getServiceDepartment({
                    organization_id: org_id || "",
                    service_department_id: id,
                });
                const data: IServiceDepartment = response.data.result;
                setServiceDept(data);
                dispatch(setLoading(false));
            } catch (err: any) {
                if (err) {
                    warning(err.msg);
                    dispatch(setLoading(false));
                }
            } finally {
                dispatch(setLoading(false));
            }
        }
    };

    return (
        <StyledNewDetail>
            {<ModuleServiceDeptForm department={serviceDept} template={template} />}
        </StyledNewDetail>
    );
};
