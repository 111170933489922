import { Form, Spin, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";

import { SharedInput, StyledSignUp, IconLoadingPage, AuthButton } from "@components";
import { PATH_LOGIN, PATH_VERIFY_EMAIL } from "@configs";
import { IRegister } from "@interfaces";
import { authApi } from "@api";
import { resetTimeResendEmail, selectAuth } from "@redux";
import { ModuleExternalSignUp } from "@modules";
import { showErrorMessage, theme, useNotify } from "@utils";
import styled from "styled-components";

export const PageSignUp = () => {
    //page Hooks
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { success, error } = useNotify();
    const { id } = useParams<{ id: string }>();
    //redux state
    const { isLoading } = useSelector(selectAuth);

    // validation
    const data: IRegister = {
        email: "",
        inviteId: id,
        name: "",
    };

    const registerSchema = Yup.object().shape({
        email: Yup.string()
            .required(
                t("validation.required", {
                    returnObjects: true,
                    name: t("page.email"),
                })
            )
            .email(t("validation.email_invalid"))
            .max(
                255,
                t("validation.max_length", {
                    returnObjects: true,
                    name: t("page.email"),
                })
            ),
    });

    const handleSignUp = async (values: IRegister, { setSubmitting }: FormikHelpers<IRegister>) => {
        try {
            //WHAT : call api sign up
            const res = await authApi.register(values);
            if (res.data.success) {
                success(t("page.sign_up_successfully"));
                dispatch(resetTimeResendEmail());
                history.push(`${PATH_VERIFY_EMAIL}/${values.email}`);
            }
        } catch (err: any) {
            if (err.response) {
                error(showErrorMessage(err.response));
            } else error(t("message.sign_up_fail"));
        } finally {
            setSubmitting(false);
        }
    };

    const handleMoveLogin = () => {
        history.push(PATH_LOGIN);
    };
    //formik
    const { values, handleChange, handleBlur, handleSubmit, isSubmitting, errors, touched } =
        useFormik({
            initialValues: data,
            validationSchema: registerSchema,
            onSubmit: handleSignUp,
        });
    return (
        <>
            <Spin spinning={isLoading} size="large" indicator={<IconLoadingPage />}>
                <StyledSignUp>
                    <StyledLeftLayout>
                        <img src="/logo64.png" />
                        <p>{t("page.sanan_rak_municipality_office")}</p>
                        <div className="cross-line" />
                        <h4>{t("page.e_teesaban")}</h4>
                    </StyledLeftLayout>
                    <div className="signup-layout">
                        <div className="signup-container">
                            <div className="signup-header">
                                <img src="/logo120.png" alt="Tessabarn logo" />
                                <h3>{t("page.sign_up")}</h3>
                                <p>{t("page.login_des")}</p>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <Form.Item
                                    colon={false}
                                    label={t("page.email")}
                                    className="main-color"
                                >
                                    <SharedInput
                                        name="email"
                                        style={{ width: "100%" }}
                                        onChange={handleChange}
                                        value={values.email}
                                        onBlur={handleBlur}
                                        errors={errors.email}
                                        touched={touched.email}
                                    />
                                </Form.Item>
                                <Form.Item
                                    colon={false}
                                    label={t("page.name")}
                                    className="main-color"
                                >
                                    <SharedInput
                                        name="name"
                                        style={{ width: "100%" }}
                                        onChange={handleChange}
                                        value={values.email}
                                        onBlur={handleBlur}
                                        errors={errors.email}
                                        touched={touched.email}
                                    />
                                </Form.Item>
                                {/* <Row gutter={17}>
                                    <Col span={8}></Col>
                                    <Col span={16}>
                                        <Form.Item
                                            colon={false}
                                            label={t("page.name")}
                                            className="main-color"
                                        >
                                            <SharedInput
                                                name="name"
                                                style={{ width: "100%" }}
                                                onChange={handleChange}
                                                value={values.email}
                                                onBlur={handleBlur}
                                                errors={errors.email}
                                                touched={touched.email}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row> */}
                                <Form.Item
                                    colon={false}
                                    label={t("page.surname")}
                                    className="main-color"
                                >
                                    <SharedInput
                                        name="surname"
                                        style={{ width: "100%" }}
                                        onChange={handleChange}
                                        value={values.email}
                                        onBlur={handleBlur}
                                        errors={errors.email}
                                        touched={touched.email}
                                    />
                                </Form.Item>
                                <Form.Item
                                    colon={false}
                                    label={t("page.telephone_number")}
                                    className="main-color"
                                >
                                    <SharedInput
                                        name="telephone_number"
                                        style={{ width: "100%" }}
                                        onChange={handleChange}
                                        value={values.email}
                                        onBlur={handleBlur}
                                        errors={errors.email}
                                        touched={touched.email}
                                    />
                                </Form.Item>
                                <Form.Item
                                    colon={false}
                                    label={t("page.id_card_code")}
                                    className="main-color"
                                >
                                    <SharedInput
                                        name="id_card_code"
                                        style={{ width: "100%" }}
                                        onChange={handleChange}
                                        value={values.email}
                                        onBlur={handleBlur}
                                        errors={errors.email}
                                        touched={touched.email}
                                    />
                                </Form.Item>
                                {/* <div className="term_condition">
                                    <input
                                        style={{
                                            height: "initial",
                                            position: "relative",
                                            top: "2px",
                                        }}
                                        type="checkbox"
                                        id="remember"
                                    />
                                    <p>
                                        <a href="">
                                            {t("page.signup.read_accepted")}
                                            <span className="color-blue">
                                                {t("page.signup.terms_of_service")}
                                            </span>
                                            {t("page.signup.and")}
                                            <span className="color-blue">
                                                {t("page.signup.privacy_policy")}
                                            </span>
                                        </a>
                                    </p>
                                </div> */}
                                <AuthButton
                                    type="primary"
                                    size="large"
                                    text={t("page.sign_up")}
                                    htmlType="submit"
                                    disable={isSubmitting}
                                    className=""
                                />
                                <div className="log_in" onClick={handleMoveLogin}>
                                    <span className="have-account">
                                        {t("page.already_have_account")}
                                    </span>
                                    {t("page.login")}
                                </div>
                            </form>
                        </div>
                    </div>
                </StyledSignUp>
            </Spin>
        </>
    );
};
// const StyledWrapper = styled.div`
//     @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
//         height: 100vh;
//         padding: 16px;
//         overflow-y: scroll;
//     }
// `;
const StyledMobileLayout = styled.div`
    display: none;
    background-image: url("images/newUser/signup-bg-mobile.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    p,
    h4,
    h5 {
        margin: 0;
    }
    .m-info-layout {
        background: rgba(255, 255, 255, 0.96);
        box-shadow: 0px 14px 32px rgba(33, 43, 97, 0.19);
        backdrop-filter: blur(33px);
        border-radius: 16px;
        padding: 32px;
        position: relative;
    }
    .color {
        color: #f22f46;
    }

    .m-info-header {
        .title-des {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            color: #000000;
            margin-top: 16px;
        }
        h4 {
            font-style: normal;
            font-weight: 700;
            font-size: 26px;
            line-height: 35px;
        }
        .first-title {
            color: #000000;
        }
    }
    .m-info-body {
        padding: 32px 0;
        h5 {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 19px;
            color: #000000;
            margin-bottom: 20px;
        }
        .list-item {
            display: flex;
            margin-bottom: 16px;
            svg {
                width: 20px;
                height: 20px;
            }
            p {
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 19px;
            }
            .icon {
                margin-right: 16px;
            }
        }
    }
    .mobile-footer {
        display: flex;
        min-height: 194px;
        .m-footer-layout {
            flex: 1;
        }
        p {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 19px;
            color: #000000;
        }
        p.first-row {
            margin-bottom: 32px;
        }
        img {
            position: absolute;
            bottom: -32px;
            right: -14px;
        }
    }
    @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
        display: block;
        margin: 0 -16px;
        padding: 16px;
        padding-bottom: 32px;
    }
`;
const StyledLeftLayout = styled.div`
    flex: 3;
    background: ${theme.colors.main};
    height: 100vh;
    position: relative;
    background-image: url("/logo-register.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 80px 0;
    p,
    h4 {
        margin: 0;
    }
    .cross-line {
        border-bottom: 1.5px solid #ffffff;
        height: 1px;
        width: 84px;
        padding-top: 20px;
    }
    p {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #ffffff;
    }
    h4 {
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 53px;
        text-align: center;
        color: #fff;
    }
`;
