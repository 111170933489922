import {
    IBookingParams,
    IBookingDetailParams,
    IBookingCreate,
    IServiceParams,
    IServicesParams,
    IDayInMonth,
} from "@interfaces";
import axiosClient from "./axiosClient";

export const bookingAPI = {
    getBookingServices: (params: IBookingParams) => {
        const url = "booking_service/getBookingServices";
        return axiosClient.get(url, { params });
    },
    getBookingService: (params: IBookingDetailParams) => {
        const url = "booking_service/getBookingService";
        return axiosClient.get(url, { params });
    },
    createBookingService: (params: IBookingCreate) => {
        const url = "booking_service/createBookingService";
        return axiosClient.post(url, params);
    },
    deleteBookingService: (params: IBookingDetailParams) => {
        const url = "booking_service/deleteBookingService";
        return axiosClient.post(url, params);
    },
    getDayInMonthTemplate: (params: IDayInMonth) => {
        const url = "booking_service/getDayInMonthTemplate";
        return axiosClient.get(url, { params });
    },
    completeBookingService: (params: IBookingDetailParams) => {
        const url = "booking_service/completeBookingService";
        return axiosClient.post(url, params);
    },
    cancelBookingService: (params: IBookingDetailParams) => {
        const url = "booking_service/cancelBookingService";
        return axiosClient.post(url, params);
    },
};
