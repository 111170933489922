import { ISvg } from "@interfaces";
export const IconDepartment = (props: ISvg) => (
    <svg
        width={props.size || "24"}
        height={props.size || "24"}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M18.6998 16.2224C20.6212 16.6365 22 17.6115 22 19C22 20.8785 19.4766 22 16.5 22C13.5234 22 11 20.8785 11 19C11 17.6115 12.3788 16.6365 14.3002 16.2224C13.5071 15.5808 13 14.5996 13 13.5C13 11.567 14.567 10 16.5 10C18.433 10 20 11.567 20 13.5C20 14.5996 19.4929 15.5808 18.6998 16.2224ZM10 20C10.5523 20 11 20.4477 11 21C11 21.5523 10.5523 22 10 22H6C3.79086 22 2 20.2091 2 18V6C2 3.79086 3.79086 2 6 2H18C20.2091 2 22 3.79086 22 6V9C22 9.55229 21.5523 10 21 10C20.4477 10 20 9.55229 20 9V6C20 4.89543 19.1046 4 18 4H6C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20H10ZM7 8C6.44772 8 6 7.55228 6 7C6 6.44772 6.44772 6 7 6H17C17.5523 6 18 6.44772 18 7C18 7.55228 17.5523 8 17 8H7ZM7 12C6.44772 12 6 11.5523 6 11C6 10.4477 6.44772 10 7 10H9C9.55229 10 10 10.4477 10 11C10 11.5523 9.55229 12 9 12H7ZM7 16C6.44772 16 6 15.5523 6 15C6 14.4477 6.44772 14 7 14H9C9.55229 14 10 14.4477 10 15C10 15.5523 9.55229 16 9 16H7ZM13.078 18.9109C13.018 18.974 13.0027 19.001 13.0003 18.9991C13.0027 18.999 13.018 19.026 13.078 19.0891C13.2051 19.2227 13.4257 19.3677 13.7242 19.5004C14.4268 19.8127 15.4232 20 16.5 20C17.5768 20 18.5732 19.8127 19.2758 19.5004C19.5743 19.3677 19.7949 19.2227 19.922 19.0891C19.982 19.026 19.9973 18.999 19.9997 19.0009C19.9973 19.001 19.982 18.974 19.922 18.9109C19.7949 18.7773 19.5743 18.6323 19.2758 18.4996C18.5732 18.1873 17.5768 18 16.5 18C15.4232 18 14.4268 18.1873 13.7242 18.4996C13.4257 18.6323 13.2051 18.7773 13.078 18.9109ZM16.5 15C17.3284 15 18 14.3284 18 13.5C18 12.6716 17.3284 12 16.5 12C15.6716 12 15 12.6716 15 13.5C15 14.3284 15.6716 15 16.5 15Z"
            fill="#0D6EFD"
        />
    </svg>
);
