export const CreatePasswordSvg = () => (
    <svg
        width="221"
        height="174"
        viewBox="0 0 221 174"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M112.05 172.084C159.257 172.084 197.465 133.74 197.465 86.4307C197.465 39.1212 159.257 0.915039 112.05 0.915039C64.9809 0.915039 26.6338 39.2591 26.6338 86.5686C26.6338 133.878 64.9809 172.084 112.05 172.084Z"
            fill="#EFEFEF"
        />
        <path
            d="M152.867 171.765C139.854 172.179 124.211 171.765 113.136 171.628C102.199 172.041 86.4174 172.179 73.4043 171.765C86.2789 164.455 90.8474 150.8 91.4011 141.835H134.594C135.563 150.662 140.131 164.455 152.867 171.765Z"
            fill="#0D6EFD"
        />
        <path
            d="M194.674 27.6295V140.317C194.674 143.213 192.459 145.282 189.967 145.282H37.1326C34.5023 145.282 32.4258 143.076 32.4258 140.317V27.6295C32.4258 25.0088 34.6408 22.6641 37.1326 22.6641H189.69C192.598 22.802 194.674 25.0088 194.674 27.6295Z"
            fill="#0D6EFD"
        />
        <path
            d="M195.394 26.8285V123.404H32.4844V26.8285C32.4844 24.198 34.7655 21.9434 37.2367 21.9434H190.451C193.303 21.9434 195.394 24.198 195.394 26.8285Z"
            fill="#8A959E"
        />
        <path d="M189.487 27.6592H38.2002V117.688H189.677V27.6592H189.487Z" fill="white" />
        <path
            d="M113.272 138.524C115.487 138.524 117.287 136.869 117.287 134.524C117.287 132.317 115.626 130.524 113.272 130.524C111.057 130.524 109.258 132.18 109.258 134.524C109.258 136.731 110.919 138.524 113.272 138.524Z"
            fill="#C4C4C4"
        />
        <path
            d="M1.00098 172.041H219.732"
            stroke="#EFEFEF"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M16.3898 76.2242C19.5738 76.2242 22.0657 73.7415 22.0657 70.5691C22.0657 67.3968 19.5738 64.9141 16.3898 64.9141C13.2057 64.9141 10.7139 67.3968 10.7139 70.5691C10.7139 73.7415 13.3442 76.2242 16.3898 76.2242Z"
            fill="#FAC159"
        />
        <path
            d="M10.7132 57.8793C12.7897 57.8793 14.5894 56.0862 14.5894 54.0173C14.5894 51.9483 12.7897 50.1553 10.7132 50.1553C8.6366 50.1553 6.83691 51.9483 6.83691 54.0173C6.83691 56.2241 8.6366 57.8793 10.7132 57.8793Z"
            fill="#FCEDED"
        />
        <path
            d="M213.801 90.4305C217.816 90.4305 221 87.2581 221 83.2582C221 79.2583 217.816 76.0859 213.801 76.0859C209.787 76.0859 206.603 79.2583 206.603 83.2582C206.603 87.2581 209.787 90.4305 213.801 90.4305Z"
            fill="#0D6EFD"
        />
        <path
            d="M175.919 125.241H51.8678C47.3568 125.241 43.8711 122.324 43.8711 118.72V44.591C43.8711 40.9874 47.3568 38.0703 51.8678 38.0703H175.919C180.43 38.0703 183.916 40.9874 183.916 44.591V118.72C183.711 122.324 180.225 125.241 175.919 125.241Z"
            fill="#E7F1FF"
        />
        <path
            d="M54.4523 49.8986C56.0742 49.8986 57.3889 48.5869 57.3889 46.9688C57.3889 45.3508 56.0742 44.0391 54.4523 44.0391C52.8304 44.0391 51.5156 45.3508 51.5156 46.9688C51.5156 48.5869 52.8304 49.8986 54.4523 49.8986Z"
            fill="#F22F46"
        />
        <path
            d="M63.091 49.8986C64.7128 49.8986 66.0276 48.5869 66.0276 46.9688C66.0276 45.3508 64.7128 44.0391 63.091 44.0391C61.4691 44.0391 60.1543 45.3508 60.1543 46.9688C60.1543 48.5869 61.4691 49.8986 63.091 49.8986Z"
            fill="#FAC159"
        />
        <path
            d="M71.8995 49.8986C73.5214 49.8986 74.8362 48.5869 74.8362 46.9688C74.8362 45.3508 73.5214 44.0391 71.8995 44.0391C70.2777 44.0391 68.9629 45.3508 68.9629 46.9688C68.9629 48.5869 70.2777 49.8986 71.8995 49.8986Z"
            fill="#57BF94"
        />
        <path
            d="M70.8645 66.0982H54.2811C53.2446 66.0982 52.3809 65.2365 52.3809 64.5471C52.3809 63.6855 53.2446 62.9961 54.2811 62.9961H70.8645C71.901 62.9961 72.7647 63.8578 72.7647 64.5471C72.7647 65.4088 71.901 66.0982 70.8645 66.0982Z"
            fill="#8A959E"
        />
        <path
            d="M66.8915 92.8111H54.4539C53.4174 92.8111 52.5537 91.9494 52.5537 91.26C52.5537 90.3983 53.4174 89.709 54.4539 89.709H66.8915C67.928 89.709 68.7917 90.5707 68.7917 91.26C68.619 91.9494 67.7552 92.8111 66.8915 92.8111Z"
            fill="#8A959E"
        />
        <path
            d="M107.122 92.8111H72.7019C71.6655 92.8111 70.8018 91.9494 70.8018 91.26C70.8018 90.3983 71.6655 89.709 72.7019 89.709H107.122C108.159 89.709 109.023 90.5707 109.023 91.26C108.85 91.9494 107.986 92.8111 107.122 92.8111Z"
            fill="white"
        />
        <path
            d="M59.2906 84.1939H54.281C53.2446 84.1939 52.3809 83.3322 52.3809 82.6429C52.3809 81.7812 53.2446 81.0918 54.281 81.0918H59.2906C60.3271 81.0918 61.1908 81.9535 61.1908 82.6429C61.1908 83.5046 60.3271 84.1939 59.2906 84.1939Z"
            fill="white"
        />
        <path
            d="M94.1992 84.1939H67.2068C66.1704 84.1939 65.3066 83.3322 65.3066 82.6429C65.3066 81.7812 66.1704 81.0918 67.2068 81.0918H94.1992C95.2357 81.0918 96.0994 81.9535 96.0994 82.6429C96.0994 83.5046 95.2357 84.1939 94.1992 84.1939Z"
            fill="white"
        />
        <path
            d="M78.6381 75.0601H54.2811C53.2446 75.0601 52.3809 74.1984 52.3809 73.5091C52.3809 72.6474 53.2446 71.958 54.2811 71.958H78.6381C79.6745 71.958 80.5382 72.8197 80.5382 73.5091C80.5382 74.3708 79.8473 75.0601 78.6381 75.0601Z"
            fill="white"
        />
        <path
            d="M110.476 75.0601H86.1189C85.0825 75.0601 84.2188 74.1984 84.2188 73.5091C84.2188 72.6474 85.0825 71.958 86.1189 71.958H110.476C111.512 71.958 112.376 72.8197 112.376 73.5091C112.376 74.3708 111.685 75.0601 110.476 75.0601Z"
            fill="#8A959E"
        />
        <path
            d="M127.879 75.0601H118.178C117.141 75.0601 116.277 74.1984 116.277 73.5091C116.277 72.6474 117.141 71.958 118.178 71.958H127.879C128.916 71.958 129.78 72.8197 129.78 73.5091C129.78 74.3708 129.089 75.0601 127.879 75.0601Z"
            fill="#8A959E"
        />
        <path
            d="M158.577 137.917C181.676 137.917 200.402 119.084 200.402 95.8528C200.402 72.6216 181.676 53.7891 158.577 53.7891C135.478 53.7891 116.752 72.6216 116.752 95.8528C116.752 119.084 135.478 137.917 158.577 137.917Z"
            fill="#FAC159"
        />
        <path
            d="M193.456 95.8528C193.456 115.211 177.826 130.785 158.577 130.785C145.263 130.785 133.83 123.362 127.896 112.3C127.751 112.154 127.607 111.863 127.607 111.572C127.172 110.553 126.593 109.68 126.304 108.661C126.304 108.516 126.159 108.37 126.159 108.079C126.015 107.642 125.725 107.06 125.58 106.478C125.146 105.022 124.712 103.421 124.423 101.82C124.133 100.219 123.988 98.6183 123.844 96.8717C123.844 96.435 123.844 95.9984 123.844 95.5617C123.844 90.9042 124.712 86.3921 126.449 82.3168C126.449 82.1712 126.593 81.8801 126.738 81.7346C127.172 80.7157 127.607 79.6969 128.185 78.8236C128.33 78.5325 128.475 78.3869 128.62 78.0958C134.553 67.4707 145.842 60.4844 158.867 60.4844C177.826 60.7755 193.456 76.4948 193.456 95.8528Z"
            fill="white"
        />
        <path
            d="M184.915 83.6265V107.206C184.915 110.116 182.599 112.3 179.85 112.3H127.894C127.749 112.154 127.604 111.863 127.604 111.572C127.17 110.553 126.591 109.68 126.302 108.661C126.302 108.515 126.157 108.37 126.157 108.079C124.71 104.295 123.986 100.074 123.986 95.7071C123.986 91.0495 124.855 86.5375 126.591 82.4621C126.591 82.3166 126.736 82.0255 126.881 81.8799C127.315 80.8611 127.749 79.8423 128.328 78.969C128.473 78.6779 128.617 78.5323 128.762 78.2412H179.85C182.599 78.3868 184.915 80.7155 184.915 83.6265Z"
            fill="#E3E3E3"
        />
        <path d="M139.473 82.0254L131.369 108.661H129.487L137.592 82.0254H139.473Z" fill="white" />
        <path d="M144.105 82.0254L136.001 108.661H133.975L142.079 82.0254H144.105Z" fill="white" />
        <path d="M148.591 82.0254L140.632 108.661H138.605L146.71 82.0254H148.591Z" fill="white" />
        <path d="M153.221 82.0254L145.117 108.661H143.235L151.195 82.0254H153.221Z" fill="white" />
        <path
            d="M130.356 82.0254L124.277 101.966C123.988 100.365 123.843 98.7635 123.698 97.017L128.329 82.0254H130.356Z"
            fill="white"
        />
        <path
            d="M134.986 82.0254L126.882 108.661H126.303C126.303 108.515 126.158 108.37 126.158 108.079C126.013 107.642 125.724 107.06 125.579 106.478L133.105 81.8799H134.986V82.0254Z"
            fill="white"
        />
        <path d="M157.851 82.0254L149.747 108.661H147.721L155.825 82.0254H157.851Z" fill="white" />
        <path d="M162.34 82.0254L154.236 108.661H152.354L160.459 82.0254H162.34Z" fill="white" />
        <path d="M166.97 82.0254L158.866 108.661H156.84L164.944 82.0254H166.97Z" fill="white" />
        <path d="M171.456 82.0254L163.496 108.661H161.47L169.574 82.0254H171.456Z" fill="white" />
        <path d="M174.063 82.0254L165.958 108.661H167.984L176.089 82.0254H174.063Z" fill="white" />
        <path
            d="M180.574 82.3165L172.614 108.661H170.588L178.692 82.0254H179.85C180.14 82.0254 180.284 82.1709 180.574 82.3165Z"
            fill="white"
        />
        <path d="M181.299 88.2842V94.9794L177.102 108.661H175.076L181.299 88.2842Z" fill="white" />
        <path
            d="M181.299 103.421V107.205C181.299 108.078 180.575 108.661 179.852 108.661H179.707L181.299 103.421Z"
            fill="white"
        />
        <path
            d="M175.508 104.878H142.946C141.209 104.878 139.762 103.422 139.762 101.675V88.7216C139.762 86.975 141.209 85.5195 142.946 85.5195H175.508C177.245 85.5195 178.692 86.975 178.692 88.7216V101.675C178.548 103.422 177.1 104.878 175.508 104.878Z"
            fill="white"
            stroke="#E3E3E3"
            strokeMiterlimit="10"
        />
        <path
            d="M150.328 91.7774C150.762 92.0685 150.762 92.5051 150.472 92.9418L148.736 94.9795L151.196 94.5428C151.63 94.3973 152.064 94.8339 152.064 95.2706V95.7072C152.064 96.1438 151.63 96.4349 151.196 96.4349L148.736 95.9983L150.472 97.8904C150.762 98.1815 150.762 98.7637 150.328 99.0548L149.893 99.2004C149.459 99.4915 149.025 99.2004 148.88 98.7637L148.012 96.2894L147.144 98.7637C146.999 99.2004 146.42 99.3459 146.131 99.2004L145.841 99.0548C145.407 98.7637 145.407 98.3271 145.696 97.8904L147.433 95.8528L144.973 96.2894C144.539 96.435 144.104 95.9983 144.104 95.5617V95.2706C144.104 94.8339 144.539 94.3973 144.973 94.5428L147.433 94.9795L145.696 92.9418C145.407 92.6507 145.552 92.0685 145.841 91.7774L146.275 91.6318C146.71 91.3407 147.144 91.6318 147.288 92.0685L148.157 94.5428L149.025 92.0685C149.17 91.6318 149.604 91.4863 150.038 91.6318L150.328 91.7774Z"
            fill="black"
        />
        <path
            d="M161.472 91.7774C161.906 92.0685 161.906 92.5051 161.617 92.9418L159.88 94.9795L162.341 94.5428C162.775 94.3973 163.209 94.8339 163.209 95.2706V95.5617C163.209 95.9983 162.775 96.435 162.341 96.2894L159.88 95.8528L161.617 97.7449C161.906 98.036 161.906 98.6182 161.472 98.9093L161.183 99.0548C160.749 99.3459 160.315 99.0548 160.17 98.6182L159.301 96.1438L158.144 98.7637C157.999 99.2004 157.42 99.3459 157.131 99.2004L156.841 99.0548C156.407 98.7637 156.407 98.3271 156.696 97.8904L158.433 95.8528L155.973 96.2894C155.539 96.435 155.104 95.9983 155.104 95.5617V95.2706C155.104 94.8339 155.539 94.3973 155.973 94.5428L158.288 94.9795L156.696 92.9418C156.407 92.6507 156.552 92.0685 156.841 91.7774L157.131 91.6318C157.565 91.3407 157.999 91.6318 158.144 92.0685L159.012 94.5428L159.88 92.0685C160.025 91.6318 160.604 91.4863 160.893 91.6318L161.472 91.7774Z"
            fill="black"
        />
        <path
            d="M172.613 91.9234C172.903 92.0689 173.047 92.5056 172.758 92.9422L171.021 94.9799L173.626 94.3977C174.06 94.2522 174.35 94.6888 174.35 94.9799V95.5621C174.35 95.9988 174.06 96.2899 173.626 96.1443L171.021 95.7077L172.758 97.7454C173.047 98.0365 172.903 98.4731 172.613 98.7642L172.034 99.0553C171.745 99.2009 171.311 99.0553 171.166 98.7642L170.298 96.1443L169.284 98.6187C169.14 99.0553 168.706 99.2009 168.416 98.9098L167.837 98.6187C167.548 98.4731 167.403 98.0365 167.693 97.5998L169.429 95.5621L166.824 96.2899C166.39 96.2899 166.101 95.9988 166.101 95.7077V95.1255C166.101 94.6888 166.39 94.3977 166.824 94.5433L169.284 94.9799L167.548 92.9422C167.258 92.6511 167.403 92.2145 167.693 91.9234L168.271 91.6323C168.561 91.4867 168.995 91.6323 169.14 91.9234L170.008 94.3977L171.021 91.9234C171.166 91.6323 171.6 91.3412 171.89 91.6323L172.613 91.9234Z"
            fill="black"
        />
    </svg>
);
