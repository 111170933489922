import styled from "styled-components";
import { memo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Upload, Button, Row, Col, Modal, Input, Spin } from "antd";

import { biddingAPI } from "@api";
import { Checked, CheckIcon, IconDelete } from "@components";
import { showErrorMessage, useNotify } from "@utils";
import { UploadFileIcon } from "src/Components/icon/UploadFile";
import { IFileUrl } from "@interfaces";
import { Check } from "react-feather";

interface IProps {
    index: number;
    handleGetFile: (file: IFileUrl) => void;
    title?: string | JSX.Element;
    file?: IFileUrl;
    lable?: string;
    placeholder?: string;
    changeName: (name: string) => void;
    onDelete: () => void;
}

export const ComponentFileUpload = memo((props: IProps) => {
    const { t } = useTranslation();
    const { error } = useNotify();
    // props
    const { handleGetFile, title, file, changeName, onDelete } = props;

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const onUploadFile = async (file: any) => {
        setIsLoading(true);
        const formData = new FormData();
        //@ts-ignore
        formData.append("file", file);
        try {
            const res = await biddingAPI.uploadFile(formData);
            handleGetFile(res.data.result);
        } catch (err: any) {
            if (err.response) {
                error(showErrorMessage(err.response));
            } else error(t("message.error"));
        } finally {
            setIsLoading(false);
        }
    };

    const uploadProps = {
        name: "file",
        accept: ".pdf",
        maxCount: 1,
        beforeUpload() {
            return false;
        },
        onChange({ file }) {
            if (file.status !== "uploading") {
                onUploadFile(file);
            }
        },
        showUploadList: false,
    };

    return (
        <>
            <div className="title">{title}</div>
            <StyledContainer>
                <div className="file-bg">
                    <p className="file-name">
                        <Input value={file?.name} onChange={(e) => changeName(e.target.value)} />
                    </p>
                    <Upload multiple={false} {...uploadProps}>
                        <button
                            className="file-btn"
                            type="button"
                            style={file?.file_url ? { background: "#34C759" } : {}}
                        >
                            {file?.file_url ? <Checked /> : <UploadFileIcon />}
                            <span>{`อัปโหลดไฟล์`}</span>
                        </button>
                    </Upload>
                    <div className="delete-icon" onClick={onDelete}>
                        <IconDelete />
                    </div>
                </div>
            </StyledContainer>
        </>
    );
});

const StyledContainer = styled.div`
    /* background-color: #f7f7f7; */
    margin-bottom: 16px;
    .file-bg {
        padding: 20px;
        display: flex;
        justify-content: space-between;
        column-gap: 15px;
        align-items: center;
        column-gap: 15px;
        align-items: center;
        border-radius: 4px;
        border: 1px solid #f3f3f3;
        border-radius: 12px;
        background: #f7f7f7;
        .file-name {
            margin: auto 0;
            flex: 2;
            input {
                height: 45px;
                background: #ffffff;
                border-radius: 6px;
            }
        }
    }
    .file-btn {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        background-color: #0d6efd;
        color: white;
        font-weight: 500;
        font-size: 16px;
        padding: 10px 20px;
        border: none;
        border-radius: 4px;
        column-gap: 6px;
    }
    .ant-upload .ant-upload-btn {
        padding: 40px;
        background-color: #f7f7f8;
        p {
            font-size: 12px;
            text-align: center;
            color: #6c7084;
        }
    }
    .btn-drag {
        &:hover {
            color: white !important;
            background: #6c7084;
        }
    }
    .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
        border-color: #6c7084;
    }
    .ant-radio-wrapper {
        display: flex;
        align-items: center;
        height: 48px;
    }
    .preview-field {
        padding: 40px 20px;
        position: relative;
        border: 1px dashed #6c7084;
        border-radius: 12px;
        .img-wrap {
            overflow: hidden;
            position: relative;
            border-radius: 10px;
            cursor: pointer;
            height: 100%;
            img {
                object-fit: cover;
                width: 100%;
                height: 136px;
                object-position: center;
                @media (max-width: ${(p) => p.theme.breakPoints.breakOnlyMobile}) {
                    height: 250px;
                }
            }
            .img-layer {
                position: absolute;
                z-index: 1;
                background-color: transparent;
                width: 100%;
                height: 100%;
                :hover {
                    background-color: rgba(68, 61, 65, 0.45);
                }
                :hover .icon {
                    display: block;
                    z-index: 100;
                }
                .icon {
                    display: none;
                }
                .icon-full-screen {
                    position: absolute;
                    left: 10px;
                    top: 10px;
                }
                .icon-delete {
                    position: absolute;
                    right: 10px;
                    top: 10px;
                }
            }
        }
        .button-upload-preview {
            overflow: hidden;
            position: absolute;
            right: 20px;
            bottom: 14px;
            border: none;
            cursor: pointer;
            button {
                border: none;
                font-size: 14px;
                font-weight: 600;
                background-color: transparent !important;
                :hover,
                :active,
                :focus {
                    color: rgba(0, 0, 0, 0.65);
                }
            }
            input {
                position: absolute;
                z-index: 1;
                opacity: 0;
                cursor: pointer;
            }
        }
    }
    .ant-upload .ant-upload-btn {
        padding: 40px;
        background-color: #f7f7f8;
        p {
            font-size: 12px;
            text-align: center;
            color: #6c7084;
        }
    }
    .btn-drag {
        &:hover {
            color: white !important;
            background: #6c7084;
        }
    }
    .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
        border-color: #6c7084;
    }
    .drag-field {
        height: 100%;
    }
    .ant-upload-drag-container {
        .label {
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            color: #a5a5a5;
            margin-top: 16px;
        }
    }
    @media (max-width: ${(p) => p.theme.breakPoints.breakOnlyMobile}) {
        .preview-field {
            padding: 20px;
            .img-wrap {
                img {
                    height: 100px;
                    width: 100px;
                }
            }
        }
        .ant-upload-btn {
            padding: 20px;
        }
    }
`;

// const StyledUploadButton = styled(Button)`
//     background: #6c7084;
//     font-size: 16px;
//     color: white;
//     padding: 6px 16px;
//     height: auto;
//     border-radius: 2px;
//     margin-bottom: 18px;
// `;

const StyledUploadButtonInline = styled(Button)`
    display: flex;
    align-items: center;
`;

const StyledCol = styled(Col)`
    margin-bottom: 12px;
`;
