export default {
    user: "ผู้ใช้งาน",
    contact: "รายชื่อ",
    loyalty_rules: "กฎการใช้งาน Loyalty",
    points: "Points",
    _points: "points",
    initial_point: "Initial Points",
    need_one_rule: "You need at least one loyalty rule",
    require_rule: "need loyalty rule",
    time_expiry: "เวลาหมดอายุ",
    image: "รูปภาพ",
    membership: "กลุ่มสมาชิก",
    baht_spent: "บาท",
    membership_name: "ชื่อกลุ่มสมาชิก",
    benefits: "สิทธิพิเศษ",
    name: "ชื่อ",
    phone_number: "เบอร์โทร",
    email: "อีเมล",
    sales: "ยอดซื้อ",
    phoneNumber: "เบอร์โทร",
    date_of_birth: "วันเดือนปีเกิด",
    benefit: "สิทธิพิเศษ",
    role: "Role",
    _contact_name: "ชื่อของรายชื่อ",
    _contact: "รายชื่อ",
    _user: "ผู้ใช้งาน",
    webhook: "Webhook",
    reward_code: "โค้ดของรางวัล",
    quantity_limit: "จำนวนที่จำกัด",
    quantity_limit_per_user: "จำนวนที่จำกัดต่อผู้ใช้่งาน",
    description: "รายละเอียด",
    this_field: "ช่องนี้",
    _segment: "segment",
    _this: "นี้",
    _benefit: "สิทธิพิเศษ",
    _reward: "ของรางวัล",
    access_token: "Access token",
    channel_ID: "Channel ID",
    channel_secret: "Channel secret",
    liff_Url: "LIFF URL",
    field_name: "ชื่อช่อง",
    property_name: "ชื่อ Property",
    option_name: "ชื่อตัวเลือก",
    tag: "Tag",
    _coupon: "คูปอง",
    _free_point: "Free Point",
    _traffic_source: "Traffic Source",
    point_threshold: "เกณฑ์ Points สำหรับสมาชิก Tier นี้",
};
