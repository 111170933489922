import { ISvg } from "@interfaces";
export const IconLock = (props: ISvg) => (
    <svg
        width={props.size || "120"}
        height={props.size || "120"}
        viewBox="0 0 120 120"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="60" cy="60" r="60" fill={props.color || "#0D6EFD"} fillOpacity="0.16" />
        <path
            d="M80.9414 36.2391V58.9739H74.5312V36.2391C74.6582 34.2381 74.3764 32.2321 73.7031 30.345C73.0298 28.4579 71.9793 26.7298 70.6165 25.2675C69.2537 23.8051 67.6076 22.6395 65.7798 21.8427C63.952 21.046 61.9813 20.6349 59.9894 20.6349C57.9976 20.6349 56.0269 21.046 54.1991 21.8427C52.3713 22.6395 50.7251 23.8051 49.3624 25.2675C47.9996 26.7298 46.9491 28.4579 46.2758 30.345C45.6025 32.2321 45.3207 34.2381 45.4477 36.2391V58.9739H39.0375V36.2391C38.886 33.3749 39.3156 30.5096 40.3001 27.818C41.2846 25.1264 42.8034 22.6648 44.7639 20.5834C46.7244 18.502 49.0854 16.8444 51.7031 15.7116C54.3207 14.5788 57.1402 13.9946 59.9894 13.9946C62.8387 13.9946 65.6582 14.5788 68.2758 15.7116C70.8935 16.8444 73.2545 18.502 75.215 20.5834C77.1754 22.6648 78.6943 25.1264 79.6788 27.818C80.6633 30.5096 81.0929 33.3749 80.9414 36.2391Z"
            fill="white"
        />
        <path
            opacity="0.36"
            d="M39.0664 42.1533L45.447 42.3923V58.9729H39.0664V42.1533Z"
            fill={props.color || "#0D6EFD"}
        />
        <path
            opacity="0.36"
            d="M80.9421 43.8558V58.9725H74.5615V43.5869L80.9421 43.8558Z"
            fill={props.color || "#0D6EFD"}
        />
        <path
            d="M89.1319 50.5488H30.876V99.2149H89.1319V50.5488Z"
            fill={props.color || "#0D6EFD"}
        />
        <path
            d="M62.455 74.5685L64.8885 86.1898H54.5312L56.9944 74.5685C56.0378 73.9731 55.3004 73.0796 54.8943 72.0238C54.4883 70.968 54.4357 69.8075 54.7447 68.7189C55.0536 67.6303 55.7073 66.6729 56.6061 65.9925C57.505 65.312 58.6 64.9455 59.7247 64.9488C60.8494 64.9455 61.9444 65.312 62.8433 65.9925C63.7422 66.6729 64.3958 67.6303 64.7047 68.7189C65.0137 69.8075 64.9612 70.968 64.5551 72.0238C64.149 73.0796 63.4116 73.9731 62.455 74.5685Z"
            fill="white"
        />
    </svg>
);
