import { useState, useEffect } from "react";
import {
    StyledTable,
    ComponentEmptyData,
    StyledTableButtonTop,
    ComponentRowAdmin,
} from "@components";
import { Row, Col, Pagination } from "antd";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import {
    PAGINATION,
    PAGE_START,
    INITIAL_LIST_PARAMS,
    PATH_CREATE_NEWS,
    PATH_ADMIN_CREATE,
} from "@configs";
import { IPaginationNext, IAdminUser } from "@interfaces";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth, setLoading } from "@redux";
import { useNotify, usePermission } from "@utils";
import { useHistory } from "react-router";
import { adminAPI } from "@api";

const INITAL_PAGE = {
    xpage: PAGE_START,
    xlimit: PAGINATION,
};

export const PageAdminList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const org_id = useSelector(selectAuth).auth?.organization_data?._id;
    const permission = useSelector(selectAuth).permission;
    const [status, setStatus] = useState<boolean>(false);
    const [pagination, setPagination] = useState<IPaginationNext>(INITAL_PAGE);
    const [admins, setAdmins] = useState<IAdminUser[]>([]);
    const [total, setTotal] = useState<number>(0);
    const { success, error } = useNotify();
    const history = useHistory();
    usePermission({ permission: permission?.permission_list.organization.is_manage_permission });
    useEffect(() => {
        fetchAdmins(pagination, status);
    }, [pagination, status]);

    const fetchAdmins = async (pagination: IPaginationNext, status: boolean) => {
        dispatch(setLoading(true));
        try {
            const response = await adminAPI.getAdmins({
                organization_id: org_id || "",
                ...pagination,
                ...(status && { is_active: status }),
            });
            setAdmins(response.data.result);
            setTotal(response.data.total);
            // success(response.data.msg);
        } catch (err: any) {
            error(err.msg);
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const updateAdmin = async (isEnable: boolean, data: IAdminUser) => {
        dispatch(setLoading(true));
        try {
            const res = await adminAPI.updateAdmin({
                ...data,
                ...{ organization_id: org_id || "" },
                ...(isEnable && { is_active: isEnable }),
            });
            callbackGetList();
            if (res.data.error === "1") {
                error(t(res.data.msg));
            } else {
                success(t(res.data.msg));
            }
        } catch (error) {
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };
    const callbackGetList = () => {
        fetchAdmins(pagination, status);
    };
    const handlePagination = (page) => {
        setPagination({ xpage: page, xlimit: pagination.xlimit });
    };

    return (
        <StyledTable>
            <div className="page-layout small-layout">
                <div className="page-header-layout">
                    <div className="page-header">
                        <div>
                            <h3>{`รายชื่อผู้ใช้งาน`}</h3>
                        </div>
                        <div className="flex-header">
                            <StyledTableButtonTop
                                type="danger"
                                size="default"
                                text={`เพิ่มผู้ใช้งาน`}
                                onClick={() => history.push(PATH_ADMIN_CREATE)}
                                className="add-btn"
                                icon="plus"
                            />
                        </div>
                    </div>
                </div>
                <div className="page-table-layout">
                    <div className="table">
                        <ScrollContainer vertical={false}>
                            <div className="table_ground">
                                <div className="table-body">
                                    <div className="table-header">
                                        <Row
                                            gutter={24}
                                            type="flex"
                                            justify="space-between"
                                            align="middle"
                                            className="header-row"
                                        >
                                            <Col className="col-item" span={6}>
                                                <p>{`รายชื่อเจ้าหน้าที่`}</p>
                                            </Col>
                                            <Col className="col-item" span={4}>
                                                <p>{`สิทธิการใช้งาน`}</p>
                                            </Col>
                                            <Col className="col-item" span={4}>
                                                <p>{`อีเมล`}</p>
                                            </Col>
                                            <Col className="col-item" span={6}>
                                                <p>{`สถานะยืนยันตัวตนบัญชี`}</p>
                                            </Col>
                                            <Col className="col-item" span={4}>
                                                <p>{`จัดการ`}</p>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div>
                                        {admins && admins.length > 0 ? (
                                            <div className="data-table">
                                                {admins.map((item, index) => (
                                                    <ComponentRowAdmin
                                                        key={index}
                                                        data={item}
                                                        itemJson={JSON.stringify(item)}
                                                        handleEdit={updateAdmin}
                                                        callbackGetList={callbackGetList}
                                                        index={index}
                                                    />
                                                ))}
                                                <div style={{ width: "100%", height: 10 }}></div>
                                            </div>
                                        ) : (
                                            <ComponentEmptyData />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </ScrollContainer>
                    </div>
                </div>
                <div className="page-bottom">
                    {total > 0 && (
                        <div className="pagination">
                            <Pagination
                                defaultCurrent={INITIAL_LIST_PARAMS.page || 1}
                                pageSize={PAGINATION}
                                total={total}
                                onChange={handlePagination}
                                current={pagination.xpage}
                            />
                        </div>
                    )}
                </div>
            </div>
        </StyledTable>
    );
};
