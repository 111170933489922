export const HomeOutline = () => (
    <svg
        width="21"
        height="22"
        viewBox="0 0 21 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.7168 14.2913C12.9208 14.2913 13.9008 15.2643 13.9008 16.4603V19.5362C13.9008 19.7932 14.1068 19.9992 14.3708 20.0052H16.2768C17.7788 20.0052 18.9998 18.7993 18.9998 17.3173V8.59325C18.9928 8.08325 18.7498 7.60325 18.3328 7.28425L11.7398 2.02625C10.8548 1.32525 9.61682 1.32525 8.72882 2.02825L2.18082 7.28225C1.74782 7.61125 1.50482 8.09125 1.49982 8.61025V17.3173C1.49982 18.7993 2.72082 20.0052 4.22282 20.0052H6.14682C6.41782 20.0052 6.63782 19.7902 6.63782 19.5262C6.63782 19.4682 6.64482 19.4102 6.65682 19.3552V16.4603C6.65682 15.2713 7.63082 14.2993 8.82582 14.2913H11.7168ZM16.2768 21.5053H14.3528C13.2508 21.4793 12.4008 20.6143 12.4008 19.5363V16.4603C12.4008 16.0913 12.0938 15.7913 11.7168 15.7913H8.83082C8.46182 15.7933 8.15682 16.0943 8.15682 16.4603V19.5263C8.15682 19.6013 8.14682 19.6733 8.12582 19.7413C8.01782 20.7313 7.17182 21.5053 6.14682 21.5053H4.22282C1.89382 21.5053 -0.000183105 19.6262 -0.000183105 17.3172V8.60325C0.00981689 7.60925 0.467817 6.69925 1.25882 6.10025L7.79382 0.85525C9.23282 -0.28475 11.2378 -0.28475 12.6738 0.85325L19.2558 6.10325C20.0288 6.69225 20.4868 7.60025 20.4998 8.58225V17.3172C20.4998 19.6262 18.6058 21.5053 16.2768 21.5053Z"
            fill="#0D6EFD"
        />
    </svg>

);
