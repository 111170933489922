import {
  ComponentContactSortIcon,
  ComponentEmptyData,
  ComponentRowAreaList,
  StyledTable,
  StyledTableButtonTop,
} from "@components";
import {
  enumContactSortFields,
  enumSortBy,
  PAGE_START,
  PAGINATION,
  PATH_CREATE_EMPLOY,
  PATH_TRASH_FEE_AREA_CREATE,
} from "@configs";
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import { IBidding, IContactListParams, IPaginationNext, ITrashfeeArea } from "@interfaces";
import { getCategories, selectAuth, selectContact, selectOrg, setLoading } from "@redux";
import { useNotify, usePermission } from "@utils";
import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { trashfeeAPI } from "@api";

const INITAL_PAGE = {
  xpage: PAGE_START,
  xlimit: PAGINATION,
};

export const PageAreaList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const categories = useSelector(selectOrg).category;
  const permission = useSelector(selectAuth).permission;
  const org_id = useSelector(selectAuth).auth?.organization_data?._id;
  const search_params = useSelector(selectContact).paramsContact;
  const [params, setParams] = useState<IContactListParams>(search_params);

  const [pagination, setPagination] = useState<IPaginationNext>(INITAL_PAGE);
  const [textSearch, setTextSearch] = useState<string>("");
  const [dateFrom, setDateFrom] = useState<string>("");
  const [dateTo, setDateTo] = useState<string>("");

  const [news, setNews] = useState<IBidding[]>([]);
  const [areaList, setAreaList] = useState<ITrashfeeArea[]>([]);
  const [total, setTotal] = useState<number>(0);
  const { success, error } = useNotify();
  usePermission({ permission: permission?.permission_list.trash_fee.is_manage });
  useEffect(() => {
    fetchAreaList();
  }, [pagination, textSearch, dateFrom, dateTo]);

  const handleSort = (sortField: enumContactSortFields, sortBy: enumSortBy) => {
    const newSortParams = { ...params, sortField, sortBy };
    setParams(newSortParams);
    // fetchContacts(newSortParams);
  };

  const fetchAreaList = async () => {
    dispatch(setLoading(true));
    try {
      const payload = {
        organization_id: org_id || "",
        xpage: "1",
      };
      const response = await trashfeeAPI.getAreaList({ ...payload });
      setAreaList(response.data.result);
      dispatch(setLoading(false));
    } catch {
      dispatch(setLoading(false));

    }
  };


  const callbackGetList = () => {
    // fetchNews(pagination, textSearch, dateFrom, dateTo);
    fetchAreaList();
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(TouchSensor)
  );
  const editNews = (userId: string, points: number) => {
    console.log("update fun");
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const swapItem = (items: ITrashfeeArea[]) => {
        const oldIndex = items.findIndex((item) => JSON.stringify(item) === active.id);
        const newIndex = items.findIndex((item) => JSON.stringify(item) === over.id);
        return arrayMove(items, oldIndex, newIndex);
      };
      setAreaList(swapItem);
    }
  };

  return (
    <StyledTable>
      <div className="page-layout">
        <div className="page-header-layout">
          <div className="page-header">
            <div>
              <h3>หมวดพื้นที่</h3>
            </div>
            <div className="flex-header">
              <StyledTableButtonTop
                type="danger"
                size="default"
                text="เพิ่มหมวดพื้นที่"
                onClick={() => history.push(PATH_TRASH_FEE_AREA_CREATE)}
                className="add-btn"
                icon="plus"
              />
            </div>
          </div>
          <div className="search-layout">
            <div className="status-filter"></div>
            <div className="filter"></div>
          </div>
        </div>
        <div className="page-table-layout">
          <div className="table">
            <ScrollContainer vertical={false}>
              <div className="table_ground">
                <div className="table-body">
                  <div className="table-header">
                    <Row
                      gutter={24}
                      type="flex"
                      justify="space-between"
                      align="middle"
                      className="header-row"
                    >
                      <Col className="col-item" span={5}>
                        <p>ชื่อหมวดพื้นที่</p>
                        <div className="filter-button">
                          <ComponentContactSortIcon
                            handleSort={handleSort}
                            sortBy={enumSortBy.ASC}
                            sortField={enumContactSortFields.FULL_NAME}
                            searchParams={params}
                          />
                          <ComponentContactSortIcon
                            handleSort={handleSort}
                            sortBy={enumSortBy.DESC}
                            sortField={enumContactSortFields.FULL_NAME}
                            searchParams={params}
                          />
                        </div>
                      </Col>
                      <Col className="col-item" span={12}>
                        <p>รายละเอียดที่อยู่</p>
                        <div className="filter-button">
                          <ComponentContactSortIcon
                            handleSort={handleSort}
                            sortBy={enumSortBy.ASC}
                            sortField={enumContactSortFields.TEL}
                            searchParams={params}
                          />
                          <ComponentContactSortIcon
                            handleSort={handleSort}
                            sortBy={enumSortBy.DESC}
                            sortField={enumContactSortFields.TEL}
                            searchParams={params}
                          />
                        </div>
                      </Col>
                      <Col className="col-item" span={3}>
                        <p>จัดการ</p>
                        <div className="filter-button">
                          <ComponentContactSortIcon
                            handleSort={handleSort}
                            sortBy={enumSortBy.ASC}
                            sortField={enumContactSortFields.POINTS}
                            searchParams={params}
                          />
                          <ComponentContactSortIcon
                            handleSort={handleSort}
                            sortBy={enumSortBy.DESC}
                            sortField={enumContactSortFields.POINTS}
                            searchParams={params}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                  >
                    <SortableContext
                      items={news.map((item) => JSON.stringify(item))}
                      strategy={rectSortingStrategy}
                    >
                      {areaList && areaList.length > 0 ? (
                        <div className="data-table">
                          {areaList.map((item, index) => (
                            <ComponentRowAreaList
                              key={index}
                              data={item}
                              itemJson={JSON.stringify(item)}
                              handleEdit={editNews}
                              callbackGetList={callbackGetList}
                              index={index}
                            />
                          ))}
                          <div
                            style={{ width: "100%", height: 10 }}
                          ></div>
                        </div>
                      ) : (
                        <ComponentEmptyData />
                      )}
                    </SortableContext>
                  </DndContext>
                </div>
              </div>
            </ScrollContainer>
          </div>
        </div>
      </div>
    </StyledTable>
  );
};
