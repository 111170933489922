import { useEffect, memo, useState, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Row, Col, Select, Modal } from "antd";
import styled from "styled-components";
import moment from "moment";
import {
  IAmphur,
  IResultUpdate,
  ITrashfeeArea,
  ITrashfeeAreaCreate,
  ITrashfeeDistrict,
  ITrashfeeHouse,
  ITrashfeeHouseCreate,
  ITrashfeeHouseUpdate,
} from "@interfaces";
import { StyledSubmitButton, StyledCancelButton, StyledCard, SharedInput } from "@components";
import { useYup } from "@validations";
import { setLoading, selectAuth, selectContact } from "@redux";
import { showErrorMessage, useNotify } from "@utils";
import { PATH_TRASH_FEE_HOUSE, PATH_TRASH_FEE_HOUSE_CREATE } from "@configs";
import { trashfeeAPI } from "@api";

interface IProps {
  // result: IResult | undefined;
  detail: ITrashfeeHouse | undefined;
  areaList: ITrashfeeArea[];
}

const initData: IResultUpdate = {
  organization_id: "",
  title: "",
  image_url: "",
  is_enable: true,
  information_id: "",
  created_date: new Date().toString(),
  documents: [],
};
const { Option } = Select;
export const ModuleHouseForm = memo((props: IProps) => {
  //hook
  const { YupNews, YupBidding } = useYup();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { error, success } = useNotify();
  const search_params = useSelector(selectContact).paramsContact;

  const organization_id = useSelector(selectAuth).auth?.admin_data?.organization_id;

  const pathName = window.location.pathname;
  const [districtList, setDistrictList] = useState<ITrashfeeDistrict[]>([]);
  const org_id = useSelector(selectAuth).auth?.organization_data?._id;

  const createBidding = Yup.object().shape(YupBidding);
  const { detail, areaList } = props;
  const [amphurList, setAmphurList] = useState<IAmphur[]>([]);
  const prefix = ['คุณ', 'นาย', 'นาง', 'นางสาว'];
  const [focusAmphur, setFocusAmphur] = useState<string>("");
  const [housePrice, setHousePrice] = useState<number>(0);
  const [value, setValue] = useState({
    organization_id: "",
    profile_id: "",
    area_id: "",
    register_no: "",
    prefix: "",
    name: "",
    surname: "",
    card_no: "",
    house_no: "",
    moo: "",
    soi: "",
    road: "",
    district_id: "",
    amphur_id: "",
    house_type: "home",
    trash_fee: 0,
  });


  useEffect(() => {
    if (org_id) {
      getAllAmphur();
    }
  }, [org_id]);

  useEffect(() => {
    if (focusAmphur !== "") {
      fetchDistrict(focusAmphur);
    }
  }, [focusAmphur]);

  useEffect(() => {
    if (detail) {
      setValue({
        ...value,
        area_id: detail.area_id,
        register_no: detail.register_no,
        prefix: detail.owner.prefix,
        name: detail.owner.name,
        surname: detail.owner.surname,
        card_no: detail.owner.card_no,
        house_no: detail.house_no,
        moo: detail.moo,
        soi: detail.soi,
        road: detail.road,
        district_id: detail.district_id,
        trash_fee: detail.trash_fee,
        amphur_id: detail?.amphur_id,
        ...(detail.amphur_id === "" && {
          district_id: "",
          amphur_id: "",
        }),
      });
      setFocusAmphur(detail?.amphur_id);
      // setFieldValue("title", result.title);
      // setFieldValue("is_enable", result.is_enable);
      // setFieldValue("information_id", result._id);
      // setFieldValue("created_date", result.created_date);
      // setFieldValue("documents", result.documents);
      // setFieldValue("image_url", result.image_url);
    }
  }, [detail]);

  const getAllAmphur = async () => {
    try {
      let response = await trashfeeAPI.getAllAmphurByOrganizationId({
        organization_id: org_id || ""
      });
      if (response.data.error === "0") {
        setAmphurList(response.data.result);
      } else {
        error(response.data.msg);
      }
    } catch (error: any) {
      if (error) {
        error(error.msg);
      }
    }
  };

  const fetchHousePrice = async () => {
    try {
      dispatch(setLoading(true));

      const response = await trashfeeAPI.getHomePrice({
        organization_id: org_id || "",
      });
      console.log('house price', response.data);
      if (response.data.error === "0") {
        setHousePrice(response.data.result);
      }
      dispatch(setLoading(false));

    } catch {
      dispatch(setLoading(false));
    }
  };

  const fetchDistrict = async (aId: string) => {
    try {
      const response = await trashfeeAPI.getDistrictList({
        amphur_id: aId,
        xpage: 1,
        xlimit: 30,
      });
      setDistrictList(response.data.result);
    } catch (err: any) {
      error(err.message);
    }
  };

  const amphurChange = (id: string) => {
    setValue({
      ...value,
      amphur_id: id,
      district_id: "",
    });
    setFocusAmphur(id);
  };

  const handleBiddingSubmit = (values: IResultUpdate) => {
    // console.log("hello submit", values);
  };

  const handleClose = () => {
    history.push(PATH_TRASH_FEE_HOUSE);
  };

  const prefixChange = (text: string) => {
    setValue({
      ...value,
      prefix: text,
    });
  };

  const districtChange = (id: string) => {
    setValue({
      ...value,
      district_id: id,
    });
  };
  const areaChange = (id: string) => {
    setValue({
      ...value,
      area_id: id,
    });
  };

  const { values, handleChange, handleBlur, handleSubmit, errors, touched, setFieldValue } =
    useFormik({
      initialValues: initData,
      validationSchema: createBidding,
      enableReinitialize: true,
      onSubmit: handleBiddingSubmit,
    });

  const saveHouse = async () => {
    // save house
    if (pathName === PATH_TRASH_FEE_HOUSE_CREATE) {
      try {
        let payload: ITrashfeeHouseCreate = {
          organization_id: organization_id || "",
          profile_id: "",
          area_id: value.area_id,
          register_no: value.register_no,
          prefix: value.prefix,
          name: value.name,
          surname: value.surname,
          card_no: value.card_no,

          house_no: value.house_no,
          moo: value.moo,
          soi: value.soi,
          road: value.road,
          district_id: value.district_id,
          house_type: "home",
          trash_fee: housePrice,
          amphur_id: value.amphur_id,
        };
        const response = await trashfeeAPI.createHouse(payload);
        if (response.data.error === "0") {
          success(t("message.create.success"));
          handleClose();
        } else {
          error(response.data.msg);
        }

      } catch (err: any) {
        if (err.response) {
          err(showErrorMessage(err.response));
        } else {
          error(t("message.error"));
        }
      } finally {
        dispatch(setLoading(false));
      }
    }
  };


  const editHouse = async () => {
    // edit house
    if (id !== "") {
      console.log('edit hosue');
      try {
        let payload: ITrashfeeHouseUpdate = {
          organization_id: organization_id || "",
          house_id: id,
          profile_id: "",
          area_id: value.area_id,
          register_no: value.register_no,
          prefix: value.prefix,
          name: value.name,
          surname: value.surname,
          card_no: value.card_no,

          house_no: value.house_no,
          moo: value.moo,
          soi: value.soi,
          road: value.road,
          district_id: value.district_id,
          house_type: "home",
          trash_fee: housePrice,
          amphur_id: value.amphur_id,
        };
        const response = await trashfeeAPI.updateHouse(payload);
        if (response.data.error === "0") {
          success(t("message.create.success"));
          handleClose();
        } else {
          error(response.data.msg);
        }

      } catch (err: any) {
        if (err.response) {
          err(showErrorMessage(err.response));
        } else {
          error(t("message.error"));
        }
      } finally {
        dispatch(setLoading(false));
      }
    }
  };

  const saveArea = () => {
    dispatch(setLoading(true));
    try {
      // const payload: ITrashfeeAreaCreate = {
      //   organization_id: organization_id || "",
      //   area_name: 
      // }
    } catch {

    }
  };

  const changeField = (e: any) => {
    let arr = value;
    arr[e.target.id] = e.target.value;
    setValue({ ...arr });
  };

  return (
    <div className="form-wrap">
      <form onSubmit={handleSubmit}>
        <div className="page-header">
          <h3>
            {pathName === PATH_TRASH_FEE_HOUSE_CREATE
              ? "เพิ่มรายชื่อทะเบียนประเภทครัวเรือน"
              : "แก้ไขรายชื่อทะเบียนประเภทครัวเรือน"
            }
          </h3>
          <p>โปรดระบุรายละเอียดให้ครบถ้วน</p>
        </div>
        {/* สร้างรายการประกาศผล */}
        {pathName === PATH_TRASH_FEE_HOUSE_CREATE ? (
          <StyledPageBody>
            <StyledCard>
              <div className="title">
                ข้อมูลเจ้าของทะเบียนบ้านครัวเรือน
              </div>
              <div>
                <Row gutter={16}>
                  <Col md={4}>
                    <label className="label">
                      <span className="label-name">คำนำหน้า*</span>
                      <br /> <br />
                    </label>
                    <Select
                      value={value.prefix}
                      onChange={prefixChange}
                    >
                      {prefix.length > 0 &&
                        prefix.map((item, index) => {
                          return (
                            <Option value={item} key={index}>
                              {item}
                            </Option>
                          );
                        })
                      }
                    </Select>
                  </Col>
                  <Col md={10}>
                    <SharedInput
                      label="ชื่อ*"
                      // descLabel={t("page._2_100_characters")}
                      name="name"
                      value={value.name}
                      onBlur={handleBlur}
                      onChange={changeField}
                      parentClassName="mb-6"
                    />
                  </Col>
                  <Col md={10}>
                    <SharedInput
                      label="นามสกุล*"
                      // descLabel={t("page._2_100_characters")}
                      name="surname"
                      value={value.surname}
                      onBlur={handleBlur}
                      onChange={changeField}
                      parentClassName="mb-6"
                    />
                  </Col>
                  <Col md={24}>
                    <SharedInput
                      label="เลขบัตรประชาชน*"
                      // descLabel={t("page._2_100_characters")}
                      name="card_no"
                      value={value.card_no}
                      onBlur={handleBlur}
                      onChange={changeField}
                      parentClassName="mb-6"
                    />
                  </Col>
                </Row>
              </div>
            </StyledCard>
            <StyledCard>
              <div className="title">
                ข้อมูลที่อยู่
              </div>
              <div>
                <Row gutter={16}>
                  <Col md={12}>
                    <label className="label">
                      <span className="label-name">หมวดพื้นที่*</span>
                      <br /> <br />
                    </label>
                    <Select
                      value={value.area_id}
                      onChange={areaChange}
                    >
                      {areaList.length > 0 &&
                        areaList.map((item, index) => {
                          return (
                            <Option value={item._id} key={index}>
                              {item.area_name}
                            </Option>
                          );
                        })
                      }
                    </Select>
                  </Col>
                  <Col md={12}>
                    <SharedInput
                      label="เลขที่ทะเบียนบ้าน*"
                      // descLabel={t("page._2_100_characters")}
                      name="register_no"
                      value={value.register_no}
                      onBlur={handleBlur}
                      onChange={changeField}
                      parentClassName="mb-6"
                    />
                  </Col>
                  <Col md={12}>
                    <SharedInput
                      label="บ้านเลขที่*"
                      // descLabel={t("page._2_100_characters")}
                      name="house_no"
                      value={value.house_no}
                      onBlur={handleBlur}
                      onChange={changeField}
                      parentClassName="mb-6"
                    />
                  </Col>
                  <Col md={12}>
                    <SharedInput
                      label="ซอย"
                      // descLabel={t("page._2_100_characters")}
                      name="soi"
                      value={value.soi}
                      onBlur={handleBlur}
                      onChange={changeField}
                      parentClassName="mb-6"
                    />
                  </Col>
                  <Col md={12}>
                    <SharedInput
                      label="หมู่"
                      // descLabel={t("page._2_100_characters")}
                      name="moo"
                      value={value.moo}
                      onBlur={handleBlur}
                      onChange={changeField}
                      parentClassName="mb-6"
                    />
                  </Col>
                  <Col md={12}>
                    <SharedInput
                      label="ถนน"
                      // descLabel={t("page._2_100_characters")}
                      name="road"
                      value={value.road}
                      onBlur={handleBlur}
                      onChange={changeField}
                      parentClassName="mb-6"
                    />
                  </Col>
                  <Col xs={6} sm={6} md={12}>
                    <label className="label">
                      <span className="label-name">อำเภอ*</span>
                      <br /> <br />
                    </label>
                    <Select
                      value={value?.amphur_id}
                      onChange={amphurChange}
                    >
                      {amphurList.length > 0 &&
                        amphurList.map((item, index) => {
                          return (
                            <Option value={item.amphur_id} key={index}>
                              {item.amphur_name_tha}
                            </Option>
                          );
                        })
                      }
                    </Select>
                  </Col>
                  <Col md={12}>
                    <label className="label">
                      <span className="label-name">ตำบล*</span>
                      <br /> <br />
                    </label>
                    <Select
                      value={value.district_id}
                      onChange={districtChange}
                    >
                      {districtList.length > 0 &&
                        districtList.map((item, index) => {
                          return (
                            <Option value={item.district_id} key={index}>
                              {item.district_name_tha}
                            </Option>
                          );
                        })
                      }
                    </Select>
                  </Col>
                  {/* <Col md={12}>
                    <SharedInput
                      label="รหัสไปรษณีย์"
                      // descLabel={t("page._2_100_characters")}
                      name="card_no"
                      onBlur={handleBlur}
                      onChange={changeField}
                      parentClassName="mb-6"
                    />
                  </Col> */}
                </Row>
              </div>

            </StyledCard>
            <div className="btn-layout">
              <StyledSubmitButton
                type="default"
                text={t("page.save")}
                // htmlType="submit"
                onClick={saveHouse}
              // onClick={handleSubmit}
              />
              <StyledCancelButton
                type="sub"
                text={t("page.cancel")}
                htmlType="button"
                onClick={handleClose}
              />
            </div>
          </StyledPageBody>
        ) : (
          <StyledPageBody>
            <StyledCard>
              <div className="title">
                ข้อมูลเจ้าของทะเบียนบ้านครัวเรือน
              </div>
              <div>
                <Row gutter={16}>
                  <Col md={4}>
                    <label className="label">
                      <span className="label-name">คำนำหน้า*</span>
                      <br /> <br />
                    </label>
                    <Select
                      value={value.prefix}
                      onChange={prefixChange}
                    >
                      {prefix.length > 0 &&
                        prefix.map((item, index) => {
                          return (
                            <Option value={item} key={index}>
                              {item}
                            </Option>
                          );
                        })
                      }
                    </Select>
                  </Col>
                  <Col md={10}>
                    <SharedInput
                      label="ชื่อ*"
                      // descLabel={t("page._2_100_characters")}
                      name="name"
                      value={value.name}
                      onBlur={handleBlur}
                      onChange={changeField}
                      parentClassName="mb-6"
                    />
                  </Col>
                  <Col md={10}>
                    <SharedInput
                      label="นามสกุล*"
                      // descLabel={t("page._2_100_characters")}
                      name="surname"
                      value={value.surname}
                      onBlur={handleBlur}
                      onChange={changeField}
                      parentClassName="mb-6"
                    />
                  </Col>
                  <Col md={24}>
                    <SharedInput
                      label="เลขบัตรประชาชน*"
                      // descLabel={t("page._2_100_characters")}
                      name="card_no"
                      value={value.card_no}
                      onBlur={handleBlur}
                      onChange={changeField}
                      parentClassName="mb-6"
                    />
                  </Col>
                </Row>
              </div>
            </StyledCard>
            <StyledCard>
              <div className="title">
                ข้อมูลที่อยู่
              </div>
              <div>
                <Row gutter={16}>
                  <Col md={12}>
                    <label className="label">
                      <span className="label-name">หมวดพื้นที่*</span>
                      <br /> <br />
                    </label>
                    <Select
                      value={value.area_id}
                      onChange={areaChange}
                    >
                      {areaList.length > 0 &&
                        areaList.map((item, index) => {
                          return (
                            <Option value={item._id} key={index}>
                              {item.area_name}
                            </Option>
                          );
                        })
                      }
                    </Select>
                  </Col>
                  <Col md={12}>
                    <SharedInput
                      label="เลขที่ทะเบียนบ้าน*"
                      // descLabel={t("page._2_100_characters")}
                      name="register_no"
                      value={value.register_no}
                      onBlur={handleBlur}
                      onChange={changeField}
                      parentClassName="mb-6"
                    />
                  </Col>
                  <Col md={12}>
                    <SharedInput
                      label="บ้านเลขที่*"
                      // descLabel={t("page._2_100_characters")}
                      name="house_no"
                      value={value.house_no}
                      onBlur={handleBlur}
                      onChange={changeField}
                      parentClassName="mb-6"
                    />
                  </Col>
                  <Col md={12}>
                    <SharedInput
                      label="ซอย"
                      // descLabel={t("page._2_100_characters")}
                      name="soi"
                      value={value.soi}
                      onBlur={handleBlur}
                      onChange={changeField}
                      parentClassName="mb-6"
                    />
                  </Col>
                  <Col md={12}>
                    <SharedInput
                      label="หมู่"
                      // descLabel={t("page._2_100_characters")}
                      name="moo"
                      value={value.moo}
                      onBlur={handleBlur}
                      onChange={changeField}
                      parentClassName="mb-6"
                    />
                  </Col>
                  <Col md={12}>
                    <SharedInput
                      label="ถนน"
                      // descLabel={t("page._2_100_characters")}
                      name="road"
                      value={value.road}
                      onBlur={handleBlur}
                      onChange={changeField}
                      parentClassName="mb-6"
                    />
                  </Col>
                  <Col xs={6} sm={6} md={12}>
                    <label className="label">
                      <span className="label-name">อำเภอ*</span>
                      <br /> <br />
                    </label>
                    <Select
                      value={value?.amphur_id}
                      onChange={amphurChange}
                    >
                      {amphurList.length > 0 &&
                        amphurList.map((item, index) => {
                          return (
                            <Option value={item.amphur_id} key={index}>
                              {item.amphur_name_tha}
                            </Option>
                          );
                        })
                      }
                    </Select>
                  </Col>
                  <Col md={12}>
                    <label className="label">
                      <span className="label-name">ตำบล*</span>
                      <br /> <br />
                    </label>
                    <Select
                      value={value.district_id}
                      onChange={districtChange}
                    >
                      {districtList.length > 0 &&
                        districtList.map((item, index) => {
                          return (
                            <Option value={item.district_id} key={index}>
                              {item.district_name_tha}
                            </Option>
                          );
                        })
                      }
                    </Select>
                  </Col>
                  {/* <Col md={12}>
                    <SharedInput
                      label="รหัสไปรษณีย์"
                      // descLabel={t("page._2_100_characters")}
                      name="card_no"
                      onBlur={handleBlur}
                      onChange={changeField}
                      parentClassName="mb-6"
                    />
                  </Col> */}
                </Row>
              </div>

            </StyledCard>
            <div className="btn-layout">
              <StyledSubmitButton
                type="default"
                text={t("page.save")}
                // htmlType="submit"
                onClick={editHouse}
              // onClick={handleSubmit}
              />
              <StyledCancelButton
                type="sub"
                text={t("page.cancel")}
                htmlType="button"
                onClick={handleClose}
              />
            </div>
          </StyledPageBody>
        )}
      </form>
    </div>
  );
});
const StyledAddCategoryModal = styled(Modal)`
    .ant-modal-header {
        border-bottom: 0.5px solid #8a959e !important;
        margin-bottom: 20px;
    }
    .form-input {
        .input-with-search {
            width: 100%;
        }
        .ant-select-selection__rendered {
            height: 100%;
        }
    }
    .footer {
        display: flex;
        justify-content: center;
        .add-cat-btn {
            width: 170px;
        }
    }
`;
const StyledSwitch = styled.div`
    display: flex;
    align-items: center;
    p {
        margin-bottom: 0;
    }
`;

const StyleMenuTab = styled.div`
    span {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin-right: 10px;
        padding: 10px;
        cursor: pointer;
    }
    .selected {
        background-color: #fff;
        border-top: 5px solid #0d6efd;
        color: #0d6efd;
        border-radius: 5px 5px 0 0;
    }
`;

const StyledBidderNum = styled.div`
    height: 30px;
    display: flex;
    .title {
        margin-left: 5px;
    }

    .number {
        font-weight: 600;
        font-size: 35px;
        line-height: 53px;
        margin-left: auto;
    }
`;

const StyledBidderList = styled.div`
    .header {
        display: flex;
        justify-content: space-between;
    }
`;

const StyledPageBody = styled.div`
    display: grid;
    /* grid-template-columns: 760px 2fr; */
    grid-gap: 15px;
`;

const StyledDetailBody = styled.div`
    background: #ffffff;
    border-radius: 4px;
    padding: 30px;
    max-width: 700px;
    .title {
        font-weight: 500;
        font-size: 25px;
        line-height: 38px;
    }
    .period {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }

    hr {
        border-top: 1px solid #f3f3f3;
    }

    .detail-headline {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }
    .detail-desc {
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
    }
`;

const StyledDocument = styled.div`
    background-color: #f4f4f4;
    border-radius: 5px;
    margin: 10px 0px;
    display: flex;
    justify-content: space-between;
    .name {
        margin: auto 30px;
        font-weight: 600;
    }
    button {
        background-color: #0d6efd;
        border: none;
        padding: 10px;
        width: 100px;
        border-radius: 5px;
        color: white;
        margin: 15px;
    }
`;

const StyledFileName = styled.p`
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
`;
