import { useEffect, memo, useState, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Row, Col, Checkbox } from "antd";
import styled from "styled-components";
import { IAdminUser, IAdminCreate, IPermission } from "@interfaces";
import {
    StyledSubmitButton,
    StyledCancelButton,
    StyledCard,
    SharedInput,
    ShareButton,
    ComponentModal,
} from "@components";
import { useYup } from "@validations";
import { setLoading, selectAuth } from "@redux";

import { showErrorMessage, useNotify } from "@utils";
import { PATH_ADMIN, PATH_ADMIN_CREATE, PATH_NEWS } from "@configs";
import { adminAPI } from "@api";

interface IProps {
    admin: IAdminUser | undefined;
    permission: IPermission[] | undefined;
}

const initData: IAdminCreate = {
    organization_id: "",
    username: "",
    permission_id: "",
    name: "",
    surname: "",
    tel_no: "",
};

export const ModuleAdminForm = memo((props: IProps) => {
    //hook
    const { YupInviteAdmin } = useYup();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { error, success } = useNotify();
    const [showModal, setShowModal] = useState<boolean>(false);
    const organization_id = useSelector(selectAuth).auth?.admin_data?.organization_id;
    const adminEmail = useSelector(selectAuth).admin?.admin_data?.username;
    const pathName = window.location.pathname;
    const validateAdmin = Yup.object().shape(YupInviteAdmin);
    const { admin, permission } = props;
    useEffect(() => {
        if (admin) {
            setFieldValue("surname", admin.surname);
            setFieldValue("name", admin.name);
            setFieldValue("username", admin.username);
            setFieldValue("tel_no", admin.tel_no);
            setFieldValue("permission_id", admin.permission_id);
        }
    }, [admin]);

    const handleNewsSubmit = async (values: IAdminCreate) => {
        dispatch(setLoading(true));
        try {
            if (pathName === PATH_ADMIN_CREATE) {
                const res = await adminAPI.createAdmin({
                    ...values,
                    ...{ organization_id: organization_id || "" },
                });
                if (res.data.error === "0") {
                    success(res.data.msg);
                    history.push(PATH_ADMIN);
                } else {
                    error(res.data.msg);
                }
            } else {
                const res = await adminAPI.updateAdmin({
                    ...values,
                    ...{ organization_id: organization_id || "" },
                    ...{ admin_id: admin?._id },
                });
                if (res.data.error === "1") {
                    error(res.data.msg);
                } else {
                    success(res.data.msg);
                    history.push(PATH_ADMIN);
                }
            }
        } catch (err: any) {
            if (err.response) {
                error(showErrorMessage(err.response));
            } else error(t("message.error"));
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleCancel = () => {
        history.push(PATH_ADMIN);
    };

    const handleDelete = async () => {
        try {
            const { data } = await adminAPI.deleteAdmin({
                organization_id: organization_id || "",
                admin_id: admin?._id || "",
            });
            if (data.error === "1") {
                error(data.msg);
            } else {
                success(data.msg);
                history.push(PATH_ADMIN);
            }
        } catch (err: any) {
            if (err) {
                error(err.msg);
            } else error(t("message.delete.fail"));
        }
    };

    const { values, handleChange, handleBlur, handleSubmit, errors, touched, setFieldValue } =
        useFormik({
            initialValues: initData,
            validationSchema: validateAdmin,
            enableReinitialize: true,
            onSubmit: handleNewsSubmit,
        });
    const handleCheckPermission = (item: IPermission) => {
        setFieldValue("permission_id", item._id);
    };
    return (
        <div className="form-wrap">
            <form onSubmit={handleSubmit}>
                <div className="page-header">
                    <h3>{`แก้ไขผู้ใช้งาน`}</h3>
                    <p className="sub-title">{`โปรดระบุรายละเอียดให้ครบถ้วน`}</p>
                </div>
                <div className="page-body">
                    <div className="card-wrap">
                        <StyledCard>
                            <div className="title">{t("page.user_role")}</div>
                            <Row gutter={32}>
                                <Col span={12}>
                                    <SharedInput
                                        label={t("page.name")}
                                        // descLabel={t("page._2_100_characters")}
                                        name="surname"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.surname}
                                        errors={errors.surname}
                                        touched={touched.surname}
                                        parentClassName="mb-6"
                                    />
                                </Col>
                                <Col span={12}>
                                    <SharedInput
                                        label={`นามสกุล (ภาษาไทย)*`}
                                        // descLabel={t("page._2_100_characters")}
                                        name="name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.name}
                                        errors={errors.name}
                                        touched={touched.name}
                                        parentClassName="mb-6"
                                    />
                                </Col>
                            </Row>
                            <Row gutter={32}>
                                <Col span={12}>
                                    <SharedInput
                                        label={t("page.email")}
                                        // descLabel={t("page._2_100_characters")}
                                        name="username"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.username}
                                        errors={errors.username}
                                        touched={touched.username}
                                        parentClassName="mb-6"
                                    />
                                </Col>
                                <Col span={12}>
                                    <SharedInput
                                        label={`เบอร์โทรศัพท์*`}
                                        // descLabel={t("page._2_100_characters")}
                                        name="tel_no"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.tel_no}
                                        errors={errors.tel_no}
                                        touched={touched.tel_no}
                                        parentClassName="mb-6"
                                        type="number"
                                    />
                                </Col>
                            </Row>
                        </StyledCard>
                    </div>
                    <div className="card-wrap">
                        <StyledCard>
                            <div className="title">{t("page.user_role")}</div>
                            {permission &&
                                permission.map((item, index) => (
                                    <div key={index}>
                                        <Checkbox
                                            key={index}
                                            onChange={() => handleCheckPermission(item)}
                                            checked={item._id === values.permission_id}
                                        >
                                            {item.name}
                                        </Checkbox>
                                        <br />
                                    </div>
                                ))}
                        </StyledCard>
                    </div>
                    <div className="btn-group-layout">
                        {(pathName !== PATH_ADMIN_CREATE || values.username !== adminEmail) && (
                            <StyledCancelButton
                                type="sub"
                                text={t("page.delete")}
                                htmlType="button"
                                className="cancel-btn"
                                onClick={handleDelete}
                            />
                        )}
                        <div className="btn-group">
                            <StyledCancelButton
                                type="textMain"
                                text={t("page.cancel")}
                                htmlType="button"
                                onClick={handleCancel}
                            />
                            <StyledSubmitButton
                                type="default"
                                text={t("page.save")}
                                htmlType="submit"
                                // disable={isSubmitting}
                            />
                        </div>
                    </div>
                </div>
            </form>
            <ComponentModal
                isVisible={showModal}
                title={t("page.add_news_type")}
                width={680}
                style={{ marginTop: "14vh" }}
                className={"add-category"}
                onCancel={() => setShowModal(false)}
            >
                <StyledBody></StyledBody>
                <div className="footer">
                    <ShareButton
                        type="primary"
                        size="large"
                        className="add-cat-btn"
                        text={t("page.agree")}
                        onClick={() => setShowModal(false)}
                    />
                </div>
            </ComponentModal>
        </div>
    );
});

const StyledSwitch = styled.div`
    display: flex;
    align-items: center;
    p {
        margin-bottom: 0;
    }
`;

const StyledBody = styled.div`
    .form-input {
        margin-top: 20px;
        p {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #29304d;
        }
    }
`;
