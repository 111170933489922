import { ISvg } from "@interfaces";
export const IconPresent = (props: ISvg) => (
    <svg
        width={props.size || "24"}
        height={props.size || "24"}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M21.3803 5.75992C21.3462 5.63223 21.2871 5.51257 21.2064 5.40786C21.1257 5.30316 21.0251 5.21549 20.9103 5.14992L15.7103 2.14992C15.5968 2.08339 15.4712 2.03992 15.3409 2.022C15.2105 2.00409 15.0779 2.01209 14.9506 2.04553C14.8233 2.07897 14.7039 2.13721 14.5992 2.2169C14.4945 2.29659 14.4065 2.39616 14.3403 2.50992L12.0003 6.56992L9.66034 2.50992C9.59417 2.39616 9.50621 2.29659 9.40149 2.2169C9.29676 2.13721 9.17734 2.07897 9.05007 2.04553C8.92279 2.01209 8.79017 2.00409 8.6598 2.022C8.52943 2.03992 8.40387 2.08339 8.29034 2.14992L3.09034 5.14992C2.97557 5.21549 2.87494 5.30316 2.79427 5.40786C2.71359 5.51257 2.65448 5.63223 2.62034 5.75992C2.55415 6.01337 2.59006 6.28266 2.72034 6.50992L6.72034 13.3399C6.27408 14.1575 6.02738 15.0689 6.00034 15.9999C5.99796 16.9082 6.20182 17.8052 6.59656 18.6232C6.99129 19.4412 7.5666 20.159 8.27909 20.7223C8.99159 21.2856 9.82268 21.6798 10.7097 21.8752C11.5967 22.0706 12.5165 22.062 13.3998 21.8502C14.283 21.6383 15.1066 21.2287 15.8085 20.6522C16.5104 20.0757 17.0722 19.3474 17.4517 18.5222C17.8311 17.6969 18.0183 16.7963 17.999 15.8882C17.9797 14.9801 17.7545 14.0883 17.3403 13.2799L21.2403 6.51992C21.3129 6.40886 21.3627 6.28447 21.3867 6.15399C21.4108 6.02351 21.4086 5.88955 21.3803 5.75992ZM5.00034 6.37992L8.46034 4.37992L11.6803 9.99992C10.3078 10.0847 9.00712 10.6431 8.00034 11.5799L5.00034 6.37992ZM12.0003 19.9999C10.9395 19.9999 9.92206 19.5785 9.17191 18.8283C8.42176 18.0782 8.00034 17.0608 8.00034 15.9999C8.00034 14.9391 8.42176 13.9216 9.17191 13.1715C9.92206 12.4213 10.9395 11.9999 12.0003 11.9999C13.0612 11.9999 14.0786 12.4213 14.8288 13.1715C15.5789 13.9216 16.0003 14.9391 16.0003 15.9999C16.0003 17.0608 15.5789 18.0782 14.8288 18.8283C14.0786 19.5785 13.0612 19.9999 12.0003 19.9999ZM16.0003 11.5499C15.4574 11.054 14.8266 10.664 14.1403 10.3999L13.1603 8.56992L15.5803 4.37992L19.0403 6.37992L16.0003 11.5499Z"
            fill={props.color || "#0D6EFD"}
        />
    </svg>
);
