import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { StyledNewDetail } from "@components";
import { IReward } from "@interfaces";
import { PATH_PRIVILEGES_CREATE, PATH_PRIVILEGES } from "@configs";
import { ModuleRewardForm } from "@modules";
import { useNotify, usePermission } from "@utils";
import { rewardAPI } from "@api";
import { setLoading, selectAuth } from "@redux";

export const PageRewardCreate = () => {
    const { id } = useParams<{ id: string }>();
    const org_id = useSelector(selectAuth).auth?.organization_data?._id;
    const permission = useSelector(selectAuth).permission;
    const dispatch = useDispatch();
    const history = useHistory();
    const { warning } = useNotify();
    const { t } = useTranslation();
    // page state
    const [reward, setReward] = useState<IReward>();
    // variable
    const pathName = history.location.pathname;
    usePermission({ permission: permission?.permission_list.claim_reward.is_manage_claim });
    useEffect(() => {
        fetchReward();
    }, []);
    const fetchReward = async () => {
        if (pathName !== PATH_PRIVILEGES_CREATE) {
            dispatch(setLoading(true));
            try {
                const response = await rewardAPI.detail({
                    organization_id: org_id || "",
                    reward_id: id,
                });
                const data: IReward = response.data.result;
                setReward(data);
                dispatch(setLoading(false));
            } catch (err: any) {
                if (err) {
                    warning(err.msg);
                    dispatch(setLoading(false));
                } else warning(t("message.not_found", { name: t("object._reward") }));
                history.push(PATH_PRIVILEGES);
            } finally {
                dispatch(setLoading(false));
            }
        }
    };

    return <StyledNewDetail>{<ModuleRewardForm reward={reward} />}</StyledNewDetail>;
};
