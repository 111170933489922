import { IConplaintCategory, IDivision, INewsCategory, ITrashfeeArea } from "@interfaces";
import { ConfigProvider, DatePicker, Input, Select } from "antd";
import moment from "moment";
import "moment/locale/th";
import locale from "antd/es/locale-provider/th_TH";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { CaretDownOutlined } from "@ant-design/icons";
const { Search } = Input;
const { Option } = Select;

interface Iprops {
  setTextSearch: (value: string) => void;
  onChangeDate?: (dates, dateStrings: string) => void;
  total: number;
  setSearchHouseType: (value: string) => void;
  setSearchArea: (value: string) => void;
  areaList: ITrashfeeArea[];
  handleSearchDate: (month: number, year: number) => void;
}
const dateFormat = 'MM/YYYY';


export const ComponentTableSearchBill = (props: Iprops) => {
  const { setTextSearch,
    setSearchHouseType,
    total,
    areaList,
    setSearchArea,
    handleSearchDate,
  } = props;
  const { t } = useTranslation();
  moment.locale("th");
  const { MonthPicker } = DatePicker;

  const changeDate = (date, dateString) => {
    console.log(date, dateString);
    // console.log(date.month() + 1, date.year());
    if (date !== null) {
      handleSearchDate((date.month() + 1), date.year());
    } else {
      handleSearchDate(0, 0);
    }
  };

  return (
    <StyledSearchLayout>
      <div className="filter">
        <div className="search-row">
          <div className="col-item">
            <div className="filter-element">
              <div className="label">
                <p>ค้นหา :</p>
              </div>
              <div className="input">
                <Search
                  placeholder="ค้นหา"
                  onSearch={(value) => setTextSearch(value)}
                  className="search-input"
                />
              </div>
            </div>
          </div>
          <div className="col-item">
            <div className="filter-element">
              <div className="label">
                <p>
                  ประเภทที่อยู่
                  <span>{" :"}</span>
                </p>
              </div>
              <div className="input">
                <Select
                  suffixIcon={<CaretDownOutlined className="suf-icon" />}
                  onChange={setSearchHouseType}
                  placeholder={t("page.all")}
                >
                  <Option value="all">
                    ทั้งหมด
                  </Option>
                  <Option value="home">
                    ครัวเรือน
                  </Option>
                  <Option value="business">
                    กิจการ
                  </Option>
                </Select>
              </div>
            </div>
          </div>
          <div className="col-item">
            <div className="filter-element">
              <div className="label">
                <p>
                  หมวดพื้นที่
                  <span>{" :"}</span>
                </p>
              </div>
              <div className="input">
                <Select
                  onChange={setSearchArea}
                  placeholder={t("page.all")}
                  suffixIcon={<CaretDownOutlined className="suf-icon" />}
                >
                  <Option value="all">
                    ทั้งหมด
                  </Option>
                  {areaList && areaList.map((item, index) => {
                    return (
                      <Option value={item?._id} key={index}>
                        {item?.area_name}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </div>
          </div>
          <div className="col-item">
            <div className="filter-element">
              <div className="label">
                <p>
                  รอบบิล
                  <span>{" :"}</span>
                </p>
              </div>
              <div className="input">
                <MonthPicker
                  placeholder="ทั้งหมด"
                  onChange={changeDate}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="filter-element result">
          <p>
            <span>{t("page.result")}</span>
            <span>{":"}</span>
            <span className="eng">{total}</span>
          </p>
        </div>
      </div>
    </StyledSearchLayout>
  );
};
const StyledSearchLayout = styled.div`
    .filter {
        background: #fff;
        padding: 20px;
        border-radius: 4px;
        margin-bottom: 26px;
        display: flex;
        justify-content: space-between;
        .search-row {
            flex: 11;
            display: flex;
            column-gap: 10px;
            align-items: center;
        }
        .filter-element {
            flex: 2;
            display: flex;
            align-items: center;
            column-gap: 10px;
            margin-right: 15px;
            p {
                margin: 0;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                color: #646464;
            }
            .filter-input {
                width: 240px;
                @media (max-width: ${(p) => p.theme.breakPoints.breakMacLaptop}) {
                    width: 150px;
                }
            }
            .ant-input-search {
                height: 44px;
                width: 100%;
            }
            .label {
                p {
                    @media (max-width: ${(p) => p.theme.breakPoints.breakMacLaptop}) {
                        text-overflow: ellipsis;
                        max-width: 70px;
                        white-space: nowrap;
                    }
                    @media (max-width: ${(p) => p.theme.breakPoints.break13Laptop}) {
                        max-width: 80px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }
            .input {
                .search-input {
                    width: 200px;
                    @media (max-width: ${(p) => p.theme.breakPoints.breakMacLaptop}) {
                        width: 150px;
                    }
                }
                .ant-select {
                    width: 200px;
                    @media (max-width: ${(p) => p.theme.breakPoints.breakMacLaptop}) {
                        width: 150px;
                    }
                }
            }
            .ant-select {
                /* min-width: 160px; */
                width: 100%;
                .ant-select-selection--single {
                    height: 44px;
                    .ant-select-selection__rendered {
                        height: 100%;
                        align-items: center;
                        display: flex;
                    }
                }
            }
            .ant-calendar-picker-input {
                height: 44px;
                .ant-calendar-range-picker-separator {
                    vertical-align: unset !important;
                }
            }
            &.result {
                display: flex;
                justify-content: end;
                p {
                    display: flex;
                    column-gap: 10px;
                }
            }
            .suf-icon {
              color: black;
              font-size: 16px;
              line-height: 5px;
            }
        }
    }
`;
