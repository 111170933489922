import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { StyledMerchantSetting } from "@components";
import { ITheme } from "@interfaces";
import { ModuleThemeForm } from "@modules";
import { useNotify } from "@utils";
import { orgApi } from "@api";
import { setLoading, selectAuth } from "@redux";

export const PageThemeCreate = () => {
    const organization_id = useSelector(selectAuth).auth?.organization_data?._id;
    const dispatch = useDispatch();
    const { warning } = useNotify();
    // page state
    const [theme, setTheme] = useState<ITheme>();
    // variable

    useEffect(() => {
        fetchTheme();
    }, []);

    const fetchTheme = async () => {
        try {
            dispatch(setLoading(true));
            const response = await orgApi.getTheme({ organization_id: organization_id || "" });

            const data: ITheme = response.data.result;
            setTheme(data);
            dispatch(setLoading(false));
        } catch (err: any) {
            if (err) {
                warning(err.msg);
                dispatch(setLoading(false));
            }
        } finally {
            dispatch(setLoading(false));
        }
    };

    return <StyledMerchantSetting>{<ModuleThemeForm theme={theme} />}</StyledMerchantSetting>;
};
