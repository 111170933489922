import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { Col, Row, Avatar, Menu, Dropdown, Button } from "antd";
import { MoreHorizontal } from "react-feather";
import styled from "styled-components";
import { IAdminUser } from "@interfaces";
import { adminAPI } from "@api";
import { defaultImage, PATH_ADMIN } from "@configs";

import { useNotify } from "@utils";
import {
    ComponentModal,
    IconSubmit,
    ShareButton,
    StyledTableButtonTop,
    SuccessIcon,
} from "@components";
import { useState } from "react";
import { selectAuth } from "@redux";
import { useSelector } from "react-redux";

interface IProps {
    data: IAdminUser;
    itemJson: string;
    handleEdit: (status: boolean, data: IAdminUser) => void;
    callbackGetList: () => void;
    index: number;
}

export const ComponentRowAdmin = (props: IProps) => {
    //hooks
    const { t } = useTranslation();
    const { success, error } = useNotify();
    const organization_id = useSelector(selectAuth).auth?.organization_data?._id;
    //props
    const { data, callbackGetList } = props;
    const history = useHistory();
    const [isShow, setShowModal] = useState<boolean>(false);
    const handleActivite = async () => {
        try {
            const res = await adminAPI.sendMailActivateLink({
                organization_id: organization_id || "",
                admin_id: data?._id || "",
            });
            if (res.data.error === "1") {
                error(res.data.msg);
            } else {
                setShowModal(true);
                success(res.data.msg);
                callbackGetList();
            }
        } catch (err: any) {
            if (err) {
                error(err.msg);
            } else error(t("message.delete.fail"));
        }
    };
    return (
        <div className="table-element">
            <div className="table-row small">
                <Row
                    style={{ height: "100%" }}
                    gutter={24}
                    type="flex"
                    align="middle"
                    justify="space-between"
                >
                    <Col className="col-item" span={6}>
                        <Avatar
                            shape="circle"
                            src={data?.image_url || defaultImage}
                            size={52}
                            style={{ minWidth: 52 }}
                        />
                        {/* <Link to={`${PATH_ADMIN}/${data._id}`}> */}
                        <p className="row-title">
                            {`${data.surname}${" "}${data.name} ` || t("page.empty_text")}
                        </p>
                        {/* </Link> */}
                    </Col>
                    <Col className="col-item visible-md" span={4}>
                        <p>{data?.permission_name || t("page.empty_text")}</p>
                    </Col>
                    <Col className="col-item m-member-code" span={4}>
                        <p className="visible-md eng">{data?.email || t("page.empty_text")}</p>
                    </Col>
                    <Col className="col-item visible-md" span={6}>
                        <p className={data?.is_activate ? "is_activate" : "inactivate"}>
                            {data?.is_activate ? t("page.activite") : t("page.inactivite")}
                        </p>
                        {data?.is_activate === false && (
                            <StyledTableButtonTop
                                type="danger"
                                size="default"
                                text={`ส่งอีเมลอีกครั้ง`}
                                onClick={handleActivite}
                                className="ant-btn-primary"
                                customIcon={<IconSubmit />}
                            />
                        )}
                    </Col>
                    <Col className="col-item" span={4}>
                        <ShareButton
                            type="default"
                            size="large"
                            className="ant-btn-primary carryout-btn"
                            text={`แก้ไข`}
                            onClick={() => history.push(PATH_ADMIN + "/" + data._id)}
                        />
                    </Col>
                </Row>
            </div>
            <ComponentModal
                title={"-"}
                isVisible={isShow}
                onCancel={() => setShowModal(false)}
                className="success-modal"
                width={205}
            >
                <StyledSuccess>
                    <SuccessIcon />
                    <p className="success-message">{t("page.activite_msg")}</p>
                </StyledSuccess>
            </ComponentModal>
        </div>
    );
};

const StyledSuccess = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 32px !important;
    text-align: center;
    .success-message {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #000000;
    }
`;
